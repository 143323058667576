import React, { useEffect, useState, useRef } from 'react'
import DataTable from 'react-data-table-component'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { sessionActive } from '../../../lib/functions'
import { Col, Row, Button, Form, Modal } from 'react-bootstrap'
import GTHFOR92 from './GTHFOR92'
import { getFormats} from '../../../lib/formatos/request'
import { useUserContext } from '../../../../../hooks/custom'


export const Permission = props => {
  const {sessionUser, Permission } = useUserContext()
  const [showFormCreate, setShowFormCreate] = useState(false)

  const [IdUser_PK, setIdUser_PK] = useState(localStorage.getItem('IdUser_PK'))
  const [isView, setIsView] = useState(true)
  const [restart, setRestart] = useState(false)
  const [IdFormat_PK, setIdFormat_PK] = useState('')
  const [showForm, setShowForm] = useState(false)
  const [data, setData] = useState()
  const [formats, setFormats] = useState([])
  const [filterindItems, setFilterindItems] = useState([])
  const [ModalDelete, setModalDelete] = useState(false)
  const [search, setSearch] = useState()
  const [idDelete, setIdDelete] = useState()
  const [list, setList] = useState([])

  useEffect(() => {
    if (!restart) {
      setRestart(!restart)
      sessionActive()
      fillTable()
    }
  }, [restart])

  const fillTable = async () => {

    //Obtener lista de solicitudes de empleo
    const resFormats = await getFormats('GTHFOR92')
    if (resFormats.data.status === 200) {
      setFormats(resFormats.data.data)
      setFilterindItems(resFormats.data.data)
    } else {
      toast.error(resFormats.data.msj)
    }


  }
  const newContract = (data) => {

    if ((Permission.superAdmin.access || Permission.humanTalent.PUT) && data.Status === "disponible")
      return true
    return false
  }
  const editRow = row => {
    if (props.put) {
      if (row.Status === 'creado') return true
      else return false
    }
    return false
  }
  const deleteRow = row => {
    /* !props.put || row.Status!="solicitado" && !isTH || IdUser_PK!=row.IdUser_FK*/
    if (props.delete) {
      if (IdUser_PK === row.IdUser_FK && row.Status === 'solicitado') return true
      else return false
    }
    return false
  }

  // const deleteSol = async () => {
  //   //borrar un solicitud
  //   const resDelele = await deleteFormatId('MITFOR207', idDelete)
  //   if (resDelele.data.status === 200) {
  //     toast.success(resDelele.data.msj)
  //   } else {
  //     toast.error(resDelele.data.msj)
  //   }
  //   fillTable()
  // }

  const OnFilter = e => {
    let FilterText = e.target.value
    setSearch(FilterText)
    setFilterindItems(
      formats.filter(
        item =>
           //item.IdFormat_PK.includes(FilterText) ||
           item.Nombre.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.Area.toLowerCase().includes(FilterText.toLowerCase()) ||
         item.Fecha_Solicitud.includes(FilterText) ||
          item.Cargo.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.Status.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.Cedula.includes(FilterText)

      )
    )
  }

  const ClearSearch = () => {
    setSearch('')
    setFilterindItems(formats)
  }

  const handleChange =(data)=>{
    setData(data)
    setShowForm(!showForm)
    setRestart(false)
    setIsView(true)
    setIdFormat_PK (data.IdFormat_PK )

  }


  const columns = [
    {
      name: 'Id',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row )
          }}
          data-tag="allowRowEvents"
        >
          {row.IdFormat_PK }
        </div>
      ),
      selector: row => row.IdFormat_PK ,
      sortable: true,
      fixed: true,
    },
    {
      name: 'Fecha de solicitud',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row )
          }}
          data-tag="allowRowEvents"
        >
          {row.Fecha_Solicitud}
        </div>
      ),
      selector: row => row.Fecha_Solicitud ,
      sortable: true,
    },
    {
      name: 'Nombre',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row )
          }}
          data-tag="allowRowEvents"
        >
          {row.Nombre}
        </div>
      ),
      selector: row => row.Nombre ,
      sortable: true,
    },
    {
      name: 'Area',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row )
          }}
          data-tag="allowRowEvents"
        >
          {row.Area}
        </div>
      ),
      selector: row => row.Area ,
      sortable: true,
    },
    {
      name: 'Cedula',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row )
          }}
          data-tag="allowRowEvents"
        >
          {row.Cedula}
        </div>
      ),
      selector: row => row.Cedula ,
      sortable: true,
    },
    {
      name: 'Posición de trabajo',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row )
          }}
          data-tag="allowRowEvents"
        >
          {row.Cargo}
        </div>
      ),
      selector: row => row.Cargo ,
      sortable: true,
    },
    {
      name: 'Estado ',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row )
          }}
          data-tag="allowRowEvents"
        >
          {row.Status}
        </div>
      ),
      selector: row => row.Status,
      sortable: true,
      grow: 1.5,
    },
    {
      name: 'Acciones',
      cell: row => (
        <div className="tabla dropdown dropstart">
          <i
            className="bi bi-three-dots-vertical"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          />
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          {/* <li>
              <Link
                onClick={() => {
                  setShowFormCreate(!showFormCreate)
                  setRestart(false)
                  setIsView(false)
                  setIdFormat_PK("en creacion")
                  setData(row)

                }}
                className={'dropdown-item text-center border-end' + (newContract(row) ? '' : ' disabled')}
                to="/humantalent"
              >
                <i className="bi bi-pen me-3 "></i>Contratar
              </Link>
            </li> */}
            <li>
              <Link
                onClick={() => {
                  setData(row)
                  setShowForm(!showForm)
                  setRestart(false)
                  setIsView(false)
                  setIdFormat_PK (row.IdFormat_PK )
                }}
                className={'dropdown-item text-center border-end' + (editRow(row) ? '' : ' disabled')}
                to="/humantalent"
              >
                <i className="bi bi-pen me-3 "></i>Editar
              </Link>
            </li>
            <li>
              <Link
                className={'dropdown-item text-center border-end' + (deleteRow(row) ? '' : ' disabled')}
                to="/humantalent"
                onClick={() => {
                  setIdDelete(row.IdFormat_PK )
                  setRestart(false)
                  setModalDelete(true)
                }}
              >
                <i className="bi bi-trash me-3"></i>Borrar
              </Link>
            </li>
          </ul>
        </div>
      ),
      button: true,
    },
  ]

  return (
    <>
      <Row>
        <Col>
          {props.newBotton && (
              <Button variant="success"
                className="m-3 p-2 rounded-pill btn-sm ms-1"
                disabled={props.post ? false : true}
                onClick={() => {
                  setShowForm(!showForm)
                  setRestart(false)
                  setIsView(false)
                  setIdFormat_PK('en creacion')
                }}
              >
                <i className="fa fa-solid fa-envelope mx-2" />
                Nueva solicitud
              </Button>
            )}
            <Col className="d-flex">
            <Form.Control
              type="text"
              id="search"
              placeholder="Búsqueda"
              aria-label="search input"
              value={search}
              onChange={OnFilter}
            />
            <Button data-tip="Limpiar Filtro" className="btn btn-success btn-sm" type="button" onClick={ClearSearch}>
              <i className="bi bi-arrow-counterclockwise" />
            </Button>
          </Col>

          <Row>
            <DataTable
              fixedHeader={false}
              fixedHeaderScrollHeight={{fixedHeaderScrollHeight: '100px'}}
              columns={columns}
              data={filterindItems}
              pagination
              responsive
              dense={props.dense}
              highlightOnHover
              pointerOnHover
              noDataComponent="No hay registros para mostrar"
              paginationComponentOptions={{
                rowsPerPageText: 'Filas por página:',
                rangeSeparatorText: 'de',
              }}
            />

          </Row>
        </Col>

      </Row>

      {showForm && <GTHFOR92
        sessionUser={props.sessionUser}
        data={data}
        setData={setData}
        show={showForm}
        hide={setShowForm}
        restart={restart}
        setRestart={setRestart}
        isView={isView}
        IdFormat_PK={IdFormat_PK}
        list={list}
      />}

    </>
  )
}

export default Permission
