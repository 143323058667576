import React, { useEffect, useState, useRef } from 'react'
import DataTable from 'react-data-table-component'
import { toast } from 'react-toastify'
import { sessionActive } from '../../lib/functions'
import { Col, Row, Button, Form } from 'react-bootstrap'
//import MITFOR207 from '../../lib/formatos/MITFOR207'
import GTHFOR070 from '../../lib/formatos/GTHFOR070'
import GTHFOR086 from '../../lib/formatos/GTHFOR086'
import COMFORRequisiciones from '../../lib/formatos/COMFORRequisiciones'
//import Cotizacion from '../../lib/formatos/Cotizacion'
import OrdenServ from '../../lib/formatos/OrdenServ'
import Inform from '../../lib/formatos/Inform'
import { getFormatsById, getWorkflowById } from '../../lib/formatos/request'
import { getMultiDataF } from '../../lib/request'
import GTHFORContract from '../../lib/formatos/GTHFORContract'
import COMFOR167 from '../../lib/formatos/COMFOR167'
import COFIFOR159 from '../../lib/formatos/COFIFOR159'
import COFIFOR271 from '../../lib/formatos/COFIFOR271'
import GTHFOR076 from '../../lib/formatos/GTHFOR076'
import GTHFOR244 from '../../lib/formatos/GTHFOR244'
import COFIFOR161 from '../../lib/formatos/COFIFOR161'


export const UserWorkflow = ({ sessionUser, dense }) => {
  const IdUser_PK = localStorage.getItem('IdUser_PK')
  const isView = true
  const [restart, setRestart] = useState(false)
  const [IdFormat_PK, setIdFormat_PK] = useState('')
  const [data, setData] = useState({})
  const [workflowsAll, setWorkflowsAll] = useState([])
  const [workflows, setWorkflows] = useState([])
  const [filterindItems, setFilterindItems] = useState([])
  const [search, setSearch] = useState()
  const [wfSelected, setWfSelected] = useState()
  const [showGHTFORContract, hideGHTFORContract] = useState(false)
  const [showGHTFOR076, hideGHTFOR076] = useState(false)
  const [showGTHFOR070, hideGTHFOR070] = useState(false)
  const [showCOMFORRequisiciones, hideCOMFORRequisiciones] = useState(false)
  const [showGTHFOR086, hideGTHFOR086] = useState(false)
  const [showCOMFOR167, hideCOMFOR167] = useState(false)
  const [showGTHFOR244, hideGTHFOR244] = useState(false)
  const [showCOFIFOR159, hideCOFIFOR159] = useState(false)
  const [showCOFIFOR271, hideCOFIFOR271] = useState(false)
  const [showCOFIFOR161, hideCOFIFOR161] = useState(false)
  const [showOrden, hideOrden] = useState(false)
  const [showInforme, hideInforme] = useState(false)
  const isTH = false
  const [isRev, setIsRev] = useState(false)
  const [isApro, setIsApro] = useState(false)
  const [list, setList] = useState([])
  const [isSign, setIsSign] = useState(false)

  const [addQuotation, setaddQuotation] = useState(true)
  const [cbFilterState, setcbFilterState] = useState({ open: true, close: false })
  const [textFilter, settextFilter] = useState("pendiente")
  let didInit = useRef(false)

  let cbFilter = cbFilterState

  useEffect(() => {

    if (!restart) {      
        setRestart(!restart)
        sessionActive()
        fillTable()
      // if (!didInit.current) {
      //   didInit.current = true
      //   setRestart(!restart)
      //   sessionActive()
      //   fillTable()
      // }
    }
  }, [restart, workflows])

  const clearStatus = () => {
    setIsRev(false)
    setIsApro(false)

  }



  const fillTable = async () => {
    //Obtener lista de solicitudes de workflow
    const resWorkflows = await getWorkflowById("IdEmployee_FK", localStorage.getItem('IdUser_PK'))
   
    if (resWorkflows.data.status === 200) {
      console.log(resWorkflows.data.data)
      setWorkflowsAll(resWorkflows.data.data.wf)
      setWorkflows(checkboxFilter(resWorkflows.data.data.wf, textFilter))
      setFilterindItems(checkboxFilter(resWorkflows.data.data.wf, textFilter))
    } else {

      toast.error(resWorkflows.data.msj)
    }
    ////------------descarga data para formularios
    let temp
    temp = resWorkflows.data.data.list

    setList({
      ...temp,
      listaOtroRecurso: temp.listaOtroRecurso.map((number, i) => <option key={i} value={number.OtroRecurso}></option>),
      listaCargos: temp.listaOtroRecurso.map((number, i) => <option key={i} value={number.cargo}></option>),
      mainCompany:[]
    })


  }



  const checkboxFilter = (data, filter) => {
    return data.filter(item => item.Status.toLowerCase().includes(filter))

  }
  const handleChange = async ({ target }) => {
    const { name, type } = target
    const value = type === 'checkbox' ? target.checked : target.value
    cbFilter = { ...cbFilter, [name]: value }
    setcbFilterState({ ...cbFilterState, [name]: value })
    let filter = "zzzz"
    if (!cbFilter.open && cbFilter.close)
      filter = "finalizado"

    else if (cbFilter.open && !cbFilter.close)
      filter = "pendiente"
    else if (cbFilter.open && cbFilter.close)
      filter = ""

    settextFilter(filter)
    setWorkflows(checkboxFilter(workflowsAll, filter))
    setFilterindItems(checkboxFilter(workflowsAll, filter))
  }


  const OnFilter = e => {
    let FilterText = e.target.value
    setSearch(FilterText)
    setFilterindItems(
      workflows.filter(
        item =>
          item.IdEmployee_FK.includes(FilterText.toLowerCase()) ||
          item.documents.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.action.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.Status.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.Format.toLowerCase().includes(FilterText.toLowerCase())

      )
    )
  }






  const selectedDoc = async (wfItem) => {
    setIsSign(wfItem.Status === "finalizado" ? false : true)
    let formato = wfItem["Format"]
    clearStatus()
    if (formato === "GTHFOR069" || formato === "GTHFOR274" || formato === "GTHFOR275" || formato === "GTHFOR276") {
      formato="employeecontract"
    }
    console.log(formato, wfItem["IdFormat_FK"])

    var result = (await getFormatsById(formato, wfItem["IdFormat_FK"])).data.data[0]
    if (!result) {
      toast.error('Documento no existe')
      return
    }
    setData(result)
    setIdFormat_PK(wfItem["IdFormat_FK"])
    setWfSelected(wfItem)
    if (wfItem["IdEmployee_FK"] === IdUser_PK) {
      if (wfItem["Status"] === "Revisión") setIsRev(true)
      else if (wfItem["Status"] === "Aprobación") setIsApro(true)
    }
    if (formato === "employeecontract" ) {
      hideGHTFORContract(true)
    }
    // else if (formato === "COFIFOR159" || formato === "COFIFOR271")
    //   formato = "cofiforant"
    else if (formato === "GTHFOR070") hideGTHFOR070(true)
    else if (formato === "GTHFOR76") hideGHTFOR076(true)
    /* ---------------------Solicitudes de servicio------------------------ */
    else if (formato === "requisiciones") {
      var cotizacion = await getMultiDataF('st_multidata', 'Cotizacion')
      setList({ cotizacion: await cotizacion?.map(value => value.names) })
      setData({
        ...result, Empresa: JSON.parse(result.Empresa),
        SolicitadoPor: JSON.parse(result.SolicitadoPor),
        CotizaTable: result.CotizaTable.indexOf("{") !== -1 ? JSON.parse(result.CotizaTable) : []
      })
      setaddQuotation(false)
      if (result.Status === "cotizar" && wfItem.IdEmployee_FK.includes(localStorage.getItem('IdUser_PK'))) {
        setaddQuotation(true)
      }

      hideCOMFORRequisiciones(true)
    }

    else if (formato === "COMFOR167") hideCOMFOR167(true)
    else if (formato === "GTHFOR244") {
      setData({ ...result, dataAnt: JSON.parse(result.dataAnt), dataAct: JSON.parse(result.dataAct) })
      hideGTHFOR244(true)
    }
    else if (formato === "GTHFOR086") hideGTHFOR086(true)
    else if (formato === "COFIFOR159") hideCOFIFOR159(true)
    else if (formato === "COFIFOR271") hideCOFIFOR271(true)
    else if (formato === "COFIFOR161") hideCOFIFOR161(true)
    else if (formato === "ordenservicio") hideOrden(true)
    else if (formato === "informe") hideInforme(true)

    setRestart(!restart)

  }


  const columns = [
    {
      name: 'Id',
      cell: row => (
        <div
          onClick={() => {
            selectedDoc(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.IdWorkFlow_PK}
        </div>
      ),
      selector: row => row.IdWorkFlow_PK,
      sortable: true,
      grow: 0.1,
      fixed: true,
    },
    {
      name: 'Descripción de tarea',
      cell: row => (
        <div
          onClick={() => {
            selectedDoc(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.TaskDescription}
        </div>
      ),
      selector: row => row.TaskDescription,
      sortable: true,
      grow: 2,
    },
    {
      name: 'Documento',
      cell: row => (
        <div
          onClick={() => {
            selectedDoc(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.documents}
        </div>
      ),
      selector: row => row.documents,
      sortable: true,
      grow: 1.5,
    },
    {
      name: 'Accion',
      cell: row => (
        <div
          onClick={() => {
            selectedDoc(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.action}
        </div>
      ),
      selector: row => row.Status,
      sortable: true,
    },
    {
      name: 'Estado',
      cell: row => (
        <div
          onClick={() => {
            selectedDoc(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.Status}
        </div>
      ),
      selector: row => row.Status,
      sortable: true,
    }, {
      name: 'Formato',
      cell: row => (
        <div
          onClick={() => {
            selectedDoc(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.Format}
        </div>
      ),
      selector: row => row.Format,
      sortable: true,
    },
    {
      name: 'Formato Id',
      cell: row => (
        <div
          onClick={() => {
            selectedDoc(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.IdFormat_FK}
        </div>
      ),
      selector: row => row.IdFormat_PK,
      sortable: true,
    },
    {
      name: 'fecha creacion',
      cell: row => (
        <div
          onClick={() => {
            selectedDoc(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.dateCreation === null ? "pendiente" : row.dateCreation.substring(0, 10)}
        </div>
      ),
      selector: row => row.dateCreation,
      sortable: true,
    },
    {
      name: 'fecha cierre',
      cell: row => (
        <div
          onClick={() => {
            selectedDoc(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.dateFinish === null ? "pendiente" : row.dateFinish.substring(0, 10)}
        </div>
      ),
      selector: row => row.dateFinish,
      sortable: true,
    },


  ]

  return (
    <>
      <Row>
        <Col>

          <Row >
            <Col xs={9} className="d-flex ">
              <Form.Control
                type="text"
                id="search"
                placeholder="Búsqueda"
                aria-label="search input"
                value={search}
                onChange={OnFilter}
              />
              <Button data-tip="Limpiar Filtro" className="btn btn-success btn-sm" type="button" onClick={checkboxFilter}>
                <i className="bi bi-arrow-counterclockwise" />
              </Button>
            </Col>
            <Col className="d-flex pt-2">
              <Form.Check
                inline
                onChange={(e) => { handleChange(e) }}
                checked={cbFilterState.open ? true : false}
                id="pendiente"
                label="pendiente"
                name="open"
                type={"checkbox"}

              />
              <Form.Check
                inline
                onChange={(e) => { handleChange(e) }}
                checked={cbFilterState.close ? true : false}
                id="finalizado"
                label="finalizado"
                name="close"
                type={"checkbox"}

              />
            </Col>

          </Row>
          <Row>
            <DataTable
              fixedHeader={false}
              fixedHeaderScrollHeight={{ fixedHeaderScrollHeight: '100px' }}
              columns={columns}
              data={filterindItems}
              pagination
              responsive
              dense={dense}
              defaultSortFieldId="Id"
              defaultSortAsc={false}
              highlightOnHover
              pointerOnHover
              noDataComponent="No hay registros para mostrar"
              paginationComponentOptions={{
                rowsPerPageText: 'Filas por página:',
                rangeSeparatorText: 'de',
              }}
            />

          </Row>
        </Col>

      </Row>{showGHTFOR076 && <GTHFOR076
        show={showGHTFOR076}
        hide={hideGHTFOR076}
        data={data}
        isView={isView}
        isSign={isSign}
        setData={setData}
        sessionUser={sessionUser}
        IdFormat_PK={IdFormat_PK}
        workflow={wfSelected}
        restart={restart}
        setRestart={setRestart}

      />}
      {showGHTFORContract && <GTHFORContract
        show={showGHTFORContract}
        hide={hideGHTFORContract}
        data={data}
        isView={isView}
        isSign={isSign}
        setData={setData}
        sessionUser={sessionUser}
        IdFormat_PK={IdFormat_PK}
        workflow={wfSelected}
        restart={restart}
        setRestart={setRestart}
        list={list}

      />}
      {showGTHFOR070 && <GTHFOR070
        data={data}
        show={showGTHFOR070}
        hide={hideGTHFOR070}
        setData={setData}
        isTH={isTH}
        isApro={isApro}
        isRev={isRev}
        isSign={isSign}
        restart={restart}
        setRestart={setRestart}
        isView={isView}
        sessionUser={sessionUser}
        IdFormat_PK={IdFormat_PK}
        workflow={wfSelected}
        list={list}
      />}
      {showGTHFOR086 && <GTHFOR086
        data={data}
        setData={setData}
        show={showGTHFOR086}
        hide={hideGTHFOR086}
        isTH={isTH}
        isApro={isApro}
        isRev={isRev}
        isSign={isSign}
        restart={restart}
        sessionUser={sessionUser}
        setRestart={setRestart}
        isView={isView}
        IdFormat_PK={IdFormat_PK}
        workflow={wfSelected}
        list={list}
      />}
      {showCOMFORRequisiciones && <COMFORRequisiciones
        data={data}
        show={showCOMFORRequisiciones}
        hide={hideCOMFORRequisiciones}
        setData={setData}
        restart={restart}
        sessionUser={sessionUser}
        setRestart={setRestart}
        isView={isView}
        isSign={isSign}
        IdFormat_PK={IdFormat_PK}
        workflow={wfSelected}
        addQuotation={addQuotation}
        list={list}
      />}
      {showGTHFOR244 && <GTHFOR244
        data={data}
        setData={setData}
        show={showGTHFOR244}
        hide={hideGTHFOR244}
        isTH={false}
        isSign={isSign}
        restart={restart}
        sessionUser={sessionUser}
        setRestart={setRestart}
        isView={isView}
        IdFormat_PK={IdFormat_PK}
        workflow={wfSelected}
      />}
      {showOrden && <OrdenServ
        data={data}
        show={showOrden}
        hide={hideOrden}
        sessionUser={sessionUser}
        restart={restart}
        setRestart={setRestart}
        isSign={isSign}
        isView={isView}
        IdFormat_PK={IdFormat_PK}
        workflow={wfSelected}
      />}{showCOMFOR167 && <COMFOR167
        sessionUser={sessionUser}
        data={data}
        setData={setData}
        show={showCOMFOR167}
        hide={hideCOMFOR167}
        isSign={isSign}
        restart={restart}
        setRestart={setRestart}
        isView={isView}
        IdFormat_PK={IdFormat_PK}
        workflow={wfSelected}
      />}
      {showCOFIFOR159 && <COFIFOR159
        sessionUser={sessionUser}
        data={data}
        setData={setData}
        show={showCOFIFOR159}
        hide={hideCOFIFOR159}
        restart={restart}
        setRestart={setRestart}
        isView={true}
        IdFormat_PK={IdFormat_PK}
        isSign={isSign}
        list={list}
        workflow={wfSelected}
      />}
      {showCOFIFOR271 && <COFIFOR271 sessionUser={sessionUser} data={data} setData={setData} show={showCOFIFOR271}
        hide={hideCOFIFOR271} restart={restart} setRestart={setRestart} isView={true}
        IdFormat_PK={IdFormat_PK} isSign={isSign} list={list} workflow={wfSelected}
      />}
      {showCOFIFOR161 && <COFIFOR161 sessionUser={sessionUser} data={data} setData={setData} show={showCOFIFOR161}
        hide={hideCOFIFOR161} restart={restart} setRestart={setRestart} isView={true}
        IdFormat_PK={IdFormat_PK} isSign={isSign} list={list} workflow={wfSelected}
      />}
      {showInforme && <Inform
        data={data}
        show={showInforme}
        hide={hideInforme}
        isTH={isTH}
        sessionUser={sessionUser}
        isApro={isApro}
        isRev={isRev}
        isSign={isSign}
        restart={restart}
        setRestart={setRestart}
        isView={isView}
        IdFormat_PK={IdFormat_PK}
        workflow={wfSelected}
      />}
      {/* <MITFOR207
        data={data}
        setData={setData}
        show={showForm}
        hide={setShowForm}
        restart={restart}
        setRestart={setRestart}
        isView={isView}
        IdFormat_PK={IdFormat_PK}
      /> */}
    </>
  )
}

export default UserWorkflow
