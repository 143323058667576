

import {  useListContext } from '../../../hooks/custom';
import React, { useEffect, useState } from 'react';
import SelectSearch from 'react-select-search';
import Select from 'react-select';

export default function SelectOption({ type, state, setState, nameState, NotAv= false,  disabled, required, multi = false, widthSelect='100%',list,  placeHolder = "seleccione..." }) {
  /* <-----------------descripcion de props------------------
                type=> tipo de datos que va a  buscar city o nombre de type de la tabla st_mdata
                NotAv => agra la opcion N/A  a lalista
                state = nombre del estado que tiene el valor del campo 
                setState = funcion para cambio de estado
                nameState => nombre del subestado al que pertenece, si no pertenece a ninguno, el espacio va en blanco
                ej.  const [state, setState]= useState({nameState:value})
                multiple, se envia props en true si se requiere multiple
---------------------------------------------------------------------------------------------------------*/
   

  const { listData } = useListContext()
  const [cityState, setCityState] = useState()//disabled?listData.cities.filter(i=> i.value === parseInt(nameState?state[nameState]:state)) :listData.cities)
  const [multiData, setMultiData] = useState()
  const [disabledValue, setdisabledValue] = useState('')

 
  useEffect(() => {
    let valueInit =nameState?state[nameState]:state
    let data
    let newItem = []
   
    if (type === "custom"){
      setdisabledValue()
      setMultiData(list)
    
    }
    else if (type === "CeCo"){
      newItem={
        id: -1,
        name: 'N/A',
        value: 'N/A',
        type: undefined,
        Lider: 'N/A',
        NumeroContrato: 'N/A',
        users:'N/A' ,
        value:'N/A'}

     
      data = NotAv?[newItem , ...listData.CeCo]:listData.CeCo
      setMultiData(data)
      setdisabledValue(valueInit)
    }
      else if (type === "CIIU"){
        newItem = {
          id: undefined,
          name: 'N/A',
          value: 'N/A',
          type: 'N/A'
        }
      data = NotAv?[newItem , ...listData.CIIU]:listData.CIIU
      
      setMultiData(data)
  setdisabledValue(valueInit?data.filter(i=> i.value === valueInit)[0].name:'')
    }
    else if (type !== "city" && type !== "country") {
      data = listData.multidata.filter(i => i.type === type)
      if (multi) {
        data = JSON.parse(JSON.stringify(data).split('\"name\":').join('\"label\":'))     
      }   
      newItem={ id: -1, name: 'N/A', value: 'N/A', type: type }
      data = NotAv?[newItem , ...data]:data
      
      
      setMultiData(data)

      setdisabledValue(valueInit?data.filter(i=> i.value === valueInit)[0].name:'')
    }
    else if (type === "city") {      
    newItem= { state_id: '-1', name: 'N/A', value: 'N/A' }    
    data = NotAv?[newItem , ...listData.cities]:listData.cities
    setCityState(data)
    setdisabledValue(valueInit?data.filter(i=> i.value === `${valueInit}`)[0].name:'')

  }

  }, [list])




  const handleChange = (e) => {
    
    if (multi) {
      const labels = [];
      for (const obj of e) {
        labels.push(obj.label);
      }
      setState(labels)
    }
    else if (!nameState) {
      setState(e)
    }
    else {
      setState({ ...state, [nameState]: e })
    }


  }
  return (


    <div className="filter-select-container" style={{ minWidth: widthSelect, width: widthSelect }}>
      {type === "country" && (<SelectSearch
        onChange={e => { handleChange(e) }}
        options={listData.countries}
        value={!nameState ? state : state[nameState]}
        placeholder="busque el Pais"
        search
        required={required}
        disabled={disabled}
        closeOnSelect />)}
  
        { type === "city" && (disabled?<input style={{borderBottom: '0px', background:'white'}} value={disabledValue}  disabled={true}/>:
        <SelectSearch
          
          onChange={e => { handleChange(e); }}
          options={cityState}
          value={!nameState ? state : state[nameState]}
          placeholder="seleccione la ciudad"
          search
          required={required}
          disabled={disabled}
          closeOnSelect />)}  
      {(type !== "city" && type !== "country" && !multi) && (disabled?<input style={{borderBottom: '0px', background:'white'}} value={disabledValue} disabled={true}/>:
        <SelectSearch
          onChange={e => { handleChange(e); }}
          options={multiData}
          value={!nameState ? state : state[nameState]}
          placeholder={placeHolder}
          search

          required={required}
          disabled={disabled}
          closeOnSelect
        />)}
      {(type !== "city" && type !== "country" && multi) && (disabled?<input style={{borderBottom: '0px', background:'white'}}  value={disabledValue} disabled={true}/>:
        <Select 
          onChange={e => { handleChange(e); }}
          isMulti
          name="multi"
          options={multiData}
          className="basic-multi-select"
          classNamePrefix="select"
          placeholder={placeHolder}
        />)}
    </div>

  )
}

