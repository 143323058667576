import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { CreateFormat, getFormatsById, updateFormat } from './request'
import { getUniqueList, getFullData } from '../request'
import { Button, Col, Form, Row } from 'react-bootstrap'
import Moment from 'moment';
import { toast } from 'react-toastify'
import { EqTable } from '../../logistic/EqTable'





export const MITFOR207 = ({ sessionUser, show, hide, restart, setRestart, isView, data='', setData, IdFormat_PK }) => {
  //-------------------------Info Formato   
  const formato = 'MIT-FOR-207'
  const revision = '1'
  const fechaRev = '13/09/2023'
  const nombreFormato = "HOJA DE VIDA EQUIPOS DE INSPECCION, MEDICION Y ENSAYO"
  const typef = 'MITFOR207'

  //-------------------------Registros 
  const [status, setStatus] = useState('')
  const [currentUser, setCurrentUser] = useState(sessionUser)
  const [date, setDate] = useState('')
  const [accesorios, setAccesorios] = useState('')
  const [calibraciones, setCalibraciones] = useState('')
  const [reparaciones, setReparaciones] = useState('')
  const [LCC, setLCC] = useState('')



  useEffect(() => {
    if (!restart) {
      setStatus('solicitado')
      setRestart(!restart)
      if (show)
        getInfo()

    }
  }, [restart])

  const handleChange = async ({ target }) => {
    const { name, type } = target
    const value = type === 'checkbox' ? target.checked : target.value
    setData({ ...data, [name]: value })

  }

  const getInfo = async () => {

    const formatDate = Moment().format('YYYY-MM-DD')
    setDate(formatDate)


      setLCC( data.EqGestionCambio===undefined || data.EqGestionCambio===null?[] :JSON.parse(data.EqGestionCambio))
      setAccesorios( data.EqAccesorios===undefined || data.EqAccesorios===null?[] : JSON.parse(data.EqAccesorios))
      setCalibraciones( data.EqCalibraciones===undefined || data.EqReparaciones===null ?[] :JSON.parse(data.EqCalibraciones))
      setReparaciones( data.EqReparaciones===undefined || data.EqReparaciones===null ?[] : JSON.parse(data.EqReparaciones))
    return true
  }

  const clearStates = () => {
    setData('')
    setCurrentUser('')
    setDate('')
    setAccesorios('')
    setCalibraciones('')
    setReparaciones('')
    setLCC('')

  }


  const onSubmit = async e => {
    e.preventDefault()
    var temp = ''
    var result = ''

    temp = data
    temp['EqAccesorios'] = JSON.stringify(accesorios)
    temp['EqReparaciones'] = JSON.stringify(reparaciones)
    temp['EqCalibraciones'] = JSON.stringify(calibraciones)
    temp['EqGestionCambio'] = JSON.stringify(LCC)
    if (IdFormat_PK === 'en creacion') {
      temp['log'] = JSON.stringify([{ date: new Date(), name: currentUser.NombreTrabajador, lastname: currentUser.ApellidoTrabajador, action: "creation", duty: currentUser.cargo }])
      temp['Status'] = 'Disponilble'
      result = await CreateFormat(temp, typef)

    } else {

      temp['IdFormat_PK'] = JSON.stringify(IdFormat_PK)
      temp['log'] = JSON.stringify({ date: new Date(), name: currentUser.NombreTrabajador, lastname: currentUser.ApellidoTrabajador, action: "uptade", duty: currentUser.cargo })
      result = await updateFormat(temp, typef)
    }
    setData(temp)

    if (result.data.status === 200) {
      toast.success(result.data.msj);
    } else {
      toast.error(result.data.msj);
    }
    clearStates()
    hide(!show)
    setRestart(!restart)
  }

  return (
    <>
      {show && (
        <Overlay style={{ zIndex: '1' }} >
          <ModalContainer>
            <ModalHeader>
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <th style={{ width: '20%', verticalAlign: 'bottom' }} rowSpan={3}>
                      NIT 830.507.705-2
                    </th>
                    <th style={{ width: '55%', height: '38px' }}>RELIABILITY MAINTENANCE SERVICES S.A.S</th>
                    <th style={{ width: '25%' }}>{formato}</th>
                  </tr>
                  <tr>
                    <th rowSpan={2}>{nombreFormato}</th>
                    <th style={{ fontSize: '10px' }}>Revisión: {revision}</th>
                  </tr>
                  <tr>
                    <th style={{ fontSize: '10px' }}>Fecha de revisión: {fechaRev}</th>
                  </tr>
                </tbody>
              </table>
            </ModalHeader>
            <CloseButtonModal onClick={() =>{ hide(!show)
              clearStates()
      
            }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-x-square"
                viewBox="0 0 16 16"
              >
                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </CloseButtonModal>
            <table>
              <tbody>
                <tr>
                  <th style={{ width: '55%', textAlign: 'right' }}></th>
                  <th style={{ width: '15%', textAlign: 'right' }}>
                    <label>Equipo   No.</label>
                  </th>
                  <td style={{ width: '25%', background: '#FFF' }}>
                    <label>{data.IdFormat_PK}</label>
                  </td>
                </tr>
              </tbody>
            </table>

            <Form onSubmit={onSubmit}>
              <Frame isView={isView}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <th className="rms" colSpan={4} style={{ width: '100%' }}>
                        <h2 className="text-center">A. INFORMACIÓN GENERAL </h2>
                      </th>
                    </tr>
                    <tr>
                      <td style={{ width: '22%' }}><label>DENOMINACIÓN EQUIPO:</label></td>
                      <td style={{ width: '28%' }}><input name='EqDenominacion' value={data.EqDenominacion} onChange={handleChange} required disabled={isView ? true : false} /></td>
                      <th className="photo" colSpan={2} style={{ width: '50%' }}><h2 className="text-center">IMAGEN DEL EQUIPO </h2></th>
                    </tr>
                    <tr>
                      <td ><label>IDENTIFIACIÓN INTERNA/TAG NO.:</label></td>
                      <td ><input name='EqIdInterna' value={data.EqIdInterna} onChange={handleChange} required disabled={isView ? true : false} /></td>
                      <th rowSpan={11} colSpan={2} style={{ width: '50%' }}></th>
                    </tr>
                    <tr>
                      <td ><label>IDENTIFIACIÓN (SERIAL):</label></td>
                      <td ><input name='EqSerial' value={data.EqSerial} onChange={handleChange} required disabled={isView ? true : false} /></td>
                    </tr>
                    <tr>
                      <td ><label>MARCA:</label></td>
                      <td ><input name='EqMarca' value={data.EqMarca} onChange={handleChange} required disabled={isView ? true : false} /></td>
                    </tr>
                    <tr>
                      <td ><label>MODELO:</label></td>
                      <td ><input name='EqModelo' value={data.EqModelo} onChange={handleChange} required disabled={isView ? true : false} /></td>
                    </tr>
                    <tr>
                      <td ><label>TIPO:</label></td>
                      <td ><input name='EqTipo' value={data.EqTipo} onChange={handleChange} required disabled={isView ? true : false} /></td>
                    </tr>
                    <tr>
                      <td ><label>SUMINISTRO POTENCIA:</label></td>
                      <td ><input name='EqSuministroPotencia' value={data.EqSuministroPotencia} onChange={handleChange} required disabled={isView ? true : false} /></td>
                    </tr>
                    <tr>
                      <td ><label>FRECUENCIA OPERACIÓN:</label></td>
                      <td ><input name='EqFrecOp' value={data.EqFrecOp} onChange={handleChange} required disabled={isView ? true : false} /></td>
                    </tr>
                    <tr>
                      <td ><label>FECHA DE ADQUISICIÓN:</label></td>
                      <td ><input data-provide="datepicker" type={'date'} name='EqFechaAdquisicion' value={data.EqFechaAdquisicion} onChange={handleChange} required disabled={isView ? true : false} /></td>
                    </tr>
                    <tr>
                      <td ><label>FECHA PUESTA EN SERVICIO:</label></td>
                      <td ><input data-provide="datepicker" type={'date'} name='EqFechaEnServicio' value={data.EqFechaEnServicio} onChange={handleChange} required disabled={isView ? true : false} /></td>
                    </tr>
                    <tr>
                      <td ><label>AREA/SECCIÓN DE USO:</label></td>
                      <td ><input name='EqAreaSeccionUso' value={data.EqAreaSeccionUso} onChange={handleChange} required disabled={isView ? true : false} /></td>
                    </tr><tr>
                      <td ><label>ESTADO INSTRUMENTO:</label></td>
                      <td ><input list='estado' name='EqEstadoInst' value={data.EqEstadoInst} onChange={handleChange} required disabled={isView ? true : false} /></td>
                    </tr>
                    <tr>
                      <td ><label>CAMPO/PROCESO:</label></td>
                      <td ><input name='EqCampoProceso' value={data.EqCampoProceso} onChange={handleChange} required disabled={isView ? true : false} /></td>
                      <td style={{ width: '20%' }} ><label>MANUAL DE OPERACIÓN:</label></td>
                      <td ><Row>
                        <Col>
                          <label style={{ width: '25px', right: '10px' }}>
                            <input
                              style={{ width: '20px' }}
                              type={'radio'}
                              name={'EqManualOp'}
                              value={'Si'}
                              checked={data['EqManualOp'] === 'Si'}
                              onChange={handleChange}
                              required
                              disabled={isView ? true : false}
                            />
                            Si
                          </label>
                        </Col>
                        <Col>
                          <label style={{ width: '25px', right: '10px' }}>
                            <input
                              style={{ width: '20px' }}
                              type={'radio'}
                              name={'EqManualOp'}
                              value={'No'}
                              checked={data['EqManualOp'] === 'No'}
                              onChange={handleChange}
                              required
                              disabled={isView ? true : false}
                            />
                            No
                          </label>
                        </Col>
                      </Row></td>
                    </tr>
                    <tr>
                      <td ><label>APLICACIÓN ESPECÍFICA:</label></td>
                      <td colSpan={3}><textarea style={{ width: '100%' }} name='EqAplicEspecifica' value={data.EqAplicEspecifica} onChange={handleChange} required disabled={isView ? true : false} /></td>
                    </tr><tr>
                      <td ><label>TIEMPO EN MISIÓN:</label></td>
                      <td ><input name='EqTiempoEnMision' type={'number'} value={data.EqTiempoEnMision} onChange={handleChange} required disabled={isView ? true : false} /></td>
                      <td ><label>COSTO DEL EQUIPO:</label></td>
                      <td ><input name='EqCosto' type={'number'} value={data.EqCosto} onChange={handleChange} required disabled={isView ? true : false} /></td>
                    </tr>
                    <tr>
                      <td ><label>CUSTODIO:</label></td>
                      <td ><input name='EqCustodio' value={data.EqCustodio} onChange={handleChange} required disabled={isView ? true : false} /></td>
                    </tr>
                  </tbody>
                </table>
              </Frame>

              <Frame isView={isView}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <th colSpan={4} className="rms" style={{ width: '20%' }}>
                        <h2 className="text-center">B. INFORMACIÓN DEL PROVEEDOR </h2>
                      </th>
                    </tr>
                    <tr>
                      <td style={{ width: '22%' }}><label>RAZÓN SOCIAL:</label></td>
                      <td colSpan={3}><input name='EqProvNombre' value={data.EqProvNombre} onChange={handleChange} required disabled={isView ? true : false} /></td>

                    </tr>
                    <tr>
                      <td ><label>DIRECCIÓN:</label></td>
                      <td ><input name='EqProvDireccion' value={data.EqProvDireccion} onChange={handleChange} required disabled={isView ? true : false} /></td>
                      <td ><label>CIUDAD:</label></td>
                      <td ><input name='EqProvCiudad' value={data.EqProvCiudad} onChange={handleChange} required disabled={isView ? true : false} /></td>
                    </tr>
                    <tr>
                      <td ><label>TEL/FAX:</label></td>
                      <td ><input name='EqProvTel' value={data.EqProvTel} onChange={handleChange} required disabled={isView ? true : false} /></td>
                      <td ><label>E-MAIL:</label></td>
                      <td ><input name='EqProvEmail' value={data.EqProvEmail} onChange={handleChange} required disabled={isView ? true : false} /></td>
                    </tr>
                    <tr>
                      <td ><label>CONTACTO:</label></td>
                      <td ><input name='EqProvContacto' value={data.EqProvContacto} onChange={handleChange} required disabled={isView ? true : false} /></td>
                      <td ><label>CARGO:</label></td>
                      <td ><input name='EqProvCargo' value={data.EqProvCargo} onChange={handleChange} required disabled={isView ? true : false} /></td>
                    </tr>
                    <tr>
                      <td>
                        <label>
                          <input
                            style={{ width: '20px', right: '30px' }}
                            type={'checkbox'}
                            name={'EqProvFrabricante'}
                            checked={data['EqProvFrabricante']}
                            onChange={handleChange}

                            disabled={isView ? true : false}
                          />
                          FABRICANTE
                        </label>
                      </td>
                      <td >
                        <label>
                          <input
                            style={{ width: '20px', right: '30px' }}
                            type={'checkbox'}
                            name={'EqProvDistribuidor'}
                            checked={data['EqProvDistribuidor']}
                            onChange={handleChange}

                            disabled={isView ? true : false}
                          />
                          DISTRIBUIDOR
                        </label>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Frame>
              <Frame isView={isView}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <th colSpan={8} className="rms" style={{ width: '20%' }}>
                        <h2 className="text-center">C.  CARACTERÍSTICAS METROLÓGICAS, DATOS TÉCNICOS Y ACCESORIOS</h2>
                      </th>
                    </tr>
                    <tr>
                      <td colSpan={2} style={{ width: '22%' }}><label>MAGNITUD(ES)  A MEDIR :</label></td>
                      <td colSpan={6}><input name='EqCarMagAMedir' value={data.EqCarMagAMedir} onChange={handleChange} required disabled={isView ? true : false} /></td>

                    </tr>
                    <tr>
                      <td colSpan={2} style={{ width: '25%' }}><label>SPAN:</label></td>
                      <td colSpan={2} style={{ width: '30%' }}><input name='EqCarSpan' value={data.EqCarSpan} onChange={handleChange} required disabled={isView ? true : false} /></td>
                      <td colSpan={2} style={{ width: '22%' }}><label>RESOLUCIÓN:  	</label></td>
                      <td colSpan={2} style={{ width: '25%' }}><input name='EqCarResolucion' value={data.EqCarResolucion} onChange={handleChange} required disabled={isView ? true : false} /></td>
                    </tr>
                    <tr>
                      <td colSpan={2}><label>UNIFORMIDAD:	</label></td>
                      <td colSpan={2}><input name='EqCarUniformidad' value={data.EqCarUniformidad} onChange={handleChange} required disabled={isView ? true : false} /></td>
                      <td colSpan={2}><label>PRECISIÓN:</label></td>
                      <td colSpan={2}><input name='EqCarPrecision' value={data.EqCarPrecision} onChange={handleChange} required disabled={isView ? true : false} /></td>
                    </tr>
                    <tr>
                      <td ><label>CATEGORÍA:</label></td>
                      <td ><input name='EqCarCategoria' value={data.EqCarCategoria} onChange={handleChange} required disabled={isView ? true : false} /></td>
                      <td ><label>CLASE/DIV.:</label></td>
                      <td ><input name='EqCarClaseDiv' value={data.EqCarClaseDiv} onChange={handleChange} required disabled={isView ? true : false} /></td>
                      <td colSpan={2}><label>EXACTITUD/ERROR:</label></td>
                      <td colSpan={2}><input name='EqCarExactitudError' value={data.EqCarExactitudError} onChange={handleChange} required disabled={isView ? true : false} /></td>
                    </tr>
                    <tr>
                      <td colSpan={2}  ><label>CALIBRACIÓN:</label></td>
                      <td >
                        <label style={{ width: '25px', right: '10px' }}>
                          <input
                            style={{ width: '20px' }}
                            type={'radio'}
                            name={'EqCarCalibracion'}
                            value={'INTERNA'}
                            checked={data['EqCarCalibracion'] === 'INTERNA'}
                            onChange={handleChange}
                            required
                            disabled={isView ? true : false}
                          />
                          INTERNA
                        </label>
                      </td>
                      <td>
                        <label style={{ width: '25px', right: '10px' }}>
                          <input
                            style={{ width: '20px' }}
                            type={'radio'}
                            name={'EqCarCalibracion'}
                            value={'EXTERNA'}
                            checked={data['EqCarCalibracion'] === 'EXTERNA'}
                            onChange={handleChange}
                            required
                            disabled={isView ? true : false}
                          />
                          EXTERNA
                        </label>
                      </td>
                      <td colSpan={2}><label>APTITUD SIL:</label></td>
                      <td colSpan={2}><input name='EqCarAptitudSIL' value={data.EqCarAptitudSIL} onChange={handleChange} required disabled={isView ? true : false} /></td>

                    </tr>
                    <tr>
                      <td colSpan={2} ><label>FECHA DE CALIBRACION INICIAL:</label></td>
                      <td colSpan={2} ><input data-provide="datepicker" type={'date'} name='EqCarFechaCalIni' value={data.EqCarFechaCalIni} onChange={handleChange} required disabled={isView ? true : false} /></td>
                      <td colSpan={2}><label>LUGAR DE CALIBRACIÓN INICIAL:</label></td>
                      <td colSpan={2}><input name='EqCarLugarCalIni' value={data.EqCarLugarCalIni} onChange={handleChange} required disabled={isView ? true : false} /></td>
                    </tr>
                    <tr>
                      <td colSpan={2} style={{ width: '25%' }}><label>CERTIFICADO CALIBR. INICIAL No:</label></td>
                      <td colSpan={2} style={{ width: '30%' }}><input name='EqCarCertCal' value={data.EqCarCertCal} onChange={handleChange} required disabled={isView ? true : false} /></td>
                      <td colSpan={2} style={{ width: '22%' }}><label>FREC. DE CALIBRACIÓN INICIAL:  	</label></td>
                      <td colSpan={2} style={{ width: '25%' }}><input name='EqCarFrecCal' value={data.EqCarFrecCal} onChange={handleChange} required disabled={isView ? true : false} /></td>
                    </tr>
                  </tbody>
                </table>
              </Frame>
              <Frame isView={isView}>
                <table style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th colSpan={8} className="rms" style={{ width: '20%' }}>
                        <h2 className="text-center">ACCESORIOS  Y OBSERVACIONES</h2>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan={8} style={{ width: '23%' }} > <EqTable setData={setAccesorios} data={accesorios} tableType={'Accessory'} isView={isView}></EqTable></td>
                    </tr>
                    <tr>
                      <td style={{ width: '23%' }} ><label>OBSERVACIONES:</label></td>
                      <td colSpan={2}><textarea style={{ width: '100%' }} name='EqObservaciones' value={data.EqObservaciones} onChange={handleChange} required disabled={isView ? true : false} /></td>
                    </tr>
                  </tbody>
                </table>
              </Frame>
              <Frame isView={isView}>
                <table style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th colSpan={6} className="rms" style={{ width: '20%' }}>
                        <h2 className="text-center">D.  CALIBRACIONES</h2>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan={8} style={{ width: '23%' }} > <EqTable setData={setCalibraciones} data={calibraciones} tableType={'Calibration'} isView={isView}></EqTable></td>
                    </tr>

                  </tbody>
                </table>
              </Frame>
              <Frame isView={isView}>
                <table style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th colSpan={6} className="rms" style={{ width: '20%' }}>
                        <h2 className="text-center">E.  REPARACIÓN Y MANTENIMIENTO</h2>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan={8} style={{ width: '23%' }} > <EqTable setData={setReparaciones} data={reparaciones} tableType={'Maintenance'} isView={isView}></EqTable></td>
                    </tr>

                  </tbody>
                </table>
              </Frame>
              <Frame isView={isView}>
                <table style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th colSpan={6} className="rms" style={{ width: '20%' }}>
                        <h2 className="text-center">F.  CICLO DE VIDA Y GESTION DEL CAMBIO</h2>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td ><label>VIDA UTIL ESTIMADA:</label></td>
                      <td ><input name='EqVidaUtil' type={'number'} value={data.EqVidaUtil} onChange={handleChange} required disabled={isView ? true : false} /></td>
                      <td ><label>DISPOSICION (UNA VEZ CUMPLIDA SU VIDA UTIL):</label></td>
                      <td ><input name='EqDispocision' value={data.EqDispocision} onChange={handleChange} required disabled={isView ? true : false} /></td>
                    </tr>
                    <tr>
                      <td colSpan={8} style={{ width: '23%' }} > <EqTable setData={setLCC} data={LCC} tableType={'LCC'} isView={isView}></EqTable></td>
                    </tr>

                  </tbody>
                </table>
              </Frame>
              <Row>
                <Col></Col>
                <Col></Col>
                <Col></Col>
                <Col></Col>
                {!isView ? (
                  <>
                    <Col>
                      <Button
                        className="btn btn-danger"
                        onClick={() => {
                          clearStates()
                          hide(!show)
                        }}
                      >
                        Cancelar
                      </Button>
                    </Col>
                    <Col>
                      <Button type="submit" className="btn btn-success">
                        Guardar
                      </Button>
                    </Col>
                  </>
                ) : (
                  <Col>
                    <Button
                      className="btn btn-secundary"
                      onClick={() => {
                        clearStates()
                        hide(!show)
                      }}
                    >
                      Cerrar
                    </Button>
                  </Col>
                )}
              </Row>

            </Form>
          </ModalContainer>
        </Overlay>
      )}
    </>
  )
}

export default MITFOR207

const Frame = styled.div`
  width: 100%;
  padding-bottom: 0px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: left;
  border-width: 2px;
  border-color: #1a004a;
  border-style: solid;
  font-size: 12px;
  font-weight: 100;
  .photo{
    background:  #ebf1de
  }
  .rms{
    background:  #44bb7a
  }
  label {
    padding-left: 5px;
    display: flex;
    font-size: 12px;
    font-weight: NORMAL;
    text-align: 'center'
  }
  input {
    text-align: center;
    width: 100%;
    border-bottom: 1px solid black;
    border-color: #1a004a;
    background: ${props => (props.isView ? '#FFF' : '#cee1ed')};

    padding-left: 5px;
    margin-top: 5px;
  }
  textarea {
    text-align: justify;
    width: 100%;
    border-bottom: 1px solid black;
    border-color: #1a004a;
    background: ${props => (props.isView ? '#FFF' : '#cee1ed')};

    padding-left: 5px;
    margin-top: 5px;
  }

  input.edit {
    background: #cee1ed;
  }
  input.disable {
    background: #fff;
  }
  h2 {
    font-size: 14px;
    font-weight: bold;
    text-align: 'center'

  }
 
  th {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    text-align: left;
    vertical-align: center;
    padding-left: 5px;

    input {
      width: 100%;
    }
  }
  tr {
    border-width: 0px;
    border-color: #1a004a;
    border-style: solid;
  }
`

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgb(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalContainer = styled.div`
  width: 1000px;
  height: 90vh;
  min-height: 500px;
  background: #fff;
  position: relative;
  border-radius: 5px;
  overflow: scroll;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
`

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
  padding-bottom: 10px;

  th {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
  }
  tr {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
  }
  table {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    text-align: center;
    vertical-align: center;
    font-size: 14px;
  }
  h3 {
    font-weight: 500;
    font-size: 16px;
    color: #1766dc;
  }
`

const CloseButtonModal = styled.div`
  position: absolute;
  top: 2px;
  right: 5px;
  width: 20px;
  height: 20px;
  border: none;
  background: none;
  cursor: pointer;
  transition: 0.3s ease all;
  border-radius: 5px;
  color: #1766dc;
  &:hover {
    background: #f2f2f2;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`
