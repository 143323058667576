import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios'

import RouterApp from './routes/RouterApp'
import RouterAuth from './routes/RouterAuth'
import ApiURL from './services/apirest'
import { ListContextProvider } from './context/ListContext'
import { UserContextProvider } from './context/userContext'


const initialState = {
  userSignIn: false,
  isReady: false,
  IdUser_PK: '',
  TOKEN: '',
}

const App = () => {
  const [state, setState] = useState(initialState)

  const setConfig = useCallback(async () => {
    const headers = {
      headers: { 'x-access-token': localStorage.getItem('TOKEN') },
    }
    const { data } = await axios.post(ApiURL + 'auth/isSignin', {}, headers) //Hacer petición HTTP
    if (data.status === 200) {
      setState({ ...initialState, userSignIn: true })
    }
  }, [])


  useEffect(() => {
    setConfig()
  }, [setConfig])



  const navigationSelect = () => {
    switch (state.userSignIn) {
      case true:
        return <ListContextProvider>
          <UserContextProvider>
          <RouterApp /></UserContextProvider>
          </ListContextProvider>
      case false:
        return <RouterAuth />
      default:
        return <RouterAuth />
    }
  }



  return (<div>{navigationSelect()}</div>

  )
}
export default App
