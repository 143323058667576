import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import {  sessionActive } from '../../lib/functions'
import { Col, Row, Button, Form, Modal } from 'react-bootstrap'
import COMFORService from '../../lib/formatos/COMFORService'
import { getFormats, updateFormat } from '../../lib/formatos/request'
import { getEmployeeF } from '../../humantalent/HumanTalent.request'
import SpinnerLoad from  '../../lib/spinnerLoad'


export const Services = props => {
  const [restart, setRestart] = useState(false)
  const [IdFormat_PK, setIdFormat_PK] = useState('')
  const [showForm, setShowForm] = useState(false)
  const [isView, setIsView] = useState(false)
  const [ModalStatus, setModalStatus] = useState(false)
  const [idDelete, setIdDelete] = useState()
  const [search, setSearch] = useState()
  const [data, setData] = useState()
  const [filterindItems, setFilterindItems] = useState([])
  const [ModalDelete, setModalDelete] = useState(false)
  const [list, setList] = useState()

  const typef = 'COMFORServicios'

  const fillTable = async () => {
    //Obtener lista de equipos
    let resFormats = await getFormats('MITFOR207')
    
    //Obtener lista de empleados
    const resEmployees = await getEmployeeF('EC')

    if(resEmployees.data.status===200){
      setList({
        eqList:resFormats.data.data.map(i =>{ return {...i,value:i.IdFormat_PK,  name: i.IdFormat_PK + ' - ' +i.EqMarca + ' - ' +i.EqModelo + ' - ' +i.EqSerial + ' - ' +i.EqTipo + ' - ' +i.EqDenominacion }})
         ,
        personalList:resEmployees.data.data.map(
          i =>{ return {...i, value:i.IdFormat_PK,  name: i.IdEmployee_FK + ' - ' + i.NombreTrabajador + ' ' + i.ApellidoTrabajador + ' - ' + i.cargo + ' - ' + i.areaTrabajo + ' - ' + i.CeCo
        }}
          )
      })}else toast.error('usuario no tiene permisos para consultar empleados')
      
    //Obtener lista de servicios
    resFormats = await getFormats(typef)
    if (resFormats.data.status === 200) {
      setServices(resFormats.data.data)
      setFilterindItems(resFormats.data.data)
    } else {
      toast.error(resFormats.data.msj)
    }
  }

  const [services, setServices] = useState()

  useEffect(() => {
    sessionActive()
    fillTable()

  }, [restart])

  const OnFilter = e => {
    let FilterText = e.target.value
    setSearch(FilterText)
    setFilterindItems(
      services.filter(
        item =>
          item.name.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.discipline.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.area.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.description.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.Status.toLowerCase().includes(FilterText.toLowerCase())
      )
    )
  }

  const ClearSearch = () => {
    setSearch('')
    setFilterindItems(services)
  }

  const handleChange = (data) => {
    data.EqTable = JSON.parse(data.EqTable)
    data.PersonalTable = JSON.parse(data.PersonalTable)
    setData(data)
    setShowForm(!showForm)
    setRestart(!restart)
    setIsView(true)
    setIdFormat_PK(data.IdFormat_PK)


  }
  const editRow = row => {
    if (props.put && row.Status!=="Activo") {
      return true

    }
    return false
  }
  const deleteRow = row => {
    if (props.delete && row.Status!=="Activo") {
      return true
    }
    return false
  }
  const onSubmit = async e => {
    setModalStatus(false)
    setModalDelete(false)
    e.preventDefault()
    const { accessKey, id } = e.target
    const data = { IdFormat_PK: accessKey, Status: id }
    const res = await updateFormat(data, typef)
    if (res.data.status === 200) {
      toast.success(res.data.msj)
    }
    else {
      toast.error(res.data.msj);
    }
    setRestart(!restart)



  }
  const columns = [
    {
      name: ' id',
      cell: row => (<div onClick={() => { handleChange(row) }} data-tag="allowRowEvents" >   {row.IdFormat_PK}  </div>),
      selector: row => row.IdFormat_PK,
      sortable: true,
      grow:0.05
    },
    {
      name: 'Nombre',
      cell: row => (<div onClick={() => { handleChange(row) }} data-tag="allowRowEvents" >   {row.name}  </div>),
      selector: row => row.name,
      sortable: true,
      grow:2.5
    },
    {
      name: 'Disciplina',
      cell: row => (<div onClick={() => { handleChange(row) }} data-tag="allowRowEvents" >   {row.discipline}  </div>),
      selector: row => row.discipline,
      sortable: true,
      grow:0.6
    },
    {
      name: 'Área',
      cell: row => (<div onClick={() => { handleChange(row) }} data-tag="allowRowEvents" >   {row.area}  </div>),
      selector: row => row.area,
      sortable: true,
      grow:0.6
    },
    {
      name: 'Descripción',
      cell: row => (<div onClick={() => { handleChange(row) }} data-tag="allowRowEvents" >   {row.description}  </div>),
      selector: row => row.description,
      sortable: true,
      grow:1.8
    },
    {
      name: 'Estado',
      cell: row => (<div onClick={() => { handleChange(row) }} data-tag="allowRowEvents" >   {(row.Status)}  </div>),
      selector: row => row.Status,
      sortable: true,
      grow:0.5
    }, {
      name: 'Acciones',
      cell: row => (
        <div className="tabla dropdown dropstart">
          <i
            className="bi bi-three-dots-vertical"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          />
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <Link
                onClick={() => {
                  row.EqTable = JSON.parse(row.EqTable)
                  row.PersonalTable = JSON.parse(row.PersonalTable)
                  setData(row)
                  setShowForm(!showForm)
                  setRestart(!restart)
                  setIsView(false)
                  setIdFormat_PK(row.IdFormat_PK)
                }}
                className={'dropdown-item text-center border-end' + (editRow(row) ? '' : ' disabled')}
                to="/operations"
              >
                <i className="bi bi-pen me-3 "></i>Editar
              </Link>
            </li>
            {row.Status !== "sActivo" && <li>
              <Link
                className={'dropdown-item text-center border-end' + (editRow(row) ? '' : ' disabled')}
                to="/operations"
                onClick={() => {
                  setIdDelete(row.IdFormat_PK)
                  setRestart(!restart)
                  setModalStatus(row.Status === "Disponible" ? "Inactivar" : "Activar")
                }}
              >
                {row.Status === "Disponible" ? <><i className="bi bi-lightbulb-off me-3"></i>Inactivar</> : <><i className="bi bi-lightbulb me-3"></i>Activar</>}
              </Link>
            </li>    }  
                  <li>
              <Link
                className={'dropdown-item text-center border-end' + (deleteRow(row) ? '' : ' disabled')}
                to="/operations"
                onClick={() => {
                  setIdDelete(row.IdFormat_PK)
                  setRestart(!restart)
                  setModalDelete(true)
                }}
              >
                <i className="bi bi-trash me-3"></i>Borrar
              </Link>
            </li>
          </ul>
        </div>
      ),
      button: true,
    }

  ]


  return (
    <div>
      <Row>
        <Col>
          {props.newBotton && (
            <Button
              variant="success"
              className="m-3 p-2 rounded-pill btn-sm ms-1"
              disabled={props.post ? false : true}
              onClick={() => {
                setData({Status:'en creacion'})
                setShowForm(!showForm)
                setRestart(!restart)
                setIsView(false)
                setIdFormat_PK('en creacion')
              }}
            >
              <i className="bi bi-wrench-adjustable mx-2" />
              Crear Servicio{''}
            </Button>
          )}
          {showForm && 
          <COMFORService 
          sessionUser={props.sessionUser} 
          hide={setShowForm} data={data} 
          setData={setData} isView={isView} 
          list={list}
          show={showForm} 
          restart={restart} 
          setRestart={setRestart} />}
          <Col className="d-flex">
            <Form.Control
              type="text"
              id="search"
              placeholder="Búsqueda"
              aria-label="search input"
              value={search}
              onChange={OnFilter}
            />
            <Button data-tip="Limpiar Filtro" className="btn btn-success btn-sm" type="button" onClick={ClearSearch}>
              <i className="bi bi-arrow-counterclockwise" />
            </Button>
          </Col>
          <Row>
            <SpinnerLoad trigger={services} />
            <DataTable
              fixedHeader={false}
              fixedHeaderScrollHeight={{ fixedHeaderScrollHeight: '100px' }}
              columns={columns}
              data={filterindItems}
              pagination
              responsive
              dense={props.dense}
              highlightOnHover
              pointerOnHover
              noDataComponent="No hay registros para mostrar"
              paginationComponentOptions={{
                rowsPerPageText: 'Filas por página:',
                rangeSeparatorText: 'de',
              }}
            />
          </Row>
        </Col>
      </Row>

      <Modal
        show={ModalDelete}
        onHide={() => {
          setModalDelete(false)
        }}
        centered
      >
        <Modal.Header closeButton>
          <h5>Eliminar Solicitud</h5>
        </Modal.Header>
        <Modal.Body>
          <p>¿Realmente desea eliminar el servicio {idDelete}?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-danger  "
            onClick={() => {
              setModalDelete(false)
            }}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            className="btn btn-secundary  "
            id={"delete"}
            accessKey={idDelete}
            onClick={onSubmit}
          >
            Eliminar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={ModalStatus === "Inactivar" || ModalStatus === "Activar"}
        onHide={() => {
          setModalStatus(false)
        }}
        centered
      >
        <Modal.Header closeButton>
          <h5>{ModalStatus} Servicio</h5>
        </Modal.Header>
        <Modal.Body>
          <p>¿Realmente desea {ModalStatus} el servicio {idDelete}?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-danger  "
            onClick={() => {
              setModalStatus(false)
            }}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            className="btn btn-secundary  "
            id={ModalStatus === "Inactivar" ? "Inactivo" : "Disponible"}
            accessKey={idDelete}
            onClick={onSubmit}
          >
            {ModalStatus}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Services
