import axios from 'axios'
import ApiURL from '../../../../services/apirest'

export const CreateFormat = async (data, type) => {
  const config = {
    headers: { 'x-access-token': localStorage.getItem('TOKEN') },
  }
  const datos = {
    type,
    data,
  }
  const res = await axios.post(ApiURL + 'formats', datos, config)
  return res
}

export const updateFormat = async (data, type) => {
  const config = {
    headers: { 'x-access-token': localStorage.getItem('TOKEN') },
  }
  const datos = {
    type,
    data,
  }
  const res = await axios.put(ApiURL + 'formats', datos, config)
  return res
}

export const getFormats = async (type, list) => {
  const config = {
    headers: { 'x-access-token': localStorage.getItem('TOKEN') },
  }
  const res = await axios.get(ApiURL + 'formats/' + type + '/list/'+ list, config)
  return res
}

export const getFormatsById = async (type, id) => {
  const config = {
    headers: { 'x-access-token': localStorage.getItem('TOKEN') },
  }
  const res = await axios.get(ApiURL + 'formats/' + type + '/'  + id, config)
  
  return res
}

export const deleteFormatId = async (type, id) => {
  const config = {
    headers: { 'x-access-token': localStorage.getItem('TOKEN') },
  }
  const res = await axios.delete(ApiURL + 'formats/' + type + '/' + id, config)
  return res
}

export const eraseFormatId = async (type, id) => {
  const config = {
    headers: { 'x-access-token': localStorage.getItem('TOKEN') },
  }
  const res = await axios.delete(ApiURL + 'formats/erase/' + type + '/' + id, config)
  return res
}

export const finishContractById = async (id, doc) => {
  const config = {
    headers: { 'x-access-token': localStorage.getItem('TOKEN') },
  }
  const res = await axios.delete(ApiURL + 'formats/finish/' + id + '/' + doc, config)
  return res
}

///--------Endpoint workflow-----------------

export const newWorkflow = async (data) => {
  const config = {
    headers: { 'x-access-token': localStorage.getItem('TOKEN') },
  }
  const datos = {
       data,
  }
  const res = await axios.post(ApiURL + 'workflow', datos, config)
  return res
}




export const updateWorkflow = async (data) => {
  const config = {
    headers: { 'x-access-token': localStorage.getItem('TOKEN') },
  }
  const datos = { 
    data
  }
  const res = await axios.put(ApiURL + 'workflow', datos, config)
  return res
}
export const getWorkflows = async () => {
  const config = {
    headers: { 'x-access-token': localStorage.getItem('TOKEN') },
  }
  const res = await axios.get(ApiURL + 'workflow', config)
  return res
}
export const getWorkflowById = async (column, id) => {
  const config = {
    headers: { 'x-access-token': localStorage.getItem('TOKEN') },
  }
  const res = await axios.get(ApiURL + 'workflow/' + column + '/'+ id, config)
  return res
}
export const getWorkflowByFormat = async (type, id, status) => {
  const config = {
    headers: { 'x-access-token': localStorage.getItem('TOKEN') },
  }
  const res = await axios.get(ApiURL + 'workflow/format/' + type + '/' + id+ '/' + status, config)
  return res
}
export const deleteWorkFlowById = async (id) => {
  console.log(id)
  const config = {
    headers: { 'x-access-token': localStorage.getItem('TOKEN') },
  }
  const res = await axios.delete(ApiURL + 'workflow/erase/' + id, config)
  return res
}



/* [     creado ,   firmaJefe , firmaLiderA ,  Aprobar,Rechazado , Procesar ,   Finalizados  */ 