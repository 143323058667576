import { Accordion } from 'react-bootstrap'
import ContractData from './contracts/ContractData'
import { Vacant } from '../../leader/recruitment/Vacant/Vacant'
import Employee from './employee/Employee'
import { useEffect, useState } from 'react'
import FunctionsManual from  '../../../app/leader/recruitment/functionsManual/FunctionsManual'
import ConditionChange from '../../../app/leader/recruitment/conditionChange/ConditionChange'

const Staff = props => {
  const [restartView, setRestartView] = useState(true)


  useEffect(() => {

  }, [restartView])

  return (
    <Accordion >
      <Accordion.Item eventKey="0">
        <Accordion.Header>Manual de Funciones</Accordion.Header>
        <Accordion.Body>
          <FunctionsManual
            put={props.put}
            _delete={props._delete}
            post={props.post}
            sessionUser={props.sessionUser}
            newBotton={false}
            dense={true}
            uniqueUser={false}
            route={'/humantalent'}
            isTH={true}
          ></FunctionsManual>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Solicitud de Empleo</Accordion.Header>
        <Accordion.Body>
          <Vacant
            admin={props.admin}
            put={props.put}
            _delete={props._delete}
            post={props.post}
            sessionUser={props.sessionUser}
            dense={true}
            newBotton={false}
            uniqueUser={false}
            route={'/humantalent'}
            isTH={true}
          ></Vacant>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2">
        <Accordion.Header>Reporte de Ingreso</Accordion.Header>
        <Accordion.Body>
          <Employee
            put={props.put}
            delete={props._delete}
            post={props.post}
            dense={true}
            sessionUser={props.sessionUser}
            setRestartView={setRestartView}
            restartView={restartView}
            newBotton={false} />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>Contratos</Accordion.Header>
        <Accordion.Body>
          <ContractData
            put={props.put}
            delete={props.delete}
            post={props.post}
            dense={true}
            sessionUser={props.sessionUser}
            setRestartView={setRestartView}
            restartView={restartView}
            isTH={true}
          ></ContractData>
        </Accordion.Body>
      </Accordion.Item>
      {/**/} <Accordion.Item eventKey="4">
        <Accordion.Header>Cambio de Condición Contractual </Accordion.Header>
        <Accordion.Body>
          <ConditionChange
            put={props.put}
            delete={props.delete}
            post={props.post}
            sessionUser={props.sessionUser}
            dense={true}
            newBotton={false}
            uniqueUser={false}
            route={'/humantalent'}
            isTH={true}

          />

        </Accordion.Body>
      </Accordion.Item> 

    </Accordion>
  )
}
export default Staff
