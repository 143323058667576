import { getDocument } from 'pdfjs-dist'
import React, { useEffect, useRef, useState } from 'react'
import { Typeahead } from 'react-bootstrap-typeahead'
import styled from 'styled-components'
import { getCompanieByIdF, CreateCompanieF, EditingCompanieF, getMultiDataF } from '../lib/request'
import { Col, Row, FloatingLabel, Accordion, Button, Card, Form, ButtonGroup } from 'react-bootstrap'
import SelectOption from '../lib/SelectOption'

export function HumanTable({ data = [], setData, isView, tableType, list }) {

    const [showSave, setShowSave] = useState(false)
    const [restart, setRestart] = useState(false)
    const [Data0, setData0] = useState('')
    const [Data1, setData1] = useState('')
    const [Data2, setData2] = useState('')
    const [Data3, setData3] = useState('')
    const [Data4, setData4] = useState('')
    const [parentesco, setParentesco] = useState(['Madre', 'Padre', 'Hijo', 'Conyuge', 'Primo', 'Prima'])
    const [gene, setgene] = useState(['Femenino', 'Masculino', 'Otro'])
    const select = useRef()
    const select2 = useRef()

    const tableOption = {
        tableFamily: {
            headersTable: ['Nombres', 'Apellidos', 'Sexo', 'Parentesco', 'Fecha Nacimiento'],
            columnWidth: ['25%', '25%', '10%', '10%', '15%'],
            dataTitle: ['EqNombres', 'EqApellidos', 'EqSexo', 'EqParentesco', 'EqFechaNacimiento'],
            selectTitle: "sexo"
        }
    }
    useEffect(() => {

    }, [restart])

    const saveItem = () => {

        if (tableType === 'tableFamily') var nuevoItem = { Nombres: Data0, Apellidos: Data1, Sexo: Data2, Parentesco: Data3, FechaNacimiento: Data4 }

        data[data.length === undefined ? 0 : data.length] = nuevoItem
        setData(data)
        setData0('')
        setData4('')
        setData3('')
        setData2('')
        setData1('')
        setShowSave(false)

        if(select.current)
        select.current.selectedIndex = 0
        if(select2.current)
        select2.current.selectedIndex = 0

        setRestart(!restart)


    }

    const deleteItem = (e) => {
        const tableData = data
        tableData.splice(e.currentTarget.id, 1);
        setRestart(!restart)
    }

    const handleChange = ({ target }) => {
        const { name, type, id, accessKey } = target
        const value = type === 'checkbox' ? target.checked : target.value
        if (id === 'tb') {
            data[accessKey][name.replace('_' + accessKey, '')] = value
            setRestart(!restart)
        }

        if (Data0 !== '' && Data1 !== '' && Data2 !== '' && Data3 !== '' && Data4 !== '')
            setShowSave(true)
        else if (Data0 !== '' && Data1 !== '' && Data2 !== '' && tableType === 'LCC') setShowSave(true)
        else setShowSave(false)
    }

    return (
        <Frame>{<table style={{ width: '100%' }} >
            <thead style={{ textAlign: 'center' }}>
                <tr>
                    <th scope="Col" style={{ width: tableOption[tableType]["columnWidth"][0] }}>{tableOption[tableType]["headersTable"][0]}</th>
                    <th scope="Col" style={{ width: tableOption[tableType]["columnWidth"][1]  }}>{tableOption[tableType]["headersTable"][1]}</th>
                    <th scope="Col" style={{ width: tableOption[tableType]["columnWidth"][2]  }}>{tableOption[tableType]["headersTable"][2]}</th>
                    <th scope="Col" style={{ width: tableOption[tableType]["columnWidth"][3] }}>{tableOption[tableType]["headersTable"][3]}</th>
                    <th scope="Col" style={{ width: tableOption[tableType]["columnWidth"][4]  }}>{tableOption[tableType]["headersTable"][4]}</th>
                    {!isView && <th scope="Col" style={{ width: '5%' }}>Acciones</th>}
                </tr>
            </thead>
            <tbody>
                {
                    Array.isArray(data) && data?.map((item, i) => (
                        <tr key={i}>
                            <td>{item.Nombres}</td>
                            <td>{item.Apellidos}</td>
                            <td>{item.Sexo}</td>
                            <td>{item.Parentesco}</td>
                            <td>{item.FechaNacimiento}</td>
                            {!isView && <th style={{ textAlign: 'center' }} role='button' id={i} onClick={deleteItem}>{!isView && <>
                                <i style={{ color: 'red', fontSize: '20px' }} className="fa-solid fa-trash"></i>

                            </>} </th>}</tr>))
                }{!isView && <><tr>
                    <th><input name='Data0' id='na1' value={Data0} onChange={e => { setData0(e.target.value); handleChange(e) }} disabled={false}></input></th>
                    <th><input name='Data1' id='na2' value={Data1} onChange={e => { setData1(e.target.value); handleChange(e) }} disabled={false}></input></th>
                    <th >
                        <SelectOption type={"gender"} searchable={true} state={Data2} setState={setData2} required disabled={isView ? true : false} />
                        
                    </th>
                    <th >
                    <SelectOption type={"family"} searchable={true} state={Data3} setState={setData3} required disabled={isView ? true : false} />
                      
                       
                    </th>
                    <th><input name='Data4' type={'date'} id='na4' value={Data4} onChange={e => { setData4(e.target.value); handleChange(e) }} onMouseLeave={e => { setData4(e.target.value); handleChange(e) }} disabled={false} /></th>



                    <th style={{ textAlign: 'center' }} role='button'>{showSave && <i onClick={saveItem} style={{ color: 'green', fontSize: '23px' }} className="fa-solid fa-check"></i>}</th>
                </tr>
                </>}
            </tbody>
        </table>}

        </Frame>
    )
}

const Frame = styled.div`
table{
    padding:0;
    marging:0;
    text-align: 'center'
}
td {
    
        font-size: 9px;
        font-weight: 400;
        text-align: 'center';
        /* vertical-align: center; */
    
  }
input {
    border-bottom: 0px solid black;
  }
  input.disable {
    background: #fff;
    border-bottom: 0px solid black;
  }
`