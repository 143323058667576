import { getFormats } from '../../../lib/formatos/request'
import { sessionActive } from '../../../lib/functions'
import { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { toast } from 'react-toastify'
import styled from 'styled-components'

const EqInfo = ({ setLinkAplication, Eq_select_ID, setEq_select_ID }) => {
  const [search, setSearch] = useState('')
  const [showFilterBox, setShowFilterBox] = useState(false)
  const [formats, setFormats] = useState([])
  const [employees, setEmployees] = useState(formats)
  const [options, setOptions] = useState(['solicitado', 'revisado', 'aprobado', 'eliminado', 'anulado'])

  useEffect(() => {
    sessionActive()
    fillTable()
  }, [])

  const fillTable = async () => {
    //Obtener lista de solicitudes de empleo
    const resFormats = await getFormats('MITFOR207')
    if (resFormats.data.status === 200) {
      setFormats(resFormats.data.data)
      setEmployees(resFormats.data.data)
    } else {
      toast.error(resFormats.data.msj)
    }
  }

  const columns = [
    {
      name: 'Sol.',
      cell: row => <div data-tag="allowRowEvents">{row.IdFormat_PK}</div>,
      selector: row => row.IdFormat_PK,
      sortable: true,
      center:true,
      width:'80px'
    },
    {
      name: 'Descripcion',
      cell: row => <div data-tag="allowRowEvents">{row.EqDenominacion}</div>,
      selector: row => row.IdFormat_PK,
      sortable: true,
      width:'220px'
    },
    {
      name: 'Estado',
      cell: row => <div data-tag="allowRowEvents">{row.Status}</div>,
      selector: row => row.IdFormat_PK,
      sortable: true,
      center:true,
      width:'120px'
    },
    {
      name: 'Marca',
      cell: row => <div data-tag="allowRowEvents">{row.EqMarca}</div>,
      selector: row => row.IdFormat_PK,
      sortable: true,
      
      width:'120px'
    },
    {
      name: 'Modelo',
      cell: row => <div data-tag="allowRowEvents">{row.EqModelo}</div>,
      selector: row => row.IdFormat_PK,
      sortable: true,
      center:true,
      width:'120px'
    },    
    {
      name: 'Tipo',
      cell: row => <div data-tag="allowRowEvents">{row.EqTipo}</div>,
      selector: row => row.IdFormat_PK,
      sortable: true,
      center:true,
      width:'120px'
    },   
    {
      name: 'Custodio',
      cell: row => <div data-tag="allowRowEvents">{row.EqCustodio}</div>,
      selector: row => row.IdFormat_PK,
      sortable: true,
      center:true,
      width:'120px'
    }
  ]

  const paginationOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  }

  const handleSearch = ({ target }) => {
    setSearch(target.value)
    searcher(target.value)
   
  }

  const searcher = FilterText => {
    if (!FilterText) return setEmployees(formats)

    const result = formats.filter(formato =>
          formato.EqDenominacion.toLowerCase().includes(FilterText.toLowerCase()) ||
          formato.Status.toLowerCase().includes(FilterText.toLowerCase()) ||
          formato.EqMarca.toLowerCase().includes(FilterText.toLowerCase()) ||
          formato.EqModelo.toLowerCase().includes(FilterText.toLowerCase()) ||
          formato.EqTipo.toLowerCase().includes(FilterText.toLowerCase()) ||
          formato.EqCustodio.toLowerCase().includes(FilterText.toLowerCase())
      )
    setEmployees(result)
  }

  const handleFilter = () => {

    if (showFilterBox) {
      setEmployees(formats)
  
      setOptions(['solicitado', 'revisado', 'aprobado', 'eliminado', 'anulado'])
    }
    
    setShowFilterBox(!showFilterBox)
  }

  const conditionalRowStyles = [
    {
      when: row => row.toggleSelected,
      style: {
        backgroundColor: '#eeeeee',
        userSelect: 'none',
        cursor: 'pointer',
      },
    },
    {
      when: row => !row.toggleSelected,
      style: {
        cursor: 'pointer',
      },
    },
  ]

  const handleRowClicked = row => {
    setEq_select_ID(row)
    if (row.toggleSelected === true) setLinkAplication(false)
    if (row.toggleSelected !== true) {
      options.filter(option => {
        if (row.Status.includes(option)) {
          if (option === 'aprobado') {
            setLinkAplication(true)
          } else {
            setLinkAplication(false)
          }
        }
      })
    }

    const updatedData = employees.map(item => {
      if (row.IdFormat_PK !== item.IdFormat_PK) {
        return {
          ...item,
          toggleSelected: row.toggleSelected ? !item : row.toggleSelected,
        }
      }

      return {
        ...item,
        toggleSelected: !item.toggleSelected,
      }
    })

    setEmployees(updatedData)
  }

  return (
    <div className="w-50 d-flex justify-content-center align-items-center flex-column w-50 overflow-hidden">
      <Row className="w-100 overflow-hidden">
        <Col>
          <Col className="d-flex flex-column align-items-center">
            <label>Equipos</label>
            <div className="w-100 d-flex gap-2 align-items-center justify-content-center">
              <Search placeholder="Busqueda" onChange={handleSearch} value={search} />
              <ReloadBtn>
                <i className="bi bi-arrow-counterclockwise" />
              </ReloadBtn>
              <ReloadBtn onClick={handleFilter}>
                <i className="fas fa-sliders-h" />
              </ReloadBtn>
            </div>
          </Col>
         
          <Row>
            <DataTable
              columns={columns}
              data={employees}
              pagination
              paginationComponentOptions={paginationOptions}
              noDataComponent={<span>No se encontraron elementos</span>}
              conditionalRowStyles={conditionalRowStyles}
              onRowClicked={handleRowClicked}
              fixedHeader= {true}
              fixedHeaderScrollHeight= {'600px'}
              />
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default EqInfo

const Search = styled.input`
  width: 96%;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 20px;
`

export const ReloadBtn = styled.button`
    background-color: #198754;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 8px;
    font-size: 13px;
    &:active {
        background-color: greenyellow;
    }
`