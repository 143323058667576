import { useContext, useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { decodeUserPermission, sessionActive } from '../lib/functions'
import { getFullData } from '../lib/request'
import Staff from './staff/Staff'
import EmployeesTab from './employeesTab/EmployeesTab'
import { UserContext } from '../../../context/userContext'

export default function HumanTalent() {
  
  const [admin, SetAdmin ]= useState(false)
    const [put, setPut] = useState(false)
  const [_delete, set_delete] = useState(false)
  const [post, setPost] = useState(false)
  const {sessionUser} = useContext(UserContext)
  // const [sessionUser, setsessionUser] = useState()
  useEffect(() => {
    sessionActive()
    Permissions()
  }, [])

  const Permissions = async () => {

    const resPermission = decodeUserPermission(
      localStorage.getItem('userPermission'),
      localStorage.getItem('IdUser_PK')
    )

    if (resPermission.superAdmin.access) {
      //Obtener Permisos de Usuario
      setPut(true)
      set_delete(true)
      setPost(true)
      SetAdmin(true)
    } else {
      setPut(resPermission.humanTalent.PUT)
      set_delete(resPermission.humanTalent.DELETE)
      setPost(resPermission.humanTalent.POST)
    }
    
  }

  return (
    <div className="page-content py-3 vh-100 ">
    <Row style={{ height: '100%' }}>
      <Col style={{ height: '100%' }}>
        <Card style={{ height: '100%'}}>
            <Tabs>
              <Card.Header className="card-tabs text-center  align-items-center">
                <TabList>
                  <h5 className="tabs-tittle">Talento Humano</h5>
                  <Tab>
                    <p>Contratacion</p>
                  </Tab>
                  {/* <Tab>
                    <p>Vacantes</p>
                  </Tab> */}
                  {/* <Tab>
                    <p>Empleados</p>
                  </Tab> */}
                  {/* <Tab>
                    <p>Contratos</p>
                  </Tab> */}
                  <Tab>
                    <p>Empleados</p>
                  </Tab>
                  {/* <Tab>
                    <p>Permisos</p>
                  </Tab>
                  <Tab>
                    <p>Preavisos</p>
                  </Tab>
                  <Tab>
                    <p>Otro sí</p>
                  </Tab> */}
                </TabList>
              </Card.Header>
              <Card.Body><TabPanel>
                  <Staff
                    admin={admin}
                    put={put}
                    _delete={_delete}
                    post={post}
                    sessionUser={sessionUser}
                    newBotton={false}
                    uniqueUser={false}
                    isTH={true}
                  ></Staff>
                </TabPanel>
                {/* <TabPanel>
                  <Vacant
                    put={put}
                    delete={_delete}
                    post={post}
                    sessionUser={sessionUser}
                    newBotton={false}
                    uniqueUser={false}
                    route={'/humantalent'}
                    isTH={true}
                  ></Vacant>
                </TabPanel> */}
                {/* <TabPanel>
                  <Employee
                    put={put}
                    delete={_delete}
                    post={post}
                    newBotton={true}
                    sessionUser={sessionUser}
                  ></Employee>
                </TabPanel> */}
                {/* <TabPanel>
                  <Contract put={put} delete={_delete} post={post} sessionUser={sessionUser}></Contract>
                </TabPanel> */}
                <TabPanel>
                  <EmployeesTab put={put}
                    admin={admin}
                    delete={_delete}
                    post={post}
                    newBotton={true}
                    sessionUser={sessionUser}
                    />
                </TabPanel>
                {/* <TabPanel>
                  <Permisos put={put}
                    delete={_delete}
                    post={post}
                    newBotton={true}
                    sessionUser={sessionUser}></Permisos>
                </TabPanel>
                <TabPanel>
                  <Preaviso put={put}
                    delete={_delete}
                    post={post}
                    newBotton={true}
                    sessionUser={sessionUser}></Preaviso>
                </TabPanel>
                <TabPanel>
                  <OtroSi put={put}
                    delete={_delete}
                    post={post}
                    newBotton={true}
                    sessionUser={sessionUser}></OtroSi>
                </TabPanel> */}
              </Card.Body>
            </Tabs>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
