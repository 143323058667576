import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { sessionActive } from '../../../lib/functions'
import { Col, Row, Button, Form, Modal } from 'react-bootstrap'
import Moment from 'moment'
import { getFormats, updateFormat, eraseFormatId } from '../../../lib/formatos/request'
import SelectOption from '../../../lib/SelectOption'
import { useUserContext } from '../../../../../hooks/custom'
import SpinnerLoad from  '../../../lib/spinnerLoad'
import COFIFOR161 from '../../../lib/formatos/COFIFOR161'
import FileManager from '../../../lib/FileManager'

export const Refund = props => {
  const { sessionUser } = useUserContext()
  const [IdUser_PK, setIdUser_PK] = useState(localStorage.getItem('IdUser_PK'))
  const [date, setDate] = useState('')
  const [restart, setRestart] = useState(false)
  const [IdFormat_PK, setIdFormat_PK] = useState('')
  const [showForm, setShowForm] = useState(false)
  const [formats, setFormats] = useState([])
  const [isView, setIsView] = useState(false)
  const [filterStatus, setFilterStatus] = useState("")
  const [idDelete, setIdDelete] = useState()
  const [idAction, setIdAction] = useState()
  const [search, setSearch] = useState('')
  const [data, setData] = useState('')
  const [dataRow, setDataRow] = useState('')
  const [filterindItems, setFilterindItems] = useState()
  const [initData, setInitData] = useState('')
  const [ModalDelete, setModalDelete] = useState(false)
  const [modalAction, setModalAction] = useState(false)
  const [actionBtn, setActionBtn] = useState("Finalizar")
  const [list, setList] = useState([])
  const [reintegro, setReintegro] = useState([])
  const typef = 'COFIFOR161'
  const [showModal, setShowModal] = useState(true)
  const [files, setFiles] = useState({})
  

  const estados=
    { COFIFOR161:[{label:'Procesar', value:'Procesar '}, 
                  {label:'Finalizado', value:'Finalizado '}, 
                  {label:'Reintegrar', value:'Reintegrar '}, 
                  {label:'Rintegrado', value:'Rintegrado'}]}

  useEffect(() => {

    if (!restart) {
      setRestart(!restart)
      sessionActive()
      fillTable()
    }
    else{
      refillTable()

    }
  }, [restart,filterStatus])

  // useEffect(() => {
  //   refillTable()

  // }, [filterStatus])



  const refillTable = async () => {
    const formatDate = Moment().format('YYYY-MM-DD')
    setDate(formatDate)
    
    //let _data = initData.filter(item => filterStatus.includes(item.Status));
    setFormats(filterStatus.length === 0 ? initData : initData.filter(item => filterStatus.includes(item.Status)))
    setFilterindItems(filterStatus.length === 0 ? initData : initData.filter(item => filterStatus.includes(item.Status)))
    setSearch('')
  }

  const fillTable = async () => {
    //Obtener lista de servicios
    var resFormats = await getFormats(typef)
    if (resFormats.data.status === 200) {
      const _data =( props.isAccount || props.isOper )? resFormats.data.data : resFormats.data.data.filter(registro => {
        const firmaSolicitante = JSON.parse(registro.firmaSolicitante);
        return firmaSolicitante.IdEmployee_FK === sessionUser.IdEmployee_FK;
      });
      setFormats(_data)
      setFilterindItems(_data)
      setInitData(_data)
    } else {
      toast.error(resFormats.data.msj)
    }
  }


  useEffect(() => {
    sessionActive()
    fillTable()
  }, [restart])

  const deleteSol = async () => {
    //borrar un solicitud
    const resDelele = await eraseFormatId(typef, idDelete)
    if (resDelele.data.status === 200) {
      toast.success(resDelele.data.msj)
    } else {
      toast.error(resDelele.data.msj)
    }
    fillTable()
  }
  const ActionSol = async () => {
    let filesSave = null
    let reintegroSave= null
    let newStatus = actionBtn
    if (actionBtn === "Anular") newStatus = "Anulado"
    if (actionBtn === "Revertir") newStatus = "Procesar"
    if (actionBtn === "Finalizar") newStatus = "Finalizado"
    if (actionBtn === "Reintegrar"){
      newStatus = "Reintegrar"
    
      if (!reintegro){ toast.error('Diligencie el formulario y adjunte el archivo'); return}
      if(reintegro=== undefined) {toast.error('Debe especificar el motivo del reintegro'); return}
      if(files=== undefined) {toast.error('Adjunte un soporte'); return}
      

      filesSave=JSON.stringify(files)
       reintegroSave=`${sessionUser.NombreTrabajador} ${sessionUser.ApellidoTrabajador} Fecha:${date}\n${reintegro}`

    }
    if (actionBtn === "Finalizar Reintegro"){
      newStatus = "Reintegrado" 
      if (!reintegro){ toast.error('Realice un comentario'); return}
      if(reintegro=== undefined) {toast.error('Realice un comentario'); return}
       reintegroSave=`${data.reintegro}\n${sessionUser.NombreTrabajador} ${sessionUser.ApellidoTrabajador} Fecha:${date}\n${reintegro}`

      }

    const result = await updateFormat({ IdFormat_PK: idAction, Status: newStatus, reintegro:reintegroSave, files:filesSave }, typef)
    if (result.data.status === 200) {
    setModalAction(false)  
        toast.success(result.data.msj)
    } else {
      toast.error(result.data.msj)
    }


    ClearSearch()
    fillTable()

  }

  const OnFilter = e => {

    let FilterText = e.target.value
    setSearch(FilterText)
    setFilterindItems(
      formats.filter(

        item =>
          //item.IdFormat_PK.includes(!isNaN(FilterText) ? FilterText : 0) ||
          item.createdBy.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.Contrato.includes(FilterText) ||
          item.Area.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.TipoRequisicion.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.Justificacion.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.EntregarEn.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.EntregarA.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.BuyTable.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.Status.toLowerCase().includes(FilterText.toLowerCase())
      )
    )
  }

  const ClearSearch = () => {
    setFilterStatus('')
    setSearch('')
    setFilterindItems(initData)
    setRestart(!restart)
  }

  const handleChange = (data) => {

    setData(data)
    setShowModal(!showModal)
    setShowForm(!showForm)
    setRestart(false)
    setIsView(true)
    setIdFormat_PK(data.IdFormat_PK)

  }
  const editRow = row => {

    if (props.put) {
      if (IdUser_PK === JSON.parse(row.firmaSolicitante).IdEmployee_FK && row.Status === 'Revisar') return true
      else if ( props.isOper && row.Status === 'Revisar') return true
      else return false
    }
    
    return false
  }
  const deleteRow = row => {
    if (props.delete) {
      if (IdUser_PK === JSON.parse(row.firmaSolicitante).IdEmployee_FK && row.Status === 'firmaJefe') return true

      else return false
    }
    return false
  }

  const columns = [
    {
      name: 'Id',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.IdFormat_PK}
        </div>
      ),
      selector: row => row.IdFormat_PK,
      sortable: true,
      grow: 0.1
    },
    {
      name: 'Solicitante',
      cell: row => (<div onClick={() => { handleChange(row) }} data-tag="allowRowEvents">{JSON.parse(row.firmaSolicitante).NombreTrabajador + ' ' + JSON.parse(row.firmaSolicitante).ApellidoTrabajador}</div>),
      selector: row => row.createdBy,
      sortable: true,
      grow: 2
    },
    {
      name: 'Aprobador',
      cell: row => (<div onClick={() => { handleChange(row) }} data-tag="allowRowEvents">{JSON.parse(row.firmaJefe).NombreTrabajador + ' ' + JSON.parse(row.firmaJefe).ApellidoTrabajador }</div>),
      selector: row => row.TipoRequisicion,
      sortable: true,
      grow: 2
    },

    {
      name: 'CeCo',
      cell: row => (<div onClick={() => { handleChange(row) }} data-tag="allowRowEvents">{row.CeCo}</div>),
      selector: row => row.Contrato,
      sortable: true,
      grow: 0.5
    },
    {
      name: 'Fecha Solicitud',
      cell: row => (<div onClick={() => { handleChange(row) }} data-tag="allowRowEvents">{row.fechaEntrega}</div>),
      selector: row => row.Area,
      sortable: true,
      grow: 1.2
    }, {
      name: 'Fecha Salida',
      cell: row => (<div onClick={() => { handleChange(row) }} data-tag="allowRowEvents">{row.fechaFin}</div>),
      selector: row => row.EntregarA,
      sortable: true,
      grow: 1.2
    },

    {
      name: 'Estado',
      cell: row => (<div onClick={() => { handleChange(row) }} data-tag="allowRowEvents">{(row.Status)}</div>),
      selector: row => row.Status,
      sortable: true,
      grow: 1.2
    }, {
      name: 'Acciones',
      cell: row => (
        <div className="tabla dropdown dropstart " >
          <i
            className="bi bi-three-dots-vertical"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          />
          <ul style={{ zIndex: '990000' }} className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            
{console.log('is oper => ', props.isOper)}
            {!props.isAccount && !props.isOper && !row.Status !== 'Finalizado' &&<>
              <li>
                <Link
                  onClick={() => {
                    setData({ ...row, Status: "edicion" }
                    )
                    setShowForm(!showForm)
                    setRestart(false)
                    setIsView(false)
                    setIdFormat_PK(row.IdFormat_PK)
                  }}
                  className={'dropdown-item text-center border-end' + (editRow(row) ? '' : ' disabled')}
                  to={props.route}
                >
                  <i className="bi bi-pen me-3"></i>Editar
                </Link>
              </li>
            </>}
            {props.isOper  &&<>
              <li>
                <Link
                  onClick={() => {
                    setData({ ...row, Status: "Revisando" }
                    )
                    setShowForm(!showForm)
                    setRestart(false)
                    setIsView(true)
                    setIdFormat_PK(row.IdFormat_PK)
                  }}
                  className={'dropdown-item text-center border-end' + (editRow(row) ? '' : ' disabled')}
                  to={props.route}
                >
                  <i className="bi bi-pen me-3"></i>Revisar
                </Link>
              </li>
            
            </>}
           

            {props.isAccount && 
               <li>
                <Link
                  className={'dropdown-item text-center border-end' + (row.Status === 'Procesar' ? '' : ' disabled')}
                  to={props.route}
                  onClick={() => {
                    setData({ ...row, Status: 'Procesando' }
                    )
                    setShowForm(!showForm)
                    setRestart(false)
                    setIsView(true)
                    setIdFormat_PK(row.IdFormat_PK)
                  }}
                >
                  
                  <i className="fa-solid fa-money-bill-transfer me-3"></i>Procesar
                </Link>
              </li>
           }
          </ul>
        </div>
      ),
      button: true,
    }

  ]


  return (
    <div>

      <Row >
        <Col >
          {props.newBotton && (props.format === 'COFIFOR159' || props.format === '') && (
            <Button
              variant="success"
              className="m-3 p-2 rounded-pill btn-sm ms-1"
              disabled={props.post ? false : true}
              onClick={() => {
                setRestart(false)
                setShowForm(!showForm)
                setData({ Status: 'en creacion' })
                setIsView(false)
                setIdFormat_PK('en creacion')
              }}>
              <i className="bi bi-credit-card-fill mx-2" style={{ fontSize: '16px' }} />Nuevo Anticipo{''}por que no para mañam
            </Button>
          )}
          {props.newBotton && (props.format === 'COFIFOR271' || props.format === '') && (
            <Button
              variant="success"
              className="m-3 p-2 rounded-pill btn-sm ms-1"
              disabled={props.post ? false : true}
              onClick={() => {
                setRestart(false)
                setShowForm(!showForm)
                setData({ Status: 'en creacion' })
                setIsView(false)
                setIdFormat_PK('en creacion')
              }}>
              <i className="fa-solid fa-hand-holding-dollar mx-2" style={{ fontSize: '16px' }} /> Nuevo Auxilio{''}
            </Button>
          )}
          <Col className="d-flex " style={{ width: '100%' }}>

            <Form.Control
              defaultValue={''}
              type="text"
              id="search"
              style={{ width: '65%' }}
              placeholder="Búsqueda"
              aria-label="search input"
              value={search || ''}
              onChange={OnFilter}
            />

            <SelectOption
              type={'custom'}
              widthSelect={'380px'}
              searchable={true}
              state={filterStatus}
              setState={setFilterStatus}
              list={estados[props.format]} 
              multi={true}
              placeHolder={'filtro...'}
            />
            <Button data-tip="Limpiar Filtro" className="btn btn-success btn-sm" type="button" onClick={ClearSearch}>
              <i className="bi bi-arrow-counterclockwise" />
            </Button>
          </Col>
          <Row>
            <SpinnerLoad trigger={initData} />
            <DataTable
              fixedHeader={false}
              fixedHeaderScrollHeight={{ fixedHeaderScrollHeight: '100px' }}
              columns={columns}
              data={filterindItems}
              pagination={ filterindItems?.length > 10 ?? true }
              responsive
              dense ={ filterindItems?.length > 3 ?? false }
              highlightOnHover
              pointerOnHover
              noDataComponent="No hay registros para mostrar"
              paginationComponentOptions={{
                rowsPerPageText: 'Filas por página:',
                rangeSeparatorText: 'de',
              }}
            />
          </Row>
        </Col>
      </Row>
      {showForm && <COFIFOR161
        sessionUser={sessionUser}
        data={data}
        setData={setData}
        show={showForm}
        hide={setShowForm}
        restart={restart}
        setRestart={setRestart}
        isView={isView}
        IdFormat_PK={IdFormat_PK}
        isAccount={props.isAccount}
        list={list}
        isOper={props.isOper}
      />}
     
      <Modal
        show={ModalDelete}
        onHide={() => {
          setModalDelete(false)
        }}
        centered
      >
        <Modal.Header closeButton>
          <h5>Eliminar Solicitud</h5>
        </Modal.Header>
        <Modal.Body>
          <p>¿Realmente desea eliminar anticipo {idDelete}?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {

              setModalDelete(false)
            }}
          >
            Cancelar
          </Button>
          <Button className="btn btn-danger"
            onClick={() => {
              deleteSol()
              setModalDelete(false)
            }}
          >
            Eliminar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={modalAction}
        onHide={() => {
          setModalAction(false)
        }}
        centered
      >
        <Modal.Header closeButton>
          <h5>{actionBtn} Solicitud</h5>
        </Modal.Header>
        <Modal.Body>
          {actionBtn === "Finalizar Reintegro"?
          <div className=' content-center  '><p>Agregue comentario de verificación de la devolución</p>
          <textarea
            style={{ width: '100%', height: '60px' }}
            name={'rechazo'}
            value={reintegro}
            onChange={e=>setReintegro( e.target.value)}
            disabled={false}
            required= {true}
          />   
          </div>:
          actionBtn !== "Reintegrar"?<p>¿Realmente desea {actionBtn.toLowerCase()} el anticipo {idAction}?</p>:
          <div className=' content-center  '><p>Por favor especifique el motivo para reintegrar la solicitud</p>
            <textarea
              style={{ width: '100%', height: '60px' }}
              name={'rechazo'}
              value={reintegro}
              onChange={e=>setReintegro( e.target.value)}
              disabled={false}
              required= {true}
            />
             <p>Por favor adjunte el comprobante de reintegro del dinero en PDF o correo de autorización para descuento por nómina </p>

            {/* <FileManager  name={'soporte'} state={files} setState={setFiles}  path={`${props.format}/${idAction}`} pdf={true}  multiple  /> */}
            <FileManager name={'soporte'} stateFile={files} setStateFile={setFiles} path={`${props.format}/${idAction}`} pdf={true} multiple    />
       
            </div>}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {

              setModalAction(false)
            }}
          >
            Cancelar
          </Button>
          <Button className="btn btn-danger"
            onClick={() => {
              ActionSol()
            }}
          >
            {actionBtn}
          </Button>
        </Modal.Footer>
      </Modal>

    </div>



  )
}

export default Refund
