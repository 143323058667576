import { useState } from 'react'
import FileManager from '../../lib/FileManager'

const Uploader = () => {
  const [multiple, setMultiple] = useState({
    "multiple_1": {
      "name": "multiple_1",
      "path": "soporte/Carta_TM-20-1172.pdf",
      "fileType": "application/pdf"
    },
    "multiple_2": {
      "name": "multiple_2",
      "path": "soporte/14194759.pdf",
      "fileType": "application/pdf"
    },
    "multiple_3": {
      "name": "multiple_3",
      "path": "soporte/CMP-FOR-185 FORMATO RECEPCION de servicio  copia copia copia copia.pdf",
      "fileType": "application/pdf"
    }
  })
  const [single, setSingle] = useState({
    "single": {
      "name": "single",
      "path": "cedulas/Carta_TM-20-1172.pdf",
      "fileType": "application/pdf"
    }
  })
  const [data, setData] = useState({
    "imagen": {
      "name": "imagen",
      "path": "cedulas/ticket_20231108163626.jpg",
      "fileType": "image/jpeg"
    }
  })

  return( <>
    <FileManager name={'imagen'} stateFile={data} setStateFile={setData}  path={'cedulas'} pdf={false} imageView width={'280px'} height={'300px'} disable  />
   <FileManager name={'single'} stateFile={single} setStateFile={setSingle} path={'cedulas'} pdf={true}   disable  /> 
  <FileManager name={'multiple'} stateFile={multiple} setStateFile={setMultiple} path={'soporte'} pdf={true} multiple    />
  
</>
)}

export default Uploader
