import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { sessionActive } from '../../../lib/functions'
import { Col, Row, Button, Form, Modal } from 'react-bootstrap'
import { getFormats, eraseFormatId } from '../../../lib/formatos/request'
import GTHFOR076 from '../../../lib/formatos/GTHFOR076'
import Moment from 'moment'
import SpinnerLoad from '../../../lib/spinnerLoad'

export const FunctionsManual = props => {

  const typef = "GTHFOR76"


  const [IdUser_PK, setIdUser_PK] = useState(localStorage.getItem('IdUser_PK'))
  const [restart, setRestart] = useState(false)
  const [IdFormat_PK, setIdFormat_PK] = useState('')
  const [showForm, setShowForm] = useState(false)
  const [formats, setFormats] = useState('')
  const [isView, setIsView] = useState(false)
  const [idDelete, setIdDelete] = useState()
  const [search, setSearch] = useState()
  const [filterindItems, setFilterindItems] = useState([])
  const [ModalDelete, setModalDelete] = useState(false)
  const [data, setData] = useState()
  const [status, setStatus] = useState("")

  useEffect(() => {
    
      
      sessionActive()
      fillTable()
   
  }, [restart])

  const fillTable = async () => {
    //Obtener lista de solicitudes de empleo
    const resFormats = await getFormats('GTHFOR76')
    if (resFormats.data.status === 200) {
      setFormats(resFormats.data.data)
      setFilterindItems(resFormats.data.data)
    } else {
      toast.error(resFormats.data.msj)
    }
  }
  const copyRow = row => {
    /* !props.put || row.Status!="solicitado" && !isTH || IdUser_PK!=row.IdUser_FK*/

    if (props.post)
      return true
    return false
  }
  const editRow = row => {
    /* !props.put || row.Status!="solicitado" && !isTH || IdUser_PK!=row.IdUser_FK*/
    if (props.put) {
      
      if (IdUser_PK === row.creadoPor && row.Status !== 'finalizado') return true
      if (props.isTH && row.Status !== 'finalizado') return true
      
      else return false
    }
    return false
  }
  const deleteRow = row => {
   
    if (props._delete) {
       if (IdUser_PK === row.creadoPor && row.Status === 'disponible') return true
    
      else return false
    }
    return false
  }
  const deleteSol = async () => {
    //borrar un solicitud
    const resDelele = await eraseFormatId(typef, idDelete)
    if (resDelele.data.status === 200) {
      toast.success(resDelele.data.msj)
    } else {
      toast.error(resDelele.data.msj)
    }
    fillTable()
  }

  const OnFilter = e => {
    let FilterText = e.target.value
    setSearch(FilterText)
    setFilterindItems(
      formats.filter(
          item =>
          item.objetivoCargo.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.perfilCargo.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.tipoPerfil.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.nombreSolicitante.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.fechaCreacion.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.Cargo.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.Status.toLowerCase().includes(FilterText.toLowerCase())
      )
    )
  }

  const ClearSearch = () => {
    setSearch('')
    setFilterindItems(formats)
  }

  const handleChange = (data) => {
    setData(data)
    setShowForm(!showForm)
    setRestart(false)
    setIsView(true)
    setIdFormat_PK(data.IdFormat_PK)

  }

  const columns = [
    {
      name: 'Id',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.IdFormat_PK}
        </div>
      ),
      selector: row => row.IdFormat_PK,
      sortable: true,
      grow:0.2
    },
    {
      name: 'Tipo perfil',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.tipoPerfil}
        </div>
      ),
      selector: row => row.tipoPerfil,
      sortable: true,
      grow:0.7
    },{
      name: 'Cargo',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.Cargo}
        </div>
      ),
      selector: row => row.Cargo,
      sortable: true,
      grow:2
    },
    {
      name: 'fecha',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {Moment(row.fechaCreacion).format('YYYY-MM-DD')}
        </div>
      ),
      selector: row => Moment(row.fechaCreacion).format('YYYY-MM-DD'),
      sortable: true,
      grow:0.7
    },
    {
      name: 'Creado por',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.nombreSolicitante}
        </div>
      ),
      selector: row => row.nombreSolicitante,
      sortable: true,
      grow:1.5
    },

    {
      name: 'Estado',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.Status}
        </div>
      ),
      selector: row => row.Status,
      sortable: true,
      grow: 0.7,
    },
    {
      name: 'Acciones',
      cell: row => (
        <div className="tabla dropdown dropstart">
          <i
            className="bi bi-three-dots-vertical"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          />
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            {!props.isTH && (<li>
              <Link
                onClick={() => {
                  const dataTemp= row
                  setShowForm(!showForm)
                  setRestart(false)
                  setIsView(false)
                  setIdFormat_PK('copiando')
                  setData({...dataTemp, Status:'copiando'})
                }}
                className={'dropdown-item text-center border-end' + (copyRow(row) ? '' : ' disabled')}
                to={props.route}
              >
                <i className="far fa-copy me-3 "></i>copiar
              </Link>
            </li>)}
            <li>
              <Link
                onClick={() => {
                  setData(row)
                  setShowForm(!showForm)
                  setRestart(false)
                  setIsView(false)
                  setIdFormat_PK(row.IdFormat_PK)
                  setStatus('isEdit')

                }}
                className={'dropdown-item text-center border-end' + (editRow(row) ? '' : ' disabled')}
                to={props.route}
              >

                <i className="bi bi-pen me-3 "></i>Editar
              </Link>
            </li>
            {!props.isTH && (<li>
              <Link
                className={'dropdown-item text-center border-end' + (deleteRow(row) ? '' : ' disabled')}
                to={props.route}
                onClick={() => {
                  setIdDelete(row.IdFormat_PK)
                  setRestart(false)
                  setModalDelete(true)
                }}
              >
                <i className="bi bi-trash me-3"></i>Borrar
              </Link>
            </li>)}
          </ul>
        </div>
      ),
      button: true,
    },
  ]

  return (
    <div>
      
      <Row>
        <Col>
          {props.newBotton && (
            <Button
              variant="success"
              className="m-3 p-2 rounded-pill btn-sm ms-1"
              disabled={props.post ? false : true}
              onClick={() => {
                setShowForm(!showForm)
                setRestart(false)
                setData({Status:'en creacion'})

                setIsView(false)
                setStatus('en creacion')
                setIdFormat_PK('en creacion')
              }}
            >
              <i className="fa fa-solid fa-book mx-2" />
              Nuevo Manual
            </Button>
          )}
          <Col className="d-flex">
            <Form.Control
              type="text"
              id="search"
              placeholder="Búsqueda"
              aria-label="search input"
              value={search}
              onChange={OnFilter}
            />
            <Button className="btn btn-success btn-sm" type="button" onClick={ClearSearch}>
              <i className="bi bi-arrow-counterclockwise" />
            </Button>
          </Col>
          <Row>
            <SpinnerLoad trigger={formats}/>
            <DataTable
              fixedHeader={false}
              fixedHeaderScrollHeight={{ fixedHeaderScrollHeight: '100px' }}
              columns={columns}
              data={filterindItems}
              pagination
              responsive
              dense={true}
              highlightOnHover
              pointerOnHover
              noDataComponent="No hay registros para mostrar"
              paginationComponentOptions={{
                rowsPerPageText: 'Filas por página:',
                rangeSeparatorText: 'de',
              }}
            />

          </Row>
        </Col>
      </Row>

      {showForm && <GTHFOR076
        sessionUser={props.sessionUser}
        data={data}
        setData={setData}
        show={showForm}
        hide={setShowForm}
        Status={status}
        setStatus={setStatus}
        isTH={props.isTH}
        restart={restart}
        setRestart={setRestart}
        isView={isView}
        IdFormat_PK={IdFormat_PK}
      />}

      <Modal
        show={ModalDelete}
        onHide={() => {
          setModalDelete(false)
        }}
        centered
      >
        <Modal.Header closeButton>
          <h5>Eliminar Solicitud</h5>
        </Modal.Header>
        <Modal.Body>
          <p>¿Realmente desea eliminar la solicitud {idDelete}?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setModalDelete(false)
            }}
          >
            Cancelar
          </Button>
          <Button
           className="btn btn-danger"
            onClick={() => {
              deleteSol()
              setModalDelete(false)
            }}
          >
            Eliminar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default FunctionsManual
