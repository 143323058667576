import Moment from 'moment'
import { useEffect, useState, useRef } from 'react'
import { Button, Col, Form, Row, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import Signer from '../signer'
import { CreateFormat, getFormats, newWorkflow, updateFormat, updateWorkflow } from './request'
import { useUserContext } from '../../../../hooks/custom'
import SpinnerLoad from '../spinnerLoad'
import image from '../../../../assets/img/Logo_b-st.png'
import SelectOption from '../SelectOption'
import { monedaCOP } from '../functions'
import ReactDOM from 'react-dom';
import { RefundTable } from '../../accounting/advance/refund/RefundTable'
import FileManager from '../FileManager'
export const COFIFOR161 = ({
  show,
  hide,
  restart,
  setRestart,
  isView,
  data = '',
  setData,
  isSign = false,
  isAccount = false,
  workflow = [],
  IdFormat_PK,
  isOper = false


}) => {
  /*---------ESTADOS------------------
      [     Revisar      ] => documento creado  firmado por elaborador
              V     
      [    Revisado  ] => documento firmado por el jefe
              V   
      [    aprobado   ] => documento firmado por el lider de area
              V
      [   Finalizado    ] => documento firmado por el gerente general
              V
     
  */
  const wf = {

    creando: "Firmar RQ Jefe",
    firmaAprobado: "Firmar RQ Lider Area",
    firmaLiderA: "Aprobar RQ",
    Aprobar: "Aprobar RQ"
  }
  const wfsign = {

    creando: "firmaAprobador",
    firmaAprobado: "firmaLider",
    // firmaLiderA: "firmaGerente"
  }
  const estado = {
    creando: "firmaAprobado",
    firmaAprobado: "firmaLiderA",
    firmaLiderA: "Aprobar",
    Aprobar: "Procesar",
    Procesar: "Finalizado"
  }


  //-------------------------Info Formato
  const formato = 'COFI-FOR-161'
  const revision = '0'
  const fechaRev = '06/03/2018'
  const type = 'COFIFOR161'
  const nombreFormato = 'REEMBOLSO DE GASTOS'
  const typef = 'COFIFOR161'

  //-------------------------Registros

  const printRef = useRef(null);
  const { sessionUser } = useUserContext()
  const [date, setDate] = useState('')
  const [firmaSolicitante, setfirmaSolicitante] = useState('')
  const [firmaJefe, setfirmaJefe] = useState('')
  const [recibidoContabilidad, setrecibidoContabilidad] = useState('')
  const [revisadoOperaciones, setrevisadoOperaciones] = useState('')
  const [nombreEmpresa, setnombreEmpresa] = useState('')
  const [listaLider, setListaLider] = useState([])
  const [empleados, setEmpleados] = useState('')
  const [modalAction, setModalAction] = useState(false)
  const [actionBtn, setActionBtn] = useState("Rechazar")
  const [tblRefund, setTblRefund] = useState()
  const [subtotal, setSubtotal] = useState(0)
  const [files, setFiles] = useState({})


  useEffect(() => {
    if (!restart) {
      setRestart(!restart)
      if (show) getInfo()
    }
  }, [restart])

  useEffect(() => {
    let saldoC = data.subtotal > subtotal ? data.subtotal - subtotal : 0
    let saldoT = data.subtotal <= subtotal ? subtotal - data.subtotal : 0
    setData({ ...data, reintegroCompañia: saldoC, reintegroTrabajador: saldoT })

  }, [tblRefund, subtotal])

  useEffect(() => {
    async function fetchData() {
      // Obtenemos el nodo DOM asociado a la referencia printReq
      const printReqNode = ReactDOM.findDOMNode(printRef.current);
      // Verificamos si el nodo existe antes de continuar
      if (printReqNode) {
        // Convertimos el nodo a texto utilizando outerHTML
        const printReqTexto = printReqNode.outerHTML;
        // Aquí puedes hacer lo que necesites con la variable printReqTexto
        setData({ ...data, formatData: printReqTexto })
        const result = await updateFormat({ IdFormat_PK: data.IdFormat_PK, formatData: printReqTexto }, typef)
      }
    }
    if (data.Status === "Finalizado" && !data.formatData && recibidoContabilidad.NombreTrabajador) {
      fetchData();
    }
  }, [recibidoContabilidad.NombreTrabajador])



  const handleChange = async ({ target }) => {
    const { name, type } = target
    const value = type === 'checkbox' ? target.checked : target.value
    setData({ ...data, [name]: value, })
  }

  const ActionSol = async () => {
    let motRachazo = ""
    let newStatus = ""
    if (actionBtn === "Rechazar") {
      newStatus = "Rechazado"
      motRachazo = data.rechazo + "\n" + sessionUser.NombreTrabajador + " " + sessionUser.ApellidoTrabajador + "\n" + 'fecha: ' + date
    }
    if (actionBtn === "Reanudar") {
      if (workflow.action === "Aprobar") newStatus = "firmaAprobador"
    }
    var result = await updateFormat({ IdFormat_PK: IdFormat_PK, Status: newStatus, rechazo: motRachazo }, typef)
    if (result.data.status === 200) {
      await updateWorkflow({ ...workflow, Status: actionBtn === "Rechazar" ? "rechazado" : 'pendiente', dateFinish: date })
      toast.success(result.data.msj)
    } else {
      toast.error(result.data.msj)
    }
    clearStates()
    hide(!show)
    setRestart(!restart)

  }



  const getInfo = async () => {
    const formatDate = Moment().format('YYYY-MM-DD')
    setDate(formatDate)
    let resDNI = await (await getFormats('employee')).data.data.filter(item => item.Status === "activo")
    setListaLider(resDNI.filter(item => item.personalACargo.includes("Si")))
    setEmpleados(resDNI)
    if (data.Status === 'Legalizando') {
      let UserSign = JSON.parse(data.firmaSolicitante)
      setfirmaSolicitante({
        ApellidoTrabajador: UserSign.ApellidoTrabajador,
        IdEmployee_FK: UserSign.IdEmployee_FK,
        NombreTrabajador: UserSign.NombreTrabajador,
        fecha: '',
      })
      UserSign = JSON.parse(data.firmaAprobado)
      setfirmaJefe({
        ApellidoTrabajador: UserSign.ApellidoTrabajador,
        IdEmployee_FK: UserSign.IdEmployee_FK,
        NombreTrabajador: UserSign.NombreTrabajador,
        fecha: '',
      })
      setrecibidoContabilidad({
        ApellidoTrabajador: ' recepción',
        IdEmployee_FK: '',
        NombreTrabajador: 'sin',
        fecha: '',
      })
      setrevisadoOperaciones({
        ApellidoTrabajador: ' revisión',
        IdEmployee_FK: '',
        NombreTrabajador: 'sin',
        fecha: '',
      })
      setnombreEmpresa(JSON.parse(data.empresaPaga))
      setData({
        fechaEntrega: formatDate,
        fechaFin: '',
        CeCo: data.CeCo,
        IdAnticipo: data.IdFormat_PK,
        subtotal: Object.values(JSON.parse(data.tblAnt)).reduce((total, item) => total + item.subt, 0),
        reintegroCompañia: Object.values(JSON.parse(data.tblAnt)).reduce((total, item) => total + item.subt, 0),
        reintegroTrabajador: 0,
        numeroCuenta: data.numeroCuenta,
        banco: data.Bank,
        tipoCuenta: data.tipoCuenta,
        creadoPor: sessionUser.IdEmployee_FK,
        Status: data.Status
      })








    } else {
      setFiles(await data.files.indexOf("{") !== -1 ? JSON.parse(data.files) : {})
      setTblRefund(await JSON.parse(data.tblRefund))
      setfirmaSolicitante(await JSON.parse(data.firmaSolicitante))
      setfirmaJefe(await JSON.parse(data.firmaJefe))
      setrecibidoContabilidad(await JSON.parse(data.recibidoContabilidad))
      if (isOper) {

      }
      else
        setrevisadoOperaciones(await JSON.parse(data.revisadoOperaciones))
      setnombreEmpresa(await JSON.parse(data.nombreEmpresa))
      setFiles(await JSON.parse(data.files))
      if (isOper && data.Status === 'Revisando') {
        setrevisadoOperaciones({
          ApellidoTrabajador: sessionUser.ApellidoTrabajador,
          IdEmployee_FK: sessionUser.IdEmployee_FK,
          NombreTrabajador: sessionUser.NombreTrabajador,
          fecha: formatDate
        })
      }

      if (isAccount && data.Status === 'Procesando') {
        setrecibidoContabilidad({
          ApellidoTrabajador: sessionUser.ApellidoTrabajador,
          IdEmployee_FK: sessionUser.IdEmployee_FK,
          NombreTrabajador: sessionUser.NombreTrabajador,
          fecha: formatDate
        })
      }
      if (isSign && data.Status === 'Aprobar') {
        setData({ ...data, Status: 'Aprobando' })
      }
    }

  }
  const clearStates = () => {
    data = []
    setData('')
    setDate('')
  }

  const onSubmit = async e => {
    e.preventDefault()
    var nextId_WF = ""
    var nextWF = []
    if (subtotal === 0) {
      toast.error('debe incluir al menos un (1) item')
      return
    }
    if (
      (data.Status === 'Legalizando' && sessionUser.IdEmployee_FK === firmaSolicitante.IdEmployee_FK && !firmaSolicitante.image) ||
      (isSign && sessionUser.IdEmployee_FK === firmaJefe.IdEmployee_FK && !firmaJefe.image)
    ) {
      toast.error('por favor firme el documento')
      return
    }
    var result = ''
    data = {
      ...data,
      firmaSolicitante: JSON.stringify(firmaSolicitante),
      firmaJefe: JSON.stringify(firmaJefe),
      recibidoContabilidad: JSON.stringify(recibidoContabilidad),
      revisadoOperaciones: JSON.stringify(revisadoOperaciones),
      nombreEmpresa: JSON.stringify(nombreEmpresa),
      tblRefund: JSON.stringify(tblRefund),
      files: JSON.stringify(files),

    }
    if (data.Status === 'Legalizando') {
      data['Status'] = 'Revisar'
      delete data.IdFormat_PK
      result = await CreateFormat(data, typef)
      if (result.data.status === 200) {
        result = await updateFormat({ Status: 'Legalizando', IdFormat_PK: data.IdAnticipo }, 'COFIFORANT')
        if (result.data.status === 200) {
          toast.success("Legalización creada exitosamente")
        }
        else {
          toast.error(result.data.msj); return
        }
      }
      else {
        toast.error(result.data.msj); return
      }
    }
    else {
      delete data.firmaSolicitante
      delete data.fechaActualizacion
      delete data.fechaCreacion

      if (data.Status === 'edicion') {
        data['Status'] = 'Revisar'
      }
      else if (data.Status === 'Revisando') {
        data['Status'] = 'Aprobar'
        nextWF = {
          TaskDescription: 'Legalizacion Gastos de ' + firmaSolicitante.NombreTrabajador,
          action: "Aprobar",
          Status: "pendiente",
          Format: type,
          dateCreation: date,
          documents: "Legalización Gastos ",
          IdFormat_FK: data.IdFormat_PK,
          IdEmployee_created: sessionUser.IdEmployee_FK,
          IdEmployee_FK: firmaJefe.IdEmployee_FK,
        }
        result = await newWorkflow(nextWF)
      }
      else if (data.Status === 'Aprobando') {
        data['Status'] = 'Procesar'

      }
      else if (data.Status === 'Procesando') {
        delete data.firmaJefe
        data['Status'] = 'Finalizado'
      }

      result = await updateFormat(data, typef)
      if (result.data.status === 200) {
        if (data.Status === 'Finalizado')
          result = await updateFormat({ Status: 'Finalizado', IdFormat_PK: data.IdAnticipo }, 'COFIFORANT')

        else
          await updateWorkflow({ ...workflow, Status: 'finalizado', dateFinish: date })
        toast.success("Auxilio actualizado exitosamente")
      } else {
        toast.error(result.data.msj); return
      }
    }
    clearStates()
    hide(!show)
    setRestart(!restart)

  }

  return (
    <>
      {show && (
        <Overlay style={{ zIndex: '1' }}>
          {/* <SpinnerLoad trigger={firmaJefe} /> */}
          <ModalContainer id="modal-container">
            <CloseButtonModal className="no-print"
              onClick={() => {
                clearStates()
                hide(!show)
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-x-square"
                viewBox="0 0 16 16"
              >
                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </CloseButtonModal>
            <Form onSubmit={onSubmit}>
              {data.formatData && false ? <div dangerouslySetInnerHTML={{ __html: data.formatData }}></div> : <PrintArea ref={printRef} >          <ModalHeader>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <th style={{ width: '20%', fontSize: '10px', verticalAlign: 'bottom' }} rowSpan={3}>
                        <img
                          className="format_logo"
                          src={image}
                          style={{ paddingBottom: '7px', width: '55%', minWidth: "40px" }}
                          alt="Logo RMS"
                        /><br />
                        NIT 830.507.705-2
                      </th>
                      <th style={{ width: '50%', height: '35px' }}>RELIABILITY MAINTENANCE SERVICES S.A.S</th>
                      <th style={{ width: '20%' }}>{formato}</th>
                    </tr>
                    <tr>
                      <th rowSpan={2} >{nombreFormato}</th>
                      <th style={{ fontSize: '10px', height: '5px' }}>Revisión: {revision}</th>
                    </tr>
                    <tr>
                      <th style={{ fontSize: '10px' }}>Fecha de revisión: {fechaRev}</th>
                    </tr>
                  </tbody>
                </table>
              </ModalHeader>
                <Frame isView={isView}>
                  <table>
                    <tbody>
                      <tr  >
                        <td style={{ width: '15%' }}></td>
                        <td style={{ width: '11%' }}></td>
                        <td style={{ width: '15%' }}><label> Fecha de Entrega:</label> </td>
                        <td style={{ width: '10%' }}>
                          {data.fechaEntrega}
                        </td>  <td style={{ width: '10%' }}><label> Solicitud:</label> </td>
                        <td style={{ width: '15%' }}>
                          {IdFormat_PK}
                        </td>
                        <td style={{ width: '10%' }}><label> Estado:</label></td>
                        <td style={{ width: '20%' }}>
                          {data.Status}
                        </td>
                      </tr>

                      <tr  >
                        <td ><label> Nombre del solicitante:</label> </td>
                        <td colSpan={3} >
                          <input
                            className="disable"
                            value={firmaSolicitante.NombreTrabajador + ' ' + firmaSolicitante.ApellidoTrabajador}
                            onChange={e => { }}
                            disabled
                          />
                        </td>
                        <td ><label> numero C.C.:</label></td>
                        <td >
                          <input
                            className="disable"
                            value={firmaSolicitante.IdEmployee_FK}
                            onChange={e => { }}
                            disabled
                          />
                        </td>
                      </tr>
                      <tr  >
                        <td ><label> Empresa:</label> </td>
                        <td colSpan={3} >
                          <input
                            className="disable"
                            value={nombreEmpresa.SocialName}
                            onChange={e => { }}
                            disabled
                          />
                        </td>
                        <td ><label> NIT.:</label></td>
                        <td >
                          <input
                            className="disable"
                            value={nombreEmpresa.IdCompany_PK}
                            onChange={e => { }}
                            disabled
                          />
                        </td>
                      </tr>

                    </tbody>
                  </table>
                </Frame>


                <Frame isView={isView}>
                  <RefundTable data={tblRefund} setData={setTblRefund} isView={isView} CeCo={data.CeCo} setSubtotal={setSubtotal} />

                  <table >
                    <tbody >
                      <tr>
                        <td style={{ width: '180px' }} ></td>
                        <td style={{ width: '105px' }}><label>Cuenta Bancaria:</label></td>
                        <td style={{ width: '120px' }}>
                          <input
                            name="numeroCuenta"
                            value={data.numeroCuenta}
                            onChange={handleChange}
                            required
                            disabled={isView ? true : false}
                          /></td>

                        <th colSpan={5} style={{ width: '592px' }}> menos Anticipo:</th>
                        <th style={{ width: '100px', textAlign: 'end', paddingRight: '5px' }}>{monedaCOP(data.subtotal)}</th>
                      </tr>
                      <tr>
                        <td ></td>
                        <td ><label> Banco:</label></td>
                        <td >
                          <SelectOption type={"Bank"} searchable={true} state={data} setState={setData} widthSelect='144px' nameState={'banco'} required disabled={isView ? true : false} />
                        </td>

                        <th colSpan={5} style={{ width: '592px' }}>Saldo a reintegrar a la compañia:</th>
                        <th style={{ width: '100px', textAlign: 'end' }}>{monedaCOP(data.reintegroCompañia)}</th>
                      </tr>
                      <tr>
                        <td  ></td>
                        <td ><label> Tipo de Cuenta:</label></td>
                        <td >
                          <SelectOption type={"typeAccount"} searchable={true} state={data} setState={setData} nameState={'tipoCuenta'} required disabled={isView ? true : false} />
                        </td>

                        <th colSpan={5} style={{ width: '592px' }}>Saldo a reintegrar al empleado:</th>
                        <th style={{ width: '100px', textAlign: 'end' }}>{monedaCOP(data.reintegroTrabajador)}</th>
                      </tr><br />
                      <tr>

                        <td style={{ textAlign: 'center', borderBottom: '1px', verticalAlign: 'bottom' }}>
                          <br></br>
                          <input
                            style={{ borderBottom: '0px' }}
                            className="disable "
                            value={recibidoContabilidad.NombreTrabajador + ' ' + recibidoContabilidad.ApellidoTrabajador}
                            onChange={e => { }}
                            disabled
                          />

                        </td>

                        <td colSpan={2} style={{ width: '100px', textAlign: 'center', verticalAlign: 'bottom' }}>
                          <Signer
                            enable={sessionUser.IdEmployee_FK === firmaSolicitante.IdEmployee_FK}
                            sign={firmaSolicitante}
                            setSign={setfirmaSolicitante}
                            doc={type}
                            user={sessionUser.IdEmployee_FK}
                            isView={isView}
                          />

                        </td>
                        <td colSpan={2} style={{ textAlign: 'center' }}>
                          <Signer
                            enable={isSign && sessionUser.IdEmployee_FK === firmaJefe.IdEmployee_FK}
                            sign={firmaJefe}
                            setSign={setfirmaJefe}
                            doc={typef}
                            user={sessionUser.IdEmployee_FK}
                            width='50%'
                          />
                        </td>
                        <td colSpan={2} style={{ width: '100px', textAlign: 'center', borderBottom: '1px', verticalAlign: 'bottom' }}>
                          <input
                            style={{ borderBottom: '0px' }}
                            className="disable "
                            value={revisadoOperaciones.NombreTrabajador + ' ' + revisadoOperaciones.ApellidoTrabajador}
                            onChange={e => { }}
                            disabled
                          />

                        </td>
                        <td><label>Fecha:</label></td>
                        <td>{date}</td>
                      </tr>
                      <tr style={{ borderTop: '1px', textAlign: 'center', alignContent: 'center' }}>
                        <td   ><hr style={{ margin: '0px 10px 0px 10px', padding: '0px', border: '1px solid black' }} />
                          <label>
                            VoBo Gestor Administrativo:</label>
                        </td>
                        <td colSpan={2} style={{ textAlign: 'center' }} >
                          <hr style={{ margin: '0px 10px 0px 10px', padding: '0px', border: '1px solid black' }} />
                          <label style={{ alignSelf: 'center' }}>
                            Firma de quien legaliza:</label>
                        </td>
                        <td colSpan={2} style={{ width: '400px', textAlign: 'center' }} >
                          <hr style={{ margin: '15px 10px 0px 10px', padding: '0px', border: '1px solid black' }} />
                          {firmaJefe.NombreTrabajador + ' ' + firmaJefe.ApellidoTrabajador}<br />
                          <label>
                            Firma (Autorizador) Líder de Área</label>
                        </td>
                        <td colSpan={2} style={{ width: '400px', textAlign: 'center' }}>
                          <hr style={{ margin: '0px 10px 0px 10px', padding: '0px', border: '1px solid black' }} />
                          <label>
                            Reviso Cost Control:</label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Frame>
                <table>
                  <tbody style={{ paddingLeft: "10px", paddingRight: "10px", fontSize: '9px', maxHeight: '10px', height: '10px' }} >
                    <tr>
                      <td><strong>Recomendaciones que se deben tener en cuenta:</strong><br />
                        1. RMS S.A. tiene la obligacion de realizar retencion en la fuente, Rte Iva y Rte ICA siempre y cuando la compra del bien y/o servicio supere la base minima de retencion el fuente<br />
                        Ver tabla de retencion en la fuente vigente para el año gravable 2023 <br />
                        2. Las facturas deben estar a nombre de RMS S.A.<br />
                        3. Las compras bienes y/o servicios que se realicen por primera vez al regimen simplificado se les debe adjuntar fotopia del RUT (Registro Unico Tributario)
                      </td></tr>
                  </tbody>
                </table>
              </PrintArea>}
              <br />
              <Frame className="no-print">

                <Row style={{ width: '100%', minWidth: '100%' }}>
                  <FileManager name={'soporte'} stateFile={files} setStateFile={setFiles} path={`${typef}/${data.IdFormat_PK}`} pdf={true} multiple disable={isView} />
                </Row>

              </Frame>
              <Row className="no-print">
                <Col></Col>
                <Col></Col>
                <Col></Col>

                <Col></Col>

                {(!isView || (data.Status !== 'Rechazado' && isSign) || (isAccount && data.Status === 'Procesando') || (isOper && data.Status === 'Revisando')) ? (
                  <>
                    <Col>
                      <Button
                        className="btn btn-danger"
                        onClick={() => {
                          clearStates()
                          hide(!show)
                        }}
                      >
                        Cancelar
                      </Button>
                    </Col>

                    <Col>
                      <Button type="submit" className="btn btn-success">
                        {isAccount && data.Status === 'Procesando' ? 'Finalizar' : 'Guardar'}
                      </Button>
                    </Col>
                  </>
                ) : (
                  <Col>
                    <Button
                      className="btn btn-secundary no-print"
                      onClick={() => {
                        clearStates()
                        hide(!show)
                      }}
                    >
                      Cerrar
                    </Button>
                  </Col>
                )}

                {data.Status !== 'creando' && isSign && <Col>
                  <Button
                    className="btn btn-warning"
                    onClick={() => {
                      setActionBtn(data.Status === 'Rechazado' ? "Reanudar" : 'Rechazar')
                      setModalAction(true)
                    }}
                  >
                    {data.Status === "Rechazado" ? 'Reanudar' : 'Rechazar'}
                  </Button>
                </Col>}
              </Row>

            </Form>
          </ModalContainer>
        </Overlay>
      )}
      <Modal
        show={modalAction}
        onHide={() => {
          setModalAction(false)
        }}
        centered
      >
        <Modal.Header closeButton>
          <h5>{actionBtn} Solicitud</h5>
        </Modal.Header>
        <Modal.Body>
          <p>¿Realmente desea {actionBtn.toLowerCase()} la solicitud {IdFormat_PK}? </p>

          {actionBtn === "Rechazar" && <><p>Por favor especifique el motivo para rechazar la solicitud de Auxilio</p>
            <textarea
              style={{ width: '100%', height: '60px' }}
              name={'rechazo'}
              value={data.rechazo}
              onChange={handleChange}

              disabled={false}
            /></>}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setData({ ...data, rechazo: "" })
              setModalAction(false)
            }}
          >
            Cancelar
          </Button>
          <Button className="btn btn-danger"
            onClick={() => {
              if (data.rechazo === "") {
                toast.error('indique el motivo para rechazar')
                return
              }
              ActionSol()
              setModalAction(false)
            }}
          >
            {actionBtn}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default COFIFOR161

const PrintArea = styled.div`
width: 20.3cm;
`
const FrameTable = styled.div`
input {
    border-bottom: 0px solid black;
}
input:disabled {

    background: #fff;
    border-bottom: 0px solid black;
}
label{
    font-weight:10;
}
td{
    border: 1px solid black;
}
}
`

const Frame = styled.div`
  width: 30cm;
  padding: 4px;
  
  align-items: center;
  justify-content: left;
  border-width: 1px;
  border-top-width: 0px;
  border-bottom-width: 1px;
  border-color: #1a004a;
  border-style: solid;
  font-size: 10px;
  font-weight: 1000;
  
  table{
    width:100%
  }

  .rms {
    background: #f2f2f2;
  }
  label {
    padding-left: 5px;
    display: flex;
    font-size: 11px;
    font-weight: bold;
    text-align: 'center';
    align-content: center;
    justify-content: center
  }
  select {
    
    background: ${props => (props.isView ? '#FFF' : '#cee1ed')};
    
  
}
  input {
    text-align: center;
    width: 100%;
    border-bottom: 1px solid black;
    border-color: #1a004a;
    background: ${props => (props.isView ? '#FFF' : '#cee1ed')};
    padding-left: 5px;
  }
  textarea {
    text-align: justify;
    width: 100%;
    border-bottom: 1px solid black;
    border-color: #1a004a;
    background: ${props => (props.isView ? '#FFF' : '#cee1ed')};
    resize: none;
    padding-left: 5px;
  }
  input.edit {
    background: #cee1ed;
  }
  input.disable {
    background: #fff;
  }
  h2 {
    font-size: 14px;
    font-weight: bold;
    text-align: 'center';
  }
  th {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    text-align: left;
    vertical-align: center;
    padding-left: 5px;
    input {
      width: 100%;
    }
  }
  
  tr {
    border-width: 0px;
    border-color: #1a004a;
    border-style: solid;
  }
  td {
    border-width: 0px;
    border-color: #1a004a;
    border-style: solid;
  }
`

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgb(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalContainer = styled.div`
  
  width: 30.6cm;
  max-height: 90vh;
  min-height: 90vh;
  background: #fff;
  position: relative;
  border-radius: 5px;
  overflow-x: scroll;
  overflow-y: scroll;
  
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 0.3cm;

  @media print {
    padding-top: 20px;
    padding-left: 0px;
    max-height: 100vh;
    min-height: 100vh;
    min-height: 21cm;
    width: 100%;
    height: 21cm;
    width:30cm;
    min-width:30cm;
    /* Estilos para la versión impresa */
    overflow: hidden;
    /* Aquí ocultamos los componentes que no queremos imprimir */
    @page {
     size:  30.6cm 21cm; /* Puedes usar diferentes tamaños de papel como A4, Letter, etc. */
      margin: 0; /* Márgenes laterales en 0 */
      padding: 0px;
      orientation: landscape ; /* Cambiar a 'landscape' si necesitas orientación horizontal */
     writing-mode: rl;
    }

    body{
              writing-mode: rl;
      
    }
      /* Ajustar el área de impresión */
    .no-print {
      display: none;
    }
} 

`


const ModalHeader = styled.div`
  width: 30cm;
  display: flex;

  align-items: center;
  justify-content: space-between;

  td {
    font-weight: 5000;
    
  }
  th {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    
  }
  tr {
    border-width: 0px;
    border-color: #1a004a;
    border-style: solid;
  }
  table {
    border-width: 0px;
    border-bottom-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    text-align: center;
    vertical-align: center;
    font-size: 14px;
  }
  h3 {
    font-weight: 500;
    font-size: 16px;
    color: #1766dc;
  }
`

const CloseButtonModal = styled.div`
  position: absolute;
  top: 12px;
  right: 5px;
  width: 20px;
  height: 20px;
  border: none;
  margin: 0px;
  padding:0px;
  background: none;
  cursor: pointer;
  transition: 0.3s ease all;
  border-radius: 5px;
  color: #1766dc;
  &:hover {
    background: #f2f2f2;
    
  }
  svg {
    width: 100%;
    height: 100%;
  }
`

