import { Accordion } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import FinancialAid from './financialAid/FinancialAid'
import Refund from './refund/Refund'



export default function Advance  (props) {
  const [restartView, setRestartView] = useState(true)
  const userStatus = localStorage.getItem("Status")


  useEffect(() => {

  }, [restartView])

  return (
    <Accordion >
      <Accordion.Item eventKey="0">
        <Accordion.Header>Auxilio</Accordion.Header>
        <Accordion.Body>
        <FinancialAid
            put={props.put}
            delete={props.delete}
            post={props.post}
            sessionUser={props.sessionUser}
            dense={true}
            format={'COFIFOR271'}
            newBotton={false}
            uniqueUser={false}
            route={'/accounting'}
            isAccount={true}
          /> 
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="1">
        <Accordion.Header>Anticipos </Accordion.Header>
        <Accordion.Body>
          <FinancialAid
            put={props.put}
            delete={props.delete}
            post={props.post}
            sessionUser={props.sessionUser}
            dense={true}
            format={'COFIFOR159'}
            newBotton={false}
            uniqueUser={false}
            route={'/accounting'}
            isAccount={true}
          /> 
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2" >
        <Accordion.Header>Legalizacion Gastos</Accordion.Header>
        <Accordion.Body>
        <Refund
            put={props.put}
            delete={props.delete}
            post={props.post}
            sessionUser={props.sessionUser}
            dense={true}
            newBotton={false}
            uniqueUser={false}
            route={'/accounting'}
            isAccount={true}
          /> 
        </Accordion.Body>
      </Accordion.Item>

    </Accordion>
  )
}

