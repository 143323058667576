import { Accordion } from 'react-bootstrap'

import { useEffect } from 'react'
import RQ from './RQ/RQ'




const SuppliesTab = props => {
  const restartView = true


  useEffect(() => {

  }, [restartView])

  return (
    <Accordion >
      <Accordion.Item eventKey="0">
        <Accordion.Header>RQ</Accordion.Header>
        <Accordion.Body>
          <RQ
            put={props.put}
            delete={props.delete}
            post={props.post}
            sessionUser={props.sessionUser}
            newBotton={false}
            route={'/supplychain'}
            uniqueUser={false}
            isSP={true}
          />
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="1">
        <Accordion.Header>Ordenes de Compra</Accordion.Header>
        <Accordion.Body>
          {/* <Vacation
            put={props.put}
            delete={props.delete}
            post={props.post}
            sessionUser={props.sessionUser}
            dense={true}
            newBotton={false}
            uniqueUser={false}
            route={'/humantalent'}
            isTH={true}
          /> */}
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2" >
        <Accordion.Header>Remisiones</Accordion.Header>
        <Accordion.Body>
          {/* <Notice
            put={props.put}
            delete={props.delete}
            post={props.post}
            dense={true}
            sessionUser={props.sessionUser}
            setRestartView={setRestartView}
            restartView ={restartView}
            newBotton={false} /> */}
        </Accordion.Body>
      </Accordion.Item>

    </Accordion>
  )
}
export default SuppliesTab
