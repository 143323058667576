import axios from "axios";
import crypto from "crypto-js";
import ApiURL from "../../../services/apirest";
import { Cities } from "../../constant/data";
export const encodeUserPermission = (JSONRoles, key) => {
  try {
    return crypto.AES.encrypt(JSON.stringify(JSONRoles), key).toString();
  } catch (error) {
    localStorage.clear()
    window.location.href = "/"
    window.location.reload()
  }
};

export const permissionInit = {
  user: { access: false, PUT: false, DELETE: false, POST: false },
  companies: { access: false, PUT: false, DELETE: false, POST: false },
  employes: { access: true, PUT: true, DELETE: true, POST: true },
  client: { access: false, PUT: false, DELETE: false, POST: false },
  commercial: { access: false, PUT: false, DELETE: false, POST: false },
  operations: { access: false, PUT: false, DELETE: false, POST: false },
  logistic: { access: false, PUT: false, DELETE: false, POST: false },
  hseq: { access: false, PUT: false, DELETE: false, POST: false },
  technical: { access: false, PUT: false, DELETE: false, POST: false },
  leader: { access: false, PUT: false, DELETE: false, POST: false },
  accounting: { access: false, PUT: false, DELETE: false, POST: false },
  supplychain: { access: false, PUT: false, DELETE: false, POST: false },
  humanTalent: { access: false, PUT: false, DELETE: false, POST: false },
  manager: { access: false, PUT: false, DELETE: false, POST: false },
  admin: { access: false, PUT: false, DELETE: false, POST: false },
  superAdmin: { access: false, PUT: false, DELETE: false, POST: false }

}


export const decodeUserPermission = (encryptedRoles, key) => {

  try {

    let decode = JSON.parse(crypto.AES.decrypt(encryptedRoles.toString(), key).toString(crypto.enc.Utf8));

    return decode
  } catch (error) {


    // localStorage.clear()
    // window.location.reload()
    return undefined
  }
};

export const sessionActive = async () => {
  try {
    const config = {
      headers: { 'x-access-token': localStorage.getItem('TOKEN') },
    }
    const data = {
      IdUser_PK: localStorage.getItem('IdUser_PK'),
    }

    const res = await axios.post(ApiURL + 'auth/isSignin', data, config)

    if (res.data.status === 200) {
      return true
    } else {
      localStorage.clear()
      window.location.href = "/"
      window.location.reload()
    }
  } catch (error) {
    localStorage.clear()
    window.location.href = "/"
    window.location.reload()
  }
}

export function EnLetras2(valor) {
  const unidades = ['', 'UNO', 'DOS', 'TRES', 'CUATRO', 'CINCO', 'SEIS', 'SIETE', 'OCHO', 'NUEVE'];
  const especiales = ['', 'ONCE', 'DOCE', 'TRECE', 'CATORCE', 'QUINCE', 'DIECISÉIS', 'DIECISIETE', 'DIECIOCHO', 'DIECINUEVE'];
  const decenas = ['', 'DIEZ', 'VEINTE', 'TREINTA', 'CUARENTA', 'CINCUENTA', 'SESENTA', 'SETENTA', 'OCHENTA', 'NOVENTA'];
  const centenas = ['', 'CIENTO', 'DOSCIENTOS', 'TRESCIENTOS', 'CUATROCIENTOS', 'QUINIENTOS', 'SEISCIENTOS', 'SETECIENTOS', 'OCHOCIENTOS', 'NOVECIENTOS'];
  const divisor = 1000000;
  const millones = Math.floor(valor / divisor);
  valor %= divisor;
  const miles = Math.floor(valor / 1000);
  valor %= 1000;
  const cientos = valor;

  let resultado = '';

  if (millones > 0) {
    resultado += `${EnLetras(millones)} MILLÓN${millones > 1 ? 'ES' : ''} `;
  }

  if (miles > 0) {
    if (miles === 1 && cientos === 0) {
      resultado += 'MIL ';
    } else {
      resultado += `${EnLetras(miles)} MIL `;
    }
  }

  if (cientos > 0) {
    if (cientos === 100) {
      resultado += 'CIEN';
    } else if (cientos < 10) {
      resultado += `${unidades[cientos]} `;
    } else if (cientos < 20) {
      resultado += `${especiales[cientos - 10]} `;
    } else {
      resultado += `${centenas[Math.floor(cientos / 100)]} `;
      resultado += `${decenas[Math.floor((cientos % 100) / 10)]} `;
      resultado += `${unidades[cientos % 10]} `;
    }
  }

  return resultado.trim();
}

export function EnLetras(valor) {
  if(valor === 0 )
    return 'CERO '
  const unidades = ['', 'UN', 'DOS', 'TRES', 'CUATRO', 'CINCO', 'SEIS', 'SIETE', 'OCHO', 'NUEVE'];
  const especiales = ['', 'ONCE', 'DOCE', 'TRECE', 'CATORCE', 'QUINCE', 'DIECISÉIS', 'DIECISIETE', 'DIECIOCHO', 'DIECINUEVE'];
  const decenas = ['', 'DIEZ', 'VEINTE', 'TREINTA', 'CUARENTA', 'CINCUENTA', 'SESENTA', 'SETENTA', 'OCHENTA', 'NOVENTA'];
  const centenas = ['', 'CIENTO', 'DOSCIENTOS', 'TRESCIENTOS', 'CUATROCIENTOS', 'QUINIENTOS', 'SEISCIENTOS', 'SETECIENTOS', 'OCHOCIENTOS', 'NOVECIENTOS'];
  const divisor = 1000000;
  const millones = Math.floor(valor / divisor);
  valor %= divisor;
  const miles = Math.floor(valor / 1000);
  valor %= 1000;
  const cientos = valor;

  let resultado = '';

  if (millones > 0) {
    resultado += `${EnLetras(millones)} MILLÓN${millones > 1 ? 'ES' : ''} `;
  }

  if (miles > 0) {
    if (miles === 1 && cientos === 0) {
      resultado += 'MIL ';
    } else {
      resultado += `${EnLetras(miles)} MIL `;
    }
  }

  if (cientos > 0) {
    if (cientos === 100) {
      resultado += 'CIEN';
    } else if (cientos < 10) {
      resultado += `${unidades[cientos]} `;
    } else if (cientos < 20) {
      resultado += `${especiales[cientos - 10]} `;
    } else {
      resultado += `${centenas[Math.floor(cientos / 100)]} `;
      const decenasValue = Math.floor((cientos % 100) / 10);
      const unidadesValue = cientos % 10;
      if (decenasValue === 1) {
        resultado += `${especiales[unidadesValue]} `;
      } else {
        resultado += `${decenas[decenasValue]} `;
        resultado += `${unidades[unidadesValue]} `;
      }
    }
  }

  return resultado.trim();
}

export function onlyNumeric(text) {
  const inputValue = `${text}`;

  const numericValue = inputValue.replace(/[^\d,-]/g, ''); // Elimina todos los caracteres no numéricos excepto la coma y el guion
  
  return numericValue.replace('.','');
}

export function onlyNumericEvent(e) {
  const charCode = e.which ? e.which : e.keyCode;
                            const char = String.fromCharCode(charCode);
                            const regex = /^[0-9]*$/;
                            if (!regex.test(char)) {
                              e.preventDefault();
                            }
}

export function capitalLeter(text, minWordLength) {
  const input = text;
  const words = input.toLowerCase().split(' ');

  const transformedInput = words.map(word => {
    if (word.length < minWordLength) {
      return word;
    } else {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }
  }).join(' ');

  return transformedInput
}

export function PrecioEnLetras(valor) {


  return EnLetras(valor) + ' PESOS (' + parseInt(valor).toLocaleString('es-CO', { style: 'currency', currency: 'COP' }).replace(/\.\d+$/, '') + ')'

}
export function RevertMonedaCOP(valor) {
  
  const montoNumerico = valor.replace(/[^\d,.-]/g, '');
// Reemplazar cualquier coma por un punto para garantizar que el número sea reconocido correctamente
  const montoFormateado = montoNumerico.replace(',', '.');
  // Convertir el número formateado a un número de punto flotante
  const numero = parseInt(montoFormateado);

  return numero;
}


export function monedaCOP(valor) {
  
  if(valor===undefined || valor === null || valor ==='' )
  valor =0
  valor=` ${valor}`
  valor = valor.replace(/[\s$\.]/g, '');  
 
  return parseInt(valor).toLocaleString('es-CO', { style: 'currency', currency: 'COP' }).replace(/\.\d+$/, '').slice(0, -3)

}
export function porcentInput(valor) {
  valor=parseFloat(valor)

  if(valor===undefined || valor === null || valor ==='' ||  isNaN(valor))
  valor =0
  return `${valor}%`
}

export function cityName(cityCode) {
    if (cityCode)
    return Cities.find(i => parseInt(i.id_city) === parseInt(cityCode)).city
  return 'sin definir'

}

export function tieneTildes(texto) {
  var regex =/\p{Mark}/u;
  return regex.test(texto);
}

export function  printDocument  (printRef) {
  const printWindow = window.open('', '_blank');
  if (printWindow) {
    const styles = Array.from(document.styleSheets)
      .map(styleSheet => {
        try {
          return Array.from(styleSheet.cssRules)
            .map(rule => rule.cssText)
            .join('\n');
        } catch (error) {
          console.error('Error parsing CSS rules', error);
          return '';
        }
      })
      .join('\n');

    printWindow.document.write('<html><head><title>Print</title>');
    printWindow.document.write('<style>');
    printWindow.document.write(`
    /* Agrega los estilos obtenidos de la página actual */
    ${styles}

    /* Establece los márgenes */
    @page {
      margin: 7mm;
    }
    /* Oculta los elementos con la clase no-print al imprimir */
    .no-print {
      display: none !important;
    }
  `); // Agrega los estilos obtenidos de la página actual
    printWindow.document.write('</style></head><body>');
    // Agrega un evento afterprint para cerrar la ventana después de la impresión
  // printWindow.addEventListener('afterprint', () => {
  //   printWindow.close();
  // });
    printWindow.document.write(printRef.current.innerHTML);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
  } else {
    alert('Please allow popups for this website');
  }
};

