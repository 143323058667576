import React from 'react'
import { Accordion } from 'react-bootstrap'
import { NewAssigment } from './newAssigment'
import AssigmentView from './AssigmentView'


const Assignment = (props) => {
  
  return (
    <>
      <Accordion defaultActiveKey="1">
         <Accordion.Item eventKey="0">
          <Accordion.Header>Nueva asignación</Accordion.Header>
          <Accordion.Body>
            <NewAssigment></NewAssigment>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='1'>
          <Accordion.Header  >Asignaciones</Accordion.Header>
          <Accordion.Body >
            <AssigmentView put={props.put} delete={props.delete} post={props.post} sessionUser={props.sessionUser} ></AssigmentView>
          </Accordion.Body>
        </Accordion.Item>
       
      </Accordion>
    </>
  )
}

export default Assignment