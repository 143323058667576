import axios from 'axios'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import FilePath from '../../../services/filePath'
import DefaultIcon from '../../../assets/placeholder/Default.png'
import ExcelIcon from '../../../assets/placeholder/Excel.png'
import PDFIcon from '../../../assets/placeholder/PDF.png'
import PowerPointIcon from '../../../assets/placeholder/PowerPoint.png'
import WordIcon from '../../../assets/placeholder/Word.png'
import { deleteFile, uploadFile } from './request'
import { tieneTildes } from './functions'
/* ----------------------- Descripcion de Props -------------------------------------------------------------
          name:         string      // Nombre que muestra el archivo
          stateFile:        string      // nombre del estado que tiene el valor del campo 
          setStateFile:     function()  // funcion para cambio de estado
          nameState:    string      // nombre del subestado al que pertenece, si no pertenece a ninguno, el espacio va en blanco
          path:         string      // Ubicación donde se guardará el archivo
          image:        false/true  // Permite solo seleccionar imagenes
          multiple:     false/true  // despues de subri un archivo, da la opcion 
          pdf:          false/true  // Permite solo seleccionar pdf.
          imageView:    false/true  // True: Imagen sin nombre ni botón, para imagen del formulario.
          autoSubmit:   false/true  // se envia automaticamente la foto al seleccionarla
          link:         false/true  // en lugar de boton se muestra un link

  ---------------------------------------------------------------------------------------------------------*/

export default function FileManager({ name, stateFile, setStateFile, nameState, multiple = false, path, pdf = false, image = false, imageView = false, autoSubmit = false, width, height, disable = false, link }) {
  const fileIcons = {
    pdf: PDFIcon,
    document: WordIcon,
    sheet: ExcelIcon,
    presentation: PowerPointIcon,
    default: DefaultIcon,
  }

  const [file, setFile] = useState(null)
  const [previewUrl, setPreviewUrl] = useState(null)
  const [showButton, setShowButton] = useState(true)
  const [showSubmit, setShowSubmit] = useState(true)
  const [multiName, setMultiName] = useState([])
  const [restart, setRestart] = useState(true)

  useEffect(() => {
    if(stateFile === undefined) 
      setStateFile({})

    if (!!stateFile[name]?.path)
      setShowButton(false)
    if (multiple ) {
      if (Object.keys(stateFile).length === 0)
        setMultiName([])
      else {
        let temp = []

        Object.keys(stateFile).map(key => {
          temp.push(parseInt(key.match(/\d+/)[0]))
        })
        setMultiName(temp)
      }
    }
    if (file) {
      const fileReader = new FileReader()
      fileReader.onload = () => {
        setPreviewUrl(fileReader.result)
        if (autoSubmit) {
          handleUpload() // Llama a handleUpload después de configurar la vista previa
        }
      }
      fileReader.readAsDataURL(file)
    }
  }, [file, restart])

  const handleFileChange = event => {
    const selectedFile = event.target.files[0]
    if (selectedFile) {
      setFile(selectedFile)
      setShowButton(false)
      setShowSubmit(true)
    }
  }

  const getFileIcon = fileType => {
    const icon = Object.keys(fileIcons).find(key => fileType.endsWith(key))
    const iconComponent = icon ? fileIcons[icon] : fileIcons.default
    return <img height="40px" src={iconComponent} alt="File Icon" />
  }

  const renderFile = (isImageFile, fileType, nameShow) => {
    return (<div className='flexbox'>
      { disable && link ? <a style={{paddingRight:'10px', paddingTop: '10px'}} href={`${FilePath}/uploads/${stateFile[nameShow].path}`}>{nameShow}</a> :<div style={{marginBottom:multiple?'10px':'', marginRight:multiple?'10px':'', height:'100%', width:'100%'}} className="file-preview-container">
        <div className="position-relative bg-light file-preview" style={{ width: width || '200px', height: height || '40px' }}>
          {!disable && <a className="delete-button  " onClick={e => handleDelete(nameShow)}>
            <i
              className=" bi bi-trash"
              style={{ zIndex: 10, color: 'red', fontSize: '24px', cursor: 'pointer', position: 'absolute', top: '1px', left: '10px' }}
            ><label style={{ cursor: 'pointer', position: 'absolute', top: '26px', left: '-3px', fontSize: '10px' }} >borrar</label></i>
          </a>}
          <div style={{ cursor: 'pointer' }} className="position-absolute cursor-pointer top-50 start-50 translate-middle" onClick={e => handleShow(nameShow)}>
            {isImageFile ? <img style={{ cursor: 'pointer', zIndex: 1, width: 'auto', height: 'auto', maxWidth:width, maxHeight:height }} src={`${FilePath}/uploads/${stateFile[nameShow].path}`} alt="File Preview" /> : getFileIcon(fileType)}
          </div>
          <div style={{ cursor: 'pointer' }} className="position-absolute top-50 start-50 translate-middle cursor-pointer" onClick={e => handleShow(nameShow)}>
            {!isImageFile && <h5 className="file-name  text-primary text-capitalize">{nameShow}</h5>}
          </div>
        </div>
      </div>}</div>

    );

  }
  const subName = () => {
    let sufijo =''
    if(multiple){
      let i=multiName.length === 0 ? 1: multiName.length-1
      i = multiName[i]+1
    sufijo=multiName.length === 0 ? '_1':`_${i}`
   }
  return sufijo
  }
  const checkDuplicate = (nameFile) => {
    let check =false
    if(multiple && Object.keys(stateFile).length>0){
    Object.keys(stateFile).map(key => {
        if(stateFile[key].path === nameFile)
        check=true
      })
   }
  return check
  }

  const renderPreviewSingle = () => {
    if (stateFile[name]) {
      const fileType = stateFile[name].fileType || '';
      const isImageFile = fileType.startsWith('image/');
      return renderFile(isImageFile, fileType, name)
    }

    else if (file) { 
      
      const fileType = file.type || ''
      const isImageFile = fileType.startsWith('image/')
      if (tieneTildes(file.name)) {
        setFile(null)
        setShowButton(true)
        setShowSubmit(true)
        toast.error('el nombre del archivo no puede contener tildes o caracteres especiales')
        return
      }
      if (checkDuplicate(`${path}/${file.name}`)) {
        setFile(null)
        setShowButton(true)
        setShowSubmit(true)
        toast.error('el archivo se subio previamente')
        return
      }
      if (file.name.lenght > 50) {
        setFile(null)
        setShowButton(true)
        setShowSubmit(true)
        toast.error('el nombre del archivo es muy largo')
        setFile(null)
        return
      }
      if (file.size > 5000000) {
        setFile(null)
        setShowButton(true)
        setShowSubmit(true)
        toast.error('tamaño del archivo excede los 5mb')
        setFile(null)
        return
      }
      return (
        <div className="file-preview-container">
          <div className="position-relative bg-light file-preview" style={{ width: width || '200px', height: height || '40px' }}>
            <a className="delete-button  " onClick={e => handleCancel(`${name}${subName()}`)}>
              <i
                className=" bi bi-trash"
                style={{ zIndex: 10, color: 'red', fontSize: '24px', cursor: 'pointer', position: 'absolute', top: '1px', left: '10px' }}
              ><label style={{ cursor: 'pointer', position: 'absolute', top: '26px', left: '-3px', fontSize: '10px' }} >borrar</label></i>
            </a>
            <div style={{ cursor: 'pointer',  maxHeight: height }} className="position-absolute cursor-pointer top-50 start-50 translate-middle">
              {isImageFile ? <img style={{ zIndex: 1, width: '100%', height: '100%', maxHeight:'100%', maxHeight:'100%'  }} src={previewUrl} alt="File Preview" /> : getFileIcon(fileType)}
            </div>
            <div className="position-absolute top-50 start-50 translate-middle" >
              {!isImageFile && <h5 className="file-name pt-1 text-primary text-capitalize">{`${name}${subName()}`}</h5>}
            </div>
            {!autoSubmit &&
              <a
                style={{ cursor: 'pointer' }}
                className={showSubmit ? 'upload-button ' : ''}
                onClick={showSubmit ? handleUpload : ''}
              >
                <i
                  className={`position-absolute  bi ${showSubmit ? 'bi-check2-circle' : ''
                    }`}
                  style={{ color: showSubmit ? 'green' : 'blue', fontSize: '24px', top: '-1px', right: '10px' }}
                ><label style={{ cursor: 'pointer', position: 'absolute', top: '27px', right: '1px', fontSize: '10px' }}  >subir</label></i>
              </a>
            }
          </div>
        </div>
      )
    }
    else
      return null
  }
  const renderMultiple = () => {
    console.log(stateFile)
    if (multiple && Object.keys(stateFile).length>0) {

      return (
      
        Object.keys(stateFile).map((key) => {
          console.log(key);
          const fileType = stateFile[key].fileType || '';
          const isImageFile = fileType.startsWith('image/');
          return renderFile(isImageFile, fileType, key);
        })
     
      )
    }
    else
      return null
  }

  const handleUpload = async e => {
    if (e) {
      e.preventDefault()
    }

    if (!file) {
      alert('Por favor seleccione un archivo')
      return
    }
    const formData = new FormData()
    formData.append('file', file)
    const url = `${FilePath}/upload/${path}`
    try {
      
      const response = await axios.post(url, formData, { timeout: 5000 })
      let saveFile = { name: `${name}${subName()}`, path: `${path}/${file.name}`, fileType: file.type }
      toast.success(response.data)
      setShowSubmit(false)
      setStateFile({ ...stateFile, [`${name}${subName()}`]: saveFile })
      setFile(null)
      setShowButton(multiple)
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(error.response.data.error)
      } else {
        toast.error('Se produjo un error al cargar el archivo.')
        console.log(error)
      }
    }
  }

  const handleCancel = async (nameFile) => {

    delete stateFile[nameFile]
    setStateFile(stateFile)
    setFile(null)
    setShowButton(true)
    setShowSubmit(true)
    setRestart(!restart)
  }

  const handleDelete = async (nameFile) => {
    const response = await deleteFile(stateFile[nameFile].path)
    if (response.data.status === 200) {
      delete stateFile[nameFile]
      setStateFile(stateFile)
      setFile(null)
      setShowButton(true)
      setShowSubmit(true)
      toast.success(response.data.msj)
      setRestart(!restart)
    }
    else {
      delete stateFile[nameFile]
      setStateFile(stateFile)
      setFile(null)
      setShowButton(true)
      setShowSubmit(true)
      toast.error(response.data.msj);
      setRestart(!restart)
    }
    return
  }

  const handleShow = (nameFile) => {


    const url = `${FilePath}/uploads/${stateFile[nameFile].path}`// 'http://app.rms.com.co:4010/uploads/COFIFOR161/3/soporte.pdf'; // La URL del archivo en el servidor
    const ventanaEmergente = window.open(url, stateFile[nameFile].name, 'width=800, height=600');
    // Puedes personalizar las dimensiones y propiedades de la ventana emergente según tus necesidades.
    // Asegúrate de manejar cualquier error si la ventana emergente es bloqueada por el navegador.
    if (!ventanaEmergente) {
      alert('La ventana emergente fue bloqueada. Asegúrate de permitir las ventanas emergentes en tu navegador.');
    }
  }

  return (
    <form action={`${FilePath}/upload`} method="POST" encType="multipart/form-data">
      <div style={{ display: 'flex', flexWrap: 'wrap' }}> 
      {renderMultiple()}
      </div>
      <div style={{ textAlign:'center', width: width || '200px', height: height || '60px' }} >
        {showButton && !disable && (
          <> <button
            type="button"
            className="btn btn-light"
            onClick={event => document.getElementById(name).click()}
          >
            Seleccionar {name}
          </button>
            <input
              id={name}
              onChange={handleFileChange}
              className="form-control"
              type="file"
              name={name}
              style={{ display: 'none' }}
              accept={pdf ? 'application/pdf' : 'image/*'}
            />
          </>

        )}
        {renderPreviewSingle()}
       
      </div>
    </form>
  )
}


