import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import NotFound from "../components/app/lib/NotFound";
import Login from "../components/auth/Login";
import Recovery from "../components/auth/Recovery";

//Importar Componentes
const RouterAuth = () => (
  <div>
    <Router>
      <ToastContainer theme="dark" autoClose={2000} position="bottom-right" />
      <Routes>
        <Route exact path="/recoverypsw" element={<Recovery />} />
        <Route exact path="/" element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  </div>
);

export default RouterAuth;
