import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { sessionActive } from '../../lib/functions'
import { Col, Row, Button, Form, Modal, Check } from 'react-bootstrap'
import NewCLass, { NewClass } from './newClass'
import { getFormats, updateFormat } from '../../lib/formatos/request'
import { getMultiDataF, getUniqueList } from '../../lib/request'


export const ClassList = props => {
  const [IdUser_PK, setIdUser_PK] = useState(localStorage.getItem('IdUser_PK'))
  const [restart, setRestart] = useState(false)
  const [IdFormat_PK, setIdFormat_PK] = useState('')
  const [cbFilter, setcbFilter] = useState(true)
  const [showForm, setShowForm] = useState(false)
  const [formats, setFormats] = useState([])
  const [isView, setIsView] = useState(false)
  const [ModalStatus, setModalStatus] = useState(false)
  const [idDelete, setIdDelete] = useState()
  const [search, setSearch] = useState()
  const [data, setData] = useState()
  const [formatOriginal, setFormatOriginal] = useState()
  const [filterindItems, setFilterindItems] = useState([])
  const [ModalDelete, setModalDelete] = useState(false)
  const [eqTable, setEqTable] = useState([])
  const [personalTable, setPersonalTable] = useState([])

  const typef = 'classTable'
  const [list, setList] = useState()


  const fillTable = async () => {
    //Obtener listas
    const res = (await getFormats(typef)).data.data
    setFormatOriginal(res)
    setFormats(res.filter(i=> i.Type==='SubClass'))
    setFilterindItems(res.filter(i=> i.Type==='SubClass'))

    // const Initials_PK = await getUniqueList('st_multidata', 'Initials_PK')
    // const names = await getUniqueList('st_multidata', 'names')
    // const region = await getUniqueList('st_multidata', 'region')
    // const type = await getUniqueList('st_multidata', 'type')
    // const country_id = await getUniqueList('st_multidata', 'country_id')
    // setList({ Initials_PK, names, region, type, country_id })

  }



  useEffect(() => {
    sessionActive()
    fillTable()
}, [restart])

useEffect(() => {
  
  if(formatOriginal){
    let filterName = cbFilter?'SubClass': 'Class'
    setFormats(formatOriginal.filter(item => item.Type === filterName))
    setFilterindItems(formatOriginal.filter(item => item.Type === filterName))
 }

}, [cbFilter])

  const OnFilter = e => {
    let FilterText = e.target.value
    setSearch(FilterText)
    setFilterindItems(
      formats.filter(
        item =>
          // item.id.includes(FilterText) ||
          item.Class.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.ClassName.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.SubClass.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.Description.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.Type.toLowerCase().includes(FilterText.toLowerCase())
      )
    )
  }

  const ClearSearch = () => {
    setSearch('')
    setFilterindItems(formats)
  }

  const handleChange = (data) => {
    setData(data)
    setShowForm(!showForm)
    setRestart(!restart)
    setIsView(true)
    setIdFormat_PK(data.id)

  }
  const editRow = row => {
    if (props.put) {
      return true

    }
    return false
  }
  const deleteRow = row => {
    if (props.delete) {
      return true
    }
    return false
  }
  const onSubmit = async e => {
    e.preventDefault()
    



    setModalStatus(false)
    setModalDelete(false)
    
    const { accessKey, id } = e.target
    const data = { IdFormat_PK: accessKey, status: id }
    const res = await updateFormat(data, typef)
    if (res.data.status === 200) {
      toast.success(res.data.msj)
    }
    else {
      toast.error(res.data.msj);
    }
    setRestart(!restart)



  }
  const columns = [

    {
      name: 'Clase',
      cell: row => (<div onClick={() => { handleChange(row) }} data-tag="allowRowEvents" >   {row.Class}  </div>),
      selector: row => row.Initials_PK,
      sortable: true,
    },
    {
      name: 'Nombre Clase',
      cell: row => (<div onClick={() => { handleChange(row) }} data-tag="allowRowEvents" >   {row.ClassName}  </div>),
      selector: row => row.names,
      sortable: true,
      grow: 2
    },
    {
      name: 'SubClase',
      cell: row => (<div onClick={() => { handleChange(row) }} data-tag="allowRowEvents" >   {row.SubClass}  </div>),
      selector: row => row.type,
      sortable: true,
    },
    {
      name: 'Descipción',
      cell: row => (<div onClick={() => { handleChange(row) }} data-tag="allowRowEvents" >   {row.Description}  </div>),
      selector: row => row.region,
      sortable: true,
      grow: 2
    },
    {
      name: 'Tipo',
      cell: row => (<div onClick={() => { handleChange(row) }} data-tag="allowRowEvents" >   {(row.Type)}  </div>),
      selector: row => row.country_id,
      sortable: true,
    }, {
      name: 'Acciones',
      cell: row => (
        <div className="tabla dropdown dropstart">
          <i
            className="bi bi-three-dots-vertical"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          />
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li >
              <Link
                onClick={() => {
                  setData(row)
                  setShowForm(!showForm)
                  setRestart(!restart)
                  setIsView(false)
                  setIdFormat_PK(row.IdFormat_PK)
                }}
                className={'dropdown-item text-center border-end' + (editRow(row) ? '' : ' disabled')}
                to="/admin"
              >
                <i className="bi bi-pen me-3 "></i>Editar
              </Link>
            </li>
            <li >
              <Link
                className={'dropdown-item text-center border-end' + (editRow(row) ? '' : ' disabled')}
                to="/admin"
                onClick={() => {
                  row.id = "copia"
                  setData(row)
                  setShowForm(!showForm)
                  setRestart(!restart)
                  setIsView(false)
                  setIdFormat_PK("copiar")
                }}
              >
                <i className="bi bi-files me-3"></i>Copiar
              </Link>
            </li>            <li className="dropdown-item">
              <Link
                className={'dropdown-item text-center border-end' + (deleteRow(row) ? '' : ' disabled')}
                to="/admin"
                onClick={() => {
                  setIdDelete(row.IdFormat_PK)
                  setRestart(!restart)
                  setModalDelete(true)
                }}
              >
                <i className="bi bi-trash me-3"></i>Borrar
              </Link>
            </li>
          </ul>
        </div>
      ),
      button: true,
    }

  ]


  return (
    <div>
      <Row>
        <Col>
          {props.newBotton && (
            <Button
              variant="success"
              className="m-3 p-2 rounded-pill btn-sm ms-1"
              disabled={props.post ? false : true}
              onClick={() => {
                setShowForm(!showForm)
                setRestart(!restart)
                setIsView(false)
                setData('')
                setIdFormat_PK('en creacion')
              }}
            >
              <i className="bi bi-wrench-adjustable mx-2" />
              Crear Item{' '}
            </Button>
          )}
          {showForm && <NewClass sessionUser={props.sessionUser} list={formatOriginal} hide={setShowForm} data={data} setData={setData} isView={isView} show={showForm} restart={restart} setRestart={setRestart} />}
          <Col className="d-flex">
            <Form.Control
              type="text"
              id="search"
              placeholder="Búsqueda"
              aria-label="search input"
              value={search}
              onChange={OnFilter}
            />
            <Form.Check
              className="m-2 inline-radio-2 "
              inline
              onChange={(e) => { setcbFilter(false) }}
              checked={cbFilter ? false : true}
              id="Clase"
              label="Clase"
              name="active"
              type={"radio"}

            /><Form.Check
              className="m-2 inline-radio-2  "
              inline
              onChange={(e) => { setcbFilter(true) }}
              checked={cbFilter ? true : false}
              id="Subclase"
              label="Subclase"
              name="active"
              type={"radio"}
            />
            <Button data-tip="Limpiar Filtro" className="btn btn-success btn-sm" type="button" onClick={ClearSearch}>
              <i className="bi bi-arrow-counterclockwise" />
            </Button>
          </Col>
          <Row>
            <DataTable
              fixedHeader={false}
              fixedHeaderScrollHeight={{ fixedHeaderScrollHeight: '100px' }}
              columns={columns}
              data={filterindItems}
              responsive
              dense={true}
              highlightOnHover
              pointerOnHover
              noDataComponent="No hay registros para mostrar"
              paginationComponentOptions={{
                rowsPerPageText: 'Filas por página:',
                rangeSeparatorText: 'de',
              }}
            />
          </Row>
        </Col>
      </Row>

      <Modal
        show={ModalDelete}
        onHide={() => {
          setModalDelete(false)
        }}
        centered
      >
        <Modal.Header closeButton>
          <h5>Eliminar Solicitud</h5>
        </Modal.Header>
        <Modal.Body>
          <p>¿Realmente desea eliminar la solicitud {idDelete}?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-danger  "
            onClick={() => {
              setModalDelete(false)
            }}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            className="btn btn-secundary  "
            id={"delete"}
            accessKey={idDelete}
            onClick={onSubmit}
          >
            Eliminar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={ModalStatus === "Inactivar" || ModalStatus === "Activar"}
        onHide={() => {
          setModalStatus(false)
        }}
        centered
      >
        <Modal.Header closeButton>
          <h5>{ModalStatus} Servicio</h5>
        </Modal.Header>
        <Modal.Body>
          <p>¿Realmente desea {ModalStatus} el servicio {idDelete}?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-danger  "
            onClick={() => {
              setModalStatus(false)
            }}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            className="btn btn-secundary  "
            id={ModalStatus === "Inactivar" ? "Inactivo" : "Activo"}
            accessKey={idDelete}
            onClick={onSubmit}
          >
            {ModalStatus}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default ClassList
