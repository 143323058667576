
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

export const EqTable = ({ data=[], setData, isView, tableType, isReceive }) => {
    //const [nuevoAccesorio, setNuevoaccesorio] = useState([])
    const [showSave, setShowSave] = useState(false)
    const [restart, setRestart] = useState(false)
    const [Data1, setData1] = useState('')
    const [Data2, setData2] = useState('')
    const [Data3, setData3] = useState('')
    const [Data4, setData4] = useState('')
    const [Data5, setData5] = useState('')

    useEffect(() => {
        setRestart(true)
    }, [restart])

    const saveItem = () => {
        console.log("DATA",data)
        if (tableType === 'Accessory') var nuevoItem = { Descripcion: Data1, Serial: Data2, Cantidad: Data3, Estado: Data4, observacion: Data5 }
        else if (tableType === 'Prestamo') var nuevoItem = { Descripcion: Data1, Serial: Data2, Cantidad: Data3, Estado: Data4, observacion: Data5, tipo: 'Accesorios' }
        else if (tableType === 'LCC') var nuevoItem = { Gestion: Data1, Responsable: Data2, RequierePlan: Data3 }
        else var nuevoItem = { Fecha: Data1, Resultado: Data2, Reporte: Data3, FechaProx: Data4, Resposable: Data5 }
        
        data[data.length] = nuevoItem
        
        setData(data)
        setData5('')
        setData4('')
        setData3('')
        setData2('')
        setData1('')
        setShowSave(false)
        setRestart(false)
    }
    const deleteItem = (e) => {
        const months = data
        months.splice(e.currentTarget.id, 1);
        setData(data);
        setRestart(false)
    }


    const handleChange = ({ target }) => {
        const { name, type, id, accessKey } = target
        const value = type === 'checkbox' ? target.checked : target.value
        if (id === 'tb') {
            data[accessKey][name.replace('_' + accessKey, '')] = value
            setData(data)
            setRestart(false)
        }
        if (Data1 !== '' && Data2 !== '' && Data3 !== '' && Data4 !== '' && Data5 !== '')
            setShowSave(true)
        else if (Data1 !== '' && Data2 !== '' && Data3 !== '' && tableType === 'LCC') setShowSave(true)
        else setShowSave(false)
    }


    return (
        <EqFrame>{(tableType === 'Prestamo') && <table style={{ width: '100%' }} >
            <thead style={{ textAlign: 'center' }}>
                <tr>
                    <th style={{ width: '25%' }} colSpan={2} rowSpan={2}><label>DESCRIPCIÓN</label></th>
                    <th rowSpan={2} style={{ width: '10%' }}><label>SERIAL</label></th>
                    <th style={{ width: '5%' }} rowSpan={2}><label>CANT.</label></th>
                    <th colSpan={2} ><label>ESTADO ENTREGA</label></th>
                    <th rowSpan={2}><label>OBSERVACIÓN INICIAL</label></th>
                    <th colSpan={2} ><label>ESTADO RECIBIDO</label></th>
                    <th rowSpan={2}><label>OBSERVACIÓN RECIBIDO</label></th>
                </tr>
                <tr>
                    <th ><label>B.E.</label></th>
                    <th ><label>M.E.</label></th>
                    <th ><label>B.E.</label></th>
                    <th ><label>M.E.</label></th>

                </tr>
            </thead>
            <tbody>
                {
                    Array.isArray(data) && data?.map((item, i) => (
                        <tr key={i}>
                            {i < 2 && <th scope='row' rowSpan={item.tipo === 'Equipo' ? 1 : data.length}>{item.tipo === 'Equipo' ? 'Equipo' : 'Accesorios'}</th>}
                            <th>{item.Descripcion}</th>
                            <th><input className={isReceive ? 'disable' : ''} accessKey={i} name={'Serial_' + i} id='tb' value={data[i]['Serial']} onChange={handleChange} required disabled={isView || isReceive ? true : false} /></th>
                            <th style={{ textAlign: 'center' }} ><input className={isReceive ? 'disable' : ''} accessKey={i} id='tb' type='number' name={'Cantidad_' + i} value={data[i]['Cantidad']} onChange={handleChange} required disabled={isView || isReceive ? true : false} /></th>
                            <th style={{ textAlign: 'center' }}><input accessKey={i} id='tb' type={'radio'} name={'Estado_' + i} value={'BE'} checked={data[i]['Estado'] === 'BE' ? "checked" : ""} onChange={handleChange} required disabled={isView || isReceive && isReceive ? true : false} /></th>
                            <th style={{ textAlign: 'center' }}><input accessKey={i} id='tb' type={'radio'} name={'Estado_' + i} value={'ME'} checked={data[i]['Estado'] === 'BE' ? "" : "checked"} onChange={handleChange} required disabled={isView || isReceive ? true : false} /></th>
                            <th ><input className={isReceive ? 'disable' : ''} accessKey={i} name={'observacion_' + i} id='tb' value={data[i]['observacion']} onChange={handleChange} required disabled={isView || isReceive ? true : false} /></th>
                            {isReceive && <><th style={{ textAlign: 'center' }}><input accessKey={i} id='tb' type={'radio'} name={'EstadoDev_' + i} value={'BE'} checked={data[i]['EstadoDev'] === 'BE' ? "checked" : ""} onChange={handleChange} required disabled={isView ? true : false} /></th>
                                <th style={{ textAlign: 'center' }}><input accessKey={i} id='tb' type={'radio'} name={'EstadoDev_' + i} value={'ME'} checked={data[i]['EstadoDev'] === 'BE' ? "" : "checked"} onChange={handleChange} required disabled={isView ? true : false} /></th>
                                <th ><input accessKey={i} name={'observacionDev_' + i} id='tb' value={data[i]['observacionDev']} onChange={handleChange} required disabled={isView ? true : false} /></th></>}

                        </tr>
                    ))
                }{!isReceive && !isView && <tr>
                    {data.length < 2 && <th>Accesorios</th>}
                    <th><input name='Data1' id='na1' value={Data1} onChange={e => {
                        setData1(e.target.value); handleChange(e);
                    }} disabled={isView ? true : false}></input></th>
                    <th><input name='Data2' id='na2' value={Data2} onChange={e => {
                        setData2(e.target.value); handleChange(e);
                    }} disabled={isView ? true : false} /></th>
                    <th><input type={'number'} name='Data3' id='na3' value={Data3} onChange={e => {
                        setData3(e.target.value); handleChange(e);
                    }} disabled={isView ? true : false} /></th>
                    <th style={{ textAlign: 'center' }}><input type={'radio'} name='Data4' id='na4' value={'BE'} checked={Data4 === 'BE' ? 'checked' : ''} onChange={e => { setData4(e.target.value); handleChange(e); }} disabled={isView ? true : false} /></th>
                    <th style={{ textAlign: 'center' }}><input type={'radio'} name='Data4' id='na4' value={'ME'} checked={Data4 === 'BE' ? '' : 'checked'} onChange={e => { setData4(e.target.value); handleChange(e); }} disabled={isView ? true : false} /></th>
                    <th><input name='Data5' id='na5' value={Data5} onChange={e => {
                        setData5(e.target.value); handleChange(e);
                    }} disabled={isView ? true : false} /></th>
                    <td style={{ textAlign: 'center' }} role='button'>{showSave && <i onClick={saveItem} style={{ color: 'green', fontSize: '23px' }} className="fa-solid fa-check"></i>}</td>
                </tr>}
            </tbody>
        </table>}
            {(tableType === 'Accessory') && <table style={{ width: '100%' }} >
                <thead>

                    <tr>
                        <th scope='Col' style={{ width: '4%' }}><label>item</label></th>
                        <th scope='Col' style={{ width: '28%' }}><label>Descripcion</label></th>
                        <th scope='Col' style={{ width: '20%' }}><label>serial</label></th>
                        <th scope='Col' style={{ width: '10%' }}><label>Cantidad</label></th>
                        <th scope='Col' style={{ width: '5%' }}><label>Estado</label></th>
                        <th scope='Col' style={{ width: '24%' }}><label>Observaciones</label></th>
                        <th scope='Col' style={{ width: '10%' }}><label>Acciones</label></th>
                    </tr></thead>
                <tbody>
                    {
                        Array.isArray(data) && data.map((item, i) => (
                            <tr key={i}>
                                <th scope='row'><label>{i + 1}</label></th>
                                <th><label>{item.Descripcion}</label></th>
                                <th><label>{item.Serial}</label></th>
                                <th><label>{item.Cantidad}</label></th>
                                <th><label>{item.Estado}</label></th>
                                <th><label>{item.observacion}</label></th>
                                <th style={{ textAlign: 'center' }} role='button' id={i} onClick={deleteItem}>{!isView && <>
                                    <i style={{ color: 'red', fontSize: '20px' }} className="fa-solid fa-trash"></i>

                                </>} </th>

                            </tr>
                        ))
                    }
                    <tr>
                        <th scope='row'></th>

                        <th><input name='Data1' id='na1' value={Data1} onChange={e => {
                            setData1(e.target.value); handleChange(e);
                        }} disabled={isView ? true : false}></input></th>
                        <th><input name='Data2' id='na2' value={Data2} onChange={e => {
                            setData2(e.target.value); handleChange(e);
                        }} disabled={isView ? true : false} /></th>
                        <th><input type={'number'} name='Data3' id='na3' value={Data3} onChange={e => {
                            setData3(e.target.value); handleChange(e);
                        }} disabled={isView ? true : false} /></th>
                        <th><input list='estado' name='Data4' id='na4' value={Data4} onChange={e => {
                            setData4(e.target.value); handleChange(e);
                        }} disabled={isView ? true : false} /></th>
                        <th><input name='Data5' id='na5' value={Data5} onChange={e => {
                            setData5(e.target.value); handleChange(e);
                        }} disabled={isView ? true : false} /></th>
                        <th style={{ textAlign: 'center' }} role='button'>{showSave && <i onClick={saveItem} style={{ color: 'green', fontSize: '23px' }} className="fa-solid fa-check"></i>}</th>
                    </tr>
                </tbody>
            </table>}
            {(tableType === 'Calibration' || tableType === 'Maintenance') && <table style={{ width: '100%' }} >
                <tbody>{
                    Array.isArray(data) && data?.map((item, i) => (<>
                        <tr key={i * 3}>
                            <th className="photo" style={{ width: '16%' }}><label>FECHA:</label></th>
                            <th className="photo" style={{ width: '16%' }}><label>RESULTADO:</label></th>
                            <th className="photo" colSpan={4}><label>{tableType === 'Calibration' ? 'No DE REPORTE DE CALIBRACIÓN Y OBSERVACIONES' : 'No DE REPORTE Y OBSERVACIONES'}</label></th>
                            <th className="photo" ><label>Accion</label></th>
                        </tr>
                        <tr key={i * 3 + 1}>
                            <th  ><label>{item.Fecha}</label></th>
                            <th  ><label>{item.Resultado}</label></th>
                            <th colSpan={4}><label>{item.Reporte}</label></th>
                            <th rowSpan={2} style={{ textAlign: 'center' }} role='button' id={i} onClick={deleteItem}>{!isView && <>
                                <i style={{ color: 'red', fontSize: '20px' }} className="fa-solid fa-trash"></i> </>} </th>
                        </tr>
                        <tr key={i * 3 + 2}>
                            <th colSpan={2} ><label>{tableType === 'Calibration' ? 'FECHA PROX. CALBRACIÓN:' : 'FECHA PROXIMO MTTO'}</label></th>
                            <th   ><label>{item.FechaProx}</label></th>
                            <th style={{ width: '16%' }} ><label>RESPONSABLE:</label></th>
                            <th colSpan={2} ><label>{item.Resposable}</label></th>

                        </tr>
                    </>))}
                    <tr>
                        <th className="photo" style={{ width: '16%' }}><label>FECHA:</label></th>
                        <th className="photo" style={{ width: '16%' }}><label>RESULTADO:</label></th>
                        <th className="photo" colSpan={4}><label>{tableType === 'Calibration' ? 'No DE REPORTE DE CALIBRACIÓN Y OBSERVACIONES' : 'No DE REPORTE Y OBSERVACIONES'}</label></th>
                        <th className="photo" ><label>Accion</label></th>

                    </tr><tr>
                        <th  ><input data-provide="datepicker" type={'date'} name='Data1' value={Data1} onChange={e => { setData1(e.target.value); handleChange(e); }} disabled={isView ? true : false} /></th>
                        <th  ><input name='Data2' value={Data2} onChange={e => { setData2(e.target.value); handleChange(e); }} disabled={isView ? true : false} /></th>
                        <th colSpan={4}><input name='Data3' value={Data3} onChange={e => { setData3(e.target.value); handleChange(e); }} disabled={isView ? true : false} /></th>
                        <th rowSpan={2} style={{ textAlign: 'center' }} >{showSave && <i onClick={saveItem} style={{ color: 'green', fontSize: '23px' }} className="fa-solid fa-check"></i>}</th>
                    </tr><tr>
                        <th colSpan={2} ><label>FECHA PROX. CALBRACIÓN:</label></th>
                        <th   ><input data-provide="datepicker" type={'date'} name='Data4' value={Data4} onChange={e => { setData4(e.target.value); handleChange(e); }} disabled={isView ? true : false} /></th>
                        <th style={{ width: '16%' }} ><label>RESPONSABLE:</label></th>
                        <th colSpan={2} ><input name='Data5' value={Data5} onChange={e => { setData5(e.target.value); handleChange(e); }} disabled={isView ? true : false} /></th>

                    </tr>
                </tbody>
            </table>}
            {(tableType === 'LCC') && <table style={{ width: '100%' }} >
                <thead>

                    <tr className="photo">
                        <th scope='Col' style={{ width: '4%' }}><label>item</label></th>
                        <th scope='Col' style={{ width: '38%' }}><label>GESTION PROPUESTA</label></th>
                        <th scope='Col' style={{ width: '20%' }}><label>RESPONSABLE</label></th>
                        <th scope='Col' style={{ width: '32%' }}><label>REQUIERE PLANIFICACIÓN DE CAMBIOS CRÍTICOS EN LA CONFIGURACIÓN DEL SIG.
                            FORMATO GG-GEN1-F-3</label></th>
                        <th scope='Col' style={{ width: '8%' }}><label>Accion</label></th>
                    </tr></thead>
                <tbody>
                    {
                        data !== null && data !== '' && data !== undefined && data?.map((item, i) => (
                            <tr key={i}>
                                <th scope='row'><label>{i + 1}</label></th>
                                <th><label>{item.Gestion}</label></th>
                                <th><label>{item.Responsable}</label></th>
                                <th><label>{item.RequierePlan}</label></th>

                                <th style={{ textAlign: 'center' }} role='button' id={i} onClick={deleteItem}>{!isView && <>
                                    <i style={{ color: 'red', fontSize: '20px' }} className="fa-solid fa-trash"></i>

                                </>} </th>

                            </tr>
                        ))
                    }
                    <tr key={10001}>
                        <th scope='row'></th>

                        <th><input name='Data1' id='na1' value={Data1} onChange={e => {
                            setData1(e.target.value); handleChange(e);
                        }} disabled={isView ? true : false} ></input></th>
                        <th><input name='Data2' id='na2' value={Data2} onChange={e => {
                            setData2(e.target.value); handleChange(e);
                        }} disabled={isView ? true : false} /></th>
                        <th><input list="siono" name='Data3' id='na3' value={Data3} onChange={e => {
                            setData3(e.target.value); handleChange(e);
                        }} disabled={isView ? true : false} /></th>


                        <th style={{ textAlign: 'center' }} role='button'>{showSave && <i onClick={saveItem} style={{ color: 'green', fontSize: '23px' }} className="fa-solid fa-check"></i>}</th>
                    </tr>
                </tbody>
            </table>}
            <datalist id="siono">
                <option value="SI"></option>
                <option value="No" />
            </datalist>
            <datalist id="estado">
                <option value="BE">Buen Estado</option>
                <option value="ME">Mal Estado</option>
            </datalist>
        </EqFrame>
    )
}

const EqFrame = styled.div`
input {
    border-bottom: 0px solid black;
  }
  input.disable {
    background: #fff;
    border-bottom: 0px solid black;
  }
`
