import React, { useState, useEffect } from "react";
import { BrowserRouter, Route,  Routes } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
//import ReactTooltip from "react-tooltip";
import "react-toastify/dist/ReactToastify.css";
import NotFound from "../components/app/lib/NotFound";
import { Sidebar } from "../components/app/lib/Sidebar";
import { Requisiones } from "../components/app/Solicitudes/Requisiones";
import Logistic from "../components/app/logistic/Logistic";
import Leader from "../components/app/leader/Leader";
import Humantalent from "../components/app/humantalent/HumanTalent";
import Admin from "../components/app/admin/Admin";
import SelectContract from "../components/app/Solicitudes/SelectContract";
import { getUserByIdF } from "../components/app/lib/request";

import Users from "../components/app/users/Users";
import Supplychain from  "../components/app/supplychain/Supplychain";
import Selfservice from "../components/app/selfservice/Selfservice";
import { isMobile } from "react-device-detect";
import Accounting from "../components/app/accounting/Accounting";
import Commercial from "../components/app/commercial/Commercial";
import Operations from "../components/app/operations/Operations";


const RouterApp = () => {
  const [dataUser, setDataUser] = useState([])
  const [contracts, setContracts] = useState([])
  const [expand, collapse] =useState(false)
  const [contractSelected, setContractSelected] = useState([])
  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    // Este efecto se ejecutará cada vez que la ruta cambie
    const handleRouteChange = () => {
      // Redireccionar a la página de inicio
      window.location.href = "/";
    };
    // Agregar un listener para escuchar cambios en la ruta
    window.addEventListener("hashchange", handleRouteChange);

    // Eliminar el listener cuando el componente se desmonte
    return () => {
      window.removeEventListener("hashchange", handleRouteChange);
    };
  }, []);


  const getData = async () => {
    let session = { user: localStorage.getItem('IdUser_PK') }

    const resUser = await getUserByIdF('IdUser_PK', session.user, true)

    if (resUser.data.status === 200) {
      setDataUser(resUser.data.data)
      setContracts(resUser.data.data.contracts)

    } else {
      toast.error(resUser.data.msj)
    }

  }
  const CerrarSesion = () => {
    localStorage.clear()
    window.location.href = "/"
    window.location.reload()
  }


  return (
    <BrowserRouter>
      
     {isMobile || window.innerWidth <= 915  ?
        <button className="floating-button" onClick={CerrarSesion}>
           <i className="fas fa-times"></i>
        </button>:
      <Sidebar collapse = {collapse} />  }
      {/* <ReactTooltip place="right" type="info" effect="solid" />   */}
      <ToastContainer theme="dark" autoClose={2000} position="bottom-right" />
      <Routes>
        {/** Rutas Usuarios */}
        <Route path="/selfservice" element={<Selfservice />} />
        <Route path="/users" element={<Users />} />
        <Route path="/users/edit/:id" element={<Users />} />
        {/**Ruta talento humano */}
        <Route exact path="/humantalent/" element={<Humantalent />} />
        <Route path="/humantalent/edit/:id" element={<Humantalent />} />
        {/**Ruta lider */}
        <Route exact path="/leader/" element={<Leader />} />
        {/**Ruta requisiciones */}
        <Route exact path="/SelectContract" element={
          <SelectContract
            expandSidebar={expand}
            setDataUser={setDataUser}
            dataUser={dataUser}
            contracts={contracts}
            contractSelected={""}
            setContracts={setContracts}
            setContractSelected={setContractSelected}
          />
        } />
        <Route exact path="/request" element={
          <Requisiones
            dataUser={dataUser}
            contracts={contracts}
            contractSelected={contractSelected}
          />
        } />
        {/**Ruta admin */}
        <Route exact path="/admin/" element={<Admin />} />
        <Route path="/admin/edit/:id" element={<Admin />} />
        {/** Rutas Contabilidad */}
        <Route exact path="/accounting" element={<Accounting />} />
        {/** Rutas Commecial */}
        <Route exact path="/commercial" element={<Commercial 
            dataUser={dataUser}
            contracts={contracts} />} />
        {/** Rutas Operaciones */}
        <Route exact path="/operations" element={<Operations 
            dataUser={dataUser}
            contracts={contracts} />} />
            
        {/** Rutas Logistica */}
        <Route exact path="/logistic" element={<Logistic />} />
        {/** Rutas Compras */}
        <Route exact path="/supplychain" element={< Supplychain />} />

        {/** Rutas Generales */}
        <Route exact path="/" element={<Selfservice />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RouterApp;
