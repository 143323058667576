import { useContext } from "react"
import { ListContext } from "../context/ListContext"
import { UserContext } from "../context/userContext"


export function useListContext (){
    const context = useContext(ListContext)
    if(!context){
        throw new Error('useListContext must be used whitin a DataContextProvider')
   }
   return context
   
}

export function useUserContext (){
    const context = useContext(UserContext)
    if(!context){
        throw new Error('useUserContext must be used whitin a DataContextProvider')
   }
   return context
   
}

// export function useCompanyContext (){
//     const context = useContext(CompanyContext)
//     if(!context){
//         throw new Error('useCompanyContext must be used whitin a DataContextProvider')
//    }
//    return context
   
// }