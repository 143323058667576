import React, { useEffect, useState, useRef } from 'react'
import DataTable from 'react-data-table-component'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { sessionActive } from '../../lib/functions'
import { Col, Row, Button, Form, Modal } from 'react-bootstrap'
import { getFormats, deleteFormatId, updateFormat } from '../../lib/formatos/request'
import { getFullData, getMultiDataF } from '../../lib/request'
import { COMFORRequisiciones } from '../../lib/formatos/COMFORRequisiciones'





export const Solicitudes = ({ put, _delete, post, newBotton, dataUser, contractSelected = [], route, all = false }) => {
  const [IdUser_PK, setIdUser_PK] = useState(localStorage.getItem('IdUser_PK'))
  const [isView, setIsView] = useState(true)
  const [isQuot, setIsQuot] = useState(false)
  const [restart, setRestart] = useState(false)
  const [IdFormat_PK, setIdFormat_PK] = useState('')
  const [showForm, setShowForm] = useState(false)
  const [showModal, setShowModal] = useState(true)
  const [data, setData] = useState({})
  const [formats, setFormats] = useState([])
  const [filterindItems, setFilterindItems] = useState([])
  const [ModalDelete, setModalDelete] = useState(false)
  const [search, setSearch] = useState()
  const [idDelete, setIdDelete] = useState()
  const [list, setList] = useState([])



  useEffect(() => {

    if (!restart) {
      setRestart(!restart)
      sessionActive()
      fillTable()

    }
  }, [restart])

  const fillTable = async () => {


    //Obtener lista de solicitudes 
    const resFormats = await getFullData('requisiciones', "IdContract_FK", contractSelected?.IdFormat_PK)
    if (resFormats.data.status === 200) {
      var request = resFormats.data.data
      setFormats(request)
      setFilterindItems(request)
    } else {
      toast.error(resFormats.data.msj)
    }

    //Obtener listasdetalles para cotizacion
    var cotizacion = await getMultiDataF('st_multidata', 'Cotizacion')

    cotizacion = await cotizacion?.map(value => value.names)
    setList({ cotizacion })


  }

  const editRow = row => {

    if (put) {
      if (IdUser_PK === row.IdUser_FK && row.Status === 'cotizar') return true
      else if (row.Status === 'cotizado' && all) return true
      else return false
    }
    return false
  }
  const deleteRow = row => {

    if (_delete) {
      if (IdUser_PK === row.IdUser_FK && row.Status === 'cotizar') return true
      else return false
    }
    return false
  }

  const deleteSol = async () => {
    //borrar un solicitud
    const resDelele = await deleteFormatId('MITFOR207', idDelete)
    if (resDelele.data.status === 200) {
      toast.success(resDelele.data.msj)
    } else {
      toast.error(resDelele.data.msj)
    }
    fillTable()
  }

  const OnFilter = e => {
    let FilterText = e.target.value
    setSearch(FilterText)

    setFilterindItems(
      formats.filter(
        item =>
          item.Empresa.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.Status.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.SolicitadoPor.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.Objetivo.toLowerCase().includes(FilterText.toLowerCase()) /* */

      )
    )
  }

  const ClearSearch = () => {
    setSearch('')
    setFilterindItems(formats)
  }

  const handleChange = (data) => {
    setData({
      ...data, Empresa: JSON.parse(data.Empresa),
      SolicitadoPor: JSON.parse(data.SolicitadoPor),
      CotizaTable: data.CotizaTable.indexOf("{") !== -1 ? JSON.parse(data.CotizaTable) : []
    }
    )
    setIsQuot(false)
    setIsView(true)
    setIdFormat_PK(data.IdFormat_PK)
    setShowForm(!showForm)
    setShowModal(!showModal)

  }
  const onSubmit = async e => {

    e.preventDefault()
    const { accessKey, id } = e.target
    const data = { IdFormat_PK: accessKey, status: id }
    const res = await updateFormat(data)
    if (res.data.status === 200) {
      toast.success(res.data.msj)
    }
    else {
      toast.error(res.data.msj);
    }
    setRestart(!restart)



  }


  const columns = [
    {
      name: 'Id',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.IdFormat_PK}
        </div>

      ),
      selector: row => row.IdFormat_PK,
      sortable: true,
      fixed: true,
      grow: 0.1,
    },
    {
      name: 'Objetivo',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.Objetivo}
        </div>
      ),
      selector: row => row.Objetivo,
      sortable: true,
      grow: 2,
    },
    {
      name: 'Tipo Necesidad',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.TipoNecesidad}
        </div>
      ),
      selector: row => row.TipoNecesidad,
      sortable: true,
      grow: 1,
    },
    {
      name: 'Fecha solicitud',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.FechaSolicitud}
        </div>
      ),
      selector: row => row.FechaSolicitud,
      sortable: true,
    },
    {
      name: 'Fecha Necesidad',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.FechaNecesidad}
        </div>
      ),
      selector: row => row.FechaNecesidad,
      sortable: true,
    },
    {
      name: 'Lugar de Servicio',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.LugarServicio}
        </div>
      ),
      selector: row => row.LugarServicio,
      sortable: true,
    },

    {
      name: 'Objetivo',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.Objetivo}
        </div>
      ),
      selector: row => row.Objetivo,
      sortable: true,
    },
    {
      name: 'Estado',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.Status}
        </div>
      ),
      selector: row => row.Status,
      sortable: true,
    },

    {
      name: 'Acciones',
      cell: row => (
        <div className="tabla dropdown dropstart">
          <i
            className="bi bi-three-dots-vertical"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          />
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <Link
                onClick={() => {
                  if (all && row.Status === 'cotizado') {
                    setIsView(true)
                    setIsQuot(true)
                  } else
                    setIsView(false)
                  setData({
                    ...row,
                    Empresa: JSON.parse(row.Empresa),
                    SolicitadoPor: JSON.parse(row.SolicitadoPor),
                    CotizaTable: row.CotizaTable.indexOf("{") !== -1 ? JSON.parse(row.CotizaTable) : []
                  })

                  setShowForm(!showForm)
                  setIdFormat_PK(row.IdFormat_PK)
                }}
                className={'dropdown-item text-center border-end' + (editRow(row) ? '' : ' disabled')}
                to={route}
              >
                <i className="bi bi-pen me-3 "></i>Editar
              </Link>
            </li>
            <li>
              <Link
                className={'dropdown-item text-center border-end' + (deleteRow(row) ? '' : ' disabled')}
                to={route}
                onClick={() => {
                  setIdDelete(row.IdFormat_PK)
                  setModalDelete(true)
                }}
              >
                <i className="bi bi-trash me-3"></i>Borrar
              </Link>
            </li>
          </ul>
        </div>
      ),
      button: true,
    },
  ]

  return (
    <>
      <Row>
        <Col>
          {newBotton && (
            <Button variant="success"
              className="m-3 p-2 rounded-pill btn-sm ms-1"
              disabled={post ? false : true}
              onClick={() => {
                const SolicitadoPor = {
                  IdCompany_FK: dataUser.user.IdCompany_FK,
                  IdUser_PK: dataUser.user.IdUser_PK,
                  Position: dataUser.user.Position,
                  UserEmail: dataUser.user.UserEmail,
                  UserLastName: dataUser.user.UserLastName,
                  UserName: dataUser.user.UserName,
                  phoneNumber: dataUser.user.phoneNumber,
                  Status: dataUser.user.Status

                }
                setShowForm(!showForm)
                setRestart(false)
                setIsView(false)
                setData({ ...data, Status: "creacion", Empresa: contractSelected.Empresa, SolicitadoPor: SolicitadoPor })
                setIdFormat_PK('en creacion')
              }}
            >
              <i className="fa-solid fa-clipboard mx-2" />
              Nueva solicitud
            </Button>
          )}
          <Col className="d-flex">
            <Form.Control
              type="text"
              id="search"
              placeholder="Búsqueda"
              aria-label="search input"
              value={search}
              onChange={OnFilter}
            />
            <Button data-tip="Limpiar Filtro" className="btn btn-success btn-sm" type="button" onClick={ClearSearch}>
              <i className="bi bi-arrow-counterclockwise" />
            </Button>
          </Col>
          <Row>
            <DataTable
              fixedHeader={false}
              fixedHeaderScrollHeight={{ fixedHeaderScrollHeight: '100px' }}
              columns={columns}
              data={filterindItems}
              pagination
              responsive

              highlightOnHover
              pointerOnHover
              noDataComponent="No hay registros para mostrar"
              paginationComponentOptions={{
                rowsPerPageText: 'Filas por página:',
                rangeSeparatorText: 'de',
              }}
            />

          </Row>
        </Col>

      </Row>


      {showForm && (<COMFORRequisiciones
        dataUser={dataUser}
        data={data}
        setData={setData}
        show={showForm}
        contractSelected={contractSelected}
        hide={setShowForm}
        restart={restart}
        setRestart={setRestart}
        isView={isView}
        addQuotation={isQuot}
        IdFormat_PK={IdFormat_PK}
        list={list}
      />)}
    </>
  )
}

export default Solicitudes
