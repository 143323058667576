import { Accordion } from 'react-bootstrap'

import { useEffect } from 'react'
import RQ from '../../supplychain/suppliesTab/RQ/RQ'
import FinancialAid from  '../../accounting/advance/financialAid/FinancialAid'
import Refund from '../../accounting/advance/refund/Refund'




const OperReq = props => {
  const restartView = true
 

  useEffect(() => {

  }, [restartView])

  return (
    <Accordion >
      <Accordion.Item eventKey="0">
        <Accordion.Header>RQ</Accordion.Header>
        <Accordion.Body>
          <RQ
            put={props.put}
            delete={props.delete}
            post={props.post}
            sessionUser={props.sessionUser}
            newBotton={true}
            uniqueUser={true}
            route={'/operations'}
            isOper
          />
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="1">
        <Accordion.Header>Solicitud de Auxilio</Accordion.Header>
        <Accordion.Body>
        <FinancialAid
            put={props.put}
            delete={props.delete}
            post={props.post}
            sessionUser={props.sessionUser}
            dense={true}
            format={'COFIFOR271'}
            newBotton={true}
            uniqueUser={false}
            route={'/operations'}
            isOper
          /> 
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2" >
        <Accordion.Header>Solicitud de Anticipo</Accordion.Header>
        <Accordion.Body>
        <FinancialAid
            put={props.put}
            delete={props.delete}
            post={props.post}
            sessionUser={props.sessionUser}
            dense={true}
            format={'COFIFOR159'}
            newBotton={true}
            uniqueUser={false}
            route={'/operations'}
            isOper
          /> 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3" >
        <Accordion.Header>Legalización de Gastos</Accordion.Header>
        <Accordion.Body>
        <Refund
            put={props.put}
            delete={props.delete}
            post={props.post}
            sessionUser={props.sessionUser}
            dense={true}
            uniqueUser={true}
            route={'/operations'}
            isOper
          /> 
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}
export default OperReq
