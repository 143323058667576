import React, { useEffect, useState, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { Button, Col, Form, Row } from 'react-bootstrap'
import Moment from 'moment'
import { toast } from 'react-toastify'
import image from '../../../../assets/img/Logo_b-st.png'



import SelectOption from '../SelectOption'
import { CreateCompanieF, EditingCompanieF, getCompaniesF } from '../request'
import SpinnerLoad from '../spinnerLoad'



export const COMFORCompanies = ({
  show,
  hide,
  restart,
  setRestart,
  isView,
  data = [],
  sessionUser,
  setData,
  list,
  IdCompany_PK = "En Creación",
}) => {
  //-------------------------Info Formato
  const formato = 'COM-FOR-COMPANIES'
  const revision = 'N/A'
  const fechaRev = 'N/A'
  const nombreFormato = 'REGISTRO DE EMPRESA'
  const typef = 'company'

  //-------------------------Registros

  const [companyData, setCompanyData] = useState()
  const [currentUser, setCurrentUser] = useState('')
  const [date, setDate] = useState('')
  const [CRMCompany, setCRMCompany] = useState('')



  useEffect(() => {
    getInfo()
  }, [restart])




  const handleChange = async ({ target }) => {
    const { name, type } = target
    const value = type === 'checkbox' ? target.checked : target.value
    if (name === 'IdCompany_PK') {
      if (/^\d+$/.test(value) && !value.includes('-')) {
        setData({ ...data, [name]: value })
      } else {
        // Mostrar un toast o mensaje de error
        toast.error('No se requiere dígito de verificación');
        return
      }

    }
    setData({ ...data, [name]: value })
  }

  useEffect(() => {
    if (CRMCompany === '')
      return
    let companySelected = companyData.filter(item => item.value === CRMCompany)[0]
    let datos = {
      ...data,
      Address: companySelected.address,
      BornDate: companySelected.founded_year,
      Country: companySelected.country,
      Industry: companySelected.industry,
      numberofemployees: companySelected.numberofemployees,
      SocialName: companySelected.name,
      city: companySelected.city,
      phone: companySelected.phone,
      publicCompany: companySelected.is_public==='true'?'Si':'No',
      url: companySelected.website,
      products: companySelected.description

    }
    setData(datos)

  }, [CRMCompany])


  const getInfo = async () => {
    const formatDate = Moment().format('YYYY-MM-DD')
    setDate(formatDate)

    if (data.Status === 'en creacion') {
      let companyRequest = await getCompaniesF('CC')
      if (companyRequest.data.status === 200)
        companyRequest = companyRequest.data.data.map((item, index) => {
          return { ...item, key: index, value: item.hs_object_id };
        })
      else companyRequest = 'no se pudo consultar empresas'
console.log(companyRequest)
      setCompanyData(companyRequest)


    }




  }

  const clearStates = () => {
    data = []
    setData('')
    setCurrentUser('')
    setDate('')
  }
  const onSubmit = async e => {
    e.preventDefault()


    var result = ''

    delete data.image
    const temp = {
      ...data,

      Status: "Activo",
      createdBy: sessionUser.IdEmployee_FK,
    }

   
    if (data.createDate === undefined)
      result = await CreateCompanieF(temp)
    else {
      delete temp.modifyDate
      delete temp.createDate
      result = await EditingCompanieF(temp)
    }

    if (result.data.status === 200) {
      toast.success(result.data.msj)
    }
    else {
      toast.error(result.data.msj);
    }


    clearStates()
    hide(!show)
    setRestart(!restart)
  }



  return (
    <>
      {show && (
        <Overlay style={{ zIndex: '1' }}>
          {data.Status === 'en creacion' ? <SpinnerLoad trigger={companyData} /> : <SpinnerLoad trigger={data} />}
          <ModalContainer>
            <ModalHeader>
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <th style={{ width: '20%', fontSize: '10px', verticalAlign: 'bottom' }} rowSpan={3}>
                      <img
                        className="format_logo"
                        src={image}
                        style={{ paddingBottom: '7px', width: '75%', minWidth: "40px" }}
                        alt="Logo RMS"
                      /><br />
                      NIT 830.507.705-2
                    </th>
                    <th style={{ width: '55%', height: '38px' }}>RELIABILITY MAINTENANCE SERVICES S.A.S</th>
                    <th style={{ width: '25%' }}>{formato}</th>
                  </tr>
                  <tr>
                    <th rowSpan={2}>{nombreFormato}</th>
                    <th style={{ fontSize: '10px' }}>Revisión: {revision}</th>
                  </tr>
                  <tr>
                    <th style={{ fontSize: '10px' }}>Fecha de revisión: {fechaRev}</th>
                  </tr>
                </tbody>
              </table>
            </ModalHeader>
            <CloseButtonModal onClick={() => {
              clearStates()
              hide(!show)
            }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-x-square"
                viewBox="0 0 16 16"
              >
                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </CloseButtonModal>
            <table>
              <tbody>
                <tr>
                  <th style={{ width: '55%', textAlign: 'right' }}></th>
                  <th style={{ width: '15%', textAlign: 'right' }}>
                    <label>Empresa No.</label>
                  </th>
                  <td style={{ width: '25%', background: '#FFF' }}>
                    <label>{IdCompany_PK}</label>
                  </td>
                </tr>
              </tbody>
            </table>

            <Form onSubmit={onSubmit}>
              <Frame isView={isView}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <th className="rms" colSpan={7} style={{ width: '100%' }}>
                        <h2 className="text-center">INFORMACION</h2>
                      </th>
                    </tr>
                    <tr>
                      <td rowSpan={10} style={{ alignItems: "center", textAlign: "", width: '15%' }}>


                      </td>
                      <td style={{ width: '14%' }}>
                        <label>Nombre de la empresa:</label>
                      </td>
                      <td style={{ width: '60%' }} colSpan={3} >
                        {IdCompany_PK === "en creacion" ?
                          <SelectOption type={"custom"} searchable={true} state={CRMCompany} setState={setCRMCompany} list={companyData} required disabled={isView ? true : false} /> :
                          <input
                            name="SocialName"
                            value={data.SocialName}
                            onChange={handleChange}
                            required
                            disabled={isView ? true : false} />
                        }

                      </td>
                    </tr>
                    <tr>
                      <td ><label>Estructura juridica:</label></td>
                      <td style={{ width: '22%' }} >
                        <SelectOption type={"legal_form"} searchable={true} state={data} setState={setData} nameState={'LegalForm'} required disabled={isView ? true : false} />

                      </td>
                      <td style={{ width: '12%' }} ><label>NIT:</label></td>
                      <td >
                        <input
                          name="IdCompany_PK"
                          value={data.IdCompany_PK}
                          onChange={handleChange}
                          onKeyPress={e => {
                            const charCode = e.which ? e.which : e.keyCode;
                            const char = String.fromCharCode(charCode);
                            const regex = /^[0-9]*$/;
                            if (!regex.test(char)) {
                              e.preventDefault();
                            }
                          }}
                          required
                          disabled={IdCompany_PK === 'en creacion' ? false : true}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td ><label>Actividad Económica:</label></td>
                      <td colSpan={3} >
                        <SelectOption type={"CIIU"} searchable={true} state={data} setState={setData} nameState={'CIIU_FK'} required disabled={isView ? true : false} />

                      </td>

                    </tr>
                    <tr>
                      <td>
                        <label>Empresa Publica</label>
                      </td>
                      <td>
                        <select
                          name="publicCompany"
                          value={data.publicCompany}
                          onChange={handleChange}
                          required
                          disabled={isView ? true : false}

                        >
                          <option value={'No'}>No</option>
                          <option value={'Si'} >Si</option>
                          <option value={'Mixta'} >Mixta</option>

                        </select>
                      </td>

                      <td>
                        <label> Industria</label>
                      </td>
                      <td colSpan={2}>
                        <input
                          name="Industry"
                          value={data.Industry}
                          onChange={handleChange}
                          required
                          disabled={isView ? true : false}></input>
                      </td>
                    </tr>

                    <tr>
                      <td >
                        <label>Direccion:</label>
                      </td>
                      <td >
                        <input

                          name="Address"
                          value={data.Address}
                          onChange={handleChange}
                          required
                          disabled={isView ? true : false}
                        />
                      </td>
                      <td >
                        <label>Ciudad:</label>
                      </td>
                      <td >
                        <input

                          name="city"
                          value={data.city}
                          onChange={handleChange}
                          required
                          disabled={isView ? true : false}
                        />

                      </td>

                    </tr>
                    <tr>
                      <td><label>Pais:</label></td>

                      <td >
                        <input

                          name="Country"
                          value={data.Country}
                          onChange={handleChange}
                          required
                          disabled={isView ? true : false}
                        />



                      </td>
                      <td >
                        <label>Telefono fijo:</label>
                      </td>
                      <td >
                        <input

                          name="phone"
                          value={data.phone}
                          onChange={handleChange}
                          required
                          disabled={isView ? true : false}
                        />
                      </td>

                    </tr>
                    <tr>
                      <td >
                        <label>Sitio web:</label>
                      </td>
                      <td >
                        <input

                          name="url"
                          value={data.url}
                          onChange={handleChange}
                          required
                          disabled={isView ? true : false}
                        />
                      </td>
                      <td ><label>Email:</label></td>

                      <td >
                        <input

                          name="email"
                          value={data.email}
                          onChange={handleChange}
                          required
                          disabled={isView ? true : false}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td >
                        <label>Representante legal:</label>
                      </td>
                      <td >
                        <input

                          name="LegalRepresentative"
                          value={data.LegalRepresentative}
                          onChange={handleChange}
                          required
                          disabled={isView ? true : false}
                        />
                      </td>
                      <td >
                        <label>Cedula:</label>
                      </td>
                      <td >
                        <input

                          name="DNILegalRep"
                          value={data.DNILegalRep}
                          onChange={handleChange}
                          required
                          disabled={isView ? true : false}
                        />
                      </td>

                    </tr>
                    <tr>
                      <td >
                        <label>Presona Contacto:</label>
                      </td>
                      <td >
                        <input

                          name="ContactPerson"
                          value={data.ContactPerson}
                          onChange={handleChange}
                          required
                          disabled={isView ? true : false}
                        />
                      </td>
                      <td ><label>Número telefónico:</label></td>

                      <td >
                        <input

                          name="PhoneContact"
                          value={data.PhoneContact}
                          onChange={handleChange}
                          required
                          disabled={isView ? true : false}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td >
                        <label>Año de creacion:</label>
                      </td>
                      <td >
                        <input

                          name="BornDate"
                          value={data.BornDate}
                          onChange={handleChange}
                          required
                          disabled={isView ? true : false}
                        />
                      </td>
                      <td ><label>Núm. empleados:</label></td>

                      <td >
                        <input

                          name="numberofemployees"
                          value={data.numberofemployees}
                          onChange={handleChange}
                          required
                          disabled={isView ? true : false}
                        />
                      </td>
                    </tr>




                    <tr>
                      <td colSpan={2}><label>¿Qué clase de productos o servicios ofrece ?</label></td>
                      <td colSpan={4}>
                        <textarea
                          style={{ overflow: 'hidden', resize: 'none' }}
                          name="products"
                          value={data.products}
                          onChange={handleChange}
                          required
                          rows={6}
                          disabled={isView ? true : false}

                        ></textarea></td>

                    </tr>

                  </tbody>
                </table>
              </Frame>


              <Row>
                <Col></Col>
                <Col></Col>
                <Col></Col>
                <Col></Col>
                {!isView ? (
                  <>
                    <Col>
                      <Button
                        className="btn btn-danger"
                        onClick={() => {
                          clearStates()
                          hide(!show)
                        }}
                      >
                        Cancelar
                      </Button>
                    </Col>
                    <Col>
                      <Button type="submit" className="btn btn-success">
                        Guardar
                      </Button>
                    </Col>
                  </>
                ) : (
                  <Col>
                    <Button
                      className="btn btn-secundary"
                      onClick={() => {
                        clearStates()
                        hide(!show)
                      }}
                    >
                      Cerrar
                    </Button>
                  </Col>
                )}
              </Row>
            </Form>
          </ModalContainer>
        </Overlay>
      )}
    </>
  )
}

export default COMFORCompanies


const Frame = styled.div`
          width: 950px;
          padding-bottom: 0px;
          margin-bottom: 10px;
          align-items: center;
          justify-content: left;
          border-width: 2px;
          border-color: #1a004a;
          border-style: solid;
          font-size: 12px;
          font-weight: 100;
          .photo{
            background:  #ebf1de
  }
          .rms{
            background:  #F2F2F2
  }
  label {
          padding-left: 5px;
          display: flex;
          font-size: 12px;
          font-weight: bold;
          text-align: 'center';
    
  }
  input {
          text-align: center;
          width: 100%;
          border-bottom: 1px solid black;
          border-color: #1a004a;
          background: ${props => (props.isView ? '#FFF' : '#cee1ed')};
          padding-left: 5px;
          /* margin-top: 5px; */
  }
  textarea {
    text-align: justify;
    width: 100%;
    border-bottom: 1px solid black;
    border-color: #1a004a;
    background: ${props => (props.isView ? '#FFF' : '#cee1ed')};
    resize: none;
    padding-left: 5px;
    
  } 

  input.edit {
    background: #cee1ed;
  }
  input.disable {
    background: #fff;
  }
  h2 {
    font-size: 14px;
    font-weight: bold;
    text-align: 'center';
  }

  th {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    text-align: left;
    vertical-align: center;
    padding-left: 5px;

    input {
      width: 100%;
    }
  }
  tr {
    border-width: 0px;
    border-color: #1a004a;
    border-style: solid;
  }
    
  td {
          border-width: 1px;
          border-color: #1a004a;
          border-style: solid;
  }
`

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgb(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalContainer = styled.div`
  width: 1010px;
  max-height: 90vh;
  min-height: 90vh;
  background: #fff;
  position: relative;
  border-radius: 5px;
  overflow: scroll;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
`

const ModalHeader = styled.div`
  width: 950px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
  padding-bottom: 10px;

  th {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
  }
  tr {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
  }
  table {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    text-align: center;
    vertical-align: center;
    font-size: 14px;
  }
  h3 {
    font-weight: 500;
    font-size: 16px;
    color: #1766dc;
  }
`

const CloseButtonModal = styled.div`
  position: absolute;
  top: 2px;
  right: 5px;
  width: 20px;
  height: 20px;
  border: none;
  background: none;
  cursor: pointer;
  transition: 0.3s ease all;
  border-radius: 5px;
  color: #1766dc;
  &:hover {
    background: #f2f2f2;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`
export const Label = styled.label`
  width: 185px;
  height:150px;
  position: relative;
  cursor: ${({ isView }) => (isView ? 'normal' : 'pointer')};
`

