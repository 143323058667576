import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import {  updateFormat } from './request'
import { Button, Col, Form, Row } from 'react-bootstrap'
import Moment from 'moment';
import Signer from '../signer'
import { toast } from 'react-toastify'
import { HumanTable } from '../../humantalent/HumanTable'
import SelectOption from '../SelectOption'
import { useUserContext } from '../../../../hooks/custom'
import SpinnerLoad from '../spinnerLoad'
import image from '../../../../assets/img/Logo_b-st.png'
import { monedaCOP, onlyNumeric } from '../functions'
import { createEmployeeFormat, updateEmployeeFormat } from '../../humantalent/HumanTalent.request'



export const GTHFOR086 = ({ show, hide, restart, setRestart, isView, isCreate = false, isSign = false, data, setData, IdFormat_PK, list, workflow }) => {

  /*---------ESTADOS------------------
      [     creado      ] => docuento guardado sin firmas
              V
      [  firmadoEmpleado  ] => cuando el empleado lo ha firmado
              V
      [     firmadoTH     ] => cuando TH lo ha firmado
              V
      [    firmadoJefe    ] => cuando el jefe lo ha firmado
              V
      [    disponible   ] => cuando el contabilidad lo ha firmado
              V
     [    contratacion   ] => cuando esta en proceso de firmas contratos
              V
       [     activo      ] => cuando se ha vinculado con un contrato
              V
      [    inactivo     ] => cuando el contrato ha termiado

  */


  const flujo = {
    wf: {
      creado: "verificar informacion",
      firmadoEmpleado: "firmar TH",
      firmadoTH: "firmar Jefe",
      firmadoJefe: "Firmar Contabilidad",
      disponible: "Contratar",
      activo: "inactivo"

    },
    estado: {
      creando: "creado",
      creado: "firmadoEmpleado",
      firmadoEmpleado: "firmadoTH",
      firmadoTH: "firmadoJefe",
      firmadoJefe: "disponible",
      disponible: "activo",
      activo: "inactivo"

    }
  }
  //-------------------------Info Formato   
  const formato = 'GTH-FOR-86'
  const revision = '7'
  const fechaRev = '31/07/2020'
  const nombreFormato = "REPORTE DE INGRESO"
  const typef = 'GTHFOR086'
  const type = 'GTHFOR086'

  //-------------------------Registros 
  const { sessionUser, Permission } = useUserContext()
  const [mainCompany, setMainCompany] = useState([])
  const [status, setStatus] = useState(IdFormat_PK)
  const [date, setDate] = useState('')
  const [familyTable, setFamilyTable] = useState([])
  const [basico, setbasico] = useState(true)
  const [beneficiario, setbeneficiario] = useState(true)
  const [firmaEmpleado, setfirmaEmpleado] = useState('')
  const [firmaContabilidad, setfirmaContabilidad] = useState('')
  const [firmaTalentoHumano, setfirmaTalentoHumano] = useState('')
  const [firmaJefe, setfirmaJefe] = useState('')
  const [load, setLoad] = useState(false)
  const [empleados, setEmpleados] = useState('')
  const [listaLider, setListaLider] = useState([])
  const [listaTH, setListaTH] = useState(null)
  const [listaContabilidad, setListaContabilidad] = useState(null)
  const [salary, setSalary] = useState({})
  const createContract = Permission.superAdmin.access || Permission.humanTalent.POST
  const [_next, setNext] = useState(false)
  const [nextWF, setNextWF] = useState()
  const [count, setcount] = useState(0)
  const [employee, setEmployee] = useState()
  useEffect(() => {

    verifyNext()

  }
    , [firmaEmpleado.IdEmployee_FK])

  useEffect(() => {
    if (restart)
      getInfo()
  }, [restart])

  const convertToPdf = () => {
    // Código para imprimir el contenido
    window.print();
  };
  const handleChange = async ({ target }) => {
    const { name, type } = target
    const value = type === 'checkbox' ? target.checked : target.value
    if (name === 'IntegralSalary') {

      setbasico(false)
    } else {
      setbasico(true)
    }

    if (name === 'EmpBeneficiaries') {
      if (value === 'No') {
        setbeneficiario(false)
      } else {
        setbeneficiario(true)
      }
    }
    setData({ ...data, [name]: value })

  }
  const verifyNext = () => {

    if (isSign) {
      const _Sign = {
        firmaEmpleado: sessionUser.IdEmployee_FK === firmaEmpleado.IdEmployee_FK,
        firmaTalentoHumano: sessionUser.IdEmployee_FK === firmaTalentoHumano.IdEmployee_FK,
        firmaJefe: sessionUser.IdEmployee_FK === `${firmaJefe.IdEmployee_FK}`,
        firmaContabilidad: sessionUser.IdEmployee_FK === firmaContabilidad.IdEmployee_FK,
      }
      let tempWF = { ...workflow, IdWorkFlow_PK: null, IdEmployee_created: sessionUser.IdEmployee_FK }
      if (_Sign.firmaEmpleado && !_Sign.firmaTalentoHumano && !_Sign.firmaJefe && !_Sign.firmaContabilidad) {
        setNext({ Status: "firmadoEmpleado", wf: true })
        tempWF = { ...tempWF, IdEmployee_FK: firmaTalentoHumano.IdEmployee_FK, action: 'firmar TH' }
      } else if (!_Sign.firmaEmpleado && _Sign.firmaTalentoHumano && !_Sign.firmaJefe && !_Sign.firmaContabilidad) {
        setNext({ Status: "firmadoTH", wf: true })
        tempWF = { ...tempWF, IdEmployee_FK: `${firmaJefe.IdEmployee_FK}`, action: 'firmar Jefe' }
      } else if (!_Sign.firmaEmpleado && _Sign.firmaTalentoHumano && _Sign.firmaJefe && !_Sign.firmaContabilidad) {

        setNext({ Status: "firmadoJefe", wf: true })
        tempWF = { ...tempWF, IdEmployee_FK: firmaContabilidad.IdEmployee_FK, action: 'Firmar Contabilidad' }

      } else if (!_Sign.firmaEmpleado && !_Sign.firmaTalentoHumano && _Sign.firmaJefe && !_Sign.firmaContabilidad) {
        setNext({ Status: "firmadoJefe", wf: true })
        tempWF = { ...tempWF, IdEmployee_FK: firmaContabilidad.IdEmployee_FK, action: 'Firmar Contabilidad' }
      } else if (!_Sign.firmaEmpleado && !_Sign.firmaTalentoHumano && !_Sign.firmaJefe && _Sign.firmaContabilidad) {
        setNext({ Status: "disponible", wf: false })
        return
      } else if (!_Sign.firmaEmpleado && !_Sign.firmaTalentoHumano && _Sign.firmaJefe && _Sign.firmaContabilidad) {
        setNext({ Status: "disponible", wf: false })
        return
      }
      setNextWF(tempWF)
    } else {
      if (isCreate) {
        setNext({ Status: "creado", wf: true })
      } else if (data.Status === 'editando') {
        setNext({ Status: "creado", wf: false })
      }
    }

  }
  const getInfo = async () => {
    let mainCompany = []
    const formatDate = Moment().format('YYYY-MM-DD')
    
    // list.empleados.filter(item => item.areaTrabajo.includes("CYF"))
    setDate(formatDate)
    // let resDNI = await getFormats('employee')
    // resDNI = resDNI.data.data.filter(item => item.Status === "activo")
    // console.log(resDNI, list.empleados)
    setEmpleados( list.Empleados)
    // var res = await getUniqueList('GTHFOR070', 'Contrato')
    // var listTemp = res
    // let contrato = await getFormats('COMFORContract')
    // let contrato = list.contrato.find(contrato => contrato.CeCo === data.centroCosto)
    if (data.EmpBeneficiariesData !== undefined) {
      setFamilyTable(await JSON.parse(data.EmpBeneficiariesData))
    }
    // var resCompanies = await getFormats('company')
    // if (resCompanies.data.status === 200) {
    //   mainCompany = resCompanies.data.data.find(i => i.MainCompany === "MAIN")
      // setMainCompany(mainCompany)
    // }

    setMainCompany(list?.mainCompany[0])

    if (isCreate && !load) {
      setLoad(true)
      if (data.dniCandidato !== undefined) {

        let dateEnd = new Date();

        if (data.terminoInicial)
          dateEnd.setMonth(dateEnd.getMonth() + 1 + parseInt(data.terminoInicial.split(" ")[0]));

        setNextWF({
          TaskDescription: `Ingreso de ${data.nombreCandidato} solicitado por  ${sessionUser.NombreTrabajador} `,
          Status: "pendiente",
          action: 'verificar informacion',
          Format: type,
          dateCreation: formatDate,
          IdEmployee_created: sessionUser.IdEmployee_FK,
          documents: nombreFormato,
          IdEmployee_FK: data.dniCandidato,
        })
        setfirmaEmpleado({
          ApellidoTrabajador: data.apellidoCandidato,
          IdEmployee_FK: `${data.dniCandidato}`,
          NombreTrabajador: data.nombreCandidato,
          fecha: ""
        })
        setfirmaJefe({
          ApellidoTrabajador: data.apellidoSolicitante,
          IdEmployee_FK: `${data.IdUser_FK}`,
          NombreTrabajador: data.nombreSolicitante,
          fecha: ""
        })

        setListaContabilidad( list.Empleados.filter(item => item.areaTrabajo.includes("CYF")))
        setListaLider( list.Empleados.filter(item => item.personalACargo.includes("Si")))
        setListaTH( list.Empleados.filter(item => item.areaTrabajo.includes("TH")))
        if (data.salario)
          setSalary(JSON.parse(data.salario))
        data = {
          EmpName: data.nombreCandidato,
          EmpLastName: data.apellidoCandidato,
          idEmployeePK: data.dniCandidato,
          EmpCCExpedition: data.expedicionCandidato,
          WorksiteCity: data.lugarTrabajoReq,
          WorksitePosition: data.cargoRequerido,
          Area: data.areaSolicitante,
          CostCenter: data.centroCosto,
          HiringDate: data.ingresoEfectivo,
          ContractType: data.tipoContrato,
          WorkShift: data.turno,
          ExpirationDate: dateEnd.toString().split("T")[0],
          WorksiteAdress: list.mainCompany[0].Address,
          WorksitePhone: list.mainCompany[0].phone,

          Laptop: data.computador,
          Phone: data.telefonoMovil,
          User: data.eMail,
          WorkSite: data.puestoTrabajo,
          OfficeSupplies: data.elementosOficina,
          Screen: data.pantalla,
          Harddisk: data.discoDuro,
          Licence: data.licencias, //Avantel
          createdBy: sessionUser.IdEmployee_FK,
          EmpRQ: data.IdFormat_PK,
          Date: date,
          Status: "creando"
        }




        setData(data)
      }
    } else if (!isCreate && !load) {
      if (isSign) setStatus("firmando")
      else if (!isView && data.Status === "editando") {
        setListaContabilidad( list.Empleados.filter(item => item.areaTrabajo.includes("CYF")))
        setListaLider( list.Empleados.filter(item => item.personalACargo.includes("Si")))
        setListaTH( list.Empleados.filter(item => item.areaTrabajo.includes("TH")))
      }

      setfirmaTalentoHumano(JSON.parse(data.firma2))
      setfirmaJefe(JSON.parse(data.firma3))
      setfirmaContabilidad(JSON.parse(data.firma4))
      setSalary(JSON.parse(data.Salary))
      setbeneficiario(JSON.parse(data.EmpBeneficiariesData))
      setfirmaEmpleado(JSON.parse(data.firma1))
    }
    return true
  }
  const clearStates = () => {
    data = []
    if (IdFormat_PK !== "en creacion") {
      setData('')
      //setRestart(!restart)
    }
    setDate('')
  }
  const onSubmit = async e => {

    e.preventDefault()

    var result = []



    if (
      (isSign && (sessionUser.IdEmployee_FK) === (firmaEmpleado.IdEmployee_FK) && !firmaEmpleado.image) ||
      (isSign && (sessionUser.IdEmployee_FK) === (firmaTalentoHumano.IdEmployee_FK) && !firmaTalentoHumano.image) ||
      (isSign && (sessionUser.IdEmployee_FK) === `${firmaJefe.IdEmployee_FK}` && !firmaJefe.image) ||
      (isSign && (sessionUser.IdEmployee_FK) === (firmaContabilidad.IdEmployee_FK) && !firmaContabilidad.image)
    ) {
      toast.error('por favor firmar el documento')
      return
    }

    data = {
      ...data,
      firma1: JSON.stringify(firmaEmpleado),
      firma2: JSON.stringify(firmaTalentoHumano),
      firma3: JSON.stringify(firmaJefe),
      firma4: JSON.stringify(firmaContabilidad),
      salary: JSON.stringify(salary),
      EmpBeneficiariesData: JSON.stringify(familyTable),

    }
    // let userData = {
    //   IdUser_PK: data.idEmployeePK,
    //   UserName: data.EmpName,
    //   UserLastName: data.EmpLastName,
    //   IdCompany_FK: mainCompany.IdCompany_PK,
    //   UserEmail: data.EmpEmail,
    //   phoneNumber: data.EmpCellPhone,
    //   IdJefeInm_FK: `${firmaJefe.IdEmployee_FK}`,
    //   Position: data.WorksitePosition,
    //   Status: "activing"
    // }
    if (isSign) {
      let dataSing, actionWF, idWF
      if (data.Status === "creado") {
        dataSing = { IdFormat_PK: data.IdFormat_PK, firma1: JSON.stringify(firmaEmpleado), Status: "firmadoEmpleado" }
        actionWF = 'firmar TH'
        idWF = firmaTalentoHumano.IdEmployee_FK
      }
      else if (data.Status === "firmadoEmpleado") {
        dataSing = { IdFormat_PK: data.IdFormat_PK, firma2: JSON.stringify(firmaTalentoHumano), firma3: JSON.stringify(firmaJefe), Status: firmaJefe.image ? 'firmadoJefe' : "firmadoTH" }
        actionWF = firmaJefe.image ? 'firmar Contabilidad' : 'firmar Jefe'
        idWF = firmaJefe.image ? firmaContabilidad.IdEmployee_FK : firmaJefe.IdEmployee_FK
      }
      else if (data.Status === "firmadoTH") {
        dataSing = { IdFormat_PK: data.IdFormat_PK, firma3: JSON.stringify(firmaJefe), firma4: JSON.stringify(firmaContabilidad), Status: firmaContabilidad.image ? 'disponible' : "firmadoJefe" }
        actionWF = firmaContabilidad.image ? undefined : 'firmar Contabilidad'
        idWF = firmaContabilidad.image ? undefined : firmaContabilidad.IdEmployee_FK

      } else if (data.Status === "firmadoJefe") {
        dataSing = { IdFormat_PK: data.IdFormat_PK, firma4: JSON.stringify(firmaContabilidad), Status: "disponible" }
        actionWF = undefined
        idWF = undefined
      }
     result = await updateEmployeeFormat(type, dataSing, workflow, idWF, actionWF)
  
    }
    else if (isCreate) {
       result = await createEmployeeFormat(type,{...data, Status:'creado'},
        {
          TaskDescription: `Ingreso de ${firmaEmpleado.NombreTrabajador} solicitado por  ${sessionUser.NombreTrabajador} `,
          Status: "pendiente",
          action: 'verificar informacion',
          Format: type,
          dateCreation: date,
          IdEmployee_created: sessionUser.IdEmployee_FK,
          documents: "Reporte de Ingreso",
          IdEmployee_FK: firmaEmpleado.IdEmployee_FK,
          IdFormat_FK: ""
        }
      )

      // // VERIFICAR SI EXISTEN OTROS DOCUMENTOS DE LA MISMA PERSONA EN PROCESO
      // result = await getFormats(typef)
      // var docOpen = result.data.data.filter(i => i.idEmployeePK.includes(data.idEmployeePK) & i.Status !== "inactivo").length
      // if (docOpen > 0) {
      //   toast.error("El usuario " + data.idEmployeePK + " tiene " + docOpen + " solicitudes de empleo en proceso, por favor cancele o inactive los documentos e intentelo de nuevo")
      //   return
      // }
      // //SE CREAR EL NUEVO PERFIL DE EMPLEADO
      // result = await CreateFormat({ ...data, createdBy: sessionUser.IdEmployee_FK }, typef)
      // if (result.data.status !== 200) { toast.error(result.data.msj); return }
      // // SE CREA EL WF PARA QUE EL NUEVO EMPLEADO FIRME EL DOCUMENTO, TENIENDO EN CUENTA EL ID DEL DOCUMENTO CREADO ANTERIORMENTE
      // result = await newWorkflow({ ...nextWF, IdFormat_FK: result.data.data.insertId })
      // // SE ACTUALIZA EL FORMATO DE SOLICITUD DE EMPLEO, SE PASA A FINALIZADO
      // result = await updateFormat({ IdFormat_PK: data.EmpRQ, Status: "finalizado" }, "GTHFOR070")
      // // SE VERIFICA SI EL USUSAIRO YA ESXITES, SI EXISTE SE ACTIVA Y SI NO SE CREA UNO NUEVO
      // result = await getUserByIdF('IdUser_PK', data.idEmployeePK + "@", false)
      // if (result.data.status === 200) result = await EditingUserF(userData)
      // else result = await CreateUserF(userData)
    }
    else {
      // result = await getUserByIdF('IdUser_PK', data.idEmployeePK + "@", false)
      // if (result.data.status === 200) result = await EditingUserF(userData)
      // else result = await CreateUserF(userData)

      result = await updateFormat(data, typef)

      // if (result.data.status === 200 && !_next.wf) {
      //   toast.success(result.data.msj)
      // }
      // else if (result.data.status === 200 && _next.wf) {
      //   result = await newWorkflow({ ...nextWF, IdFormat_FK: data.IdFormat_PK })
      //   if (result.data.status === 200) {
      //     toast.success(result.data.msj)

      //   } else { toast.error(result.data.msj); return }
      // }
      // else { toast.error(result.data.msj); return }
    }
    if (result.data.status === 200) {
      toast.success(result.data.msj)
    } else {
      toast.error(result.data.msj);
      return
    }
     clearStates()
     hide(!show)
     setRestart(!restart)
  }
  return (
    <>
      {show && (
        <Overlay style={{ zIndex: '1' }} >
          <SpinnerLoad trigger={firmaEmpleado.IdEmployee_FK} />
          <ModalContainer>
            <CloseButtonModal className="no-print" onClick={() => {
              hide(!show)
              clearStates()

            }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-x-square"
                viewBox="0 0 16 16"
              >
                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </CloseButtonModal>
            <ModalHeader>
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <th style={{ width: '20%', fontSize: '10px', verticalAlign: 'bottom' }} rowSpan={3}>
                      <img
                        className="format_logo"
                        src={image}
                        style={{ paddingBottom: '7px', width: '75%', minWidth: "40px" }}
                        alt="Logo RMS"
                      /><br />
                      NIT 830.507.705-2
                    </th>
                    <th style={{ width: '55%', height: '30px' }}>RELIABILITY MAINTENANCE SERVICES S.A.S</th>
                    <th style={{ width: '25%' }}>{formato}</th>
                  </tr>
                  <tr>
                    <th rowSpan={2}>{nombreFormato}</th>
                    <th style={{ fontSize: '10px' }}>Revisión: {revision}</th>
                  </tr>
                  <tr>
                    <th style={{ fontSize: '10px' }}>Fecha de revisión: {fechaRev}</th>
                  </tr>
                </tbody>
              </table>
            </ModalHeader>
            <Row><Col></Col>
              <Col>
                <center> <label style={{ fontSize: '11px', fontWeight: 'bold' }}>Solicitud No.</label>
                  <label style={{ fontSize: '11px', fontWeight: '10000' }}>{IdFormat_PK}</label>
                </center>
              </Col>
              <Col>
                <center> <label style={{ fontSize: '11px', fontWeight: 'bold' }}>Estado:</label>
                  <label style={{ fontSize: '11px', fontWeight: '10000' }}>{data.Status}</label>
                </center>
              </Col>
            </Row>




            <Form onSubmit={onSubmit}>
              <Frame isView={isView}>
                <table style={{ width: '100%', maxWidth: '100%' }}>
                  <tbody>
                    <tr>
                      <th className="rms" colSpan={9} style={{ width: '100%' }}>
                        <h2 className="text-center">DATOS PERSONALES </h2>
                      </th>
                    </tr>
                    <tr >
                      <td style={{ width: '9%' }}><label>Nombres:</label></td>
                      <td style={{ width: '14%' }}><input name='EmpName' value={data.EmpName} onChange={handleChange} required readOnly disabled={isView ? true : false} /></td>
                      <td style={{ width: '8%' }}><label>Apellidos:</label></td>
                      <td style={{ width: '15%' }}><input name='EmpLastName' value={data.EmpLastName} onChange={handleChange} required readOnly disabled={isView ? true : false} /></td>
                      <td colSpan={1} style={{ width: '10%' }} ><label>Dir. residencia:</label></td>
                      <td colSpan={3} style={{ width: '42%' }} ><input name='EmpAddress' value={data.EmpAddress} onChange={handleChange} required disabled={isView ? true : false} /></td>
                    </tr>
                    <tr >
                      <td ><label>Cédula:</label></td>
                      <td style={{fontWeight:'400', textAlign:'center'}}>{data.idEmployeePK}</td>
                      <td ><label>De:</label></td>
                      <td >
                        <SelectOption type={"city"} searchable={true} state={data} setState={setData} nameState={'EmpCCExpedition'} required disabled={isView ? true : false} />
                      </td>
                      <td style={{ width: '13%' }} ><label>Ciudad:</label></td>
                      <td  >
                        <SelectOption type={"city"} searchable={true} state={data} setState={setData} nameState={'EmpCity'} required disabled={isView ? true : false} />


                      </td>
                      <td  ><label>Barrio:</label></td>
                      <td colSpan={2}  ><input name='EmpNeighborhood' value={data.EmpNeighborhood} onChange={handleChange} required disabled={isView ? true : false} /></td>
                    </tr>
                    <tr >
                      <td colSpan={2} ><label>Fecha de nacimiento:</label></td>
                      <td colSpan={2} ><input name='EmpBornDate' type={'date'} value={data.EmpBornDate} onChange={handleChange} required disabled={isView ? true : false} /></td>
                      <td ><label>Teléfono fijo:</label></td>
                      <td ><input name='EmpPhone' value={data.EmpPhone} onChange={e => setData({ ...data, EmpPhone: e.target.value })} required disabled={isView ? true : false} /></td>
                      <td ><label>Móvil:</label></td>
                      <td colSpan={2}><input name='EmpCellPhone' value={data.EmpCellPhone} onChange={e => setData({ ...data, EmpCellPhone: onlyNumeric(e.target.value) })} required disabled={isView ? true : false} /></td>
                    </tr>
                    <tr >
                      <td colSpan={2}><label>Lugar de nacimiento:</label></td>
                      <td colSpan={2} >
                        <SelectOption type={"city"} searchable={true} state={data} setState={setData} nameState={'EmpBornCity'} required disabled={isView ? true : false} />
                      </td>
                      <td ><label>Email:</label></td>
                      <td colSpan={4} ><input name='EmpEmail' value={data.EmpEmail} onChange={handleChange} required disabled={isView ? true : false} /></td>
                    </tr>
                    <tr >
                      <td colSpan={2}><label>Grupo sanguineo:</label></td>
                      <td colSpan={2} ><SelectOption type={"BloodGroup"} searchable={true} state={data} setState={setData} nameState={'EmpRH'} required disabled={isView ? true : false} /></td>
                      <td  ><label>Estado civil:</label></td>
                      <td ><SelectOption type={"CivilState"} searchable={true} state={data} setState={setData} nameState={'EmpCivilStatus'} required disabled={isView ? true : false} /></td>
                      <td ><label>Sexo:</label></td>
                      <td colSpan={2}><SelectOption type={"gender"} searchable={true} state={data} setState={setData} nameState={'EmpGender'} required disabled={isView ? true : false} /></td>
                    </tr>
                    <tr >
                      <td colSpan={2}><label>Licencia de conducción:</label></td>
                      <td colSpan={2} >
                        <SelectOption type={"DrivingLicense"} searchable={true} state={data} setState={setData} nameState={'EmpDrivingLicense'} required disabled={isView ? true : false} />
                      </td>
                      <td colSpan={1}><label>Número de hijos:</label></td>
                      <td colSpan={3} ><input name='EmpCantSons' placeholder='Cantidad en número' value={data.EmpCantSons} onChange={handleChange} required disabled={isView ? true : false} /></td>
                    </tr>
                    <tr >
                      <td colSpan={2}><label>En caso de emergencia avisar a :</label></td>
                      <td colSpan={2}><input name='EmpEmergencyPerson' value={data.EmpEmergencyPerson} onChange={handleChange} required disabled={isView ? true : false} /></td>
                      <td colSpan={1}><label>Tél. de contacto:</label></td>
                      <td  ><input name='EmpEmergencyPhone' value={data.EmpEmergencyPhone} onChange={handleChange} required disabled={isView ? true : false} /></td>
                      <td><label>Parentesco:</label></td>
                      <td  >
                        <SelectOption type={"family"} searchable={true} state={data} setState={setData} nameState={'EmpEmergencyPersonKindship'} required disabled={isView ? true : false} />
                      </td>
                    </tr>


                    <tr >
                      <td colSpan={8}><label>NOTA: Anexar las copias de la Cédula de Ciudadanía y Licencia de Conducción.</label></td>
                    </tr>
                    <tr >
                      <td colSpan={4} ><label>Beneficiarios para afiliación a EPS y Caja de Compensación:</label></td>
                      <td colSpan={2}><Row>
                        <Col>
                          <label style={{ width: '2px', right: '1px' }}>
                            <input
                              style={{ width: '20px' }}
                              type={'radio'}
                              name={'EmpBeneficiaries'}
                              value={'Si'}
                              checked={data['EmpBeneficiaries'] === 'Si' ? 'checked' : ''}
                              onChange={handleChange}
                              required
                              disabled={isView ? true : false}
                            />
                            Si
                          </label>
                        </Col>
                      </Row></td>
                      <td colSpan={2}>
                        <label style={{ width: '2px', right: '1px' }}>
                          <input
                            style={{ width: '20px' }}
                            type={'radio'}
                            name={'EmpBeneficiaries'}
                            value={'No'}
                            checked={data['EmpBeneficiaries'] === 'No' ? 'checked' : ''}
                            onChange={handleChange}
                            required
                            disabled={isView ? true : false}
                          />
                          No
                        </label>
                      </td>
                    </tr>
                    {data['EmpBeneficiaries'] === "Si" && (<tr >
                      <td colSpan={9} >
                        <HumanTable className='no-print' data={familyTable} setData={setFamilyTable} isView={isView} tableType={'tableFamily'} list={list} />
                      </td>
                    </tr>)}
                  </tbody>
                </table>
              </Frame>
              {/* ------------------------------------------------------------------Segunda tabla--------------------------------------------------------------------------------- */}
              <Frame isView={isView}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr >
                      <th className="rms" colSpan={7} style={{ width: '100%' }}>
                        <h2 className="text-center">DATOS LABORALES </h2>
                      </th>
                    </tr>
                    <tr >
                      <td  ><label>Dirección:</label></td>
                      <td colSpan={3} ><input tabIndex="1" name='WorksiteAdress' value={data.WorksiteAdress} onChange={handleChange} required disabled={isView ? true : false} /></td>
                      <td style={{ width: '15%' }} rowSpan={2}><label>Sueldo:</label></td>
                      <td ><label>Básico:$</label></td>
                      <td ><input name='salary' tabIndex="11" value={salary.tipo === "Basico" ? monedaCOP(salary.valor) : ""} onChange={e => { setSalary({ ...salary, valor: onlyNumeric(e.target.value), tipo: "Basico" }) }} disabled={isView ? true : false} /></td>
                    </tr>
                    <tr >
                      <td style={{ width: '7%' }}><label>Ciudad:</label></td>
                      <td style={{ width: '20%' }}>
                        <SelectOption type={"city"} searchable={true} state={data} setState={setData} nameState={'WorksiteCity'} required disabled={isView ? true : false} />
                      </td>
                      <td style={{ width: '7%' }}><label >Telefono:</label></td>
                      <td style={{ width: '12%' }}><input tabIndex="3" name='WorksitePhone' value={data.WorksitePhone} onChange={handleChange} required disabled={isView ? true : false} /></td>
                      <td style={{ width: '10%' }}><label>Integral:$</label></td>
                      <td style={{ width: '10%' }}><input tabIndex="12" name='IntegralSalary' value={salary.tipo === "Integral" ? monedaCOP(salary.valor) : ""} onChange={e => { setSalary({ ...salary, valor: onlyNumeric(e.target.value), tipo: "Integral", primaAlimentacion: "", primaLocalizacion: "", primaMovilizacion: "", otroSalario: "", textoOtroSalario: "" }) }} disabled={isView ? true : false} /></td>
                    </tr>
                    <tr >
                      <td ><label>Cargo:</label></td>
                      <td ><input tabIndex="4" name='WorksitePosition' value={data.WorksitePosition} onChange={handleChange} required disabled={isView ? true : false} /></td>
                      <td ><label >Area:</label></td>
                      <td >
                        <SelectOption type={"workArea"} searchable={true} state={data} setState={setData} nameState={'Area'} required disabled={isView ? true : false} widthSelect='100%' />


                      </td>
                      <td colSpan={3} ><label>Primas extralegales no prestacionales:</label></td>
                    </tr>
                    <tr >
                      <td colSpan={2}><label>Centro de costo:</label></td>
                      <td colSpan={2} >
                        <SelectOption type={"CeCo"} searchable={true} state={data} setState={setData} nameState={'CostCenter'} required disabled={isView ? true : false} />
                      </td>
                      <td><label>Alimentación:$</label></td>
                      <td colSpan={2} >{basico && (<input tabIndex="13" className={salary.tipo === "Integral" ? "disable" : ""} name='Alimentation' value={monedaCOP(salary.primaAlimentacion)} onChange={e => setSalary({ ...salary, primaAlimentacion: onlyNumeric(e.target.value) })} disabled={isView || salary.tipo === "Integral" ? true : false} />)} </td>
                    </tr>
                    <tr >
                      <td colSpan={2}><label>Fecha de contratación:</label></td>
                      <td colSpan={2} ><input tabIndex="7" name='HiringDate' type={'date'} value={data.HiringDate} onChange={handleChange} required disabled={isView ? true : false} /></td>
                      <td><label>Localización:$</label></td>
                      <td colSpan={2} style={{ width: '18%' }}>{(<input tabIndex="14" className={salary.tipo === "Integral" ? "disable" : ""} name='Localitation' value={monedaCOP(salary.primaLocalizacion)} onChange={e => setSalary({ ...salary, primaLocalizacion: onlyNumeric(e.target.value) })} disabled={isView || salary.tipo === "Integral" ? true : false} />)}</td>
                    </tr>
                    <tr >
                      <td colSpan={2}><label>Fecha de vencimiento:</label></td>
                      <td colSpan={2} style={{ width: '18%' }}><input tabIndex="8" name='ExpirationDate' type={'date'} value={data.ExpirationDate} onChange={handleChange} required disabled={isView ? true : false} /></td>
                      <td><label>Movilización:</label></td>
                      <td colSpan={2} style={{ width: '18%' }}>{(<input tabIndex="15" className={salary.tipo === "Integral" ? "disable" : ""} name='movilitation' value={monedaCOP(salary.primaMovilizacion)} onChange={e => setSalary({ ...salary, primaMovilizacion: onlyNumeric(e.target.value) })} disabled={isView || salary.tipo === "Integral" ? true : false} />)}</td>
                    </tr>
                    <tr >
                      <td colSpan={2}><label>Tipo de contrato:</label></td>
                      <td colSpan={2} style={{ width: '12%' }}>
                        <SelectOption type={"typeContract"} searchable={true} state={data} setState={setData} nameState={'ContractType'} required disabled={isView ? true : false} />
                      </td>
                      <td style={{ fontWeight: "bold", paddingLeft: "7px" }}>Otro: <input tabIndex="15" className={salary.tipo === "Integral" ? "disable" : ""} name='Other' style={{ width: '75%' }} value={salary.textoOtroSalario} onChange={e => setSalary({ ...salary, textoOtroSalario: e.target.value })} disabled={isView || salary.tipo === "Integral" ? true : false} /></td>
                      <td colSpan={2} style={{ width: '18%' }}>{(<input tabIndex="16" name='Other' className={salary.tipo === "Integral" ? "disable" : ""} value={monedaCOP(salary.otroSalario)} onChange={e => setSalary({ ...salary, otroSalario: onlyNumeric (e.target.value) })} disabled={isView || salary.tipo === "Integral" ? true : false} />)}</td>
                    </tr>
                    <tr >
                      <td colSpan={2}><label>Turno de trabajo:</label></td>
                      <td colSpan={2} style={{ width: '18%' }}>
                        <SelectOption type={"workShift"} searchable={true} state={data} setState={setData} nameState={'WorkShift'} required disabled={isView ? true : false} />
                      </td>

                      <td ><label>¿Tiene personal a cargo?</label></td>
                      <td >
                        <label style={{ width: '35px', right: '1px' }}>
                          <input tabIndex="16"
                            style={{ width: '20px' }}
                            type={'radio'}
                            name={'PersonalACargo'}
                            value={'Si'}
                            checked={data['PersonalACargo'] === 'Si' ? 'checked' : ''}
                            onChange={handleChange}
                            required
                            disabled={isView ? true : false}
                          />
                          Si
                        </label>
                      </td>
                      <td >
                        <label style={{ width: '35px', right: '1px' }}>
                          <input tabIndex="17"
                            style={{ width: '20px' }}
                            type={'radio'}
                            name="PersonalACargo"
                            value={'No'}
                            checked={data['PersonalACargo'] === 'No'}
                            onChange={handleChange}
                            required
                            disabled={isView ? true : false}
                          />
                          No
                        </label>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Frame>

              {/* ------------------------------------------------------------------Trecera  tabla---------------------------------------------------------------- */}


              <Frame isView={isView}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr colSpan={8}>
                      <th className="rms" colSpan={4} style={{ width: '50%', textAlign: 'center' }}>
                        <h2>DATOS SEGURIDAD SOCIAL</h2>
                      </th>
                      <th className="rms" style={{ width: '50%', textAlign: 'center' }} colSpan={4}>
                        <h2>BENEFICIO TRIBUTARIO RETENCIÓN EN LA FUENTE.</h2>
                      </th>
                    </tr>
                    <tr >
                      <td ><label>ARL:</label></td>
                      <td style={{ width: '28%' }}>
                        <SelectOption type={"ARL"} searchable={true} state={data} setState={setData} nameState={'ARL'} required disabled={isView ? true : false} />
                      </td>
                      <td ><label>EPS:</label></td>
                      <td style={{ width: '28%' }}>
                        <SelectOption type={"EPS"} searchable={true} state={data} setState={setData} nameState={'EPS'} required disabled={isView ? true : false} />
                      </td>
                      <td colSpan={4} style={{ width: '50%' }}></td>
                    </tr>
                    <tr >
                      <td ><label>AFP:</label></td>
                      <td style={{ width: '28%' }}>
                        <SelectOption type={"AFP"} searchable={true} state={data} setState={setData} nameState={'AFP'} required disabled={isView ? true : false} />
                      </td>
                      <td ><label>Cesantias:</label></td>
                      <td style={{ width: '28%' }}>
                        <SelectOption type={"AFC"} searchable={true} state={data} setState={setData} nameState={'Severance'} required disabled={isView ? true : false} />
                      </td>
                      <td ><label>Intereses de vivienda:</label></td>
                      <td colSpan={2}>
                        <label style={{ width: '35px', right: '1px' }}>
                          <input tabIndex="25"
                            style={{ width: '20px' }}
                            type={'radio'}
                            name={'HomeInterest'}
                            value={'Si'}
                            checked={data['HomeInterest'] === 'Si' ? 'checked' : ''}
                            onChange={handleChange}
                            required
                            disabled={isView ? true : false}
                          />
                          Si
                        </label>
                      </td>
                      <td colSpan={2}>
                        <label style={{ width: '35px', right: '1px' }}>
                          <input tabIndex="26"
                            style={{ width: '20px' }}
                            type={'radio'}
                            name={'HomeInterest'}
                            value={'No'}
                            checked={data['HomeInterest'] === 'No' ? 'checked' : ''}
                            onChange={handleChange}
                            required
                            disabled={isView ? true : false}
                          />
                          No
                        </label>
                      </td>
                    </tr>
                    <tr >
                      <td colSpan={2}><label>Caja de compensación:</label></td>
                      <td colSpan={2} style={{ width: '28%' }}>
                        <SelectOption type={"CCF"} searchable={true} state={data} setState={setData} nameState={'CompensationBox'} required disabled={isView ? true : false} />
                      </td>
                      <td ><label>Estudio Y/o salud prepagado:</label></td>
                      <td colSpan={2}>
                        <label style={{ width: '35px', right: '1px' }}>
                          <input tabIndex="27"
                            style={{ width: '20px' }}
                            type={'radio'}
                            name={'StudyHealth'}
                            value={'Si'}
                            checked={data['StudyHealth'] === 'Si' ? 'checked' : ''}
                            onChange={handleChange}
                            required
                            disabled={isView ? true : false}
                          />
                          Si
                        </label>
                      </td>
                      <td colSpan={2}>
                        <label style={{ width: '35px', right: '1px' }}>
                          <input
                            tabIndex="28"
                            style={{ width: '20px' }}
                            type={'radio'}
                            name={'StudyHealth'}
                            value={'No'}
                            checked={data['StudyHealth'] === 'No' ? 'checked' : ''}
                            onChange={handleChange}
                            required
                            disabled={isView ? true : false}
                          />
                          No
                        </label>
                      </td>
                    </tr>
                    <tr >
                      <td colSpan={2}><label>Pensionado:</label></td>
                      <td >
                        <label style={{ width: '25px', right: '1px' }}>
                          <input tabIndex="23"
                            style={{ width: '20px' }}
                            type={'radio'}
                            name={'Pension'}
                            value={'Si'}
                            checked={data['Pension'] === 'Si' ? 'checked' : ''}
                            onChange={handleChange}
                            required
                            disabled={isView ? true : false}
                          />
                          Si
                        </label>
                      </td>
                      <td >
                        <label style={{ width: '25px', right: '1px' }}>
                          <input tabIndex="24"
                            style={{ width: '20px' }}
                            type={'radio'}
                            name={'Pension'}
                            value={'No'}
                            checked={data['Pension'] === 'No' ? 'checked' : ''}
                            onChange={handleChange}
                            required
                            disabled={isView ? true : false}
                          />
                          No
                        </label>
                      </td>
                      <td ><label>Aporte voluntario pensión o AFC:</label></td>
                      <td colSpan={2}>
                        <label style={{ width: '35px', right: '1px' }}>
                          <input
                            tabIndex="29"
                            style={{ width: '20px' }}
                            type={'radio'}
                            name={'AFC'}
                            value={'Si'}
                            checked={data['AFC'] === 'Si' ? 'checked' : ''}
                            onChange={handleChange}
                            required
                            disabled={isView ? true : false}
                          />
                          Si
                        </label>
                      </td>
                      <td colSpan={2}>
                        <label style={{ width: '35px', right: '1px' }}>
                          <input
                            tabIndex="30"
                            style={{ width: '20px' }}
                            type={'radio'}
                            name={'AFC'}
                            value={'No'}
                            checked={data['AFC'] === 'No' ? 'checked' : ''}
                            onChange={handleChange}
                            required
                            disabled={isView ? true : false}
                          />
                          No
                        </label>
                      </td>
                    </tr>
                    <tr >
                      <td colSpan={8}><label>NOTA: Para Beneficio Tributario anexar los certificados de las entidades correspondientes.</label></td>
                    </tr>
                  </tbody>
                </table>
              </Frame>

              {/* ------------------------------------------------------------------Cuarta  tabla---------------------------------------------------------------- */}


              <Frame isView={isView}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr colSpan={7}>
                      <th className="rms" colSpan={3} style={{ width: '50%', textAlign: 'center' }}>
                        <h2>DATOS DOTACIÓN</h2>
                      </th>
                      <th className="rms" style={{ width: '50%', textAlign: 'center' }} colSpan={4}>
                        <h2>RECURSOS DE TRABAJO.</h2>
                      </th>
                    </tr>
                    <tr>
                      <td ><label>Camisa talla:</label></td>
                      <td colSpan={2} style={{ width: '28%' }}>
                        <SelectOption type={"Sizes"} searchable={true} state={data} setState={setData} nameState={'Tshirt'} required disabled={isView ? true : false} />
                      </td>
                      <td ><label>Computador:</label></td>
                      <td> <input type={'checkbox'} name='Laptop' tabIndex="34" value={data.Laptop} onChange={handleChange} checked={data.Laptop ? 'checked' : ''} disabled={isView ? true : false}></input> </td>
                      <td ><label>Email y usuario:</label></td>
                      <td> <input type={'checkbox'} name='User' tabIndex="35" value={data.User} onChange={handleChange} checked={data.User !== "" ? 'checked' : ''} disabled={isView ? true : false}></input> </td>
                    </tr>
                    <tr>
                      <td ><label>Pantalón talla:</label></td>
                      <td colSpan={2} style={{ width: '28%' }}><input tabIndex="31" name='Pants' value={data.Pants} onChange={handleChange} required disabled={isView ? true : false} /></td>
                      <td ><label>Pantalla/Monitor:</label></td>
                      <td> <input type={'checkbox'} name='Screen' tabIndex="36" value={data.Screen} onChange={handleChange} checked={data.Screen ? 'checked' : ''} disabled={isView ? true : false}></input> </td>
                      <td ><label>Puesto de trabajo:</label></td>
                      <td> <input type={'checkbox'} name='WorkSite' tabIndex="37" value={data.WorkSite} onChange={handleChange} checked={data.WorkSite ? 'checked' : ''} disabled={isView ? true : false}></input> </td>
                    </tr>
                    <tr>
                      <td ><label>Botas No:</label></td>
                      <td colSpan={2} style={{ width: '28%' }}><input tabIndex="32" name='Boots' value={data.Boots} onChange={handleChange} required disabled={isView ? true : false} /></td>
                      <td ><label>Télefono Movil:</label></td>
                      <td> <input type={'checkbox'} name='Phone' tabIndex="38" value={data.Phone} onChange={handleChange} checked={data.Phone ? 'checked' : ''} disabled={isView ? true : false}></input> </td>
                      <td ><label>Disco Duro:</label></td>
                      <td> <input type={'checkbox'} name='Harddisk' tabIndex="39" value={data.Harddisk} onChange={handleChange} checked={data.Harddisk ? 'checked' : ''} disabled={isView ? true : false}></input> </td>
                    </tr>
                    <tr>
                      <td ><label>Botas de caucho No:</label></td>
                      <td colSpan={2} style={{ width: '28%' }}><input tabIndex="33" name='RubberBoots' value={data.RubberBoots} onChange={handleChange} required disabled={isView ? true : false} /></td>
                      <td ><label>Licencias:</label></td>
                      <td> <input type={'checkbox'} name='Licence' tabIndex="40" value={data.Licence} onChange={handleChange} checked={data.Licence !== '' ? 'checked' : ''} disabled={isView ? true : false}></input> </td>
                      <td ><label>Elementos de oficina:</label></td>
                      <td> <input type={'checkbox'} name='OfficeSupplies' tabIndex="41" value={data.OfficeSupplies} onChange={handleChange} checked={data.OfficeSupplies ? 'checked' : ''} disabled={isView ? true : false}></input> </td>
                    </tr>
                  </tbody>
                </table>
              </Frame>
              {/* ------------------------------------------------------------------Quinta  tabla---------------------------------------------------------------- */}


              <Frame isView={isView}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <th className="rms" colSpan={8} style={{ width: '100%' }}>
                        <h2 className="text-center">ACEPTACIÓN DE LOS DATOS DEL EMPLEADO Y AUTORIZACIÓN PAGO DE SALARIO </h2>
                      </th>
                    </tr>
                    <tr >
                      <td colSpan={8}><label>Declaro que la informacion suministrada es real y verídica. Autorizo a RMS S.A.S a consignar mi salario:</label></td>
                    </tr>
                    <tr>
                      <td colSpan={2} ><label>Banco:</label></td>
                      <td colSpan={2}>
                        <SelectOption type={"Bank"} searchable={true} state={data} setState={setData} nameState={'Bank'} required disabled={isView ? true : false} />


                      </td>
                      <th rowSpan={4} colSpan={4} style={{ width: '50%', maxHeight: '80px', alignSelf: "center", textAlign: "center" }} >

                        <Signer tabIndex="43" style={{ maxHeight: '80px', height: '80px', display: "block", margin: "auto" }}
                          enable={isSign && (sessionUser.IdEmployee_FK) === (firmaEmpleado.IdEmployee_FK)}
                          sign={firmaEmpleado}
                          setSign={setfirmaEmpleado}
                          doc={type}
                          user={sessionUser.IdEmployee_FK}
                          save={true}
                        />
                      </th>
                    </tr>
                    <tr>
                      <td colSpan={2}><label>Cuenta No:</label></td>
                      <td colSpan={2}><input tabIndex="44" name='CountNumber' value={data.CountNumber} onChange={handleChange} required disabled={isView ? true : false} /></td>
                    </tr>
                    <tr>
                      <td colSpan={2}><label>Ahorro:</label></td>
                      <td colSpan={2}>
                        <input
                          tabIndex="45"
                          style={{ width: '20px' }}
                          type={'radio'}
                          name={'AccountType'}
                          value={'SavingAccount'}
                          checked={data['AccountType'] === 'SavingAccount' ? 'checked' : ''}
                          onChange={handleChange}
                          required
                          disabled={isView ? true : false}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}><label>Corriente:</label></td>
                      <td colSpan={2}>
                        <input
                          tabIndex="46"
                          style={{ width: '20px' }}
                          type={'radio'}
                          name={'AccountType'}
                          value={'CurrentAccount'}
                          checked={data['AccountType'] === 'CurrentAccount' ? 'checked' : ''}
                          onChange={handleChange}
                          required
                          disabled={isView ? true : false}

                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} ><label>Fecha:</label></td>
                      <td colSpan={2}><input tabIndex="47" name='Date' type={'date'} value={data.Date} onChange={handleChange} required disabled={isView ? true : false} /></td>
                      <td colSpan={4} rowSpan={3} style={{ alignContent: "center", textAlign: 'center', }}><label>FIRMA DEL EMPLEADO:</label><br />{firmaEmpleado.NombreTrabajador + " " + firmaEmpleado.ApellidoTrabajador}</td>


                    </tr>
                    <tr >
                      <td colSpan={4}><label>NOTA: Anexar el certificado del banco con el número de cuenta.</label></td>
                    </tr>
                  </tbody>
                </table>
              </Frame>

              {/* ------------------------------------------------------------------Sexta tabla---------------------------------------------------------------- */}


              <Frame isView={isView}>
                <table style={{ width: '100%' }}>
                  <thead>

                    <tr>
                      <th className="rms" colSpan={6} style={{ width: '100%' }}>
                        <h2 className="text-center">VERIFICACIÓN Y APROBACIÓN </h2>
                      </th>
                    </tr>
                  </thead>
                  <tbody>

                    <tr >
                      <td colSpan={6} style={{ width: '100%' }}><label>Declaro que la informacion suministrada es real y verídica. Autorizo a RMS S.A.S a consignar mi salario:</label></td>
                    </tr>
                    <tr>

                      <td colSpan={2} style={{ width: '33%', height: '50px', textAlign: "center" }}>

                        <Signer
                          enable={isSign && (sessionUser.IdEmployee_FK) === `${firmaTalentoHumano.IdEmployee_FK}`}
                          sign={firmaTalentoHumano}
                          setSign={setfirmaTalentoHumano}
                          doc={type}
                          user={sessionUser.IdEmployee_FK}
                        /></td>
                      <td colSpan={2} style={{ width: '34%', alignSelf: "center", textAlign: "center" }}>

                        <Signer style={{ alignSelf: "center", textAlign: "center", display: "block" }}
                          enable={isSign && (sessionUser.IdEmployee_FK) === `${firmaJefe.IdEmployee_FK}`}
                          sign={firmaJefe}
                          setSign={setfirmaJefe}
                          doc={type}
                          user={sessionUser.IdEmployee_FK}
                        />
                      </td>
                      <td colSpan={2} style={{ width: '33%', alignSelf: "center", textAlign: "center" }}>
                        <Signer
                          enable={isSign && (sessionUser.IdEmployee_FK) === (firmaContabilidad.IdEmployee_FK)}
                          sign={firmaContabilidad}
                          setSign={setfirmaContabilidad}
                          doc={type}
                          user={sessionUser.IdEmployee_FK}
                        />
                      </td>

                    </tr>

                    <tr >
                      <td colSpan={2} >
                        {listaTH ? <select
                          tabIndex="48"
                          name="talentoHumano"
                          value={firmaTalentoHumano.IdEmployee_FK}
                          onChange={e => setfirmaTalentoHumano({
                            ApellidoTrabajador: e.target.selectedOptions[0].lang,
                            NombreTrabajador: e.target.selectedOptions[0].id,
                            IdEmployee_FK: e.target.value,
                            fecha: ""
                          })}
                          required
                          disabled={isView ? true : false}
                          placeholder="seleccione..."
                          title="seleccione..."
                        >
                          <option key={0} selected disabled></option>

                          {listaTH.map((item, i) => {
                            return (
                              <option key={i + 1} id={item.NombreTrabajador} lang={item.ApellidoTrabajador} value={item.IdEmployee_FK}>{item.NombreTrabajador + " " + item.ApellidoTrabajador} </option>
                            );
                          })}
                        </select> : <input
                          onChange={e => { }}
                          className="disable"
                          value={firmaTalentoHumano.NombreTrabajador + " " + firmaTalentoHumano.ApellidoTrabajador}
                          required
                          disabled
                        />}
                      </td>
                      <td colSpan={2}>
                        <input
                          onChange={e => { }}
                          className="disable"
                          value={firmaJefe.NombreTrabajador + " " + firmaJefe.ApellidoTrabajador}
                          required
                          disabled
                        />
                      </td>
                      <td colSpan={2}>
                        {listaContabilidad ? <select
                          name="contabilidad"
                          tabIndex="49"
                          value={firmaContabilidad.IdEmployee_FK}
                          onChange={e => setfirmaContabilidad({
                            ApellidoTrabajador: e.target.selectedOptions[0].lang,
                            NombreTrabajador: e.target.selectedOptions[0].id,
                            IdEmployee_FK: e.target.value,
                            fecha: ""
                          })}
                          required
                          disabled={isView ? true : false}
                          placeholder="seleccione..."
                          title="seleccione..."
                        >
                          <option key={0} selected disabled></option>

                          {listaContabilidad.map((item, i) => {
                            return (
                              <option key={i + 1} id={item.NombreTrabajador} lang={item.ApellidoTrabajador} value={item.IdEmployee_FK}>{item.NombreTrabajador + " " + item.ApellidoTrabajador} </option>
                            );
                          })}
                        </select> : <input
                          onChange={e => { }}
                          className="disable"
                          value={firmaContabilidad.NombreTrabajador + " " + firmaContabilidad.ApellidoTrabajador}
                          required
                          disabled
                        />}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} style={{ alignContent: "center", textAlign: 'center', fontWeight: 'bold' }}>TALENTO HUMANO</td>
                      <td colSpan={2} style={{ alignContent: "center", textAlign: 'center', fontWeight: 'bold' }}>JEFE INMEDIATO</td>
                      <td colSpan={2} style={{ alignContent: "center", textAlign: 'center', fontWeight: 'bold' }}>CONTABILIDAD / FINANCIERA</td>
                    </tr>

                  </tbody>
                </table>
              </Frame>
              <Row className="no-print">
                <Col></Col>
                <Col></Col>
                <Col></Col>
                <Col></Col>
                {(!isView || isSign) ? (
                  <>
                    <Col>
                      <Button
                        className="btn btn-danger"
                        onClick={() => {
                          clearStates()
                          hide(!show)
                        }}
                      >
                        Cancelar
                      </Button>
                    </Col>
                    {data.Status === "disponible" && createContract && <Col colSpan={2}>
                      <Button type="submit" className="btn btn-primary" >
                        Contratar
                      </Button>
                    </Col>}
                    <Col>
                      {(status === 'en creacion' || data.Status === 'editando' || isSign && data.Status !== "activo") && <Col colSpan={2}>
                        <Button type="submit" className="btn btn-success" >
                          Guadar
                        </Button>
                      </Col>}
                    </Col>
                  </>
                ) : (<> <Col>

                  <Col>
                    <Button
                      className="btn btn-info"
                      onClick={convertToPdf}
                    >
                      <i className="bi bi-printer"></i>
                    </Button>

                  </Col>
                </Col>
                  <Col>
                    <Button
                      className="btn btn-secundary"
                      onClick={() => {
                        clearStates()
                        hide(!show)
                      }}
                    >
                      Cerrar
                    </Button>
                  </Col></>
                )}
              </Row>

            </Form>
          </ModalContainer>
        </Overlay>
      )
      }
    </>
  )
}

export default GTHFOR086

const Frame = styled.div`
  width: 21cm;
  padding-bottom: 0px;
  margin-bottom: 3px;
  align-items: center;
  justify-content: left;
  border-width: 0.5px;
  border-color: #1a004a;
  border-style: solid;
  font-size: 9px;
  font-weight: 1000;
  label {
    padding-left: 5px;
    display: flex;
    font-size: 10px;
    font-weight: bold;
    text-align: 'center';
  }
  select {
    height:13px;
    text-align: center;
    width: 100%;
    background: ${props => (props.isView ? '#FFF' : '#cee1ed')};
    border-width: 0px;
  }
  SelectOption {
    text-align: center;
    width: 100%;
    border-bottom: 0px solid black;
    border-color: #1a004a;
    background: ${props => (props.isView ? '#FFF' : '#cee1ed')};
    padding-left: 5px;
    
  }
  input {
    text-align: center;
    width: 100%;
    border-bottom: 0px solid black;
    border-color: #1a004a;
    background: ${props => (props.isView ? '#FFF' : '#cee1ed')};
    padding-left: 0px;
    marging-left: 15px;
  }
  
  input.edit {
    background: #cee1ed;
  }
  input.disable {
    background: #fff;
  }
  h2 {
    font-size: 10px;
    font-weight: bold;
    text-align: 'center';
    marging-bottom: 2px !!important;
    padding:0px;
    /* vertical-align: center; */
  }
  h7 {
    font-size: 10px;
    font-weight: bold;
    text-align: 'center';
     vertical-align: center; 
  }
  th {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    text-align: left;
    vertical-align: center;
    padding-left: 5px;
    padding-bottom: 0px;
    marging-bottom: 0px;
    input {
      width: 100%;
    }
  }
  tr {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    diaplay: flex !important;
  }
  
  td {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    margin: 0 auto;
  }
          `

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgb(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
          `

const ModalContainer = styled.div`
  width: 21.89cm;
  max-height: 90vh;
  min-height: 90vh;
  background: #fff;
  position: relative;
  border-radius: 5px;
  overflow-x: hidden;
  overflow-y: scroll;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 10px;

  @media print {

    padding-top: 100px;
    width: 100%;
    max-height: 100%;
    min-height: 100%;

    /* Estilos para la versión impresa */
    overflow: hidden;
    /* Aquí ocultamos los componentes que no queremos imprimir */
    @page {
      size: legal; /* Puedes usar diferentes tamaños de papel como A4, Letter, etc. */
      padding-top:0px;
      orientation: portrait ; /* Cambiar a 'landscape' si necesitas orientación horizontal */
    }
     
      /* Ajustar el área de impresión */
    .no-print {
      display: none;
    }
} 
  `

const ModalHeader = styled.div`
width: 21cm;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
  padding-bottom: 1px;

  th {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
  }
  tr {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
  }
  table {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    text-align: center;
    vertical-align: center;
    font-size: 12px;
  }
  h3 {
    font-weight: 5000;
    font-size: 10px;
    color: #1766dc;
  }
          `

const CloseButtonModal = styled.div`
          position: absolute;
          top: 2px;
          right: 5px;
          width: 20px;
          height: 20px;
          border: none;
          background: none;
          cursor: pointer;
          transition: 0.3s ease all;
          border-radius: 5px;
          color: #1766dc;
          &:hover {
            background: #f2f2f2;
  }
          svg {
            width: 100%;
          height: 100%;
  }
          `
