import React, { useState } from "react";
// import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import ApiURL from "../../services/apirest.js";
import Image from "../../assets/img/Logo_w.png";

const Login = () => {
  // const port=4010
  const [state, setState] = useState({
    IdUser_PK: "",
    Password: "",
    UserName: "",
    UserLastName: "",
    userPermission: "",
    msj: "",
    TOKEN: "",
    status: "",
    isSignin: false,
    maxroll: 0,
  });
  //     constructor(props) {
  //         super(props);
  //         this.state = {
  //         IdUser_PK: '',
  //         Password: '',
  //         UserName: '',
  //         UserLastName: '',
  //         userPermission:'',
  //         msj: '',
  //         TOKEN: '',
  //         status: '',
  //         isSignin: false,
  //         maxroll: 0
  //     }
  // }
  const onHover = (e) => {
    e.target.style.background = "white";
    e.target.style.color = "black";
  };

  const onOut = (e) => {
    e.target.style.background = "black";
    e.target.style.color = "white";
  };

  const onChangeInput = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const Array = {
      IdUser_PK: state.IdUser_PK,
      Password: state.Password,
    };
    const res = await axios.post(ApiURL + "auth/signin", Array);
    const data = res.data;
  
    if (data.status === 200) {
      const newState = {
        msj: data.msj,
        TOKEN: data.data.TOKEN,
        status: data.status,
        IdUser_PK: data.data.IdUser_PK,
        UserName: data.data.UserName,
        UserLastName: data.data.UserLastName,
        userPermission: data.data.userPermission,
        avatar: data.data.avatar,
        isSignin: true,
        maxroll: data.data.maxRoll,
        sign: data.data.sign,
        Status: data.data.Status,
        port: data.data.port
      };
      setState(newState);
      localStorage.setItem("TOKEN", newState.TOKEN);
      localStorage.setItem("IdUser_PK", newState.IdUser_PK);
      localStorage.setItem("UserName", newState.UserName);
      localStorage.setItem("UserLastName", newState.UserLastName);
      localStorage.setItem("userPermission", newState.userPermission);
      localStorage.setItem("sign", newState.sign);
      localStorage.setItem("Status", newState.Status);
      localStorage.setItem("avatar", newState.avatar);
      localStorage.setItem("port", newState.port);
      localStorage.setItem(
        "initials",
        newState.UserName.substring(0, 1).toUpperCase() +
          newState.UserLastName.substring(0, 1).toUpperCase()
      );
      localStorage.setItem("maxRoll", newState.maxroll);
      window.location.href = "/"
      await window.location.reload();
    } else {
      setState({
        msj: data.msj,
        IdUser_PK: "",
        Password: "",
      });
      
      toast.error(data.msj);
    }
  };

  return (
    <div style={styles.main}>
      <div style={styles.container}>
        <center>
          <div style={styles.middle} className="d-md-flex d-sm-block">
            <div id="login" style={styles.login} className="border-end">
              <form onSubmit={onSubmit} style={styles.form}>
                <fieldset className="clearfix">
                  <div className="input-group py-2">
                    <span className="input-group-text bg-white" id="usertext">
                      <i className="fs-3 bi bi-person-fill"></i>
                    </span>
                    <input
                      aria-describedby="usertext"
                      className="form-control p-3"
                      type="text"
                      placeholder="Usuario"
                      onChange={onChangeInput}
                      autoComplete="usuario"
                      name="IdUser_PK"
                      value={state.IdUser_PK}
                      required
                    />
                  </div>

                  <div className="input-group py-2">
                    <span className="input-group-text bg-white" id="passtext">
                      <i className="fs-3 bi bi-lock-fill"></i>
                    </span>
                    <input
                      aria-describedby="passtext"
                      className="form-control p-3"
                      type="password"
                      placeholder="Contraseña"
                      autoComplete="password"
                      name="Password"
                      onChange={onChangeInput}
                      value={state.Password}
                      required
                    />
                  </div>

                  <div className="py-2 row align-items-center">
                    <span
                      style={{
                        width: "50%",
                        textAlign: "left",
                        display: "inline-block",
                      }}
                    >
                      {/* <Link className="text-white" to="/recoverypsw">
                        Recuperar Contraseña
                      </Link> */}
                    </span>
                    <span
                      style={{
                        width: "50%",
                        textAlign: "right",
                        display: "inline-block",
                      }}
                    >
                      <input
                        style={styles.input}
                        type="submit"
                        value="Ingresar"
                        onMouseOver={onHover}
                        onMouseLeave={onOut}
                      />
                    </span>
                  </div>
                </fieldset>
                <div className="clearfix"></div>
              </form>

              <div className="clearfix"></div>
            </div>
            <div className="logo d-none d-lg-block" style={styles.logo}>
              <img
                className="img_logo"
                src={Image}
                style={styles.img_logo}
                alt="Logo RMS"
              
              />
              <div className="clearfix"></div>
            </div>
          </div>
        </center>
      </div>
    </div>
  );
};

export default Login;

const styles = {
  main: {
    "--color-1": "#015fa8",
    "--color-2": "#004e89",
    background: `
      radial-gradient(
        ellipse at center,
        var(--color-1) 1%,
        var(--color-2) 100%
      )`,
    height: "100vh",
    width: "100%",
  },

  container: {
    left: "50%",
    position: "fixed",
    top: "50%",
    transform: `translate(-50%, -50%)`,
  },

  middle: {
    width: "600px",
  },

  logo: {
    color: "#fff",
    fontSize: "250px",
    lineHeight: "25px",
    display: "inline-block",
    width: "37%",
    margin:"5%"
  },

  login: {
    padding: "0px 22px",
    width: "59%",
  },

  form: {
    width: "250px",
  },

  input: {
    borderRadius: "3px",
    backgroundColor: "#000000",
    color: "#eee",
    fontWeight: "bold",
    textTransform: "uppercase",
    padding: "5px 10px",
    lineHeight: "1.5em",

    "&:hover": {
      backgroundColor: "#fff",
      color: "#000",
    },
  },

  inputs: {
    backgroundColor: "#fff",
    borderRadius: "0px 3px 3px 0px",
    color: "#000",
    marginBottom: "1em",
    padding: "0 16px",
    width: "200px",
    height: "50px",
    lineHeight: "1.5em",
  },

  img_logo: { 
    color: "#000",
    width: "240px",
    //height: "150px",
  },
};
