import { MITFOR191 } from '../../lib/formatos/MITFOR191'
import React, { useState, useEffect } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import EmployeeInfo from './newAssigment/EmployeeInfo';
import EqInfo from './newAssigment/EqInfo'
import { getFullData } from '../../lib/request';




export const NewAssigment = () => {
  const [IdFormat_PK, setIdFormat_PK] = useState('')
  const [Employee_select_ID, setEmployee_select_ID] = useState(0)
  const [Eq_select_ID, setEq_select_ID] = useState(0)
  const [restart, setRestart] = useState(false)
  const [data, setData] = useState('')
  const [linkAplication, setLinkAplication] = useState(false)
  const [linkEmployee, setLinkEmployee] = useState(false)
  const [disable, setDisable] = useState(true)
  //const disable = linkAplication && linkEmployee ? false : true
  const [show, hide] = useState(false)

  function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
}


  useEffect(() => {
    
    if ((Employee_select_ID.Status === 'activo'
    ) &&
      Eq_select_ID.Status === 'Disponible')
      setDisable(false)
    else setDisable(true)

  }, [Employee_select_ID, Eq_select_ID])

  const handleShowForm = async () => {
    setIdFormat_PK('en creacion')
    var temp = await localStorage.getItem('IdUser_PK')
    var res = await getFullData('employee', 'IdEmployee_FK', temp)
    
    temp = {
      EqDenominacion: Eq_select_ID.EqDenominacion,IdEquipo:Eq_select_ID.IdFormat_PK, EqMarca: Eq_select_ID.EqMarca, EqModelo: Eq_select_ID.EqModelo,
      EqSerial: Eq_select_ID.EqSerial, EqIdInterna: Eq_select_ID.EqIdInterna, EqEstado:Eq_select_ID.EqEstadoInst,
      EqObservaciones:Eq_select_ID.EqObservaciones,
      EqAccesorios: Eq_select_ID.EqAccesorios, EqCalibraciones: JSON.parse(Eq_select_ID.EqCalibraciones),
      Custodio: Employee_select_ID, 	EqTipo: Eq_select_ID.EqTipo

    }
    setData(temp)
    
    setRestart(false)
    hide(!show)
  }

  return (
    <div className="d-flex gap-3 justify-content-center align-items-start">
      <EqInfo
        setEq_select_ID={setEq_select_ID}
        Eq_select_ID={Eq_select_ID}
        setLinkAplication={setLinkAplication}
      />
      {show && (
        <MITFOR191
          show={show}
          hide={hide}
          data={data}
          isView={false}
          setData={setData}
          IdFormat_PK="en creacion"
          setRestart={setRestart}
          restart={restart}
        />
      )}
      <Col><Row style={{ minWidth: "100px" }} >
        {!disable && <Button className="h-25 align-self-center" onClick={handleShowForm} >
          Asignar
        </Button>}</Row></Col>
      <EmployeeInfo className="d-flex gap-3 justify-content-center align-items-start"
        linkEmployee={linkEmployee}
        setEmployee_select_ID={setEmployee_select_ID}
        Employee_select_ID={Employee_select_ID}
        IdFormat_PK={IdFormat_PK}
        setLinkEmployee={setLinkEmployee}
      />
    </div>
  )
}
