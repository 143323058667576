import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { CreateFormat, getFormatsById, updateFormat,newWorkflow, updateWorkflow } from './request'
import { getUniqueList, getFullData } from '../request'
import { Button, Col, Form, Row } from 'react-bootstrap'
import Moment from 'moment';
import { toast } from 'react-toastify'
import { EqTable } from '../../logistic/EqTable'
import Signer from '../signer'
import { Schedule } from '../Schedule'
import { newEvent, fillCalendar, updateEvent } from '../Schedule.request'



export const MITFOR191 = ({ show, hide, restart, setRestart, isView, isAccept, isMIT, setData, data, IdFormat_PK, workflow, sessionUser }) => {
  //-------------------------Info Formato   
  const formato = 'MIT-FOR-191'
  const revision = '4'
  const fechaRev = '25/02/2018'
  const nombreFormato = "ENTREGA Y RECIBIDO DE EQUIPOS DE METROLOGIA"
  const typef = 'MITFOR191'


  //-------------------------Registros 
  const [Status, setStatus] = useState('')
  const [currentUser, setCurrentUser] = useState('')
  const [IdUser_FK, setIdUser_FK] = useState('')
  const [entregaMIT, setEntregaMIT] = useState('')
  const [recibeCustodio, setRecibeCustodio] = useState('')
  const [recibeMIT, setRecibeMit] = useState('')
  const [firmaEntregaMIT, setFirmaEntregaMIT] = useState('')
  const [firmaRecibeCustodio, setFirmaRecibeCustodio] = useState('')
  const [firmaRecibeMIT, setFirmaRecibeMit] = useState('')
  const [inicioServicio, setInicioServicio] = useState('')
  const [finServicio, setFinServicio] = useState('')
  const [showCalendar, hideCalendar] = useState(false)
  const [eventText, setEventText] = useState('')
  const [date, setDate] = useState('')
  const [isReceive, setIsReceive] = useState(false)
  const [calibraciones, setCalibraciones] = useState('')
  const [accesorios, setAccesorios] = useState('')
  const [fechaCal, setFechaCal] = useState('')
  const [CeCo, setCeCo] = useState('')
  const [Localizacion, setlocalizacion] = useState('')
  const [events, setEvents] = useState([]) // =  fillCalendar(null, null,'MITFOR207_' + data.IdEquipo ) //

  useEffect(() => {
    if (!restart) {
      setStatus('solicitado')
      setRestart(!restart)
      if (show)
        getInfo()
    }
  }, [restart])

  const handleChange = async (e) => {

    const { name, type, accessKey, id } = e.target
    const value = type === 'checkbox' ? e.target.checked : e.target.value

    if (type === "date" && id === 'service') {
      if (CeCo === '' || CeCo === undefined) {
        toast.error('llenar el campo de Centro de costo / Contrato')
        return
      }
      if (Localizacion === '' || Localizacion === undefined) {
        toast.error('llenar el campo de Localizacion')
        return
      }
      setEventText('CeCo/Contrato/Orden: ' + CeCo + ' Localizacon: ' + Localizacion)
      hideCalendar(true)
    }
    if (id === 'tb') {
      var temp = accesorios
      temp[accessKey][name] = value
      setAccesorios(temp)
    }
    data[name] = value
  }
  const test = () => {

  }

  const getInfo = async () => {

    const formatDate = Moment().format('YYYY-MM-DD')
    setDate(formatDate)
    var temp = await localStorage.getItem('IdUser_PK')
    setIdUser_FK(temp)
    var res = await getFullData('employee', 'IdEmployee_FK', temp)
    setCurrentUser(res.data.data[0])

    setEvents(await fillCalendar(null, null, 'MITFOR207_' + data.IdEquipo))



    if (IdFormat_PK === 'en creacion') {

      if (data.EqCalibraciones !== null) {
        setFechaCal(data.EqCalibraciones[data.EqCalibraciones.length - 1]['Fecha'])
      }
      temp = {
        NombreTrabajador: res.data.data[0].NombreTrabajador,
        ApellidoTrabajador: res.data.data[0].ApellidoTrabajador,
        cargo: res.data.data[0].cargo,
        areaTrabajo: res.data.data[0].areaTrabajo,
        IdEmployee_FK: res.data.data[0].IdEmployee_FK,
        fecha: date
      }
      setEntregaMIT(temp)
      temp = {
        NombreTrabajador: data.Custodio.NombreTrabajador,
        ApellidoTrabajador: data.Custodio.ApellidoTrabajador,
        cargo: data.Custodio.cargo,
        areaTrabajo: data.Custodio.areaTrabajo,
        IdEmployee_FK: data.Custodio.IdEmployee_FK, fecha: ''
      }
      setRecibeCustodio(temp)
      temp = {
        NombreTrabajador: '',
        ApellidoTrabajador: '',
        cargo: '',
        areaTrabajo: '',
        IdEmployee_FK: '', fecha: ''
      }
      setRecibeMit(temp)
      let accesoriosEq = await JSON.parse(data.EqAccesorios)
      temp = [{ Descripcion: data.EqDenominacion, Serial: data.EqSerial, Cantidad: '1', Estado: data.EqEstado, observacion: data.EqObservaciones, tipo: 'Equipo' }]
      accesoriosEq?.map((item, i) => {
        item['tipo'] = 'Accesorio'
        temp.push(item)
        return
      })
      setAccesorios(temp)
    }
    else {

      setlocalizacion(data['EqLocalizacion'])
      setCeCo(data['Ceco'])
      setInicioServicio(data['inicioServicio'])
      setFinServicio(data['finServicio'])
      data['EqAccesorios'] = JSON.parse(data['EqAccesorios'])
      data['EqCalibraciones'] = JSON.parse(data['EqCalibraciones'])
      data['Custodio'] = JSON.parse(data['Custodio'])
      setFechaCal(data['fechaCalibracion'])
      setAccesorios(data['EqAccesorios'])
      setEntregaMIT(data['firmas']['entregaMIT'])
      setRecibeCustodio(data['firmas']['recibeCustodio'])
      setFirmaEntregaMIT(data['firmas']['entregaMIT']['firma'])
      setFirmaRecibeCustodio(data['firmas']['recibeCustodio']['firma'])
    }


    return true
  }

  const clearStates = () => {
    setCurrentUser('')
    setDate('')
    setAccesorios('')
    setCalibraciones('')
  }


  const onSubmit = async e => {
    e.preventDefault()
    const resWorkflow=[]
    if ((IdFormat_PK === 'en creacion' && !firmaEntregaMIT)) {
      toast.error('por favor firmar el documento')
      return
    } else {
      setStatus('solicitado')
      entregaMIT['firma'] = firmaEntregaMIT
      entregaMIT['fecha'] = date
      data['Status'] = Status


      workflow = {
        TaskDescription: "Asignación de equipo " + data.EqDenominacion,
        Status: "Aceptación",
        Format: typef,
        dateCreation: date,
        documents: "Asignación Equipos",
        IdEmployee_created: sessionUser.IdEmployee_FK,
        IdEmployee_FK: recibeCustodio.IdEmployee_FK,
        IdFormat_FK: ""
      }
    }
    var temp = ''
    var result = ''
    var resEvent = ''
    data['EqCalibraciones'] = JSON.stringify(data['EqCalibraciones'])
    data['Custodio'] = JSON.stringify(data['Custodio'])
    data['EqAccesorios'] = JSON.stringify(accesorios)
    data['inicioServicio'] = inicioServicio
    data['EqLocalizacion'] = Localizacion
    data['Ceco'] = CeCo
    data['finServicio'] = finServicio
    data['fechaCalibracion'] = fechaCal
    data['firmas'] = JSON.stringify({ entregaMIT, recibeCustodio, recibeMIT })

    if (IdFormat_PK === 'en creacion') {
      result = await CreateFormat(data, typef)
      workflow["IdFormat_FK"] = result.data.data.insertId
      resWorkflow = await newWorkflow(workflow)
      resEvent = await newEvent('CeCo/Contrato/Orden: ' + data.Ceco + ' Localizacon: ' + data.EqLocalizacion,
        inicioServicio, finServicio, recibeCustodio.IdEmployee_FK, typef, result.data.data.insertId, 'MITFOR207_' + data.IdEquipo, Status)
      checkStatus(result, resEvent, resWorkflow)

    } else {
      if(isAccept){

     } 


      result = await updateFormat(data, typef)
      resEvent = await updateEvent(data['IdEvent_FK'], 'CeCo/Contrato/Orden: ' + data.Ceco + ' Localizacon: ' + data.EqLocalizacion,
        inicioServicio, finServicio, recibeCustodio.IdEmployee_FK, typef, result.data.data.insertId, 'MITFOR207_' + data.IdEquipo, Status)

      checkStatus(result, resEvent, resWorkflow)

    }

    clearStates()
    hide(!show)
    setRestart(!restart)
  }

  const checkStatus = (result, resEvent, resWorkflow) => {
    if (result.data.status === 200 && resEvent.data.status === 200 && resWorkflow.data.status === 200) {
      toast.success(result.data.msj);
    } else {
      toast.error(result.data.msj);
      toast.error(resEvent.data.msj);
      toast.error(resWorkflow.data.msj);

    }

  }



  return (
    <>{showCalendar && (
      <Schedule
        show={showCalendar}
        hide={hideCalendar}
        setDateStart={setInicioServicio}
        setDateEnd={setFinServicio}
        setEvents={setEvents}
        text={eventText}
        IdEq={'MITFOR207_' + data.IdEquipo}
        events={events}
      />)}
      {show && (
        <Overlay style={{ zIndex: 10 }}>
          <ModalContainer>
            <ModalHeader >
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <th style={{ width: '20%', verticalAlign: 'bottom' }} rowSpan={3}>
                      NIT 830.507.705-2
                    </th>
                    <th style={{ width: '55%', height: '38px' }}>RELIABILITY MAINTENANCE SERVICES S.A.S</th>
                    <th style={{ width: '25%' }}>{formato}</th>
                  </tr>
                  <tr>
                    <th rowSpan={2}>{nombreFormato}</th>
                    <th style={{ fontSize: '10px' }}>Revisión: {revision}</th>
                  </tr>
                  <tr>
                    <th style={{ fontSize: '10px' }}>Fecha de revisión: {fechaRev}</th>
                  </tr>
                </tbody>
              </table>
            </ModalHeader>
            <CloseButtonModal onClick={() => hide(!show)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-x-square"
                viewBox="0 0 16 16"
              >
                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </CloseButtonModal>
            <table>
              <tbody>
                <tr>
                  <th style={{ width: '35%', textAlign: 'right' }}></th>
                  <th style={{ width: '15%', textAlign: 'right' }}>
                    <label>Asignación No.</label>
                  </th>
                  <td style={{ width: '25%', background: '#FFF' }}>
                    <label>{data.IdFormat_PK}</label>
                  </td>
                </tr>
              </tbody>
            </table>
            <Form onSubmit={onSubmit}>
              <Frame isView={isView}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr style={{ height: '35px' }}>
                      <td style={{ width: '5%' }}><label>EQUIPO:</label></td>
                      <td style={{ width: '45%', background: '#fff' }} colSpan={3}><input style={{ background: '#fff' }} name='EqDenominacion' value={data.EqDenominacion} onChange={handleChange} disabled /></td>
                      <td style={{ width: '25%' }} colSpan={2}><label>CENTRO DE COSTO Y/O CONTRATO:</label></td>
                      <td style={{ width: '25%' }} ><input value={CeCo} onChange={e => setCeCo(e.target.value)} required disabled={isView ? true : false} /></td>
                    </tr>
                    <tr style={{ height: '35px' }}>
                      <td ><label>MARCA:</label></td>
                      <td ><input style={{ background: '#fff' }} name='EqMarca' value={data.EqMarca} onChange={handleChange} disabled /></td>
                      <td style={{ width: '5%' }} ><label>MODELO:</label></td>
                      <td ><input style={{ background: '#fff' }} name='EqModelo' value={data.EqModelo} onChange={handleChange} disabled /></td>
                      <td colSpan={2} ><label>LOCALIZACIÖN:</label></td>
                      <td ><input name='EqLocalizacion' value={Localizacion} onChange={e => setlocalizacion(e.target.value)} required disabled={isView ? true : false} /></td>
                    </tr>
                    <tr >
                      <td rowSpan={2} ><label>SERIAL:</label></td>
                      <td rowSpan={2}  ><input style={{ background: '#fff' }} name='EqSerial' value={data.EqSerial} onChange={handleChange} disabled /></td>
                      <td rowSpan={2}  ><label>TAG:</label></td>
                      <td rowSpan={2} ><input style={{ background: '#fff' }} name='EqIdInterna' value={data.EqIdInterna} onChange={handleChange} disabled /></td>
                      <td rowSpan={2} style={{ width: '20%' }} ><label>FECHA DE ALQUILER Y/O TRABAJO:</label></td>
                      <td >inicio</td><td ><input id='service' data-provide="datepicker" type={'date'} name='inicioServicio' value={inicioServicio} onFocus={handleChange} readOnly onChange={test()}
                        required disabled={isView ? true : false} /></td></tr>
                    <tr>
                      <td >fin</td><td ><input id='service' data-provide="datepicker" type={'date'} name='finServicio' value={finServicio} onFocus={handleChange} readOnly onChange={test()}
                        required disabled={isView ? true : false} /></td>
                    </tr>
                    <tr style={{ height: '35px' }}>
                      <td colSpan={3}><label>Anexo Certificado de Calibración:</label></td>
                      <td >
                        <td ><input list="siono" name='EqCertCalibracion' value={data.EqCertCalibracion} onChange={handleChange} required disabled={isView ? true : false} /></td>

                      </td>

                      <td colSpan={2} ><label>FECHA ULTIMA CALIBRACIÓN:</label></td>
                      <td ><input name='fechaCal' data-provide="datepicker" type={'date'} value={fechaCal} onChange={e => setFechaCal(e.target.value)} required disabled={isView ? true : false} /></td>
                    </tr>

                  </tbody>
                </table>
              </Frame>

              <Frame isView={isView} style={{ border: '0px solid black' }}>
                <EqTable setData={setAccesorios} data={accesorios} tableType={'Prestamo'} isView={isView} isReceive={isReceive}></EqTable>

              </Frame>
              <Frame isView={isView} style={{ border: '0px solid black' }}>
                <table >
                  <tbody>
                    <tr>
                      <th style={{ width: '150px' }}><label>OBSERVACIONES:</label></th>
                      <th ><textarea style={{ width: '100%' }} name='Observaciones' value={data.Observaciones} onChange={handleChange} required disabled={isView ? true : false} /></th>
                    </tr>
                  </tbody>
                </table>

              </Frame>
              <Frame isView={isView} style={{ border: '0px solid black' }}>
                <table >

                  <thead style={{ textAlign: 'center' }}>
                    <tr>
                      <th style={{ width: '25%' }}  ></th>
                      <th style={{ width: '30%' }}>NOMBRE</th>
                      <th style={{ width: '25%' }} >FIRMA</th>
                      <th style={{ width: '15%' }} >FECHA</th>

                    </tr>

                  </thead>
                  <tbody>
                    <tr style={{ height: '60px' }}>
                      <th ><label>Entrega por el Area MIT:</label></th>
                      <th >{entregaMIT.NombreTrabajador + ' ' + entregaMIT.ApellidoTrabajador}</th>
                      <th style={{ alignItems: 'center' }} >
                        <Signer
                          enable={IdFormat_PK === 'en creacion' ? true : false}
                          sign={firmaEntregaMIT}
                          setSign={setFirmaEntregaMIT}
                          doc={typef}
                          user={IdUser_FK}
                        /></th>
                      <th >{IdFormat_PK === 'en creacion' ? date : entregaMIT.fecha}</th>
                    </tr>
                    <tr style={{ height: '60px' }}>
                      <th ><label>Recibe Custodio:</label></th>
                      <th >{recibeCustodio.NombreTrabajador + ' ' + recibeCustodio.ApellidoTrabajador}</th>
                      <th style={{ alignItems: 'center' }} >
                        <Signer
                          enable={data.Status === 'solicitado' ? true : false}
                          sign={firmaRecibeCustodio}
                          setSign={setFirmaEntregaMIT}
                          doc={typef}
                          user={IdUser_FK}
                        /></th>
                      <th >{data.Status === 'solicitado' ? date : entregaMIT.fecha}</th>
                    </tr>
                    <tr style={{ height: '60px' }}>
                      <th ><label>Recibe Custodio:</label></th>
                      <th >{recibeMIT.NombreTrabajador === undefined ? '' : recibeMIT.NombreTrabajador + ' ' + recibeMIT.ApellidoTrabajador}</th>
                      <th style={{ alignItems: 'center' }} >
                        <Signer
                          enable={data.Status === 'prestamo' ? true : false}
                          sign={firmaRecibeMIT}
                          setSign={setFirmaRecibeMit}
                          doc={typef}
                          user={IdUser_FK}
                        /></th>
                      <th >{data.Status === 'prestamo' ? recibeMIT.fecha : ''}</th>
                    </tr>
                  </tbody>
                </table>

              </Frame>
              <Row>
                <Col></Col>
                <Col></Col>
                <Col></Col>
                <Col></Col>
                {!isView ? (
                  <>
                    <Col>
                      <Button
                        className="btn btn-danger"
                        onClick={() => {
                          clearStates()
                          hide(!show)
                        }}
                      >
                        Cancelar
                      </Button>
                    </Col>
                    <Col>
                      <Button type="submit" className="btn btn-success">
                        Guardar
                      </Button>
                    </Col>
                  </>
                ) : (
                  <Col>
                    <Button
                      className="btn btn-secundary"
                      onClick={() => {
                        clearStates()
                        hide(!show)
                      }}
                    >
                      Cerrar
                    </Button>
                  </Col>
                )}
              </Row>
              <datalist id="siono">
                <option value="SI"></option>
                <option value="No" />
              </datalist>
              <datalist id="estado">
                <option value="BE">Buen Estado</option>
                <option value="ME">Mal Estado</option>
              </datalist>
            </Form>
          </ModalContainer>
        </Overlay>
      )}
    </>
  )
}

export default MITFOR191

const Frame = styled.div`
  width: 100%;
  padding-bottom: 0px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: left;
  border-width: 2px;
  border-color: #1a004a;
  border-style: solid;
  font-size: 12px;
  font-weight: 100;
  .photo{
    background:  #ebf1de
  }
  .rms{
    background:  #44bb7a
  }
  label {
    padding-left: 5px;
    display: flex;
    font-size: 12px;
    font-weight: bold;
    text-align: 'center'
  }
  input {
    text-align: center;
    width: 100%;
    border-bottom: 1px solid black;
    border-color: #1a004a;
    background: ${props => (props.isView ? '#FFF' : '#cee1ed')};

    padding-left: 5px;
    margin-top: 5px;
  }
  textarea {
    text-align: justify;
    width: 100%;
    border: 0px solid black;
    border-color: #1a004a;
    background: ${props => (props.isView ? '#FFF' : '#cee1ed')};

    padding-left: 5px;
    margin-top: 5px;
  }

  input.edit {
    background: #cee1ed;
  }
  input.disable {
    background: #fff;
  }
  h2 {
    font-size: 14px;
    font-weight: bold;
    text-align: 'center'

  }
  thead{
    th {
      text-align: center;
    }

  }
 
  th {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    text-align: left;
    vertical-align: center;
    padding-left: 5px;

    input {
      width: 100%;
    }
  }
  tr {
    
    border-width: 0px;
    border-color: #1a004a;
    border-style: solid;
  }
  table{
    width: 100%
  }
`

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgb(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalContainer = styled.div`
  width: 1000px;
  height: 80vh;
  min-height: 500px;
  background: #fff;
  position: relative;
  border-radius: 5px;
  overflow: scroll;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
`

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
  padding-bottom: 10px;

  th {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
  }
  tr {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
  }
  table {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    text-align: center;
    vertical-align: center;
    font-size: 14px;
  }
  h3 {
    font-weight: 500;
    font-size: 16px;
    color: #1766dc;
  }
`

const CloseButtonModal = styled.div`
  position: absolute;
  top: 2px;
  right: 5px;
  width: 20px;
  height: 20px;
  border: none;
  background: none;
  cursor: pointer;
  transition: 0.3s ease all;
  border-radius: 5px;
  color: #1766dc;
  &:hover {
    background: #f2f2f2;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`
