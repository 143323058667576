export const Cities = [
    {"id_city":"1097","city":"Ábrego","iso_country":"CO","state_id":"54","state":"NSA"},
    {"id_city":"1","city":"Abriaquí","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"2","city":"Acacías","iso_country":"CO","state_id":"50","state":"MET"},
    {"id_city":"3","city":"Acandí","iso_country":"CO","state_id":"27","state":"CHO"},
    {"id_city":"4","city":"Acevedo","iso_country":"CO","state_id":"41","state":"HUI"},
    {"id_city":"5","city":"Achí","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"6","city":"Agrado","iso_country":"CO","state_id":"41","state":"HUI"},
    {"id_city":"7","city":"Agua de Dios","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"8","city":"Aguachica","iso_country":"CO","state_id":"20","state":"CES"},
    {"id_city":"9","city":"Aguada","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"10","city":"Aguadas","iso_country":"CO","state_id":"17","state":"CAL"},
    {"id_city":"11","city":"Aguazul","iso_country":"CO","state_id":"85","state":"CAS"},
    {"id_city":"12","city":"Agustín Codazzi","iso_country":"CO","state_id":"20","state":"CES"},
    {"id_city":"13","city":"Aipe","iso_country":"CO","state_id":"41","state":"HUI"},
    {"id_city":"17","city":"Albán","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"18","city":"Albán(San José)","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"14","city":"Albania","iso_country":"CO","state_id":"18","state":"CAQ"},
    {"id_city":"15","city":"Albania","iso_country":"CO","state_id":"44","state":"LAG"},
    {"id_city":"16","city":"Albania","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"19","city":"Alcalá","iso_country":"CO","state_id":"76","state":"VAC"},
    {"id_city":"20","city":"Alejandria","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"21","city":"Algarrobo","iso_country":"CO","state_id":"47","state":"MAG"},
    {"id_city":"22","city":"Algeciras","iso_country":"CO","state_id":"41","state":"HUI"},
    {"id_city":"23","city":"Almaguer","iso_country":"CO","state_id":"19","state":"CAU"},
    {"id_city":"24","city":"Almeida","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"25","city":"Alpujarra","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"26","city":"Altamira","iso_country":"CO","state_id":"41","state":"HUI"},
    {"id_city":"27","city":"Alto Baudó ","iso_country":"CO","state_id":"27","state":"CHO"},
    {"id_city":"28","city":"Altos del Rosario","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"29","city":"Alvarado","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"30","city":"Amagá","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"31","city":"Amalfi","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"32","city":"Ambalema","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"33","city":"Anapoima","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"34","city":"Ancuya","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"35","city":"Andalucía","iso_country":"CO","state_id":"76","state":"VAC"},
    {"id_city":"36","city":"Andes","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"37","city":"Angelópolis","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"38","city":"Angostura","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"39","city":"Anolaima","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"40","city":"Anorí","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"41","city":"Anserma","iso_country":"CO","state_id":"17","state":"CAL"},
    {"id_city":"42","city":"Ansermanuevo","iso_country":"CO","state_id":"76","state":"VAC"},
    {"id_city":"44","city":"Anzá","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"43","city":"Anzoátegui","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"45","city":"Apartadó","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"47","city":"Apía","iso_country":"CO","state_id":"66","state":"RIS"},
    {"id_city":"46","city":"Apulo","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"48","city":"Aquitania","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"49","city":"Aracataca","iso_country":"CO","state_id":"47","state":"MAG"},
    {"id_city":"50","city":"Aranzazu","iso_country":"CO","state_id":"17","state":"CAL"},
    {"id_city":"51","city":"Aratoca","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"52","city":"Arauca","iso_country":"CO","state_id":"81","state":"ARA"},
    {"id_city":"53","city":"Arauquita","iso_country":"CO","state_id":"81","state":"ARA"},
    {"id_city":"54","city":"Arbeláez","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"55","city":"Arboleda","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"56","city":"Arboledas","iso_country":"CO","state_id":"54","state":"NSA"},
    {"id_city":"57","city":"Arboletes","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"58","city":"Arcabuco","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"59","city":"Arenal","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"60","city":"Argelia","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"61","city":"Argelia","iso_country":"CO","state_id":"19","state":"CAU"},
    {"id_city":"62","city":"Argelia","iso_country":"CO","state_id":"76","state":"VAC"},
    {"id_city":"63","city":"Ariguaní(El Difícil)","iso_country":"CO","state_id":"47","state":"MAG"},
    {"id_city":"64","city":"Arjona","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"65","city":"Armenia","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"66","city":"Armenia ","iso_country":"CO","state_id":"63","state":"QUI"},
    {"id_city":"67","city":"Armero(Guayabal)","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"68","city":"Arroyohondo","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"69","city":"Astrea","iso_country":"CO","state_id":"20","state":"CES"},
    {"id_city":"70","city":"Ataco","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"71","city":"Atrato (Yuto)","iso_country":"CO","state_id":"27","state":"CHO"},
    {"id_city":"72","city":"Ayapel","iso_country":"CO","state_id":"23","state":"COR"},
    {"id_city":"73","city":"Bagadó","iso_country":"CO","state_id":"27","state":"CHO"},
    {"id_city":"74","city":"Bahía Solano (Mútis)","iso_country":"CO","state_id":"27","state":"CHO"},
    {"id_city":"75","city":"Bajo Baudó (Pizarro)","iso_country":"CO","state_id":"27","state":"CHO"},
    {"id_city":"76","city":"Balboa","iso_country":"CO","state_id":"19","state":"CAU"},
    {"id_city":"77","city":"Balboa","iso_country":"CO","state_id":"66","state":"RIS"},
    {"id_city":"78","city":"Baranoa","iso_country":"CO","state_id":"8","state":"ATL"},
    {"id_city":"79","city":"Baraya","iso_country":"CO","state_id":"41","state":"HUI"},
    {"id_city":"80","city":"Barbacoas","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"81","city":"Barbosa","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"82","city":"Barbosa","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"83","city":"Barichara","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"84","city":"Barranca de Upía","iso_country":"CO","state_id":"50","state":"MET"},
    {"id_city":"85","city":"Barrancabermeja","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"86","city":"Barrancas","iso_country":"CO","state_id":"44","state":"LAG"},
    {"id_city":"87","city":"Barranco de Loba","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"88","city":"Barranquilla","iso_country":"CO","state_id":"8","state":"ATL"},
    {"id_city":"89","city":"Becerríl","iso_country":"CO","state_id":"20","state":"CES"},
    {"id_city":"90","city":"Belalcázar","iso_country":"CO","state_id":"17","state":"CAL"},
    {"id_city":"94","city":"Belén","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"95","city":"Belén","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"96","city":"Belén de Bajirá","iso_country":"CO","state_id":"27","state":"CHO"},
    {"id_city":"98","city":"Belén de los Andaquíes","iso_country":"CO","state_id":"18","state":"CAQ"},
    {"id_city":"97","city":"Belén de Umbría","iso_country":"CO","state_id":"66","state":"RIS"},
    {"id_city":"91","city":"Bello","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"92","city":"Belmira","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"93","city":"Beltrán","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"99","city":"Berbeo","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"100","city":"Betania","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"101","city":"Beteitiva","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"102","city":"Betulia","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"103","city":"Betulia","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"104","city":"Bituima","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"105","city":"Boavita","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"106","city":"Bochalema","iso_country":"CO","state_id":"54","state":"NSA"},
    {"id_city":"107","city":"Bogotá D.C.","iso_country":"CO","state_id":"11","state":"BTA"},
    {"id_city":"108","city":"Bojacá","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"109","city":"Bojayá (Bellavista)","iso_country":"CO","state_id":"27","state":"CHO"},
    {"id_city":"110","city":"Bolívar","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"111","city":"Bolívar","iso_country":"CO","state_id":"19","state":"CAU"},
    {"id_city":"112","city":"Bolívar","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"113","city":"Bolívar","iso_country":"CO","state_id":"76","state":"VAC"},
    {"id_city":"114","city":"Bosconia","iso_country":"CO","state_id":"20","state":"CES"},
    {"id_city":"115","city":"Boyacá","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"116","city":"Briceño","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"117","city":"Briceño","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"118","city":"Bucaramanga","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"119","city":"Bucarasica","iso_country":"CO","state_id":"54","state":"NSA"},
    {"id_city":"120","city":"Buenaventura","iso_country":"CO","state_id":"76","state":"VAC"},
    {"id_city":"121","city":"Buenavista","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"122","city":"Buenavista","iso_country":"CO","state_id":"23","state":"COR"},
    {"id_city":"123","city":"Buenavista","iso_country":"CO","state_id":"63","state":"QUI"},
    {"id_city":"124","city":"Buenavista","iso_country":"CO","state_id":"70","state":"SUC"},
    {"id_city":"125","city":"Buenos Aires","iso_country":"CO","state_id":"19","state":"CAU"},
    {"id_city":"126","city":"Buesaco","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"127","city":"Buga","iso_country":"CO","state_id":"76","state":"VAC"},
    {"id_city":"128","city":"Bugalagrande","iso_country":"CO","state_id":"76","state":"VAC"},
    {"id_city":"129","city":"Burítica","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"130","city":"Busbanza","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"131","city":"Cabrera","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"132","city":"Cabrera","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"133","city":"Cabuyaro","iso_country":"CO","state_id":"50","state":"MET"},
    {"id_city":"267","city":"Cáceres","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"134","city":"Cachipay","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"268","city":"Cáchira","iso_country":"CO","state_id":"54","state":"NSA"},
    {"id_city":"269","city":"Cácota","iso_country":"CO","state_id":"54","state":"NSA"},
    {"id_city":"135","city":"Caicedo","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"136","city":"Caicedonia","iso_country":"CO","state_id":"76","state":"VAC"},
    {"id_city":"137","city":"Caimito","iso_country":"CO","state_id":"70","state":"SUC"},
    {"id_city":"138","city":"Cajamarca","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"139","city":"Cajibío","iso_country":"CO","state_id":"19","state":"CAU"},
    {"id_city":"140","city":"Cajicá","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"141","city":"Calamar","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"142","city":"Calamar","iso_country":"CO","state_id":"95","state":"GUV"},
    {"id_city":"143","city":"Calarcá","iso_country":"CO","state_id":"63","state":"QUI"},
    {"id_city":"144","city":"Caldas","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"145","city":"Caldas","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"146","city":"Caldono","iso_country":"CO","state_id":"19","state":"CAU"},
    {"id_city":"150","city":"Calí","iso_country":"CO","state_id":"76","state":"VAC"},
    {"id_city":"147","city":"California","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"148","city":"Calima (Darién)","iso_country":"CO","state_id":"76","state":"VAC"},
    {"id_city":"149","city":"Caloto","iso_country":"CO","state_id":"19","state":"CAU"},
    {"id_city":"151","city":"Campamento","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"152","city":"Campo de la Cruz","iso_country":"CO","state_id":"8","state":"ATL"},
    {"id_city":"153","city":"Campoalegre","iso_country":"CO","state_id":"41","state":"HUI"},
    {"id_city":"154","city":"Campohermoso","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"155","city":"Canalete","iso_country":"CO","state_id":"23","state":"COR"},
    {"id_city":"156","city":"Candelaria","iso_country":"CO","state_id":"8","state":"ATL"},
    {"id_city":"157","city":"Candelaria","iso_country":"CO","state_id":"76","state":"VAC"},
    {"id_city":"158","city":"Cantagallo","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"159","city":"Cantón de San Pablo","iso_country":"CO","state_id":"27","state":"CHO"},
    {"id_city":"178","city":"Cañasgordas","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"160","city":"Caparrapí","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"161","city":"Capitanejo","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"270","city":"Cáqueza","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"162","city":"Caracolí","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"163","city":"Caramanta","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"164","city":"Carcasí","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"165","city":"Carepa","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"166","city":"Carmen de Apicalá","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"167","city":"Carmen de Carupa","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"168","city":"Carmen de Viboral","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"169","city":"Carmen del Darién (CURBAR","iso_country":"CO","state_id":"27","state":"CHO"},
    {"id_city":"170","city":"Carolina","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"171","city":"Cartagena","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"172","city":"Cartagena del Chairá","iso_country":"CO","state_id":"18","state":"CAQ"},
    {"id_city":"173","city":"Cartago","iso_country":"CO","state_id":"76","state":"VAC"},
    {"id_city":"174","city":"Carurú","iso_country":"CO","state_id":"97","state":"VAU"},
    {"id_city":"175","city":"Casabianca","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"176","city":"Castilla la Nueva","iso_country":"CO","state_id":"50","state":"MET"},
    {"id_city":"177","city":"Caucasia","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"179","city":"Cepita","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"180","city":"Cereté","iso_country":"CO","state_id":"23","state":"COR"},
    {"id_city":"181","city":"Cerinza","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"182","city":"Cerrito","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"183","city":"Cerro San Antonio","iso_country":"CO","state_id":"47","state":"MAG"},
    {"id_city":"271","city":"Cértegui","iso_country":"CO","state_id":"27","state":"CHO"},
    {"id_city":"184","city":"Chachaguí","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"185","city":"Chaguaní","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"186","city":"Chalán","iso_country":"CO","state_id":"70","state":"SUC"},
    {"id_city":"210","city":"Chámeza","iso_country":"CO","state_id":"85","state":"CAS"},
    {"id_city":"187","city":"Chaparral","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"188","city":"Charalá","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"189","city":"Charta","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"211","city":"Chía","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"190","city":"Chigorodó","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"191","city":"Chima","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"193","city":"Chimá","iso_country":"CO","state_id":"23","state":"COR"},
    {"id_city":"192","city":"Chimichagua","iso_country":"CO","state_id":"20","state":"CES"},
    {"id_city":"196","city":"Chinácota","iso_country":"CO","state_id":"54","state":"NSA"},
    {"id_city":"194","city":"Chinavita","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"195","city":"Chinchiná","iso_country":"CO","state_id":"17","state":"CAL"},
    {"id_city":"197","city":"Chinú","iso_country":"CO","state_id":"23","state":"COR"},
    {"id_city":"198","city":"Chipaque","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"199","city":"Chipatá","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"200","city":"Chiquinquirá","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"212","city":"Chíquiza","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"201","city":"Chiriguaná","iso_country":"CO","state_id":"20","state":"CES"},
    {"id_city":"202","city":"Chiscas","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"203","city":"Chita","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"204","city":"Chitagá","iso_country":"CO","state_id":"54","state":"NSA"},
    {"id_city":"205","city":"Chitaraque","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"206","city":"Chivatá","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"207","city":"Chivolo","iso_country":"CO","state_id":"47","state":"MAG"},
    {"id_city":"213","city":"Chívor","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"208","city":"Choachí","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"209","city":"Chocontá","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"214","city":"Cicuco","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"218","city":"Ciénaga","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"219","city":"Ciénaga","iso_country":"CO","state_id":"47","state":"MAG"},
    {"id_city":"220","city":"Ciénaga de Oro","iso_country":"CO","state_id":"23","state":"COR"},
    {"id_city":"215","city":"Cimitarra","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"216","city":"Circasia","iso_country":"CO","state_id":"63","state":"QUI"},
    {"id_city":"217","city":"Cisneros","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"221","city":"Clemencia","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"222","city":"Cocorná","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"223","city":"Coello","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"224","city":"Cogua","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"225","city":"Colombia","iso_country":"CO","state_id":"41","state":"HUI"},
    {"id_city":"227","city":"Colón","iso_country":"CO","state_id":"86","state":"PUT"},
    {"id_city":"228","city":"Colón (Génova)","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"226","city":"Colosó (Ricaurte)","iso_country":"CO","state_id":"70","state":"SUC"},
    {"id_city":"272","city":"Cómbita","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"229","city":"Concepción","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"230","city":"Concepción","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"231","city":"Concordia","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"232","city":"Concordia","iso_country":"CO","state_id":"47","state":"MAG"},
    {"id_city":"233","city":"Condoto","iso_country":"CO","state_id":"27","state":"CHO"},
    {"id_city":"234","city":"Confines","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"235","city":"Consaca","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"236","city":"Contadero","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"237","city":"Contratación","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"238","city":"Convención","iso_country":"CO","state_id":"54","state":"NSA"},
    {"id_city":"239","city":"Copacabana","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"240","city":"Coper","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"241","city":"Cordobá","iso_country":"CO","state_id":"63","state":"QUI"},
    {"id_city":"273","city":"Córdoba","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"274","city":"Córdoba","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"242","city":"Corinto","iso_country":"CO","state_id":"19","state":"CAU"},
    {"id_city":"243","city":"Coromoro","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"244","city":"Corozal","iso_country":"CO","state_id":"70","state":"SUC"},
    {"id_city":"245","city":"Corrales","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"246","city":"Cota","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"247","city":"Cotorra","iso_country":"CO","state_id":"23","state":"COR"},
    {"id_city":"248","city":"Covarachía","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"249","city":"Coveñas","iso_country":"CO","state_id":"70","state":"SUC"},
    {"id_city":"250","city":"Coyaima","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"251","city":"Cravo Norte","iso_country":"CO","state_id":"81","state":"ARA"},
    {"id_city":"252","city":"Cuaspud (Carlosama)","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"254","city":"Cubará","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"253","city":"Cubarral","iso_country":"CO","state_id":"50","state":"MET"},
    {"id_city":"255","city":"Cucaita","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"256","city":"Cucunubá","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"275","city":"Cúcuta","iso_country":"CO","state_id":"54","state":"NSA"},
    {"id_city":"257","city":"Cucutilla","iso_country":"CO","state_id":"54","state":"NSA"},
    {"id_city":"258","city":"Cuitiva","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"259","city":"Cumaral","iso_country":"CO","state_id":"50","state":"MET"},
    {"id_city":"260","city":"Cumaribo","iso_country":"CO","state_id":"99","state":"VID"},
    {"id_city":"261","city":"Cumbal","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"262","city":"Cumbitara","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"263","city":"Cunday","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"264","city":"Curillo","iso_country":"CO","state_id":"18","state":"CAQ"},
    {"id_city":"265","city":"Curití","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"266","city":"Curumaní","iso_country":"CO","state_id":"20","state":"CES"},
    {"id_city":"276","city":"Dabeiba","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"277","city":"Dagua","iso_country":"CO","state_id":"76","state":"VAC"},
    {"id_city":"278","city":"Dibulla","iso_country":"CO","state_id":"44","state":"LAG"},
    {"id_city":"279","city":"Distracción","iso_country":"CO","state_id":"44","state":"LAG"},
    {"id_city":"280","city":"Dolores","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"281","city":"Don Matías","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"282","city":"Dos Quebradas","iso_country":"CO","state_id":"66","state":"RIS"},
    {"id_city":"283","city":"Duitama","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"284","city":"Durania","iso_country":"CO","state_id":"54","state":"NSA"},
    {"id_city":"285","city":"Ebéjico","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"325","city":"El Águila","iso_country":"CO","state_id":"76","state":"VAC"},
    {"id_city":"286","city":"El Bagre","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"287","city":"El Banco","iso_country":"CO","state_id":"47","state":"MAG"},
    {"id_city":"288","city":"El Cairo","iso_country":"CO","state_id":"76","state":"VAC"},
    {"id_city":"289","city":"El Calvario","iso_country":"CO","state_id":"50","state":"MET"},
    {"id_city":"290","city":"El Carmen","iso_country":"CO","state_id":"54","state":"NSA"},
    {"id_city":"291","city":"El Carmen","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"292","city":"El Carmen de Atrato","iso_country":"CO","state_id":"27","state":"CHO"},
    {"id_city":"293","city":"El Carmen de Bolívar","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"294","city":"El Castillo","iso_country":"CO","state_id":"50","state":"MET"},
    {"id_city":"295","city":"El Cerrito","iso_country":"CO","state_id":"76","state":"VAC"},
    {"id_city":"296","city":"El Charco","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"297","city":"El Cocuy","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"298","city":"El Colegio","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"299","city":"El Copey","iso_country":"CO","state_id":"20","state":"CES"},
    {"id_city":"300","city":"El Doncello","iso_country":"CO","state_id":"18","state":"CAQ"},
    {"id_city":"301","city":"El Dorado","iso_country":"CO","state_id":"50","state":"MET"},
    {"id_city":"302","city":"El Dovio","iso_country":"CO","state_id":"76","state":"VAC"},
    {"id_city":"303","city":"El Espino","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"304","city":"El Guacamayo","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"305","city":"El Guamo","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"306","city":"El Molino","iso_country":"CO","state_id":"44","state":"LAG"},
    {"id_city":"307","city":"El Paso","iso_country":"CO","state_id":"20","state":"CES"},
    {"id_city":"308","city":"El Paujil","iso_country":"CO","state_id":"18","state":"CAQ"},
    {"id_city":"309","city":"El Peñol","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"310","city":"El Peñon","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"311","city":"El Peñon","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"312","city":"El Peñón","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"313","city":"El Piñon","iso_country":"CO","state_id":"47","state":"MAG"},
    {"id_city":"314","city":"El Playón","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"316","city":"El Retén","iso_country":"CO","state_id":"47","state":"MAG"},
    {"id_city":"315","city":"El Retorno","iso_country":"CO","state_id":"95","state":"GUV"},
    {"id_city":"317","city":"El Roble","iso_country":"CO","state_id":"70","state":"SUC"},
    {"id_city":"318","city":"El Rosal","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"319","city":"El Rosario","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"320","city":"El Tablón de Gómez","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"321","city":"El Tambo","iso_country":"CO","state_id":"19","state":"CAU"},
    {"id_city":"322","city":"El Tambo","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"323","city":"El Tarra","iso_country":"CO","state_id":"54","state":"NSA"},
    {"id_city":"324","city":"El Zulia","iso_country":"CO","state_id":"54","state":"NSA"},
    {"id_city":"326","city":"Elías","iso_country":"CO","state_id":"41","state":"HUI"},
    {"id_city":"327","city":"Encino","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"328","city":"Enciso","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"329","city":"Entrerríos","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"330","city":"Envigado","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"331","city":"Espinal","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"332","city":"Facatativá","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"333","city":"Falan","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"334","city":"Filadelfia","iso_country":"CO","state_id":"17","state":"CAL"},
    {"id_city":"335","city":"Filandia","iso_country":"CO","state_id":"63","state":"QUI"},
    {"id_city":"336","city":"Firavitoba","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"337","city":"Flandes","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"338","city":"Florencia","iso_country":"CO","state_id":"18","state":"CAQ"},
    {"id_city":"339","city":"Florencia","iso_country":"CO","state_id":"19","state":"CAU"},
    {"id_city":"340","city":"Floresta","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"343","city":"Florián","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"341","city":"Florida","iso_country":"CO","state_id":"76","state":"VAC"},
    {"id_city":"342","city":"Floridablanca","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"356","city":"Fómeque","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"344","city":"Fonseca","iso_country":"CO","state_id":"44","state":"LAG"},
    {"id_city":"345","city":"Fortúl","iso_country":"CO","state_id":"81","state":"ARA"},
    {"id_city":"346","city":"Fosca","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"347","city":"Francisco Pizarro","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"348","city":"Fredonia","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"349","city":"Fresno","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"350","city":"Frontino","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"351","city":"Fuente de Oro","iso_country":"CO","state_id":"50","state":"MET"},
    {"id_city":"352","city":"Fundación","iso_country":"CO","state_id":"47","state":"MAG"},
    {"id_city":"353","city":"Funes","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"354","city":"Funza","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"357","city":"Fúquene","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"355","city":"Fusagasugá","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"358","city":"Gachalá","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"359","city":"Gachancipá","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"360","city":"Gachantivá","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"361","city":"Gachetá","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"364","city":"Galán","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"362","city":"Galapa","iso_country":"CO","state_id":"8","state":"ATL"},
    {"id_city":"363","city":"Galeras (Nueva Granada)","iso_country":"CO","state_id":"70","state":"SUC"},
    {"id_city":"365","city":"Gama","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"366","city":"Gamarra","iso_country":"CO","state_id":"20","state":"CES"},
    {"id_city":"413","city":"Gámbita","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"414","city":"Gámeza","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"367","city":"Garagoa","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"368","city":"Garzón","iso_country":"CO","state_id":"41","state":"HUI"},
    {"id_city":"415","city":"Génova","iso_country":"CO","state_id":"63","state":"QUI"},
    {"id_city":"369","city":"Gigante","iso_country":"CO","state_id":"41","state":"HUI"},
    {"id_city":"370","city":"Ginebra","iso_country":"CO","state_id":"76","state":"VAC"},
    {"id_city":"371","city":"Giraldo","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"372","city":"Girardot","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"373","city":"Girardota","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"374","city":"Girón","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"416","city":"Gómez Plata","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"375","city":"Gonzalez","iso_country":"CO","state_id":"20","state":"CES"},
    {"id_city":"376","city":"Gramalote","iso_country":"CO","state_id":"54","state":"NSA"},
    {"id_city":"377","city":"Granada","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"378","city":"Granada","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"379","city":"Granada","iso_country":"CO","state_id":"50","state":"MET"},
    {"id_city":"380","city":"Guaca","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"381","city":"Guacamayas","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"382","city":"Guacarí","iso_country":"CO","state_id":"76","state":"VAC"},
    {"id_city":"383","city":"Guachavés","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"384","city":"Guachené","iso_country":"CO","state_id":"19","state":"CAU"},
    {"id_city":"385","city":"Guachetá","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"386","city":"Guachucal","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"387","city":"Guadalupe","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"388","city":"Guadalupe","iso_country":"CO","state_id":"41","state":"HUI"},
    {"id_city":"389","city":"Guadalupe","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"390","city":"Guaduas","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"391","city":"Guaitarilla","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"392","city":"Gualmatán","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"393","city":"Guamal","iso_country":"CO","state_id":"47","state":"MAG"},
    {"id_city":"394","city":"Guamal","iso_country":"CO","state_id":"50","state":"MET"},
    {"id_city":"395","city":"Guamo","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"397","city":"Guapí","iso_country":"CO","state_id":"19","state":"CAU"},
    {"id_city":"396","city":"Guapota","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"398","city":"Guaranda","iso_country":"CO","state_id":"70","state":"SUC"},
    {"id_city":"399","city":"Guarne","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"400","city":"Guasca","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"401","city":"Guatapé","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"402","city":"Guataquí","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"403","city":"Guatavita","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"404","city":"Guateque","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"412","city":"Guática","iso_country":"CO","state_id":"66","state":"RIS"},
    {"id_city":"405","city":"Guavatá","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"406","city":"Guayabal de Siquima","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"407","city":"Guayabetal","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"408","city":"Guayatá","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"409","city":"Guepsa","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"410","city":"Guicán","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"411","city":"Gutiérrez","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"417","city":"Hacarí","iso_country":"CO","state_id":"54","state":"NSA"},
    {"id_city":"418","city":"Hatillo de Loba","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"419","city":"Hato","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"420","city":"Hato Corozal","iso_country":"CO","state_id":"85","state":"CAS"},
    {"id_city":"421","city":"Hatonuevo","iso_country":"CO","state_id":"44","state":"LAG"},
    {"id_city":"422","city":"Heliconia","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"423","city":"Herrán","iso_country":"CO","state_id":"54","state":"NSA"},
    {"id_city":"424","city":"Herveo","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"425","city":"Hispania","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"426","city":"Hobo","iso_country":"CO","state_id":"41","state":"HUI"},
    {"id_city":"427","city":"Honda","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"428","city":"Ibagué","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"429","city":"Icononzo","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"430","city":"Iles","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"431","city":"Imúes","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"1101","city":"indefinido","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"433","city":"Inírida","iso_country":"CO","state_id":"94","state":"GUA"},
    {"id_city":"432","city":"Inzá","iso_country":"CO","state_id":"19","state":"CAU"},
    {"id_city":"434","city":"Ipiales","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"1098","city":"Íquira","iso_country":"CO","state_id":"41","state":"HUI"},
    {"id_city":"435","city":"Isnos","iso_country":"CO","state_id":"41","state":"HUI"},
    {"id_city":"436","city":"Istmina","iso_country":"CO","state_id":"27","state":"CHO"},
    {"id_city":"437","city":"Itagüí","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"438","city":"Ituango","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"439","city":"Izá","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"440","city":"Jambaló","iso_country":"CO","state_id":"19","state":"CAU"},
    {"id_city":"441","city":"Jamundí","iso_country":"CO","state_id":"76","state":"VAC"},
    {"id_city":"442","city":"Jardín","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"443","city":"Jenesano","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"444","city":"Jericó","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"445","city":"Jericó","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"446","city":"Jerusalén","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"447","city":"Jesús María","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"448","city":"Jordán","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"449","city":"Juan de Acosta","iso_country":"CO","state_id":"8","state":"ATL"},
    {"id_city":"450","city":"Junín","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"451","city":"Juradó","iso_country":"CO","state_id":"27","state":"CHO"},
    {"id_city":"452","city":"La Apartada y La Frontera","iso_country":"CO","state_id":"23","state":"COR"},
    {"id_city":"453","city":"La Argentina","iso_country":"CO","state_id":"41","state":"HUI"},
    {"id_city":"454","city":"La Belleza","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"455","city":"La Calera","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"456","city":"La Capilla","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"457","city":"La Ceja","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"458","city":"La Celia","iso_country":"CO","state_id":"66","state":"RIS"},
    {"id_city":"459","city":"La Cruz","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"460","city":"La Cumbre","iso_country":"CO","state_id":"76","state":"VAC"},
    {"id_city":"461","city":"La Dorada","iso_country":"CO","state_id":"17","state":"CAL"},
    {"id_city":"462","city":"La Esperanza","iso_country":"CO","state_id":"54","state":"NSA"},
    {"id_city":"463","city":"La Estrella","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"464","city":"La Florida","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"465","city":"La Gloria","iso_country":"CO","state_id":"20","state":"CES"},
    {"id_city":"466","city":"La Jagua de Ibirico","iso_country":"CO","state_id":"20","state":"CES"},
    {"id_city":"467","city":"La Jagua del Pilar","iso_country":"CO","state_id":"44","state":"LAG"},
    {"id_city":"468","city":"La Llanada","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"469","city":"La Macarena","iso_country":"CO","state_id":"50","state":"MET"},
    {"id_city":"470","city":"La Merced","iso_country":"CO","state_id":"17","state":"CAL"},
    {"id_city":"471","city":"La Mesa","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"472","city":"La Montañita","iso_country":"CO","state_id":"18","state":"CAQ"},
    {"id_city":"473","city":"La Palma","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"474","city":"La Paz","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"475","city":"La Paz (Robles)","iso_country":"CO","state_id":"20","state":"CES"},
    {"id_city":"476","city":"La Peña","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"477","city":"La Pintada","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"478","city":"La Plata","iso_country":"CO","state_id":"41","state":"HUI"},
    {"id_city":"479","city":"La Playa","iso_country":"CO","state_id":"54","state":"NSA"},
    {"id_city":"480","city":"La Primavera","iso_country":"CO","state_id":"99","state":"VID"},
    {"id_city":"481","city":"La Salina","iso_country":"CO","state_id":"85","state":"CAS"},
    {"id_city":"482","city":"La Sierra","iso_country":"CO","state_id":"19","state":"CAU"},
    {"id_city":"483","city":"La Tebaida","iso_country":"CO","state_id":"63","state":"QUI"},
    {"id_city":"484","city":"La Tola","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"485","city":"La Unión","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"486","city":"La Unión","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"487","city":"La Unión","iso_country":"CO","state_id":"70","state":"SUC"},
    {"id_city":"488","city":"La Unión","iso_country":"CO","state_id":"76","state":"VAC"},
    {"id_city":"489","city":"La Uvita","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"490","city":"La Vega","iso_country":"CO","state_id":"19","state":"CAU"},
    {"id_city":"491","city":"La Vega","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"492","city":"La Victoria","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"493","city":"La Victoria","iso_country":"CO","state_id":"17","state":"CAL"},
    {"id_city":"494","city":"La Victoria","iso_country":"CO","state_id":"76","state":"VAC"},
    {"id_city":"495","city":"La Virginia","iso_country":"CO","state_id":"66","state":"RIS"},
    {"id_city":"496","city":"Labateca","iso_country":"CO","state_id":"54","state":"NSA"},
    {"id_city":"497","city":"Labranzagrande","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"498","city":"Landázuri","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"499","city":"Lebrija","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"500","city":"Leiva","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"501","city":"Lejanías","iso_country":"CO","state_id":"50","state":"MET"},
    {"id_city":"502","city":"Lenguazaque","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"514","city":"Lérida","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"503","city":"Leticia","iso_country":"CO","state_id":"91","state":"AMA"},
    {"id_city":"515","city":"Líbano","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"504","city":"Liborina","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"505","city":"Linares","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"506","city":"Lloró","iso_country":"CO","state_id":"27","state":"CHO"},
    {"id_city":"516","city":"López (Micay)","iso_country":"CO","state_id":"19","state":"CAU"},
    {"id_city":"507","city":"Lorica","iso_country":"CO","state_id":"23","state":"COR"},
    {"id_city":"508","city":"Los Córdobas","iso_country":"CO","state_id":"23","state":"COR"},
    {"id_city":"509","city":"Los Palmitos","iso_country":"CO","state_id":"70","state":"SUC"},
    {"id_city":"510","city":"Los Patios","iso_country":"CO","state_id":"54","state":"NSA"},
    {"id_city":"511","city":"Los Santos","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"512","city":"Lourdes","iso_country":"CO","state_id":"54","state":"NSA"},
    {"id_city":"513","city":"Luruaco","iso_country":"CO","state_id":"8","state":"ATL"},
    {"id_city":"517","city":"Macanal","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"518","city":"Macaravita","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"519","city":"Maceo","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"520","city":"Machetá","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"521","city":"Madrid","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"522","city":"Magangué","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"523","city":"Magüi (Payán)","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"524","city":"Mahates","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"525","city":"Maicao","iso_country":"CO","state_id":"44","state":"LAG"},
    {"id_city":"526","city":"Majagual","iso_country":"CO","state_id":"70","state":"SUC"},
    {"id_city":"588","city":"Málaga","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"527","city":"Malambo","iso_country":"CO","state_id":"8","state":"ATL"},
    {"id_city":"528","city":"Mallama (Piedrancha)","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"529","city":"Manatí","iso_country":"CO","state_id":"8","state":"ATL"},
    {"id_city":"530","city":"Manaure","iso_country":"CO","state_id":"44","state":"LAG"},
    {"id_city":"531","city":"Manaure Balcón del Cesar","iso_country":"CO","state_id":"20","state":"CES"},
    {"id_city":"535","city":"Maní","iso_country":"CO","state_id":"85","state":"CAS"},
    {"id_city":"532","city":"Manizales","iso_country":"CO","state_id":"17","state":"CAL"},
    {"id_city":"533","city":"Manta","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"534","city":"Manzanares","iso_country":"CO","state_id":"17","state":"CAL"},
    {"id_city":"536","city":"Mapiripan","iso_country":"CO","state_id":"50","state":"MET"},
    {"id_city":"537","city":"Margarita","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"545","city":"María la Baja","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"538","city":"Marinilla","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"539","city":"Maripí","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"540","city":"Mariquita","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"541","city":"Marmato","iso_country":"CO","state_id":"17","state":"CAL"},
    {"id_city":"542","city":"Marquetalia","iso_country":"CO","state_id":"17","state":"CAL"},
    {"id_city":"543","city":"Marsella","iso_country":"CO","state_id":"66","state":"RIS"},
    {"id_city":"544","city":"Marulanda","iso_country":"CO","state_id":"17","state":"CAL"},
    {"id_city":"546","city":"Matanza","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"547","city":"Medellín","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"548","city":"Medina","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"549","city":"Medio Atrato","iso_country":"CO","state_id":"27","state":"CHO"},
    {"id_city":"550","city":"Medio Baudó","iso_country":"CO","state_id":"27","state":"CHO"},
    {"id_city":"551","city":"Medio San Juan (ANDAGOYA)","iso_country":"CO","state_id":"27","state":"CHO"},
    {"id_city":"552","city":"Melgar","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"553","city":"Mercaderes","iso_country":"CO","state_id":"19","state":"CAU"},
    {"id_city":"554","city":"Mesetas","iso_country":"CO","state_id":"50","state":"MET"},
    {"id_city":"555","city":"Milán","iso_country":"CO","state_id":"18","state":"CAQ"},
    {"id_city":"556","city":"Miraflores","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"557","city":"Miraflores","iso_country":"CO","state_id":"95","state":"GUV"},
    {"id_city":"558","city":"Miranda","iso_country":"CO","state_id":"19","state":"CAU"},
    {"id_city":"559","city":"Mistrató","iso_country":"CO","state_id":"66","state":"RIS"},
    {"id_city":"560","city":"Mitú","iso_country":"CO","state_id":"97","state":"VAU"},
    {"id_city":"561","city":"Mocoa","iso_country":"CO","state_id":"86","state":"PUT"},
    {"id_city":"562","city":"Mogotes","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"563","city":"Molagavita","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"564","city":"Momil","iso_country":"CO","state_id":"23","state":"COR"},
    {"id_city":"565","city":"Mompós","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"566","city":"Mongua","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"567","city":"Monguí","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"568","city":"Moniquirá","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"569","city":"Montebello","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"570","city":"Montecristo","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"571","city":"Montelíbano","iso_country":"CO","state_id":"23","state":"COR"},
    {"id_city":"572","city":"Montenegro","iso_country":"CO","state_id":"63","state":"QUI"},
    {"id_city":"573","city":"Monteria","iso_country":"CO","state_id":"23","state":"COR"},
    {"id_city":"574","city":"Monterrey","iso_country":"CO","state_id":"85","state":"CAS"},
    {"id_city":"582","city":"Moñitos","iso_country":"CO","state_id":"23","state":"COR"},
    {"id_city":"575","city":"Morales","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"576","city":"Morales","iso_country":"CO","state_id":"19","state":"CAU"},
    {"id_city":"577","city":"Morelia","iso_country":"CO","state_id":"18","state":"CAQ"},
    {"id_city":"578","city":"Morroa","iso_country":"CO","state_id":"70","state":"SUC"},
    {"id_city":"579","city":"Mosquera","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"580","city":"Mosquera","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"581","city":"Motavita","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"583","city":"Murillo","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"584","city":"Murindó","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"585","city":"Mutatá","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"586","city":"Mutiscua","iso_country":"CO","state_id":"54","state":"NSA"},
    {"id_city":"587","city":"Muzo","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"589","city":"Nariño","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"590","city":"Nariño","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"591","city":"Nariño","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"609","city":"Nátaga","iso_country":"CO","state_id":"41","state":"HUI"},
    {"id_city":"592","city":"Natagaima","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"593","city":"Nechí","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"594","city":"Necoclí","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"595","city":"Neira","iso_country":"CO","state_id":"17","state":"CAL"},
    {"id_city":"596","city":"Neiva","iso_country":"CO","state_id":"41","state":"HUI"},
    {"id_city":"597","city":"Nemocón","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"598","city":"Nilo","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"599","city":"Nimaima","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"600","city":"Nobsa","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"601","city":"Nocaima","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"602","city":"Norcasia","iso_country":"CO","state_id":"17","state":"CAL"},
    {"id_city":"603","city":"Norosí","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"604","city":"Novita","iso_country":"CO","state_id":"27","state":"CHO"},
    {"id_city":"605","city":"Nueva Granada","iso_country":"CO","state_id":"47","state":"MAG"},
    {"id_city":"606","city":"Nuevo Colón","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"607","city":"Nunchía","iso_country":"CO","state_id":"85","state":"CAS"},
    {"id_city":"608","city":"Nuquí","iso_country":"CO","state_id":"27","state":"CHO"},
    {"id_city":"610","city":"Obando","iso_country":"CO","state_id":"76","state":"VAC"},
    {"id_city":"611","city":"Ocamonte","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"612","city":"Ocaña","iso_country":"CO","state_id":"54","state":"NSA"},
    {"id_city":"613","city":"Oiba","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"614","city":"Oicatá","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"615","city":"Olaya","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"616","city":"Olaya Herrera","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"617","city":"Onzaga","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"618","city":"Oporapa","iso_country":"CO","state_id":"41","state":"HUI"},
    {"id_city":"619","city":"Orito","iso_country":"CO","state_id":"86","state":"PUT"},
    {"id_city":"620","city":"Orocué","iso_country":"CO","state_id":"85","state":"CAS"},
    {"id_city":"621","city":"Ortega","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"622","city":"Ospina","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"623","city":"Otanche","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"624","city":"Ovejas","iso_country":"CO","state_id":"70","state":"SUC"},
    {"id_city":"625","city":"Pachavita","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"626","city":"Pacho","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"723","city":"Pácora","iso_country":"CO","state_id":"17","state":"CAL"},
    {"id_city":"627","city":"Padilla","iso_country":"CO","state_id":"19","state":"CAU"},
    {"id_city":"724","city":"Páez","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"725","city":"Páez (Belalcazar)","iso_country":"CO","state_id":"19","state":"CAU"},
    {"id_city":"628","city":"Paicol","iso_country":"CO","state_id":"41","state":"HUI"},
    {"id_city":"629","city":"Pailitas","iso_country":"CO","state_id":"20","state":"CES"},
    {"id_city":"630","city":"Paime","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"631","city":"Paipa","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"632","city":"Pajarito","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"633","city":"Palermo","iso_country":"CO","state_id":"41","state":"HUI"},
    {"id_city":"634","city":"Palestina","iso_country":"CO","state_id":"17","state":"CAL"},
    {"id_city":"635","city":"Palestina","iso_country":"CO","state_id":"41","state":"HUI"},
    {"id_city":"636","city":"Palmar","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"637","city":"Palmar de Varela","iso_country":"CO","state_id":"8","state":"ATL"},
    {"id_city":"638","city":"Palmas del Socorro","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"639","city":"Palmira","iso_country":"CO","state_id":"76","state":"VAC"},
    {"id_city":"640","city":"Palmito","iso_country":"CO","state_id":"70","state":"SUC"},
    {"id_city":"641","city":"Palocabildo","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"642","city":"Pamplona","iso_country":"CO","state_id":"54","state":"NSA"},
    {"id_city":"643","city":"Pamplonita","iso_country":"CO","state_id":"54","state":"NSA"},
    {"id_city":"644","city":"Pandi","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"645","city":"Panqueba","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"726","city":"Páramo","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"646","city":"Paratebueno","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"647","city":"Pasca","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"648","city":"Patía (El Bordo)","iso_country":"CO","state_id":"19","state":"CAU"},
    {"id_city":"649","city":"Pauna","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"650","city":"Paya","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"651","city":"Paz de Ariporo","iso_country":"CO","state_id":"85","state":"CAS"},
    {"id_city":"652","city":"Paz de Río","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"653","city":"Pedraza","iso_country":"CO","state_id":"47","state":"MAG"},
    {"id_city":"654","city":"Pelaya","iso_country":"CO","state_id":"20","state":"CES"},
    {"id_city":"655","city":"Pensilvania","iso_country":"CO","state_id":"17","state":"CAL"},
    {"id_city":"659","city":"Peñol","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"656","city":"Peque","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"657","city":"Pereira","iso_country":"CO","state_id":"66","state":"RIS"},
    {"id_city":"658","city":"Pesca","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"660","city":"Piamonte","iso_country":"CO","state_id":"19","state":"CAU"},
    {"id_city":"661","city":"Pie de Cuesta","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"662","city":"Piedras","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"663","city":"Piendamó","iso_country":"CO","state_id":"19","state":"CAU"},
    {"id_city":"664","city":"Pijao","iso_country":"CO","state_id":"63","state":"QUI"},
    {"id_city":"665","city":"Pijiño","iso_country":"CO","state_id":"47","state":"MAG"},
    {"id_city":"666","city":"Pinchote","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"667","city":"Pinillos","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"668","city":"Piojo","iso_country":"CO","state_id":"8","state":"ATL"},
    {"id_city":"669","city":"Pisva","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"670","city":"Pital","iso_country":"CO","state_id":"41","state":"HUI"},
    {"id_city":"671","city":"Pitalito","iso_country":"CO","state_id":"41","state":"HUI"},
    {"id_city":"672","city":"Pivijay","iso_country":"CO","state_id":"47","state":"MAG"},
    {"id_city":"673","city":"Planadas","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"674","city":"Planeta Rica","iso_country":"CO","state_id":"23","state":"COR"},
    {"id_city":"675","city":"Plato","iso_country":"CO","state_id":"47","state":"MAG"},
    {"id_city":"676","city":"Policarpa","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"677","city":"Polonuevo","iso_country":"CO","state_id":"8","state":"ATL"},
    {"id_city":"678","city":"Ponedera","iso_country":"CO","state_id":"8","state":"ATL"},
    {"id_city":"679","city":"Popayán","iso_country":"CO","state_id":"19","state":"CAU"},
    {"id_city":"680","city":"Pore","iso_country":"CO","state_id":"85","state":"CAS"},
    {"id_city":"681","city":"Potosí","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"682","city":"Pradera","iso_country":"CO","state_id":"76","state":"VAC"},
    {"id_city":"683","city":"Prado","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"684","city":"Providencia","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"685","city":"Providencia","iso_country":"CO","state_id":"88","state":"SAP"},
    {"id_city":"686","city":"Pueblo Bello","iso_country":"CO","state_id":"20","state":"CES"},
    {"id_city":"687","city":"Pueblo Nuevo","iso_country":"CO","state_id":"23","state":"COR"},
    {"id_city":"688","city":"Pueblo Rico","iso_country":"CO","state_id":"66","state":"RIS"},
    {"id_city":"689","city":"Pueblorrico","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"690","city":"Puebloviejo","iso_country":"CO","state_id":"47","state":"MAG"},
    {"id_city":"691","city":"Puente Nacional","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"692","city":"Puerres","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"693","city":"Puerto Asís","iso_country":"CO","state_id":"86","state":"PUT"},
    {"id_city":"694","city":"Puerto Berrío","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"695","city":"Puerto Boyacá","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"696","city":"Puerto Caicedo","iso_country":"CO","state_id":"86","state":"PUT"},
    {"id_city":"697","city":"Puerto Carreño","iso_country":"CO","state_id":"99","state":"VID"},
    {"id_city":"698","city":"Puerto Colombia","iso_country":"CO","state_id":"8","state":"ATL"},
    {"id_city":"699","city":"Puerto Concordia","iso_country":"CO","state_id":"50","state":"MET"},
    {"id_city":"700","city":"Puerto Escondido","iso_country":"CO","state_id":"23","state":"COR"},
    {"id_city":"701","city":"Puerto Gaitán","iso_country":"CO","state_id":"50","state":"MET"},
    {"id_city":"702","city":"Puerto Guzmán","iso_country":"CO","state_id":"86","state":"PUT"},
    {"id_city":"703","city":"Puerto Leguízamo","iso_country":"CO","state_id":"86","state":"PUT"},
    {"id_city":"704","city":"Puerto Libertador","iso_country":"CO","state_id":"23","state":"COR"},
    {"id_city":"705","city":"Puerto Lleras","iso_country":"CO","state_id":"50","state":"MET"},
    {"id_city":"706","city":"Puerto López","iso_country":"CO","state_id":"50","state":"MET"},
    {"id_city":"707","city":"Puerto Nare","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"708","city":"Puerto Nariño","iso_country":"CO","state_id":"91","state":"AMA"},
    {"id_city":"709","city":"Puerto Parra","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"710","city":"Puerto Rico","iso_country":"CO","state_id":"18","state":"CAQ"},
    {"id_city":"711","city":"Puerto Rico","iso_country":"CO","state_id":"50","state":"MET"},
    {"id_city":"712","city":"Puerto Rondón","iso_country":"CO","state_id":"81","state":"ARA"},
    {"id_city":"713","city":"Puerto Salgar","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"714","city":"Puerto Santander","iso_country":"CO","state_id":"54","state":"NSA"},
    {"id_city":"715","city":"Puerto Tejada","iso_country":"CO","state_id":"19","state":"CAU"},
    {"id_city":"716","city":"Puerto Triunfo","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"717","city":"Puerto Wilches","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"718","city":"Pulí","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"719","city":"Pupiales","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"720","city":"Puracé (Coconuco)","iso_country":"CO","state_id":"19","state":"CAU"},
    {"id_city":"721","city":"Purificación","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"722","city":"Purísima","iso_country":"CO","state_id":"23","state":"COR"},
    {"id_city":"727","city":"Quebradanegra","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"728","city":"Quetame","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"729","city":"Quibdó","iso_country":"CO","state_id":"27","state":"CHO"},
    {"id_city":"730","city":"Quimbaya","iso_country":"CO","state_id":"63","state":"QUI"},
    {"id_city":"731","city":"Quinchía","iso_country":"CO","state_id":"66","state":"RIS"},
    {"id_city":"732","city":"Quipama","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"733","city":"Quipile","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"734","city":"Ragonvalia","iso_country":"CO","state_id":"54","state":"NSA"},
    {"id_city":"735","city":"Ramiriquí","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"758","city":"Ráquira","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"736","city":"Recetor","iso_country":"CO","state_id":"85","state":"CAS"},
    {"id_city":"737","city":"Regidor","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"738","city":"Remedios","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"739","city":"Remolino","iso_country":"CO","state_id":"47","state":"MAG"},
    {"id_city":"740","city":"Repelón","iso_country":"CO","state_id":"8","state":"ATL"},
    {"id_city":"741","city":"Restrepo","iso_country":"CO","state_id":"50","state":"MET"},
    {"id_city":"742","city":"Restrepo","iso_country":"CO","state_id":"76","state":"VAC"},
    {"id_city":"743","city":"Retiro","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"744","city":"Ricaurte","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"745","city":"Ricaurte","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"763","city":"Río de oro","iso_country":"CO","state_id":"20","state":"CES"},
    {"id_city":"759","city":"Río Iró","iso_country":"CO","state_id":"27","state":"CHO"},
    {"id_city":"746","city":"Rio Negro","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"760","city":"Río Quito","iso_country":"CO","state_id":"27","state":"CHO"},
    {"id_city":"761","city":"Río Sucio","iso_country":"CO","state_id":"17","state":"CAL"},
    {"id_city":"762","city":"Río Viejo","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"747","city":"Rioblanco","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"748","city":"Riofrío","iso_country":"CO","state_id":"76","state":"VAC"},
    {"id_city":"749","city":"Riohacha","iso_country":"CO","state_id":"44","state":"LAG"},
    {"id_city":"764","city":"Ríonegro","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"765","city":"Ríosucio","iso_country":"CO","state_id":"27","state":"CHO"},
    {"id_city":"750","city":"Risaralda","iso_country":"CO","state_id":"17","state":"CAL"},
    {"id_city":"751","city":"Rivera","iso_country":"CO","state_id":"41","state":"HUI"},
    {"id_city":"752","city":"Roberto Payán (San José)","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"753","city":"Roldanillo","iso_country":"CO","state_id":"76","state":"VAC"},
    {"id_city":"754","city":"Roncesvalles","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"755","city":"Rondón","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"756","city":"Rosas","iso_country":"CO","state_id":"19","state":"CAU"},
    {"id_city":"757","city":"Rovira","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"766","city":"Sabana de Torres","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"767","city":"Sabanagrande","iso_country":"CO","state_id":"8","state":"ATL"},
    {"id_city":"768","city":"Sabanalarga","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"769","city":"Sabanalarga","iso_country":"CO","state_id":"8","state":"ATL"},
    {"id_city":"770","city":"Sabanalarga","iso_country":"CO","state_id":"85","state":"CAS"},
    {"id_city":"771","city":"Sabanas de San Angel (SAN","iso_country":"CO","state_id":"47","state":"MAG"},
    {"id_city":"772","city":"Sabaneta","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"773","city":"Saboyá","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"955","city":"Sácama","iso_country":"CO","state_id":"85","state":"CAS"},
    {"id_city":"956","city":"Sáchica","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"774","city":"Sahagún","iso_country":"CO","state_id":"23","state":"COR"},
    {"id_city":"775","city":"Saladoblanco","iso_country":"CO","state_id":"41","state":"HUI"},
    {"id_city":"776","city":"Salamina","iso_country":"CO","state_id":"17","state":"CAL"},
    {"id_city":"777","city":"Salamina","iso_country":"CO","state_id":"47","state":"MAG"},
    {"id_city":"778","city":"Salazar","iso_country":"CO","state_id":"54","state":"NSA"},
    {"id_city":"779","city":"Saldaña","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"780","city":"Salento","iso_country":"CO","state_id":"63","state":"QUI"},
    {"id_city":"781","city":"Salgar","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"782","city":"Samacá","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"784","city":"Samaná","iso_country":"CO","state_id":"17","state":"CAL"},
    {"id_city":"783","city":"Samaniego","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"785","city":"Sampués","iso_country":"CO","state_id":"70","state":"SUC"},
    {"id_city":"786","city":"San Agustín","iso_country":"CO","state_id":"41","state":"HUI"},
    {"id_city":"787","city":"San Alberto","iso_country":"CO","state_id":"20","state":"CES"},
    {"id_city":"788","city":"San Andrés","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"790","city":"San Andrés de Cuerquía","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"789","city":"San Andrés Sotavento","iso_country":"CO","state_id":"23","state":"COR"},
    {"id_city":"791","city":"San Antero","iso_country":"CO","state_id":"23","state":"COR"},
    {"id_city":"792","city":"San Antonio","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"793","city":"San Antonio de Tequendama","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"794","city":"San Benito","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"795","city":"San Benito Abad","iso_country":"CO","state_id":"70","state":"SUC"},
    {"id_city":"796","city":"San Bernardo","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"797","city":"San Bernardo","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"798","city":"San Bernardo del Viento","iso_country":"CO","state_id":"23","state":"COR"},
    {"id_city":"799","city":"San Calixto","iso_country":"CO","state_id":"54","state":"NSA"},
    {"id_city":"800","city":"San Carlos","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"801","city":"San Carlos","iso_country":"CO","state_id":"23","state":"COR"},
    {"id_city":"802","city":"San Carlos de Guaroa","iso_country":"CO","state_id":"50","state":"MET"},
    {"id_city":"803","city":"San Cayetano","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"804","city":"San Cayetano","iso_country":"CO","state_id":"54","state":"NSA"},
    {"id_city":"805","city":"San Cristobal","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"806","city":"San Diego","iso_country":"CO","state_id":"20","state":"CES"},
    {"id_city":"807","city":"San Eduardo","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"808","city":"San Estanislao","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"809","city":"San Fernando","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"810","city":"San Francisco","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"811","city":"San Francisco","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"812","city":"San Francisco","iso_country":"CO","state_id":"86","state":"PUT"},
    {"id_city":"813","city":"San Gíl","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"814","city":"San Jacinto","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"815","city":"San Jacinto del Cauca","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"816","city":"San Jerónimo","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"817","city":"San Joaquín","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"818","city":"San José","iso_country":"CO","state_id":"17","state":"CAL"},
    {"id_city":"819","city":"San José de Miranda","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"820","city":"San José de Montaña","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"821","city":"San José de Pare","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"822","city":"San José de Uré","iso_country":"CO","state_id":"23","state":"COR"},
    {"id_city":"823","city":"San José del Fragua","iso_country":"CO","state_id":"18","state":"CAQ"},
    {"id_city":"824","city":"San José del Guaviare","iso_country":"CO","state_id":"95","state":"GUV"},
    {"id_city":"825","city":"San José del Palmar","iso_country":"CO","state_id":"27","state":"CHO"},
    {"id_city":"826","city":"San Juan de Arama","iso_country":"CO","state_id":"50","state":"MET"},
    {"id_city":"827","city":"San Juan de Betulia","iso_country":"CO","state_id":"70","state":"SUC"},
    {"id_city":"828","city":"San Juan de Nepomuceno","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"829","city":"San Juan de Pasto","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"830","city":"San Juan de Río Seco","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"831","city":"San Juan de Urabá","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"832","city":"San Juan del Cesar","iso_country":"CO","state_id":"44","state":"LAG"},
    {"id_city":"833","city":"San Juanito","iso_country":"CO","state_id":"50","state":"MET"},
    {"id_city":"834","city":"San Lorenzo","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"835","city":"San Luis","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"836","city":"San Luís","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"837","city":"San Luís de Gaceno","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"838","city":"San Luís de Palenque","iso_country":"CO","state_id":"85","state":"CAS"},
    {"id_city":"839","city":"San Marcos","iso_country":"CO","state_id":"70","state":"SUC"},
    {"id_city":"840","city":"San Martín","iso_country":"CO","state_id":"20","state":"CES"},
    {"id_city":"841","city":"San Martín","iso_country":"CO","state_id":"50","state":"MET"},
    {"id_city":"842","city":"San Martín de Loba","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"843","city":"San Mateo","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"844","city":"San Miguel","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"845","city":"San Miguel","iso_country":"CO","state_id":"86","state":"PUT"},
    {"id_city":"846","city":"San Miguel de Sema","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"847","city":"San Onofre","iso_country":"CO","state_id":"70","state":"SUC"},
    {"id_city":"848","city":"San Pablo","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"849","city":"San Pablo","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"850","city":"San Pablo de Borbur","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"851","city":"San Pedro","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"852","city":"San Pedro","iso_country":"CO","state_id":"70","state":"SUC"},
    {"id_city":"853","city":"San Pedro","iso_country":"CO","state_id":"76","state":"VAC"},
    {"id_city":"854","city":"San Pedro de Cartago","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"855","city":"San Pedro de Urabá","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"856","city":"San Pelayo","iso_country":"CO","state_id":"23","state":"COR"},
    {"id_city":"857","city":"San Rafael","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"858","city":"San Roque","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"859","city":"San Sebastián","iso_country":"CO","state_id":"19","state":"CAU"},
    {"id_city":"860","city":"San Sebastián de Buenavis","iso_country":"CO","state_id":"47","state":"MAG"},
    {"id_city":"861","city":"San Vicente","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"862","city":"San Vicente del Caguán","iso_country":"CO","state_id":"18","state":"CAQ"},
    {"id_city":"863","city":"San Vicente del Chucurí","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"864","city":"San Zenón","iso_country":"CO","state_id":"47","state":"MAG"},
    {"id_city":"865","city":"Sandoná","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"866","city":"Santa Ana","iso_country":"CO","state_id":"47","state":"MAG"},
    {"id_city":"867","city":"Santa Bárbara","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"868","city":"Santa Bárbara","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"869","city":"Santa Bárbara (Iscuandé)","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"870","city":"Santa Bárbara de Pinto","iso_country":"CO","state_id":"47","state":"MAG"},
    {"id_city":"871","city":"Santa Catalina","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"872","city":"Santa Fé de Antioquia","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"873","city":"Santa Genoveva de Docorod","iso_country":"CO","state_id":"27","state":"CHO"},
    {"id_city":"874","city":"Santa Helena del Opón","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"875","city":"Santa Isabel","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"876","city":"Santa Lucía","iso_country":"CO","state_id":"8","state":"ATL"},
    {"id_city":"878","city":"Santa María","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"879","city":"Santa María","iso_country":"CO","state_id":"41","state":"HUI"},
    {"id_city":"877","city":"Santa Marta","iso_country":"CO","state_id":"47","state":"MAG"},
    {"id_city":"880","city":"Santa Rosa","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"881","city":"Santa Rosa","iso_country":"CO","state_id":"19","state":"CAU"},
    {"id_city":"882","city":"Santa Rosa de Cabal","iso_country":"CO","state_id":"66","state":"RIS"},
    {"id_city":"883","city":"Santa Rosa de Osos","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"884","city":"Santa Rosa de Viterbo","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"885","city":"Santa Rosa del Sur","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"886","city":"Santa Rosalía","iso_country":"CO","state_id":"99","state":"VID"},
    {"id_city":"887","city":"Santa Sofía","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"888","city":"Santana","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"889","city":"Santander de Quilichao","iso_country":"CO","state_id":"19","state":"CAU"},
    {"id_city":"890","city":"Santiago","iso_country":"CO","state_id":"54","state":"NSA"},
    {"id_city":"891","city":"Santiago","iso_country":"CO","state_id":"86","state":"PUT"},
    {"id_city":"892","city":"Santo Domingo","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"893","city":"Santo Tomás","iso_country":"CO","state_id":"8","state":"ATL"},
    {"id_city":"894","city":"Santuario","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"895","city":"Santuario","iso_country":"CO","state_id":"66","state":"RIS"},
    {"id_city":"896","city":"Sapuyes","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"897","city":"Saravena","iso_country":"CO","state_id":"81","state":"ARA"},
    {"id_city":"898","city":"Sardinata","iso_country":"CO","state_id":"54","state":"NSA"},
    {"id_city":"899","city":"Sasaima","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"900","city":"Sativanorte","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"901","city":"Sativasur","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"902","city":"Segovia","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"903","city":"Sesquilé","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"904","city":"Sevilla","iso_country":"CO","state_id":"76","state":"VAC"},
    {"id_city":"905","city":"Siachoque","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"906","city":"Sibaté","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"907","city":"Sibundoy","iso_country":"CO","state_id":"86","state":"PUT"},
    {"id_city":"908","city":"Silos","iso_country":"CO","state_id":"54","state":"NSA"},
    {"id_city":"909","city":"Silvania","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"910","city":"Silvia","iso_country":"CO","state_id":"19","state":"CAU"},
    {"id_city":"911","city":"Simacota","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"912","city":"Simijaca","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"913","city":"Simití","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"915","city":"Sincé","iso_country":"CO","state_id":"70","state":"SUC"},
    {"id_city":"914","city":"Sincelejo","iso_country":"CO","state_id":"70","state":"SUC"},
    {"id_city":"916","city":"Sipí","iso_country":"CO","state_id":"27","state":"CHO"},
    {"id_city":"917","city":"Sitionuevo","iso_country":"CO","state_id":"47","state":"MAG"},
    {"id_city":"918","city":"Soacha","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"919","city":"Soatá","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"920","city":"Socha","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"921","city":"Socorro","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"922","city":"Socotá","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"923","city":"Sogamoso","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"924","city":"Solano","iso_country":"CO","state_id":"18","state":"CAQ"},
    {"id_city":"925","city":"Soledad","iso_country":"CO","state_id":"8","state":"ATL"},
    {"id_city":"926","city":"Solita","iso_country":"CO","state_id":"18","state":"CAQ"},
    {"id_city":"927","city":"Somondoco","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"928","city":"Sonsón","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"929","city":"Sopetrán","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"930","city":"Soplaviento","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"931","city":"Sopó","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"932","city":"Sora","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"933","city":"Soracá","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"934","city":"Sotaquirá","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"935","city":"Sotara (Paispamba)","iso_country":"CO","state_id":"19","state":"CAU"},
    {"id_city":"936","city":"Sotomayor (Los Andes)","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"937","city":"Suaita","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"938","city":"Suan","iso_country":"CO","state_id":"8","state":"ATL"},
    {"id_city":"953","city":"Suárez","iso_country":"CO","state_id":"19","state":"CAU"},
    {"id_city":"954","city":"Suárez","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"939","city":"Suaza","iso_country":"CO","state_id":"41","state":"HUI"},
    {"id_city":"940","city":"Subachoque","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"941","city":"Sucre","iso_country":"CO","state_id":"19","state":"CAU"},
    {"id_city":"942","city":"Sucre","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"943","city":"Sucre","iso_country":"CO","state_id":"70","state":"SUC"},
    {"id_city":"944","city":"Suesca","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"945","city":"Supatá","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"946","city":"Supía","iso_country":"CO","state_id":"17","state":"CAL"},
    {"id_city":"947","city":"Suratá","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"948","city":"Susa","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"949","city":"Susacón","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"950","city":"Sutamarchán","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"951","city":"Sutatausa","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"952","city":"Sutatenza","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"957","city":"Tabio","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"958","city":"Tadó","iso_country":"CO","state_id":"27","state":"CHO"},
    {"id_city":"959","city":"Talaigua Nuevo","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"960","city":"Tamalameque","iso_country":"CO","state_id":"20","state":"CES"},
    {"id_city":"1021","city":"Támara","iso_country":"CO","state_id":"85","state":"CAS"},
    {"id_city":"961","city":"Tame","iso_country":"CO","state_id":"81","state":"ARA"},
    {"id_city":"1022","city":"Támesis","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"962","city":"Taminango","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"963","city":"Tangua","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"964","city":"Taraira","iso_country":"CO","state_id":"97","state":"VAU"},
    {"id_city":"965","city":"Tarazá","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"966","city":"Tarqui","iso_country":"CO","state_id":"41","state":"HUI"},
    {"id_city":"967","city":"Tarso","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"968","city":"Tasco","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"969","city":"Tauramena","iso_country":"CO","state_id":"85","state":"CAS"},
    {"id_city":"970","city":"Tausa","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"971","city":"Tello","iso_country":"CO","state_id":"41","state":"HUI"},
    {"id_city":"972","city":"Tena","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"973","city":"Tenerife","iso_country":"CO","state_id":"47","state":"MAG"},
    {"id_city":"974","city":"Tenjo","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"975","city":"Tenza","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"976","city":"Teorama","iso_country":"CO","state_id":"54","state":"NSA"},
    {"id_city":"977","city":"Teruel","iso_country":"CO","state_id":"41","state":"HUI"},
    {"id_city":"978","city":"Tesalia","iso_country":"CO","state_id":"41","state":"HUI"},
    {"id_city":"979","city":"Tibacuy","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"980","city":"Tibaná","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"981","city":"Tibasosa","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"982","city":"Tibirita","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"983","city":"Tibú","iso_country":"CO","state_id":"54","state":"NSA"},
    {"id_city":"984","city":"Tierralta","iso_country":"CO","state_id":"23","state":"COR"},
    {"id_city":"985","city":"Timaná","iso_country":"CO","state_id":"41","state":"HUI"},
    {"id_city":"987","city":"Timbío","iso_country":"CO","state_id":"19","state":"CAU"},
    {"id_city":"986","city":"Timbiquí","iso_country":"CO","state_id":"19","state":"CAU"},
    {"id_city":"988","city":"Tinjacá","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"989","city":"Tipacoque","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"990","city":"Tiquisio (Puerto Rico)","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"991","city":"Titiribí","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"992","city":"Toca","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"993","city":"Tocaima","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"994","city":"Tocancipá","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"995","city":"Toguí","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"996","city":"Toledo","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"997","city":"Toledo","iso_country":"CO","state_id":"54","state":"NSA"},
    {"id_city":"998","city":"Tolú","iso_country":"CO","state_id":"70","state":"SUC"},
    {"id_city":"999","city":"Tolú Viejo","iso_country":"CO","state_id":"70","state":"SUC"},
    {"id_city":"1000","city":"Tona","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"1001","city":"Topagá","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"1002","city":"Topaipí","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"1003","city":"Toribío","iso_country":"CO","state_id":"19","state":"CAU"},
    {"id_city":"1004","city":"Toro","iso_country":"CO","state_id":"76","state":"VAC"},
    {"id_city":"1005","city":"Tota","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"1006","city":"Totoró","iso_country":"CO","state_id":"19","state":"CAU"},
    {"id_city":"1007","city":"Trinidad","iso_country":"CO","state_id":"85","state":"CAS"},
    {"id_city":"1008","city":"Trujillo","iso_country":"CO","state_id":"76","state":"VAC"},
    {"id_city":"1009","city":"Tubará","iso_country":"CO","state_id":"8","state":"ATL"},
    {"id_city":"1010","city":"Tuchín","iso_country":"CO","state_id":"23","state":"COR"},
    {"id_city":"1011","city":"Tulúa","iso_country":"CO","state_id":"76","state":"VAC"},
    {"id_city":"1012","city":"Tumaco","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"1013","city":"Tunja","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"1014","city":"Tunungua","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"1023","city":"Túquerres","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"1015","city":"Turbaco","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"1016","city":"Turbaná","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"1017","city":"Turbo","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"1018","city":"Turmequé","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"1019","city":"Tuta","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"1020","city":"Tutasá","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"1024","city":"Ubalá","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"1025","city":"Ubaque","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"1026","city":"Ubaté","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"1027","city":"Ulloa","iso_country":"CO","state_id":"76","state":"VAC"},
    {"id_city":"1099","city":"Úmbita","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"1028","city":"Une","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"1029","city":"Unguía","iso_country":"CO","state_id":"27","state":"CHO"},
    {"id_city":"1030","city":"Unión Panamericana (ÁNIMA","iso_country":"CO","state_id":"27","state":"CHO"},
    {"id_city":"1031","city":"Uramita","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"1032","city":"Uribe","iso_country":"CO","state_id":"50","state":"MET"},
    {"id_city":"1033","city":"Uribia","iso_country":"CO","state_id":"44","state":"LAG"},
    {"id_city":"1034","city":"Urrao","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"1035","city":"Urumita","iso_country":"CO","state_id":"44","state":"LAG"},
    {"id_city":"1036","city":"Usiacuri","iso_country":"CO","state_id":"8","state":"ATL"},
    {"id_city":"1100","city":"Útica","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"1037","city":"Valdivia","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"1038","city":"Valencia","iso_country":"CO","state_id":"23","state":"COR"},
    {"id_city":"1039","city":"Valle de San José","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"1040","city":"Valle de San Juan","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"1041","city":"Valle del Guamuez","iso_country":"CO","state_id":"86","state":"PUT"},
    {"id_city":"1042","city":"Valledupar","iso_country":"CO","state_id":"20","state":"CES"},
    {"id_city":"1043","city":"Valparaiso","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"1044","city":"Valparaiso","iso_country":"CO","state_id":"18","state":"CAQ"},
    {"id_city":"1045","city":"Vegachí","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"1077","city":"Vélez","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"1046","city":"Venadillo","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"1047","city":"Venecia","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"1048","city":"Venecia (Ospina Pérez)","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"1049","city":"Ventaquemada","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"1050","city":"Vergara","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"1051","city":"Versalles","iso_country":"CO","state_id":"76","state":"VAC"},
    {"id_city":"1052","city":"Vetas","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"1053","city":"Viani","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"1054","city":"Vigía del Fuerte","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"1055","city":"Vijes","iso_country":"CO","state_id":"76","state":"VAC"},
    {"id_city":"1056","city":"Villa Caro","iso_country":"CO","state_id":"54","state":"NSA"},
    {"id_city":"1058","city":"Villa de Leiva","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"1059","city":"Villa del Rosario","iso_country":"CO","state_id":"54","state":"NSA"},
    {"id_city":"1057","city":"Villa Rica","iso_country":"CO","state_id":"19","state":"CAU"},
    {"id_city":"1060","city":"Villagarzón","iso_country":"CO","state_id":"86","state":"PUT"},
    {"id_city":"1061","city":"Villagómez","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"1062","city":"Villahermosa","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"1063","city":"Villamaría","iso_country":"CO","state_id":"17","state":"CAL"},
    {"id_city":"1064","city":"Villanueva","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"1065","city":"Villanueva","iso_country":"CO","state_id":"44","state":"LAG"},
    {"id_city":"1066","city":"Villanueva","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"1067","city":"Villanueva","iso_country":"CO","state_id":"85","state":"CAS"},
    {"id_city":"1068","city":"Villapinzón","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"1069","city":"Villarrica","iso_country":"CO","state_id":"73","state":"TOL"},
    {"id_city":"1070","city":"Villavicencio","iso_country":"CO","state_id":"50","state":"MET"},
    {"id_city":"1071","city":"Villavieja","iso_country":"CO","state_id":"41","state":"HUI"},
    {"id_city":"1072","city":"Villeta","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"1073","city":"Viotá","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"1074","city":"Viracachá","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"1075","city":"Vista Hermosa","iso_country":"CO","state_id":"50","state":"MET"},
    {"id_city":"1076","city":"Viterbo","iso_country":"CO","state_id":"17","state":"CAL"},
    {"id_city":"1078","city":"Yacopí","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"1079","city":"Yacuanquer","iso_country":"CO","state_id":"52","state":"NAR"},
    {"id_city":"1080","city":"Yaguará","iso_country":"CO","state_id":"41","state":"HUI"},
    {"id_city":"1081","city":"Yalí","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"1082","city":"Yarumal","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"1083","city":"Yolombó","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"1084","city":"Yondó (Casabe)","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"1085","city":"Yopal","iso_country":"CO","state_id":"85","state":"CAS"},
    {"id_city":"1086","city":"Yotoco","iso_country":"CO","state_id":"76","state":"VAC"},
    {"id_city":"1087","city":"Yumbo","iso_country":"CO","state_id":"76","state":"VAC"},
    {"id_city":"1088","city":"Zambrano","iso_country":"CO","state_id":"13","state":"BOL"},
    {"id_city":"1089","city":"Zapatoca","iso_country":"CO","state_id":"68","state":"SAN"},
    {"id_city":"1090","city":"Zapayán (PUNTA DE PIEDRAS","iso_country":"CO","state_id":"47","state":"MAG"},
    {"id_city":"1091","city":"Zaragoza","iso_country":"CO","state_id":"5","state":"ANT"},
    {"id_city":"1092","city":"Zarzal","iso_country":"CO","state_id":"76","state":"VAC"},
    {"id_city":"1093","city":"Zetaquirá","iso_country":"CO","state_id":"15","state":"BOY"},
    {"id_city":"1094","city":"Zipacón","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"1095","city":"Zipaquirá","iso_country":"CO","state_id":"25","state":"CUN"},
    {"id_city":"1096","city":"Zona Bananera (PRADO-SEVI","iso_country":"CO","state_id":"47","state":"MAG"}
    ]
export const Countries =[
    {"id":"1","iso":"AF","nombre":"Afganistán"},
    {"id":"2","iso":"AX","nombre":"Islas Gland"},
    {"id":"3","iso":"AL","nombre":"Albania"},
    {"id":"4","iso":"DE","nombre":"Alemania"},
    {"id":"5","iso":"AD","nombre":"Andorra"},
    {"id":"6","iso":"AO","nombre":"Angola"},
    {"id":"7","iso":"AI","nombre":"Anguilla"},
    {"id":"8","iso":"AQ","nombre":"Antártida"},
    {"id":"9","iso":"AG","nombre":"Antigua y Barbuda"},
    {"id":"10","iso":"AN","nombre":"Antillas Holandesas"},
    {"id":"11","iso":"SA","nombre":"Arabia Saudí"},
    {"id":"12","iso":"DZ","nombre":"Argelia"},
    {"id":"13","iso":"AR","nombre":"Argentina"},
    {"id":"14","iso":"AM","nombre":"Armenia"},
    {"id":"15","iso":"AW","nombre":"Aruba"},
    {"id":"16","iso":"AU","nombre":"Australia"},
    {"id":"17","iso":"AT","nombre":"Austria"},
    {"id":"18","iso":"AZ","nombre":"Azerbaiyán"},
    {"id":"19","iso":"BS","nombre":"Bahamas"},
    {"id":"20","iso":"BH","nombre":"Bahréin"},
    {"id":"21","iso":"BD","nombre":"Bangladesh"},
    {"id":"22","iso":"BB","nombre":"Barbados"},
    {"id":"23","iso":"BY","nombre":"Bielorrusia"},
    {"id":"24","iso":"BE","nombre":"Bélgica"},
    {"id":"25","iso":"BZ","nombre":"Belice"},
    {"id":"26","iso":"BJ","nombre":"Benin"},
    {"id":"27","iso":"BM","nombre":"Bermudas"},
    {"id":"28","iso":"BT","nombre":"Bhután"},
    {"id":"29","iso":"BO","nombre":"Bolivia"},
    {"id":"30","iso":"BA","nombre":"Bosnia y Herzegovina"},
    {"id":"31","iso":"BW","nombre":"Botsuana"},
    {"id":"32","iso":"BV","nombre":"Isla Bouvet"},
    {"id":"33","iso":"BR","nombre":"Brasil"},
    {"id":"34","iso":"BN","nombre":"Brunéi"},
    {"id":"35","iso":"BG","nombre":"Bulgaria"},
    {"id":"36","iso":"BF","nombre":"Burkina Faso"},
    {"id":"37","iso":"BI","nombre":"Burundi"},
    {"id":"38","iso":"CV","nombre":"Cabo Verde"},
    {"id":"39","iso":"KY","nombre":"Islas Caimán"},
    {"id":"40","iso":"KH","nombre":"Camboya"},
    {"id":"41","iso":"CM","nombre":"Camerún"},
    {"id":"42","iso":"CA","nombre":"Canadá"},
    {"id":"43","iso":"CF","nombre":"República Centroafricana"},
    {"id":"44","iso":"TD","nombre":"Chad"},
    {"id":"45","iso":"CZ","nombre":"República Checa"},
    {"id":"46","iso":"CL","nombre":"Chile"},
    {"id":"47","iso":"CN","nombre":"China"},
    {"id":"48","iso":"CY","nombre":"Chipre"},
    {"id":"49","iso":"CX","nombre":"Isla de Navidad"},
    {"id":"50","iso":"VA","nombre":"Ciudad del Vaticano"},
    {"id":"51","iso":"CC","nombre":"Islas Cocos"},
    {"id":"52","iso":"CO","nombre":"Colombia"},
    {"id":"53","iso":"KM","nombre":"Comoras"},
    {"id":"54","iso":"CD","nombre":"República Democrática del Congo"},
    {"id":"55","iso":"CG","nombre":"Congo"},
    {"id":"56","iso":"CK","nombre":"Islas Cook"},
    {"id":"57","iso":"KP","nombre":"Corea del Norte"},
    {"id":"58","iso":"KR","nombre":"Corea del Sur"},
    {"id":"59","iso":"CI","nombre":"Costa de Marfil"},
    {"id":"60","iso":"CR","nombre":"Costa Rica"},
    {"id":"61","iso":"HR","nombre":"Croacia"},
    {"id":"62","iso":"CU","nombre":"Cuba"},
    {"id":"63","iso":"DK","nombre":"Dinamarca"},
    {"id":"64","iso":"DM","nombre":"Dominica"},
    {"id":"65","iso":"DO","nombre":"República Dominicana"},
    {"id":"66","iso":"EC","nombre":"Ecuador"},
    {"id":"67","iso":"EG","nombre":"Egipto"},
    {"id":"68","iso":"SV","nombre":"El Salvador"},
    {"id":"69","iso":"AE","nombre":"Emiratos Árabes Unidos"},
    {"id":"70","iso":"ER","nombre":"Eritrea"},
    {"id":"71","iso":"SK","nombre":"Eslovaquia"},
    {"id":"72","iso":"SI","nombre":"Eslovenia"},
    {"id":"73","iso":"ES","nombre":"España"},
    {"id":"74","iso":"UM","nombre":"Islas ultramarinas de Estados Unidos"},
    {"id":"75","iso":"US","nombre":"Estados Unidos"},
    {"id":"76","iso":"EE","nombre":"Estonia"},
    {"id":"77","iso":"ET","nombre":"Etiopía"},
    {"id":"78","iso":"FO","nombre":"Islas Feroe"},
    {"id":"79","iso":"PH","nombre":"Filipinas"},
    {"id":"80","iso":"FI","nombre":"Finlandia"},
    {"id":"81","iso":"FJ","nombre":"Fiyi"},
    {"id":"82","iso":"FR","nombre":"Francia"},
    {"id":"83","iso":"GA","nombre":"Gabón"},
    {"id":"84","iso":"GM","nombre":"Gambia"},
    {"id":"85","iso":"GE","nombre":"Georgia"},
    {"id":"86","iso":"GS","nombre":"Islas Georgias del Sur y Sandwich del Sur"},
    {"id":"87","iso":"GH","nombre":"Ghana"},
    {"id":"88","iso":"GI","nombre":"Gibraltar"},
    {"id":"89","iso":"GD","nombre":"Granada"},
    {"id":"90","iso":"GR","nombre":"Grecia"},
    {"id":"91","iso":"GL","nombre":"Groenlandia"},
    {"id":"92","iso":"GP","nombre":"Guadalupe"},
    {"id":"93","iso":"GU","nombre":"Guam"},
    {"id":"94","iso":"GT","nombre":"Guatemala"},
    {"id":"95","iso":"GF","nombre":"Guayana Francesa"},
    {"id":"96","iso":"GN","nombre":"Guinea"},
    {"id":"97","iso":"GQ","nombre":"Guinea Ecuatorial"},
    {"id":"98","iso":"GW","nombre":"Guinea-Bissau"},
    {"id":"99","iso":"GY","nombre":"Guyana"},
    {"id":"100","iso":"HT","nombre":"Haití"},
    {"id":"101","iso":"HM","nombre":"Islas Heard y McDonald"},
    {"id":"102","iso":"HN","nombre":"Honduras"},
    {"id":"103","iso":"HK","nombre":"Hong Kong"},
    {"id":"104","iso":"HU","nombre":"Hungría"},
    {"id":"105","iso":"IN","nombre":"India"},
    {"id":"106","iso":"ID","nombre":"Indonesia"},
    {"id":"107","iso":"IR","nombre":"Irán"},
    {"id":"108","iso":"IQ","nombre":"Iraq"},
    {"id":"109","iso":"IE","nombre":"Irlanda"},
    {"id":"110","iso":"IS","nombre":"Islandia"},
    {"id":"111","iso":"IL","nombre":"Israel"},
    {"id":"112","iso":"IT","nombre":"Italia"},
    {"id":"113","iso":"JM","nombre":"Jamaica"},
    {"id":"114","iso":"JP","nombre":"Japón"},
    {"id":"115","iso":"JO","nombre":"Jordania"},
    {"id":"116","iso":"KZ","nombre":"Kazajstán"},
    {"id":"117","iso":"KE","nombre":"Kenia"},
    {"id":"118","iso":"KG","nombre":"Kirguistán"},
    {"id":"119","iso":"KI","nombre":"Kiribati"},
    {"id":"120","iso":"KW","nombre":"Kuwait"},
    {"id":"121","iso":"LA","nombre":"Laos"},
    {"id":"122","iso":"LS","nombre":"Lesotho"},
    {"id":"123","iso":"LV","nombre":"Letonia"},
    {"id":"124","iso":"LB","nombre":"Líbano"},
    {"id":"125","iso":"LR","nombre":"Liberia"},
    {"id":"126","iso":"LY","nombre":"Libia"},
    {"id":"127","iso":"LI","nombre":"Liechtenstein"},
    {"id":"128","iso":"LT","nombre":"Lituania"},
    {"id":"129","iso":"LU","nombre":"Luxemburgo"},
    {"id":"130","iso":"MO","nombre":"Macao"},
    {"id":"131","iso":"MK","nombre":"ARY Macedonia"},
    {"id":"132","iso":"MG","nombre":"Madagascar"},
    {"id":"133","iso":"MY","nombre":"Malasia"},
    {"id":"134","iso":"MW","nombre":"Malawi"},
    {"id":"135","iso":"MV","nombre":"Maldivas"},
    {"id":"136","iso":"ML","nombre":"Malí"},
    {"id":"137","iso":"MT","nombre":"Malta"},
    {"id":"138","iso":"FK","nombre":"Islas Malvinas"},
    {"id":"139","iso":"MP","nombre":"Islas Marianas del Norte"},
    {"id":"140","iso":"MA","nombre":"Marruecos"},
    {"id":"141","iso":"MH","nombre":"Islas Marshall"},
    {"id":"142","iso":"MQ","nombre":"Martinica"},
    {"id":"143","iso":"MU","nombre":"Mauricio"},
    {"id":"144","iso":"MR","nombre":"Mauritania"},
    {"id":"145","iso":"YT","nombre":"Mayotte"},
    {"id":"146","iso":"MX","nombre":"México"},
    {"id":"147","iso":"FM","nombre":"Micronesia"},
    {"id":"148","iso":"MD","nombre":"Moldavia"},
    {"id":"149","iso":"MC","nombre":"Mónaco"},
    {"id":"150","iso":"MN","nombre":"Mongolia"},
    {"id":"151","iso":"MS","nombre":"Montserrat"},
    {"id":"152","iso":"MZ","nombre":"Mozambique"},
    {"id":"153","iso":"MM","nombre":"Myanmar"},
    {"id":"154","iso":"NA","nombre":"Namibia"},
    {"id":"155","iso":"NR","nombre":"Nauru"},
    {"id":"156","iso":"NP","nombre":"Nepal"},
    {"id":"157","iso":"NI","nombre":"Nicaragua"},
    {"id":"158","iso":"NE","nombre":"Níger"},
    {"id":"159","iso":"NG","nombre":"Nigeria"},
    {"id":"160","iso":"NU","nombre":"Niue"},
    {"id":"161","iso":"NF","nombre":"Isla Norfolk"},
    {"id":"162","iso":"NO","nombre":"Noruega"},
    {"id":"163","iso":"NC","nombre":"Nueva Caledonia"},
    {"id":"164","iso":"NZ","nombre":"Nueva Zelanda"},
    {"id":"165","iso":"OM","nombre":"Omán"},
    {"id":"166","iso":"NL","nombre":"Países Bajos"},
    {"id":"167","iso":"PK","nombre":"Pakistán"},
    {"id":"168","iso":"PW","nombre":"Palau"},
    {"id":"169","iso":"PS","nombre":"Palestina"},
    {"id":"170","iso":"PA","nombre":"Panamá"},
    {"id":"171","iso":"PG","nombre":"Papúa Nueva Guinea"},
    {"id":"172","iso":"PY","nombre":"Paraguay"},
    {"id":"173","iso":"PE","nombre":"Perú"},
    {"id":"174","iso":"PN","nombre":"Islas Pitcairn"},
    {"id":"175","iso":"PF","nombre":"Polinesia Francesa"},
    {"id":"176","iso":"PL","nombre":"Polonia"},
    {"id":"177","iso":"PT","nombre":"Portugal"},
    {"id":"178","iso":"PR","nombre":"Puerto Rico"},
    {"id":"179","iso":"QA","nombre":"Qatar"},
    {"id":"180","iso":"GB","nombre":"Reino Unido"},
    {"id":"181","iso":"RE","nombre":"Reunión"},
    {"id":"182","iso":"RW","nombre":"Ruanda"},
    {"id":"183","iso":"RO","nombre":"Rumania"},
    {"id":"184","iso":"RU","nombre":"Rusia"},
    {"id":"185","iso":"EH","nombre":"Sahara Occidental"},
    {"id":"186","iso":"SB","nombre":"Islas Salomón"},
    {"id":"187","iso":"WS","nombre":"Samoa"},
    {"id":"188","iso":"AS","nombre":"Samoa Americana"},
    {"id":"189","iso":"KN","nombre":"San Cristóbal y Nevis"},
    {"id":"190","iso":"SM","nombre":"San Marino"},
    {"id":"191","iso":"PM","nombre":"San Pedro y Miquelón"},
    {"id":"192","iso":"VC","nombre":"San Vicente y las Granadinas"},
    {"id":"193","iso":"SH","nombre":"Santa Helena"},
    {"id":"194","iso":"LC","nombre":"Santa Lucía"},
    {"id":"195","iso":"ST","nombre":"Santo Tomé y Príncipe"},
    {"id":"196","iso":"SN","nombre":"Senegal"},
    {"id":"197","iso":"CS","nombre":"Serbia y Montenegro"},
    {"id":"198","iso":"SC","nombre":"Seychelles"},
    {"id":"199","iso":"SL","nombre":"Sierra Leona"},
    {"id":"200","iso":"SG","nombre":"Singapur"},
    {"id":"201","iso":"SY","nombre":"Siria"},
    {"id":"202","iso":"SO","nombre":"Somalia"},
    {"id":"203","iso":"LK","nombre":"Sri Lanka"},
    {"id":"204","iso":"SZ","nombre":"Suazilandia"},
    {"id":"205","iso":"ZA","nombre":"Sudáfrica"},
    {"id":"206","iso":"SD","nombre":"Sudán"},
    {"id":"207","iso":"SE","nombre":"Suecia"},
    {"id":"208","iso":"CH","nombre":"Suiza"},
    {"id":"209","iso":"SR","nombre":"Surinam"},
    {"id":"210","iso":"SJ","nombre":"Svalbard y Jan Mayen"},
    {"id":"211","iso":"TH","nombre":"Tailandia"},
    {"id":"212","iso":"TW","nombre":"Taiwán"},
    {"id":"213","iso":"TZ","nombre":"Tanzania"},
    {"id":"214","iso":"TJ","nombre":"Tayikistán"},
    {"id":"215","iso":"IO","nombre":"Territorio Británico del Océano Índico"},
    {"id":"216","iso":"TF","nombre":"Territorios Australes Franceses"},
    {"id":"217","iso":"TL","nombre":"Timor Oriental"},
    {"id":"218","iso":"TG","nombre":"Togo"},
    {"id":"219","iso":"TK","nombre":"Tokelau"},
    {"id":"220","iso":"TO","nombre":"Tonga"},
    {"id":"221","iso":"TT","nombre":"Trinidad y Tobago"},
    {"id":"222","iso":"TN","nombre":"Túnez"},
    {"id":"223","iso":"TC","nombre":"Islas Turcas y Caicos"},
    {"id":"224","iso":"TM","nombre":"Turkmenistán"},
    {"id":"225","iso":"TR","nombre":"Turquía"},
    {"id":"226","iso":"TV","nombre":"Tuvalu"},
    {"id":"227","iso":"UA","nombre":"Ucrania"},
    {"id":"228","iso":"UG","nombre":"Uganda"},
    {"id":"229","iso":"UY","nombre":"Uruguay"},
    {"id":"230","iso":"UZ","nombre":"Uzbekistán"},
    {"id":"231","iso":"VU","nombre":"Vanuatu"},
    {"id":"232","iso":"VE","nombre":"Venezuela"},
    {"id":"233","iso":"VN","nombre":"Vietnam"},
    {"id":"234","iso":"VG","nombre":"Islas Vírgenes Británicas"},
    {"id":"235","iso":"VI","nombre":"Islas Vírgenes de los Estados Unidos"},
    {"id":"236","iso":"WF","nombre":"Wallis y Futuna"},
    {"id":"237","iso":"YE","nombre":"Yemen"},
    {"id":"238","iso":"DJ","nombre":"Yibuti"},
    {"id":"239","iso":"ZM","nombre":"Zambia"},
    {"id":"240","iso":"ZW","nombre":"Zimbabue"}
    ]
export const CIIU =[
    {"Id":"2","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"01","CIIU_desc":"Agricultura, ganadería, caza y actividades de servicios conexas "},
    {"Id":"3","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"011","CIIU_desc":"Cultivos agrícolas transitorios "},
    {"Id":"4","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"0111","CIIU_desc":"Cultivo de cereales (excepto arroz), legumbres y semillas oleaginosas "},
    {"Id":"5","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"0112","CIIU_desc":"Cultivo de arroz "},
    {"Id":"6","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"0113","CIIU_desc":"Cultivo de hortalizas, raíces y tubérculos "},
    {"Id":"7","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"0114","CIIU_desc":"Cultivo de tabaco "},
    {"Id":"8","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"0115","CIIU_desc":"Cultivo de plantas textiles "},
    {"Id":"9","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"0119","CIIU_desc":"Otros cultivos transitorios n.c.p."},
    {"Id":"10","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"012","CIIU_desc":"Cultivos agrícolas permanentes "},
    {"Id":"11","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"0121","CIIU_desc":"Cultivo de frutas tropicales y subtropicales"},
    {"Id":"12","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"0122","CIIU_desc":"Cultivo de plátano y banano"},
    {"Id":"13","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"0123","CIIU_desc":"Cultivo de café"},
    {"Id":"14","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"0124","CIIU_desc":"Cultivo de caña de azúcar"},
    {"Id":"15","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"0125","CIIU_desc":"Cultivo de flor de corte"},
    {"Id":"16","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"0126","CIIU_desc":"Cultivo de palma para aceite (palma africana) y otros frutos oleaginosos"},
    {"Id":"17","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"0127","CIIU_desc":"Cultivo de plantas con las que se preparan bebidas"},
    {"Id":"18","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"0128","CIIU_desc":"Cultivo de especias y de plantas aromáticas y medicinales "},
    {"Id":"19","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"0129","CIIU_desc":"Otros cultivos permanentes n.c.p."},
    {"Id":"20","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"0130","CIIU_desc":"Propagación de plantas (actividades de los viveros, excepto viveros forestales) "},
    {"Id":"21","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"014","CIIU_desc":"Ganadería "},
    {"Id":"22","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"0141","CIIU_desc":"Cría de ganado bovino y bufalino"},
    {"Id":"23","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"0142","CIIU_desc":"Cría de caballos y otros equinos "},
    {"Id":"24","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"0143","CIIU_desc":"Cría de ovejas y cabras "},
    {"Id":"25","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"0144","CIIU_desc":"Cría de ganado porcino"},
    {"Id":"26","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"0145","CIIU_desc":"Cría de aves de corral"},
    {"Id":"27","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"0149","CIIU_desc":"Cría de otros animales n.c.p."},
    {"Id":"28","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"0150","CIIU_desc":"Explotación mixta (agrícola y pecuaria) "},
    {"Id":"29","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"016","CIIU_desc":"Actividades de apoyo a la agricultura y la ganadería, y actividades posteriores a la cosecha "},
    {"Id":"30","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"0161","CIIU_desc":"Actividades de apoyo a la agricultura "},
    {"Id":"31","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"0162","CIIU_desc":"Actividades de apoyo a la ganadería"},
    {"Id":"32","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"0163","CIIU_desc":"Actividades posteriores a la cosecha "},
    {"Id":"33","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"0164","CIIU_desc":"Tratamiento de semillas para propagación "},
    {"Id":"34","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"0170","CIIU_desc":"Caza ordinaria y mediante trampas y actividades de servicios conexas "},
    {"Id":"35","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"02","CIIU_desc":"Silvicultura y extracción de madera"},
    {"Id":"36","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"0210","CIIU_desc":"Silvicultura y otras actividades forestales"},
    {"Id":"37","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"0220","CIIU_desc":"Extracción de madera "},
    {"Id":"38","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"0230","CIIU_desc":"Recolección de productos forestales diferentes a la madera"},
    {"Id":"39","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"0240","CIIU_desc":"Servicios de apoyo a la silvicultura "},
    {"Id":"40","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"03","CIIU_desc":"Pesca y acuicultura"},
    {"Id":"41","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"031","CIIU_desc":"Pesca "},
    {"Id":"42","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"0311","CIIU_desc":"Pesca marítima "},
    {"Id":"43","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"0312","CIIU_desc":"Pesca de agua dulce "},
    {"Id":"44","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"032","CIIU_desc":"Acuicultura "},
    {"Id":"45","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"0321","CIIU_desc":"Acuicultura marítima "},
    {"Id":"46","section":"SECCIÓN A","section_desc":"AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA Y PESCA","CIIU":"0322","CIIU_desc":"Acuicultura de agua dulce"},
    {"Id":"47","section":"SECCIÓN B","section_desc":"EXPLOTACIÓN DE MINAS Y CANTERAS","CIIU":"05","CIIU_desc":"Extracción de carbón de piedra y lignito"},
    {"Id":"48","section":"SECCIÓN B","section_desc":"EXPLOTACIÓN DE MINAS Y CANTERAS","CIIU":"0510","CIIU_desc":"Extracción de hulla (carbón de piedra)"},
    {"Id":"49","section":"SECCIÓN B","section_desc":"EXPLOTACIÓN DE MINAS Y CANTERAS","CIIU":"0520","CIIU_desc":"Extracción de carbón lignito"},
    {"Id":"50","section":"SECCIÓN B","section_desc":"EXPLOTACIÓN DE MINAS Y CANTERAS","CIIU":"06","CIIU_desc":"Extracción de petróleo crudo y gas natural"},
    {"Id":"51","section":"SECCIÓN B","section_desc":"EXPLOTACIÓN DE MINAS Y CANTERAS","CIIU":"0610","CIIU_desc":"Extracción de petróleo crudo"},
    {"Id":"52","section":"SECCIÓN B","section_desc":"EXPLOTACIÓN DE MINAS Y CANTERAS","CIIU":"0620","CIIU_desc":"Extracción de gas natural"},
    {"Id":"53","section":"SECCIÓN B","section_desc":"EXPLOTACIÓN DE MINAS Y CANTERAS","CIIU":"07","CIIU_desc":"Extracción de minerales metalíferos"},
    {"Id":"54","section":"SECCIÓN B","section_desc":"EXPLOTACIÓN DE MINAS Y CANTERAS","CIIU":"0710","CIIU_desc":"Extracción de minerales de hierro"},
    {"Id":"55","section":"SECCIÓN B","section_desc":"EXPLOTACIÓN DE MINAS Y CANTERAS","CIIU":"072","CIIU_desc":"Extracción de minerales metalíferos no ferrosos"},
    {"Id":"56","section":"SECCIÓN B","section_desc":"EXPLOTACIÓN DE MINAS Y CANTERAS","CIIU":"0721","CIIU_desc":"Extracción de minerales de uranio y de torio"},
    {"Id":"57","section":"SECCIÓN B","section_desc":"EXPLOTACIÓN DE MINAS Y CANTERAS","CIIU":"0722","CIIU_desc":"Extracción de oro y otros metales preciosos"},
    {"Id":"58","section":"SECCIÓN B","section_desc":"EXPLOTACIÓN DE MINAS Y CANTERAS","CIIU":"0723","CIIU_desc":"Extracción de minerales de níquel"},
    {"Id":"59","section":"SECCIÓN B","section_desc":"EXPLOTACIÓN DE MINAS Y CANTERAS","CIIU":"0729","CIIU_desc":"Extracción de otros minerales metalíferos no ferrosos n.c.p."},
    {"Id":"60","section":"SECCIÓN B","section_desc":"EXPLOTACIÓN DE MINAS Y CANTERAS","CIIU":"08","CIIU_desc":"Extracción de otras minas y canteras"},
    {"Id":"61","section":"SECCIÓN B","section_desc":"EXPLOTACIÓN DE MINAS Y CANTERAS","CIIU":"081","CIIU_desc":"Extracción de piedra, arena, arcillas, cal, yeso, caolín, bentonitas y similares"},
    {"Id":"62","section":"SECCIÓN B","section_desc":"EXPLOTACIÓN DE MINAS Y CANTERAS","CIIU":"0811","CIIU_desc":"Extracción de piedra, arena, arcillas comunes, yeso y anhidrita"},
    {"Id":"63","section":"SECCIÓN B","section_desc":"EXPLOTACIÓN DE MINAS Y CANTERAS","CIIU":"0812","CIIU_desc":"Extracción de arcillas de uso industrial, caliza, caolín y bentonitas"},
    {"Id":"64","section":"SECCIÓN B","section_desc":"EXPLOTACIÓN DE MINAS Y CANTERAS","CIIU":"0820","CIIU_desc":"Extracción de esmeraldas, piedras preciosas y semipreciosas"},
    {"Id":"65","section":"SECCIÓN B","section_desc":"EXPLOTACIÓN DE MINAS Y CANTERAS","CIIU":"089","CIIU_desc":"Extracción de otros minerales no metálicos n.c.p."},
    {"Id":"66","section":"SECCIÓN B","section_desc":"EXPLOTACIÓN DE MINAS Y CANTERAS","CIIU":"0891","CIIU_desc":"Extracción de minerales para la fabricación de abonos y productos químicos"},
    {"Id":"67","section":"SECCIÓN B","section_desc":"EXPLOTACIÓN DE MINAS Y CANTERAS","CIIU":"0892","CIIU_desc":"Extracción de halita (sal)"},
    {"Id":"68","section":"SECCIÓN B","section_desc":"EXPLOTACIÓN DE MINAS Y CANTERAS","CIIU":"0899","CIIU_desc":"Extracción de otros minerales no metálicos n.c.p."},
    {"Id":"69","section":"SECCIÓN B","section_desc":"EXPLOTACIÓN DE MINAS Y CANTERAS","CIIU":"09","CIIU_desc":"Actividades de servicios de apoyo para la explotación de minas "},
    {"Id":"70","section":"SECCIÓN B","section_desc":"EXPLOTACIÓN DE MINAS Y CANTERAS","CIIU":"0910","CIIU_desc":"Actividades de apoyo para la extracción de petróleo y de gas natural"},
    {"Id":"71","section":"SECCIÓN B","section_desc":"EXPLOTACIÓN DE MINAS Y CANTERAS","CIIU":"0990","CIIU_desc":"Actividades de apoyo para otras actividades de explotación de minas y canteras"},
    {"Id":"72","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"10","CIIU_desc":"Elaboración de productos alimenticios"},
    {"Id":"73","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"101","CIIU_desc":"Procesamiento y conservación de carne, pescado, crustáceos y moluscos "},
    {"Id":"74","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1011","CIIU_desc":"Procesamiento y conservación de carne y productos cárnicos"},
    {"Id":"75","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1012","CIIU_desc":"Procesamiento y conservación de pescados, crustáceos y moluscos"},
    {"Id":"76","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1020","CIIU_desc":"Procesamiento y conservación de frutas, legumbres, hortalizas y tubérculos"},
    {"Id":"77","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1030","CIIU_desc":"Elaboración de aceites y grasas de origen vegetal y animal"},
    {"Id":"78","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1040","CIIU_desc":"Elaboración de productos lácteos"},
    {"Id":"79","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"105","CIIU_desc":"Elaboración de productos de molinería, almidones y productos derivados del almidón"},
    {"Id":"80","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1051","CIIU_desc":"Elaboración de productos de molinería"},
    {"Id":"81","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1052","CIIU_desc":"Elaboración de almidones y productos derivados del almidón"},
    {"Id":"82","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"106","CIIU_desc":"Elaboración de productos de café"},
    {"Id":"83","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1061","CIIU_desc":"Trilla de café"},
    {"Id":"84","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1062","CIIU_desc":"Descafeinado, tostión y molienda del café"},
    {"Id":"85","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1063","CIIU_desc":"Otros derivados del café"},
    {"Id":"86","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"107","CIIU_desc":"Elaboración de azúcar y panela"},
    {"Id":"87","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1071","CIIU_desc":"Elaboración y refinación de azúcar"},
    {"Id":"88","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1072","CIIU_desc":"Elaboración de panela"},
    {"Id":"89","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"108","CIIU_desc":"Elaboración de otros productos alimenticios"},
    {"Id":"90","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1081","CIIU_desc":"Elaboración de productos de panadería"},
    {"Id":"91","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1082","CIIU_desc":"Elaboración de cacao, chocolate y productos de confitería"},
    {"Id":"92","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1083","CIIU_desc":"Elaboración de macarrones, fideos, alcuzcuz y productos farináceos similares"},
    {"Id":"93","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1084","CIIU_desc":"Elaboración de comidas y platos preparados"},
    {"Id":"94","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1089","CIIU_desc":"Elaboración de otros productos alimenticios n.c.p."},
    {"Id":"95","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1090","CIIU_desc":"Elaboración de alimentos preparados para animales"},
    {"Id":"96","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"11","CIIU_desc":"Elaboración de bebidas"},
    {"Id":"97","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"110","CIIU_desc":"Elaboración de bebidas"},
    {"Id":"98","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1101","CIIU_desc":"Destilación, rectificación y mezcla de bebidas alcohólicas"},
    {"Id":"99","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1102","CIIU_desc":"Elaboración de bebidas fermentadas no destiladas"},
    {"Id":"100","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1103","CIIU_desc":"Producción de malta, elaboración de cervezas y otras bebidas malteadas"},
    {"Id":"101","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1104","CIIU_desc":"Elaboración de bebidas no alcohólicas, producción de aguas minerales y de otras aguas embotelladas"},
    {"Id":"102","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"12","CIIU_desc":"Elaboración de productos de tabaco"},
    {"Id":"103","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1200","CIIU_desc":"Elaboración de productos de tabaco"},
    {"Id":"104","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"13","CIIU_desc":"Fabricación de productos textiles"},
    {"Id":"105","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"131","CIIU_desc":"Preparación, hilatura, tejeduría y acabado de productos textiles"},
    {"Id":"106","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1311","CIIU_desc":"Preparación e hilatura de fibras textiles"},
    {"Id":"107","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1312","CIIU_desc":"Tejeduría de productos textiles"},
    {"Id":"108","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1313","CIIU_desc":"Acabado de productos textiles"},
    {"Id":"109","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"139","CIIU_desc":"Fabricación de otros productos textiles"},
    {"Id":"110","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1391","CIIU_desc":"Fabricación de tejidos de punto y ganchillo"},
    {"Id":"111","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1392","CIIU_desc":"Confección de artículos con materiales textiles, excepto prendas de vestir"},
    {"Id":"112","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1393","CIIU_desc":"Fabricación de tapetes y alfombras para pisos"},
    {"Id":"113","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1394","CIIU_desc":"Fabricación de cuerdas, cordeles, cables, bramantes y redes"},
    {"Id":"114","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1399","CIIU_desc":"Fabricación de otros artículos textiles n.c.p."},
    {"Id":"115","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"14","CIIU_desc":"Confección de prendas de vestir"},
    {"Id":"116","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1410","CIIU_desc":"Confección de prendas de vestir, excepto prendas de piel"},
    {"Id":"117","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1420","CIIU_desc":"Fabricación de artículos de piel"},
    {"Id":"118","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1430","CIIU_desc":"Fabricación de artículos de punto y ganchillo"},
    {"Id":"119","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"151","CIIU_desc":"Curtido y recurtido de cueros; fabricación de artículos de viaje, bolsos de mano y artículos similares, y fabricación de artículos de talabartería y guarnicionería, adobo y teñido de pieles"},
    {"Id":"120","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1511","CIIU_desc":"Curtido y recurtido de cueros; recurtido y teñido de pieles"},
    {"Id":"121","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1512","CIIU_desc":"Fabricación de artículos de viaje, bolsos de mano y artículos similares elaborados en cuero, y fabricación de artículos de talabartería y guarnicionería"},
    {"Id":"122","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1513","CIIU_desc":"Fabricación de artículos de viaje, bolsos de mano y artículos similares; artículos de talabartería y guarnicionería elaborados en otros materiales"},
    {"Id":"123","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"152","CIIU_desc":"Fabricación de calzado"},
    {"Id":"124","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1521","CIIU_desc":"Fabricación de calzado de cuero y piel, con cualquier tipo de suela"},
    {"Id":"125","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1522","CIIU_desc":"Fabricación de otros tipos de calzado, excepto calzado de cuero y piel"},
    {"Id":"126","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1523","CIIU_desc":"Fabricación de partes del calzado"},
    {"Id":"127","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"16","CIIU_desc":"Transformación de la madera y fabricación de productos de madera y de corcho, excepto muebles; fabricación de artículos de cestería y espartería"},
    {"Id":"128","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1610","CIIU_desc":"Aserrado, acepillado e impregnación de la madera"},
    {"Id":"129","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1620","CIIU_desc":"Fabricación de hojas de madera para enchapado; fabricación de tableros contrachapados, tableros laminados, tableros de partículas y otros tableros y paneles"},
    {"Id":"130","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1630","CIIU_desc":"Fabricación de partes y piezas de madera, de carpintería y ebanistería para la construcción"},
    {"Id":"131","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1640","CIIU_desc":"Fabricación de recipientes de madera"},
    {"Id":"132","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1690","CIIU_desc":"Fabricación de otros productos de madera; fabricación de artículos de corcho, cestería y espartería"},
    {"Id":"133","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"17","CIIU_desc":"Fabricación de papel, cartón y productos de papel y cartón"},
    {"Id":"134","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"170","CIIU_desc":"Fabricación de papel, cartón y productos de papel y cartón"},
    {"Id":"135","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1701","CIIU_desc":"Fabricación de pulpas (pastas) celulósicas; papel y cartón"},
    {"Id":"136","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1702","CIIU_desc":"Fabricación de papel y cartón ondulado (corrugado); fabricación de envases, empaques y de embalajes de papel y cartón."},
    {"Id":"137","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1709","CIIU_desc":"Fabricación de otros artículos de papel y cartón"},
    {"Id":"138","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"18","CIIU_desc":"Actividades de impresión y de producción de copias a partir de grabaciones originales "},
    {"Id":"139","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"181","CIIU_desc":"Actividades de impresión y actividades de servicios relacionados con la impresión"},
    {"Id":"140","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1811","CIIU_desc":"Actividades de impresión"},
    {"Id":"141","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1812","CIIU_desc":"Actividades de servicios relacionados con la impresión"},
    {"Id":"142","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1820","CIIU_desc":"Producción de copias a partir de grabaciones originales "},
    {"Id":"143","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"19","CIIU_desc":"Coquización, fabricación de productos de la refinación del petróleo y actividad de mezcla de combustibles "},
    {"Id":"144","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1910","CIIU_desc":"Fabricación de productos de hornos de coque"},
    {"Id":"145","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"192","CIIU_desc":"Fabricación de productos de la refinación del petróleo"},
    {"Id":"146","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1921","CIIU_desc":"Fabricación de productos de la refinación del petróleo"},
    {"Id":"147","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"1922","CIIU_desc":"Actividad de mezcla de combustibles"},
    {"Id":"148","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"20","CIIU_desc":"Fabricación de sustancias y productos químicos"},
    {"Id":"149","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"201","CIIU_desc":"Fabricación de sustancias químicas básicas, abonos y compuestos inorgánicos nitrogenados, plásticos y caucho sintético en formas primarias"},
    {"Id":"150","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2011","CIIU_desc":"Fabricación de sustancias y productos químicos básicos"},
    {"Id":"151","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2012","CIIU_desc":"Fabricación de abonos y compuestos inorgánicos nitrogenados"},
    {"Id":"152","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2013","CIIU_desc":"Fabricación de plásticos en formas primarias"},
    {"Id":"153","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2014","CIIU_desc":"Fabricación de caucho sintético en formas primarias"},
    {"Id":"154","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"202","CIIU_desc":"Fabricación de otros productos químicos"},
    {"Id":"155","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2021","CIIU_desc":"Fabricación de plaguicidas y otros productos químicos de uso agropecuario"},
    {"Id":"156","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2022","CIIU_desc":"Fabricación de pinturas, barnices y revestimientos similares, tintas para impresión y masillas"},
    {"Id":"157","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2023","CIIU_desc":"Fabricación de jabones y detergentes, preparados para limpiar y pulir; perfumes y preparados de tocador"},
    {"Id":"158","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2029","CIIU_desc":"Fabricación de otros productos químicos n.c.p."},
    {"Id":"159","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2030","CIIU_desc":"Fabricación de fibras sintéticas y artificiales"},
    {"Id":"160","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"21","CIIU_desc":"Fabricación de productos farmacéuticos, sustancias químicas medicinales y productos botánicos de uso farmacéutico"},
    {"Id":"161","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2100","CIIU_desc":"Fabricación de productos farmacéuticos, sustancias químicas medicinales y productos botánicos de uso farmacéutico"},
    {"Id":"162","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"22","CIIU_desc":"Fabricación de productos de caucho y de plástico"},
    {"Id":"163","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"221","CIIU_desc":"Fabricación de productos de caucho"},
    {"Id":"164","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2211","CIIU_desc":"Fabricación de llantas y neumáticos de caucho"},
    {"Id":"165","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2212","CIIU_desc":"Reencauche de llantas usadas"},
    {"Id":"166","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2219","CIIU_desc":"Fabricación de formas básicas de caucho y otros productos de caucho n.c.p."},
    {"Id":"167","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"222","CIIU_desc":"Fabricación de productos de plástico"},
    {"Id":"168","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2221","CIIU_desc":"Fabricación de formas básicas de plástico"},
    {"Id":"169","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2229","CIIU_desc":"Fabricación de artículos de plástico n.c.p."},
    {"Id":"170","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"23","CIIU_desc":"Fabricación de otros productos minerales no metálicos"},
    {"Id":"171","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2310","CIIU_desc":"Fabricación de vidrio y productos de vidrio"},
    {"Id":"172","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"239","CIIU_desc":"Fabricación de productos minerales no metálicos n.c.p."},
    {"Id":"173","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2391","CIIU_desc":"Fabricación de productos refractarios"},
    {"Id":"174","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2392","CIIU_desc":"Fabricación de materiales de arcilla para la construcción"},
    {"Id":"175","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2393","CIIU_desc":"Fabricación de otros productos de cerámica y porcelana"},
    {"Id":"176","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2394","CIIU_desc":"Fabricación de cemento, cal y yeso"},
    {"Id":"177","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2395","CIIU_desc":"Fabricación de artículos de hormigón, cemento y yeso"},
    {"Id":"178","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2396","CIIU_desc":"Corte, tallado y acabado de la piedra"},
    {"Id":"179","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2399","CIIU_desc":"Fabricación de otros productos minerales no metálicos n.c.p."},
    {"Id":"180","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"24","CIIU_desc":"Fabricación de productos metalúrgicos básicos"},
    {"Id":"181","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2410","CIIU_desc":"Industrias básicas de hierro y de acero"},
    {"Id":"182","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"242","CIIU_desc":"Industrias básicas de metales preciosos y de metales no ferrosos"},
    {"Id":"183","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2421","CIIU_desc":"Industrias básicas de metales preciosos"},
    {"Id":"184","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2429","CIIU_desc":"Industrias básicas de otros metales no ferrosos"},
    {"Id":"185","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"243","CIIU_desc":"Fundición de metales"},
    {"Id":"186","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2431","CIIU_desc":"Fundición de hierro y de acero"},
    {"Id":"187","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2432","CIIU_desc":"Fundición de metales no ferrosos "},
    {"Id":"188","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"25","CIIU_desc":"Fabricación de productos elaborados de metal, excepto maquinaria y equipo"},
    {"Id":"189","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"251","CIIU_desc":"Fabricación de productos metálicos para uso estructural, tanques, depósitos y generadores de vapor"},
    {"Id":"190","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2511","CIIU_desc":"Fabricación de productos metálicos para uso estructural"},
    {"Id":"191","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2512","CIIU_desc":"Fabricación de tanques, depósitos y recipientes de metal, excepto los utilizados para el envase o transporte de mercancías"},
    {"Id":"192","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2513","CIIU_desc":"Fabricación de generadores de vapor, excepto calderas de agua caliente para calefacción central"},
    {"Id":"193","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2520","CIIU_desc":"Fabricación de armas y municiones"},
    {"Id":"194","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"259","CIIU_desc":"Fabricación de otros productos elaborados de metal y actividades de servicios relacionadas con el trabajo de metales"},
    {"Id":"195","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2591","CIIU_desc":"Forja, prensado, estampado y laminado de metal; pulvimetalurgia"},
    {"Id":"196","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2592","CIIU_desc":"Tratamiento y revestimiento de metales; mecanizado"},
    {"Id":"197","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2593","CIIU_desc":"Fabricación de artículos de cuchillería, herramientas de mano y artículos de ferretería"},
    {"Id":"198","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2599","CIIU_desc":"Fabricación de otros productos elaborados de metal n.c.p."},
    {"Id":"199","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"26","CIIU_desc":"Fabricación de productos informáticos, electrónicos y ópticos"},
    {"Id":"200","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2610","CIIU_desc":"Fabricación de componentes y tableros electrónicos"},
    {"Id":"201","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2620","CIIU_desc":"Fabricación de computadoras y de equipo periférico"},
    {"Id":"202","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2630","CIIU_desc":"Fabricación de equipos de comunicación"},
    {"Id":"203","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2640","CIIU_desc":"Fabricación de aparatos electrónicos de consumo"},
    {"Id":"204","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"265","CIIU_desc":"Fabricación de equipo de medición, prueba, navegación y control; fabricación de relojes"},
    {"Id":"205","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2651","CIIU_desc":"Fabricación de equipo de medición, prueba, navegación y control"},
    {"Id":"206","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2652","CIIU_desc":"Fabricación de relojes"},
    {"Id":"207","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2660","CIIU_desc":"Fabricación de equipo de irradiación y equipo electrónico de uso médico y terapéutico"},
    {"Id":"208","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2670","CIIU_desc":"Fabricación de instrumentos ópticos y equipo fotográfico"},
    {"Id":"209","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2680","CIIU_desc":"Fabricación de medios magnéticos y ópticos para almacenamiento de datos"},
    {"Id":"210","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"27","CIIU_desc":"Fabricación de aparatos y equipo eléctrico"},
    {"Id":"211","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"271","CIIU_desc":"Fabricación de motores, generadores y transformadores eléctricos y de aparatos de distribución y control de la energía eléctrica"},
    {"Id":"212","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2711","CIIU_desc":"Fabricación de motores, generadores y transformadores eléctricos"},
    {"Id":"213","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2712","CIIU_desc":"Fabricación de aparatos de distribución y control de la energía eléctrica"},
    {"Id":"214","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2720","CIIU_desc":"Fabricación de pilas, baterías y acumuladores eléctricos"},
    {"Id":"215","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"273","CIIU_desc":"Fabricación de hilos y cables aislados y sus dispositivos"},
    {"Id":"216","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2731","CIIU_desc":"Fabricación de hilos y cables eléctricos y de fibra óptica"},
    {"Id":"217","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2732","CIIU_desc":"Fabricación de dispositivos de cableado"},
    {"Id":"218","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2740","CIIU_desc":"Fabricación de equipos eléctricos de iluminación"},
    {"Id":"219","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2750","CIIU_desc":"Fabricación de aparatos de uso doméstico"},
    {"Id":"220","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2790","CIIU_desc":"Fabricación de otros tipos de equipo eléctrico n.c.p."},
    {"Id":"221","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"28","CIIU_desc":"Fabricación de maquinaria y equipo n.c.p."},
    {"Id":"222","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"281","CIIU_desc":"Fabricación de maquinaria y equipo de uso general"},
    {"Id":"223","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2811","CIIU_desc":"Fabricación de motores, turbinas, y partes para motores de combustión interna"},
    {"Id":"224","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2812","CIIU_desc":"Fabricación de equipos de potencia hidráulica y neumática"},
    {"Id":"225","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2813","CIIU_desc":"Fabricación de otras bombas, compresores, grifos y válvulas"},
    {"Id":"226","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2814","CIIU_desc":"Fabricación de cojinetes, engranajes, trenes de engranajes y piezas de transmisión"},
    {"Id":"227","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2815","CIIU_desc":"Fabricación de hornos, hogares y quemadores industriales"},
    {"Id":"228","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2816","CIIU_desc":"Fabricación de equipo de elevación y manipulación"},
    {"Id":"229","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2817","CIIU_desc":"Fabricación de maquinaria y equipo de oficina (excepto computadoras y equipo periférico)"},
    {"Id":"230","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2818","CIIU_desc":"Fabricación de herramientas manuales con motor"},
    {"Id":"231","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2819","CIIU_desc":"Fabricación de otros tipos de maquinaria y equipo de uso general n.c.p."},
    {"Id":"232","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"282","CIIU_desc":"Fabricación de maquinaria y equipo de uso especial"},
    {"Id":"233","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2821","CIIU_desc":"Fabricación de maquinaria agropecuaria y forestal"},
    {"Id":"234","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2822","CIIU_desc":"Fabricación de máquinas formadoras de metal y de máquinas herramienta"},
    {"Id":"235","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2823","CIIU_desc":"Fabricación de maquinaria para la metalurgia"},
    {"Id":"236","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2824","CIIU_desc":"Fabricación de maquinaria para explotación de minas y canteras y para obras de construcción"},
    {"Id":"237","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2825","CIIU_desc":"Fabricación de maquinaria para la elaboración de alimentos, bebidas y tabaco"},
    {"Id":"238","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2826","CIIU_desc":"Fabricación de maquinaria para la elaboración de productos textiles, prendas de vestir y cueros"},
    {"Id":"239","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2829","CIIU_desc":"Fabricación de otros tipos de maquinaria y equipo de uso especial n.c.p."},
    {"Id":"240","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"29","CIIU_desc":"Fabricación de vehículos automotores, remolques y semirremolques"},
    {"Id":"241","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2910","CIIU_desc":"Fabricación de vehículos automotores y sus motores"},
    {"Id":"242","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2920","CIIU_desc":"Fabricación de carrocerías para vehículos automotores; fabricación de remolques y semirremolques "},
    {"Id":"243","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"2930","CIIU_desc":"Fabricación de partes, piezas (autopartes) y accesorios (lujos) para vehículos automotores"},
    {"Id":"244","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"30","CIIU_desc":"Fabricación de otros tipos de equipo de transporte"},
    {"Id":"245","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"301","CIIU_desc":"Construcción de barcos y otras embarcaciones"},
    {"Id":"246","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"3011","CIIU_desc":"Construcción de barcos y de estructuras flotantes"},
    {"Id":"247","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"3012","CIIU_desc":"Construcción de embarcaciones de recreo y deporte"},
    {"Id":"248","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"3020","CIIU_desc":"Fabricación de locomotoras y de material rodante para ferrocarriles"},
    {"Id":"249","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"3030","CIIU_desc":"Fabricación de aeronaves, naves espaciales y de maquinaria conexa"},
    {"Id":"250","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"3040","CIIU_desc":"Fabricación de vehículos militares de combate"},
    {"Id":"251","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"309","CIIU_desc":"Fabricación de otros tipos de equipo de transporte n.c.p."},
    {"Id":"252","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"3091","CIIU_desc":"Fabricación de motocicletas"},
    {"Id":"253","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"3092","CIIU_desc":"Fabricación de bicicletas y de sillas de ruedas para personas con discapacidad"},
    {"Id":"254","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"3099","CIIU_desc":"Fabricación de otros tipos de equipo de transporte n.c.p."},
    {"Id":"255","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"31","CIIU_desc":"Fabricación de muebles, colchones y somieres"},
    {"Id":"256","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"3110","CIIU_desc":"Fabricación de muebles "},
    {"Id":"257","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"3120","CIIU_desc":"Fabricación de colchones y somieres"},
    {"Id":"258","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"32","CIIU_desc":"Otras industrias manufactureras"},
    {"Id":"259","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"3210","CIIU_desc":"Fabricación de joyas, bisutería y artículos conexos"},
    {"Id":"260","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"3220","CIIU_desc":"Fabricación de instrumentos musicales"},
    {"Id":"261","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"3230","CIIU_desc":"Fabricación de artículos y equipo para la práctica del deporte"},
    {"Id":"262","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"3240","CIIU_desc":"Fabricación de juegos, juguetes y rompecabezas"},
    {"Id":"263","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"3250","CIIU_desc":"Fabricación de instrumentos, aparatos y materiales médicos y odontológicos (incluido mobiliario)"},
    {"Id":"264","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"3290","CIIU_desc":"Otras industrias manufactureras n.c.p."},
    {"Id":"265","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"33","CIIU_desc":"Instalación, mantenimiento y reparación especializado de maquinaria y equipo"},
    {"Id":"266","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"331","CIIU_desc":"Mantenimiento y reparación especializado de productos elaborados en metal y de maquinaria y equipo"},
    {"Id":"267","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"3311","CIIU_desc":"Mantenimiento y reparación especializado de productos elaborados en metal"},
    {"Id":"268","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"3312","CIIU_desc":"Mantenimiento y reparación especializado de maquinaria y equipo"},
    {"Id":"269","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"3313","CIIU_desc":"Mantenimiento y reparación especializado de equipo electrónico y óptico"},
    {"Id":"270","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"3314","CIIU_desc":"Mantenimiento y reparación especializado de equipo eléctrico"},
    {"Id":"271","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"3315","CIIU_desc":"Mantenimiento y reparación especializado de equipo de transporte, excepto los vehículos automotores, motocicletas y bicicletas"},
    {"Id":"272","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"3319","CIIU_desc":"Mantenimiento y reparación de otros tipos de equipos y sus componentes n.c.p."},
    {"Id":"273","section":"SECCIÓN C","section_desc":"INDUSTRIAS MANUFACTURERAS","CIIU":"3320","CIIU_desc":"Instalación especializada de maquinaria y equipo industrial "},
    {"Id":"274","section":"SECCIÓN D","section_desc":"SUMINISTRO DE ELECTRICIDAD, GAS, VAPOR Y AIRE ACONDICIONADO","CIIU":"35","CIIU_desc":"Suministro de electricidad, gas, vapor y aire acondicionado "},
    {"Id":"275","section":"SECCIÓN D","section_desc":"SUMINISTRO DE ELECTRICIDAD, GAS, VAPOR Y AIRE ACONDICIONADO","CIIU":"351","CIIU_desc":"Generación, transmisión, distribución y comercialización de energía eléctrica"},
    {"Id":"276","section":"SECCIÓN D","section_desc":"SUMINISTRO DE ELECTRICIDAD, GAS, VAPOR Y AIRE ACONDICIONADO","CIIU":"3511","CIIU_desc":"Generación de energía eléctrica"},
    {"Id":"277","section":"SECCIÓN D","section_desc":"SUMINISTRO DE ELECTRICIDAD, GAS, VAPOR Y AIRE ACONDICIONADO","CIIU":"3512","CIIU_desc":"Transmisión de energía eléctrica"},
    {"Id":"278","section":"SECCIÓN D","section_desc":"SUMINISTRO DE ELECTRICIDAD, GAS, VAPOR Y AIRE ACONDICIONADO","CIIU":"3513","CIIU_desc":"Distribución de energía eléctrica"},
    {"Id":"279","section":"SECCIÓN D","section_desc":"SUMINISTRO DE ELECTRICIDAD, GAS, VAPOR Y AIRE ACONDICIONADO","CIIU":"3514","CIIU_desc":"Comercialización de energía eléctrica"},
    {"Id":"280","section":"SECCIÓN D","section_desc":"SUMINISTRO DE ELECTRICIDAD, GAS, VAPOR Y AIRE ACONDICIONADO","CIIU":"3520","CIIU_desc":"Producción de gas; distribución de combustibles gaseosos por tuberías"},
    {"Id":"281","section":"SECCIÓN D","section_desc":"SUMINISTRO DE ELECTRICIDAD, GAS, VAPOR Y AIRE ACONDICIONADO","CIIU":"3530","CIIU_desc":"Suministro de vapor y aire acondicionado"},
    {"Id":"282","section":"SECCIÓN E","section_desc":"DISTRIBUCIÓN DE AGUA; EVACUACIÓN Y TRATAMIENTO DE AGUAS RESIDUALES, GESTIÓN DE DESECHOS Y ACTIVIDADES DE SANEAMIENTO AMBIENTAL","CIIU":"36","CIIU_desc":"Captación, tratamiento y distribución de agua"},
    {"Id":"283","section":"SECCIÓN E","section_desc":"DISTRIBUCIÓN DE AGUA; EVACUACIÓN Y TRATAMIENTO DE AGUAS RESIDUALES, GESTIÓN DE DESECHOS Y ACTIVIDADES DE SANEAMIENTO AMBIENTAL","CIIU":"3600","CIIU_desc":"Captación, tratamiento y distribución de agua"},
    {"Id":"284","section":"SECCIÓN E","section_desc":"DISTRIBUCIÓN DE AGUA; EVACUACIÓN Y TRATAMIENTO DE AGUAS RESIDUALES, GESTIÓN DE DESECHOS Y ACTIVIDADES DE SANEAMIENTO AMBIENTAL","CIIU":"37","CIIU_desc":"Evacuación y tratamiento de aguas residuales"},
    {"Id":"285","section":"SECCIÓN E","section_desc":"DISTRIBUCIÓN DE AGUA; EVACUACIÓN Y TRATAMIENTO DE AGUAS RESIDUALES, GESTIÓN DE DESECHOS Y ACTIVIDADES DE SANEAMIENTO AMBIENTAL","CIIU":"3700","CIIU_desc":"Evacuación y tratamiento de aguas residuales"},
    {"Id":"286","section":"SECCIÓN E","section_desc":"DISTRIBUCIÓN DE AGUA; EVACUACIÓN Y TRATAMIENTO DE AGUAS RESIDUALES, GESTIÓN DE DESECHOS Y ACTIVIDADES DE SANEAMIENTO AMBIENTAL","CIIU":"38","CIIU_desc":"Recolección, tratamiento y disposición de desechos, recuperación de materiales"},
    {"Id":"287","section":"SECCIÓN E","section_desc":"DISTRIBUCIÓN DE AGUA; EVACUACIÓN Y TRATAMIENTO DE AGUAS RESIDUALES, GESTIÓN DE DESECHOS Y ACTIVIDADES DE SANEAMIENTO AMBIENTAL","CIIU":"381","CIIU_desc":"Recolección de desechos"},
    {"Id":"288","section":"SECCIÓN E","section_desc":"DISTRIBUCIÓN DE AGUA; EVACUACIÓN Y TRATAMIENTO DE AGUAS RESIDUALES, GESTIÓN DE DESECHOS Y ACTIVIDADES DE SANEAMIENTO AMBIENTAL","CIIU":"3811","CIIU_desc":"Recolección de desechos no peligrosos"},
    {"Id":"289","section":"SECCIÓN E","section_desc":"DISTRIBUCIÓN DE AGUA; EVACUACIÓN Y TRATAMIENTO DE AGUAS RESIDUALES, GESTIÓN DE DESECHOS Y ACTIVIDADES DE SANEAMIENTO AMBIENTAL","CIIU":"3812","CIIU_desc":"Recolección de desechos peligrosos"},
    {"Id":"290","section":"SECCIÓN E","section_desc":"DISTRIBUCIÓN DE AGUA; EVACUACIÓN Y TRATAMIENTO DE AGUAS RESIDUALES, GESTIÓN DE DESECHOS Y ACTIVIDADES DE SANEAMIENTO AMBIENTAL","CIIU":"382","CIIU_desc":"Tratamiento y disposición de desechos"},
    {"Id":"291","section":"SECCIÓN E","section_desc":"DISTRIBUCIÓN DE AGUA; EVACUACIÓN Y TRATAMIENTO DE AGUAS RESIDUALES, GESTIÓN DE DESECHOS Y ACTIVIDADES DE SANEAMIENTO AMBIENTAL","CIIU":"3821","CIIU_desc":"Tratamiento y disposición de desechos no peligrosos"},
    {"Id":"292","section":"SECCIÓN E","section_desc":"DISTRIBUCIÓN DE AGUA; EVACUACIÓN Y TRATAMIENTO DE AGUAS RESIDUALES, GESTIÓN DE DESECHOS Y ACTIVIDADES DE SANEAMIENTO AMBIENTAL","CIIU":"3822","CIIU_desc":"Tratamiento y disposición de desechos peligrosos"},
    {"Id":"293","section":"SECCIÓN E","section_desc":"DISTRIBUCIÓN DE AGUA; EVACUACIÓN Y TRATAMIENTO DE AGUAS RESIDUALES, GESTIÓN DE DESECHOS Y ACTIVIDADES DE SANEAMIENTO AMBIENTAL","CIIU":"3830","CIIU_desc":"Recuperación de materiales"},
    {"Id":"294","section":"SECCIÓN E","section_desc":"DISTRIBUCIÓN DE AGUA; EVACUACIÓN Y TRATAMIENTO DE AGUAS RESIDUALES, GESTIÓN DE DESECHOS Y ACTIVIDADES DE SANEAMIENTO AMBIENTAL","CIIU":"39","CIIU_desc":"Actividades de saneamiento ambiental y otros servicios de gestión de desechos"},
    {"Id":"295","section":"SECCIÓN E","section_desc":"DISTRIBUCIÓN DE AGUA; EVACUACIÓN Y TRATAMIENTO DE AGUAS RESIDUALES, GESTIÓN DE DESECHOS Y ACTIVIDADES DE SANEAMIENTO AMBIENTAL","CIIU":"3900","CIIU_desc":"Actividades de saneamiento ambiental y otros servicios de gestión de desechos"},
    {"Id":"296","section":"SECCIÓN F","section_desc":"CONSTRUCCIÓN","CIIU":"41","CIIU_desc":"Construcción de edificios"},
    {"Id":"297","section":"SECCIÓN F","section_desc":"CONSTRUCCIÓN","CIIU":"411","CIIU_desc":"Construcción de edificios"},
    {"Id":"298","section":"SECCIÓN F","section_desc":"CONSTRUCCIÓN","CIIU":"4111","CIIU_desc":"Construcción de edificios residenciales"},
    {"Id":"299","section":"SECCIÓN F","section_desc":"CONSTRUCCIÓN","CIIU":"4112","CIIU_desc":"Construcción de edificios no residenciales"},
    {"Id":"300","section":"SECCIÓN F","section_desc":"CONSTRUCCIÓN","CIIU":"42","CIIU_desc":"Obras de ingeniería civil"},
    {"Id":"301","section":"SECCIÓN F","section_desc":"CONSTRUCCIÓN","CIIU":"4210","CIIU_desc":"Construcción de carreteras y vías de ferrocarril"},
    {"Id":"302","section":"SECCIÓN F","section_desc":"CONSTRUCCIÓN","CIIU":"4220","CIIU_desc":"Construcción de proyectos de servicio público"},
    {"Id":"303","section":"SECCIÓN F","section_desc":"CONSTRUCCIÓN","CIIU":"4290","CIIU_desc":"Construcción de otras obras de ingeniería civil"},
    {"Id":"304","section":"SECCIÓN F","section_desc":"CONSTRUCCIÓN","CIIU":"43","CIIU_desc":"Actividades especializadas para la construcción de edificios y obras de ingeniería civil"},
    {"Id":"305","section":"SECCIÓN F","section_desc":"CONSTRUCCIÓN","CIIU":"431","CIIU_desc":"Demolición y preparación del terreno"},
    {"Id":"306","section":"SECCIÓN F","section_desc":"CONSTRUCCIÓN","CIIU":"4311","CIIU_desc":"Demolición"},
    {"Id":"307","section":"SECCIÓN F","section_desc":"CONSTRUCCIÓN","CIIU":"4312","CIIU_desc":"Preparación del terreno"},
    {"Id":"308","section":"SECCIÓN F","section_desc":"CONSTRUCCIÓN","CIIU":"432","CIIU_desc":"Instalaciones eléctricas, de fontanería y otras instalaciones especializadas"},
    {"Id":"309","section":"SECCIÓN F","section_desc":"CONSTRUCCIÓN","CIIU":"4321","CIIU_desc":"Instalaciones eléctricas"},
    {"Id":"310","section":"SECCIÓN F","section_desc":"CONSTRUCCIÓN","CIIU":"4322","CIIU_desc":"Instalaciones de fontanería, calefacción y aire acondicionado"},
    {"Id":"311","section":"SECCIÓN F","section_desc":"CONSTRUCCIÓN","CIIU":"4329","CIIU_desc":"Otras instalaciones especializadas"},
    {"Id":"312","section":"SECCIÓN F","section_desc":"CONSTRUCCIÓN","CIIU":"4330","CIIU_desc":"Terminación y acabado de edificios y obras de ingeniería civil"},
    {"Id":"313","section":"SECCIÓN F","section_desc":"CONSTRUCCIÓN","CIIU":"4390","CIIU_desc":"Otras actividades especializadas para la construcción de edificios y obras de ingeniería civil"},
    {"Id":"314","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"45","CIIU_desc":"Comercio, mantenimiento y reparación de vehículos automotores y motocicletas, sus partes, piezas y accesorios"},
    {"Id":"315","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"451","CIIU_desc":"Comercio de vehículos automotores"},
    {"Id":"316","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4511","CIIU_desc":"Comercio de vehículos automotores nuevos"},
    {"Id":"317","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4512","CIIU_desc":"Comercio de vehículos automotores usados"},
    {"Id":"318","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4520","CIIU_desc":"Mantenimiento y reparación de vehículos automotores"},
    {"Id":"319","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4530","CIIU_desc":"Comercio de partes, piezas (autopartes) y accesorios (lujos) para vehículos automotores"},
    {"Id":"320","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"454","CIIU_desc":"Comercio, mantenimiento y reparación de motocicletas y de sus partes, piezas y accesorios"},
    {"Id":"321","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4541","CIIU_desc":"Comercio de motocicletas y de sus partes, piezas y accesorios"},
    {"Id":"322","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4542","CIIU_desc":"Mantenimiento y reparación de motocicletas y de sus partes y piezas"},
    {"Id":"323","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"46","CIIU_desc":"Comercio al por mayor y en comisión o por contrata, excepto el comercio de vehículos automotores y motocicletas"},
    {"Id":"324","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4610","CIIU_desc":"Comercio al por mayor a cambio de una retribución o por contrata"},
    {"Id":"325","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4620","CIIU_desc":"Comercio al por mayor de materias primas agropecuarias; animales vivos"},
    {"Id":"326","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"463","CIIU_desc":"Comercio al por mayor de alimentos, bebidas y tabaco"},
    {"Id":"327","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4631","CIIU_desc":"Comercio al por mayor de productos alimenticios"},
    {"Id":"328","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4632","CIIU_desc":"Comercio al por mayor de bebidas y tabaco"},
    {"Id":"329","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"464","CIIU_desc":"Comercio al por mayor de artículos y enseres domésticos (incluidas prendas de vestir)"},
    {"Id":"330","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4641","CIIU_desc":"Comercio al por mayor de productos textiles, productos confeccionados para uso doméstico"},
    {"Id":"331","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4642","CIIU_desc":"Comercio al por mayor de prendas de vestir"},
    {"Id":"332","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4643","CIIU_desc":"Comercio al por mayor de calzado"},
    {"Id":"333","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4644","CIIU_desc":"Comercio al por mayor de aparatos y equipo de uso doméstico"},
    {"Id":"334","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4645","CIIU_desc":"Comercio al por mayor de productos farmacéuticos, medicinales, cosméticos y de tocador"},
    {"Id":"335","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4649","CIIU_desc":"Comercio al por mayor de otros utensilios domésticos n.c.p."},
    {"Id":"336","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"465","CIIU_desc":"Comercio al por mayor de maquinaria y equipo "},
    {"Id":"337","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4651","CIIU_desc":"Comercio al por mayor de computadores, equipo periférico y programas de informática"},
    {"Id":"338","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4652","CIIU_desc":"Comercio al por mayor de equipo, partes y piezas electrónicos y de telecomunicaciones"},
    {"Id":"339","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4653","CIIU_desc":"Comercio al por mayor de maquinaria y equipo agropecuarios"},
    {"Id":"340","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4659","CIIU_desc":"Comercio al por mayor de otros tipos de maquinaria y equipo n.c.p."},
    {"Id":"341","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"466","CIIU_desc":"Comercio al por mayor especializado de otros productos"},
    {"Id":"342","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4661","CIIU_desc":"Comercio al por mayor de combustibles sólidos, líquidos, gaseosos y productos conexos"},
    {"Id":"343","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4662","CIIU_desc":"Comercio al por mayor de metales y productos metalíferos"},
    {"Id":"344","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4663","CIIU_desc":"Comercio al por mayor de materiales de construcción, artículos de ferretería, pinturas, productos de vidrio, equipo y materiales de fontanería y calefacción"},
    {"Id":"345","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4664","CIIU_desc":"Comercio al por mayor de productos químicos básicos, cauchos y plásticos en formas primarias y productos químicos de uso agropecuario"},
    {"Id":"346","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4665","CIIU_desc":"Comercio al por mayor de desperdicios, desechos y chatarra"},
    {"Id":"347","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4669","CIIU_desc":"Comercio al por mayor de otros productos n.c.p."},
    {"Id":"348","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4690","CIIU_desc":"Comercio al por mayor no especializado"},
    {"Id":"349","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"47","CIIU_desc":"Comercio al por menor (incluso el comercio al por menor de combustibles), excepto el de vehículos automotores y motocicletas"},
    {"Id":"350","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"471","CIIU_desc":"Comercio al por menor en establecimientos no especializados"},
    {"Id":"351","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4711","CIIU_desc":"Comercio al por menor en establecimientos no especializados con surtido compuesto principalmente por alimentos, bebidas o tabaco"},
    {"Id":"352","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4719","CIIU_desc":"Comercio al por menor en establecimientos no especializados, con surtido compuesto principalmente por productos diferentes de alimentos (víveres en general), bebidas y tabaco"},
    {"Id":"353","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"472","CIIU_desc":"Comercio al por menor de alimentos (víveres en general), bebidas y tabaco, en establecimientos especializados"},
    {"Id":"354","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4721","CIIU_desc":"Comercio al por menor de productos agrícolas para el consumo en establecimientos especializados"},
    {"Id":"355","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4722","CIIU_desc":"Comercio al por menor de leche, productos lácteos y huevos, en establecimientos especializados"},
    {"Id":"356","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4723","CIIU_desc":"Comercio al por menor de carnes (incluye aves de corral), productos cárnicos, pescados y productos de mar, en establecimientos especializados"},
    {"Id":"357","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4724","CIIU_desc":"Comercio al por menor de bebidas y productos del tabaco, en establecimientos especializados"},
    {"Id":"358","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4729","CIIU_desc":"Comercio al por menor de otros productos alimenticios n.c.p., en establecimientos especializados"},
    {"Id":"359","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"473","CIIU_desc":"Comercio al por menor de combustible, lubricantes, aditivos y productos de limpieza para automotores, en establecimientos especializados"},
    {"Id":"360","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4731","CIIU_desc":"Comercio al por menor de combustible para automotores"},
    {"Id":"361","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4732","CIIU_desc":"Comercio al por menor de lubricantes (aceites, grasas), aditivos y productos de limpieza para vehículos automotores"},
    {"Id":"362","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"474","CIIU_desc":"Comercio al por menor de equipos de informática y de comunicaciones, en establecimientos especializados"},
    {"Id":"363","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4741","CIIU_desc":"Comercio al por menor de computadores, equipos periféricos, programas de informática y equipos de telecomunicaciones en establecimientos especializados"},
    {"Id":"364","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4742","CIIU_desc":"Comercio al por menor de equipos y aparatos de sonido y de video, en establecimientos especializados"},
    {"Id":"365","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"475","CIIU_desc":"Comercio al por menor de otros enseres domésticos en establecimientos especializados"},
    {"Id":"366","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4751","CIIU_desc":"Comercio al por menor de productos textiles en establecimientos especializados"},
    {"Id":"367","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4752","CIIU_desc":"Comercio al por menor de artículos de ferretería, pinturas y productos de vidrio en establecimientos especializados"},
    {"Id":"368","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4753","CIIU_desc":"Comercio al por menor de tapices, alfombras y cubrimientos para paredes y pisos en establecimientos especializados"},
    {"Id":"369","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4754","CIIU_desc":"Comercio al por menor de electrodomésticos y gasodomésticos de uso doméstico, muebles y equipos de iluminación"},
    {"Id":"370","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4755","CIIU_desc":"Comercio al por menor de artículos y utensilios de uso doméstico"},
    {"Id":"371","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4759","CIIU_desc":"Comercio al por menor de otros artículos domésticos en establecimientos especializados"},
    {"Id":"372","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"476","CIIU_desc":"Comercio al por menor de artículos culturales y de entretenimiento, en establecimientos especializados"},
    {"Id":"373","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4761","CIIU_desc":"Comercio al por menor de libros, periódicos, materiales y artículos de papelería y escritorio, en establecimientos especializados"},
    {"Id":"374","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4762","CIIU_desc":"Comercio al por menor de artículos deportivos, en establecimientos especializados "},
    {"Id":"375","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4769","CIIU_desc":"Comercio al por menor de otros artículos culturales y de entretenimiento n.c.p. en establecimientos especializados"},
    {"Id":"376","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"477","CIIU_desc":"Comercio al por menor de otros productos en establecimientos especializados"},
    {"Id":"377","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4771","CIIU_desc":"Comercio al por menor de prendas de vestir y sus accesorios (incluye artículos de piel) en establecimientos especializados"},
    {"Id":"378","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4772","CIIU_desc":"Comercio al por menor de todo tipo de calzado y artículos de cuero y sucedáneos del cuero en establecimientos especializados."},
    {"Id":"379","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4773","CIIU_desc":"Comercio al por menor de productos farmacéuticos y medicinales, cosméticos y artículos de tocador en establecimientos especializados"},
    {"Id":"380","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4774","CIIU_desc":"Comercio al por menor de otros productos nuevos en establecimientos especializados"},
    {"Id":"381","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4775","CIIU_desc":"Comercio al por menor de artículos de segunda mano"},
    {"Id":"382","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"478","CIIU_desc":"Comercio al por menor en puestos de venta móviles"},
    {"Id":"383","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4781","CIIU_desc":"Comercio al por menor de alimentos, bebidas y tabaco, en puestos de venta móviles"},
    {"Id":"384","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4782","CIIU_desc":"Comercio al por menor de productos textiles, prendas de vestir y calzado, en puestos de venta móviles"},
    {"Id":"385","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4789","CIIU_desc":"Comercio al por menor de otros productos en puestos de venta móviles"},
    {"Id":"386","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"479","CIIU_desc":"Comercio al por menor no realizado en establecimientos, puestos de venta o mercados"},
    {"Id":"387","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4791","CIIU_desc":"Comercio al por menor realizado a través de Internet"},
    {"Id":"388","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4792","CIIU_desc":"Comercio al por menor realizado a través de casas de venta o por correo"},
    {"Id":"389","section":"SECCIÓN G","section_desc":"COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS","CIIU":"4799","CIIU_desc":"Otros tipos de comercio al por menor no realizado en establecimientos, puestos de venta o mercados."},
    {"Id":"390","section":"SECCIÓN H","section_desc":"TRANSPORTE Y ALMACENAMIENTO","CIIU":"49","CIIU_desc":"Transporte terrestre; transporte por tuberías"},
    {"Id":"391","section":"SECCIÓN H","section_desc":"TRANSPORTE Y ALMACENAMIENTO","CIIU":"491","CIIU_desc":"Transporte férreo"},
    {"Id":"392","section":"SECCIÓN H","section_desc":"TRANSPORTE Y ALMACENAMIENTO","CIIU":"4911","CIIU_desc":"Transporte férreo de pasajeros"},
    {"Id":"393","section":"SECCIÓN H","section_desc":"TRANSPORTE Y ALMACENAMIENTO","CIIU":"4912","CIIU_desc":"Transporte férreo de carga "},
    {"Id":"394","section":"SECCIÓN H","section_desc":"TRANSPORTE Y ALMACENAMIENTO","CIIU":"492","CIIU_desc":"Transporte terrestre público automotor"},
    {"Id":"395","section":"SECCIÓN H","section_desc":"TRANSPORTE Y ALMACENAMIENTO","CIIU":"4921","CIIU_desc":"Transporte de pasajeros"},
    {"Id":"396","section":"SECCIÓN H","section_desc":"TRANSPORTE Y ALMACENAMIENTO","CIIU":"4922","CIIU_desc":"Transporte mixto"},
    {"Id":"397","section":"SECCIÓN H","section_desc":"TRANSPORTE Y ALMACENAMIENTO","CIIU":"4923","CIIU_desc":"Transporte de carga por carretera"},
    {"Id":"398","section":"SECCIÓN H","section_desc":"TRANSPORTE Y ALMACENAMIENTO","CIIU":"4930","CIIU_desc":"Transporte por tuberías"},
    {"Id":"399","section":"SECCIÓN H","section_desc":"TRANSPORTE Y ALMACENAMIENTO","CIIU":"50","CIIU_desc":"Transporte acuático"},
    {"Id":"400","section":"SECCIÓN H","section_desc":"TRANSPORTE Y ALMACENAMIENTO","CIIU":"501","CIIU_desc":"Transporte marítimo y de cabotaje"},
    {"Id":"401","section":"SECCIÓN H","section_desc":"TRANSPORTE Y ALMACENAMIENTO","CIIU":"5011","CIIU_desc":"Transporte de pasajeros marítimo y de cabotaje "},
    {"Id":"402","section":"SECCIÓN H","section_desc":"TRANSPORTE Y ALMACENAMIENTO","CIIU":"5012","CIIU_desc":"Transporte de carga marítimo y de cabotaje "},
    {"Id":"403","section":"SECCIÓN H","section_desc":"TRANSPORTE Y ALMACENAMIENTO","CIIU":"502","CIIU_desc":"Transporte fluvial"},
    {"Id":"404","section":"SECCIÓN H","section_desc":"TRANSPORTE Y ALMACENAMIENTO","CIIU":"5021","CIIU_desc":"Transporte fluvial de pasajeros"},
    {"Id":"405","section":"SECCIÓN H","section_desc":"TRANSPORTE Y ALMACENAMIENTO","CIIU":"5022","CIIU_desc":"Transporte fluvial de carga"},
    {"Id":"406","section":"SECCIÓN H","section_desc":"TRANSPORTE Y ALMACENAMIENTO","CIIU":"51","CIIU_desc":"Transporte aéreo"},
    {"Id":"407","section":"SECCIÓN H","section_desc":"TRANSPORTE Y ALMACENAMIENTO","CIIU":"511","CIIU_desc":"Transporte aéreo de pasajeros "},
    {"Id":"408","section":"SECCIÓN H","section_desc":"TRANSPORTE Y ALMACENAMIENTO","CIIU":"5111","CIIU_desc":"Transporte aéreo nacional de pasajeros "},
    {"Id":"409","section":"SECCIÓN H","section_desc":"TRANSPORTE Y ALMACENAMIENTO","CIIU":"5112","CIIU_desc":"Transporte aéreo internacional de pasajeros "},
    {"Id":"410","section":"SECCIÓN H","section_desc":"TRANSPORTE Y ALMACENAMIENTO","CIIU":"512","CIIU_desc":"Transporte aéreo de carga "},
    {"Id":"411","section":"SECCIÓN H","section_desc":"TRANSPORTE Y ALMACENAMIENTO","CIIU":"5121","CIIU_desc":"Transporte aéreo nacional de carga "},
    {"Id":"412","section":"SECCIÓN H","section_desc":"TRANSPORTE Y ALMACENAMIENTO","CIIU":"5122","CIIU_desc":"Transporte aéreo internacional de carga "},
    {"Id":"413","section":"SECCIÓN H","section_desc":"TRANSPORTE Y ALMACENAMIENTO","CIIU":"52","CIIU_desc":"Almacenamiento y actividades complementarias al transporte"},
    {"Id":"414","section":"SECCIÓN H","section_desc":"TRANSPORTE Y ALMACENAMIENTO","CIIU":"5210","CIIU_desc":"Almacenamiento y depósito"},
    {"Id":"415","section":"SECCIÓN H","section_desc":"TRANSPORTE Y ALMACENAMIENTO","CIIU":"522","CIIU_desc":"Actividades de las estaciones, vías y servicios complementarios para el transporte"},
    {"Id":"416","section":"SECCIÓN H","section_desc":"TRANSPORTE Y ALMACENAMIENTO","CIIU":"5221","CIIU_desc":"Actividades de estaciones, vías y servicios complementarios para el transporte terrestre"},
    {"Id":"417","section":"SECCIÓN H","section_desc":"TRANSPORTE Y ALMACENAMIENTO","CIIU":"5222","CIIU_desc":"Actividades de puertos y servicios complementarios para el transporte acuático"},
    {"Id":"418","section":"SECCIÓN H","section_desc":"TRANSPORTE Y ALMACENAMIENTO","CIIU":"5223","CIIU_desc":"Actividades de aeropuertos, servicios de navegación aérea y demás actividades conexas al transporte aéreo"},
    {"Id":"419","section":"SECCIÓN H","section_desc":"TRANSPORTE Y ALMACENAMIENTO","CIIU":"5224","CIIU_desc":"Manipulación de carga"},
    {"Id":"420","section":"SECCIÓN H","section_desc":"TRANSPORTE Y ALMACENAMIENTO","CIIU":"5229","CIIU_desc":"Otras actividades complementarias al transporte"},
    {"Id":"421","section":"SECCIÓN H","section_desc":"TRANSPORTE Y ALMACENAMIENTO","CIIU":"53","CIIU_desc":"Correo y servicios de mensajería"},
    {"Id":"422","section":"SECCIÓN H","section_desc":"TRANSPORTE Y ALMACENAMIENTO","CIIU":"5310","CIIU_desc":"Actividades postales nacionales"},
    {"Id":"423","section":"SECCIÓN H","section_desc":"TRANSPORTE Y ALMACENAMIENTO","CIIU":"5320","CIIU_desc":"Actividades de mensajería"},
    {"Id":"424","section":"SECCIÓN I","section_desc":"ALOJAMIENTO Y SERVICIOS DE COMIDA","CIIU":"55","CIIU_desc":"Alojamiento"},
    {"Id":"425","section":"SECCIÓN I","section_desc":"ALOJAMIENTO Y SERVICIOS DE COMIDA","CIIU":"551","CIIU_desc":"Actividades de alojamiento de estancias cortas"},
    {"Id":"426","section":"SECCIÓN I","section_desc":"ALOJAMIENTO Y SERVICIOS DE COMIDA","CIIU":"5511","CIIU_desc":"Alojamiento en hoteles "},
    {"Id":"427","section":"SECCIÓN I","section_desc":"ALOJAMIENTO Y SERVICIOS DE COMIDA","CIIU":"5512","CIIU_desc":"Alojamiento en apartahoteles"},
    {"Id":"428","section":"SECCIÓN I","section_desc":"ALOJAMIENTO Y SERVICIOS DE COMIDA","CIIU":"5513","CIIU_desc":"Alojamiento en centros vacacionales "},
    {"Id":"429","section":"SECCIÓN I","section_desc":"ALOJAMIENTO Y SERVICIOS DE COMIDA","CIIU":"5514","CIIU_desc":"Alojamiento rural"},
    {"Id":"430","section":"SECCIÓN I","section_desc":"ALOJAMIENTO Y SERVICIOS DE COMIDA","CIIU":"5519","CIIU_desc":"Otros tipos de alojamientos para visitantes"},
    {"Id":"431","section":"SECCIÓN I","section_desc":"ALOJAMIENTO Y SERVICIOS DE COMIDA","CIIU":"5520","CIIU_desc":"Actividades de zonas de camping y parques para vehículos recreacionales"},
    {"Id":"432","section":"SECCIÓN I","section_desc":"ALOJAMIENTO Y SERVICIOS DE COMIDA","CIIU":"5530","CIIU_desc":"Servicio por horas "},
    {"Id":"433","section":"SECCIÓN I","section_desc":"ALOJAMIENTO Y SERVICIOS DE COMIDA","CIIU":"5590","CIIU_desc":"Otros tipos de alojamiento n.c.p."},
    {"Id":"434","section":"SECCIÓN I","section_desc":"ALOJAMIENTO Y SERVICIOS DE COMIDA","CIIU":"56","CIIU_desc":"Actividades de servicios de comidas y bebidas"},
    {"Id":"435","section":"SECCIÓN I","section_desc":"ALOJAMIENTO Y SERVICIOS DE COMIDA","CIIU":"561","CIIU_desc":"Actividades de restaurantes, cafeterías y servicio móvil de comidas"},
    {"Id":"436","section":"SECCIÓN I","section_desc":"ALOJAMIENTO Y SERVICIOS DE COMIDA","CIIU":"5611","CIIU_desc":"Expendio a la mesa de comidas preparadas"},
    {"Id":"437","section":"SECCIÓN I","section_desc":"ALOJAMIENTO Y SERVICIOS DE COMIDA","CIIU":"5612","CIIU_desc":"Expendio por autoservicio de comidas preparadas"},
    {"Id":"438","section":"SECCIÓN I","section_desc":"ALOJAMIENTO Y SERVICIOS DE COMIDA","CIIU":"5613","CIIU_desc":"Expendio de comidas preparadas en cafeterías"},
    {"Id":"439","section":"SECCIÓN I","section_desc":"ALOJAMIENTO Y SERVICIOS DE COMIDA","CIIU":"5619","CIIU_desc":"Otros tipos de expendio de comidas preparadas n.c.p."},
    {"Id":"440","section":"SECCIÓN I","section_desc":"ALOJAMIENTO Y SERVICIOS DE COMIDA","CIIU":"562","CIIU_desc":"Actividades de catering para eventos y otros servicios de comidas"},
    {"Id":"441","section":"SECCIÓN I","section_desc":"ALOJAMIENTO Y SERVICIOS DE COMIDA","CIIU":"5621","CIIU_desc":"Catering para eventos"},
    {"Id":"442","section":"SECCIÓN I","section_desc":"ALOJAMIENTO Y SERVICIOS DE COMIDA","CIIU":"5629","CIIU_desc":"Actividades de otros servicios de comidas"},
    {"Id":"443","section":"SECCIÓN I","section_desc":"ALOJAMIENTO Y SERVICIOS DE COMIDA","CIIU":"5630","CIIU_desc":"Expendio de bebidas alcohólicas para el consumo dentro del establecimiento"},
    {"Id":"444","section":"SECCIÓN J","section_desc":"INFORMACIÓN Y COMUNICACIONES","CIIU":"58","CIIU_desc":"Actividades de edición"},
    {"Id":"445","section":"SECCIÓN J","section_desc":"INFORMACIÓN Y COMUNICACIONES","CIIU":"581","CIIU_desc":"Edición de libros, publicaciones periódicas y otras actividades de edición"},
    {"Id":"446","section":"SECCIÓN J","section_desc":"INFORMACIÓN Y COMUNICACIONES","CIIU":"5811","CIIU_desc":"Edición de libros"},
    {"Id":"447","section":"SECCIÓN J","section_desc":"INFORMACIÓN Y COMUNICACIONES","CIIU":"5812","CIIU_desc":"Edición de directorios y listas de correo"},
    {"Id":"448","section":"SECCIÓN J","section_desc":"INFORMACIÓN Y COMUNICACIONES","CIIU":"5813","CIIU_desc":"Edición de periódicos, revistas y otras publicaciones periódicas"},
    {"Id":"449","section":"SECCIÓN J","section_desc":"INFORMACIÓN Y COMUNICACIONES","CIIU":"5819","CIIU_desc":"Otros trabajos de edición"},
    {"Id":"450","section":"SECCIÓN J","section_desc":"INFORMACIÓN Y COMUNICACIONES","CIIU":"5820","CIIU_desc":"Edición de programas de informática (software)"},
    {"Id":"451","section":"SECCIÓN J","section_desc":"INFORMACIÓN Y COMUNICACIONES","CIIU":"59","CIIU_desc":"Actividades cinematográficas, de video y producción de programas de televisión, grabación de sonido y edición de música"},
    {"Id":"452","section":"SECCIÓN J","section_desc":"INFORMACIÓN Y COMUNICACIONES","CIIU":"591","CIIU_desc":"Actividades de producción de películas cinematográficas, video y producción de programas, anuncios y comerciales de televisión"},
    {"Id":"453","section":"SECCIÓN J","section_desc":"INFORMACIÓN Y COMUNICACIONES","CIIU":"5911","CIIU_desc":"Actividades de producción de películas cinematográficas, videos, programas, anuncios y comerciales de televisión"},
    {"Id":"454","section":"SECCIÓN J","section_desc":"INFORMACIÓN Y COMUNICACIONES","CIIU":"5912","CIIU_desc":"Actividades de posproducción de películas cinematográficas, videos, programas, anuncios y comerciales de televisión"},
    {"Id":"455","section":"SECCIÓN J","section_desc":"INFORMACIÓN Y COMUNICACIONES","CIIU":"5913","CIIU_desc":"Actividades de distribución de películas cinematográficas, videos, programas, anuncios y comerciales de televisión"},
    {"Id":"456","section":"SECCIÓN J","section_desc":"INFORMACIÓN Y COMUNICACIONES","CIIU":"5914","CIIU_desc":"Actividades de exhibición de películas cinematográficas y videos"},
    {"Id":"457","section":"SECCIÓN J","section_desc":"INFORMACIÓN Y COMUNICACIONES","CIIU":"5920","CIIU_desc":"Actividades de grabación de sonido y edición de música"},
    {"Id":"458","section":"SECCIÓN J","section_desc":"INFORMACIÓN Y COMUNICACIONES","CIIU":"60","CIIU_desc":"Actividades de programación, transmisión y/o difusión"},
    {"Id":"459","section":"SECCIÓN J","section_desc":"INFORMACIÓN Y COMUNICACIONES","CIIU":"6010","CIIU_desc":"Actividades de programación y transmisión en el servicio de radiodifusión sonora"},
    {"Id":"460","section":"SECCIÓN J","section_desc":"INFORMACIÓN Y COMUNICACIONES","CIIU":"6020","CIIU_desc":"Actividades de programación y transmisión de televisión"},
    {"Id":"461","section":"SECCIÓN J","section_desc":"INFORMACIÓN Y COMUNICACIONES","CIIU":"61","CIIU_desc":"Telecomunicaciones"},
    {"Id":"462","section":"SECCIÓN J","section_desc":"INFORMACIÓN Y COMUNICACIONES","CIIU":"6110","CIIU_desc":"Actividades de telecomunicaciones alámbricas"},
    {"Id":"463","section":"SECCIÓN J","section_desc":"INFORMACIÓN Y COMUNICACIONES","CIIU":"6120","CIIU_desc":"Actividades de telecomunicaciones inalámbricas"},
    {"Id":"464","section":"SECCIÓN J","section_desc":"INFORMACIÓN Y COMUNICACIONES","CIIU":"6130","CIIU_desc":"Actividades de telecomunicación satelital"},
    {"Id":"465","section":"SECCIÓN J","section_desc":"INFORMACIÓN Y COMUNICACIONES","CIIU":"6190","CIIU_desc":"Otras actividades de telecomunicaciones"},
    {"Id":"466","section":"SECCIÓN J","section_desc":"INFORMACIÓN Y COMUNICACIONES","CIIU":"62","CIIU_desc":"Desarrollo de sistemas informáticos (planificación, análisis, diseño, programación, pruebas), consultoría informática y actividades relacionadas"},
    {"Id":"467","section":"SECCIÓN J","section_desc":"INFORMACIÓN Y COMUNICACIONES","CIIU":"620","CIIU_desc":"Desarrollo de sistemas informáticos (planificación, análisis, diseño, programación, pruebas), consultoría informática y actividades relacionadas"},
    {"Id":"468","section":"SECCIÓN J","section_desc":"INFORMACIÓN Y COMUNICACIONES","CIIU":"6201","CIIU_desc":"Actividades de desarrollo de sistemas informáticos (planificación, análisis, diseño, programación, pruebas)"},
    {"Id":"469","section":"SECCIÓN J","section_desc":"INFORMACIÓN Y COMUNICACIONES","CIIU":"6202","CIIU_desc":"Actividades de consultoría informática y actividades de administración de instalaciones informáticas"},
    {"Id":"470","section":"SECCIÓN J","section_desc":"INFORMACIÓN Y COMUNICACIONES","CIIU":"6209","CIIU_desc":"Otras actividades de tecnologías de información y actividades de servicios informáticos"},
    {"Id":"471","section":"SECCIÓN J","section_desc":"INFORMACIÓN Y COMUNICACIONES","CIIU":"63","CIIU_desc":"Actividades de servicios de información"},
    {"Id":"472","section":"SECCIÓN J","section_desc":"INFORMACIÓN Y COMUNICACIONES","CIIU":"631","CIIU_desc":"Procesamiento de datos, alojamiento (hosting) y actividades relacionadas; portales web"},
    {"Id":"473","section":"SECCIÓN J","section_desc":"INFORMACIÓN Y COMUNICACIONES","CIIU":"6311","CIIU_desc":"Procesamiento de datos, alojamiento (hosting) y actividades relacionadas"},
    {"Id":"474","section":"SECCIÓN J","section_desc":"INFORMACIÓN Y COMUNICACIONES","CIIU":"6312","CIIU_desc":"Portales web"},
    {"Id":"475","section":"SECCIÓN J","section_desc":"INFORMACIÓN Y COMUNICACIONES","CIIU":"639","CIIU_desc":"Otras actividades de servicio de información"},
    {"Id":"476","section":"SECCIÓN J","section_desc":"INFORMACIÓN Y COMUNICACIONES","CIIU":"6391","CIIU_desc":"Actividades de agencias de noticias"},
    {"Id":"477","section":"SECCIÓN J","section_desc":"INFORMACIÓN Y COMUNICACIONES","CIIU":"6399","CIIU_desc":"Otras actividades de servicio de información n.c.p."},
    {"Id":"478","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"64","CIIU_desc":"Actividades de servicios financieros, excepto las de seguros y de pensiones"},
    {"Id":"479","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"641","CIIU_desc":"Intermediación monetaria"},
    {"Id":"480","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"6411","CIIU_desc":"Banco Central"},
    {"Id":"481","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"6412","CIIU_desc":"Bancos comerciales"},
    {"Id":"482","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"642","CIIU_desc":"Otros tipos de intermediación monetaria"},
    {"Id":"483","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"6421","CIIU_desc":"Actividades de las corporaciones financieras"},
    {"Id":"484","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"6422","CIIU_desc":"Actividades de las compañías de financiamiento"},
    {"Id":"485","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"6423","CIIU_desc":"Banca de segundo piso"},
    {"Id":"486","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"6424","CIIU_desc":"Actividades de las cooperativas financieras"},
    {"Id":"487","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"643","CIIU_desc":"Fideicomisos, fondos (incluye fondos de cesantías) y entidades financieras similares"},
    {"Id":"488","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"6431","CIIU_desc":"Fideicomisos, fondos y entidades financieras similares"},
    {"Id":"489","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"6432","CIIU_desc":"Fondos de cesantías"},
    {"Id":"490","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"649","CIIU_desc":"Otras actividades de servicio financiero, excepto las de seguros y pensiones"},
    {"Id":"491","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"6491","CIIU_desc":"Leasing financiero (arrendamiento financiero)"},
    {"Id":"492","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"6492","CIIU_desc":"Actividades financieras de fondos de empleados y otras formas asociativas del sector solidario"},
    {"Id":"493","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"6493","CIIU_desc":"Actividades de compra de cartera o factoring"},
    {"Id":"494","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"6494","CIIU_desc":"Otras actividades de distribución de fondos"},
    {"Id":"495","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"6495","CIIU_desc":"Instituciones especiales oficiales"},
    {"Id":"496","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"6496","CIIU_desc":"Capitalización"},
    {"Id":"497","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"6499","CIIU_desc":"Otras actividades de servicio financiero, excepto las de seguros y pensiones n.c.p."},
    {"Id":"498","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"65","CIIU_desc":"Seguros (incluso el reaseguro), seguros sociales y fondos de pensiones, excepto la seguridad social"},
    {"Id":"499","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"651","CIIU_desc":"Seguros y capitalización"},
    {"Id":"500","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"6511","CIIU_desc":"Seguros generales "},
    {"Id":"501","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"6512","CIIU_desc":"Seguros de vida"},
    {"Id":"502","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"6513","CIIU_desc":"Reaseguros"},
    {"Id":"503","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"6515","CIIU_desc":"Seguros de salud"},
    {"Id":"504","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"652","CIIU_desc":"Servicios de seguros sociales de salud y riesgos profesionales"},
    {"Id":"505","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"6521","CIIU_desc":"Servicios de seguros sociales de salud"},
    {"Id":"506","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"6522","CIIU_desc":"Servicios de seguros sociales de riesgos laborales"},
    {"Id":"507","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"6523","CIIU_desc":"Servicios de seguros sociales en riesgos de familia"},
    {"Id":"508","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"653","CIIU_desc":"Servicios de seguros sociales de pensiones"},
    {"Id":"509","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"6531","CIIU_desc":"Régimen de prima media con prestación definida (RPM)"},
    {"Id":"510","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"6532","CIIU_desc":"Régimen de ahorro con solidaridad (RAIS)."},
    {"Id":"511","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"66","CIIU_desc":"Actividades auxiliares de las actividades de servicios financieros"},
    {"Id":"512","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"661","CIIU_desc":"Actividades auxiliares de las actividades de servicios financieros, excepto las de seguros y pensiones"},
    {"Id":"513","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"6611","CIIU_desc":"Administración de mercados financieros"},
    {"Id":"514","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"6612","CIIU_desc":"Corretaje de valores y de contratos de productos básicos"},
    {"Id":"515","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"6613","CIIU_desc":"Otras actividades relacionadas con el mercado de valores"},
    {"Id":"516","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"6614","CIIU_desc":"Actividades de las sociedades de intermediación cambiaria y de servicios financieros especiales"},
    {"Id":"517","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"6615","CIIU_desc":"Actividades de los profesionales de compra y venta de divisas"},
    {"Id":"518","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"6619","CIIU_desc":"Otras actividades auxiliares de las actividades de servicios financieros n.c.p."},
    {"Id":"519","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"662","CIIU_desc":"Actividades de servicios auxiliares de los servicios de seguros y pensiones"},
    {"Id":"520","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"6621","CIIU_desc":"Actividades de agentes y corredores de seguros"},
    {"Id":"521","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"6629","CIIU_desc":"Evaluación de riesgos y daños, y otras actividades de servicios auxiliares"},
    {"Id":"522","section":"SECCIÓN K","section_desc":"ACTIVIDADES FINANCIERAS Y DE SEGUROS","CIIU":"6630","CIIU_desc":"Actividades de administración de fondos"},
    {"Id":"523","section":"SECCIÓN L","section_desc":"ACTIVIDADES INMOBILIARIAS","CIIU":"68","CIIU_desc":"Actividades inmobiliarias"},
    {"Id":"524","section":"SECCIÓN L","section_desc":"ACTIVIDADES INMOBILIARIAS","CIIU":"6810","CIIU_desc":"Actividades inmobiliarias realizadas con bienes propios o arrendados"},
    {"Id":"525","section":"SECCIÓN L","section_desc":"ACTIVIDADES INMOBILIARIAS","CIIU":"6820","CIIU_desc":"Actividades inmobiliarias realizadas a cambio de una retribución o por contrata"},
    {"Id":"526","section":"SECCIÓN M","section_desc":"ACTIVIDADES PROFESIONALES, CIENTÍFICAS Y TÉCNICAS","CIIU":"69","CIIU_desc":"Actividades jurídicas y de contabilidad"},
    {"Id":"527","section":"SECCIÓN M","section_desc":"ACTIVIDADES PROFESIONALES, CIENTÍFICAS Y TÉCNICAS","CIIU":"6910","CIIU_desc":"Actividades jurídicas"},
    {"Id":"528","section":"SECCIÓN M","section_desc":"ACTIVIDADES PROFESIONALES, CIENTÍFICAS Y TÉCNICAS","CIIU":"6920","CIIU_desc":"Actividades de contabilidad, teneduría de libros, auditoría financiera y asesoría tributaria"},
    {"Id":"529","section":"SECCIÓN M","section_desc":"ACTIVIDADES PROFESIONALES, CIENTÍFICAS Y TÉCNICAS","CIIU":"70","CIIU_desc":"Actividades de administración empresarial; actividades de consultoría de gestión"},
    {"Id":"530","section":"SECCIÓN M","section_desc":"ACTIVIDADES PROFESIONALES, CIENTÍFICAS Y TÉCNICAS","CIIU":"7010","CIIU_desc":"Actividades de administración empresarial"},
    {"Id":"531","section":"SECCIÓN M","section_desc":"ACTIVIDADES PROFESIONALES, CIENTÍFICAS Y TÉCNICAS","CIIU":"7020","CIIU_desc":"Actividades de consultoría de gestión"},
    {"Id":"532","section":"SECCIÓN M","section_desc":"ACTIVIDADES PROFESIONALES, CIENTÍFICAS Y TÉCNICAS","CIIU":"71","CIIU_desc":"Actividades de arquitectura e ingeniería; ensayos y análisis técnicos"},
    {"Id":"533","section":"SECCIÓN M","section_desc":"ACTIVIDADES PROFESIONALES, CIENTÍFICAS Y TÉCNICAS","CIIU":"711","CIIU_desc":"Actividades de arquitectura e ingeniería y otras actividades conexas de consultoría técnica"},
    {"Id":"534","section":"SECCIÓN M","section_desc":"ACTIVIDADES PROFESIONALES, CIENTÍFICAS Y TÉCNICAS","CIIU":"7111","CIIU_desc":"Actividades de arquitectura"},
    {"Id":"535","section":"SECCIÓN M","section_desc":"ACTIVIDADES PROFESIONALES, CIENTÍFICAS Y TÉCNICAS","CIIU":"7112","CIIU_desc":"Actividades de ingeniería y otras actividades conexas de consultoría técnica"},
    {"Id":"536","section":"SECCIÓN M","section_desc":"ACTIVIDADES PROFESIONALES, CIENTÍFICAS Y TÉCNICAS","CIIU":"7120","CIIU_desc":"Ensayos y análisis técnicos"},
    {"Id":"537","section":"SECCIÓN M","section_desc":"ACTIVIDADES PROFESIONALES, CIENTÍFICAS Y TÉCNICAS","CIIU":"72","CIIU_desc":"Investigación científica y desarrollo"},
    {"Id":"538","section":"SECCIÓN M","section_desc":"ACTIVIDADES PROFESIONALES, CIENTÍFICAS Y TÉCNICAS","CIIU":"7210","CIIU_desc":"Investigaciones y desarrollo experimental en el campo de las ciencias naturales y la ingeniería "},
    {"Id":"539","section":"SECCIÓN M","section_desc":"ACTIVIDADES PROFESIONALES, CIENTÍFICAS Y TÉCNICAS","CIIU":"7220","CIIU_desc":"Investigaciones y desarrollo experimental en el campo de las ciencias sociales y las humanidades"},
    {"Id":"540","section":"SECCIÓN M","section_desc":"ACTIVIDADES PROFESIONALES, CIENTÍFICAS Y TÉCNICAS","CIIU":"73","CIIU_desc":"Publicidad y estudios de mercado"},
    {"Id":"541","section":"SECCIÓN M","section_desc":"ACTIVIDADES PROFESIONALES, CIENTÍFICAS Y TÉCNICAS","CIIU":"7310","CIIU_desc":"Publicidad"},
    {"Id":"542","section":"SECCIÓN M","section_desc":"ACTIVIDADES PROFESIONALES, CIENTÍFICAS Y TÉCNICAS","CIIU":"7320","CIIU_desc":"Estudios de mercado y realización de encuestas de opinión pública"},
    {"Id":"543","section":"SECCIÓN M","section_desc":"ACTIVIDADES PROFESIONALES, CIENTÍFICAS Y TÉCNICAS","CIIU":"74","CIIU_desc":"Otras actividades profesionales, científicas y técnicas"},
    {"Id":"544","section":"SECCIÓN M","section_desc":"ACTIVIDADES PROFESIONALES, CIENTÍFICAS Y TÉCNICAS","CIIU":"7410","CIIU_desc":"Actividades especializadas de diseño "},
    {"Id":"545","section":"SECCIÓN M","section_desc":"ACTIVIDADES PROFESIONALES, CIENTÍFICAS Y TÉCNICAS","CIIU":"7420","CIIU_desc":"Actividades de fotografía"},
    {"Id":"546","section":"SECCIÓN M","section_desc":"ACTIVIDADES PROFESIONALES, CIENTÍFICAS Y TÉCNICAS","CIIU":"7490","CIIU_desc":"Otras actividades profesionales, científicas y técnicas n.c.p."},
    {"Id":"547","section":"SECCIÓN M","section_desc":"ACTIVIDADES PROFESIONALES, CIENTÍFICAS Y TÉCNICAS","CIIU":"75","CIIU_desc":"Actividades veterinarias"},
    {"Id":"548","section":"SECCIÓN M","section_desc":"ACTIVIDADES PROFESIONALES, CIENTÍFICAS Y TÉCNICAS","CIIU":"7500","CIIU_desc":"Actividades veterinarias"},
    {"Id":"549","section":"SECCIÓN N","section_desc":"ACTIVIDADES DE SERVICIOS ADMINISTRATIVOS Y DE APOYO","CIIU":"77","CIIU_desc":"Actividades de alquiler y arrendamiento"},
    {"Id":"550","section":"SECCIÓN N","section_desc":"ACTIVIDADES DE SERVICIOS ADMINISTRATIVOS Y DE APOYO","CIIU":"7710","CIIU_desc":"Alquiler y arrendamiento de vehículos automotores"},
    {"Id":"551","section":"SECCIÓN N","section_desc":"ACTIVIDADES DE SERVICIOS ADMINISTRATIVOS Y DE APOYO","CIIU":"772","CIIU_desc":"Alquiler y arrendamiento de efectos personales y enseres domésticos"},
    {"Id":"552","section":"SECCIÓN N","section_desc":"ACTIVIDADES DE SERVICIOS ADMINISTRATIVOS Y DE APOYO","CIIU":"7721","CIIU_desc":"Alquiler y arrendamiento de equipo recreativo y deportivo"},
    {"Id":"553","section":"SECCIÓN N","section_desc":"ACTIVIDADES DE SERVICIOS ADMINISTRATIVOS Y DE APOYO","CIIU":"7722","CIIU_desc":"Alquiler de videos y discos "},
    {"Id":"554","section":"SECCIÓN N","section_desc":"ACTIVIDADES DE SERVICIOS ADMINISTRATIVOS Y DE APOYO","CIIU":"7729","CIIU_desc":"Alquiler y arrendamiento de otros efectos personales y enseres domésticos n.c.p."},
    {"Id":"555","section":"SECCIÓN N","section_desc":"ACTIVIDADES DE SERVICIOS ADMINISTRATIVOS Y DE APOYO","CIIU":"7730","CIIU_desc":"Alquiler y arrendamiento de otros tipos de maquinaria, equipo y bienes tangibles n.c.p."},
    {"Id":"556","section":"SECCIÓN N","section_desc":"ACTIVIDADES DE SERVICIOS ADMINISTRATIVOS Y DE APOYO","CIIU":"7740","CIIU_desc":"Arrendamiento de propiedad intelectual y productos similares, excepto obras protegidas por derechos de autor"},
    {"Id":"557","section":"SECCIÓN N","section_desc":"ACTIVIDADES DE SERVICIOS ADMINISTRATIVOS Y DE APOYO","CIIU":"78","CIIU_desc":"Actividades de empleo"},
    {"Id":"558","section":"SECCIÓN N","section_desc":"ACTIVIDADES DE SERVICIOS ADMINISTRATIVOS Y DE APOYO","CIIU":"7810","CIIU_desc":"Actividades de agencias de gestión y colocación de empleo"},
    {"Id":"559","section":"SECCIÓN N","section_desc":"ACTIVIDADES DE SERVICIOS ADMINISTRATIVOS Y DE APOYO","CIIU":"7820","CIIU_desc":"Actividades de empresas de servicios temporales"},
    {"Id":"560","section":"SECCIÓN N","section_desc":"ACTIVIDADES DE SERVICIOS ADMINISTRATIVOS Y DE APOYO","CIIU":"7830","CIIU_desc":"Otras actividades de provisión de talento humano"},
    {"Id":"561","section":"SECCIÓN N","section_desc":"ACTIVIDADES DE SERVICIOS ADMINISTRATIVOS Y DE APOYO","CIIU":"79","CIIU_desc":"Actividades de las agencias de viajes, operadores turísticos, servicios de reserva y actividades relacionadas"},
    {"Id":"562","section":"SECCIÓN N","section_desc":"ACTIVIDADES DE SERVICIOS ADMINISTRATIVOS Y DE APOYO","CIIU":"791","CIIU_desc":"Actividades de las agencias de viajes y operadores turísticos"},
    {"Id":"563","section":"SECCIÓN N","section_desc":"ACTIVIDADES DE SERVICIOS ADMINISTRATIVOS Y DE APOYO","CIIU":"7911","CIIU_desc":"Actividades de las agencias de viaje"},
    {"Id":"564","section":"SECCIÓN N","section_desc":"ACTIVIDADES DE SERVICIOS ADMINISTRATIVOS Y DE APOYO","CIIU":"7912","CIIU_desc":"Actividades de operadores turísticos"},
    {"Id":"565","section":"SECCIÓN N","section_desc":"ACTIVIDADES DE SERVICIOS ADMINISTRATIVOS Y DE APOYO","CIIU":"7990","CIIU_desc":"Otros servicios de reserva y actividades relacionadas"},
    {"Id":"566","section":"SECCIÓN N","section_desc":"ACTIVIDADES DE SERVICIOS ADMINISTRATIVOS Y DE APOYO","CIIU":"80","CIIU_desc":"Actividades de seguridad e investigación privada"},
    {"Id":"567","section":"SECCIÓN N","section_desc":"ACTIVIDADES DE SERVICIOS ADMINISTRATIVOS Y DE APOYO","CIIU":"8010","CIIU_desc":"Actividades de seguridad privada"},
    {"Id":"568","section":"SECCIÓN N","section_desc":"ACTIVIDADES DE SERVICIOS ADMINISTRATIVOS Y DE APOYO","CIIU":"8020","CIIU_desc":"Actividades de servicios de sistemas de seguridad"},
    {"Id":"569","section":"SECCIÓN N","section_desc":"ACTIVIDADES DE SERVICIOS ADMINISTRATIVOS Y DE APOYO","CIIU":"8030","CIIU_desc":"Actividades de detectives e investigadores privados"},
    {"Id":"570","section":"SECCIÓN N","section_desc":"ACTIVIDADES DE SERVICIOS ADMINISTRATIVOS Y DE APOYO","CIIU":"81","CIIU_desc":"Actividades de servicios a edificios y paisajismo (jardines, zonas verdes)"},
    {"Id":"571","section":"SECCIÓN N","section_desc":"ACTIVIDADES DE SERVICIOS ADMINISTRATIVOS Y DE APOYO","CIIU":"8110","CIIU_desc":"Actividades combinadas de apoyo a instalaciones"},
    {"Id":"572","section":"SECCIÓN N","section_desc":"ACTIVIDADES DE SERVICIOS ADMINISTRATIVOS Y DE APOYO","CIIU":"812","CIIU_desc":"Actividades de limpieza"},
    {"Id":"573","section":"SECCIÓN N","section_desc":"ACTIVIDADES DE SERVICIOS ADMINISTRATIVOS Y DE APOYO","CIIU":"8121","CIIU_desc":"Limpieza general interior de edificios"},
    {"Id":"574","section":"SECCIÓN N","section_desc":"ACTIVIDADES DE SERVICIOS ADMINISTRATIVOS Y DE APOYO","CIIU":"8129","CIIU_desc":"Otras actividades de limpieza de edificios e instalaciones industriales"},
    {"Id":"575","section":"SECCIÓN N","section_desc":"ACTIVIDADES DE SERVICIOS ADMINISTRATIVOS Y DE APOYO","CIIU":"8130","CIIU_desc":"Actividades de paisajismo y servicios de mantenimiento conexos"},
    {"Id":"576","section":"SECCIÓN N","section_desc":"ACTIVIDADES DE SERVICIOS ADMINISTRATIVOS Y DE APOYO","CIIU":"82","CIIU_desc":"Actividades administrativas y de apoyo de oficina y otras actividades de apoyo a las empresas"},
    {"Id":"577","section":"SECCIÓN N","section_desc":"ACTIVIDADES DE SERVICIOS ADMINISTRATIVOS Y DE APOYO","CIIU":"821","CIIU_desc":"Actividades administrativas y de apoyo de oficina"},
    {"Id":"578","section":"SECCIÓN N","section_desc":"ACTIVIDADES DE SERVICIOS ADMINISTRATIVOS Y DE APOYO","CIIU":"8211","CIIU_desc":"Actividades combinadas de servicios administrativos de oficina"},
    {"Id":"579","section":"SECCIÓN N","section_desc":"ACTIVIDADES DE SERVICIOS ADMINISTRATIVOS Y DE APOYO","CIIU":"8219","CIIU_desc":"Fotocopiado, preparación de documentos y otras actividades especializadas de apoyo a oficina"},
    {"Id":"580","section":"SECCIÓN N","section_desc":"ACTIVIDADES DE SERVICIOS ADMINISTRATIVOS Y DE APOYO","CIIU":"8220","CIIU_desc":"Actividades de centros de llamadas (Call center)"},
    {"Id":"581","section":"SECCIÓN N","section_desc":"ACTIVIDADES DE SERVICIOS ADMINISTRATIVOS Y DE APOYO","CIIU":"8230","CIIU_desc":"Organización de convenciones y eventos comerciales"},
    {"Id":"582","section":"SECCIÓN N","section_desc":"ACTIVIDADES DE SERVICIOS ADMINISTRATIVOS Y DE APOYO","CIIU":"829","CIIU_desc":"Actividades de servicios de apoyo a las empresas n.c.p."},
    {"Id":"583","section":"SECCIÓN N","section_desc":"ACTIVIDADES DE SERVICIOS ADMINISTRATIVOS Y DE APOYO","CIIU":"8291","CIIU_desc":"Actividades de agencias de cobranza y oficinas de calificación crediticia"},
    {"Id":"584","section":"SECCIÓN N","section_desc":"ACTIVIDADES DE SERVICIOS ADMINISTRATIVOS Y DE APOYO","CIIU":"8292","CIIU_desc":"Actividades de envase y empaque"},
    {"Id":"585","section":"SECCIÓN N","section_desc":"ACTIVIDADES DE SERVICIOS ADMINISTRATIVOS Y DE APOYO","CIIU":"8299","CIIU_desc":"Otras actividades de servicio de apoyo a las empresas n.c.p."},
    {"Id":"586","section":"SECCIÓN O","section_desc":"ADMINISTRACIÓN PÚBLICA Y DEFENSA; PLANES DE SEGURIDAD SOCIAL DE AFILIACIÓN OBLIGATORIA","CIIU":"84","CIIU_desc":"Administración pública y defensa; planes de seguridad social de afiliación obligatoria"},
    {"Id":"587","section":"SECCIÓN O","section_desc":"ADMINISTRACIÓN PÚBLICA Y DEFENSA; PLANES DE SEGURIDAD SOCIAL DE AFILIACIÓN OBLIGATORIA","CIIU":"841","CIIU_desc":"Administración del Estado y aplicación de la política económica y social de la comunidad"},
    {"Id":"588","section":"SECCIÓN O","section_desc":"ADMINISTRACIÓN PÚBLICA Y DEFENSA; PLANES DE SEGURIDAD SOCIAL DE AFILIACIÓN OBLIGATORIA","CIIU":"8411","CIIU_desc":"Actividades legislativas de la administración pública"},
    {"Id":"589","section":"SECCIÓN O","section_desc":"ADMINISTRACIÓN PÚBLICA Y DEFENSA; PLANES DE SEGURIDAD SOCIAL DE AFILIACIÓN OBLIGATORIA","CIIU":"8412","CIIU_desc":"Actividades ejecutivas de la administración pública"},
    {"Id":"590","section":"SECCIÓN O","section_desc":"ADMINISTRACIÓN PÚBLICA Y DEFENSA; PLANES DE SEGURIDAD SOCIAL DE AFILIACIÓN OBLIGATORIA","CIIU":"8413","CIIU_desc":"Regulación de las actividades de organismos que prestan servicios de salud, educativos, culturales y otros servicios sociales, excepto servicios de seguridad social "},
    {"Id":"591","section":"SECCIÓN O","section_desc":"ADMINISTRACIÓN PÚBLICA Y DEFENSA; PLANES DE SEGURIDAD SOCIAL DE AFILIACIÓN OBLIGATORIA","CIIU":"8414","CIIU_desc":"Actividades reguladoras y facilitadoras de la actividad económica"},
    {"Id":"592","section":"SECCIÓN O","section_desc":"ADMINISTRACIÓN PÚBLICA Y DEFENSA; PLANES DE SEGURIDAD SOCIAL DE AFILIACIÓN OBLIGATORIA","CIIU":"8415","CIIU_desc":"Actividades de los otros órganos de control y otras instituciones"},
    {"Id":"593","section":"SECCIÓN O","section_desc":"ADMINISTRACIÓN PÚBLICA Y DEFENSA; PLANES DE SEGURIDAD SOCIAL DE AFILIACIÓN OBLIGATORIA","CIIU":"842","CIIU_desc":"Prestación de servicios a la comunidad en general"},
    {"Id":"594","section":"SECCIÓN O","section_desc":"ADMINISTRACIÓN PÚBLICA Y DEFENSA; PLANES DE SEGURIDAD SOCIAL DE AFILIACIÓN OBLIGATORIA","CIIU":"8421","CIIU_desc":"Relaciones exteriores "},
    {"Id":"595","section":"SECCIÓN O","section_desc":"ADMINISTRACIÓN PÚBLICA Y DEFENSA; PLANES DE SEGURIDAD SOCIAL DE AFILIACIÓN OBLIGATORIA","CIIU":"8422","CIIU_desc":"Actividades de defensa"},
    {"Id":"596","section":"SECCIÓN O","section_desc":"ADMINISTRACIÓN PÚBLICA Y DEFENSA; PLANES DE SEGURIDAD SOCIAL DE AFILIACIÓN OBLIGATORIA","CIIU":"8423","CIIU_desc":"Orden público y actividades de seguridad"},
    {"Id":"597","section":"SECCIÓN O","section_desc":"ADMINISTRACIÓN PÚBLICA Y DEFENSA; PLANES DE SEGURIDAD SOCIAL DE AFILIACIÓN OBLIGATORIA","CIIU":"8424","CIIU_desc":"Administración de justicia"},
    {"Id":"598","section":"SECCIÓN O","section_desc":"ADMINISTRACIÓN PÚBLICA Y DEFENSA; PLANES DE SEGURIDAD SOCIAL DE AFILIACIÓN OBLIGATORIA","CIIU":"8430","CIIU_desc":"Actividades de planes de seguridad social de afiliación obligatoria"},
    {"Id":"599","section":"SECCIÓN P","section_desc":"EDUCACIÓN","CIIU":"85","CIIU_desc":"Educación"},
    {"Id":"600","section":"SECCIÓN P","section_desc":"EDUCACIÓN","CIIU":"851","CIIU_desc":"Educación de la primera infancia, preescolar y básica primaria"},
    {"Id":"601","section":"SECCIÓN P","section_desc":"EDUCACIÓN","CIIU":"8511","CIIU_desc":"Educación de la primera infancia"},
    {"Id":"602","section":"SECCIÓN P","section_desc":"EDUCACIÓN","CIIU":"8512","CIIU_desc":"Educación preescolar"},
    {"Id":"603","section":"SECCIÓN P","section_desc":"EDUCACIÓN","CIIU":"8513","CIIU_desc":"Educación básica primaria"},
    {"Id":"604","section":"SECCIÓN P","section_desc":"EDUCACIÓN","CIIU":"852","CIIU_desc":"Educación secundaria y de formación laboral"},
    {"Id":"605","section":"SECCIÓN P","section_desc":"EDUCACIÓN","CIIU":"8521","CIIU_desc":"Educación básica secundaria "},
    {"Id":"606","section":"SECCIÓN P","section_desc":"EDUCACIÓN","CIIU":"8522","CIIU_desc":"Educación media académica"},
    {"Id":"607","section":"SECCIÓN P","section_desc":"EDUCACIÓN","CIIU":"8523","CIIU_desc":"Educación media técnica "},
    {"Id":"608","section":"SECCIÓN P","section_desc":"EDUCACIÓN","CIIU":"8530","CIIU_desc":"Establecimientos que combinan diferentes niveles de educación "},
    {"Id":"609","section":"SECCIÓN P","section_desc":"EDUCACIÓN","CIIU":"854","CIIU_desc":"Educación superior"},
    {"Id":"610","section":"SECCIÓN P","section_desc":"EDUCACIÓN","CIIU":"8541","CIIU_desc":"Educación técnica profesional"},
    {"Id":"611","section":"SECCIÓN P","section_desc":"EDUCACIÓN","CIIU":"8542","CIIU_desc":"Educación tecnológica"},
    {"Id":"612","section":"SECCIÓN P","section_desc":"EDUCACIÓN","CIIU":"8543","CIIU_desc":"Educación de instituciones universitarias o de escuelas tecnológicas"},
    {"Id":"613","section":"SECCIÓN P","section_desc":"EDUCACIÓN","CIIU":"8544","CIIU_desc":"Educación de universidades"},
    {"Id":"614","section":"SECCIÓN P","section_desc":"EDUCACIÓN","CIIU":"855","CIIU_desc":"Otros tipos de educación"},
    {"Id":"615","section":"SECCIÓN P","section_desc":"EDUCACIÓN","CIIU":"8551","CIIU_desc":"Formación para el trabajo"},
    {"Id":"616","section":"SECCIÓN P","section_desc":"EDUCACIÓN","CIIU":"8552","CIIU_desc":"Enseñanza deportiva y recreativa"},
    {"Id":"617","section":"SECCIÓN P","section_desc":"EDUCACIÓN","CIIU":"8553","CIIU_desc":"Enseñanza cultural"},
    {"Id":"618","section":"SECCIÓN P","section_desc":"EDUCACIÓN","CIIU":"8559","CIIU_desc":"Otros tipos de educación n.c.p."},
    {"Id":"619","section":"SECCIÓN P","section_desc":"EDUCACIÓN","CIIU":"8560","CIIU_desc":"Actividades de apoyo a la educación"},
    {"Id":"620","section":"SECCIÓN Q","section_desc":"ACTIVIDADES DE ATENCIÓN DE LA SALUD HUMANA Y DE ASISTENCIA SOCIAL","CIIU":"86","CIIU_desc":"Actividades de atención de la salud humana"},
    {"Id":"621","section":"SECCIÓN Q","section_desc":"ACTIVIDADES DE ATENCIÓN DE LA SALUD HUMANA Y DE ASISTENCIA SOCIAL","CIIU":"8610","CIIU_desc":"Actividades de hospitales y clínicas, con internación"},
    {"Id":"622","section":"SECCIÓN Q","section_desc":"ACTIVIDADES DE ATENCIÓN DE LA SALUD HUMANA Y DE ASISTENCIA SOCIAL","CIIU":"862","CIIU_desc":"Actividades de práctica médica y odontológica, sin internación "},
    {"Id":"623","section":"SECCIÓN Q","section_desc":"ACTIVIDADES DE ATENCIÓN DE LA SALUD HUMANA Y DE ASISTENCIA SOCIAL","CIIU":"8621","CIIU_desc":"Actividades de la práctica médica, sin internación"},
    {"Id":"624","section":"SECCIÓN Q","section_desc":"ACTIVIDADES DE ATENCIÓN DE LA SALUD HUMANA Y DE ASISTENCIA SOCIAL","CIIU":"8622","CIIU_desc":"Actividades de la práctica odontológica"},
    {"Id":"625","section":"SECCIÓN Q","section_desc":"ACTIVIDADES DE ATENCIÓN DE LA SALUD HUMANA Y DE ASISTENCIA SOCIAL","CIIU":"869","CIIU_desc":"Otras actividades de atención relacionadas con la salud humana"},
    {"Id":"626","section":"SECCIÓN Q","section_desc":"ACTIVIDADES DE ATENCIÓN DE LA SALUD HUMANA Y DE ASISTENCIA SOCIAL","CIIU":"8691","CIIU_desc":"Actividades de apoyo diagnóstico"},
    {"Id":"627","section":"SECCIÓN Q","section_desc":"ACTIVIDADES DE ATENCIÓN DE LA SALUD HUMANA Y DE ASISTENCIA SOCIAL","CIIU":"8692","CIIU_desc":"Actividades de apoyo terapéutico"},
    {"Id":"628","section":"SECCIÓN Q","section_desc":"ACTIVIDADES DE ATENCIÓN DE LA SALUD HUMANA Y DE ASISTENCIA SOCIAL","CIIU":"8699","CIIU_desc":"Otras actividades de atención de la salud humana"},
    {"Id":"629","section":"SECCIÓN Q","section_desc":"ACTIVIDADES DE ATENCIÓN DE LA SALUD HUMANA Y DE ASISTENCIA SOCIAL","CIIU":"87","CIIU_desc":"Actividades de atención residencial medicalizada"},
    {"Id":"630","section":"SECCIÓN Q","section_desc":"ACTIVIDADES DE ATENCIÓN DE LA SALUD HUMANA Y DE ASISTENCIA SOCIAL","CIIU":"8710","CIIU_desc":"Actividades de atención residencial medicalizada de tipo general"},
    {"Id":"631","section":"SECCIÓN Q","section_desc":"ACTIVIDADES DE ATENCIÓN DE LA SALUD HUMANA Y DE ASISTENCIA SOCIAL","CIIU":"8720","CIIU_desc":"Actividades de atención residencial, para el cuidado de pacientes con retardo mental, enfermedad mental y consumo de sustancias psicoactivas"},
    {"Id":"632","section":"SECCIÓN Q","section_desc":"ACTIVIDADES DE ATENCIÓN DE LA SALUD HUMANA Y DE ASISTENCIA SOCIAL","CIIU":"8730","CIIU_desc":"Actividades de atención en instituciones para el cuidado de personas mayores y/o discapacitadas"},
    {"Id":"633","section":"SECCIÓN Q","section_desc":"ACTIVIDADES DE ATENCIÓN DE LA SALUD HUMANA Y DE ASISTENCIA SOCIAL","CIIU":"8790","CIIU_desc":"Otras actividades de atención en instituciones con alojamiento"},
    {"Id":"634","section":"SECCIÓN Q","section_desc":"ACTIVIDADES DE ATENCIÓN DE LA SALUD HUMANA Y DE ASISTENCIA SOCIAL","CIIU":"88","CIIU_desc":"Actividades de asistencia social sin alojamiento"},
    {"Id":"635","section":"SECCIÓN Q","section_desc":"ACTIVIDADES DE ATENCIÓN DE LA SALUD HUMANA Y DE ASISTENCIA SOCIAL","CIIU":"8810","CIIU_desc":"Actividades de asistencia social sin alojamiento para personas mayores y discapacitadas"},
    {"Id":"636","section":"SECCIÓN Q","section_desc":"ACTIVIDADES DE ATENCIÓN DE LA SALUD HUMANA Y DE ASISTENCIA SOCIAL","CIIU":"889","CIIU_desc":"Otras actividades de asistencia social sin alojamiento"},
    {"Id":"637","section":"SECCIÓN Q","section_desc":"ACTIVIDADES DE ATENCIÓN DE LA SALUD HUMANA Y DE ASISTENCIA SOCIAL","CIIU":"8891","CIIU_desc":"Actividades de guarderías para niños y niñas"},
    {"Id":"638","section":"SECCIÓN Q","section_desc":"ACTIVIDADES DE ATENCIÓN DE LA SALUD HUMANA Y DE ASISTENCIA SOCIAL","CIIU":"8899","CIIU_desc":"Otras actividades de asistencia social n.c.p."},
    {"Id":"639","section":"SECCIÓN R ","section_desc":"ACTIVIDADES ARTÍSTICAS, DE ENTRETENIMIENTO Y RECREACIÓN","CIIU":"90","CIIU_desc":"Actividades creativas, artísticas y de entretenimiento"},
    {"Id":"640","section":"SECCIÓN R ","section_desc":"ACTIVIDADES ARTÍSTICAS, DE ENTRETENIMIENTO Y RECREACIÓN","CIIU":"900","CIIU_desc":"Actividades creativas, artísticas y de entretenimiento "},
    {"Id":"641","section":"SECCIÓN R ","section_desc":"ACTIVIDADES ARTÍSTICAS, DE ENTRETENIMIENTO Y RECREACIÓN","CIIU":"9001","CIIU_desc":"Creación literaria"},
    {"Id":"642","section":"SECCIÓN R ","section_desc":"ACTIVIDADES ARTÍSTICAS, DE ENTRETENIMIENTO Y RECREACIÓN","CIIU":"9002","CIIU_desc":"Creación musical"},
    {"Id":"643","section":"SECCIÓN R ","section_desc":"ACTIVIDADES ARTÍSTICAS, DE ENTRETENIMIENTO Y RECREACIÓN","CIIU":"9003","CIIU_desc":"Creación teatral"},
    {"Id":"644","section":"SECCIÓN R ","section_desc":"ACTIVIDADES ARTÍSTICAS, DE ENTRETENIMIENTO Y RECREACIÓN","CIIU":"9004","CIIU_desc":"Creación audiovisual"},
    {"Id":"645","section":"SECCIÓN R ","section_desc":"ACTIVIDADES ARTÍSTICAS, DE ENTRETENIMIENTO Y RECREACIÓN","CIIU":"9005","CIIU_desc":"Artes plásticas y visuales"},
    {"Id":"646","section":"SECCIÓN R ","section_desc":"ACTIVIDADES ARTÍSTICAS, DE ENTRETENIMIENTO Y RECREACIÓN","CIIU":"9006","CIIU_desc":"Actividades teatrales"},
    {"Id":"647","section":"SECCIÓN R ","section_desc":"ACTIVIDADES ARTÍSTICAS, DE ENTRETENIMIENTO Y RECREACIÓN","CIIU":"9007","CIIU_desc":"Actividades de espectáculos musicales en vivo"},
    {"Id":"648","section":"SECCIÓN R ","section_desc":"ACTIVIDADES ARTÍSTICAS, DE ENTRETENIMIENTO Y RECREACIÓN","CIIU":"9008","CIIU_desc":"Otras actividades de espectáculos en vivo n.c.p."},
    {"Id":"649","section":"SECCIÓN R ","section_desc":"ACTIVIDADES ARTÍSTICAS, DE ENTRETENIMIENTO Y RECREACIÓN","CIIU":"91","CIIU_desc":"Actividades de bibliotecas, archivos, museos y otras actividades culturales"},
    {"Id":"650","section":"SECCIÓN R ","section_desc":"ACTIVIDADES ARTÍSTICAS, DE ENTRETENIMIENTO Y RECREACIÓN","CIIU":"910","CIIU_desc":"Actividades de bibliotecas, archivos, museos y otras actividades culturales"},
    {"Id":"651","section":"SECCIÓN R ","section_desc":"ACTIVIDADES ARTÍSTICAS, DE ENTRETENIMIENTO Y RECREACIÓN","CIIU":"9101","CIIU_desc":"Actividades de bibliotecas y archivos"},
    {"Id":"652","section":"SECCIÓN R ","section_desc":"ACTIVIDADES ARTÍSTICAS, DE ENTRETENIMIENTO Y RECREACIÓN","CIIU":"9102","CIIU_desc":"Actividades y funcionamiento de museos, conservación de edificios y sitios históricos"},
    {"Id":"653","section":"SECCIÓN R ","section_desc":"ACTIVIDADES ARTÍSTICAS, DE ENTRETENIMIENTO Y RECREACIÓN","CIIU":"9103","CIIU_desc":"Actividades de jardines botánicos, zoológicos y reservas naturales"},
    {"Id":"654","section":"SECCIÓN R ","section_desc":"ACTIVIDADES ARTÍSTICAS, DE ENTRETENIMIENTO Y RECREACIÓN","CIIU":"92","CIIU_desc":"Actividades de juegos de azar y apuestas"},
    {"Id":"655","section":"SECCIÓN R ","section_desc":"ACTIVIDADES ARTÍSTICAS, DE ENTRETENIMIENTO Y RECREACIÓN","CIIU":"9200","CIIU_desc":"Actividades de juegos de azar y apuestas"},
    {"Id":"656","section":"SECCIÓN R ","section_desc":"ACTIVIDADES ARTÍSTICAS, DE ENTRETENIMIENTO Y RECREACIÓN","CIIU":"93","CIIU_desc":"Actividades deportivas y actividades recreativas y de esparcimiento"},
    {"Id":"657","section":"SECCIÓN R ","section_desc":"ACTIVIDADES ARTÍSTICAS, DE ENTRETENIMIENTO Y RECREACIÓN","CIIU":"931","CIIU_desc":"Actividades deportivas"},
    {"Id":"658","section":"SECCIÓN R ","section_desc":"ACTIVIDADES ARTÍSTICAS, DE ENTRETENIMIENTO Y RECREACIÓN","CIIU":"9311","CIIU_desc":"Gestión de instalaciones deportivas"},
    {"Id":"659","section":"SECCIÓN R ","section_desc":"ACTIVIDADES ARTÍSTICAS, DE ENTRETENIMIENTO Y RECREACIÓN","CIIU":"9312","CIIU_desc":"Actividades de clubes deportivos"},
    {"Id":"660","section":"SECCIÓN R ","section_desc":"ACTIVIDADES ARTÍSTICAS, DE ENTRETENIMIENTO Y RECREACIÓN","CIIU":"9319","CIIU_desc":"Otras actividades deportivas"},
    {"Id":"661","section":"SECCIÓN R ","section_desc":"ACTIVIDADES ARTÍSTICAS, DE ENTRETENIMIENTO Y RECREACIÓN","CIIU":"932","CIIU_desc":"Otras actividades recreativas y de esparcimiento"},
    {"Id":"662","section":"SECCIÓN R ","section_desc":"ACTIVIDADES ARTÍSTICAS, DE ENTRETENIMIENTO Y RECREACIÓN","CIIU":"9321","CIIU_desc":"Actividades de parques de atracciones y parques temáticos"},
    {"Id":"663","section":"SECCIÓN R ","section_desc":"ACTIVIDADES ARTÍSTICAS, DE ENTRETENIMIENTO Y RECREACIÓN","CIIU":"9329","CIIU_desc":"Otras actividades recreativas y de esparcimiento n.c.p."},
    {"Id":"664","section":"SECCIÓN S","section_desc":"OTRAS ACTIVIDADES DE SERVICIOS","CIIU":"94","CIIU_desc":"Actividades de asociaciones"},
    {"Id":"665","section":"SECCIÓN S","section_desc":"OTRAS ACTIVIDADES DE SERVICIOS","CIIU":"941","CIIU_desc":"Actividades de asociaciones empresariales y de empleadores, y asociaciones profesionales"},
    {"Id":"666","section":"SECCIÓN S","section_desc":"OTRAS ACTIVIDADES DE SERVICIOS","CIIU":"9411","CIIU_desc":"Actividades de asociaciones empresariales y de empleadores"},
    {"Id":"667","section":"SECCIÓN S","section_desc":"OTRAS ACTIVIDADES DE SERVICIOS","CIIU":"9412","CIIU_desc":"Actividades de asociaciones profesionales"},
    {"Id":"668","section":"SECCIÓN S","section_desc":"OTRAS ACTIVIDADES DE SERVICIOS","CIIU":"9420","CIIU_desc":"Actividades de sindicatos de empleados"},
    {"Id":"669","section":"SECCIÓN S","section_desc":"OTRAS ACTIVIDADES DE SERVICIOS","CIIU":"949","CIIU_desc":"Actividades de otras asociaciones"},
    {"Id":"670","section":"SECCIÓN S","section_desc":"OTRAS ACTIVIDADES DE SERVICIOS","CIIU":"9491","CIIU_desc":"Actividades de asociaciones religiosas"},
    {"Id":"671","section":"SECCIÓN S","section_desc":"OTRAS ACTIVIDADES DE SERVICIOS","CIIU":"9492","CIIU_desc":"Actividades de asociaciones políticas"},
    {"Id":"672","section":"SECCIÓN S","section_desc":"OTRAS ACTIVIDADES DE SERVICIOS","CIIU":"9499","CIIU_desc":"Actividades de otras asociaciones n.c.p."},
    {"Id":"673","section":"SECCIÓN S","section_desc":"OTRAS ACTIVIDADES DE SERVICIOS","CIIU":"95","CIIU_desc":"Mantenimiento y reparación de computadores, efectos personales y enseres domésticos"},
    {"Id":"674","section":"SECCIÓN S","section_desc":"OTRAS ACTIVIDADES DE SERVICIOS","CIIU":"951","CIIU_desc":"Mantenimiento y reparación de computadores y equipo de comunicaciones"},
    {"Id":"675","section":"SECCIÓN S","section_desc":"OTRAS ACTIVIDADES DE SERVICIOS","CIIU":"9511","CIIU_desc":"Mantenimiento y reparación de computadores y de equipo periférico"},
    {"Id":"676","section":"SECCIÓN S","section_desc":"OTRAS ACTIVIDADES DE SERVICIOS","CIIU":"9512","CIIU_desc":"Mantenimiento y reparación de equipos de comunicación"},
    {"Id":"677","section":"SECCIÓN S","section_desc":"OTRAS ACTIVIDADES DE SERVICIOS","CIIU":"952","CIIU_desc":"Mantenimiento y reparación de efectos personales y enseres domésticos"},
    {"Id":"678","section":"SECCIÓN S","section_desc":"OTRAS ACTIVIDADES DE SERVICIOS","CIIU":"9521","CIIU_desc":"Mantenimiento y reparación de aparatos electrónicos de consumo"},
    {"Id":"679","section":"SECCIÓN S","section_desc":"OTRAS ACTIVIDADES DE SERVICIOS","CIIU":"9522","CIIU_desc":"Mantenimiento y reparación de aparatos y equipos domésticos y de jardinería "},
    {"Id":"680","section":"SECCIÓN S","section_desc":"OTRAS ACTIVIDADES DE SERVICIOS","CIIU":"9523","CIIU_desc":"Reparación de calzado y artículos de cuero"},
    {"Id":"681","section":"SECCIÓN S","section_desc":"OTRAS ACTIVIDADES DE SERVICIOS","CIIU":"9524","CIIU_desc":"Reparación de muebles y accesorios para el hogar"},
    {"Id":"682","section":"SECCIÓN S","section_desc":"OTRAS ACTIVIDADES DE SERVICIOS","CIIU":"9529","CIIU_desc":"Mantenimiento y reparación de otros efectos personales y enseres domésticos"},
    {"Id":"683","section":"SECCIÓN S","section_desc":"OTRAS ACTIVIDADES DE SERVICIOS","CIIU":"96","CIIU_desc":"Otras actividades de servicios personales"},
    {"Id":"684","section":"SECCIÓN S","section_desc":"OTRAS ACTIVIDADES DE SERVICIOS","CIIU":"960","CIIU_desc":"Otras actividades de servicios personales"},
    {"Id":"685","section":"SECCIÓN S","section_desc":"OTRAS ACTIVIDADES DE SERVICIOS","CIIU":"9601","CIIU_desc":"Lavado y limpieza, incluso la limpieza en seco, de productos textiles y de piel"},
    {"Id":"686","section":"SECCIÓN S","section_desc":"OTRAS ACTIVIDADES DE SERVICIOS","CIIU":"9602","CIIU_desc":"Peluquería y otros tratamientos de belleza"},
    {"Id":"687","section":"SECCIÓN S","section_desc":"OTRAS ACTIVIDADES DE SERVICIOS","CIIU":"9603","CIIU_desc":"Pompas fúnebres y actividades relacionadas"},
    {"Id":"688","section":"SECCIÓN S","section_desc":"OTRAS ACTIVIDADES DE SERVICIOS","CIIU":"9609","CIIU_desc":"Otras actividades de servicios personales n.c.p."},
    {"Id":"689","section":"SECCIÓN T ","section_desc":"ACTIVIDADES DE LOS HOGARES INDIVIDUALES EN CALIDAD DE EMPLEADORES; ACTIVIDADES NO DIFERENCIADAS DE LOS HOGARES INDIVIDUALES COMO PRODUCTORES DE BIENES Y SERVICIOS PARA USO PROPIO","CIIU":"97","CIIU_desc":"Actividades de los hogares individuales como empleadores de personal doméstico"},
    {"Id":"690","section":"SECCIÓN T ","section_desc":"ACTIVIDADES DE LOS HOGARES INDIVIDUALES EN CALIDAD DE EMPLEADORES; ACTIVIDADES NO DIFERENCIADAS DE LOS HOGARES INDIVIDUALES COMO PRODUCTORES DE BIENES Y SERVICIOS PARA USO PROPIO","CIIU":"9700","CIIU_desc":"Actividades de los hogares individuales como empleadores de personal doméstico"},
    {"Id":"691","section":"SECCIÓN T ","section_desc":"ACTIVIDADES DE LOS HOGARES INDIVIDUALES EN CALIDAD DE EMPLEADORES; ACTIVIDADES NO DIFERENCIADAS DE LOS HOGARES INDIVIDUALES COMO PRODUCTORES DE BIENES Y SERVICIOS PARA USO PROPIO","CIIU":"98","CIIU_desc":"Actividades no diferenciadas de los hogares individuales como productores de bienes y servicios para uso propio"},
    {"Id":"692","section":"SECCIÓN T ","section_desc":"ACTIVIDADES DE LOS HOGARES INDIVIDUALES EN CALIDAD DE EMPLEADORES; ACTIVIDADES NO DIFERENCIADAS DE LOS HOGARES INDIVIDUALES COMO PRODUCTORES DE BIENES Y SERVICIOS PARA USO PROPIO","CIIU":"9810","CIIU_desc":"Actividades no diferenciadas de los hogares individuales como productores de bienes para uso propio"},
    {"Id":"693","section":"SECCIÓN T ","section_desc":"ACTIVIDADES DE LOS HOGARES INDIVIDUALES EN CALIDAD DE EMPLEADORES; ACTIVIDADES NO DIFERENCIADAS DE LOS HOGARES INDIVIDUALES COMO PRODUCTORES DE BIENES Y SERVICIOS PARA USO PROPIO","CIIU":"9820","CIIU_desc":"Actividades no diferenciadas de los hogares individuales como productores de servicios para uso propio"},
    {"Id":"694","section":"SECCIÓN U","section_desc":"ACTIVIDADES DE ORGANIZACIONES Y ENTIDADES EXTRATERRITORIALES ","CIIU":"99","CIIU_desc":"Actividades de organizaciones y entidades extraterritoriales"},
    {"Id":"695","section":"SECCIÓN U","section_desc":"ACTIVIDADES DE ORGANIZACIONES Y ENTIDADES EXTRATERRITORIALES ","CIIU":"9900","CIIU_desc":"Actividades de organizaciones y entidades extraterritoriales"},
    {"Id":"696","section":"SECCIÓN Dian","section_desc":"OTRAS CLASIFICACIONES ","CIIU":"0010","CIIU_desc":"Asalariados"},
    {"Id":"697","section":"SECCIÓN Dian","section_desc":"OTRAS CLASIFICACIONES ","CIIU":"0020","CIIU_desc":"Pensionados"},
    {"Id":"698","section":"SECCIÓN Dian","section_desc":"OTRAS CLASIFICACIONES ","CIIU":"0081","CIIU_desc":"Personas naturales y sucesiones ilíquidas sin actividad económica"},
    {"Id":"699","section":"SECCIÓN Dian","section_desc":"OTRAS CLASIFICACIONES ","CIIU":"0082","CIIU_desc":"Personas naturales subsidiadas por terceros"},
    {"Id":"700","section":"SECCIÓN Dian","section_desc":"OTRAS CLASIFICACIONES ","CIIU":"0090","CIIU_desc":"Rentistas de capital, solo para personas naturales y sucesiones ilíquidas"}
    ]
