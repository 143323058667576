import Moment from 'moment'
import { useEffect, useState, useRef } from 'react'
import { Button, Col, Form, Row, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { getUserByIdF } from '../request'
import Signer from '../signer'
import { CreateFormat, getFormats, newWorkflow, updateFormat, updateWorkflow } from './request'
import { useUserContext } from '../../../../hooks/custom'
import SpinnerLoad from '../spinnerLoad'
import image from '../../../../assets/img/Logo_b-st.png'
import SelectOption from '../SelectOption'
import GTHFOR076 from './GTHFOR076'
import { monedaCOP } from '../functions'


export const GTHFOR244 = ({
  show,
  hide,
  restart,
  setRestart,
  isView,
  data = '',
  setData,
  isSign = false,
  list,
  workflow = [],
  IdFormat_PK,
  isProcess,
  isTH

}) => {
  /*---------ESTADOS------------------
      [     creado      ] => documento creado  firmado por elaborador
              V
      [    firmadoTH    ] => documento firmado por TH
              V
      [   firmadoCYF   ] => documento firmado por el lider de area
              V
      [     Aprobado    ] => documento firmado por el gerente general
              V
      [     Rechazado    ] => documento firmado por el gerente general
              V
              V
      [       Finalizado         ] => Gestionado por TH
  */





  const wf = {

    creando: "Firmar RQ Jefe",
    firmaTH: "Firmar RQ Lider Area",
    firmaCYF: "Aprobar RQ",
    Aprobar: "Aprobar RQ"
  }

  const estado = {
    creando: "firmaTH",
    firmaTH: "firmaCYF",
    firmaCYF: "Aprobar",
    Aprobar: "Procesar",
    Procesar: "Finalizado"
  }


  //-------------------------Info Formato
  const formato = 'COM-FOR-244'
  const revision = '0'
  const type = 'GTHFOR244'
  const fechaRev = '17/08/2022'
  const nombreFormato = 'CAMBIO DE CONDICIÓN CONTRACTUAL'
  const typef = 'GTHFOR244'

  //-------------------------Registros
  const modalRef = useRef(null);
  const printRef = useRef(null);
  const { sessionUser } = useUserContext()
  const [makeLeader, setMakeLeader] = useState(false)
  const [date, setDate] = useState('')
  const [buyTable, setBuyTable] = useState([])
  const [firmaSolicitante, setfirmaSolicitante] = useState('')
  const [firmaTH, setfirmaTH] = useState('')
  const [firmaCYF, setfirmaCYF] = useState('')
  const [firmaGerente, setfirmaGerente] = useState('')
  const [firmaRecibidoTH, setfirmaRecibidoTH] = useState('')
  const [listaLider, setListaLider] = useState([])
  const [empleados, setEmpleados] = useState('')
  const [isEdit, setIsEdit] = useState(false)
  const [modalAction, setModalAction] = useState(false)
  const [actionBtn, setActionBtn] = useState("Rechazar")
  const [_next, setNext] = useState(false)
  const [nextWF, setNextWF] = useState()
  const [listaTH, setListaTH] = useState(null)
  const [listaContabilidad, setListaContabilidad] = useState(null)
  const [ListaGcia, setListaGcia] = useState(null)
  const [ShowManuales, hideManuales] = useState(false)
  const [dataManual, setDataManual] = useState([])
  const [manualFunciones, setmanualFunciones] = useState("N/A")


  const [signStatus, setSignStatus] = useState({ firmaSolicitante: false, firmaGerente: false, firmaCYF: false, firmaTH: false, firmaRecibidoTH: false })
  const [dataAct, setdataAct] = useState({
    lugarActividades: data.dataAct.lugarActividades || 'N/A',
    areaTrabajo: data.dataAct.areaTrabajo || 'N/A',
    cargo: data.dataAct.cargo || 'N/A',
    manualFunciones: data.dataAct.manualFunciones || '',
    personalACargo: data.dataAct.personalACargo || 'N/A',
    tipoContrato: data.dataAct.tipoContrato || 'N/A',
    salario: data.dataAct.salario ||{
      otroSalario: 'N/A',
      primaAlimentacion: 'N/A',
      primaLocalizacion: 'N/A',
      primaMovilizacion: 'N/A',
      textoOtroSalario: 'N/A',
      tipo: 'N/A',
      valor: 'N/A'
    },
    CeCo: data.dataAct.CeCo || 'N/A',
    tipoNomina: data.dataAct.tipoNomina || 'N/A'

  })

  const abrirVentanaEmergente = () => {
    const url = 'http://app.rms.com.co:4010/uploads/COFIFOR161/3/soporte.pdf'; // La URL del archivo en el servidor
    const ventanaEmergente = window.open(url, 'NombreVentanaEmergente', 'width=600, height=400');

    // Puedes personalizar las dimensiones y propiedades de la ventana emergente según tus necesidades.

    // Asegúrate de manejar cualquier error si la ventana emergente es bloqueada por el navegador.
    if (!ventanaEmergente) {
      alert('La ventana emergente fue bloqueada. Asegúrate de permitir las ventanas emergentes en tu navegador.');
    }
  }


  useEffect(() => {
    if (!restart) {
      setRestart(!restart)
      if (show) getInfo()
    }
  }, [restart])

  useEffect(() => {
    async function fetchData() {
      const res = (await (updateFormat({ IdFormat_PK: IdFormat_PK, Status: 'Finalizada', formatData: printRef.current.outerHTML }, typef)))
      if (res.data.status === '200') {
        clearStates()
        hide(!show)
        setRestart(!restart)
      }
      else {
        toast.error(res.data.msj)
      }
    }
    if (data.Status === "Finalizar" && firmaGerente.image) {
      fetchData();

    }

  }, [firmaGerente.NombreTrabajador])



  const convertToPdf = () => {
    // Código para imprimir el contenido
    window.print();
  };

  const handleChange = async ({ target }) => {
    const { name, type } = target
    const value = type === 'checkbox' ? target.checked : target.value
    setData({ ...data, [name]: value, })
  }

  const ActionSol = async () => {

    let motRachazo = ""
    let newStatus = ""
    if (actionBtn === "Rechazar") {
      newStatus = "Rechazado"
      motRachazo = data.rechazo + "\n" + sessionUser.NombreTrabajador + " " + sessionUser.ApellidoTrabajador + '\n fecha: ' + date
    }
    if (actionBtn === "Reanudar") {
      if (workflow.action === "Firmar RQ Jefe") newStatus = "firmaTH"
      if (workflow.action === "Firmar RQ Lider Area") newStatus = 'firmaCYF'
      if (workflow.action === "Aprobar RQ") newStatus = 'Aprobar'
    }
    var result = await updateFormat({ IdFormat_PK: IdFormat_PK, Status: newStatus, rechazo: motRachazo }, typef)
    if (result.data.status === 200) {
      await updateWorkflow({ ...workflow, Status: actionBtn === "Rechazar" ? "rechazado" : 'pendiente', dateFinish: date })
      toast.success(result.data.msj)
    } else {
      toast.error('error:', result.data.msj)
    }
    clearStates()
    hide(!show)
    setRestart(!restart)

  }



  const getInfo = async () => {
    
    const formatDate = Moment().format('YYYY-MM-DD')
    setDate(formatDate)
    let resDNI = await getFormats('employee')
    resDNI = resDNI.data.data.filter(item => item.Status.includes('activo'))
    setEmpleados(resDNI)
    var lider = resDNI.filter(item => item.personalACargo.includes("Si"))


    setListaLider(lider)
    setListaContabilidad(lider.filter(item => item.areaTrabajo.includes("CYF")))
    setListaTH(lider.filter(item => item.areaTrabajo.includes("TH")))
    setListaGcia(lider.filter(item => item.areaTrabajo.includes("GCIA")))



    if (IdFormat_PK === 'en creacion') {

      setfirmaSolicitante({
        ApellidoTrabajador: sessionUser.ApellidoTrabajador,
        IdEmployee_FK: sessionUser.IdEmployee_FK,
        NombreTrabajador: sessionUser.NombreTrabajador,
        fecha: '',

      })

      setfirmaRecibidoTH({
        ApellidoTrabajador: "definir",
        IdEmployee_FK: "",
        NombreTrabajador: "Sin",
        fecha: '',
      })


    } else {

      var firma = JSON.parse(data.firmaSolicitante)
      setfirmaSolicitante(firma)
      setSignStatus({ ...signStatus, firmaSolicitante: firma.image ? true : false })
      firma = JSON.parse(data.firmaTH)
      setSignStatus({ ...signStatus, firmaTH: firma.image ? true : false })
      setfirmaTH(firma)
      firma = JSON.parse(data.firmaCYF)
      setSignStatus({ ...signStatus, firmaCYF: firma.image ? true : false })
      setfirmaCYF(firma)
      firma = JSON.parse(data.firmaGerente)
      setSignStatus({ ...signStatus, firmaGerente: firma.image ? true : false })
      setfirmaGerente(firma)
      firma = JSON.parse(data.firmaRecibidoTH)
      setSignStatus({ ...signStatus, firmaRecibidoTH: firma.image ? true : false })
      setfirmaRecibidoTH(firma)
      if (data.Status === 'Aprobado' && isTH) {

        setfirmaRecibidoTH({
          ApellidoTrabajador: sessionUser.ApellidoTrabajador,
          IdEmployee_FK: sessionUser.IdEmployee_FK,
          NombreTrabajador: sessionUser.NombreTrabajador,
          fecha: '',

        })
      }


    }


    if (data.Status === 'creado' && !isView || data.Status === 'firmadoTH' && !isView) {
      setIsEdit(true)

    }


  }

  const setLeader = (target) => {
    const { id, lang, value } = target
    let lider = empleados.filter(item => item.IdEmployee_FK.includes(id))
    setfirmaCYF({
      ApellidoTrabajador: lider.length === 1 ? lider[0].ApellidoTrabajador : "contrato",
      IdEmployee_FK: lider.length === 1 ? lider[0].IdEmployee_FK : "",
      NombreTrabajador: lider.length === 1 ? lider[0].NombreTrabajador : "Seleccione el",
      fecha: '',
    })
    setMakeLeader(lider.length === 1 && lider[0].IdEmployee_FK === sessionUser.IdEmployee_FK ? true : false)
    setData({
      ...data,
      CeCo: lider.length === 1 ? lang : "sin contrato",
      Contrato: lider.length === 1 ? value : ""
    })
  }
  const clearStates = () => {
    data = []
    setData('')
    setDate('')
  }

  const verifyNext = () => {
    let tempWF = ''
    let tempNext = ''
    if (isEdit) {
      tempNext = { Status: data.Status, wf: false }
    } else if (data.Status === 'Aprobado' && isTH) { tempNext = { Status: 'Finalizado', wf: false } }

    else {
      const _signs = {
        fsol: sessionUser.IdEmployee_FK === firmaSolicitante.IdEmployee_FK,
        fTH: sessionUser.IdEmployee_FK === firmaTH.IdEmployee_FK,
        fCYF: sessionUser.IdEmployee_FK === firmaCYF.IdEmployee_FK,
        fGte: sessionUser.IdEmployee_FK === firmaGerente.IdEmployee_FK
      }

      if (_signs.fsol && !_signs.fTH && !_signs.fCYF && !_signs.fGte) {

        tempNext = { Status: "creado", wf: true }
        tempWF = { ...tempWF, IdEmployee_FK: firmaTH.IdEmployee_FK, action: 'firmar TH' }
      }
      else if (_signs.fsol && _signs.fTH && !_signs.fCYF && !_signs.fGte) {
        tempNext = { Status: "firmadoTH", wf: true }
        tempWF = { ...tempWF, IdEmployee_FK: firmaCYF.IdEmployee_FK, action: 'firmar Contabilidad' }
      }
      else if (_signs.fsol && !_signs.fTH && _signs.fCYF && !_signs.fGte) {
        if (data.Status === "creando") {
          tempNext = { Status: "creado", wf: true }
          tempWF = { ...tempWF, IdEmployee_FK: firmaTH.IdEmployee_FK, action: 'firmar TH' }
        }
        else if (data.Status === "creado") {
          tempNext = { Status: "firmadoCYF", wf: true }
          tempWF = { ...tempWF, IdEmployee_FK: firmaGerente.IdEmployee_FK, action: 'firmar Gerente' }
        }
      }
      else if (_signs.fsol && !_signs.fTH && !_signs.fCYF && _signs.fGte) {
        if (data.Status === "creando") {
          tempNext = { Status: "creado", wf: true }
          tempWF = { ...tempWF, IdEmployee_FK: firmaTH.IdEmployee_FK, action: 'firmar TH' }
        }
        else if (data.Status === "firmadoTH") {
          tempNext = { Status: "Aprobado", wf: false }
        }
      } else if (!_signs.fsol && _signs.fTH && !_signs.fCYF && !_signs.fGte) {
        if ((firmaSolicitante.IdEmployee_FK === firmaCYF.IdEmployee_FK) && firmaSolicitante.image && firmaCYF.image) {
          tempNext = { Status: "firmadoCYF", wf: true }
          tempWF = { ...tempWF, IdEmployee_FK: firmaGerente.IdEmployee_FK, action: 'firmar Gerente' }
        }
        else {
          tempNext = { Status: "firmadoTH", wf: true }
          tempWF = { ...tempWF, IdEmployee_FK: firmaCYF.IdEmployee_FK, action: 'firmar Contabilidad' }
        }
      }
      else if (!_signs.fsol && !_signs.fTH && _signs.fCYF && !_signs.fGte) {
        if ((firmaSolicitante.IdEmployee_FK === firmaGerente.IdEmployee_FK) && firmaSolicitante.image && firmaGerente.image) {
          tempNext = { Status: "Aprobado", wf: false }
        }
        else {
          tempNext = { Status: "firmadoCYF", wf: true }
          tempWF = { ...tempWF, IdEmployee_FK: firmaGerente.IdEmployee_FK, action: 'firmar Gerente' }
        }
      }
      else if (!_signs.fsol && !_signs.fTH && !_signs.fCYF && _signs.fGte) {
        tempNext = { Status: "Aprobado", wf: false }
      }


    }
    setNextWF(tempWF)
    setNext(tempNext)
    return { nextWF: tempWF, next: tempNext }
  }

  const onSubmit = async e => {
    e.preventDefault()
    var verify = ""
    var nextWF = []
    if (dataAct.lugarActividades === 'N/A' &&
      dataAct.areaTrabajo === 'N/A' &&
      dataAct.personalACargo === 'N/A' &&
      dataAct.cargo === 'N/A' &&
      dataAct.tipoContrato === 'N/A' &&
      dataAct.salario.otroSalario === 'N/A' &&
      dataAct.salario.primaAlimentacion === 'N/A' &&
      dataAct.salario.primaLocalizacion === 'N/A' &&
      dataAct.salario.primaMovilizacion === 'N/A' &&
      dataAct.salario.textoOtroSalario === 'N/A' &&
      dataAct.salario.tipo === 'N/A' &&
      dataAct.salario.valor ==='N/A' &&
      dataAct.CeCo === 'N/A' &&
      dataAct.tipoNomina === 'N/A' 
    ) {
      toast.error('debe modificar  al menos una (1) condicion')
      return
    }
    let creando = data.Status === "creando"
    if ((creando && sessionUser.IdEmployee_FK === firmaSolicitante.IdEmployee_FK && !firmaSolicitante.image) ||
      (creando && sessionUser.IdEmployee_FK === firmaTH.IdEmployee_FK && !firmaTH.image) ||
      (creando && sessionUser.IdEmployee_FK === firmaCYF.IdEmployee_FK && !firmaCYF.image) ||
      (creando && sessionUser.IdEmployee_FK === firmaGerente.IdEmployee_FK && !firmaGerente.image) ||
      (isSign && sessionUser.IdEmployee_FK === firmaTH.IdEmployee_FK && !firmaTH.image) ||
      (isSign && sessionUser.IdEmployee_FK === firmaCYF.IdEmployee_FK && !firmaCYF.image) ||
      (isSign && sessionUser.IdEmployee_FK === firmaGerente.IdEmployee_FK && !firmaGerente.image) ||
      (data.Status === 'Aprobado' && isTH && sessionUser.IdEmployee_FK === firmaRecibidoTH.IdEmployee_FK && !firmaRecibidoTH.image)
    ) {
      toast.error('por favor firme el documento')
      return
    }
    var result = ''

    verify = verifyNext()
    let _salario = data.dataAnt.salario
    data = {
      ...data,
      dataAnt: JSON.stringify(data.dataAnt),
      dataAct: JSON.stringify(dataAct),
      firmaSolicitante: JSON.stringify(firmaSolicitante),
      firmaTH: JSON.stringify(firmaTH),
      firmaCYF: JSON.stringify(firmaCYF),
      firmaGerente: JSON.stringify(firmaGerente),
      firmaRecibidoTH: JSON.stringify(firmaRecibidoTH),
      createdBy: firmaSolicitante.IdEmployee_FK,
      Status: verify.next.Status
    }
    if (isProcess) {
      let dataEmployee = { IdFormat_PK: data.IdFormat_FK,  }
      if (dataAct.lugarActividades !== 'N/A') dataEmployee = { ...dataEmployee, lugarActividades: dataAct.lugarActividades }
      if (dataAct.areaTrabajo !== 'N/A') dataEmployee = { ...dataEmployee, areaTrabajo: dataAct.areaTrabajo }
      if (dataAct.personalACargo !== 'N/A') dataEmployee = { ...dataEmployee, personalACargo: dataAct.personalACargo }
      if (dataAct.cargo !== 'N/A') dataEmployee = { ...dataEmployee, cargo: dataAct.cargo }
      if (dataAct.tipoContrato !== 'N/A') dataEmployee = { ...dataEmployee, tipoContrato: dataAct.tipoContrato }
      if (dataAct.CeCo !== 'N/A') dataEmployee = { ...dataEmployee, CeCo: dataAct.CeCo }
      if (dataAct.tipoNomina !== 'N/A') dataEmployee = { ...dataEmployee, tipoNomina: dataAct.tipoNomina }
      if (dataAct.salario.tipo !== 'N/A') _salario = { ..._salario, tipo: dataAct.salario.tipo }
      if (dataAct.salario.valor !== 'N/A') _salario = { ..._salario, valor: dataAct.salario.valor }
      if (dataAct.salario.primaAlimentacion !== 'N/A') _salario = { ..._salario, primaAlimentacion: dataAct.salario.primaAlimentacion }
      if (dataAct.salario.primaLocalizacion !== 'N/A') _salario = { ..._salario, primaLocalizacion: dataAct.salario.primaLocalizacion }
      if (dataAct.salario.primaMovilizacion !== 'N/A') _salario = { ..._salario, primaMovilizacion: dataAct.salario.primaMovilizacion }
      if (dataAct.salario.otroSalario !== 'N/A') _salario = { ..._salario, otroSalario: dataAct.salario.otroSalario }
      if (dataAct.salario.textoOtroSalario !== 'N/A') _salario = { ..._salario, textoOtroSalario: dataAct.salario.textoOtroSalario }
      

      result = await updateFormat({ ...dataEmployee, salario: JSON.stringify( _salario) }, "employee")
      if (result.data.status === 200) {
        result = await updateFormat({IdFormat_PK:IdFormat_PK, Status:'Finalizado', firmaRecibidoTH: JSON.stringify(firmaRecibidoTH)}, typef)
        toast.success("Empleado actualizado exitosamente")
      }
      else {console.log(result.data.status)
        toast.error(' error al procesar los datos',result.data.msj); return}

    }
    else if (IdFormat_PK === 'en creacion') {
      result = await CreateFormat(data, typef)
      if (result.data.status === 200) {
        nextWF = {
          TaskDescription: `Cambio de condición ${data.NombreTrabajador} ${data.ApellidoTrabajador}`,
          action: verify.nextWF.action,
          Status: "pendiente",
          Format: typef,
          dateCreation: date,
          documents: "Cambio Condición",
          IdFormat_FK: result.data.data.insertId,
          IdEmployee_created: sessionUser.IdEmployee_FK,
          IdEmployee_FK: verify.nextWF.IdEmployee_FK,
        }


        result = await newWorkflow(nextWF)
        if (result.data.status === 200) {
          toast.success("Solicitud creada exitosamente")
        }
        else {
          toast.error(result.data.msj); return
        }
      }
      else {
        toast.error(result.data.msj); return
      }
    }
    else {
      delete data.fechaCreacion
      delete data.fechaModificacion
      if (signStatus.firmaSolicitante) delete data.firmaSolicitante
      if (signStatus.firmaTH) delete data.firmaTH
      if (signStatus.firmaGerente) delete data.firmaGerente
      if (signStatus.firmaCYF) delete data.firmaCYF

      result = await updateFormat(data, typef)
      if (result.data.status === 200 && isSign && !isEdit) {
        result = await updateWorkflow({ ...workflow, Status: "finalizado", dateFinish: date })
        delete workflow.IdWorkFlow_PK
        if (data.Status !== 'Procesar' && verify.next.wf) {
          result = await newWorkflow({
            ...workflow,
            IdEmployee_FK: verify.nextWF.IdEmployee_FK,
            IdEmployee_created: sessionUser.IdEmployee_FK,
            action: verify.nextWF.action
          })
        }
        if (result.data.status === 200) {
          let employee = {
            IdEmployee_PK: data.IdEmployee_FK
          }

          toast.success("Documento actualizado exitosamente")
        } else {
          toast.error(result.data.msj); return
        }
      } else if (result.data.status === 200 && isEdit) { toast.success("Documento actualizado exitosamente") }
      else if (result.data.status === 200 && isTH && data.Status === 'Finalizado') {

        result = await updateFormat(data, typef)
        if (result.data.status === 200 && isSign && !isEdit) {
          toast.success("Cambio de condición efectuado exitosamente")
        }
        else {
          result = await updateFormat({
            IdFormat_PK: data.IdFormat_FK, Status: 'Aprobado', firmaRecibidoTH: JSON.stringify({
              ApellidoTrabajador: "definir",
              IdEmployee_FK: "",
              NombreTrabajador: "Sin",
              fecha: '',
            })
          }, typef)
        }
      }
      else {
        toast.error(result.data.msj);
        if (isTH && data.Status === 'Finalizado') {
          result = await updateFormat({
            IdFormat_PK: data.IdFormat_FK, Status: 'Aprobado', firmaRecibidoTH: JSON.stringify({
              ApellidoTrabajador: "definir",
              IdEmployee_FK: "",
              NombreTrabajador: "Sin",
              fecha: '',
            })
          }, typef)
        }

        return


      }
    }


    clearStates()
    hide(!show)
    setRestart(!restart)

  }

  return (
    <>
      {show && (
        <Overlay style={{ zIndex: '1' }}>
          <SpinnerLoad trigger={firmaSolicitante} />


          <ModalContainer ref={modalRef} className="modal-container"><CloseButtonModal
            className="no-print"
            onClick={() => {
              clearStates()
              hide(!show)
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-x-square"
              viewBox="0 0 16 16"
            >
              <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </CloseButtonModal>
            <Form onSubmit={onSubmit}>
              {data.formatData && false ? <div dangerouslySetInnerHTML={{ __html: data.formatData }}></div> : <PrintArea ref={printRef} >
                <ModalHeader>
                  <table style={{ width: '100%' }}>
                    <tbody>
                      <tr>
                        <th style={{ width: '20%', fontSize: '10px', verticalAlign: 'bottom' }} rowSpan={3}>
                          <img
                            className="format_logo"
                            src={image}
                            style={{ paddingBottom: '7px', width: '75%', minWidth: "40px" }}
                            alt="Logo RMS"
                          /><br />
                          NIT 830.507.705-2
                        </th>
                        <th style={{ width: '55%', height: '35px' }}>RELIABILITY MAINTENANCE SERVICES S.A.S</th>
                        <th style={{ width: '25%' }}>{formato}</th>
                      </tr>
                      <tr>
                        <th rowSpan={2}>{nombreFormato}</th>
                        <th style={{ fontSize: '10px' }}>Revisión: {revision}</th>
                      </tr>
                      <tr>
                        <th style={{ fontSize: '10px' }}>Fecha de revisión: {fechaRev}</th>
                      </tr>
                    </tbody>
                  </table>
                </ModalHeader>

                <Frame isView={isView} style={{ padding: '5px' }}>
                  <table style={{ width: '100%', borderWidth: '0px', padding: '0px', fontSize: '12px' }}>
                    <tbody>
                      <tr style={{ width: '100%', borderWidth: '0px', fontSize: '10px' }}>
                        <th style={{ width: '20%', borderWidth: '0px', fontSize: '10px' }}><label>CAMBIO DE CONDICIÓN ES:</label></th>
                        <td colSpan={1} style={{ width: '20%', borderWidth: '0px', textAlign: 'center' }}></td>
                        <td colSpan={2} style={{ width: '20%', borderWidth: '0px', textAlign: 'center' }}>
                          <label style={{ right: '10px' }}>
                            Temporal
                            <input
                              style={{ width: '20px' }}
                              type={'radio'}
                              name={'tipoCambio'}
                              value={'Temporal'}
                              checked={`${data.tipoCambio}` === 'Temporal' ? 'checked' : ''}
                              onChange={handleChange}
                              required
                              disabled={isView || isTH ? true : false}
                            />
                          </label>
                        </td>
                        <td colSpan={2} style={{ width: '20%', borderWidth: '0px', textAlign: 'center' }}>

                          <label style={{ right: '10px' }}>
                            Definitiva
                            <input
                              style={{ width: '20px' }}
                              type={'radio'}
                              name={'tipoCambio'}
                              value={'Definitiva'}
                              checked={`${data.tipoCambio}` === 'Definitiva' ? 'checked' : ''}
                              onChange={handleChange}
                              required
                              disabled={isView || isTH ? true : false}
                            />
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <th style={{ borderWidth: '0px', fontSize: '10px' }}><label>NOMBRE:</label></th>
                        <td colSpan={3} style={{ borderWidth: '0px', fontSize: '12px' }}><input
                          style={{ width: '100%' }}
                          className="disable"
                          name='createdBy'
                          value={data.NombreTrabajador + ' ' + data.ApellidoTrabajador}
                          onChange={() => { }}
                          required
                          disabled={true}
                        ></input></td>
                        <td style={{ borderWidth: '0px', fontSize: '10px' }} >
                          <label>CEDULA:</label>
                        </td>
                        <td colSpan={1} style={{ borderWidth: '0px', fontSize: '12px' }} > <input
                          className="disable"
                          name='createdBy'
                          value={data.IdEmployee_FK}
                          onChange={() => { }}
                          required
                          disabled={true}
                        ></input></td>
                      </tr>

                      <tr style={{ width: '100%', borderWidth: '0px' }}>
                        <th style={{ width: '20%', borderWidth: '0px', fontSize: '10px' }}  >
                          <label>FECHA DE INGRESO:</label>
                        </th>
                        <td style={{ width: '20%', borderWidth: '0px', fontSize: '10px' }}> <input
                          className="disable"
                          name='createdBy'
                          value={data.fechaContratacion}
                          onChange={() => { }}
                          required
                          disabled={true}
                        ></input></td>
                        <th style={{ width: '10%', borderWidth: '0px', fontSize: '10px' }}>
                          <label>SOLICITUD:</label>
                        </th>
                        <td style={{ width: '20%', borderWidth: '0px', fontSize: '10px' }} > <input
                          className="disable"
                          name='createdBy'
                          value={IdFormat_PK}
                          onChange={() => { }}
                          required
                          disabled={true}
                        ></input></td>
                        <th style={{ width: '10%', borderWidth: '0px', fontSize: '10px' }}>
                          <label>ESTADO:</label>
                        </th>
                        <td style={{ width: '20%', borderWidth: '0px', fontSize: '10px' }} > <input
                          className="disable"
                          name='createdBy'
                          value={data.Status}
                          onChange={() => { }}
                          required
                          disabled={true}
                        ></input></td>


                      </tr>
                    </tbody>
                  </table>




                </Frame>
                <Frame isView={isView} style={{ borderWidth: '0px' }}>
                  <table style={{ width: '100%' }}>
                    <tbody>
                      <tr style={{ textAlign: 'center' }}>
                        <th style={{ width: '33%' }} >CONCEPTO</th>
                        <th style={{ width: '33%' }}>INFORMACION CONTRACTUAL <br></br><h5> (Definir las condiciones reales del contrato)</h5></th>
                        <th style={{ width: '33%' }}>CAMBIO PROPUESTO <br></br><h5>(En aquellosPuntos SinCambios se debe dejar N/A)</h5></th>
                      </tr>
                      <tr >
                        <td>LUGAR DE TRABAJO/ SERVICIO</td>
                        <td style={{ textAlign: 'center', fontWeight:'350' }}>{data.dataAnt.lugarActividades}</td>
                        <td style={{ textAlign: 'center' }}>
                          <SelectOption type={"city"} searchable={true} state={dataAct} setState={setdataAct} nameState={'lugarActividades'} required NotAv disabled={isView ? true : false} />

                        </td>
                      </tr>
                      <tr>
                        <td>PROCESO /AREA</td>
                        <td style={{ textAlign: 'center' }}>
                          <SelectOption type={"workArea"} searchable={true} state={data.dataAnt} setState={setData} nameState={'areaTrabajo'} required disabled={true} widthSelect='100%' />
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <SelectOption type={"workArea"} searchable={true} state={dataAct} setState={setdataAct} nameState={'areaTrabajo'} required NotAv disabled={isView ? true : false} />

                        </td>
                      </tr>
                      <tr>
                        <td>CARGO</td>
                        <td style={{ textAlign: 'center', fontWeight:'350' }}>{data.dataAnt.cargo}</td>
                        <td style={{ textAlign: 'center', fontWeight:'350' }}>
                          {isView ? dataAct.cargo :

                            <select style={{ width: '100%', textAlign: 'center', fontWeight:'350' }} value={dataAct.manualFunciones} onChange={e => { setdataAct({ ...dataAct, manualFunciones: e.target.value, cargo: e.target.selectedOptions[0].id }) }} disabled={isView ? true : false} title="seleccione..">

                              <option value={''} id={"N/A"}    >N/A

                              </option>
                              {list.Manuales.filter(item => item.Status === "disponible" && item.creadoPor === sessionUser.IdEmployee_FK).map((article, i) => {//list.Manuales.filter(item => item.Status === "disponible" && item.creadoPor === sessionUser.IdEmployee_FK).map((article, i) => {

                                return (
                                  <option key={i} id={article.Cargo} value={article.IdFormat_PK}  >{article.IdFormat_PK} - {article.Cargo} </option>
                                );
                              })}
                            </select>


                          }

                        </td>
                      </tr>
                      <tr>
                        <td>PERSONAL A CARGO</td>
                        <td style={{ textAlign: 'center', fontWeight:'350' }}>{data.dataAnt.personalACargo}</td>
                        <td style={{ textAlign: 'center', fontWeight:'350' }}>
                          {isView ? dataAct.personalACargo :
                            <select style={{ width: '100%', textAlign: 'center', fontWeight:'350' }} value={dataAct.personalACargo} onChange={e => setdataAct({ ...dataAct, personalACargo: e.target.value })} disabled={isView || isTH ? true : false} title="seleccione..">
                              <option value={'N/A'}  >N/A </option>
                              <option value={'Si'}  >Si </option>
                              <option value={'No'}  >No </option>
                            </select>}
                        </td>
                      </tr>
                      <tr>
                        <td>TIPO DE CONTRATO</td>
                        <td style={{ textAlign: 'center', fontWeight:'350' }}>
                          <SelectOption type={"typeContract"} searchable={true} state={data.dataAnt} setState={setData} nameState={'tipoContrato'} required disabled={true} />

                        </td>
                        <td style={{ textAlign: 'center', fontWeight:'350' }}>
                          <SelectOption type={"typeContract"} searchable={true} state={dataAct} setState={setdataAct} nameState={'tipoContrato'} required NotAv disabled={isView ? true : false} />

                        </td>
                      </tr>
                      <tr>
                        <td>TIPO DE SALARIO O REMUNERACION</td>
                        <td style={{ textAlign: 'center', fontWeight:'350'}}>{data.dataAnt.salario.tipo}</td>
                        <td style={{ textAlign: 'center', fontWeight:'350'}}>
                          {isView ? dataAct.salario.tipo :
                            <select style={{ width: '100%', textAlign: 'center', fontWeight:'350' }} value={dataAct.salario.tipo} onChange={e => setdataAct({ ...dataAct, salario: { ...dataAct.salario, tipo: e.target.value } })} disabled={isView || isTH ? true : false} title="seleccione..">
                              <option value={'N/A'}  >N/A </option>
                              <option value={'Basico'}  >Básico </option>
                              <option value={'Indefinido'}  >Integral </option>
                            </select>}
                        </td>
                      </tr>
                      <tr>
                        <td>SALARIO O REMUNERACIÓN MENSUAL</td>
                        <td style={{ textAlign: 'center', fontWeight:'350' }}>{monedaCOP(data.dataAnt.salario.valor)}</td>
                        <td style={{ textAlign: 'center' , fontWeight:'350'}}>
                          {isView ? dataAct.salario.valor === 'N/A' ? 'N/A' : monedaCOP(dataAct.salario.valor) :
                            <input type='text' style={{ width: '100%', textAlign: 'center' }} value={dataAct.salario.valor} onChange={e => setdataAct({ ...dataAct, salario: { ...dataAct.salario, valor: e.target.value } })} disabled={isView || isTH ? true : false} />
                          }
                        </td>
                      </tr>
                      <tr>
                        <td>CENTRO DE COSTOS</td>
                        <td style={{ textAlign: 'center', fontWeight:'350' }}>{data.dataAnt.CeCo}</td>
                        <td style={{ textAlign: 'center', fontWeight:'350' }}>
                          <SelectOption type={"CeCo"} searchable={true} state={dataAct} setState={setdataAct} nameState={'CeCo'} required NotAv disabled={isView ? true : false} />

                        </td>
                      </tr>
                      <tr>
                        <td>TIPO DE NOMINA</td>
                        <td style={{ textAlign: 'center', fontWeight:'350'  }}>{data.dataAnt.tipoNomina}</td>
                        <td style={{ textAlign: 'center', fontWeight:'350' }}>
                          {isView ? dataAct.tipoNomina :
                            <select style={{ width: '100%', textAlign: 'center' }} value={dataAct.tipoNomina} onChange={e => setdataAct({ ...dataAct, tipoNomina: e.target.value })} disabled={isView || isTH ? true : false} title="seleccione..">
                              <option value={'N/A'}  >N/A </option>
                              <option value={'Legal'}  >Legal </option>
                              <option value={'Convencional'}  >Convencional </option>
                            </select>}
                        </td>
                      </tr>
                      <tr>
                        <td>OTRO:  </td>
                        <td style={{ textAlign: 'center', fontWeight:'350' }}>
                          
                         
                           Alimentación: {monedaCOP(data.dataAnt.salario.primaAlimentacion)}<br></br>
                           Localización:{monedaCOP(data.dataAnt.salario.primaLocalizacion)}<br></br>
                           Movilización:{monedaCOP(data.dataAnt.salario.primaMovilizacion)}<br></br>
                         {data.dataAnt.salario.textoOtroSalario} : {monedaCOP(data.dataAnt.salario.otroSalario)} 
                          
                          </td>
                        <td style={{ textAlign: 'center', fontWeight:'350' }}>
                          {isView ? <>{dataAct.salario.primaAlimentacion==='N/A'?'N/A':monedaCOP(dataAct.salario.primaAlimentacion)}<br></br>
                          {dataAct.salario.primaLocalizacion==='N/A'?'N/A':monedaCOP(dataAct.salario.primaLocalizacion)}<br></br>
                          {dataAct.salario.primaMovilizacion==='N/A'?'N/A':monedaCOP(dataAct.salario.primaMovilizacion)}<br></br>
                          {dataAct.salario.otroSalario==='N/A'?'N/A':(dataAct.salario.textoOtroSalario + ':' + monedaCOP(dataAct.salario.otroSalario))}
                          </>  : <>
                          <input type='text' style={{ width: '100%', textAlign: 'center' }} value={dataAct.salario.primaAlimentacion} onChange={e => setdataAct({ ...dataAct, salario: { ...dataAct.salario, primaAlimentacion: e.target.value } })} disabled={isView || isTH ? true : false} /><br></br>
                          <input type='text' style={{ width: '100%', textAlign: 'center' }} value={dataAct.salario.primaLocalizacion} onChange={e => setdataAct({ ...dataAct, salario: { ...dataAct.salario, primaLocalizacion: e.target.value } })} disabled={isView || isTH ? true : false} /><br></br>
                          <input type='text' style={{ width: '100%', textAlign: 'center' }} value={dataAct.salario.primaMovilizacion} onChange={e => setdataAct({ ...dataAct, salario: { ...dataAct.salario, primaMovilizacion: e.target.value } })} disabled={isView || isTH ? true : false} /><br></br>
                           <input type='text' style={{ width: '60%', textAlign: 'center' }} value={dataAct.salario.textoOtroSalario} onChange={e => setdataAct({ ...dataAct, salario: { ...dataAct.salario, textoOtroSalario: e.target.value } })} disabled={isView || isTH ? true : false} />
                          :<input type='text' style={{ width: '35%', textAlign: 'center' }} value={dataAct.salario.otroSalario} onChange={e => setdataAct({ ...dataAct, salario: { ...dataAct.salario, otroSalario: e.target.value } })} disabled={isView || isTH ? true : false} />
                         
                          </> }
                        </td>

                      </tr>
                    </tbody>
                  </table>
                </Frame>
                <Frame isView={isView} style={{ borderWidth: '0px' }}>
                  <table style={{ width: '100%' }}>
                    <tbody>
                      <tr style={{ textAlign: 'center', maxHeight: '15px', height: '15px' }}>
                        <th style={{ width: '33%' }}>FECHA EFECTIVA</th>
                        <th style={{ width: '33%' }}>FECHA DE INICIO </th>
                        <th style={{ width: '33%' }}>FECHA DE TERMINACIÓN <br></br><h5 style={{ fontSize: '10px', fontWeight: '400' }}>(Toda Asignación Temporal debe tener especificada una fecha limite de terminación)</h5></th>
                      </tr>
                      <tr style={{ textAlign: 'center' }} >
                        <td><input
                          type='date'
                          name="fechaEfectiva"
                          value={data.fechaEfectiva}
                          onChange={handleChange}
                          required
                          disabled={isView ? true : false}
                        /></td>
                        <td style={{ textAlign: 'center' }}><input
                          type='date'
                          name="fechaInicio"
                          value={data.fechaInicio}
                          onChange={handleChange}
                          required
                          disabled={isView ? true : false}
                        /></td>
                        <td style={{ textAlign: 'center', fontWeight:'350' }}>
                          {`${data.tipoCambio}` === 'Temporal' ? <input
                            type='date'
                            name="fechaTerminacion"
                            value={data.fechaTerminacion}
                            onChange={handleChange}
                            required
                            disabled={isView ? true : false}
                          /> : 'N/A'}</td>
                      </tr>
                    </tbody>
                  </table>
                </Frame>
                <Frame isView={isView} style={{ borderWidth: '0px' }}>

                  <table style={{ width: '100%' }}>
                    <tbody>
                      <tr><th colSpan={2}> JUSTIFICACIÓN</th></tr>
                      <tr>
                        <th style={{ width: '30%' }}>MOTIVO DEL CAMBIO PROPUESTO:</th>
                        <td style={{ width: '70%' , fontWeight:'350'}}>
                          {isView?data.motivo:
                          <textarea
                            name={'motivo'}
                            rows={2}
                            value={data.motivo}
                            onChange={handleChange}
                            disabled={isView ? true : false}
                            required
                          />}
                        </td>
                      </tr>
                      <tr>
                        <th >BENEFICIOS PARA LA EMPRESA:</th>
                        <td  style={{ fontWeight:'350'}}>
                          {isView?data.beneficio:<textarea
                            name={'beneficio'}
                            rows={2}
                            value={data.beneficio}
                            onChange={handleChange}
                            disabled={isView ? true : false}
                            required
                          />}
                        </td>
                      </tr>
                      <tr>
                        <th >OBSERVACIONES:</th>
                        <td style={{ fontWeight:'350'}} >
                          {isView?data.observacion:
                          <textarea
                            name={'observacion'}
                            rows={2}
                            value={data.observacion}
                            onChange={handleChange}
                            disabled={isView ? true : false}
                            required
                          />}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Frame>




                <Frame isView={isView} style={{ borderWidth: '0px' }} >
                  <table style={{ width: '100%' }}>
                    <tbody>
                      <tr>
                        <th style={{ width: '18%', textAlign: 'center' }}> Aprobación</th>
                        <th style={{ width: '30%', textAlign: 'center' }}>Nombre</th>

                        <th style={{ width: '10%', textAlign: 'center' }}>Fecha</th>
                        <th style={{ width: '20%', textAlign: 'center' }}>Firma</th>
                      </tr>
                      <tr style={{ height: '50px' }}>
                        <th style={{ width: '10%' }}>Solicitante (Jefe / Lider Servicio)</th>
                        <th style={{ width: '30%' }}>
                          <input
                            className="disable"
                            value={firmaSolicitante.NombreTrabajador + ' ' + firmaSolicitante.ApellidoTrabajador}
                            onChange={e => { }}
                            disabled
                          />
                        </th>

                        <th style={{ width: '10%' }}>
                          <input
                            className="disable"
                            onChange={e => { }}
                            value={firmaSolicitante.fecha}

                            disabled={true}
                          />
                        </th>
                        <th style={{ textAlign: 'center' }}>
                          <Signer
                            enable={sessionUser.IdEmployee_FK === firmaSolicitante.IdEmployee_FK}
                            sign={firmaSolicitante}
                            setSign={setfirmaSolicitante}
                            doc={type}
                            user={sessionUser.IdEmployee_FK}
                            isView={isView}
                          />
                        </th>
                      </tr>
                      <tr style={{ height: '50px' }}>
                        <th style={{ width: '10%' }}>Aprobación 1 (Líder TH)</th>
                        <th style={{ width: '30%' }}>
                          {!isView && listaTH ? <select
                            name="talentoHumano"
                            value={firmaTH.IdEmployee_FK}
                            onChange={e => setfirmaTH({
                              ApellidoTrabajador: e.target.selectedOptions[0].lang,
                              NombreTrabajador: e.target.selectedOptions[0].id,
                              IdEmployee_FK: e.target.value,
                              fecha: ""
                            })}
                            required
                            disabled={isView ? true : false}
                            placeholder="seleccione..."
                            title="seleccione..."
                          >
                            <option key={0} selected disabled></option>

                            {listaTH.map((item, i) => {
                              return (
                                <option key={i + 1} id={item.NombreTrabajador} lang={item.ApellidoTrabajador} value={item.IdEmployee_FK}>{item.NombreTrabajador + " " + item.ApellidoTrabajador} </option>
                              );
                            })}
                          </select> : <input
                            onChange={e => { }}
                            className="disable"
                            value={firmaTH.NombreTrabajador + " " + firmaTH.ApellidoTrabajador}
                            required
                            disabled
                          />}
                        </th>

                        <th style={{ width: '10%' }}>
                          <input className="disable" value={firmaTH.fecha} onChange={() => { }} disabled={true} required isView={isView} />
                        </th>
                        <th style={{ textAlign: 'center' }}>
                          <Signer
                            enable={sessionUser.IdEmployee_FK === firmaTH.IdEmployee_FK}
                            sign={firmaTH}
                            setSign={setfirmaTH}
                            doc={typef}
                            user={sessionUser.IdEmployee_FK}

                          />
                        </th>
                      </tr>
                      <tr style={{ height: '50px' }}>
                        <th style={{ width: '10%' }}>Aprobación 2 (Líder Financiero)</th>
                        <th style={{ width: '30%' }}>
                          {!isView && listaContabilidad ? <select
                            name="contabilidad"
                            value={firmaCYF.IdEmployee_FK}
                            onChange={e => setfirmaCYF({
                              ApellidoTrabajador: e.target.selectedOptions[0].lang,
                              NombreTrabajador: e.target.selectedOptions[0].id,
                              IdEmployee_FK: e.target.value,
                              fecha: ""
                            })}
                            required
                            disabled={isView ? true : false}
                            placeholder="seleccione..."
                            title="seleccione..."
                          >
                            <option key={0} selected disabled></option>

                            {listaContabilidad.map((item, i) => {
                              return (
                                <option key={i + 1} id={item.NombreTrabajador} lang={item.ApellidoTrabajador} value={item.IdEmployee_FK}>{item.NombreTrabajador + " " + item.ApellidoTrabajador} </option>
                              );
                            })}
                          </select> : <input
                            onChange={e => { }}
                            className="disable"
                            value={firmaCYF.NombreTrabajador + " " + firmaCYF.ApellidoTrabajador}
                            required
                            disabled
                          />}
                        </th>

                        <th style={{ width: '10%' }}>
                          <input className="disable" value={firmaCYF.fecha} onChange={() => { }} disabled={true} isView={isView} />
                        </th>
                        <th style={{ textAlign: 'center' }} >
                          <Signer
                            enable={(sessionUser.IdEmployee_FK === firmaCYF.IdEmployee_FK) || makeLeader}
                            sign={firmaCYF}
                            setSign={setfirmaCYF}
                            doc={typef}
                            user={sessionUser.IdEmployee_FK}
                          />
                        </th>
                      </tr>
                      <tr style={{ height: '50px' }}>
                        <th style={{ width: '10%' }}>Aprobación 3 (Gerente General)</th>
                        <th style={{ width: '30%' }}>
                          {!isView && ListaGcia ? <select
                            name="gerencia"
                            value={firmaGerente.IdEmployee_FK}
                            onChange={e => setfirmaGerente({
                              ApellidoTrabajador: e.target.selectedOptions[0].lang,
                              NombreTrabajador: e.target.selectedOptions[0].id,
                              IdEmployee_FK: e.target.value,
                              fecha: ""
                            })}
                            required
                            disabled={isView ? true : false}
                            placeholder="seleccione..."
                            title="seleccione..."
                          >
                            <option key={0} selected disabled></option>

                            {ListaGcia.map((item, i) => {
                              return (
                                <option key={i + 1} id={item.NombreTrabajador} lang={item.ApellidoTrabajador} value={item.IdEmployee_FK}>{item.NombreTrabajador + " " + item.ApellidoTrabajador} </option>
                              );
                            })}
                          </select> : <input
                            onChange={e => { }}
                            className="disable"
                            value={firmaGerente.NombreTrabajador + " " + firmaGerente.ApellidoTrabajador}
                            required
                            disabled
                          />}
                        </th>

                        <th style={{ width: '10%' }}>
                          <input className="disable" value={firmaGerente.fecha} onChange={e => { }} disabled={true} isView={firmaCYF === true ? true : false} />
                        </th>
                        <th style={{ textAlign: 'center' }}>
                          <Signer
                            enable={sessionUser.IdEmployee_FK === firmaGerente.IdEmployee_FK}
                            sign={firmaGerente}
                            setSign={setfirmaGerente}
                            doc={typef}
                            user={sessionUser.IdEmployee_FK}
                          />
                        </th>
                      </tr>
                      <tr style={{ height: '50px' }}>
                        <th style={{ width: '10%' }}>Recibido por (TH)</th>
                        <th style={{ width: '30%' }}>
                          <input
                            className="disable"
                            value={firmaRecibidoTH.NombreTrabajador + ' ' + firmaRecibidoTH.ApellidoTrabajador}
                            onChange={e => { }}
                            disabled

                          />
                        </th>

                        <th style={{ width: '10%' }}>
                          <input className="disable" value={firmaRecibidoTH.fecha} onChange={e => { }} disabled={true} isView={firmaCYF === true ? true : false} />
                        </th>
                        <th style={{ textAlign: 'center' }}>
                          <Signer
                            enable={isProcess && sessionUser.IdEmployee_FK === firmaRecibidoTH.IdEmployee_FK}
                            sign={firmaRecibidoTH}
                            setSign={setfirmaRecibidoTH}
                            doc={typef}
                            user={sessionUser.IdEmployee_FK}
                          />
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </Frame>
                {data.Status === "Rechazado" && <Frame isView={true} >
                  <table style={{ width: '100%' }}>
                    <tbody>
                      <tr>
                        <td>
                          <label>MOTIVO DEL RECHAZO  </label>
                          <textarea
                            style={{ height: '60px' }}
                            name={'rechazo'}
                            value={data.rechazo}
                            onChange={handleChange}

                            disabled={true}
                          ></textarea>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Frame>}

              </PrintArea>}

              <Row className="no-print">
                <Col></Col>
                <Col></Col>
                <Col></Col>
                <Col></Col>

                {(!isView || (data.Status !== 'Rechazado' && isSign) || isProcess) ? (
                  <>
                    <Col>
                      <Button
                        className="btn btn-danger"
                        onClick={() => {
                          clearStates()
                          hide(!show)
                        }}
                      >
                        Cancelar
                      </Button>
                    </Col>

                    <Col>
                      {isProcess ? <Button type="submit" className="btn btn-success">
                        Procesar
                      </Button> : <Button type="submit" className="btn btn-success">
                        Guardar
                      </Button>}
                    </Col>
                  </>
                ) : (<> <Col>

                  <Col>
                    <Button
                      className="btn btn-info"
                      onClick={convertToPdf}
                    >
                      <i className="bi bi-printer"></i>
                    </Button>

                  </Col>
                </Col>
                  <Col>
                    <Button
                      className="btn btn-secundary"
                      onClick={() => {
                        clearStates()
                        hide(!show)
                        // abrirVentanaEmergente()
                      }}
                    >
                      Cerrar
                    </Button>
                  </Col></>
                )}

                {data.Status !== 'creando' && isSign && <Col>
                  <Button
                    className="btn btn-warning"
                    onClick={() => {
                      setActionBtn(data.Status === 'Rechazado' ? "Reanudar" : 'Rechazar')


                      setModalAction(true)

                    }}
                  >

                    {data.Status === "Rechazado" ? 'Reanudar' : 'Rechazar'}
                  </Button>
                </Col>}
              </Row>
            </Form>
          </ModalContainer>

        </Overlay>
      )}
      <Modal
        show={modalAction}
        onHide={() => {
          setModalAction(false)
        }}
        centered
      >
        <Modal.Header closeButton>
          <h5>{actionBtn} Solicitud</h5>
        </Modal.Header>
        <Modal.Body>
          <p>¿Realmente desea {actionBtn.toLowerCase()} la Solicitud {IdFormat_PK}? </p>

          {actionBtn === "Rechazar" && <><p>Por favor especifique el motivo para rechazar la requicisión</p>
            <textarea
              style={{ width: '100%', height: '60px' }}
              name={'rechazo'}
              value={data.rechazo}
              onChange={handleChange}

              disabled={false}
            /></>}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              console.log(data)
              setData({ ...data })
              setModalAction(false)

            }}
          >
            Cancelar
          </Button>
          <Button className="btn btn-danger"
            onClick={() => {
              if (data.rechazo === "") {
                toast.error('indique el motivo para rechazar')
                return
              }
              ActionSol()
              setModalAction(false)
            }}
          >
            {actionBtn}
          </Button>
        </Modal.Footer>
      </Modal>
      {ShowManuales &&
        <GTHFOR076
          sessionUser={sessionUser}
          data={dataManual}
          setData={setDataManual}
          show={ShowManuales}
          hide={hideManuales}
          Status={"isView"}

          isTH={false}
          restart={restart}
          setRestart={setRestart}
          isView={true}
          IdFormat_PK={manualFunciones}
        />}
    </>
  )
}

export default GTHFOR244


const Frame = styled.div`
  width: 20.89cm;
  padding-bottom: 0px;
  margin-bottom: 6px;
  align-items: center;
  justify-content: left;
  border-width: 1px;
  border-color: #1a004a;
  border-style: solid;
  font-size: 12px;
  font-weight: 1000;
  .photo {
    background: #ebf1de;
  }
  table {

  border-width: 0px;
  }
  .rms {
    background: #f2f2f2;
  }
  label {
    padding-left: 5px;
    display: flex;
    font-size: 10px;
    font-weight: bold;
    text-align: 'center';
  }
  select {
    
    background: ${props => (props.isView ? '#FFF' : '#cee1ed')};
    
  
}
  input {
    text-align: center;
    width: 100%;
    border-bottom: 1px solid black;
    border-color: #1a004a;
    background: ${props => (props.isView ? '#FFF' : '#cee1ed')};
    padding-left: 5px;
    /* margin-top: 5px; */
  }
  textarea {
    text-align: justify;
    width: 100%;
    border-bottom: 1px solid black;
    border-color: #1a004a;
    background: ${props => (props.isView ? '#FFF' : '#cee1ed')};
    resize: none;
    padding-left: 5px;
  }
  input.edit {
    background: #cee1ed;
  }
  input.disable {
    background: #fff;
  }
  h2 {
    font-size: 14px;
    font-weight: bold;
    text-align: 'center';
  }
  h5 {
    font-size: 10px;
    margin-bottom: 0px;
    font-weight: 400;
    text-align: 'center';
  }
  th {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    text-align: center;
    vertical-align: center;
    padding-left: 5px;
    input {
      width: 100%;
    }
  }
  tr {
    height:25px;
    border-width: 0px;
    border-color: #1a004a;
    border-style: solid;
  }
  td {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
  }
`

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgb(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalContainer = styled.div`
  position: relative
  width: 20.89cm;
  min-width: 20.89cm;
  max-height: 90vh;
  ©height: 90vh;
  background: #fff;
  position: relative;
  border-radius: 5px;
  overflow: scroll;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 0.4cm;

  @media print {
    padding-top: 20px;
    padding-left: 25px;
    max-height: 100vh;
    min-height: 100vh;
    min-height: 30.6cm;
    width: 100%;
    height: 30.6cm;
    /* Estilos para la versión impresa */
    overflow: hidden;
    /* Aquí ocultamos los componentes que no queremos imprimir */
    @page {
      size: 22.3cm 30.6cm ; /* Puedes usar diferentes tamaños de papel como A4, Letter, etc. */
      margin: 0; /* Márgenes laterales en 0 */
      padding: 0px;
      orientation: portrait ; /* Cambiar a 'landscape' si necesitas orientación horizontal */
    }
     
      /* Ajustar el área de impresión */
    .no-print {
      display: none;
    }
} 
`
const PrintArea = styled.div`

width: 20.89cm;

`



const ModalHeader = styled.div`
  width: 20.89cm;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
  padding-bottom: 10px;
  td {
    font-weight: 1000;
    
  }
  th {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    
  }
  tr {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
  }
  table {
    border-width: 0px;
    border-color: #1a004a;
    border-style: solid;
    text-align: center;
    vertical-align: center;
    font-size: 14px;
  }
  h3 {
    font-weight: 500;
    font-size: 16px;
    color: #1766dc;
  }
`
const CloseButtonModal = styled.div`
  position: absolute;
  top: 16px;
  right: 17px;
  width: 20px;
  height: 20px;
  border: none;
  background: none;
  cursor: pointer;
  transition: 0.3s ease all;
  border-radius: 5px;
  color: #1766dc;
  &:hover {
    background: #f2f2f2;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`;