import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Button, Col, Form, Row } from 'react-bootstrap'
import Moment from 'moment'
import { toast } from 'react-toastify'
import { CreateFormat, updateFormat, updateWorkflow } from './request'
import IMAGG from '../../../../assets/img/IMAGG.png'
import ReactQuill from 'react-quill'
import Signer from '../signer'
import SpinnerLoad from '../spinnerLoad'
import image from '../../../../assets/img/Logo_b-st.png'
import { capitalLeter } from '../functions'

export const GTHFOR076 = ({
  sessionUser,
  show,
  hide,
  restart,
  setRestart,
  isView,
  data = [],
  isTH,
  status,
  isSign = false,
  setData,
  workflow,
  IdFormat_PK = "En Creación",
  list

}) => {

  /*---------ESTADOS------------------
    [     en creacion      ] => docuento guardado sin firmas
            V
    [  dispobible  ] => cuando el empleado lo ha firmado
            V
    [     firmaJefe     ] => cuando TH lo ha firmado
            V
    [     finalizado     ] cuando TH lo ha firmado
            V
      

*/
  //-------------------------Info Formato
  const formato = 'GTH-FOR-76'
  const revision = '7'
  const fechaRev = '29/04/2024'
  const nombreFormato = 'PERFIL DEL CARGO Y MANUAL DE FUNCIONES'
  const type = 'GTHFOR76'

  const HSEIni = {
    SST: "<ol><li>Procurar el cuidado integral de su salud. </li><li>Suministrar información clara, veraz y completa sobre su estado de salud. 3.\t</li><li>Cumplir la legislación normas, reglamentos e instrucciones del Sistema de Gestión de la Seguridad y Salud en el Trabajo de la empresa y lineamientos Seguridad vial. Informar oportunamente al empleador o contratante acerca de los peligros y riesgos latentesen su sitio de trabajo y Seguridad vial. </li><li>Participar en las actividades de capacitación en seguridad y salud en el trabajo definido en el plan de capacitación del SG-SST y Seguridad vial. </li><li>Participar y contribuir al cumplimiento de los objetivos del Sistema de Gestión de la Seguridad y Salud en el Trabajo SG-SST.</li><li>anexo-2_-roles-y-responsabilidades-convertido.docx</li><li>Reportar siniestros viales ocurridos en desplazamientos laborales. </li><li>Es responsabilidad de toda persona que tome parte en el tránsito como peatón, ciclista, motociclista, conductor o pasajero, comportarse en forma que no obstaculice, perjudique o ponga en riesgo su vida y la de los demás; cumplir las normas y señales de tránsito que le sean aplicables, así como obedecer las indicaciones dadas por la autoridad de tránsito. </li><li>Hacer uso de los mecanismos de comunicación y consulta definidos por RMS. </li><li>Fomentar las buenas prácticas y políticas de la Empresa y de la industria. </li><li>Participar en jornadas de ejercicio y deportes programadas, como actividades preventivas </li><li>Aplicar el Auto cuidado como herramienta de control y abstenerse de realizar actividades que lo coloquen en riesgo individual o al equipo de trabajo. </li><li>Identificar las tareas críticas y peligros del proceso a su cargo y aplicar las medidas de control establecidas.</li><li>Velar por la integridad y seguridad de personal propio y de contratistas.</li></ol>",
    medioAmb: "<ol><li>Promover las mejores prácticas ambientales en el desarrollo de la labor contratada. </li><li>Dar cumplimiento a la política de manejo de residuos industriales y residuos institucionales. </li><li>Participar de forma activa en las campañas reciclaje y cuidado ambiental. </li><li>Promover y aplicar las políticas de ahorro y uso de recursos naturales. </li><li>Colaborar en el aseguramiento de las Fichas de seguridad de los productos. Reportar a HSE para consecución de las que llegaren a faltar.</li></ol>",
    calidad: "<ol><li>Identificar riesgos y oportunidades dentro de los procesos y actividades y gestionar acciones de control. </li><li>Generar acciones correctivas y de mejora dentro de los procesos. </li><li>Apoyar la consecución de los objetivos estratégicos y de los procesos </li><li>Asistir a las capacitaciones que le hayan sido definidas en la matriz de Capacitación de talento humano y HSEQ</li><li>Solicitar de acuerdo al procedimiento las actualizaciones correspondientes en la documentación de su proceso con el fin de garantizar la idoneidad de las actividades. </li><li>Seleccionar la información que de su área será digitalizada y publicada en las carpetas de RMS. </li><li>Usar el sistema de codificación establecido para documentos y comunicaciones generados por la compañía. </li><li>Participación en las reuniones a las que sea convocado, sean estas del cronograma anual de reuniones o de emergencia.</li></ol>"
  }


  //-------------------------Registros

  const [currentUser, setCurrentUser] = useState('')
  const [date, setDate] = useState('')
  const [perfilCargo, setPerfilCargo] = useState([])
  const [funcionesCargo, setFuncionesCargo] = useState([])
  const [HSE, setHSE] = useState([])
  const [firmaEmpleado, setfirmaEmpleado] = useState([])
  const [count, setCount] = useState(0)
  

  useEffect(() => {
    if (count === 0) {
      getInfo()
    }
    setCount(1)
  }, [restart])
  // referencia a los textarea para que ajusten la altura de aceurdo al contenido
  const TAformacionAcademica = useRef(null);
  const TAconocimientosEspecificos = useRef(null);
  const TAexpLaboral = useRef(null);
  const TAhabilidades = useRef(null);
  const TAprocesosAdmin = useRef(null);
  const TAprocesosOper = useRef(null);
  const TAprocesosCampo = useRef(null);
  const TAcalidad = useRef(null);
  const TASST = useRef(null);
  const TAmedioAmb = useRef(null);
  const TAobjetivoCargo = useRef(null);
  const TArendirCuentas = useRef(null);
  const printRef = useRef(null);


  //  Menejo de cualquien cambio en los input del formulacio y lo guarda en data
  const handleChange = async ({ target }) => {
    const { name, type } = target
    const value = type === 'checkbox' ? target.checked : target.value
    setCount(1)
    setData({ ...data, [name]: value })

  }

  //  Guarda los cambios en los componentes reactquill__________________
  const handleReactQuill = (content) => {
    if (count===0)
      return
    if (isTH) setHSE({
      calidad: TAcalidad.current.value,
      SST: TASST.current.value,
      medioAmb: TAmedioAmb.current.value
    })
    else {
      setFuncionesCargo({
        procesosAdmin: TAprocesosAdmin.current.value,
        procesosOper: TAprocesosOper.current.value,
        procesosCampo: TAprocesosCampo.current.value
      })

      setPerfilCargo({
        formacionAcademica: TAformacionAcademica.current.value,
        conocimientosEspecificos: TAconocimientosEspecificos.current.value,
        expLaboral: TAexpLaboral.current.value,
        habilidades: TAhabilidades.current.value
      })

      setData({ ...data, objetivoCargo: TAobjetivoCargo.current.value, rendirCuentas: TArendirCuentas.current.value })
    }
  }



  const openPrintWindow = () => {
    const printWindow = window.open('', '_blank');
    if (printWindow) {
      const styles = Array.from(document.styleSheets)
        .map(styleSheet => {
          try {
            return Array.from(styleSheet.cssRules)
              .map(rule => rule.cssText)
              .join('\n');
          } catch (error) {
            console.error('Error parsing CSS rules', error);
            return '';
          }
        })
        .join('\n');

      printWindow.document.write('<html><head><title>Print</title>');
      printWindow.document.write('<style>');
      printWindow.document.write(`
      /* Agrega los estilos obtenidos de la página actual */
      ${styles}

      /* Establece los márgenes */
      @page {
        margin: 7mm;
        padding: 20px:
        size: letter;
      }
      /* Oculta los elementos con la clase no-print al imprimir */
      .no-print {
        display: none !important;
      }
    `); // Agrega los estilos obtenidos de la página actual
      printWindow.document.write('</style></head><body>');
      // Agrega un evento afterprint para cerrar la ventana después de la impresión
      printWindow.addEventListener('afterprint', () => {
        printWindow.close();
      });
      printWindow.document.write(printRef.current.innerHTML);
      printWindow.document.write('</body></html>');
      printWindow.document.close();
      printWindow.print();
    } else {
      alert('Please allow popups for this website');
    }
  };



  //---------------------------------------------------------
  // obtiene informacion para llenar formulacio segun estado
  const getInfo = async () => {
    // llena la fecha del dia, se guarda en el estado date
    const formatDate = Moment().format('YYYY-MM-DD')
    setDate(formatDate)
    if (data.Status === 'en creacion') {
      setHSE(HSEIni)
      setfirmaEmpleado({ ApellidoTrabajador: "", NombreTrabajador: "", IdEmployee_FK: "", fecha: "" })
      setData({
        ...data,
        creadoPor: sessionUser.IdEmployee_FK,
        nombreSolicitante: sessionUser.NombreTrabajador + " " + sessionUser.ApellidoTrabajador,
        JefeInmediato: sessionUser.cargo
      })
    } else {
      if (data.firmaEmpleado !== "") {
        setfirmaEmpleado(JSON.parse(data.firmaEmpleado))
      }
      setPerfilCargo(JSON.parse(data.perfilCargo))
      setFuncionesCargo(JSON.parse(data.funcionesCargo))
      setHSE(JSON.parse(data.HSE))
      if (!isView) {
        delete data.fechaModificacion
        delete data.fechaCreacion
        if (data.Status === 'copiando') {
          delete data.IdFormat_PK
          setfirmaEmpleado({ ApellidoTrabajador: "", NombreTrabajador: "", IdEmployee_FK: "", fecha: "" })
          setHSE(HSEIni)
          setData({
            ...data,
            JefeInmediato: sessionUser.cargo,
            creadoPor: sessionUser.IdEmployee_FK,
            nombreSolicitante: sessionUser.NombreTrabajador + ' ' + sessionUser.ApellidoTrabajador,
            Observaciones: '',
            firmaEmpleado: ''
          })
        }
      }


    }

    return true
  }

  // reinicia el formulario, borra estados y valores
  const clearStates = () => {
    setData('')
    setCurrentUser('')
    setDate('')
    setRestart(!restart)
  }

  // Guarda furmulario
  const onSubmit = async e => {
    e.preventDefault()
    if (!isSign)
      handleReactQuill()
    
    var result = ''
    data = {
      ...data,
      firmaEmpleado: JSON.stringify(firmaEmpleado),
      perfilCargo: JSON.stringify(perfilCargo),
      funcionesCargo: JSON.stringify(funcionesCargo),
      HSE: JSON.stringify(HSE),
      Status: data.Status === 'firmaEmpleado' ? 'firmaEmpleado' : "disponible",
    }
    if (data.IdFormat_PK === undefined) {
      result = await CreateFormat(data, type)
    }
    else {
      delete data.fechaModificacion
      delete data.fechaCreacion
      result = await updateFormat({ ...data, Status: isSign ? 'finalizado' : data.Status }, type)
    }
  if (result.data.status === 200) {
      if (isSign)
        result = await updateWorkflow({ ...workflow, Status: "finalizado", dateFinish: date })

      toast.success(result.data.msj)
    }
    else {
      toast.error(result.data.msj);
    }

    clearStates()
    hide(!show)
    setRestart(!restart)
  }



  return (
    <>
      {show && (
        <Overlay style={{ zIndex: '1' }}>
          <SpinnerLoad trigger={data} />
          <ModalContainer ref={printRef} >
            <CloseButtonModal className="no-print" onClick={() => {
              clearStates()
              hide(!show)
            }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-x-square"
                viewBox="0 0 16 16">
                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </CloseButtonModal>
            <ModalHeader>
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <th style={{ width: '20%', fontSize: '10px', verticalAlign: 'bottom' }} rowSpan={3}>
                      <img
                        className="format_logo"
                        src={image}
                        style={{ paddingBottom: '7px', width: '75%', minWidth: "40px" }}
                        alt="Logo RMS"
                      /><br />
                      NIT 830.507.705-2
                    </th>
                    <th style={{ width: '55%', height: '38px' }}>RELIABILITY MAINTENANCE SERVICES S.A.S</th>
                    <th style={{ width: '25%' }}>{formato}</th>
                  </tr>
                  <tr>
                    <th rowSpan={2}>{nombreFormato}</th>
                    <th style={{ fontSize: '10px' }}>Revisión:{revision}</th>
                  </tr>
                  <tr>
                    <th style={{ fontSize: '10px' }}>Fecha de revisión:{fechaRev}</th>
                  </tr>
                </tbody>
              </table>
            </ModalHeader>

            <table>
              <tbody>
                <tr>
                  <th style={{ width: '25%', textAlign: 'right' }}></th>
                  <th style={{ width: '35%', textAlign: 'right' }}>
                    <div style={{ paddingLeft: '5px', display: 'flex', fontSize: '12px', fontWeight: 'bold', textAlign: 'center' }}>
                      Manual de Funciones No.</div>
                  </th>
                  <td style={{ width: '15%', background: '#FFF' }}>
                    <div style={{ paddingLeft: '5px', display: 'flex', fontSize: '12px', fontWeight: 'bold', textAlign: 'center' }}>
                      {data.IdFormat_PK}</div>
                  </td>
                  <th style={{ width: '15%', textAlign: 'right' }}>
                    <div style={{ paddingLeft: '5px', display: 'flex', fontSize: '12px', fontWeight: 'bold', textAlign: 'center' }}>
                      Estado:</div>
                  </th>
                  <td style={{ width: '20%', background: '#FFF' }}>
                    <div style={{ paddingLeft: '5px', display: 'flex', fontSize: '12px', fontWeight: 'bold', textAlign: 'center' }}>
                      {data.Status}</div>
                  </td>
                </tr>
              </tbody>
            </table>
            <Form onSubmit={onSubmit}>
              <Frame isView={isView || isTH}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <th className="rms" colSpan={3} style={{ width: '100%' }}>
                        <h2 className="text-center">IDENTIFICACIÓN</h2>
                      </th>
                    </tr>
                    <tr>
                      <td colSpan={2} style={{ position: 'relative' }}>
                        <div style={{ position: 'relative' }}>
                          <img style={{ marginLeft: '240px' }} src={IMAGG} width={'300px'} alt='Imagen' />

                          <div style={{ minWidth: "200px", maxWidth: "200px", position: 'absolute', top: '21%', left: '54%', transform: 'translate(-50%, -50%)', textAlign: 'center', maxHeight: '100px', overflow: 'auto' }}>
                            {data.JefeInmediato}
                          </div>

                          <div style={{ minWidth: "210px", maxWidth: "210px", position: 'absolute', top: '109px', left: '54.5%', transform: 'translate(-50%, -50%)', textAlign: 'center', maxHeight: '100px', overflow: 'auto' }}>

                            {data.Cargo}
                          </div>
                        </div>
                      </td>

                    </tr>
                    <tr>
                      <td style={{ width: '150px' }}><div style={{ paddingLeft: '5px', display: 'flex', fontSize: '12px', fontWeight: 'bold', textAlign: 'center' }}>
                        CARGO</div></td>
                      <td colSpan={2} style={{ textAlign: 'center' , fontWeight:'350' }} >

                        {!isView && data.Status === "en creacion" && <select
                          style={{ width: '100px' }}
                          name="tipoPerfil"
                          id="tipoPerfil"
                          value={data.tipoPerfil}
                          onChange={handleChange}
                          required
                          disabled={isView ? true : false}
                          title="Tipo..."

                        >
                          <option value="">Seleccionar</option>
                          <option value="Lider">Líder</option>
                          <option value="Profesional">Profesional</option>
                          <option value="Tecnico">Técnico</option>

                        </select>}
                        {isView ?

                          data.Cargo
                          : <input
                            name="Cargo"
                            id="Cargo"
                            value={data.Cargo}
                            onChange={e => {
                              setData({ ...data, Cargo: capitalLeter(e.target.value, 3) })
                            }
                            }
                            required
                            disabled={isView || isTH ? true : false}
                          ></input>}</td>
                    </tr>
                    <tr>
                      <td><div style={{ paddingLeft: '5px', display: 'flex', fontSize: '12px', fontWeight: 'bold', textAlign: 'center' }}>
                        JEFE INMEDIATO</div></td>
                      <td colSpan={2} style={{ textAlign: 'center', fontWeight:'350' }}>

                        {data.JefeInmediato}
                      </td>
                    </tr>
                    <tr>
                      <td><div style={{ paddingLeft: '5px', display: 'flex', fontSize: '12px', fontWeight: 'bold', textAlign: 'center' }}>
                        PERSONAL A CARGO</div></td>
                      <td colSpan={2}>
                        {isView ?
                          <div style={{ paddingLeft: '5px', display: 'flex', fontSize: '12px', fontWeight: '350', textAlign: 'center' }}>
                            {data.PersonalACargo}</div>
                          : <select
                            name="PersonalACargo"
                            id="PersonalACargo"
                            value={data.PersonalACargo}
                            onChange={handleChange}
                            required
                            disabled={isView || isTH ? true : false}
                            title="seleccione..."

                          >
                            <option value="">Seleccionar</option>
                            <option value="Si">Sí</option>
                            <option value="No">No</option>

                          </select>} </td>
                    </tr>
                  </tbody>
                </table>
              </Frame>

              {/* ---------------------------------------------------------------------------------------------------------------------------------------------------- */}

              <Frame isView={isView}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <th className="rms" colSpan={3} style={{ width: '100%' }}>
                        <h2 className="text-center">l. OBJETIVO DEL CARGO</h2>
                      </th>
                    </tr>
                    <tr>
                      <td>

                        {!isView && !isTH ?( <ReactQuill
                          className="ql-align-justify"
                          placeholder="escribe algo"
                          value={data.objetivoCargo}
                          ref={TAobjetivoCargo}
                          onChange={handleReactQuill}
                        />)
                          : (<div style={{ textAlign: "justify", padding: "20px" , fontWeight:'350' }} dangerouslySetInnerHTML={{ __html: data.objetivoCargo }} />)
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Frame>

              {/* ---------------------------------------------------------------------------------------------------------------------------------------------------- */}

              <Frame isView={isView}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <th className="rms" colSpan={3} style={{ width: '100%' }}>
                        <h2 className="text-center">ll. PERFIL DEL CARGO</h2>
                      </th>
                    </tr>
                    <tr>
                      <td style={{ width: '20%' }}  ><h2 className="text-center">FORMACION ACADEMICA</h2></td>
                      <td colSpan={2}>

                        {!isView && !isTH ? (<ReactQuill
                          className="ql-align-justify"
                          placeholder="escribe algo"
                          value={perfilCargo.formacionAcademica}
                          ref={TAformacionAcademica}
                          onChange={handleReactQuill}
                        />)
                          :( <div style={{ textAlign: "justify", padding: "20px", fontWeight:'350' }} dangerouslySetInnerHTML={{ __html: perfilCargo.formacionAcademica }} />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td><h2 className="text-center">CONOCIMIENTOS ESPECIFICOS <br />(Certificables)</h2></td>
                      <td colSpan={2}>

                        {!isView && !isTH ? (<ReactQuill
                          className="ql-align-justify"
                          placeholder="escribe algo"
                          value={perfilCargo.conocimientosEspecificos}
                          ref={TAconocimientosEspecificos}
                          onChange={handleReactQuill}
                        ></ReactQuill>)
                          : (<div style={{ textAlign: "justify", padding: "20px", fontWeight:'350' }} dangerouslySetInnerHTML={{ __html: perfilCargo.conocimientosEspecificos }} />
                       ) }
                      </td>
                    </tr>
                    <tr>
                      <td><h2 className="text-center">EXPERIENCIA LABORAL</h2></td>
                      <td colSpan={2}>

                        {!isView && !isTH ?( <ReactQuill
                          className="ql-align-justify"
                          placeholder="escribe algo"
                          value={perfilCargo.expLaboral}
                          ref={TAexpLaboral}
                          onChange={handleReactQuill}
                        ></ReactQuill>)
                          : (<div style={{ textAlign: "justify", padding: "20px", fontWeight:'350' }} dangerouslySetInnerHTML={{ __html: perfilCargo.expLaboral }} />
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td><h2 className="text-center">HABILIDADES</h2></td>
                      <td colSpan={2}>

                        {!isView && !isTH ?( <ReactQuill
                          className="ql-align-justify"
                          placeholder="escribe algo"
                          value={perfilCargo?.habilidades}
                          ref={TAhabilidades}
                          onChange={handleReactQuill}
                        ></ReactQuill>)
                          : (<div style={{ textAlign: "justify", padding: "20px", fontWeight:'350' }} dangerouslySetInnerHTML={{ __html: perfilCargo.habilidades }} />
                        )}
                      </td>
                    </tr>

                  </tbody>
                </table>
              </Frame>

              {/* ---------------------------------------------------------------------------------------------------------------------------------------------------- */}

              <Frame isView={isView || isTH}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <th className="rms" colSpan={3} style={{ width: '100%' }}>
                        <h2 className="text-center">lll. NIVEL DE AUTORIDAD</h2>
                      </th>
                    </tr>
                    <tr>
                      <td style={{ textAlign: 'center' , fontWeight:'350'}}>{isView ? data.NivelDeAutoridad :
                        <input
                          name="NivelDeAutoridad"
                          id="NivelDeAutoridad"
                          value={data.NivelDeAutoridad}
                          onChange={handleChange}
                          required
                          disabled={isView || isTH ? true : false}
                        ></input>}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Frame>

              {/* ---------------------------------------------------------------------------------------------------------------------------------------------------- */}

              <Frame isView={isView || isTH}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <th className="rms" colSpan={3} style={{ width: '100%' }}>
                        <h2 className="text-center">lV. RENDICION DE CUENTAS</h2>
                      </th>
                    </tr>
                    <tr>
                      <td>
                        {!isView && !isTH ? <ReactQuill
                          className="ql-align-justify"
                          placeholder="escribe algo"
                          value={data.rendirCuentas}
                          ref={TArendirCuentas}
                          onChange={handleReactQuill}
                        ></ReactQuill>
                          : <div style={{ textAlign: "justify", padding: "20px" , fontWeight:'350'}} dangerouslySetInnerHTML={{ __html: data.rendirCuentas }} />
                        }
                        {/* <textarea
                          name="rendirCuentas"
                          value={data.rendirCuentas}
                          onChange={handleChange}
                          required
                          rows={4}
                          disabled={isView || isTH ? true : false}
                        ></textarea> */}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Frame>

              {/* ---------------------------------------------------------------------------------------------------------------------------------------------------- */}

              <Frame isView={isView || isTH}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <th className="rms" colSpan={3} style={{ width: '100%' }}>
                        <h2 className="text-center">V.	DESCRIPCIÓN DE FUNCIONES Y RESPONSABILIDADES DEL CARGO</h2>
                      </th>
                    </tr>
                    <tr>
                      <td style={{ width: '20%' }} ><h2 className="text-center">PROCESOS ADMINISTRATIVOS</h2></td>
                      <td colSpan={2}>

                        {!isView && !isTH ? <ReactQuill
                          className="ql-align-justify"
                          placeholder="escribe algo"
                          value={funcionesCargo.procesosAdmin}
                          ref={TAprocesosAdmin}
                          onChange={handleReactQuill}
                        ></ReactQuill>
                          : <div style={{ textAlign: "justify", padding: "20px", fontWeight:'350' }} dangerouslySetInnerHTML={{ __html: funcionesCargo.procesosAdmin }} />
                        }
                      </td>
                    </tr>
                    <tr>
                      <td><h2 className="text-center">PROCESOS OPERATIVOS</h2></td>
                      <td colSpan={2}>

                        {!isView && !isTH ? <ReactQuill
                          className="ql-align-justify"
                          placeholder="escribe algo"
                          value={funcionesCargo.procesosOper}
                          ref={TAprocesosOper}
                          onChange={handleReactQuill}
                        ></ReactQuill>
                          : <div style={{ textAlign: "justify", padding: "20px", fontWeight:'350' }} dangerouslySetInnerHTML={{ __html: funcionesCargo.procesosOper }} />
                        }
                      </td>
                    </tr>
                    <tr>
                      <td><h2 className="text-center">PROCESOS EN CAMPO</h2></td>
                      <td colSpan={2}>

                        {!isView && !isTH ? <ReactQuill
                          className="ql-align-justify"
                          placeholder="escribe algo"
                          value={funcionesCargo.procesosCampo}
                          ref={TAprocesosCampo}
                          onChange={handleReactQuill}
                        ></ReactQuill>
                          : <div style={{ textAlign: "justify", padding: "20px", fontWeight:'350' }} dangerouslySetInnerHTML={{ __html: funcionesCargo.procesosCampo }} />
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Frame>

              {/* ---------------------------------------------------------------------------------------------------------------------------------------------------- */}

              <Frame isView={isView}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <th className="rms" colSpan={3} style={{ width: '100%' }}>
                        <h2 className="text-center">VI.	COMPETENCIAS COMPORTAMENTALES
                          (Habilidades blandas)
                        </h2>
                      </th>
                    </tr>
                    <tr>
                      <td style={{paddingLeft:'10px', fontWeight:'350'}}>
                        <h2>Competencias Generales</h2><br />
                          -Liderazgo.<br />
                          -Trabajo en equipo.<br />
                          -Comunicación Asertiva.<br />
                          -Resolución de conflictos.<br />

                        
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Frame>

              {/* ---------------------------------------------------------------------------------------------------------------------------------------------------- */}

              {data.tipoPerfil === 'Lider' && <Frame isView={isView}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <th className="rms" colSpan={3} style={{ width: '100%' }}>
                        <h2 className="text-center">COMPETENCIAS INDIVIDUALES COMPORTAMENTALES<br />
                          NIVEL DIRECTIVO- LÍDERES-
                        </h2>
                      </th>
                    </tr>
                    <tr>
                      <td style={{ width: '520px' }}><h2 className="text-center">COMPETENCIA</h2></td>
                      <td style={{ width: '1000px' }}><h2 className="text-center">DEFINICION DE COMPETENCIA</h2></td>
                      <td ><h2 className="text-center">CONDUCTAS ASOCIADAS</h2></td>
                    </tr>
                    <tr>
                      <td><h2 className="text-center"> LIDERAZGO</h2></td>
                      <td  style={{ fontWeight: '350' }}>
                        Guiar y dirigir grupos y establecer y mantener la cohesión de grupo necesaria
                        para alcanzar los objetivos organizacionales
                      </td>
                      <td  style={{ fontWeight: '350' }}>
                        - Fomenta la comunicación clara, directa y concreta al interior del equipo de trabajo.<br />
                        - Genera un clima positivo y de seguridad en sus colaboradores.<br />
                        - Fomenta la participación en la toma de decisiones del equipo de trabajo.


                      </td>
                    </tr>

                    <tr>
                      <td><h2 className="text-center"> TRABAJO EN EQUIPO
                      </h2></td>
                      <td style={{ fontWeight: '350' }}>
                        Trabajar con otros de forma conjunta y de manera participativa, integrando esfuerzos para la consecución de metas organizacionales comunes.
                      </td>
                      <td  style={{ fontWeight: '350' }}>
                        - Coopera en distintas situaciones y comparte información. <br />
                        - Aporta sugerencias, ideas y opiniones. <br />
                        - Expresa expectativas positivas del equipo o de los miembros de este. <br />
                        - Establece diálogo directo con los miembros del equipo que permita compartir información e ideas en condiciones de respeto y cordialidad. <br />
                        - Respeta criterios dispares y distintas opiniones del equipo.
                      </td>
                    </tr>

                    <tr>
                      <td><h2 className="text-center"> COMUNICACIÓN ASERTIVA
                      </h2></td>
                      <td  style={{ fontWeight: '350' }}>Habilidad para expresar opiniones o comentarios de forma respetuosa, honesta y efectiva</td>
                      <td  style={{ fontWeight: '350' }}>- Escucha activa. <br />
                        - Respeto. <br />
                        - Claridad al transmitir la información. <br />
                        - Retroalimentación efectiva. <br />
                        - Dialogo.</td>
                    </tr>
                    <tr>
                      <td><h2 className="text-center"> RESOLUCIÓN DE CONFLICTOS
                      </h2></td>
                      <td  style={{ fontWeight: '350' }}>Es la manera como dos o más individuos encuentran una solución pacífica a los desacuerdos que enfrentan.</td>
                      <td style={{ fontWeight: '350' }} >- Escucha activa. <br />
                        - Dialogo. <br />
                        - Retroalimentación efectiva. <br />
                        - Respetar los diferentes puntos de vista. <br />
                        - Imparcialidad. <br />
                        - Empatía.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Frame>}

              {/* ---------------------------------------------------------------------------------------------------------------------------------------------------- */}

              {data.tipoPerfil === 'Profesional' && <Frame isView={isView}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <th className="rms" colSpan={3} style={{ width: '100%' }}>
                        <h2 className="text-center">COMPETENCIAS INDIVIDUALES COMPORTAMENTALES
                          NIVEL PROFESIONAL

                        </h2>
                      </th>
                    </tr>
                    <tr>
                      <td><h2 className="text-center">COMPETENCIA</h2></td>
                      <td><h2 className="text-center">DEFINICION DE COMPETENCIA</h2></td>
                      <td ><h2 className="text-center">CONDUCTAS ASOCIADAS</h2></td>
                    </tr>
                    <tr>
                      <td><h2 className="text-center"> LIDERAZGO</h2></td>
                      <td style={{ fontWeight: '350' }}>
                        Guiar y dirigir grupos y establecer y mantener la cohesión de grupo necesaria
                        para alcanzar los objetivos organizacionales<br />
                      </td>
                      <td  style={{ fontWeight: '350' }}>
                        - Fomenta la comunicación clara, directa y concreta al interior del equipo de trabajo.<br />
                        - Genera un clima positivo y de seguridad en sus colaboradores o compañeros del equipo de trabajo.<br />
                        - Fomenta la participación en la toma de decisiones del equipo de trabajo.


                      </td>
                    </tr>

                    <tr>
                      <td><h2 className="text-center"> TRABAJO EN EQUIPO
                      </h2></td>
                      <td style={{ fontWeight: '350' }}>
                        Trabajar con otros de forma conjunta y de manera participativa, integrando esfuerzos para la consecución de metas organizacionales comunes.
                      </td>
                      <td style={{ fontWeight: '350' }} >
                        - Coopera en distintas situaciones y comparte información. <br />
                        - Aporta sugerencias, ideas y opiniones. <br />
                        - Expresa expectativas positivas del equipo o de los miembros de este. <br />
                        - Establece diálogo directo con los miembros del equipo que permita compartir información e ideas en condiciones de respeto y cordialidad. <br />
                        - Respeta criterios dispares y distintas opiniones del equipo.
                      </td>
                    </tr>

                    <tr>
                      <td><h2 className="text-center"> COMUNICACIÓN ASERTIVA
                      </h2></td>
                      <td style={{ fontWeight: '350' }}>Habilidad para expresar opiniones o comentarios de forma respetuosa, honesta y efectiva</td>
                      <td  style={{ fontWeight: '350' }}>- Escucha activa. <br />
                        - Respeto. <br />
                        - Claridad al transmitir la información. <br />
                        - Retroalimentación efectiva. <br />
                        - Dialogo.</td>
                    </tr>
                    <tr>
                      <td><h2 className="text-center"> RESOLUCIÓN DE CONFLICTOS
                      </h2></td>
                      <td style={{ fontWeight: '350' }}>Es la manera como dos o más individuos encuentran una solución pacífica a los desacuerdos que enfrentan.</td>
                      <td  style={{ fontWeight: '350' }}>- Escucha activa. <br />
                        - Dialogo. <br />
                        - Retroalimentación efectiva. <br />
                        - Respetar los diferentes puntos de vista. <br />
                        - Imparcialidad. <br />
                        - Empatía.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Frame>}

              {/* ---------------------------------------------------------------------------------------------------------------------------------------------------- */}

              {data.tipoPerfil === 'Tecnico' && <Frame isView={isView}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <th className="rms" colSpan={3} style={{ width: '100%' }}>
                        <h2 className="text-center">COMPETENCIAS INDIVIDUALES COMPORTAMENTALES
                          NIVEL TÉCNICO
                        </h2>
                      </th>
                    </tr>
                    <tr>
                      <td><h2 className="text-center">COMPETENCIA</h2></td>
                      <td><h2 className="text-center">DEFINICION DE COMPETENCIA</h2></td>
                      <td ><h2 className="text-center">CONDUCTAS ASOCIADAS</h2></td>
                    </tr>
                    <tr>
                      <td><h2 className="text-center"> LIDERAZGO</h2></td>
                      <td style={{ fontWeight: '350' }}>
                        Guiar y dirigir grupos y establecer y mantener la cohesión de grupo necesaria
                        para alcanzar los objetivos organizacionales<br />
                      </td>
                      <td  style={{ fontWeight: '350' }}>
                        - Fomenta la comunicación clara, directa y concreta al interior del equipo de trabajo.<br />
                        - Genera un clima positivo y de seguridad en sus colaboradores o compañeros del equipo de trabajo.<br />
                        - Fomenta la participación en la toma de decisiones del equipo de trabajo.


                      </td>
                    </tr>

                    <tr>
                      <td><h2 className="text-center"> TRABAJO EN EQUIPO</h2></td>
                      <td style={{ fontWeight: '350' }}>
                        Trabajar con otros de forma conjunta y de manera participativa, integrando esfuerzos para la consecución de metas institucionales comunes.
                      </td>
                      <td  style={{ fontWeight: '350' }}>
                        - Coopera en distintas situaciones y comparte información. <br />
                        - Aporta sugerencias, ideas y opiniones. <br />
                        - Expresa expectativas positivas del equipo o de los miembros de este. <br />
                        - Establece diálogo directo con los miembros del equipo que permita compartir información e ideas en condiciones de respeto y cordialidad. <br />
                        - Respeta criterios dispares y distintas opiniones del equipo.
                      </td>
                    </tr>

                    <tr>
                      <td><h2 className="text-center"> COMUNICACIÓN ASERTIVA
                      </h2></td>
                      <td style={{ fontWeight: '350' }}>Habilidad para expresar opiniones o comentarios de forma respetuosa, honesta y efectiva</td>
                      <td  style={{ fontWeight: '350' }}>- Escucha activa. <br />
                        - Respeto. <br />
                        - Claridad al transmitir la información. <br />
                        - Retroalimentación efectiva. <br />
                        - Dialogo.</td>
                    </tr>
                    <tr>
                      <td><h2 className="text-center"> RESOLUCIÓN DE CONFLICTOS
                      </h2></td>
                      <td style={{ fontWeight: '350' }}>Es la manera como dos o más individuos encuentran una solución pacífica a los desacuerdos que enfrentan.</td>
                      <td  style={{ fontWeight: '350' }}>- Escucha activa. <br />
                        - Dialogo. <br />
                        - Retroalimentación efectiva. <br />
                        - Respetar los diferentes puntos de vista. <br />
                        - Imparcialidad. <br />
                        - Empatía.<br />
                      </td>
                    </tr>

                  </tbody>
                </table>
              </Frame>}

              {/* ---------------------------------------------------------------------------------------------------------------------------------------------------- */}

              <Frame isView={isView || !isTH}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <th className="rms" colSpan={3} style={{ width: '100%' }}>
                        <h2 className="text-center">VII.	COMPETENCIAS EN SSTA</h2>
                      </th>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: '350' }}>

                        Para el personal que requiera competencias en SSTA y no estén especificadas dentro de conocimientos específicos del cargo, estas se encontrarán dentro del plan de capacitaciones de HSEQ.

                      </td>
                    </tr>
                  </tbody>
                </table>
              </Frame>

              {/* ---------------------------------------------------------------------------------------------------------------------------------------------------- */}

              <Frame isView={isView || !isTH}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <th className="rms" colSpan={3} style={{ width: '100%' }}>
                        <h2 className="text-center">VIII.	ROLES Y RESPONSABILIDADES EN HSE y VIAL</h2>
                      </th>
                    </tr>
                    <tr>
                      <td style={{ width: '20%' }}><h2 className='text-center'>SEGURIDAD Y SALUD EN EL TRABAJO / SEGURIDAD VIAL</h2></td>
                      <td>
                        {!isView && isTH ? <ReactQuill
                          className="ql-align-justify"
                          placeholder="escribe algo"
                          value={HSE.SST}
                          ref={TASST}
                          onChange={handleReactQuill}
                        ></ReactQuill>
                          : <div style={{ textAlign: "justify", padding: "20px" , fontWeight: '350' }} dangerouslySetInnerHTML={{ __html: HSE.SST }} />
                        }
                      </td>
                    </tr>

                    <tr>
                      <td><h2 className='text-center'> MEDIO AMBIENTE</h2></td>
                      <td>

                        {!isView && isTH ? <ReactQuill
                          id='medioAmb'
                          className="ql-align-justify"
                          placeholder="escribe algo"
                          value={HSE.medioAmb}
                          ref={TAmedioAmb}
                          onChange={handleReactQuill}
                        ></ReactQuill>
                          : <div style={{ textAlign: "justify", padding: "20px"  , fontWeight: '350' }} dangerouslySetInnerHTML={{ __html: HSE.medioAmb }} />
                        }
                      </td>
                    </tr>

                    <tr>
                      <td><h2 className='text-center'> CALIDAD</h2></td>
                      <td>

                        {!isView && isTH ? <ReactQuill
                          id="calidad"
                          className="ql-align-justify"
                          placeholder="escribe algo"
                          value={HSE.calidad}
                          ref={TAcalidad}
                          onChange={handleReactQuill}//onChange={handleReactQuill}
                        ></ReactQuill>
                          : <div style={{ textAlign: "justify", padding: "20px" , fontWeight: '350' }} dangerouslySetInnerHTML={{ __html: HSE.calidad }} />}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Frame>

              {/* ---------------------------------------------------------------------------------------------------------------------------------------------------- */}


              <Frame isView={!isSign}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <td><h2>FIRMA DEL EMPLEADO: </h2></td>
                      <td style={{ height: '180x', width: '450px' }}>

                        <Signer
                          enable={sessionUser.IdEmployee_FK === firmaEmpleado.IdEmployee_FK ?? false}
                          sign={firmaEmpleado}
                          setSign={setfirmaEmpleado}
                          doc={type}
                          user={sessionUser.IdEmployee_FK}
                          isView={isView}
                        />

                      </td>
                    </tr>
                    <tr>
                      <td><h2>NOMBRE DEL EMPLEADO: </h2></td>
                      <td style={{ textAlign: 'center' , fontWeight: '350'}}> {isView ? firmaEmpleado.NombreTrabajador + " " + firmaEmpleado.ApellidoTrabajador
                        : <input
                          className='disable'
                          name="NombreEmpleado"
                          id="NombreEmpleado"
                          value={firmaEmpleado.NombreTrabajador + " " + firmaEmpleado.ApellidoTrabajador}
                          onChange={handleChange}
                          required
                          disabled
                        ></input>}</td>
                    </tr>
                    <tr>
                      <td><h2>C.C: </h2></td>
                      <td style={{ textAlign: 'center', fontWeight: '350' }}> {
                        isView ? firmaEmpleado.IdEmployee_FK :
                          <input
                            className='disable'
                            name="CedulaEmpleado"
                            id="CedulaEmpleado"
                            value={firmaEmpleado.IdEmployee_FK}
                            onChange={handleChange}
                            required
                            disabled
                          ></input>}</td>
                    </tr>
                    <tr>
                      <td><h2>FECHA: </h2></td>
                      <td style={{ textAlign: 'center', fontWeight: '350' }} >{
                        isView ? firmaEmpleado.fecha :
                          <input
                            className='disable'
                            type='text'
                            name="Fecha"
                            id="Fecha"
                            value={firmaEmpleado.fecha}
                            onChange={handleChange}
                            disabled
                          ></input>}</td>
                    </tr>
                    <tr>
                      <td colSpan={2} style={{ textAlign: 'center' }}><h2>OBSERVACIONES</h2></td>

                    </tr>
                    <tr>
                      <td colSpan={2}  style={{ fontWeight: '350'}}>{isView & !isSign? data.Observaciones : <textarea style={{ height: '80px' }}
                        name="Observaciones"
                        id="Observaciones"
                        value={data.Observaciones}
                        onChange={handleChange}
                        required
                        disabled={!isSign ? true : false}
                      ></textarea>}</td>
                    </tr>
                  </tbody>
                </table>
              </Frame>

              {/* ---------------------------------------------------------------------------------------------------------------------------------------------------- */}




              <Row className="no-print">
                <Col></Col>
                <Col></Col>
                <Col></Col>
                <Col></Col>
                {!isView || isSign ? (
                  <>
                    <Col>
                      <Button
                        className="btn btn-danger"
                        onClick={() => {
                          clearStates()
                          hide(!show)
                        }}>

                        Cancelar
                      </Button>
                    </Col>
                    <Col>
                      <Button type="submit" className="btn btn-success">
                        Guardar
                      </Button>
                    </Col>
                  </>
                ) : (
                  <> <Col>

                    <Col>
                      <Button
                        className="btn btn-info"
                        onClick={openPrintWindow}
                      >
                        <i className="bi bi-printer"></i>
                      </Button>

                    </Col>
                  </Col>
                    <Col>
                      <Button
                        className="btn btn-secundary"
                        onClick={() => {
                          clearStates()
                          hide(!show)
                        }}
                      >
                        Cerrar
                      </Button>
                    </Col></>
                )}
              </Row>
            </Form>
          </ModalContainer>
        </Overlay>
      )}
    </>
  )
}

export default GTHFOR076

const Frame = styled.div`
  width: 20.80cm;
  padding-bottom: 0px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: left;
  border-width: 0px;
  border-color: #1a004a;
  border-style: solid;
  font-size: 12px;
  font-weight: 1000;
  .photo {
    background: #ebf1de;
  }
  .rms {
    background: #F2F2F2;
  }
  .img{
      margin-left: 300px;
      padding: 0%;

    }
  labelTable {
    padding-left: 5px;
    display: flex;
    font-size: 12px;
    font-weight: bold;
    text-align: 'center';
  }
  input {
    text-align: center;
    width: 100%;
    border-bottom: 1px solid black;
    border-color: #1a004a;
    background: ${props => (props.isView ? '#FFF' : '#cee1ed')};

    padding-left: 5px;
    /* margin-top: 5px; */
  }
  textarea {
    text-align: justify;
    width: 100%;
    border-bottom: 1px solid black;
    border-color: #1a004a;
    background: ${props => (props.isView ? '#FFF' : '#cee1ed')};
    resize: none;

    padding-left: 5px;
    /* margin-top: 5px; */
  }

  input.edit {
    background: #cee1ed;
  }
  input.disable {
    background: #fff;
  }
  h2 {
    font-size: 14px;
    font-weight: bold;
    text-align: 'center';
  }

  th {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    text-align: left;
    vertical-align: center;
    padding-left: 5px;

    input {
      width: 100%;
    }
  }
  tr {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
  }
  td {
          border-width: 1px;
          border-color: #1a004a;
          border-style: solid;
  }
`

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgb(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalContainer = styled.div`
  width: 21.60cm;
  max-height: 90vh;
  min-height: 90vh;
  background: #fff;
  position: relative;
  border-radius: 5px;
  overflow-x: hidden;
  overflow-y: scroll;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 10px;
  @media print {
    padding-top: 20px;

    
    /* Estilos para la versión impresa */
    overflow: hidden;
    /* Aquí ocultamos los componentes que no queremos imprimir */
    @page {width: 100%;
    height: 100%;
      size: letter; /* Puedes usar diferentes tamaños de papel como A4, Letter, etc. */
      margin: 0; /* Márgenes laterales en 0 */
      orientation: portrait ; /* Cambiar a 'landscape' si necesitas orientación horizontal */
    }
     
      /* Ajustar el área de impresión */
    .no-print {
      display: none;
    }
} 
`

const ModalHeader = styled.div`
  width: 20.80cm;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
  padding-bottom: 10px;

  th {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
  }
  tr {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
  }
  table {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    text-align: center;
    vertical-align: center;
    font-size: 14px;
  }
  h3 {
    font-weight: 500;
    font-size: 16px;
    color: #1766dc;
  }
`

const CloseButtonModal = styled.div`
  position: absolute;
  top: 2px;
  right: 5px;
  width: 20px;
  height: 20px;
  border: none;
  background: none;
  cursor: pointer;
  transition: 0.3s ease all;
  border-radius: 5px;
  color: #1766dc;
  &:hover {
    background: #f2f2f2;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`
export const Label = styled.label`
  width: 120px;
  height: 150px;
  position: relative;
  cursor: ${({ isView }) => (isView ? 'normal' : 'pointer')};
`

export const Photo = styled.img`
  object-fit: contain;
  max-width: 110px;
  position: absolute;
  inset: 0;
  margin: auto;`