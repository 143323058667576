import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { sessionActive } from '../../lib/functions'
import { Col, Row, Button, Form, Modal } from 'react-bootstrap'
import COMFORCompanies from '../../lib/formatos/COMFORCompanies'
import { getFormats   } from '../../lib/formatos/request'
import SpinnerLoad from '../../lib/spinnerLoad'
import { EditingCompanieF  } from '../../lib/request'


export const Companies = props => {

  const typef = 'company'
  const IdUser_PK = (localStorage.getItem('IdUser_PK'))
  const [restart, setRestart] = useState(false)
  const [IdFormat_PK, setIdFormat_PK] = useState('')
  const [showForm, setShowForm] = useState(false)
  const [formats, setFormats] = useState([])
  const [isView, setIsView] = useState(false)
  const [ModalStatus, setModalStatus] = useState(false)
  const [search, setSearch] = useState()
  const [data, setData] = useState()
  const [filterindItems, setFilterindItems] = useState([])
  const [showModal, setShowModal] = useState(true)
  const [ModalInactive, setModalInactive] = useState(false)
  const [idInactive, setIdInactive] = useState('')
  const [isMain, setIsMain] = useState(false)
  const [nameCompany, setNameCompany] = useState('')
  useEffect(() => {
    if (!restart) {
      setRestart(!restart)
      sessionActive()
      fillTable()

    }
  }, [restart])


  const fillTable = async () => {
    //obtener listado de empresas
    //Obtener lista de Empresas
    const resFormats = await getFormats(typef)
    if (resFormats.data.status === 200) {
      setFormats(resFormats.data.data)
      setFilterindItems(resFormats.data.data)
    } else {
      toast.error(resFormats.data.msj)
    }


  }

  const inactiveCompany = async () => {
    // //borrar un solicitud
   const resDelele = await EditingCompanieF({IdCompany_PK:idInactive, Status:ModalStatus==='Activar'?'Activo':'Inactivo'})
    if (resDelele.data.status === 200) {
      toast.success(resDelele.data.msj)
    } else {
      toast.error(resDelele.data.msj)
    }
    fillTable()
  }


  useEffect(() => {
    sessionActive()
    fillTable()

  }, [restart])

  const OnFilter = e => {
    let FilterText = e.target.value
    setSearch(FilterText)
    setFilterindItems(
      formats.filter(
        item =>
          item.SocialName.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.products.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.url.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.Industry.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.SocialName.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.CIIU_FK.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.ContactPerson.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.city.toLowerCase().includes(FilterText.toLowerCase()) ||
           //(item.IdCompany_FK && item.IdCompany_FK.toString().includes( FilterText)) ||
           //(item.createdBy&&item.createdBy.toString().includes(FilterText)) ||
          item.Status.toLowerCase().includes(FilterText.toLowerCase())
      )
    )
  }

  const ClearSearch = () => {
    setSearch('')
    setFilterindItems(formats)
  }

  const handleChange = (data) => {

    setData(data)
    setRestart(false)
    setShowModal(!showModal)
    setShowForm(!showForm)

    setIsView(true)
    setIdFormat_PK(data.IdFormat_PK)

  }
  const editRow = row => {
    if (props.put) {
      if (IdUser_PK === row.IdUser_FK && row.Status === 'solicitado') return true
      else if (row.Status !== 'solicitado') return true
      else return false
    }
    return false
  }


  const columns = [
    {
      name: 'Id',
      cell: row => (<div onClick={() => { handleChange(row) }} data-tag="allowRowEvents">{row.IdCompany_PK}</div>),
      selector: row => row.IdCompany_PK,
      sortable: true,
    },
    {
      name: 'Razon Social',
      cell: row => (<div onClick={() => { handleChange(row) }} data-tag="allowRowEvents">{row.SocialName}</div>),
      selector: row => row.SocialName,
      sortable: true,
    },

    {
      name: 'Contacto',
      cell: row => (<div onClick={() => { handleChange(row) }} data-tag="allowRowEvents">{row.ContactPerson}</div>),
      selector: row => row.ContactPerson,
      sortable: true,
    },
    {
      name: 'Telefono',
      cell: row => (<div onClick={() => { handleChange(row) }} data-tag="allowRowEvents">{row.PhoneContact}</div>),
      selector: row => row.PhoneContact,
      sortable: true,
    },
    {
      name: 'Estado',
      cell: row => (<div onClick={() => { handleChange(row) }} data-tag="allowRowEvents">{(row.Status)}</div>),
      selector: row => row.Status,
      sortable: true,
    }, {
      name: 'Acciones',
      cell: row => (
        <div className="tabla dropdown dropstart">
          <i
            className="bi bi-three-dots-vertical"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          />
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <Link
                onClick={() => {

                  setData(row)
                  setShowForm(!showForm)
                  setRestart(!restart)
                  setIsView(false)
                  setIdFormat_PK(row.IdCompany_PK)
                }}
                className={'dropdown-item text-center border-end' + (editRow(row) ? '' : ' disabled')}
                to="/operations"
              >
                <i className="bi bi-pen me-3 "></i>Editar
              </Link>
            </li>
            <li>
              <Link
                className={'dropdown-item text-center border-end' + (editRow(row) ? '' : ' disabled')}
                to="/operations"
                onClick={() => {
                  setNameCompany(row.SocialName)
                  setIdInactive(row.IdCompany_PK)
                  setIsMain(row.MainCompany ==='MAIN'?true:false)
                  setRestart(!restart)
                  setModalInactive(true)
                  setModalStatus(row.Status === "Activo" ? "Inactivar" : "Activar")
                }}
              >
                {row.Status === "Activo" ? <><i className="bi bi-lightbulb-off me-3"></i>Inactivar</> : <><i className="bi bi-lightbulb me-3"></i>Activar</>}
              </Link>
            </li>
          </ul>
        </div>
      ),
      button: true,
    }

  ]


  return (
    <>

      <Row>
        <Col>
          {props.newBotton && (
            <Button
              variant="success"
              className="m-3 p-2 rounded-pill btn-sm ms-1"
              disabled={props.post ? false : true}
              onClick={async () => {
                setRestart(!restart)
                setShowForm(!showForm)
                setIsView(false)
                setIdFormat_PK('en creacion')
                
                  setData({ Status:'en creacion'})



                setRestart(!restart)
                setShowForm(!showForm)
                setIsView(false)
                setIdFormat_PK('en creacion')
              }}>

              <i className="fa-solid fa-file-contract mx-2" />Crear Empresa{''}
            </Button>
          )}
          <Col className="d-flex">
            <Form.Control
              type="text"
              id="search"
              placeholder="Búsqueda"
              aria-label="search input"
              value={search}
              onChange={OnFilter}
            />
            <Button data-tip="Limpiar Filtro" className="btn btn-success btn-sm" type="button" onClick={ClearSearch}>
              <i className="bi bi-arrow-counterclockwise" />
            </Button>
          </Col>
          <Row>
            <SpinnerLoad trigger={formats} />
            <DataTable
              fixedHeader={false}
              fixedHeaderScrollHeight={{ fixedHeaderScrollHeight: '100px' }}
              columns={columns}
              data={filterindItems}
              pagination
              responsive
              dense={props.dense}
              highlightOnHover
              pointerOnHover
              noDataComponent="No hay registros para mostrar"
              paginationComponentOptions={{
                rowsPerPageText: 'Filas por página:',
                rangeSeparatorText: 'de',
              }}
            />
          </Row>
        </Col>
      </Row>
      {showForm && <COMFORCompanies
        sessionUser={props.sessionUser}
        data={data}
        setData={setData}
        show={showForm}
        hide={setShowForm}
        restart={restart}
        setRestart={setRestart}
        isView={isView}
        IdCompany_PK={IdFormat_PK}

      />}
      <Modal
        show={ModalInactive}
        onHide={() => {
          setModalInactive(false)
        }}
        centered
      >
        <Modal.Header closeButton>
          <h5>{ModalStatus} Empresa</h5>
        </Modal.Header>
        <Modal.Body>
          {!isMain?<p>¿Realmente desea {ModalStatus} la  empresa {nameCompany}?</p>:
            <p>no se puede inactivar a {nameCompany} por que es la empresa principal del sistema </p>
        }
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setModalInactive(false)
            }}
          >
            Cancelar
          </Button>
         {!isMain && <Button className="btn btn-danger"
            onClick={() => {
              inactiveCompany()
              setModalInactive(false)
            }}
          >
           {ModalStatus}
          </Button>}
        </Modal.Footer>
      </Modal>
    </>




  )
}

export default Companies
