import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { sessionActive } from '../../lib/functions'
import { Col, Row, Button, Form, Modal } from 'react-bootstrap'
import COMFORContract from '../../lib/formatos/COMFORContract'
import { getFormats, deleteFormatId,updateFormat   } from '../../lib/formatos/request'
import {  getUsersF } from  '../../lib/request'
// import File, { getExtension, postFile, IMAGE_URL,createImage } from 'utils/file/File'
import SpinnerLoad from '../../lib/spinnerLoad'


export const Contracts = props => {
  const IdUser_PK = localStorage.getItem('IdUser_PK')
  const [restart, setRestart] = useState(false)
  const [IdFormat_PK, setIdFormat_PK] = useState('')
  const [showForm, setShowForm] = useState(false)
  const [isView, setIsView] = useState(false)
  const [ModalStatus, setModalStatus] = useState(false)
  const [idDelete, setIdDelete] = useState()
  const [search, setSearch] = useState()
  const [data, setData] = useState()
  const [filterindItems, setFilterindItems] = useState([])
  const [ModalDelete, setModalDelete] = useState(false)

  const [list, setList] = useState("")
  const typef = 'COMFORContract'
  const [Contracts, setContracts] = useState()


  
  useEffect(() => {
    if (!restart) {
      setRestart(!restart)
      sessionActive()
      fillTable()
    }
  }, [restart])

  const fillTable = async () => {
    //Obtener lista de servicios
    var resServices =( await getFormats('comforservicios')).data.data.map(i =>{
      return {...i, value: i.IdFormat_PK, name: i.IdFormat_PK + ' - ' + i.name     }
    });
  
   //Obtener lista de Empresas
    const resCompanies = (await getFormats("company")).data.data.map(i =>{
      return {...i, value: i.IdCompany_PK, name: i.IdCompany_PK + ' - ' + i.SocialName + ' ' + i.LegalForm    }
    });
    //Obtener lista de usuarios
    const resUsers = (await getUsersF()).data.data.map( i =>{
      return {...i, value:i.IdUser_PK, name: i.IdUser_PK + ' - ' + i.UserName + ' ' + i.UserLastName+ ', '+ resCompanies.filter(item => item.IdCompany_PK === i.IdCompany_FK)[0].SocialName
      }
    }
  );
    setList({ companies:resCompanies, services:resServices, users:resUsers })
    //Obtener lista de contratos
    const resFormats = await getFormats(typef)
    if (resFormats.data.status === 200) {
      setContracts(resFormats.data.data)
      setFilterindItems(resFormats.data.data)
    } else {
      toast.error(resFormats.data.msj)
    }  
  }

//---------Manejo de imagenes
// const [files, setFiles] = useState({})
// const handleChangeImage = ({ target }) => {
//   const { name, files: currentFile } = target
//   if (name === 'EmpPhoto') {
//     setData({ ...data, personal: { ...data, extension: getExtension(currentFile[0].name) } })
//   }
//   setFiles({ ...files, [name]: currentFile[0] })

// }


  const OnFilter = e => {
    let FilterText = e.target.value
    setSearch(FilterText)
    setFilterindItems(
      Contracts.filter(
        item =>
          item.IdFormat_PK.includes(FilterText) ||  //este no sale
          item.vigencia.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.IdCompany_FK.includes(FilterText) ||
          item.createdBy.includes(FilterText) ||
          item.description.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.Status.toLowerCase().includes(FilterText.toLowerCase()) 
      )
    )
  }

  const ClearSearch = () => {
    setSearch('')
    setFilterindItems(Contracts)
  }

  const handleChange = (data) => { 
    

    setData({
      ...data,
      Items :data.Items.indexOf("{") !== -1 ? JSON.parse(data.Items) : [] , 
      users:data.users.indexOf("{") !== -1 ? JSON.parse(data.users) : [] , 
      Empresa : JSON.parse(data.Empresa)
     })
    setRestart(false)
    setShowForm(!showForm)
    setIsView(true)
    setIdFormat_PK (data.IdFormat_PK )

  }
  const editRow = row => {
    if (props.put) {
      if (IdUser_PK === row.IdUser_FK && row.Status === 'solicitado') return true
      else if ( row.Status !== 'solicitado') return true
      else return false
    }
    return false
  }
  const deleteRow = row => {
    if (props.delete && false) {   // funcion inabilitada hasta verificar la logica
      return true
    }
    return false
  }

  const deleteContract = async () => {
     //borrar un solicitud
    const resDelele = await deleteFormatId ('comforservicios', idDelete)
    console.log(resDelele)
   
    if (resDelele.data.status === 200) {
      toast.success(resDelele.data.msj)
    } else {
      toast.error(resDelele.data.msj)
    }
    fillTable()
  }

  const onSubmit = async e => {
  
    
    e.preventDefault()
    const { accessKey, id } = e.target
    const data = { IdFormat_PK: accessKey, status: id }
    const res = await updateFormat(data, typef)
    if (res.data.status === 200) {
      toast.success(res.data.msj)
    }
    else {
      toast.error(res.data.msj);
    }
    setRestart(!restart)
  }
  const columns = [
    {
      name: 'id',
      cell: row => (<div onClick={() => {handleChange(row)}}data-tag="allowRowEvents">{row.IdFormat_PK}</div>),
      selector: row => row.IdFormat_PK,
      sortable: true,
      grow:0.3
    },
    {
      name: 'CeCo',
      cell: row => (<div onClick={() => {handleChange(row)}}data-tag="allowRowEvents">{row.CeCo}</div>),
      selector: row => row.IdFormat_PK,
      sortable: true,
      grow:0.3
    },
    {
      name: 'Vigencia',
      cell: row => (<div onClick={() => {handleChange(row)}}data-tag="allowRowEvents">{row.vigencia}</div>),
      selector: row => row.vigencia,
      sortable: true,
      grow:0.3
    },

    {
      name: 'Empresa',
      cell: row => (<div onClick={() => {handleChange(row)}}data-tag="allowRowEvents">{JSON.parse(row.Empresa).SocialName}</div>),
      selector: row => row.name,
      sortable: true,
      grow:2.5
        },

    {
      name: 'Creado por',
      cell: row => (<div onClick={() => {handleChange(row)}}data-tag="allowRowEvents">{row.createdBy}</div>),
      selector: row => row.createdBy,
      sortable: true,
      grow:0.5
    },
    {
      name: 'Descripción',
      cell: row => (<div onClick={() => {handleChange(row)}}data-tag="allowRowEvents">{row.description}</div>),
      selector: row => row.description,
      sortable: true,
      grow:2.5
    },
    {
      name: 'Estado',
      cell: row => (<div onClick={() => {handleChange(row)}}data-tag="allowRowEvents">{(row.Status)}</div>),
      selector: row => row.Status,
      sortable: true,
    }, {
      name: 'Acciones',
      cell: row => (
        <div className="tabla dropdown dropstart">
          <i
            className="bi bi-three-dots-vertical"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          />
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <Link
                onClick={() => {


                  setData({
                    ...row,
                    Items :row.Items.indexOf("{") !== -1 ? JSON.parse(row.Items) : [] , 
                    users:row.users.indexOf("{") !== -1 ? JSON.parse(row.users) : [] , 
                     Empresa : JSON.parse(row.Empresa)
                   })
                   setShowForm(!showForm)
                  setRestart(!restart)
                  setIsView(false)
                  setIdFormat_PK (row.IdFormat_PK )
                }}
                className={'dropdown-item text-center border-end' + (editRow(row) ? '' : ' disabled')}
                to="/operations"
              >
                <i className="bi bi-pen me-3 "></i>Editar
              </Link>
            </li>
            <li>
              <Link
                className={'dropdown-item text-center border-end' + (deleteRow(row) ? '' : ' disabled')}
                to="/operations"
                onClick={() => {
                  setIdDelete(row.IdFormat_PK)
                  setRestart(false)
                  setModalDelete(true)
                }}
              >
                <i className="bi bi-trash me-3"></i>Borrar
              </Link>
            </li>
          </ul>
        </div>
      ),
      button: true,
    },

  ]


  return (
    <div>
      <Row>
        <Col>
        
          {props.newBotton && (
            <Button
              variant="success"
              className="m-3 p-2 rounded-pill btn-sm ms-1"
              disabled={props.post ? false : true}
              onClick={()=>{
                setData({...data, Empresa:[]})
                setRestart(!restart)
                setShowForm(!showForm)
                setIsView(false)
                setIdFormat_PK('en creacion')
              }}>

              <i className="fa-solid fa-file-contract mx-2"/>Crear Contrato{''}
            </Button>
          )}
          {showForm && <COMFORContract 
          sessionUser={props.sessionUser} 
          list={list} hide={setShowForm} 
          data={ {...data}}    //, image: files?.EmpPhoto}} 
          setData={setData}  
          isView={isView} show={showForm} 
          restart={restart} 
          setRestart={setRestart}
          // handleChangeImage={handleChangeImage}
          />}
          <Col className="d-flex">
            <Form.Control
              type="text"
              id="search"
              placeholder="Búsqueda"
              aria-label="search input"
              value={search}
              onChange={OnFilter}
            />
            <Button data-tip="Limpiar Filtro" className="btn btn-success btn-sm" type="button" onClick={ClearSearch}>
              <i className="bi bi-arrow-counterclockwise"/>
            </Button>
          </Col>
          <Row>
            <SpinnerLoad trigger={Contracts} />
            <DataTable
              fixedHeader={false}
              fixedHeaderScrollHeight={{ fixedHeaderScrollHeight: '100px'}}
              columns={columns}
              data={filterindItems}
              pagination
              responsive
              dense={props.dense}
              highlightOnHover
              pointerOnHover
              noDataComponent="No hay registros para mostrar"
              paginationComponentOptions={{
                rowsPerPageText: 'Filas por página:',
                rangeSeparatorText: 'de',
              }}
            />
          </Row>
        </Col>
      </Row>

      <Modal
        show={ModalDelete}
        onHide={() => {
          setModalDelete(false)
        }}
        centered
      >
        <Modal.Header closeButton>
          <h5>Eliminar Solicitud</h5>
        </Modal.Header>
        <Modal.Body>
          <p>¿Realmente desea eliminar el contrato {idDelete}?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-danger"
            onClick={()=>{
              setModalDelete(false)
            }}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            className="btn btn-secundary"
            id={"delete"}
            accessKey={idDelete}
            onClick={() => {
              deleteContract()
            }}
            
          >
            Eliminar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={ModalStatus === "Inactivar" || ModalStatus === "Activar"}
        onHide={()=>{
          setModalStatus(false)
        }}
        centered
      >
        <Modal.Header closeButton><h5>{ModalStatus} Servicio</h5></Modal.Header>
        <Modal.Body>
          <p>¿Realmente desea {ModalStatus} el servicio {idDelete}?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-danger"
            onClick={()=>{
              setModalStatus(false)
            }}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            className="btn btn-secundary"
            id={ModalStatus === "Inactivar" ? "Inactivo" : "Activo"}
            accessKey={idDelete}
            onClick={onSubmit}
          >
            {ModalStatus}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Contracts
