import React, { useState, useEffect } from 'react'
import { sessionActive, decodeUserPermission } from '../lib/functions'
import { getFullData } from '../lib/request'
import { Col, Row, Card } from 'react-bootstrap'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import Solicitudes from './Solicitudes/Solicitudes'
// import Cotizaciones from './Cotizaciones/Cotizaciones'
import OrdenServicio from './OrdenServicio/OrdenServicio'
import EncuestSatisfaccion from './EncuestaSatisfaccion/EncuestaSatisfaccion'
import Informes from './Informe/Informes'
import SelectContract from './SelectContract'
import { toast } from 'react-toastify'
import { getUserByIdF } from '../lib/request'
import { Button } from 'react-bootstrap'
import COMFORContract from '../lib/formatos/COMFORContract'


export const Requisiones = ({  dataUser,  contracts,  contractSelected }) => {
  const [put, setPut] = useState(false)
  const [_delete, set_delete] = useState(false)
  const [post, setPost] = useState(false)
  const [showModal, setShowModal] = useState(true)
  const [show, hide] = useState(false)
  const [restart, setRestart] = useState(false)
const [showContract, hideContract] = useState(false)
const [viewContract, setViewContract] = useState(contractSelected)

  useEffect(() => {
  

    if (!restart) {
      setRestart(!restart)
      Permissions()
      sessionActive()
      
    }
  }, [restart,contracts ])




  const Permissions = async () => {  
      
    //Obtener Permisos de Usuario
    const resPermission = decodeUserPermission(
      localStorage.getItem('userPermission'),
      localStorage.getItem('IdUser_PK')
    )
    if (resPermission.superAdmin.access) {
      setPut(true)
      set_delete(true)
      setPost(true)
    } else {
      setPut(resPermission.client.PUT)
      set_delete(resPermission.client.DELETE)
      setPost(resPermission.client.POST)
    }


 }

  return (
    <>{ (
      <div className="position-relative page-content py-3">
        <Row>
          <Col>
            <Card style={{ minHeigth: '900px' }}>
              <Tabs>
                <Card.Header className="card-tabs text-center  align-items-center ">
                  <TabList>
                    <h5 className="tabs-tittle">Solicitud de servicio</h5> 
                       <Button className="btn btn-primary btn-sl pl-1 m-2" type="button" onClick={()=>hideContract(!showContract)} >
                  <i className="bi bi-eye" />
                </Button>
                    <Tab><p>Solicitudes</p></Tab>
                   
                    <Tab><p>Orden de servicio</p></Tab>
                    <Tab><p>Informes</p></Tab>
                    <Tab style={{ width: '220px' }}><p>Encuesta de satisfaccion</p></Tab>
                    
                
                  </TabList>
                 
                </Card.Header>
                <Card.Body>
                  <TabPanel>
                    <Solicitudes put={put} _delete={_delete} post={post} newBotton={true} dataUser={dataUser} contractSelected={contractSelected} init={setRestart} route={"/request"}>
                      {' '}
                    </Solicitudes>
                  </TabPanel>
                  <TabPanel>
                    <OrdenServicio put={put} _delete={_delete} post={post} newBotton={true} dataUser={dataUser} contractSelected={contractSelected} init={setRestart}>
                      {' '}
                    </OrdenServicio>
                  </TabPanel>
                  <TabPanel>
                    <Informes put={put} _delete={_delete} post={post} newBotton={true} dataUser={dataUser} contractSelected={contractSelected} init={setRestart}>
                      {' '}
                    </Informes>
                  </TabPanel>
                  <TabPanel>
                    <EncuestSatisfaccion put={put} _delete={_delete} post={post} newBotton={true} dataUser={dataUser} contractSelected={contractSelected} init={setRestart}>
                      {' '}
                    </EncuestSatisfaccion>          
                  </TabPanel>

                    
                </Card.Body>
              </Tabs>
            </Card>
          </Col>
        </Row>
      </div>)}
      {showContract&&<COMFORContract
        data={contractSelected}
        setData={setViewContract}

        show={showContract}
        hide={hideContract}
        restart={restart}
        setRestart={setRestart}
        isView={true}
        IdFormat_PK={contractSelected.IdFormat_PK}
       
      />}

    </>
  )
}

