import axios from 'axios'
import ApiURL from '../../../services/apirest'

export const getUsersF = async () => {
  const config = {
    headers: { 'x-access-token': localStorage.getItem('TOKEN') },
  }
  const res = await axios.get(ApiURL + 'user', config)
  return res
}

export const getCompaniesF = async type => {
  const config = {
    headers: { 'x-access-token': localStorage.getItem('TOKEN') },
  }
  const res = await axios.get(ApiURL + 'companies/' + type + '/', config)
  return res
}

export const getCompanieByIdF = async (type, id) => {
  const config = {
    headers: { 'x-access-token': localStorage.getItem('TOKEN') },
  }
  const res = await axios.get(ApiURL + 'companies/' + type + '/' + id, config)
  return res
}

export const deleteUserByIdF = async id => {
  const config = {
    headers: { 'x-access-token': localStorage.getItem('TOKEN') },
    data: {
      IdUser_PK: id,
    },
  }
  const res = await axios.delete(ApiURL + 'user/', config)
  return res
}

export const getUserByIdF = async (ColumnName, Value,  fullInfo) => {
  "add @ a value p.e. 80123454@ to search all Status"
  const config = {
    headers: { 'x-access-token': localStorage.getItem('TOKEN') },
  }
  const data = {
    ColumnName: ColumnName,
    Value: Value,
    FullInfo: fullInfo,
    
  }
  const res = await axios.post(ApiURL + 'user/getById', data, config)
  return res
}



export const EditingUserF = async (data) => {
  
  const config = {
    headers: { 'x-access-token': localStorage.getItem('TOKEN') },
  }

  const res = await axios.put(ApiURL + 'user', data, config)
  return res
}

export const updateSignUser = async (data) => {
  
  const config = {
    headers: { 'x-access-token': localStorage.getItem('TOKEN') },
  }

  const res = await axios.put(ApiURL + 'user/sign', data, config)
  return res
}



export const CreateUserF = async (data) => {
  
  const config = {
    headers: { 'x-access-token': localStorage.getItem('TOKEN') },
  }
 
  const res = await axios.post(ApiURL + 'user', data, config)
  return res
}

export const setPermissionF = async (Encryp,maxRollEncrip, IdUser_PK) => {
  const config = {
    headers: { 'x-access-token': localStorage.getItem('TOKEN') },
  }
  const data = {
    userPermission: Encryp,
    IdUser_PK: IdUser_PK,
    maxRoll: maxRollEncrip
  }
  const res = await axios.put(ApiURL + 'user', data, config)
  return res
}

export const CreateCompanieF = async (dataCompany) => {
  const config = {
    headers: { 'x-access-token': localStorage.getItem('TOKEN') },
  }
  const data = {
    type: 'CC',
    data:dataCompany
  }
  const res = await axios.post(ApiURL + 'companies', data, config)
  return res
}

export const EditingCompanieF = async (dataCompany) => {
  const config = {
    headers: { 'x-access-token': localStorage.getItem('TOKEN') },
  }
  const data = {
    type: 'CC',
    data: dataCompany,
  }
  const res = await axios.put(ApiURL + 'companies', data, config)
  return res
}

export const editCompany = async company => {
  const config = {
    headers: { 'x-access-token': localStorage.getItem('TOKEN') },
  }
  const data = {
    type: 'CC',
    data: company,
  }
  const res = await axios.put(ApiURL + 'companies', data, config)
  return res
}

export const deleteCompanieByIdF = async (type, id) => {
  const config = {
    headers: { 'x-access-token': localStorage.getItem('TOKEN') },
  }
  const res = await axios.delete(ApiURL + 'companies/' + type + '/' + id, config)
  return res
}



export const getDataF = async (type) => {
 
  const config = {
    headers: { 'x-access-token': localStorage.getItem('TOKEN') },
  }
  const res = await axios.get(ApiURL + 'data/' + type, config)
  return res.data.data
}

export const getMultiDataF = async (table, type) => {
  
  const config = {
    headers: { 'x-access-token': localStorage.getItem('TOKEN') },
  }
  const res = await axios.get(ApiURL + 'mdata/' + table + '/' + type, config)

  return res.data.data
}
export const CreateMultiDataF = async (data, table) => {
  const config = {
    headers: { 'x-access-token': localStorage.getItem('TOKEN') },
  }
  const dataSend = {
    data, table
  }
  const res = await axios.post(ApiURL + 'mdata/', dataSend, config)
  return res
}


export const getUniqueList = async (table, column) => {
  const config = {
    headers: { 'x-access-token': localStorage.getItem('TOKEN') },
  }
  const res = await axios.get(ApiURL + 'uniqueList/' + table + '/' + column, config)
  return res.data.data
}
export const getFullData = async (table, column, id) => {
  const data = { table, column, id }
  const config = {
    headers: { 'x-access-token': localStorage.getItem('TOKEN') },
  }
  const res = await axios.put(ApiURL + 'fulldata/', data, config)
  return res
}


export const deleteFile = async (path) => {
  let pathUncode = path.replace('/','@$')
  const config = {
    headers: { 'x-access-token': localStorage.getItem('TOKEN') },
  }
  const res = await axios.delete(ApiURL + 'file/' + pathUncode, config)
  return res
}


export const uploadFile = async (path, formData) => {
  let folder = path.replace('/','@$')
  const config = {
    headers: { 'x-access-token': localStorage.getItem('TOKEN') },
  }
  const res = await axios.post(ApiURL + 'file/' +folder, formData, config)
  return res
}