import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { sessionActive } from '../../../lib/functions'
import { Col, Row, Button, Form, Modal } from 'react-bootstrap'
import GTHFOR070 from '../../../lib/formatos/GTHFOR070'
import GTHFOR086 from '../../../lib/formatos/GTHFOR086'
import { getFormats, eraseFormatId, updateFormat } from '../../../lib/formatos/request'
import { useUserContext } from '../../../../../hooks/custom'
import SpinnerLoad from '../../../lib/spinnerLoad'

export const Vacant = props => {
  const { sessionUser } = useUserContext()
  const IdUser_PK = localStorage.getItem('IdUser_PK')
  const [restart, setRestart] = useState(false)
  const [IdFormat_PK, setIdFormat_PK] = useState('')
  const [showForm, setShowForm] = useState(false)
  const [formats, setFormats] = useState('')
  const [isView, setIsView] = useState(false)
  const isRev = false
  const isApro = false
  const [idDelete, setIdDelete] = useState()
  const [search, setSearch] = useState()
  const [filterindItems, setFilterindItems] = useState([])
  const [ModalDelete, setModalDelete] = useState(false)
  const [data, setData] = useState()
  const [showFormCreate, setShowFormCreate] = useState(false)
  const [list, setList] = useState("")
  const [listTH, setListTH] = useState([])


  useEffect(() => {
    setSearch('')
    if (!restart) {
      fillTable()
      
      sessionActive()
      setRestart(!restart)
    }
  }, [restart, props.sessionUser, data])

  const fillTable = async () => {
    const resFormats = await getFormats('GTHFOR070', true)
    if (resFormats.data.status === 200) {
      let dataFormat = resFormats.data.data
      if (!props.isTH) {
        dataFormat = dataFormat.filter(item => item.IdUser_FK.toString() === sessionUser.IdEmployee_FK)
      }
      setFormats(dataFormat)
      setFilterindItems(dataFormat)
    } else {
      toast.error(resFormats.data.msj)
    }
    ////------------descaragda data par formularios
    let temp
    temp = resFormats.data.list

    setList({
      ...temp,
      listaOtroRecurso: temp.listaOtroRecurso.map((number, i) => <option key={i} value={number.OtroRecurso}></option>),
      listaCargos: temp.listaOtroRecurso.map((number, i) => <option key={i} value={number.cargo}></option>)
    })
    if(props.isTH){
    const resFormats = await getFormats('GTHFOR086')
    setListTH(resFormats.data.list)
  }

  }

  const editRow = row => {
    /* !props.put || row.Status!="solicitado" && !isTH || IdUser_PK!=row.IdUser_FK*/

    if (props.put || props.admin) {
      if (props.admin) return true
      else if (IdUser_PK.toString() === row.IdUser_FK.toString() && row.Status === 'creado') return true
      else if (IdUser_PK.toString() === row.IdUser_FK.toString() && row.Status === 'solicitado') return true
      else if (props.isTH && row.Status === 'aprobado') return true
      else return false
    }
    return false
  }

  const copyRow = row => {
    /* !props.put || row.Status!="solicitado" && !isTH || IdUser_PK!=row.IdUser_FK*/

    if (props.post)
      return true
    return false
  }
  const newEmployee = (data) => {

    if (props.isTH && data.Status === "procesado")
      return true
    return false
  }
  const deleteRow = row => {

    /* !props.put || row.Status!="solicitado" && !isTH || IdUser_PK!=row.IdUser_FK*/
    if (props._delete) {

      if (IdUser_PK.toString() === row.IdUser_FK.toString() && row.Status === 'creado') return true
      else if (props.isTH) return false
      else return false
    }
    return false
  }
  const deleteSol = async () => {
    let result=0
    //borrar un solicitud
    const resDelele = await eraseFormatId('GTHFOR070', idDelete)
    if (resDelele.data.status === 200) {
       result = await updateFormat({
        IdFormat_PK: data.manualFunciones,
        HSE: JSON.stringify(JSON.parse(list.matrixauth.legal).HSEIni),
        Status: 'disponible'
      }, 'GTHFOR76')
      
      toast.success(resDelele.data.msj)
    } else {
      toast.error(resDelele.data.msj)
    }
    fillTable()
  }

  

  
  const OnFilter = e => {
    let FilterText = e.target.value
    setSearch(FilterText)
    setFilterindItems(
      formats.filter(
        formato =>
          formato.nombreSolicitante.toLowerCase().includes(FilterText.toLowerCase()) ||
          formato.Status.toLowerCase().includes(FilterText.toLowerCase()) ||
          formato.cargoRequerido.toLowerCase().includes(FilterText.toLowerCase()) ||
          formato.fechaSolicitud.toLowerCase().includes(FilterText.toLowerCase()) ||
          formato.nombreCandidato.toLowerCase().includes(FilterText.toLowerCase()) ||
          formato.apellidoCandidato.toLowerCase().includes(FilterText.toLowerCase()) ||

          formato.fechaProgIngreso.toLowerCase().includes(FilterText.toLowerCase())
      )
    )
  }

  const ClearSearch = () => {
    setSearch('')
    setFilterindItems(formats)
  }

  const columns = [
    {
      name: 'Solicitud',
      cell: row => (
        <div
          onClick={() => {
            setShowForm(!showForm)
            setRestart(false)
            setIsView(true)
            setIdFormat_PK(row.IdFormat_PK)
            setData(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.IdFormat_PK}
        </div>
      ),
      selector: row => row.IdFormat_PK,
      sortable: true,
      grow: 0.5
    },
    {
      name: 'Solicidado por',
      cell: row => (
        <div
          onClick={() => {
            setShowForm(!showForm)
            setRestart(false)
            setIsView(true)
            setIdFormat_PK(row.IdFormat_PK)
            setData(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.nombreSolicitante}
        </div>
      ),
      selector: row => row.IdFormat_PK,
      sortable: true,
      grow: 0.5
    },
    {
      name: 'fecha',
      cell: row => (
        <div
          onClick={() => {
            setShowForm(!showForm)
            setRestart(false)
            setIsView(true)
            setIdFormat_PK(row.IdFormat_PK)
            setData(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.fechaSolicitud}
        </div>
      ),
      selector: row => row.IdFormat_PK,
      sortable: true,
      grow: 0.5
    },
    {
      name: 'cargo Requerido',
      cell: row => (
        <div
          onClick={() => {
            setShowForm(!showForm)
            setRestart(false)
            setIsView(true)
            setIdFormat_PK(row.IdFormat_PK)
            setData(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.cargoRequerido}
        </div>
      ),
      selector: row => row.IdFormat_PK,
      sortable: true,
      grow: 2
    },
    {
      name: 'Candidatos',
      cell: row => (
        <div
          onClick={() => {
            setShowForm(!showForm)
            setRestart(false)
            setIsView(true)
            setIdFormat_PK(row.IdFormat_PK)
            setData(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.nombreCandidato + ' ' + row.apellidoCandidato}
        </div>
      ),
      selector: row => row.IdFormat_PK,
      sortable: true,
      grow: 2
    },
    {
      name: 'fecha Ingreso',
      cell: row => (
        <div
          onClick={() => {
            setShowForm(!showForm)
            setRestart(false)
            setIsView(true)
            setIdFormat_PK(row.IdFormat_PK)
            setData(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.fechaProgIngreso}
        </div>
      ),
      selector: row => row.IdFormat_PK,
      sortable: true,

    },
    {
      name: 'Estado',
      cell: row => (
        <div
          onClick={() => {
            setShowForm(!showForm)
            setRestart(false)
            setIsView(true)
            setIdFormat_PK(row.IdFormat_PK)
            setData(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.Status}
        </div>
      ),
      selector: row => row.IdFormat_PK,
      sortable: true,
      grow: 1.5,
    },
    {
      name: 'Acciones',
      cell: row => (
        <div className="tabla dropdown dropstart">
          <i
            className="bi bi-three-dots-vertical"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          />
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            {props.isTH && (<li>
              <Link
                onClick={() => {
              
                  setShowFormCreate(!showFormCreate)
                  setRestart(false)
                  setIsView(false)
                  setIdFormat_PK("en creacion")
                  setData(row)

                }}
                className={'dropdown-item text-center border-end' + (newEmployee(row) ? '' : ' disabled')}
                to={props.route}
              >
                <i className="bi bi-pen me-3 "></i>Crear Empleado
              </Link>
            </li>)}
            {!props.isTH && (<li>
              <Link
                onClick={() => {
                  setShowForm(!showForm)
                  setRestart(false)
                  setIsView(false)
                  setIdFormat_PK('copiando')
                  setData(row)

                }}
                className={'dropdown-item text-center border-end' + (copyRow(row) ? '' : ' disabled')}
                to={props.route}
              >
                <i className="far fa-copy me-3 "></i>copiar
              </Link>
            </li>)}
            {(!props.isTH || props.admin) && (<li>
              <Link
                onClick={() => {
                  setData({ ...row, Status: props.isTH ? 'procesando' : 'editando' })
                  setShowForm(!showForm)
                  setRestart(false)
                  setIsView(false)
                  setIdFormat_PK(row.IdFormat_PK)

                }}
                className={'dropdown-item text-center border-end' + (editRow(row) ? '' : ' disabled')}
                to={props.route}
              >
                <i className="fas fa-edit me-3 "></i>Editar
              </Link>
            </li>)}

            {!props.isTH && (<li>
              <Link
                className={'dropdown-item text-center border-end' + (deleteRow(row) ? '' : ' disabled')}
                to={props.route}
                onClick={() => {
                  setData(row)
                  setIdDelete(row.IdFormat_PK)
                  setRestart(false)
                  setModalDelete(true)
                }}
              >
                <i className="bi bi-trash me-3"></i>Borrar
              </Link>
            </li>)}
          </ul>
        </div>
      ),
      button: true,
    },
  ]

  return (
    <div>
      <Row>
        <Col>
          {props.newBotton && (
            <Button
              variant="success"
              className="m-3 p-2 rounded-pill btn-sm ms-1"
              disabled={props.post ? false : true}
              onClick={() => {
                setShowForm(!showForm)
                setRestart(false)
                setIsView(false)
                setData({ Status: 'creando' })
                setIdFormat_PK('en creacion')
              }}
            >
              <i className="bi bi-window-plus mx-2" />
              Crear Solicitud
            </Button>
          )}
          <Col className="d-flex">
            <Form.Control
              type="text"
              id="search"
              placeholder="Búsqueda"
              aria-label="search input"
              value={search}
              onChange={OnFilter}
            />
            <Button className="btn btn-success btn-sm" type="button" onClick={ClearSearch}>
              <i className="bi bi-arrow-counterclockwise" />
            </Button>
          </Col>
          <Row>
            <SpinnerLoad trigger={formats} />
            <DataTable
              fixedHeader={false}
              fixedHeaderScrollHeight={{ fixedHeaderScrollHeight: '100px' }}
              columns={columns}
              data={filterindItems}
              pagination={filterindItems?.length > 10 ?? true}
              responsive
              dense={filterindItems?.length > 3 ?? false}
              highlightOnHover
              pointerOnHover
              noDataComponent="No hay registros para mostrar"
              paginationComponentOptions={{
                rowsPerPageText: 'Filas por página:',
                rangeSeparatorText: 'de',
              }}
            />

          </Row>
        </Col>
      </Row>

      {showForm && <GTHFOR070
        sessionUser={sessionUser}
        data={data}
        show={showForm}
        hide={setShowForm}
        isTH={props.isTH}
        isApro={isApro}
        isRev={isRev}
        restart={restart}
        setRestart={setRestart}
        isView={isView}
        IdFormat_PK={IdFormat_PK}
        list={list}
      />}
      {showFormCreate && <GTHFOR086
        sessionUser={sessionUser}
        data={data}
        setData={setData}
        show={showFormCreate}
        hide={setShowFormCreate}
        isTH={props.isTH}
        isCreate={true}
        isApro={isApro}
        isRev={isRev}
        restart={restart}
        setRestart={setRestart}
        isView={isView}
        IdFormat_PK={IdFormat_PK}
        list={listTH}
      />}

      <Modal
        show={ModalDelete}
        onHide={() => {
          setModalDelete(false)
        }}
        centered
      >
        <Modal.Header closeButton>
          <h5>Eliminar Solicitud</h5>
        </Modal.Header>
        <Modal.Body>
          <p>¿Realmente desea eliminar la solicitud {idDelete}?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {

              setModalDelete(false)
            }}
          >
            Cancelar
          </Button>
          <Button className="btn btn-danger"
            onClick={() => {
              deleteSol()
              setModalDelete(false)
            }}
          >
            Eliminar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Vacant
