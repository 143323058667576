import React, { useEffect, useState, useCallback, useMemo  } from 'react'
import styled from 'styled-components'
import { Button, Col, Form, Row } from 'react-bootstrap'
import Moment from 'moment'
import { CreateFormat, getFormats, updateFormat } from './request'
import { CommTable } from '../../commercial/CommTable'
import { toast } from 'react-toastify'
import { Typeahead } from 'react-bootstrap-typeahead'
import SelectOption from '../SelectOption'
import image from '../../../../assets/img/Logo_b-st.png'
import { onlyNumericEvent } from '../functions'


export const COMFORContract = ({
  show,
  hide,
  restart,
  setRestart,
  isView,
  data = [],
  sessionUser,
   setData,
  IdFormat_PK = "En Creación",

  list
}) => {
  //-------------------------Info Formato
  const formato = 'COM-FOR-CONTRACTOS'
  const revision = 'N/A'
  const fechaRev = 'N/A'
  const nombreFormato = 'INGRESO DE CONTRATOS'
  const typef = 'COMFORContract'

  //-------------------------Registros
  const [date, setDate] = useState('')
  const [items, setItems] = useState([])
  const [users, setUsers] = useState([])
  const [empresa, setEmpresa] = useState({})
  const [empresaSel, setEmpresaSel] = useState()
  



  useEffect(() => { 
    getInfo()
    if (!restart) {
      setRestart(!restart)
      if (show) getInfo()     
    }    
  }, [restart])

  useEffect(() => {
  if(!!empresaSel){
  let companySelected= list.companies.filter(i=>i.IdCompany_PK===empresaSel)[0]
    setEmpresa({
      Industry:companySelected.Industry,
      IdCompany_FK: companySelected.IdCompany_PK,
      SocialName: companySelected.SocialName,
      ContactPerson: companySelected.ContactPerson,
      url: companySelected.url,
      PhoneContact: companySelected.PhoneContact,
    })}

  }, [empresaSel])
  

  const handleChange = async ({ target }) => {
    const { name, type } = target
    const value = type === 'checkbox' ? target.checked : target.value
    setData({ ...data, [name]: value })
  }

  const getInfo = async () => {
    const formatDate = Moment().format('YYYY-MM-DD')
    setDate(formatDate)
    if (data["createdDate"] === undefined) {
      setData({ ...data, createdDate: formatDate, createdBy: sessionUser.IdEmployee_FK })
    } else {
      setItems((data.Items))
      setUsers((data.users))
      setEmpresa((data.Empresa))  
    }
    return true
  }

  const clearStates = () => {
    setData('')
    setDate('')
  }
  const onSubmit = async e => {
    e.preventDefault()
   var result = ''
    const temp = {
      ...data,
      IdCompany_FK: empresa.IdCompany_FK,
      users: JSON.stringify(users),
      Empresa: JSON.stringify(empresa),
      Items: JSON.stringify(items),
      Status: "activo",
      createdDate: date
    }
   
      if (data.IdFormat_PK === undefined)
      result = await CreateFormat(temp, typef)
    else
      result = await updateFormat(temp, typef)

    if (result.data.status === 200) {
      toast.success(result.data.msj)
    }
    else {
      toast.error(result.data.msj);
    }


    clearStates()
    hide(!show)
    setRestart(!restart)
  }



  return (
    <>
      {show && (
        <Overlay style={{ zIndex: '1' }}>
          <ModalContainer>
            <ModalHeader>
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                  <th style={{ width: '20%', fontSize:'10px', verticalAlign: 'bottom' }} rowSpan={3}>
                      <img
                        className="format_logo"
                        src={image}
                        style={{ paddingBottom: '7px', width: '75%', minWidth: "40px" }}
                        alt="Logo RMS"
                      /><br/>
                      NIT 830.507.705-2
                    </th>
                    <th style={{ width: '55%', height: '38px' }}>RELIABILITY MAINTENANCE SERVICES S.A.S</th>
                    <th style={{ width: '25%' }}>{formato}</th>
                  </tr>
                  <tr>
                    <th rowSpan={2}>{nombreFormato}</th>
                    <th style={{ fontSize: '10px' }}>Revisión:{revision}</th>
                  </tr>
                  <tr>
                    <th style={{ fontSize: '10px' }}>Fecha de revisión:{fechaRev}</th>
                  </tr>
                </tbody>
              </table>
            </ModalHeader>
            <CloseButtonModal onClick={() => {
                          clearStates()
                          hide(!show)
                        }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-x-square"
                viewBox="0 0 16 16">
                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </CloseButtonModal>
            <table>
              <tbody>
                <tr>
                  <th style={{ width: '55%', textAlign: 'right' }}></th>
                  <th style={{ width: '15%', textAlign: 'right' }}></th>
                  <td style={{ width: '25%', background: '#FFF' }}></td>
                </tr>
              </tbody>
            </table>

            <Form onSubmit={onSubmit}>
              <Frame isView={isView}>
                <table style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th colSpan={10} className="rms" style={{ width: '20%' }}>
                        <h2 className="text-center">INFORMACIÓN DE LA EMPRESA</h2>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ width: '13%' }}><label>Empresa:</label></td>
                      <td colSpan={4}  style={{ width: '28%' }}><input className='disable' name='SocialName' value={empresa.SocialName} onChange={handleChange} required disabled={true} /></td>
                     <td><label>NIT:</label></td>
                      <td  style={{ width: '15%' }}><input className='disable' name='IdCompany_PK' value={empresa.IdCompany_FK} onChange={handleChange} disabled={true} /></td>
                      <td style={{ width: '5px' }} ><label>URL:</label></td>
                      <td colSpan={2}  ><input className='disable' name='url' value={empresa.url} onChange={handleChange} required disabled={true} /></td>
                    </tr>
                    <tr>
                      <td><label>Persona contacto:</label></td>
                      <td colSpan={4}><input  name='ContactPerson' value={empresa.ContactPerson} onChange={e=>setEmpresa({...empresa,ContactPerson:e.target.value })} required disabled={isView ? true : false} /></td>
                      <td><label>Telefono:</label></td>
                      <td><input  name='PhoneContact' value={empresa.PhoneContact} onChange={e=>setEmpresa({...empresa,PhoneContact:e.target.value })}required disabled={isView ? true : false} /></td>
                      <td style={{ width: '5px' }}><label>Sector:</label></td>
                      <td><input className='disable' name='Industry' value={empresa.Industry} onChange={handleChange} required disabled={true} /></td>
                      </tr>
                    {!isView && (<tr>
                      <td colSpan={9}>
                      <SelectOption type={"custom"}
                            searchable={true}
                            state={empresaSel}
                            setState={setEmpresaSel}
                            list={list.companies}

                        />
                      
                      </td>
                    </tr>)}
                  </tbody>
                </table>
              </Frame>
              <Frame isView={isView}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <th className="rms" colSpan={6} style={{ width: '100%' }}>
                        <h2 className="text-center">INFORMACIÓN GENERAL</h2>
                      </th>
                    </tr>
                    <tr>
                      <td style={{ width: '12%' }}><label>Objeto contractual :</label></td>
                      <td colSpan={5} ><textarea name='Objeto' value={data.Objeto} onChange={handleChange} required disabled={isView ? true : false} /></td>
                    </tr>
                    <tr>
                      <td ><label>Inicio de contrato:</label></td>
                      <td style={{ width: '15%' }}><input name='fInicioContract' type={'date'} value={data.fInicioContract} onChange={handleChange} required disabled={isView ? true : false} /></td>
                      <td style={{ width: '12%' }} ><label>Inicio labor:</label></td>
                      <td style={{ width: '15%' }}><input name='fInicioReal' type={'date'} value={data.fInicioReal} onChange={handleChange} required disabled={isView ? true : false} /></td>
                      <td style={{ width: '12%' }}><label>Número de contrato:</label></td>
                      <td style={{ width: '15%' }}><input name='NumeroContrato' value={data.NumeroContrato} onChange={handleChange} required disabled={isView ? true : false} /></td>
                    </tr>
                    <tr>
                      <td><label>Vigencia:</label></td>
                      <td><input name='vigencia' value={data.vigencia} onChange={handleChange} required disabled={isView ? true : false} /></td>
                      <td ><label>Opción de uso:</label></td>
                      <td><input name='opcionUso' value={data.opcionUso} onChange={handleChange} required disabled={isView ? true : false} /></td>
                      <td ><label>Centro de costo:</label></td>
                      <td ><input name='CeCo' value={data.CeCo} type={'number'} onChange={handleChange} required disabled={isView ? true : false} /></td>

                    </tr>
                    <tr>
                      <td ><label>Gerente Contrato:</label></td>
                      <td ><input name='GerenteContrato' value={data.GerenteContrato} onChange={handleChange} required disabled={isView ? true : false} /></td>
                      <td  ><label>ID lider:</label></td>
                      <td ><input name='Lider' value={data.Lider} onChange={handleChange} 
                          onKeyPress={e => {
                            onlyNumericEvent (e)
                          }}
                          required disabled={isView ? true : false} /></td>
                      <td ><label>Área:</label></td>
                      <td><SelectOption type={"workArea"} searchable={true} state={data} setState={setData} nameState={'area'} required disabled={isView ? true : false} widthSelect='100%'/></td>
                    </tr>
                    <tr>
                      <td><label>Descripción</label></td>
                      <td colSpan={5}>
                        <textarea rows={4} name='description' value={data.description} onChange={handleChange} required disabled={isView ? true : false} />
                        
                        </td>
                    </tr>
                  </tbody>
                </table>
              </Frame>
              <Frame isView={isView}>
                <table style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th colSpan={8} className="rms" style={{ width: '20%' }}>
                        <h2 className="text-center">USUARIOS</h2>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan={8} style={{ width: '23%' }}>
                       <CommTable data={ users } setData={setUsers} isView={isView} tableType={'tableUsers'} list={list.users} /> 
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Frame>

              <Frame isView={isView}>
                <table style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th colSpan={8} className="rms" style={{ width: '20%' }}>
                        <h2 className="text-center">CONTROL DE SERVICIOS</h2>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan={8} style={{ width: '23%' }}>
                         <CommTable data={items} setData={setItems} isView={isView} tableType={'tableServices'} list={list.services} /> 
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Frame>
              <Frame isView={isView}>
                <table style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th colSpan={8} className="rms" style={{ width: '20%' }}>
                        <h2 className="text-center">VALIDACIÓN Y OBSERVACIONES</h2>
                      </th>
                    </tr>
                    <tr>
                      <td><label>Creado por :</label></td>
                      <td colSpan={2} style={{ width: '28%' }}><input className='disable' name='createdBy' value={data.createdBy} onChange={handleChange} disabled={true} /></td>
                      <td><label>Fecha :</label></td>
                      <td colSpan={2} style={{ width: '28%' }}><input className='disable' name='createdDate' type={'date'} value={data.createdDate} onChange={handleChange} disabled={true} /></td>
                    </tr>
                    <tr>
                      <td><label>Observaciones</label></td>
                      <td colSpan={5}><input name='Observaciones' value={data.Observaciones} onChange={handleChange} disabled={isView ? true : false} /></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan={8} style={{ width: '23%' }}>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Frame>
              <Frame isView={isView}>
                <table style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th colSpan={8} className="rms" style={{ width: '20%' }}>
                        <h2 className="text-center">DOCUMENTOS Y ADJUNTOS</h2>
                      </th>
                    </tr>

                  </thead>
             
                </table>
              </Frame>
              <Row>
                <Col></Col>
                <Col></Col>
                <Col></Col>
                <Col></Col>
                {!isView ? (
                  <>
                    <Col>
                      <Button
                        className="btn btn-danger"
                        onClick={() => {
                          clearStates()
                          hide(!show)
                        }}>

                        Cancelar
                      </Button>
                    </Col>
                    <Col>
                      <Button type="submit" className="btn btn-success">
                        Guardar
                      </Button>
                    </Col>
                  </>
                ) : (
                  <Col>
                    <Button
                      className="btn btn-secundary"
                      onClick={() => {
                        clearStates()
                        hide(!show)
                      }}
                    >
                      Cerrar
                    </Button>
                  </Col>
                )}
              </Row>
            </Form>
          </ModalContainer>
        </Overlay>
      )}
    </>
  )
}

export default COMFORContract

const Frame = styled.div`
  width: 950px;
  padding-bottom: 0px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: left;
  border-width: 0px;
  border-color: #1a004a;
  border-style: solid;
  font-size: 12px;
  font-weight: 100;
  .photo {
    background: #ebf1de;
  }
  .rms {
    background: #F2F2F2;
  }
  label {
    padding-left: 5px;
    display: flex;
    font-size: 12px;
    font-weight: bold;
    text-align: 'center';
  }
  input {
    text-align: center;
    width: 100%;
    border-bottom: 1px solid black;
    border-color: #1a004a;
    background: ${props => (props.isView ? '#FFF' : '#cee1ed')};

    padding-left: 5px;
    /* margin-top: 5px; */
  }
  textarea {
    text-align: justify;
    width: 100%;
    border-bottom: 1px solid black;
    border-color: #1a004a;
    background: ${props => (props.isView ? '#FFF' : '#cee1ed')};
    resize: none;

    padding-left: 5px;
    /* margin-top: 5px; */
  }

  input.edit {
    background: #cee1ed;
  }
  input.disable {
    background: #fff;
  }
  h2 {
    font-size: 14px;
    font-weight: bold;
    text-align: 'center';
  }

  th {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    text-align: left;
    vertical-align: center;
    padding-left: 5px;

    input {
      width: 100%;
    }
  }
  tr {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
  }
  td {
          border-width: 1px;
          border-color: #1a004a;
          border-style: solid;
  }
`

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgb(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalContainer = styled.div`
  width: 1010px;
  max-height: 90vh;
  min-height: 90vh;
  background: #fff;
  position: relative;
  border-radius: 5px;
  overflow: scroll;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
`

const ModalHeader = styled.div`
  width: 950px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
  padding-bottom: 10px;

  th {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
  }
  tr {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
  }
  table {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    text-align: center;
    vertical-align: center;
    font-size: 14px;
  }
  h3 {
    font-weight: 500;
    font-size: 16px;
    color: #1766dc;
  }
`

const CloseButtonModal = styled.div`
  position: absolute;
  top: 2px;
  right: 5px;
  width: 20px;
  height: 20px;
  border: none;
  background: none;
  cursor: pointer;
  transition: 0.3s ease all;
  border-radius: 5px;
  color: #1766dc;
  &:hover {
    background: #f2f2f2;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`
export const Label = styled.label`
  width: 120px;
  height: 150px;
  position: relative;
  cursor: ${({ isView }) => (isView ? 'normal' : 'pointer')};
`

export const Photo = styled.img`
  object-fit: contain;
  max-width: 110px;
  position: absolute;
  inset: 0;
  margin: auto;`