
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
//import events from "./events";


import "react-big-calendar/lib/css/react-big-calendar.css";









export const Schedule = ({ setDateStart, setDateEnd, isView, events,  tableType, id, isHorizontal, show, hide, text }) => {
  //const [nuevoAccesorio, setNuevoaccesorio] = useState([])
  const [fillData, setfillData] = useState(false)


  useEffect(() => {
    if (!fillData) {
      setfillData(true)
    }
  }, [fillData])



  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-');
  }


  const dayFormat = (date, culture, localizer) => localizer.format(date, 'D MMMM YYYY', culture);
  moment.locale("es");
  const localizer = momentLocalizer(moment);
  const [eventsData, setEventsData] = useState(events);

  const handleSelect = ({ start, end }) => {
    end=end-1
    const confirm =window.confirm("se prestara el servicio" + text + ' del '+ formatDate(start)+ ' al '+ formatDate(end));
    if (confirm)
    var title=text
      setDateStart(formatDate(start))
    setDateEnd(formatDate(end))
    setEventsData([
      ...eventsData,
      {
        start,
        end,
        title,

      }
    ]);
    hide(!show)
  };

  return (
    <>
      {show && (
        <Overlay style={{ zIndex: 101 }} >
          <ModalContainer>
            <ModalHeader>
            </ModalHeader>
            <CloseButtonModal onClick={() => hide(!show)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-x-square"
                viewBox="0 0 16 16"
              >
                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </CloseButtonModal>
            {show&&(<Calendar
              views={[/*"day", "agenda", "work_week",*/ "month"]}
              selectable
              localizer={localizer}
              defaultDate={new Date()}
              defaultView="month"
              events={eventsData}
              style={{ height: "100vh" }}
              onSelectEvent={(event) => alert(event.title)}
              onSelectSlot={handleSelect}
              formats={
                dayFormat
              }
            />)}
            


          </ModalContainer>
        </Overlay>
      )}
    </>
  )
}




const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgb(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalContainer = styled.div`
  width: 1000px;
  height: 870px;
  min-height: 500px;
  background: #fff;
  position: relative;
  border-radius: 5px;
  overflow: scroll;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
`

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
  padding-bottom: 10px;
  th {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
  }
  tr {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
  }
  table {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    text-align: center;
    vertical-align: center;
    font-size: 14px;
  }
  h3 {
    font-weight: 500;
    font-size: 16px;
    color: #1766dc;
  }
`

const CloseButtonModal = styled.div`
  position: absolute;
  top: 2px;
  right: 5px;
  width: 20px;
  height: 20px;
  border: none;
  background: none;
  cursor: pointer;
  transition: 0.3s ease all;
  border-radius: 5px;
  color: #1766dc;
  &:hover {
    background: #f2f2f2;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`
