import { getFormats } from '../components/app/lib/formatos/request';
import SpinnerLoad from '../components/app/lib/spinnerLoad';
import { createContext,  useEffect, useState } from 'react';
import { Cities, Countries, CIIU } from '../components/constant/data';

export const ListContext = createContext()

export function ListContextProvider(props){
    const [listData, setListData] = useState()
    useEffect(() => {
    getData()
    }, [])
    
    const listValue = {listData, setListData}
    const getData= async()=>{
        let list={}
        let listRequest= await getFormats('ListContent')
        list=listRequest.data.list
           
        list={...list, cities:Cities.map( item => { 
            return { state_id: item.state_id , name : item.city +' ('+item.state+')', value: item.id_city}; 
          }) }       
        list={...list, countries:Countries.map( item => { 
            return { id: item.id , name : item.nombre , value: item.iso}; 
          })  }
        // listRequest = await getDataF("DM")
        // list={...list, multidata:listRequest.map( item => { 
        //   return { id: item.id  , name : item.names , value: item.Initials_PK, type:item.type}; 
        // }) }
        // listRequest = await getDataF("CCT")        
        // list={...list, CeCo:listRequest.map( item => { 
        //   return { id: item.IdFormat_PK, name : item.CeCo , value: item.CeCo, type:item.type, Lider:item.Lider, NumeroContrato: item.NumeroContrato, users:item.users, Status: item.Status}; 
        // }) }
        list={...list, CIIU:CIIU.map( item => { 
          return { id: item.id  , name :item.CIIU + ' - '+ item.CIIU_desc , value: item.CIIU, type:item.section}; 
        }) }
        
        setListData(list)
        return
    }

    return(
        <ListContext.Provider value={listValue}>
          <SpinnerLoad trigger={listData} />
            {props.children}
        </ListContext.Provider>
    )
}

