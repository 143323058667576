import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button, Col, Form, Row, FloatingLabel, ButtonGroup } from 'react-bootstrap'
import Moment from 'moment'
import { CreateFormat, getFormats, updateFormat } from '../../lib/formatos/request'
import { CreateMultiDataF } from '../../lib/request'
import { toast } from 'react-toastify'

export const NewItem = ({
  show,
  hide,
  restart,
  setRestart,
  isView,
  data = [],
  sessionUser,
  setData,
  IdFormat_PK = "En Creación",
  serviceForm,
  setServiceForm,
  list
}) => {
  //-------------------------Info Formato
  const formato = 'COM-FOR-SERVICIOS'
  const revision = 'N/A'
  const fechaRev = 'N/A'
  const nombreFormato = 'INGRESO DE SERVCIOS'
  const typef = 'st_multidata'

  //-------------------------Registros
  const [status, setStatus] = useState('')
  const [currentUser, setCurrentUser] = useState('')
  const [date, setDate] = useState('')
  const [equipos, setequipos] = useState('')
  const [personas, setpersonas] = useState('')

  useEffect(() => {
    if (!restart) {
      //clearStates()
      setStatus('solicitado')
      setRestart(!restart)
      if (show) getInfo()

    }

  }, [restart])


  const handleChange = async ({ target }) => {
    const { name, type } = target
    const value = type === 'checkbox' ? target.checked : target.value
    setData({ ...data, [name]: value })
  }

  const getInfo = async () => {
    const formatDate = Moment().format('YYYY-MM-DD')
    setDate(formatDate)
   
    return true
  }

  const clearStates = () => {
    setData('')
    setCurrentUser('')
    setDate('')
  }
  const onSubmit = async e => {
    e.preventDefault()
    

    var result = ''
    
    if(data.id==="copia" || data.id === undefined){
     
    
      data.id=null
    result = await CreateFormat(data, typef)
  }
    else{
      
    result = await updateFormat(data, typef)}
    
    
     if (result.data.status === 200){
      toast.success(result.data.msj)
     }
     else {
      toast.error(result.data.msj);
    } 




    clearStates()
    hide(!show)
    setRestart(!restart)
  }



  return (
    <>
      {show && (
        <Overlay style={{ zIndex: '1'}}>
          <ModalContainer>
            <ModalHeader>
              
            </ModalHeader>
            <CloseButtonModal onClick={() => hide(!show)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-x-square"
                viewBox="0 0 16 16"
              >
                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </CloseButtonModal>


            <Form onSubmit={onSubmit}>
         
              <Frame isView={isView}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    
                    <tr>
                      <td style={{ width: '30%' }}>
                        <label>ID:</label>
                      </td>
                      <td style={{ width: '70%' }}>
                        <input
                          type="text"
                          name="id"
                          defaultValue={data.id}
                          disabled
                        />
                      </td>
                      
                    </tr>
                    <tr>
                      <td   >
                        <label>Iniciales:</label>
                      </td>
                      <td colSpan={3}>
                        <input
                          name="Initials_PK"
                          value={data.Initials_PK}
                          onChange={handleChange}
                          required
                          disabled={isView ? true : false}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>Tipo:</label>
                      </td>
                      <td colSpan={3}>
                        <input
                          name="type"
                          
                          value={data.type}
                          onChange={handleChange}
                          required
                          disabled={isView ? true : false}
                        />
                        
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>Nombre:</label>
                      </td>
                      <td colSpan={3}>
                        <input
                          name="names"
                          value={data.names}
                          onChange={handleChange}
                          required
                          disabled={isView}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>Region:</label>
                      </td>
                      <td colSpan={3}>
                        <input
                          name="region"
                          value={data.region}
                          onChange={handleChange}
                          required
                          disabled={isView ? true : false}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td   >
                        <label>Id Pais:</label>
                      </td>
                      <td colSpan={3}>
                        <input
                          name="country_id"
                          value={data.country_id}
                          onChange={handleChange}
                          required
                          disabled={isView ? true : false}
                        />
                      </td>
                    </tr>
                    
                  </tbody>
                </table>
              </Frame>
             
             
              <Row>
                <Col></Col>
                <Col></Col>
                <Col></Col>
                <Col></Col>
                {!isView ? (
                  <>
                    <Col>
                      <Button
                        className="btn btn-danger"
                        onClick={() => {
                          clearStates()
                          hide(!show)
                        }}
                      >
                        Cancelar
                      </Button>
                    </Col>
                    <Col>
                      <Button type="submit" className="btn btn-success">
                        Guardar
                      </Button>
                    </Col>
                  </>
                ) : (
                  <Col>
                    <Button
                      className="btn btn-secundary"
                      onClick={() => {
                        clearStates()
                        hide(!show)
                      }}
                    >
                      Cerrar
                    </Button>
                  </Col>
                )}
              </Row>
            </Form>
          </ModalContainer>
        </Overlay> 
      )} 
    </> 
  )
}

export default NewItem

const Frame = styled.div`
  width: 100%;
  padding-bottom: 0px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: left;

  font-size: 12px;
  font-weight: 100;
  .photo {
    background: #ebf1de;
  }
  .rms {
    background: #44bb7a;
  }
  FloatingLabel {
    
    display: flex;
    font-size: 29px;
    font-weight: NORMAL;
    text-align: 'center';
    padding-left: 25px;
    opacity: 0.65;
    transform: scale(0.75) translateY(0rem) translateX(0.15rem);
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  }
  input {
    text-align: center;
    width: 100%;
    border-bottom: 1px solid black;
    border-color: #1a004a;
    background: ${props => (props.isView ? '#FFF' : '#cee1ed')};

    padding-left: 5px;
    margin-top: 5px;
  }
  textarea {
    text-align: justify;
    width: 100%;
    border-bottom: 1px solid black;
    border-color: #1a004a;
    background: ${props => (props.isView ? '#FFF' : '#cee1ed')};

    padding-left: 5px;
    margin-top: 5px;
  }

  input.edit {
    background: #cee1ed;
  }
  input.disable {
    background: #fff;
  }
  h2 {
    font-size: 14px;
    font-weight: bold;
    text-align: 'center';
  }

  th {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    text-align: left;
    vertical-align: center;
    padding-left: 5px;

    input {
      width: 100%;
    }
  }
  tr {
    border-width: 0px;
    border-color: #1a004a;
    border-style: solid;
  }
`

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgb(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalContainer = styled.div`
  width: 500px;
  height: 300px;
  min-height: 300px;
  background: #fff;
  position: relative;
  border-radius: 5px;
  overflow: scroll;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
`

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
  padding-bottom: 10px;

  th {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
  }
  tr {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
  }
  table {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    text-align: center;
    vertical-align: center;
    font-size: 14px;
  }
  h3 {
    font-weight: 500;
    font-size: 16px;
    color: #1766dc;
  }
`

const CloseButtonModal = styled.div`
  position: absolute;
  top: 2px;
  right: 5px;
  width: 20px;
  height: 20px;
border: no ne;
backgro u nd: none;
cursor :  pointer;
trans i tion: 0.3s ease all;
border- r adius: 5px;
color:   #1766dc;
&:hove r  {
  back g round: #f2f2f2;
}  
svg  { 
    width: 100%;
  height: 100%;
}
`
