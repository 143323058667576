import axios from 'axios'
import ApiURL from '../../../services/apirest'

export const getEmployeeF = async (type) => {
    const config = {
        headers: { 'x-access-token': localStorage.getItem('TOKEN') },
    }
    const res = await axios.get(ApiURL + 'employees/' + type + '/', config)
    return res
}

export const createEmployeeF = async (type, dataEmp) => {

    const config = {
        headers: { 'x-access-token': localStorage.getItem('TOKEN') }
    }
    const data = { data: dataEmp, type }
    const res = await axios.post(ApiURL + 'employees/', data, config)
    return res
}

export const createEmployeeFormat = async (type, dataEmp, wf) => {

    const config = {
        headers: { 'x-access-token': localStorage.getItem('TOKEN') }
    }
    const data = { format: type, data: dataEmp, wf: wf }
    const res = await axios.post(ApiURL + 'employees/format/', data, config)
    return res
}
export const updateEmployeeFormat = async (type, dataEmp, wf, idEmploye_FK, actionWf) => {

    const config = {
        headers: { 'x-access-token': localStorage.getItem('TOKEN') }
    }
    const data = { format: type, data: dataEmp, wf: wf, id: idEmploye_FK, action:actionWf }
    const res = await axios.put(ApiURL + 'employees/format/', data, config)
    return res
}
export const updateEmployeeByIdF = async (type, dataEmp) => {
    const config = {
        headers: { 'x-access-token': localStorage.getItem('TOKEN') }
    }
    const data = { data: dataEmp, type }
    const res = await axios.update(ApiURL + 'employees/', data, config)
    return res
}

export const getEmployeeByIdF = async (type, id) => {
    const config = {
        headers: { 'x-access-token': localStorage.getItem('TOKEN') }
    }

    const res = await axios.get(ApiURL + 'employees/' + type + "/" + id, config)
    return res
}


export const undeletEmployeeByIdF = async (type, id) => {
    const config = {
        headers: { 'x-access-token': localStorage.getItem('TOKEN') },
    }
    const res = await axios.undelet(ApiURL + 'employees/' + type + "/" + id, config)
    return res
}

export const deleteEmployeeByIdF = async (type, id) => {
    const config = {
        headers: { 'x-access-token': localStorage.getItem('TOKEN') },
        data: {
            IdEmployeePK: id
        }
    }
    const res = await axios.delete(ApiURL + 'employees/' + type + "/" + id, config)
    return res
}

export const eraseEmployeeByIdF = async (type, id) => {
    const config = {
        headers: { 'x-access-token': localStorage.getItem('TOKEN') }
    }
    const res = await axios.delete(ApiURL + 'employees/' + type + "/" + id, config)
    return res
}









































export const EditingUserF = async (IdUser_PK, UserName, UserLastName, IdCompany_FK, UserEmail, avatar) => {
    const config = {
        headers: { 'x-access-token': localStorage.getItem('TOKEN') },
    }
    const data = {
        IdUser_PK: IdUser_PK,
        UserName: UserName,
        UserLastName: UserLastName,
        IdCompany_FK: IdCompany_FK,
        UserEmail: UserEmail,
        avatar: avatar,
    }
    const res = await axios.put(ApiURL + 'user', data, config)
    return res
}


export const setPermissionF = async (Encryp, IdEmployee_PK) => {
    const config = {
        headers: { 'x-access-token': localStorage.getItem('TOKEN') },
    }
    const data = {
        userPermission: Encryp,
        IdEmployee: IdEmployee_PK
    }
    const res = await axios.put(ApiURL + 'employee/', data, config)
    return res
}

export const CreateCompanieF = async (IdEmpresa_PK, Razon_Social, Tipo_Compania, url, Pais, Industria, FechaCreacion, Legal, Actividad_Economica, Persona_Contacto, Telefono_Contacto, Representante_Legal, DNI_Representante) => {
    const config = {
        headers: { 'x-access-token': localStorage.getItem('TOKEN') },
    }
    const data = {
        type: 'CC',
        data: {
            IdCompany_PK: IdEmpresa_PK,
            SocialName: Razon_Social,
            IdCompanyType_FK: Tipo_Compania,
            url: url,
            Conuntry: Pais,
            Industry: Industria,
            BornDate: FechaCreacion,
            LegalForm_FK: Legal,
            CIIU_FK: Actividad_Economica,
            ContactPerson: Persona_Contacto,
            PhoneContact: Telefono_Contacto,
            LegalRepresentative: Representante_Legal,
            DNILegalRep: DNI_Representante,
        }
    }
    const res = await axios.post(ApiURL + 'companies', data, config)
    return res
}

export const EditingCompanieF = async (IdEmpresa_PK, Razon_Social, Tipo_Compania, url, Pais, Industria, FechaCreacion, Legal, Actividad_Economica, Persona_Contacto, Telefono_Contacto, Representante_Legal, DNI_Representante) => {
    const config = {
        headers: { 'x-access-token': localStorage.getItem('TOKEN') },
    }
    const data = {
        type: 'CC',
        data: {
            IdCompany_PK: IdEmpresa_PK,
            SocialName: Razon_Social,
            IdCompanyType_FK: Tipo_Compania,
            url: url,
            Conuntry: Pais,
            Industry: Industria,
            BornDate: FechaCreacion,
            LegalForm_FK: Legal,
            CIIU_FK: Actividad_Economica,
            ContactPerson: Persona_Contacto,
            PhoneContact: Telefono_Contacto,
            LegalRepresentative: Representante_Legal,
            DNILegalRep: DNI_Representante,
        }
    }
    const res = await axios.put(ApiURL + 'companies', data, config)
    return res
}

export const deleteCompanieByIdF = async (type, id) => {
    const config = {
        headers: { 'x-access-token': localStorage.getItem('TOKEN') },
    }
    const res = await axios.delete(ApiURL + 'companies/' + type + "/" + id, config)
    return res
}