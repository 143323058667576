import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button, Col, Form, Row } from 'react-bootstrap'
import Moment from 'moment'
import { CreateFormat, updateFormat, newWorkflow, updateWorkflow, getFormats } from './request'
import { toast } from 'react-toastify'
import image from '../../../../assets/img/Logo_b-st.png'
import { CotTable } from '../../Solicitudes/Solicitudes/CotTable'

export const COMFORRequisiciones = ({
  show,
  hide,
  restart,
  setRestart,
  isView,
  contractSelected,
  data = [],
  dataUser,
  setData,
  IdFormat_PK = "en creacion",
  workflow = [],
  addEject = false,
  addQuotation = false,
  list

}) => {
  //-------------------------Info Formato
  const formato = 'COM-FOR-Requisiciones'
  const revision = 'N/A'
  const fechaRev = 'N/A'
  const nombreFormato = 'REQUISICION'
  const typef = 'requisiciones'

  //-------------------------Registros
  const [date, setDate] = useState('')
  const [CotizaTable, setCotizaTable] = useState()
  const [cotizar, setcotizar] = useState(true)
  const [aprobar, setAprobar] = useState(false)

  useEffect(() => {
    getInfo()




  }, [restart, CotizaTable])

  const handleChange = async (e) => {

    const { target } = e
    const { name, type } = target
    const value = type === 'checkbox' ? target.checked : target.value

    if (name === 'ClienteAceptaCostos') {
      if (value === 'No') {
        setcotizar(false)
      } else {
        setcotizar(true)
      }
    }
    setData({ ...data, [name]: value })

  }

  const getInfo = async () => {
    const formatDate = Moment().format('YYYY-MM-DD')
    setDate(formatDate)




    setCotizaTable(data.CotizaTable)
    if (workflow === undefined && data.Status !== "creacion")
      workflow["IdFormat_FK"] = data.IdFormat_FK

    if (data.Status === "cotizado" && data.IdUser_FK === localStorage.getItem('IdUser_PK')) {
      setAprobar(true)
    }
    return true
  }

  const clearStates = () => {
    addQuotation = false
    setCotizaTable([])
    data = []
    setData([])
    setDate('')
    hide(!show)
    setRestart(!restart)
  }
  const onSubmit = async e => {
    let createWF = true
    let updateWF = true
    e.preventDefault()
    setData({ ...data, CotizaTable: (CotizaTable) })

    const boton = e.nativeEvent.submitter.name


    if (data.Status === "creacion") {
      data = {
        ...data,
        IdUser_FK: data.SolicitadoPor.IdUser_PK,
        IdCompany_FK: data.Empresa.IdCompany_FK,
        Empresa: JSON.stringify(data.Empresa),
        IdContract_FK: contractSelected.IdFormat_PK,
        SolicitadoPor: JSON.stringify(data.SolicitadoPor),
        IdLeader_FK: contractSelected.Lider,
        FechaSolicitud: date, Status: cotizar ? "cotizar" : "ejecutar"
      }
      let IdEmployee = await getFormats("GTHFOR086")
      IdEmployee = JSON.stringify(IdEmployee.data.data.filter(item => item.Area.toLowerCase().includes("proyectos")).map(value => value.idEmployeePK))
      const result = await CreateFormat(data, typef)
      if (result.data.status === 200) {
        const workflow = {
          TaskDescription: "Solicitud para " + cotizar ? "cotizar servicio" : "ejecutar servicio",
          action: cotizar ? "cotizar" : "ejecutar",
          Status: "pendiente",
          Format: typef,
          dateCreation: date,
          IdEmployee_created: dataUser.user.IdUser_PK,
          documents: "Solicitud de orden de servicio",
          IdFormat_FK: result.data.data.insertId,
          IdEmployee_FK: cotizar ? IdEmployee : contractSelected.Lider
        }
        const resWorkflow = await newWorkflow(workflow)
        if (resWorkflow.data.status === 200) {
          toast.success(resWorkflow.data.msj)
          clearStates()
          return
        }
        else {
          toast.error(resWorkflow.data.msj);
          return
        }
      } else {
        toast.error(result.data.msj);
        return
      }
    } else {
      let dataSave = {
        ...data,
        Status: "cotizado",
        CotizaTable: JSON.stringify(CotizaTable),
        Empresa: JSON.stringify(data.Empresa),
        SolicitadoPor: JSON.stringify(data.SolicitadoPor)
      }
      let workflowSave = {
        Status: "pendiente",
        Format: typef,
        dateCreation: date,
        IdEmployee_created: localStorage.getItem('IdUser_PK'),
        documents: "Solicitud de orden de servicio",
        IdFormat_FK: workflow.IdFormat_FK
      }
      if (data.Status === "cotizar") {
        dataSave = { ...dataSave, Status: "cotizado" }
        workflowSave = { ...workflowSave, TaskDescription: "Solicitud de aprobación cotizacion", action: "aprobar", IdEmployee_FK: data.SolicitadoPor.IdUser_PK }
      }
      else if (data.Status === "cotizado") {
        if (addQuotation) {
          createWF = false
          updateWF = false
        } else {
          if (boton === "aprobar") {
            dataSave = { ...dataSave, Status: "ejecutar" }
            workflowSave = { ...workflowSave, TaskDescription: "Solicitud de ejecución de servicio", action: "ejecutar", IdEmployee_FK: data.IdLeader_FK }

          } else if (boton === "rechazar") {
            dataSave = { ...dataSave, Status: "rechazado" }
            createWF = false
          } else
            return

        }
      } else if (data.Status === "ejecutar" && addEject) {
        dataSave = { ...dataSave, Status: "ejecutando" }
        createWF = false

      } else {
        clearStates()
        return
      }
      const result = await updateFormat(dataSave, typef)
      if (result.data.status === 200) {
        let resWorkflow = { data: { status: 200, msg: "documento actualizado con éxito" } }
        if (createWF)
          resWorkflow = await newWorkflow(workflowSave)
        
        if (resWorkflow.data.status === 200) {
          workflow = { ...workflow, Status: "Finalizado", dateFinish: date }
          if (updateWF)
            resWorkflow = await updateWorkflow(workflow)
         
          toast.success(resWorkflow.data.msj)
          clearStates()
          return
        }
        else {
          toast.error(resWorkflow.data.msj);
          return
        }
      }
      else {
        toast.error(result.data.msj);
        return
      }
    }
  }
  return (
    <>
      {show && (
        <Overlay style={{ zIndex: '1' }}>
          <ModalContainer>
            <ModalHeader>
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                  <th style={{ width: '20%', fontSize:'10px', verticalAlign: 'bottom' }} rowSpan={3}>
                      <img
                        className="format_logo"
                        src={image}
                        style={{ paddingBottom: '7px', width: '75%', minWidth: "40px" }}
                        alt="Logo RMS"
                      /><br/>
                      NIT 830.507.705-2
                    </th>
                    <th style={{ width: '55%', height: '38px' }}>RELIABILITY MAINTENANCE SERVICES S.A.S</th>
                    <th style={{ width: '25%' }}>{formato}</th>
                  </tr>
                  <tr>
                    <th rowSpan={2}>{nombreFormato}</th>
                    <th style={{ fontSize: '10px' }}>Revisión: {revision}</th>
                  </tr>
                  <tr>
                    <th style={{ fontSize: '10px' }}>Fecha de revisión: {fechaRev}</th>
                  </tr>
                </tbody>
              </table>
            </ModalHeader>
            <CloseButtonModal onClick={() => {
              clearStates()
              hide(!show)
            }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-x-square"
                viewBox="0 0 16 16"
              >
                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </CloseButtonModal>
            <table>
              <tbody>
                <tr>
                  <th style={{ width: '28%', textAlign: 'center' }}></th>
                  <th style={{ width: '15%', textAlign: 'center' }}>
                    <label>Requisicion No.</label>
                  </th>
                  <td style={{ width: '25%', background: '#FFF' }}>
                    <label>{IdFormat_PK}</label>
                  </td>
                </tr>
              </tbody>
            </table>
            <Form onSubmit={onSubmit}>
              <Frame isView={isView}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <th className="rms" colSpan={8} style={{ width: '100%' }}>
                        <h2 className="text-center">SOLICITUD</h2>
                      </th>
                    </tr>
                    <tr>
                      <td style={{ width: '12%' }}>
                        <label>Empresa:</label>
                      </td>
                      <td style={{ width: '22%' }} >
                        <input
                          className='disable'
                          name="Empresa"
                          value={data.Empresa.SocialName}
                          required
                          disabled={true}
                        />
                      </td>
                      <td colSpan={2} style={{ width: '14%' }}><label>Fecha de Solicitud:</label></td>
                      <td style={{ width: '14%' }}>
                        <input
                          className='disable'
                          name="FechaSolicitud"
                          value={data.FechaSolicitud ?? date}
                          disabled={true}
                        />
                      </td>
                      <td style={{ width: '14%' }}><label>Fecha de Necesidad:</label></td>
                      <td style={{ width: '14%' }}>
                        <input
                          onChange={handleChange}
                          type="date"
                          name="FechaNecesidad"
                          value={data.FechaNecesidad}
                          disabled={isView ? true : false}
                          required={true}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td >
                        <label>Solicitado por:</label>
                      </td>
                      <td >
                        <input
                          className='disable'
                          name="Representante"
                          value={data.SolicitadoPor.UserName + ' ' + data.SolicitadoPor.UserLastName}


                          disabled={true}
                        />
                      </td>
                      <td style={{ width: '6%' }}>
                        <label>Cargo:</label>
                      </td>
                      <td colSpan={2} >
                        <input
                          className='disable'
                          name="Cargo"
                          value={data.SolicitadoPor.Position}

                          required
                          disabled={true}
                        />
                      </td>
                      <td ><label>Número telefónico:</label></td>

                      <td  >
                        <input
                          className='disable'
                          name="Telefono"
                          value={data.SolicitadoPor.phoneNumber}

                          required
                          disabled={true}
                        />
                      </td>
                    </tr>


                  </tbody>
                </table>
              </Frame>
              <Frame isView={isView}>
                <table style={{ width: '100%' }}>
                  <thead>

                    <tr>
                      <th colSpan={6} className="rms" style={{ width: '20%' }}>
                        <h2 className="text-center">OBJETIVOS DEL SERVICIO</h2>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td >
                        <label>Objetivo:</label>
                      </td>
                      <td >
                        <textarea
                          name="Objetivo"
                          value={data.Objetivo}
                          onChange={handleChange}
                          required disabled={isView ? true : false}
                        />

                      </td>
                      <td><label>Alcance:</label></td>
                      <td style={{ width: '25%' }}>
                        <textarea
                          name="Alcance"
                          value={data.Alcance}
                          onChange={handleChange}
                          required
                          disabled={isView ? true : false}
                        />
                      </td>

                    </tr>
                    <tr>
                      <td style={{ width: '20%' }}>
                        <label>Entregables esperados:</label>
                      </td>
                      <td colSpan={6}>
                        <textarea
                          style={{ width: '100%' }}
                          name="EntregablesEsperados"
                          value={data.EntregablesEsperados}
                          onChange={handleChange}
                          required
                          disabled={isView ? true : false}
                        />
                      </td>
                    </tr>


                  </tbody>
                </table>
              </Frame>
              <Frame isView={isView}>
                <table style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th colSpan={8} className="rms" style={{ width: '20%' }}>
                        <h2 className="text-center">DESCRIPCION DEL SERVICIO REQUERIDO</h2>
                      </th>
                    </tr>
                  </thead>
                  <tbody>


                    <tr>
                      <td >
                        <label>Tipo de necesidad:</label>
                      </td>
                      <td >
                        <textarea

                          name="TipoNecesidad"
                          value={data.TipoNecesidad}
                          onChange={handleChange}
                          required
                          disabled={isView ? true : false}
                        />

                      </td>
                      <td><label>Lugar del servicio:</label></td>
                      <td style={{ width: '20%' }}>

                        <>
                          <select
                            name="LugarServicio"
                            value={data.LugarServicio}
                            onChange={handleChange}
                            required
                            disabled={isView ? true : false}
                          >
                            {/* {ciudades.map((article, id) => {
                              return (
                                <option key={id} value={article.id}>{article.city} </option>
                              );
                            })} */}

                          </select>
                        </>


                      </td>
                      <td style={{ width: '15%' }}><label>Cantidad de equipos:</label></td>
                      <td style={{ width: '20%' }}>
                        <input
                          name="Cantidad"
                          value={data.Cantidad}
                          onChange={handleChange}
                          required
                          disabled={isView ? true : false}
                        />
                      </td>
                    </tr>




                  </tbody>
                </table>
              </Frame>
              <Frame isView={isView}>
                <table style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th colSpan={8} className="rms" style={{ width: '20%' }}>
                        <h2 className="text-center">CARACTERISTICAS DE LOS EQUIPOS</h2>
                      </th>
                    </tr>
                  </thead>
                  <tbody>

                    <tr>
                      <td style={{ width: '30%' }}><label>Referencia</label></td>
                      <td style={{ width: '30%' }}><label>Marca:</label></td>
                      <td style={{ width: '30%' }}><label>Potencia:</label></td>


                    </tr>
                    <tr>
                      <td><input
                        name="Referencia"
                        value={data.Referencia}
                        onChange={handleChange}
                        required disabled={false}>
                      </input>
                      </td>
                      <td><input
                        name="Marca"
                        value={data.Marca}
                        onChange={handleChange}
                        required disabled={false}>
                      </input>
                      </td>
                      <td><input
                        name="Potencia"
                        value={data.Potencia}
                        onChange={handleChange}
                        required disabled={false}>
                      </input>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Frame>
              <Frame isView={isView}>
                <table style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th colSpan={8} className="rms" style={{ width: '20%' }}>
                        <h2 className="text-center">ACUERDOS</h2>
                      </th>
                    </tr>
                  </thead>
                  <tbody>


                    <tr >
                      <td colSpan={3} ><label>¿El cliente está de acuerdo en que se haga una propuesta a todo costo?</label></td>
                      <td >

                        <label style={{ width: '30px', right: '1px' }}>
                          <input
                            style={{ width: '12px' }}
                            type={'radio'}
                            name={'ClienteAceptaCostos'}
                            value={'Si'}
                            checked={data['ClienteAceptaCostos'] === 'Si'}
                            onChange={handleChange}
                            required
                            disabled={isView ? true : false}
                          />
                          Si
                        </label>

                      </td>

                      <td >
                        <label style={{ width: '30px', right: '1px' }}>
                          <input
                            style={{ width: '20px' }}
                            type={'radio'}
                            name={'ClienteAceptaCostos'}
                            value={'No'}
                            checked={data['ClienteAceptaCostos'] === 'No'}
                            onChange={handleChange}
                            required
                            disabled={isView ? true : false}
                          />
                          No
                        </label>
                      </td>
                    </tr>
                    <tr >
                      <td colSpan={3} ><label>¿El cliente asume la logística (Transporte,  Alojamiento, alimentación) del personal de RMS durante el servicio?</label></td>
                      <td >

                        <label style={{ width: '20px', right: '1px' }}>
                          <input
                            style={{ width: '20px' }}
                            type={'radio'}
                            name={'ClientePagaLogistica'}
                            value={'Si'}
                            checked={data['ClientePagaLogistica'] === 'Si'}
                            onChange={handleChange}
                            required
                            disabled={isView ? true : false}
                          />
                          Si
                        </label>

                      </td>

                      <td >
                        <label style={{ width: '20px', right: '1px' }}>
                          <input
                            style={{ width: '20px' }}
                            type={'radio'}
                            name={'ClientePagaLogistica'}
                            value={'No'}
                            checked={data['ClientePagaLogistica'] === 'No'}
                            onChange={handleChange}
                            required
                            disabled={isView ? true : false}
                          />
                          No
                        </label>
                      </td>
                    </tr>

                  </tbody>
                </table>
              </Frame>

              {/* ------------------------------------------------------Cotizaciones------------------------------------------------------------------------- */}
              {cotizar && (
                <Frame isView={!addQuotation}>
                  <table style={{ width: '100%' }}>
                    <thead >
                      <tr>
                        <th colSpan={7} className="rms" style={{ width: '20%' }} >
                          <h2 className="text-center">COTIZACION</h2>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr  >
                        <td colSpan={9} style={{ width: '23%' }}>
                          <CotTable data={CotizaTable} setData={setCotizaTable}
                            isView={!addQuotation} tableType={'tableCotizacion'} list={list} />
                        </td>
                      </tr>
                      <tr>
                        <th colSpan={7}>
                          <label>Observaciones</label>
                        </th>
                      </tr>
                      <tr><td colSpan={7}>
                        <textarea name='observacioCoti' value={data.observacioCoti} onChange={handleChange} required disabled={addQuotation ? false : true}></textarea>
                      </td>
                      </tr>
                    </tbody>
                  </table>
                </Frame>
              )}
              <Row>
                <Col></Col>
                <Col></Col>
                <Col></Col>
                <Col></Col>
                <Col></Col>
                {(!isView || addQuotation) ? (
                  <>
                    <Col>
                      <Button
                        className="btn btn-danger"
                        onClick={() => {
                          clearStates()
                          hide(!show)
                        }}
                      >
                        Cancelar
                      </Button>
                    </Col>

                    <Col>
                      <Button type="submit" className="btn btn-success" disabled={false} >
                        Guardar
                      </Button>
                    </Col>

                  </>
                ) : (
                  <>{aprobar && (<>

                    <Col>
                      <Button type="submit" name='aprobar' className="btn btn-success" disabled={false} >
                        Aprobar
                      </Button>
                    </Col>
                    <Col>
                      <Button type="submit" name='rechazar' className="btn btn-danger" disabled={false} >
                        Rechazar
                      </Button>
                    </Col>
                  </>)}
                    <Col>
                      <Button
                        className="btn btn-secundary"
                        onClick={() => {
                          clearStates()
                          hide(!show)
                        }}
                      >
                        Cerrar
                      </Button>
                    </Col>

                  </>
                )}
              </Row>

            </Form>
          </ModalContainer>
        </Overlay>
      )}

    </>
  )
}

export default COMFORRequisiciones

const Frame = styled.div`
          width: 100%;
          padding-bottom: 0px;
          margin-bottom: 10px;
          align-items: center;
          justify-content: left;
          border-width: 2px;
          border-color: #1a004a;
          border-style: solid;
          font-size: 12px;
          font-weight: 100;
          .photo{
            background:  #ebf1de
  }
          .rms{
            background:  #F2F2F2
  }
  label {
          padding-left: 5px;
          display: flex;
          font-size: 12px;
          font-weight: bold;
          text-align: 'center';
    
  }
  input {
          text-align: center;
          width: 100%;
          border-bottom: 1px solid black;
          border-color: #1a004a;
          background: ${props => (props.isView ? '#FFF' : '#cee1ed')};
          padding-left: 5px;
          /* margin-top: 5px; */
  }
  textarea {
    text-align: justify;
    width: 100%;
    border-bottom: 1px solid black;
    border-color: #1a004a;
    background: ${props => (props.isView ? '#FFF' : '#cee1ed')};
    resize: none;
    padding-left: 5px;
    
  } 

  input.edit {
    background: #cee1ed;
  }
  input.disable {
    background: #fff;
  }
  h2 {
    font-size: 14px;
    font-weight: bold;
    text-align: 'center';
  }

  th {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    text-align: left;
    vertical-align: center;
    padding-left: 5px;

    input {
      width: 100%;
    }
  }
  tr {
    border-width: 0px;
    border-color: #1a004a;
    border-style: solid;
  }
    
  td {
          border-width: 1px;
          border-color: #1a004a;
          border-style: solid;
  }
`

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgb(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalContainer = styled.div`
  width: 1000px;
  max-height: 90vh;
  min-height: 500px;
  background: #fff;
  position: relative;
  border-radius: 5px;
  overflow: scroll;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
`

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
  padding-bottom: 10px;

  th {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
  }
  tr {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
  }
  table {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    text-align: center;
    vertical-align: center;
    font-size: 14px;
  }
  h3 {
    font-weight: 500;
    font-size: 16px;
    color: #1766dc;
  }
`

const CloseButtonModal = styled.div`
  position: absolute;
  top: 2px;
  right: 5px;
  width: 20px;
  height: 20px;
  border: none;
  background: none;
  cursor: pointer;
  transition: 0.3s ease all;
  border-radius: 5px;
  color: #1766dc;
  &:hover {
    background: #f2f2f2;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`
