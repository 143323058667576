import React, { useState, useEffect } from 'react'
import { sessionActive, decodeUserPermission } from '../lib/functions'
import { getFullData } from "../lib/request";
import { Col, Row, Card, Button } from 'react-bootstrap'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Equipment from './Equipment/Equipment';
import Assignment from './Assigment/Assignment';


export default function Logistic() {
    const [admin, SetAdmin ]= useState(false)
    const [put, setPut] = useState(false);
    const [_delete, set_delete] = useState(false);
    const [post, setPost] = useState(false);
    const [sessionUser, SetSessionUser] = useState(null)
    
    useEffect(() => {
        sessionActive();
        Permissions();
    }, [])
    const Permissions =async () => {
        //Obtener Permisos de Usuario
        const resPermission = decodeUserPermission(localStorage.getItem("userPermission"), localStorage.getItem("IdUser_PK"))
        if (resPermission.superAdmin.access) {
            setPut(true); set_delete(true); setPost(true);
            SetAdmin(true)
        } else {
            setPut(resPermission.logistic.PUT);
            set_delete(resPermission.logistic.DELETE);
            setPost(resPermission.logistic.POST);
        }
        //Obtener perfil de usuario (contrato)
        var res = await getFullData("employee", "IdEmployee_FK",localStorage.getItem("IdUser_PK"))
            SetSessionUser(res.data.data[0])
           
        
    }

    return (
        <div className="page-content py-3">
            <Row >
                <Col>
                    <Card style={{minHeigth: "900px"}}>
                        <Tabs >
                            <Card.Header className="card-tabs text-center  align-items-center">
                                <TabList>
                                    <h5 className='tabs-tittle'>Logistica</h5>
                                    <Tab>
                                        <p>Equipos</p>
                                    </Tab>
                                    <Tab>
                                        <p>Asignación</p>
                                    </Tab>
                                   
                                </TabList>
                            </Card.Header>
                            <Card.Body>

                                <TabPanel>
                                <Equipment put={put} delete={_delete} post={post} sessionUser={sessionUser} newBotton={true} uniqueUser={true}></Equipment>
                                    
                                </TabPanel>
                                <TabPanel>
                                <Assignment put={put} delete={_delete} post={post} sessionUser={sessionUser} newBotton={true} uniqueUser={true}></Assignment>
                                </TabPanel>

                               
                                
                            </Card.Body>
                        </Tabs>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}
