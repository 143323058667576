import { Accordion } from 'react-bootstrap'

import { useEffect, useState } from 'react'

import Vacation from './vacation/Vacation'
import Notice from './notice/Notice'
import ContractAdd from './contractAdd/ContractAdd'
import Permission from './permission/Permission'


const EmployeesTab = props => {
  const [restartView, setRestartView] = useState(true)
  

  useEffect (() => {
    
  }, [restartView])

  return (
    <Accordion >
      <Accordion.Item eventKey="0">
        <Accordion.Header>Permisos</Accordion.Header>
        <Accordion.Body>
          <Permission
            put={props.put}
            delete={props.delete}
            post={props.post}
            sessionUser={props.sessionUser}
            newBotton={false}
            dense={true}
            uniqueUser={false}
            route={'/humantalent'}
            isTH={true}
          />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Vacacionies</Accordion.Header>
        <Accordion.Body>
          <Vacation
            put={props.put}
            delete={props.delete}
            post={props.post}
            sessionUser={props.sessionUser}
            dense={true}
            newBotton={false}
            uniqueUser={false}
            route={'/humantalent'}
            isTH={true}
          />
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2">
        <Accordion.Header>Preaviso</Accordion.Header>
        <Accordion.Body>
          <Notice
            put={props.put}
            delete={props.delete}
            post={props.post}
            dense={true}
            sessionUser={props.sessionUser}
            setRestartView={setRestartView}
            restartView ={restartView}
            newBotton={false} />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>Otro Si</Accordion.Header>
        <Accordion.Body>
          <ContractAdd
            put={props.put}
            delete={props.delete}
            post={props.post}
            dense={true}
            sessionUser={props.sessionUser}
            setRestartView={setRestartView}
            restartView ={restartView}
            isTH={true}
          />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}
export default EmployeesTab
