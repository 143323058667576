import React, { useRef ,useState, useEffect} from 'react'
import Popup from 'reactjs-popup'
import styled from 'styled-components'
import Moment from 'moment';
import CryptoJS from 'crypto-js'
import 'reactjs-popup/dist/index.css'
import { Col, Row, Button } from 'react-bootstrap'
import SignaturePad from 'react-signature-canvas'
import { updateSignUser } from './request';
import { toast } from 'react-toastify';

/* ----------------------- Descripcion de Props -------------------------------------------------------
        doc: Nombre del formato que se esta firmando (nombre del componente ej. GTHFOR070)
        user: documento de usuario que firma el documento (IdUser_PK)
        sing: registro de firma (useState)         =>{nombreTrabajador, apellidoTrabajador,idEmployee, image, crypt, date }
        setSing: funcion para modificar registro de firma (useState)
        enable: con true habilita el boton para realizar firma 
---------------------------------------------------------------------------------------------------------*/

function Signer({ doc, user, sign, setSign, enable, width='80%', save=false }) {
  const [date, setDate] = useState('')
  const [signStoraged, setSignStoraged] = useState(localStorage.getItem('sign'))
  
  useEffect(() => {
    const formatDate = Moment().format('YYYY-MM-DD')
    setDate(formatDate)

  }, [doc])
  const sigCanvas = useRef({})
  const limpiar = () => sigCanvas.current.clear()

  const guardar = async () => {
    var today = new Date(),
      date = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear()
   if(  sigCanvas.current.isEmpty()){
    toast.error('debe realizar la firma sobre el pad blanco')

    return 
  }
// Convertimos el canvas recortado a una imagen JPEG con menor calidad (0.3)
const imageDataURL = sigCanvas.current.toDataURL("image/jpeg", 0.3);

    setSign({
      ...sign,
      fecha: date,
      image:imageDataURL, // sigCanvas.current.toDataURL('image.png'),
      crypt: CryptoJS.AES.encrypt(doc + ' ' + user + ' ' + date, doc).toString(),
    })
    if(save){
    localStorage.setItem("sign",  imageDataURL);
    const resEditing = await updateSignUser({IdUser_PK:user, sign:imageDataURL})}
    
  }
  const firmar = () => {
    var today = new Date(),
      date = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear()
    setSign({
      ...sign,
      fecha: date,
      image: signStoraged,
      crypt: CryptoJS.AES.encrypt(doc + ' ' + user + ' ' + date, doc).toString(),
    })
  }
  return (
    <CenteredContainer>
    
      {sign.crypt ? (
        <Popup
          style={{width: "1000px", border: "0" }}
          modal
          trigger={
            <Boton  style={{ border: "0"  }}  >
              <img src={sign.image} alt="" style={{ display: 'block' ,border: "0" , width: width, height: '80%',alignSelf:"center",textAlign:"center" }} />{' '}
            </Boton>
          }
        >
          {close => (
            <>
              <CloseButtonModal onClick={close}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-x-square"
                  viewBox="0 0 16 16"
                >
                  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </CloseButtonModal>
              <img src={sign.image} alt="firma" style={{ display: 'block',border: "0" , margin: '0 auto', width: '80%', height: '80%' }} />
              <p>Encode: {sign.crypt !== undefined ? sign.crypt : 'no hay datos'}</p>
          
              <p>
                Decode:
                {sign.crypt !== undefined ? CryptoJS.AES.decrypt(sign.crypt, doc).toString(CryptoJS.enc.Utf8) : 'no hay datos'}
              </p>
            </>
          )}
        </Popup>
      ) : (
        enable && (
          <Popup
            
            modal
            trigger={<Button className="btn btn-primary btn-lg btn-block">Firma aquí </Button>}
            closeOnDocumentClick={false}
          >
            {close => (
              <div  style={{ minWidth:"610px", maxWidth: "610px"}}>
               <Line /> {/* Agrega la línea gris encima del SignaturePad */}
                
                <SignaturePad
                  ref={sigCanvas}
                  canvasProps={{  width: 600, height: 225, style: { border: '0' } }}
                  penColor="blue"
                  backgroundColor="white" 

                />
                 <Row className="col text-center">
                  <Col style={{ alignItems: 'center' }}>
                    <Button onClick={close}>Cerrar</Button>
                  </Col>
                  <Col>
                    {' '}
                    <Button className="btn btn-warning" onClick={limpiar}>
                      Limpiar
                    </Button>
                  </Col>
                  <Col> {' '}
                  {signStoraged.includes('data:image') &&(
                    <Button className="btn btn-dark" onClick={firmar}>
                      Firmar
                    </Button>)}
                  </Col>
                  <Col>
                    {' '}
                    <Button className="btn btn-success" onClick={guardar}>
                      Guardar
                    </Button>
                  </Col>
                </Row>
             
              </div>
            )}
          </Popup>
        )
      )}
    </CenteredContainer>
  )
}

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Boton = styled.div`
display: flex;
justify-content: center;
align-items: center;
border: 1px solid black;
background: #fff;
width: 100%;
height: 100%;
`
const Line = styled.div`
  position: absolute;
  width: calc(100% - 40px); /* Ancho del SignaturePad con padding lateral de 20px */
  height: 2px;
  background-color: #ccc;
  margin-top: 180px; /* Ajusta esta distancia para que la línea quede encima del SignaturePad */
  margin-left: 20px; /* Mismo valor que el padding lateral del SignaturePad */
`;

const CloseButtonModal = styled.div`
  position: absolute;
  top: 2px;
  right: 5px;
  width: 20px;
  height: 20px;
  border: none;
  background: none;
  cursor: pointer;
  transition: 0.3s ease all;
  border-radius: 5px;
  color: #1766dc;
  &:hover {
    background: #f2f2f2;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`

export default Signer
