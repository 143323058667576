import React, { useEffect, useState  } from 'react'
import styled from 'styled-components'
import { Button, Col, Form, Row } from 'react-bootstrap'
import Moment from 'moment'
import { toast } from 'react-toastify'
import { CreateFormat, updateFormat} from '../../../lib/formatos/request'


export const GTHFOR92 = ({
  show,
  hide,
  restart,
  setRestart,
  isView,
  data = [],
   setData,
  IdFormat_PK = "En Creación",

  list
}) => {
  //-------------------------Info Formato
  const formato = 'GTH-FOR-92'
  const revision = 'N/A'
  const fechaRev = 'N/A'
  const nombreFormato = 'SOLICITUD DE PERMISO'
  const typef = 'GTHFOR92'

  //-------------------------Registros
  const [currentUser, setCurrentUser] = useState('')
  const [date, setDate] = useState('')
  const [items, setItems] = useState([])
  const [users, setUsers] = useState([])
  const [empresa, setEmpresa] = useState({})



  useEffect(() => {
    
    getInfo()

    if (!restart) {
      
      
      setRestart(!restart)
      if (show) getInfo()
      
    }
    
    
  }, [restart])

  const selectedItem = (e) => {
    setEmpresa(e[0])
    return



  }
  const handleChange = async ({ target }) => {
    const { name, type } = target
    const value = type === 'checkbox' ? target.checked : target.value
    setData({ ...data, [name]: value })

  }

  const getInfo = async () => {
    const formatDate = Moment().format('YYYY-MM-DD')
    setDate(formatDate)

    if (data["createdDate"] === undefined) {
   
      setData({ ...data, Fecha_Solicitud: formatDate,/* createdBy: sessionUser.IdEmployee_FK*/ })

    } else {
      setItems((data.Items))
      setUsers((data.users))
      setEmpresa((data.Empresa))
    
    }



    return true
  }

  const clearStates = () => {
    setData('')
    setCurrentUser('')
    setDate('')
  }
  const onSubmit = async e => {
    e.preventDefault()
   var result = ''

   
    const temp = {
      ...data,


      
      
      status: "activo",
      Fecha_Solicitud: date

    }
   

    if (data.IdFormat_PK === undefined)
      result = await CreateFormat(temp, typef)
    else
      result = await updateFormat(temp, typef)

    if (result.data.status === 200) {
      toast.success(result.data.msj)
    }
    else {
      toast.error(result.data.msj);
    }


    clearStates()
    hide(!show)
    setRestart(!restart)
  }



  return (
    <>
      {show && (
        <Overlay style={{ zIndex: '1' }}>
          <ModalContainer>
            <ModalHeader>
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <th style={{ width: '20%', verticalAlign: 'bottom' }} rowSpan={3}>NIT 830.507.705-2</th>
                    <th style={{ width: '55%', height: '38px' }}>RELIABILITY MAINTENANCE SERVICES S.A.S</th>
                    <th style={{ width: '25%' }}>{formato}</th>
                  </tr>
                  <tr>
                    <th rowSpan={2}>{nombreFormato}</th>
                    <th style={{ fontSize: '10px' }}>Revisión:{revision}</th>
                  </tr>
                  <tr>
                    <th style={{ fontSize: '10px' }}>Fecha de revisión:{fechaRev}</th>
                  </tr>
                </tbody>
              </table>
            </ModalHeader>
            <CloseButtonModal onClick={() => {
                          clearStates()
                          hide(!show)
                        }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-x-square"
                viewBox="0 0 16 16">
                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </CloseButtonModal>
            <table>
              <tbody>
                <tr>
                  <th style={{ width: '55%', textAlign: 'right' }}></th>
                  <th style={{ width: '15%', textAlign: 'right' }}></th>
                  <td style={{ width: '25%', background: '#FFF' }}></td>
                </tr>
              </tbody>
            </table>

                      <Form onSubmit={onSubmit}>
                      <Frame isView={isView}>
                              <table style={{ width: '100%' }}>
                                 
                                  <tbody>
                                      <tr>
                                          
                                          <td colSpan={2}></td>
                                          <td style={{width:'15%'}} ><label>Fecha de solicitud</label></td>
                                          <td><input
                                          type='date' className='disable'
                                          name='Fecha_Solicitud' value={data.Fecha_Solicitud} onChange={handleChange} required disabled={true}
                                          ></input></td>
                                      </tr>
                                      <tr>
                                          <td ><label>Area:</label></td>
                                          <td style={{width:'50%'}}><input
                                          name='Area' value={data.Area} onChange={handleChange} required disabled={isView ? true : false}
                                          ></input></td>
                                          <td colSpan={2} rowSpan={4}></td>
                                      </tr>
                                      <tr>
                                          <td ><label>Nombre del empleado:</label></td>
                                          <td style={{width:'50%'}}><input
                                          name='Nombre' value={data.Nombre} onChange={handleChange} required disabled={isView ? true : false}
                                          ></input></td>
                                          
                                      </tr>
                                      <tr>
                                          <td ><label>C.C:</label></td>
                                          <td style={{width:'50%'}}><input
                                          name='Cedula' value={data.Cedula} onChange={handleChange} required disabled={isView ? true : false}
                                          ></input></td>
                                          
                                      </tr>
                                      <tr>
                                          <td ><label>Cargo:</label></td>
                                          <td style={{width:'50%'}}><input
                                          name='Cargo' value={data.Cargo} onChange={handleChange} required disabled={isView ? true : false}
                                          ></input></td>
                                          
                                      </tr>
                                  </tbody>
                              </table>
                          </Frame>
                          <Frame isView={isView}>
                              <table style={{ width: '100%' }}>
                                 
                                  <tbody>
                                      <tr>
                                          <td rowSpan={3} style={{background:'lightgrey'}}>
                                              <h2 className="text-center" style={{fontSize:'12px'}}>Fecha de permiso </h2>
                                          </td>
                                          <td colSpan={3} style={{background:'lightgrey'}}>
                                              <h2 className="text-center"  style={{fontSize:'12px'}}>Tiempo de permiso</h2>
                                          </td>
                                          <td colSpan={7} style={{background:'lightgrey'}}>
                                              <h2 className="text-center"  style={{fontSize:'12px'}}>Clase de permiso</h2>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td colSpan={2} style={{background:'lightgrey'}}>
                                              <h2 className="text-center"  style={{fontSize:'12px'}}>Menor de un día</h2>
                                          </td>
                                          <td style={{background:'lightgrey'}}>
                                              <h2 className="text-center"  style={{fontSize:'12px'}}>día(s)</h2>
                                          </td>
                                    
                                          <td rowSpan={2} style={{background:'lightgrey'}}>
                                              <h2 className="text-center"  style={{fontSize:'12px'}}>Cita medica</h2>
                                          </td>
                                          <td rowSpan={2} style={{background:'lightgrey'}}>
                                              <h2 className="text-center"  style={{fontSize:'12px'}}>No remunerado</h2>
                                          </td>
                                          <td rowSpan={2} style={{background:'lightgrey'}}>
                                              <h2 className="text-center"  style={{fontSize:'12px'}}>Compensatorio </h2>
                                              <label className='text-center'>(Indicar día trabajado)</label>
                                          </td>
                                          <td rowSpan={2} style={{background:'lightgrey'}}>
                                              <h2 className="text-center"  style={{fontSize:'12px'}}>Calamidad</h2>
                                          </td>
                                          <td rowSpan={2} style={{background:'lightgrey'}}>
                                              <h2 className="text-center"  style={{fontSize:'12px'}}>Personal</h2>
                                          </td>
                                          <td rowSpan={2} style={{background:'lightgrey'}}>
                                              <h2 className="text-center"  style={{fontSize:'12px'}}>Licencias</h2>
                                              <label className='text-center'>indicar tipo de licencia</label>
                                          </td>
                                          <td rowSpan={2} style={{background:'lightgrey'}}>
                                              <h2 className="text-center"  style={{fontSize:'12px'}}>Dia de la familia</h2>
                                              <label className='text-center'> (Ley 1857/2017)</label>
                                          </td>
                                      </tr>
                                      <tr>
                                        <td style={{background:'lightgrey'}}><h2 className="text-center"  style={{fontSize:'12px'}}>Hora salida</h2></td>
                                        <td style={{background:'lightgrey'}}><h2 className="text-center"  style={{fontSize:'12px'}}>Hora llegada</h2></td>
                                        <td style={{background:'lightgrey'}}><h2 className="text-center"  style={{fontSize:'12px'}}>Numero de dias</h2></td>
                                      </tr>
                                      <tr>
                                        <td><input
                                        type='date'
                                        name='FechaPermiso' value={data.FechaPermiso} onChange={handleChange} required disabled={isView ? true : false}
                                        ></input></td>
                                        <td><input
                                        name='HoraSalida' value={data.HoraSalida} onChange={handleChange}  disabled={isView ? true : false}
                                        ></input></td>
                                        <td><input
                                        name='HoraLlegada' value={data.HoraLlegada} onChange={handleChange}  disabled={isView ? true : false}
                                        ></input></td>
                                        <td><input 
                                        type='number'
                                        name='NumeroDias' value={data.NumeroDias} onChange={handleChange}  disabled={isView ? true : false}
                                        ></input></td>
                                        <td>
                                          <input
                                              type='radio'
                                              name='TipoPermiso'
                                              value={'Cita Medica'}
                                              checked={data['TipoPermiso'] === 'Cita Medica' ? 'checked' : ''}
                                              onChange={handleChange}
                                              required
                                              disabled={isView ? true : false}
                                           ></input>
                                        </td>
                                        <td>
                                          <input
                                              type='radio'
                                              name='TipoPermiso'
                                              value={'No remunerado'}
                                              checked={data['TipoPermiso'] === 'No remunerado' ? 'checked' : ''}
                                              onChange={handleChange}
                                              required
                                              disabled={isView ? true : false}
                                           ></input>
                                        </td>
                                        <td>
                                          <input
                                              type='radio'
                                              name='TipoPermiso'
                                              value={'Compensatorio'}
                                              checked={data['TipoPermiso'] === 'Compensatorio' ? 'checked' : ''}
                                              onChange={handleChange}
                                              required
                                              disabled={isView ? true : false}
                                           ></input>
                                        </td>
                                        <td>
                                          <input
                                              type='radio'
                                              name='TipoPermiso'
                                              value={'Calamidad'}
                                              checked={data['TipoPermiso'] === 'Calamidad' ? 'checked' : ''}
                                              onChange={handleChange}
                                              required
                                              disabled={isView ? true : false}
                                           ></input>
                                        </td>
                                        <td>
                                          <input
                                              type='radio'
                                              name='TipoPermiso'
                                              value={'Personal'}
                                              checked={data['TipoPermiso'] === 'Personal' ? 'checked' : ''}
                                              onChange={handleChange}
                                              required
                                              disabled={isView ? true : false}
                                           ></input>
                                        </td>
                                        <td>
                                          <input
                                              type='radio'
                                              name='TipoPermiso'
                                              value={'Licencias'}
                                              checked={data['TipoPermiso'] === 'Licencias' ? 'checked' : ''}
                                              onChange={handleChange}
                                              required
                                              disabled={isView ? true : false}
                                           ></input>
                                        </td>
                                        <td>
                                          <input
                                              type='radio'
                                              name='TipoPermiso'
                                              value={'Dia de la familia'}
                                              checked={data['TipoPermiso'] === 'Dia de la familia' ? 'checked' : ''}
                                              onChange={handleChange}
                                              required
                                              disabled={isView ? true : false}
                                           ></input>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td><label>Observaciones</label></td>
                                        <td colSpan={10}><textarea
                                         name='Observaciones' value={data.Observaciones} onChange={handleChange}  disabled={isView ? true : false}
                                        ></textarea></td>
                                      </tr>
                                  </tbody>
                              </table>
                          </Frame>
              <Frame isView={isView}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                        <td><label>FIRMA DEL EMPLEADO:</label></td>
                        <td style={{height:'80px', width:'28%'}} ></td>
                        <td><label>JEFE INMEDIATO  <br/>y/o GERENCIA CORRESPONDIENTE:</label></td>
                        <td style={{height:'80px', width:'28%'}} ></td>
                      </tr>
                      <tr>
                        <td><label>GERENCIA GENERAL Vbo:</label></td>
                        <td style={{height:'80px', width:'28%'}} ></td> 
                        <td colSpan={2}></td>
                        
                      </tr>
                      <tr >
                      <td colSpan={9}><h2 className='text-center'>Nota: Es importante que el formato sea firmado tanto por el empleado como por el jefe directo que autoriza el permiso.</h2></td>
                    </tr>
                   
                  </tbody>
                </table>
              </Frame>

             
              <Row>
                <Col></Col>
                <Col></Col>
                <Col></Col>
                <Col></Col>
                {!isView ? (
                  <>
                    <Col>
                      <Button
                        className="btn btn-danger"
                        onClick={() => {
                          clearStates()
                          hide(!show)
                        }}>

                        Cancelar
                      </Button>
                    </Col>
                    <Col>
                      <Button type="submit" className="btn btn-success">
                        Guardar
                      </Button>
                    </Col>
                  </>
                ) : (
                  <Col>
                    <Button
                      className="btn btn-secundary"
                      onClick={() => {
                        clearStates()
                        hide(!show)
                      }}
                    >
                      Cerrar
                    </Button>
                  </Col>
                )}
              </Row>
            </Form>
          </ModalContainer>
        </Overlay>
      )}
    </>
  )
}

export default GTHFOR92

const Frame = styled.div`
  width: 100%;
  padding-bottom: 0px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: left;
  border-width: 2px;
  border-color: #1a004a;
  border-style: solid;
  font-size: 12px;
  font-weight: 100;
  .photo {
    background: #ebf1de;
  }
  .rms {
    background: #F2F2F2;
  }
  label {
    padding-left: 5px;
    display: flex;
    font-size: 12px;
    font-weight: bold;
    text-align: 'center';
  }
  input {
    text-align: center;
    width: 100%;
    border-bottom: 1px solid black;
    border-color: #1a004a;
    background: ${props => (props.isView ? '#FFF' : '#cee1ed')};

    padding-left: 5px;
    /* margin-top: 5px; */
  }
  textarea {
    text-align: justify;
    width: 100%;
    border-bottom: 1px solid black;
    border-color: #1a004a;
    background: ${props => (props.isView ? '#FFF' : '#cee1ed')};
    resize: none;

    padding-left: 5px;
    /* margin-top: 5px; */
  }

  input.edit {
    background: #cee1ed;
  }
  input.disable {
    background: #fff;
  }
  h2 {
    font-size: 14px;
    font-weight: bold;
    text-align: 'center';
  }

  th {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    text-align: left;
    vertical-align: center;
    padding-left: 5px;

    input {
      width: 100%;
    }
  }
  tr {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
  }
  td {
          border-width: 1px;
          border-color: #1a004a;
          border-style: solid;
  }
`

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgb(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalContainer = styled.div`
  width: 1000px;
  height: 90vh;
  min-height: 500px;
  background: #fff;
  position: relative;
  border-radius: 5px;
  overflow: scroll;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
`

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
  padding-bottom: 10px;

  th {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
  }
  tr {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
  }
  table {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    text-align: center;
    vertical-align: center;
    font-size: 14px;
  }
  h3 {
    font-weight: 500;
    font-size: 16px;
    color: #1766dc;
  }
`

const CloseButtonModal = styled.div`
  position: absolute;
  top: 2px;
  right: 5px;
  width: 20px;
  height: 20px;
  border: none;
  background: none;
  cursor: pointer;
  transition: 0.3s ease all;
  border-radius: 5px;
  color: #1766dc;
  &:hover {
    background: #f2f2f2;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`
export const Label = styled.label`
  width: 120px;
  height: 150px;
  position: relative;
  cursor: ${({ isView }) => (isView ? 'normal' : 'pointer')};
`

export const Photo = styled.img`
  object-fit: contain;
  max-width: 110px;
  position: absolute;
  inset: 0;
  margin: auto;`