import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import image1 from '../../../assets/img/r.png';
import image2 from '../../../assets/img/m.png';
import image3 from '../../../assets/img/s.png';
import image4 from '../../../assets/img/linea.png';

const SpinnerLoad = ({ trigger }) => {
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0)
  const [record, setrecord] = useState({})  

  useEffect(() => {
    setCount(count + 1)
    setrecord({...record, [`record${count}`]:''})
    if(trigger !==''&& trigger !==undefined && trigger !==[])
       setLoading(false);

  }, [trigger]);

  return (
    <>
      {loading && (
        <Overlay>
          <SpinnerContainer>
            <SpinnerOuter />
            <SpinnerInner />
            <ImagesContainer>
              <FirstImageContainer>
                <FadeImage style={{zIndex:10000}} src={image1}  width="17px" height="20px" delay="1s" />
                <FadeImage style={{zIndex:10000}} src={image2} width="20px" height="21px" delay="2s" />
                <FadeImage style={{zIndex:10000}} src={image3} width="17px" height="20px" delay="3s" />
              </FirstImageContainer>
              <FadeImage src={image4} width="55px" height="6px" delay="2s" />
            </ImagesContainer>
          </SpinnerContainer>
        </Overlay>
      )}
    </>
  );
};

export default SpinnerLoad;

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.0);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const spinnerRotation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`;

const SpinnerOuter = styled.div`
  position: absolute;
  left:-11px;
  top:-2;
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 74px;
  height: 74px;
  border-left-color: #004d89;
  border-radius: 50%;
  
  animation: ${spinnerRotation} 2s linear infinite;
`;

const SpinnerInner = styled.div`
  position: absolute;
  left:-7px;
  border: 3px solid rgba(0, 0, 0, 0.1);
  width: 66px;
  height: 66px;
  border-left-color: #004d89;
  border-radius: 50%;
  
  animation: ${spinnerRotation} 0.8s linear reverse infinite;
`;

const ImagesContainer = styled.div`
  position: absolute;
  left:-1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
`;

const FirstImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const fadeAnimation = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
`;

const FadeImage = styled.img`
  width: ${props => props.width};
  height: ${props => props.height};
  margin: 0 0px;
  animation: ${fadeAnimation} 6s infinite;

  &:nth-child(1) {
    animation-delay: ${props => props.delay};
  }
  &:nth-child(2) {
    animation-delay: ${props => props.delay};
  }
  &:nth-child(3) {
    animation-delay: ${props => props.delay};
  }
  &:nth-child(4) {
    animation-delay: ${props => props.delay};
  }

  &:nth-child(1):after,
  &:nth-child(2):after,
  &:nth-child(3):after,
  &:nth-child(4):after {
    content: '';
    display: block;
    width: ${props => props.width};
    height: ${props => props.height};
    position: absolute;
    background-color: #000;
    animation: ${fadeAnimation} 1s infinite;
    opacity: 0;
  }

  &:nth-child(1):after {
    animation-delay: ${props => props.delay};
  }
  &:nth-child(2):after {
    animation-delay: ${props => props.delay};
  }
  &:nth-child(3):after {
    animation-delay: ${props => props.delay};
  }
  &:nth-child(4):after {
    animation-delay: ${props => props.delay};
  }
`;
