import Moment from 'moment'
import { useEffect, useState, useRef } from 'react'
import { Button, Col, Form, Row, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import Signer from '../signer'
import { CreateFormat, getFormats, newWorkflow, updateFormat, updateWorkflow, getFormatsById } from './request'
import { useListContext, useUserContext } from '../../../../hooks/custom'
import SpinnerLoad from '../spinnerLoad'
import image from '../../../../assets/img/Logo_b-st.png'
import SelectOption from '../SelectOption'
import { PrecioEnLetras, monedaCOP, onlyNumeric } from '../functions'
import { getUserByIdF } from '../request'

export const COFIFOR159 = ({
  show,
  hide,
  restart,
  setRestart,
  isView,
  data = '',
  setData,
  isSign = false,
  isAccount = false,
  list,
  workflow = [],
  IdFormat_PK,


}) => {
  /*---------ESTADOS------------------
      [     creado      ] => documento creado  firmado por elaborador
              V      [    rechazado    ]
      [ firmaAprobador  ] => documento firmado por el jefe
              V   
      [    Procesar     ] => documento firmado por el lider de area
              V
      [    Por    ] => documento firmado por el gerente general
              V
      [    reintegrar    ] => documento firmado por el gerente general
              V
      [   Finalizado    ] => documento firmado por el gerente general
              V
     
  */
  const wf = {

    creando: "Firmar RQ Jefe",
    firmaAprobado: "Firmar RQ Lider Area",
    firmaLiderA: "Aprobar RQ",
    Aprobar: "Aprobar RQ"
  }
  const wfsign = {

    creando: "firmaAprobador",
    firmaAprobado: "firmaLider",
    // firmaLiderA: "firmaGerente"
  }
  const estado = {
    creando: "firmaAprobado",
    firmaAprobado: "firmaLiderA",
    firmaLiderA: "Aprobar",
    Aprobar: "Procesar",
    Procesar: "Finalizado"
  }


  //-------------------------Info Formato
  const formato = 'COFI-FOR-159'
  const revision = '10'
  const fechaRev = '17/08/2022'
  const type = 'COFIFOR159'
  const nombreFormato = 'SOLICITUD ANTICIPO PARA VIAJE '
  const typef = 'COFIFORANT'

  //-------------------------Registros

  const printRef = useRef(null);
  

  const { sessionUser } = useUserContext()
  const { listData } = useListContext()
  const [date, setDate] = useState('')
  const [firmaSolicitante, setfirmaSolicitante] = useState('')
  const [firmaAprobado, setfirmaAprobado] = useState('')
  const [firmaLider, setfirmaLider] = useState('')
  const [recibidoContabilidad, setrecibidoContabilidad] = useState('')
  const [empresaPaga, setEmpresaPaga] = useState('')
  const [listaLider, setListaLider] = useState([])
  const [empleados, setEmpleados] = useState('')
  const [isEdit, setIsEdit] = useState(false)
  const [modalAction, setModalAction] = useState(false)
  const [actionBtn, setActionBtn] = useState("Rechazar")
  const [reintegro, setReintegro] = useState(null)


  const [tblAnt, setTblAnt] = useState({
    hotel: { id: 'hotel', concept: 'Hotel', req: 0, apb: '', uni: 70000, obs: '', subt: 0 },
    desayuno: { id: 'desayuno', concept: 'Desayuno (día)', req: 0, apb: '', uni: 10000, obs: '', subt: 0 },
    almuerzo: { id: 'almuerzo', concept: 'Almuerzo (día)', req: 0, apb: '', uni: 12000, obs: '', subt: 0 },
    comida: { id: 'comida', concept: 'Comida (día)', req: 0, apb: '', uni: 10000, obs: '', subt: 0 },
    transTerm: { id: 'transTerm', concept: 'Transporte Term./Aerop.', req: 0, apb: '', uni: 35000, obs: '', subt: 0 },
    transUrb: { id: 'transUrb', concept: 'Trasporte urbano', req: 0, apb: '', uni: 2500, obs: '', subt: 0 },
    hidrat: { id: 'hidrat', concept: 'Hidratacíon', req: 0, apb: '', uni: 5000, obs: '', subt: 0 },
    lavadoR: { id: 'lavadoR', concept: 'Lavado Ropa Semanal', req: 0, apb: '', uni: 15000, obs: '', subt: 0 },
    gas: { id: 'gas', concept: 'Gasolina', req: 0, apb: '', uni: 100000, obs: '', subt: 0 },
    peaje: { id: 'peaje', concept: 'Peajes', req: 0, apb: '', uni: 9100, obs: '', subt: 0 },
    parquea: { id: 'parquea', concept: 'Parqueadero', req: 0, apb: '', uni: 15000, obs: '', subt: 0 },
    lavadoC: { id: 'lavadoC', concept: 'Lavado Camioneta', req: 0, apb: '', uni: 50000, obs: '', subt: 0 }
  })
  const subtotalSum = Object.values(tblAnt).reduce((total, item) => total + item.subt, 0);
  useEffect(() => {
    if (!restart) {
      setRestart(!restart)
      if (show) getInfo()
    }
  }, [restart])

  // useEffect(() => {
  //   async function fetchData() {
  // // Obtenemos el nodo DOM asociado a la referencia printReq
  // const printReqNode = ReactDOM.findDOMNode(printRef.current);

  // // Verificamos si el nodo existe antes de continuar
  // if (printReqNode) {
  //   // Obtenemos los estilos CSS aplicados al nodo
  //   const styles = window.getComputedStyle(printReqNode);

  //   // Convertimos los estilos en una cadena de texto
  //   let stylesText = '';
  //   for (let i = 0; i < styles.length; i++) {
  //     const property = styles[i];
  //     const value = styles.getPropertyValue(property);
  //     stylesText += `${property}: ${value}; `;
  //   }

  //   // Convertimos el nodo a texto utilizando outerHTML
  //   const printReqTexto = `${printReqNode.outerHTML}<style>${stylesText}</style>`;
  //      // Aquí puedes hacer lo que necesites con la variable printReqTexto
  //      console.log(printReqTexto);
  //      setData({ ...data, formatData: printReqTexto })
  //      const result = await updateFormat({IdFormat_PK: data.IdFormat_PK, formatData: printReqTexto}, typef)
  //    }
  //   }
  //   if (data.Status === "Finalizar" && !data.formatData && recibidoContabilidad.NombreTrabajador ) {
  //     fetchData();
  //   }
  // }, [recibidoContabilidad.NombreTrabajador])



  const handleChange = async ({ target }) => {
    const { name, type } = target
    const value = type === 'checkbox' ? target.checked : target.value
    setData({ ...data, [name]: value, })
  }
  const handleChangeTable = (value, target) => {
    const { name, id } = target
    if (id === 'req' || id === 'apb') {
      setTblAnt({ ...tblAnt, [name]: { ...tblAnt[name], [id]: value, subt: tblAnt[name]['uni'] * value } })
    }
    else
      setTblAnt({ ...tblAnt, [name]: { ...tblAnt[name], [id]: value } })

  }
  const ActionSol = async () => {
    let motRachazo = ""
    let newStatus = ""
    if (actionBtn === "Rechazar") {
      newStatus = "Rechazado"
      motRachazo = data.rechazo + "\n" + sessionUser.NombreTrabajador + " " + sessionUser.ApellidoTrabajador + "\n" + 'fecha: ' + date
    }
    if (actionBtn === "Reanudar") {

      if (workflow.action === "Aprobar") newStatus = "firmaAprobador"
    }
    var result = await updateFormat({ IdFormat_PK: IdFormat_PK, Status: newStatus, rechazo: motRachazo }, typef)
    if (result.data.status === 200) {
      await updateWorkflow({ ...workflow, Status: actionBtn === "Rechazar" ? "rechazado" : 'pendiente', dateFinish: date })
      toast.success(result.data.msj)
    } else {
      toast.error(result.data.msj)
    }
    clearStates()
    hide(!show)
    setRestart(!restart)

  }



  const getInfo = async () => {
    console.log(data)
    const formatDate = Moment().format('YYYY-MM-DD')
    setDate(formatDate)
    let resDNI = await (await getFormats('employee')).data.data.filter(item => item.Status === "activo")
    setListaLider(resDNI.filter(item => item.personalACargo.includes("Si")))
    setEmpleados(resDNI)
    if (IdFormat_PK === 'en creacion') {
      const userData = (await getFormats('gthfor086')).data.data.filter(i => i.idEmployeePK === sessionUser.IdEmployee_FK)[0]
      setfirmaSolicitante({
        ApellidoTrabajador: sessionUser.ApellidoTrabajador,
        IdEmployee_FK: sessionUser.IdEmployee_FK,
        NombreTrabajador: sessionUser.NombreTrabajador,
        fecha: '',
      })
      
      let jefeInm = await getUserByIdF('IdUser_PK', sessionUser.idJefeInm_FK, true)
      setfirmaAprobado({
        ApellidoTrabajador: jefeInm.data.data.user.UserLastName,
        IdEmployee_FK: jefeInm.data.data.user.IdUser_PK,
        NombreTrabajador: jefeInm.data.data.user.UserName,
        fecha: '',
      })
      setrecibidoContabilidad({
        ApellidoTrabajador: ' revisión',
        IdEmployee_FK: '',
        NombreTrabajador: 'sin',
        fecha: '',
      })
      const empresaPagaRes = listData.companies.filter(i => i.MainCompany === 'MAIN')[0]
      setEmpresaPaga({ SocialName: empresaPagaRes.SocialName, IdCompany_PK: empresaPagaRes.IdCompany_PK })
      setData({
        motivoViaje: "",
        rechazo: "",
        tipoCuenta: userData.AccountType === "SavingAccount" ? 'ctaaho' : 'ctacor',
        Bank: userData.Bank,
        numeroCuenta: userData.CountNumber,
        formato: type,
        rechazo: '',
        createdBy: sessionUser.IdEmployee_FK,
        CeCo: sessionUser.CeCo,
        IdFormat_PK: 'en creacion',
        FechaSolicitud: formatDate,
        Status: 'creando'
      })
    } else {
      var tablaSolicitud = await JSON.parse(data.tblAnt)
      if (isSign && data.Status === "firmaAprobador") {
        // Iterar sobre las claves del objeto
        Object.keys(tablaSolicitud).forEach((key) => {
          tablaSolicitud[key].apb = tablaSolicitud[key].req;
        });
      }
      
      setTblAnt(tablaSolicitud)
      setfirmaSolicitante(await JSON.parse(data.firmaSolicitante))
      setfirmaAprobado(await JSON.parse(data.firmaAprobado))
      setrecibidoContabilidad(await JSON.parse(data.recibidoContabilidad))
      setEmpresaPaga(await JSON.parse(data.empresaPaga))


      if (isAccount && data.Status === 'Procesando') {
        setrecibidoContabilidad({
          ApellidoTrabajador: sessionUser.ApellidoTrabajador,
          IdEmployee_FK: sessionUser.IdEmployee_FK,
          NombreTrabajador: sessionUser.NombreTrabajador,
          fecha: formatDate
        })
      }


    }


    if (data.Status === "edicion") {
      setIsEdit(true)
      setData({ ...data, Status: 'firmaAprobador' })
    }
  }



  const clearStates = () => {
    data = []
    setData('')
    setDate('')
  }

  const onSubmit = async e => {
    e.preventDefault()





    var nextId_WF = ""
    var nextWF = []
    if (subtotalSum === 0) {
      toast.error('debe incluir al menos un (1) item')
    }
    if (
      (data.Status === 'creando' && sessionUser.IdEmployee_FK === firmaSolicitante.IdEmployee_FK && !firmaSolicitante.image) ||
      (isSign && sessionUser.IdEmployee_FK === firmaAprobado.IdEmployee_FK && !firmaAprobado.image)
    ) {
      toast.error('por favor firme el documento')
      return
    }



    var result = ''
    if (!isEdit) {
      if (data.Status === 'firmaAprobador' && !!firmaLider.image) {
        data = { ...data, Status: "firmaLiderA" }
        // nextId_WF = firmaGerente.IdEmployee_FK
      }
      else
        nextId_WF = firmaLider.IdEmployee_FK
    }
    data = {
      ...data,
      firmaSolicitante: JSON.stringify(firmaSolicitante),
      firmaAprobado: JSON.stringify(firmaAprobado),
      recibidoContabilidad: JSON.stringify(recibidoContabilidad),
      empresaPaga: JSON.stringify(empresaPaga),
      tblAnt: JSON.stringify(tblAnt),
      Status: data.Status === 'creando' ? 'firmaAprobador' : data.Status
    }


    if (data.IdFormat_PK === 'en creacion') {
      delete data.IdFormat_PK
      result = await CreateFormat(data, typef)
      if (result.data.status === 200) {
        nextWF = {
          TaskDescription: 'Solicitud de ' + firmaSolicitante.NombreTrabajador,
          action: "Aprobar",
          Status: "pendiente",
          Format: type,
          dateCreation: date,
          documents: "Anticipo viaje ",
          IdFormat_FK: result.data.data.insertId,
          IdEmployee_created: sessionUser.IdEmployee_FK,
          IdEmployee_FK: firmaAprobado.IdEmployee_FK,
        }


        result = await newWorkflow(nextWF)
        if (result.data.status === 200) {
          toast.success("Anticipo creado exitosamente")
        }
        else {
          toast.error(result.data.msj); return
        }
      }
      else {
        toast.error(result.data.msj); return
      }
    }
    else {
      delete data.firmaSolicitante
      if (data.Status === 'firmaAprobador') {
        data['Status'] = 'Procesar'

      }
      else if (data.Status === 'Procesando') {
        delete data.firmaAprobado
        data['Status'] = 'Legalizar'
      }

      result = await updateFormat(data, typef)
      if (result.data.status === 200 && isSign && !isEdit) {
        result = await updateWorkflow({ ...workflow, Status: "finalizado", dateFinish: date })
        delete workflow.IdWorkFlow_PK
        if (data.Status !== 'Procesando') {

          result = await newWorkflow({
            ...workflow,
            IdEmployee_FK: nextId_WF,

            action: wf[data.Status]
          })
        }
        if (result.data.status === 200) {


          toast.success("Anticipo actualizado exitosamente")
        } else {
          toast.error(result.data.msj); return
        }
      }
      else if (result.data.status === 200 && data.Status === 'Legalizar') { toast.success("Anticipo procesado exitosamente") }

      else if (result.data.status === 200 && isEdit) { toast.success("Anticipo actualizado exitosamente") }
      else {
        toast.error(result.data.msj); return
      }
    }


    clearStates()
    hide(!show)
    setRestart(!restart)

  }

  return (
    <>
      {show && (
        <Overlay style={{ zIndex: '1' }}>
          <SpinnerLoad trigger={firmaAprobado} />
          <ModalContainer id="modal-container">
            <CloseButtonModal className="no-print"
              onClick={() => {
                clearStates()
                hide(!show)
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-x-square"
                viewBox="0 0 16 16"
              >
                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </CloseButtonModal>
            <Form onSubmit={onSubmit}>
              {data.formatData && false? <div dangerouslySetInnerHTML={{ __html: data.formatData }}></div> : <PrintArea ref={printRef} >         
               <ModalHeader>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <th style={{ width: '20%', fontSize: '10px', verticalAlign: 'bottom' }} rowSpan={3}>
                        <img
                          className="format_logo"
                          src={image}
                          style={{ paddingBottom: '7px', width: '75%', minWidth: "40px" }}
                          alt="Logo RMS"
                        /><br />
                        NIT 830.507.705-2
                      </th>
                      <th style={{ width: '50%', height: '38px' }}>RELIABILITY MAINTENANCE SERVICES S.A.S</th>
                      <th style={{ width: '20%' }}>{formato}</th>
                    </tr>
                    <tr>
                      <th rowSpan={2}>{nombreFormato}</th>
                      <th style={{ fontSize: '10px' }}>Revisión: {revision}</th>
                    </tr>
                    <tr>
                      <th style={{ fontSize: '10px' }}>Fecha de revisión: {fechaRev}</th>
                    </tr>
                  </tbody>
                </table>
              </ModalHeader>
                <Frame isView={isView}>
                  <table>
                    <tbody>
                      <tr  >
                        <td style={{ width: '20%' }}></td>
                        <td style={{ width: '16%' }}></td>
                        <td style={{ width: '10%' }}><label> Solicitud:</label> </td>
                        <td style={{ width: '15%' }}>
                          {IdFormat_PK}
                        </td>
                        <td style={{ width: '10%' }}><label> Estado:</label></td>
                        <td style={{ width: '20%' }}>
                          {data.Status}
                        </td>
                      </tr>

                      <tr  >
                        <td ><label> Nombre del solicitante:</label> </td>
                        <td colSpan={3} >
                          <input
                            className="disable"
                            value={firmaSolicitante.NombreTrabajador + ' ' + firmaSolicitante.ApellidoTrabajador}
                            onChange={e => { }}
                            disabled
                          />
                        </td>
                        <td ><label> C.C.:</label></td>
                        <td >
                          <input
                            className="disable"
                            value={firmaSolicitante.IdEmployee_FK}
                            onChange={e => { }}
                            disabled
                          />
                        </td>
                      </tr>
                      <tr  >
                        <td ><label> Motivo del viaje:</label></td>
                        <td colSpan={5} style={{ width: '75%' }}>
                          <input
                            name="motivoViaje"
                            value={data.motivoViaje}
                            onChange={handleChange}
                            required
                            disabled={isView ? true : false}
                          />

                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Frame>

                <Frame isView={isView}>
                  <table >
                    <tbody >
                      <tr  >
                        <td style={{ width: '20%' }}><label> Ciudad de origen:</label></td>
                        <td style={{ width: '30%' }}>
                         <SelectOption id='ciudadOrigen' type={"city"} searchable={true} state={data} setState={setData} nameState={'ciudadOrigen'} required disabled={isView ? true : false} />                        </td>
                        <td style={{ width: '20%' }}><label> Ciudad de destino:</label></td>
                        <td style={{ width: '30%' }}>
                          <SelectOption type={"city"} searchable={true} state={data} setState={setData} nameState={'ciudadDestino'} required disabled={isView ? true : false} />
                        </td>
                      </tr>
                      <tr  >
                        <td ><label>Fecha Salida:</label></td>
                        <td  >
                          <input
                            type={'date'}
                            name="FechaSalida"
                            value={data.FechaSalida}
                            defaultValue={''}
                            onChange={handleChange}
                            required
                            disabled={isView ? true : false}
                          />
                        </td>
                        <td ><label> Hora estimada:</label></td>
                        <td >
                          <input
                            type={'time'}
                            name="horaSalida"
                            value={data.horaSalida}
                            defaultValue={''}
                            onChange={handleChange}
                            required
                            disabled={isView ? true : false}
                          />
                        </td>
                      </tr>
                      <tr  >
                        <td ><label> Fecha de llegada:</label></td>
                        <td >
                          <input
                            type={'date'}
                            name="FechaLlegada"
                            value={data.FechaLlegada}
                            defaultValue={''}
                            onChange={handleChange}
                            required
                            disabled={isView ? true : false}
                          />
                        </td>
                        <td ><label> Hora estimada:</label></td>
                        <td >
                          <input
                            type={'time'}
                            name="horaLlegada"
                            value={data.horaLlegada}
                            defaultValue={''}
                            onChange={handleChange}
                            required
                            disabled={isView ? true : false}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Frame> 
                <Frame isView={isView}>
                  <table >
                    <tbody >
                      <tr  >
                        <td style={{ width: '20%' }}><label> Banco:</label></td>
                        <td style={{ width: '30%' }}>
                          <SelectOption type={"Bank"} searchable={true} state={data} setState={setData} nameState={'Bank'} required disabled={isView ? true : false} />
                        </td>
                        <td style={{ width: '20%' }}><label> Número de cuenta:</label></td>
                        <td style={{ width: '30%' }}>
                          <input
                            name="numeroCuenta"
                            value={data.numeroCuenta}
                            onChange={handleChange}
                            required
                            disabled={isView ? true : false}
                          /></td>
                      </tr>
                      <tr  >
                        <td style={{ width: '15%' }}><label>Tipo de cuenta:</label></td>

                        <td style={{ width: '35%' }}>
                          <SelectOption type={"typeAccount"} searchable={true} state={data} setState={setData} nameState={'tipoCuenta'} required disabled={isView ? true : false} />
                        </td>

                      </tr>
                      <tr style={{ width: '100%' }} >
                        <td style={{ width: '15%' }}><label> Valor de Anticipo:</label></td>


                        <td colSpan={3} style={{ fontSize: '11px', borderBottomWidth: '1px', borderBottomStyle: 'solid', fontWeight: '1000' }}  >
                          {PrecioEnLetras(subtotalSum)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Frame>
                <FrameTable isView={isView && !isSign}>
                  <table>
                    <tbody >
                      <tr ><th style={{ width: '20%' }} >CONCEPTO</th>
                        <th style={{ fontSize: '7px' }}>CANTIDAD REQUERIDA</th>
                        <th style={{ fontSize: '7px' }}>CANTIDAD APROBADA</th>
                        <th style={{ fontSize: '7px' }}>VALOR UNITARIO</th>
                        <th style={{ width: '15%' }}>SUBTOTAL</th>
                        <th style={{ width: '35%' }}>OBSERVACIONES</th></tr>
                      {Object.values(tblAnt).map((item, index) => (
                        <tr key={index}>
                          <td><label>{item.concept}</label></td>
                          <td><input name={item.id} id='req' className={data.Status === 'creando' ? '' : 'disable'} value={tblAnt[item.id].req} onChange={e => handleChangeTable(onlyNumeric(e.target.value), e.target)} required={data.Status === 'creando' ? true : false} disabled={data.Status === 'creando' ? false : true} /></td>
                          <td><input name={item.id} id='apb' className={isSign && data.Status === "firmaAprobador" ? '' : 'disable'} value={tblAnt[item.id].apb} onChange={e => handleChangeTable(onlyNumeric(e.target.value), e.target)} required={data.Status !== 'creando' ? true : false} disabled={isSign && data.Status === "firmaAprobador" ? false : true} /></td>
                          <td className='COP'>{monedaCOP(item.uni)}</td>
                          <td className='COP'>{monedaCOP(tblAnt[item.id].subt)}</td>
                          <td><input className={data.Status === 'Rechazado' ? 'disable' : ''} name={item.id} id='obs' value={tblAnt[item.id].obs} onChange={e => handleChangeTable(e.target.value, e.target)} disabled={isView && !isSign ? true : data.Status === 'Rechazado' ? true : false} /></td>
                        </tr>
                      ))}
                      <tr><th colSpan={4}>VALOR TOTAL AUTORIZADO</th>
                        <th className='COP' style={{ fontSize: '11px' }}>{monedaCOP(subtotalSum)}</th>
                        <th></th>
                      </tr>
                    </tbody>
                  </table>
                </FrameTable>
                <Frame >
                  <table>
                    <tbody style={{ textAlign: "justify", paddingLeft: "10px", paddingRight: "10px", fontSize: '8px', maxHeight: '10px', height: '10px' }} >
                      <tr>
                        <td>Observaciones:  <br /> Para que sean reembolsados los gastos autorizados, es indispensable presentar factura(s) por el valor total autorizado, la(s) cual(es) debe(n) cumplir con los siguientes requisitos: <br />-Factura expedida a nombre de RMS S.A.S con NIT 83050775-2 <br />- El proveedor debe ser responsable de IVA, con los requisitos de la factura Art. 617 ET (ver procedimiento de Solicitud y Legalización de anticipos No. 3.2,3.3 y 3.4). Cuando el proveedor no sea responsable de IVA, debe adjuntar copia del RUT actualizado. <br />- Si el motivo del viaje es por capacitación, es necesario firmar el compromiso de reciprocidad. <br />- En caso que exista un excedente sobre el anticipo realizado al trabajador, éste se debe consignar a la cuenta corriente Nº 69939161217 del Bancolombia a nombre de RELIABILITY MAINTENANCE SERVICES S.A.S (RMS S.A.S). <br />-De acuerdo con el valor solicitado, me comprometo con RMS S.A.S a garantizar que el uso de estos recursos serán utilizados para el propósito solicitado, de manera ética y responsable, asegurando que la inversión será de forma legal y acorde con las políticas y lineamientos establecidos por la compañía para la prevención de los delitos de Lavado de Activos , financiación del terrorismo, soborno y soborno transnacional</td></tr>


                    </tbody>
                  </table>
                </Frame>
                 <Frame style={{ padding: '0px', fontSize: '8px' }} >
                  <table>
                    <tbody>
                      <tr> <th style={{ textAlign: 'center' }}>AUTORIZACION DE DESCUENTO</th></tr>
                      <tr>
                        <td>Con la firma del presente documento autorizo a la Empresa a descontar de mi salario y/o liquidación las sumas que no soporte correctamente en
                          Contabilidad o aquellas que no reembolse mediante consignación a la cuenta corriente Nº 69939161217 del Bancolombia a nombre de
                          RELIABILITY MAINTENANCE SERVICES S.A.S (RMS S.A.S).</td></tr>
                    </tbody>
                  </table>
                </Frame> 
                <Frame isView={isView} style={{ borderBottomWidth: '1px' }}>
                  <table>
                    <tbody>
                      <tr  >
                        <td style={{ width: '17%' }}><label> Empresa que paga:</label></td>
                        <td style={{ width: '30%' }}>{empresaPaga.SocialName}</td>
                        <td style={{ width: '6%' }}> </td>
                        <td style={{ width: '20%' }}><label> Centro de Costo:</label></td>
                        <td style={{ width: '27%' }}>
                          <SelectOption type={"CeCo"} searchable={true} state={data} setState={setData} nameState={'CeCo'} required disabled={isView ? true : false} />
                        </td>
                      </tr>


                      <tr style={{ height: '50px', minWidth: '50px', maxHeight: '50px' }}>
                        <td colSpan={2} >
                          <Signer
                            enable={sessionUser.IdEmployee_FK === firmaSolicitante.IdEmployee_FK}
                            sign={firmaSolicitante}
                            setSign={setfirmaSolicitante}
                            doc={type}
                            user={sessionUser.IdEmployee_FK}
                            isView={isView}
                            width='50%'
                          />

                        </td>
                        <td></td>
                        <td colSpan={2} style={{ textAlign: 'center' }}>
                          <Signer
                            enable={isSign && sessionUser.IdEmployee_FK === firmaAprobado.IdEmployee_FK}
                            sign={firmaAprobado}
                            setSign={setfirmaAprobado}
                            doc={typef}
                            user={sessionUser.IdEmployee_FK}
                            width='50%'
                          />
                        </td>


                      </tr>
                      <tr>
                        <td colSpan={2} style={{ fontSize: '12px' }}><input
                          className="disable"
                          value={firmaSolicitante.NombreTrabajador + ' ' + firmaSolicitante.ApellidoTrabajador}
                          onChange={e => { }}
                          disabled
                        /></td>
                        <td></td>
                        <td colSpan={2} style={{ fontSize: '12px', textAlign: 'center' }}>

                          {data.Status !== 'creando' ?
                            <input
                              className="disable"
                              value={firmaAprobado.NombreTrabajador + ' ' + firmaAprobado.ApellidoTrabajador}
                              onChange={e => { }}
                              disabled
                            />

                            : <select
                              style={{ width: '75%', textAlign: 'center' }}
                              name="talentoHumano"
                              value={firmaAprobado.IdEmployee_FK}
                              onChange={e => {
                                setfirmaAprobado({
                                  ApellidoTrabajador: e.target.selectedOptions[0].lang,
                                  NombreTrabajador: e.target.selectedOptions[0].id,
                                  IdEmployee_FK: e.target.value,
                                  expedicionDNI: e.target.selectedOptions[0].className
                                });
                              }}
                              required
                              disabled={isView ? true : false}
                              placeholder="seleccione..."
                              title="seleccione..."
                            >

                              {listaLider.map((item, i) => {
                                return (
                                  <option key={i + 1} className={item.expedicionDNI} id={item.NombreTrabajador} lang={item.ApellidoTrabajador} value={item.IdEmployee_FK}>{item.NombreTrabajador + " " + item.ApellidoTrabajador} </option>
                                );
                              })}
                            </select>}

                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2} style={{ textAlign: 'center', fontSize: '10px' }}>NOMBRE Y FIRMA DEL SOLICITANTE </td>
                        <td></td>
                        <td colSpan={2} style={{ textAlign: 'center', fontSize: '10px' }}>AUTORIZACIÓN GERENTE DEL ÁREA - LIDER DE AREA - LIDER DE
                          PROYECTO (NOMBRE Y FIRMA)</td>
                      </tr>
                      <tr>
                        <td colSpan={2} style={{ textAlign: 'center' }}>C.C. {firmaSolicitante.IdEmployee_FK} </td>
                        <td></td>
                        <td colSpan={2} style={{ textAlign: 'center' }}>C.C. {firmaAprobado.IdEmployee_FK}</td>
                      </tr>

                      <tr style={{ marginTop: '50px' }}>
                        <td colSpan={2} style={{ textAlign: 'center' }}>
                          <br></br>
                          <input
                            className="disable"
                            value={recibidoContabilidad.NombreTrabajador + ' ' + recibidoContabilidad.ApellidoTrabajador}
                            onChange={e => { }}
                            disabled
                          />

                        </td>
                        <td></td>
                        <td style={{ textAlign: 'center', fontWeight: 'bold' }}><br />Fecha: </td>
                        <td style={{ textAlign: 'left' }}><br />{data.FechaSolicitud}</td>
                      </tr>
                      <tr><td colSpan={2} style={{ textAlign: 'center', fontWeight: 'bold' }}>RECIBIDO POR CONTABILIDAD: </td></tr>
                    </tbody>
                  </table>
                </Frame>
                {data.Status === "Rechazado" && <Frame isView={true}  >
                  <table style={{ width: '100%' }}>
                    <tbody>
                      <tr>
                        <td>
                          <label>MOTIVO DEL RECHAZO  </label>
                          <textarea
                            style={{ height: '60px' }}
                            name={'rechazo'}
                            value={data.rechazo}
                            onChange={handleChange}

                            disabled={true}
                          ></textarea>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Frame>}
                {(data.Status === "Reintegrar" || data.Status === "Reintegrado" ) && <Frame isView={true}  >
                  <table style={{ width: '100%' }}>
                    <tbody>
                      <tr>
                        <td>
                          <label>MOTIVO DEL REINTEGRO  </label>
                          <textarea
                            style={{ height: '80px',fontSize:'10px' }}
                            name={'reintegro'}
                            value={data.reintegro}
                            onChange={handleChange}

                            disabled={true}
                          ></textarea>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Frame>}
              </PrintArea>}
              <br />
              <Row className="no-print">
                <Col></Col>
                <Col></Col>
                <Col></Col>

                <Col></Col>

                {(!isView || (data.Status !== 'Rechazado' && isSign) || (isAccount && data.Status === 'Procesando')) ? (
                  <>
                    <Col>
                      <Button
                        className="btn btn-danger"
                        onClick={() => {
                          clearStates()
                          hide(!show)
                        }}
                      >
                        Cancelar
                      </Button>
                    </Col>

                    <Col>
                      <Button type="submit" className="btn btn-success">
                        {isAccount && data.Status === 'Procesando' ? 'Finalizar' : 'Guardar'}
                      </Button>
                    </Col>
                  </>
                ) : (
                  <Col>
                    <Button
                      className="btn btn-secundary no-print"
                      onClick={() => {
                        clearStates()
                        hide(!show)
                      }}
                    >
                      Cerrar
                    </Button>
                  </Col>
                )}

                {data.Status !== 'creando' && isSign && <Col>
                  <Button
                    className="btn btn-warning"
                    onClick={() => {
                      setActionBtn(data.Status === 'Rechazado' ? "Reanudar" : 'Rechazar')
                      setModalAction(true)
                    }}
                  >
                    {data.Status === "Rechazado" ? 'Reanudar' : 'Rechazar'}
                  </Button>
                </Col>}
              </Row>

            </Form>
          </ModalContainer>
        </Overlay>
      )}
      <Modal
        show={modalAction}
        onHide={() => {
          setModalAction(false)
        }}
        centered
      >
        <Modal.Header closeButton>
          <h5>{actionBtn} Solicitud</h5>
        </Modal.Header>
        <Modal.Body>
          <p>¿Realmente desea {actionBtn.toLowerCase()} la solicitud {IdFormat_PK}? </p>

          {actionBtn === "Rechazar" && <><p>Por favor especifique el motivo para rechazar la solicitud de anticipo</p>
            <textarea
              style={{ width: '100%', height: '60px' }}
              name={'rechazo'}
              value={data.rechazo}
              onChange={handleChange}

              disabled={false}
            /></>}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setData({ ...data, rechazo: "" })
              setModalAction(false)
            }}
          >
            Cancelar
          </Button>
          <Button className="btn btn-danger"
            onClick={() => {
              if (data.rechazo === "") {
                toast.error('indique el motivo para rechazar')
                return
              }
              ActionSol()
              setModalAction(false)
            }}
          >
            {actionBtn}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default COFIFOR159

const PrintArea = styled.div`
width: 20.3cm;
`
const FrameTable = styled.div`
  width:20.3cm;
  align-items: center;
  justify-content: left;
  border-width: 0px;
  border-top-width: 0px;
  border-bottom-width: 0px;
  border-color: #1a004a;
  border-style: solid;
  font-size: 8px;
  font-weight: 1000;

  table{
    width:100%
  }

  .COP{
    justify-content: right;
    text-align: right;
    padding-right: 6px;
  }
 
  label {
    padding-left: 0px;
    display: flex;
    font-size: 10px;
    text-align: 'center';
  }
  select {
    
    background: ${props => (props.isView ? '#FFF' : '#cee1ed')};
    
  
}
  input {
    text-align: center;
    width: 100%;
    border-bottom: 0px solid black;
    border-color: #1a004a;
    background: ${props => (props.isView ? '#FFF' : '#cee1ed')};
    padding-left: 0px;
    /* margin-top: 0px; */
  }
  textarea {
    text-align: justify;
    width: 100%;
    border-bottom: 1px solid black;
    border-color: #1a004a;
    background: ${props => (props.isView ? '#FFF' : '#cee1ed')};
    resize: none;
    padding-left: 5px;
  }
  input.edit {
    background: #cee1ed;
  }
  input.disable {
    background: #fff;
  }
  h2 {
    font-size: 14px;
    font-weight: bold;
    text-align: 'center';
  }
  th {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    text-align: center;
    font-size: 9px;
    vertical-align: center;
    padding-left: 5px;
    input {
      width: 100%;
    }
  }

  tr {
    border-width: 0px;
    border-color: #1a004a;
    border-style: solid;
  }
  td {
    font-size: 9px;
    font-weight: 1000;
    text-align: center;
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
  }
  `

const Frame = styled.div`
  width: 20.3cm;
  padding: 2px;
  
  align-items: center;
  justify-content: left;
  border-width: 1px;
  border-top-width: 0px;
  border-bottom-width: 1px;
  border-color: #1a004a;
  border-style: solid;
  font-size: 9px;
  font-weight: 1000;
  
  table{
    width:100%
  }

  .rms {
    background: #f2f2f2;
  }
  label {
    padding-left: 5px;
    display: flex;
    font-size: 11px;
    font-weight: bold;
    text-align: 'center';
  }
  select {
    
    background: ${props => (props.isView ? '#FFF' : '#cee1ed')};
    
  
}
  input {
    text-align: center;
    width: 100%;
    border-bottom: 1px solid black;
    border-color: #1a004a;
    background: ${props => (props.isView ? '#FFF' : '#cee1ed')};
    padding-left: 5px;
  }
  textarea {
    text-align: justify;
    width: 100%;
    border-bottom: 1px solid black;
    border-color: #1a004a;
    background: ${props => (props.isView ? '#FFF' : '#cee1ed')};
    resize: none;
    padding-left: 5px;
  }
  input.edit {
    background: #cee1ed;
  }
  input.disable {
    background: #fff;
  }
  h2 {
    font-size: 14px;
    font-weight: bold;
    text-align: 'center';
  }
  th {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    text-align: left;
    vertical-align: center;
    padding-left: 5px;
    input {
      width: 100%;
    }
  }
  
  tr {
    border-width: 0px;
    border-color: #1a004a;
    border-style: solid;
  }
  td {
    border-width: 0px;
    border-color: #1a004a;
    border-style: solid;
  }
`

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgb(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalContainer = styled.div`
  
  width: 21cm;
  max-height: 90vh;
  min-height: 90vh;
  background: #fff;
  position: relative;
  border-radius: 5px;
  overflow-x: hidden;
  overflow-y: scroll;
  
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 0.3cm;

  @media print {
    padding-top: 20px;
    padding-left: 25px;
    max-height: 100vh;
    min-height: 100vh;
    min-height: 27.94cm;
    width: 100%;
    height: 27.94cm;
    /* Estilos para la versión impresa */
    overflow: hidden;
    /* Aquí ocultamos los componentes que no queremos imprimir */
    @page {
      size: letter; /* Puedes usar diferentes tamaños de papel como A4, Letter, etc. */
      margin: 0; /* Márgenes laterales en 0 */
      orientation: portrait ; /* Cambiar a 'landscape' si necesitas orientación horizontal */
    }
     
      /* Ajustar el área de impresión */
    .no-print {
      display: none;
    }
} 

`


const ModalHeader = styled.div`
  width: 20.3cm;
  display: flex;
  
  align-items: center;
  justify-content: space-between;

  td {
    font-weight: 5000;
    
  }
  th {

    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    
  }
  tr {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
  }
  table {
    border-width: 1px;
    border-bottom-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    text-align: center;
    vertical-align: center;
    font-size: 14px;
  }
  h3 {
    font-weight: 500;
    font-size: 16px;
    color: #1766dc;
  }
`

const CloseButtonModal = styled.div`
  position: absolute;
  top: 12px;
  right: 5px;
  width: 20px;
  height: 20px;
  border: none;
  margin: 0px;
  padding:0px;
  background: none;
  cursor: pointer;
  transition: 0.3s ease all;
  border-radius: 5px;
  color: #1766dc;
  &:hover {
    background: #f2f2f2;
    
  }
  svg {
    width: 100%;
    height: 100%;
  }
`

