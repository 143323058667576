import { getDocument } from 'pdfjs-dist'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Form } from 'react-bootstrap'
import { InputGroup } from 'react-bootstrap'

export function CotTable({ data, setData, isView, tableType, list }) {

    const [showSave, setShowSave] = useState(false)
    const [restart, setRestart] = useState(false)
    const [Data0, setData0] = useState('')
    const [Data1, setData1] = useState('')
    const [Data2, setData2] = useState('')
    const [Data3, setData3] = useState('')
    const [Data4, setData4] = useState('')
    const [Data5, setData5] = useState('')
    const select = useRef()
    const select2 = useRef()
    const [totalCotizacion, settotalCotizacion] = useState(0)
    let suma = 0

    const tableOption = {
        tableCotizacion: {
            headersTable: ['Item', 'Descripcion', 'Detalle', 'Precio', 'Cantidad', 'total'],
            dataTitle: ['EqNombres', 'EqApellidos', 'EqFechaNacimiento', 'EqSexo', 'EqParentesco'],
            selectTitle: "sexo"
        }
    }
    useEffect(() => {

        if (Array.isArray(data) && totalCotizacion === 0)
            settotalCotizacion(data.length > 0 ? data[data.length - 1].totalCot : 0)

    }, [data])

    const saveItem = () => {
        const totalc = parseInt(totalCotizacion) + parseInt(Data5)
        settotalCotizacion(totalc)
        if (tableType === 'tableCotizacion') var nuevoItem = { Descripcion: Data1, Detalle: Data2, Precio: Data3, Cantidad: Data4, total: Data5, totalCot: totalc }

        data[data.length === undefined ? 0 : data.length] = nuevoItem

        setData(data)
        setData5('')
        setData4('')
        setData3('')
        setData2('')
        setData1('')
        setShowSave(false)
        select.current.selectedIndex = 0


        setRestart(!restart)


    }

    const deleteItem = (e) => {
        const tableData = data
        let descuento = data[e.currentTarget.id].total
        tableData.splice(e.currentTarget.id, 1);


        let total = parseInt(totalCotizacion) - descuento
        settotalCotizacion(total)
        setRestart(!restart)
    }

    const handleChange = ({ target }) => {
        const { name, type, id, accessKey } = target
        setData5(Data3 * Data4)
        if (Data1 !== '' && Data2 !== '' && Data3 !== '' && Data4 !== '')
            setShowSave(true)
        else setShowSave(false)


    }
    return (
        <Frame>{<table style={{ width: '100%' }} >
            <thead >
                <tr>
                    <th scope="Col" style={{ width: '4%', textAlign: 'center' }}>{tableOption[tableType]["headersTable"][0]}</th>
                    <th scope="Col" style={{ width: '15%', textAlign: 'center' }}>{tableOption[tableType]["headersTable"][1]}</th>
                    <th scope="Col" style={{ width: '10%', textAlign: 'center' }}>{tableOption[tableType]["headersTable"][3]}</th>
                    <th scope="Col" style={{ width: '7%', textAlign: 'center' }}>{tableOption[tableType]["headersTable"][4]}</th>
                    <th scope="Col" style={{ width: '10%', textAlign: 'center' }}>{tableOption[tableType]["headersTable"][5]}</th>
                    <th scope="Col" style={{ width: '20%', textAlign: 'center' }}>{tableOption[tableType]["headersTable"][2]}</th>
                    {!isView && <th scope="Col" style={{ width: '5%' }}>Acciones</th>}
                </tr>
            </thead>
            <tbody>
                {
                    Array.isArray(data) && data?.map((item, i) => (
                        <tr key={i}>
                            <td>{i + 1}</td>
                            <td style={{ textAlign: 'left', paddingLeft: "15px" }}>{item.Descripcion}</td>
                            <td style={{ textAlign: 'right', paddingRight: "15px" }}>{new Intl.NumberFormat().format(item.Precio)}</td>
                            <td >{item.Cantidad}</td>
                            <td style={{ textAlign: 'right', paddingRight: "10px" }}>{new Intl.NumberFormat().format(item.total)}</td>
                            <td style={{ textAlign: 'left', paddingLeft: "15px" }}>{item.Detalle}</td>
                            {!isView &&
                                <td style={{ textAlign: 'center' }} role='button' id={i} onClick={deleteItem}>{!isView && <i style={{ color: 'red', fontSize: '20px' }} className="fa-solid fa-trash"></i>} </td>}</tr>))
                }{!isView && <Fragment>
                    <tr>
                        <td></td>
                        <td ><Form.Select id='select' size="sm" ref={select} onChange={(e) => {
                            setData1(e.target.value);
                            handleChange(e);

                        }}>
                            <option key={0}></option>
                            {list?.cotizacion.map((value, id) => {
                                return (<option value={value} key={id + 1}>
                                    {value}
                                </option>)
                            })}
                        </Form.Select>
                        </td>
                        <td><input name='Data3' id='na3' type={'number'} value={Data3} onChange={e => { setData3(e.target.value); handleChange(e) }} onMouseLeave={e => { setData3(e.target.value); handleChange(e) }} disabled={false} /></td>
                        <td><input name='Data4' id='na4' type={'number'} value={Data4} onChange={e => { setData4(e.target.value); handleChange(e) }} onMouseLeave={e => { setData4(e.target.value); handleChange(e) }} disabled={false} /></td>
                        <td><input name='Data5' id='na5' value={Data5} onMouseLeave={e => { setData5(e.target.value); handleChange(e) }} disabled={true} /></td>
                        <td><input name='Data2' id='na2' value={Data2} onChange={e => { setData2(e.target.value); handleChange(e) }} onMouseLeave={e => { setData2(e.target.value); handleChange(e) }} disabled={false} /></td>
                        <td style={{ textAlign: 'center' }} role='button'>{showSave && <i onClick={saveItem} style={{ color: 'green', fontSize: '23px' }} className="fa-solid fa-check"></i>}</td>
                    </tr>

                </Fragment>}
                <tr>
                    <th colSpan={4} style={{ textAlign: 'right', paddingRight: "5px" }} >

                        Total del servicio


                    </th><th style={{ textAlign: 'right', paddingRight: "10px" }} > {new Intl.NumberFormat('es-ES', { style: 'currency', maximumFractionDigits: 0, currency: 'COP' }).format(totalCotizacion)} </th>
                </tr>


            </tbody>
        </table>}

        </Frame>
    )
}

const Frame = styled.div`
input {
    border-bottom: 0px solid black;
  }
  input.disable {
    background: #fff;
    border-bottom: 0px solid black;
  }
  table {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    text-align: center;
    vertical-align: center;
    font-size: 14px;
  }
`