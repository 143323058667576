import { monedaCOP, porcentInput } from '../../../lib/functions'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'


export const RefundTable = ({ data = [], setData, isView, CeCo, tableType, list,  setSubtotal }) => {
    const [showSave, setShowSave] = useState(false)
    const [restart, setRestart] = useState(false)
    const [dataRow, setDataRow] = useState({ fecha: '', Factura: '', NIT: '', Beneficiario: '', Descripcion: '', CeCo, VrAntesIVA: '', ValorIVA: 0, _RteFte: '0%', ValorRteFte: 0, ValorRteIVA: 0, _RteICA: "0%", ValorRteICA: 0, VrNetoPagar: 0 })
    const [maxRow, setMaxRow] = useState(17)
    const subtotalSum = Object.values(data).reduce((total, item) => total + parseInt(item.VrNetoPagar), 0);
    const VrAntesIVA = Object.values(data).reduce((total, item) => total + parseInt( item.VrAntesIVA), 0);
    const ValorIVA = Object.values(data).reduce((total, item) => total + parseInt(item.ValorIVA), 0);
    const ValorRteFte = Object.values(data).reduce((total, item) => total + parseInt(item.ValorRteFte), 0);
    const ValorRteICA = Object.values(data).reduce((total, item) => total + parseInt(item.ValorRteICA), 0);
  
    const tableOption = {
        headersTable: ['fecha', 'Nº Factura', 'NIT', 'Beneficiario', 'Descripción', 'CeCo', 'Vr. antes IVA', 'Valor IVA', '%Rte Fte', 'Valor Rte Fte', '% Rte ICA', 'Valor Rte ICA', 'Vr Neto a Pagar'],
        dataTitle: ['fecha', 'Factura', 'NIT', 'Beneficiario', 'Descripcion', 'CeCo', 'VrAntesIVA', 'ValorIVA', '_RteFte', 'ValorRteFte', '_RteICA', 'ValorRteICA', 'VrNetoPagar'],
        width: ['8%', '8%', '9%', '16%', '16%', '5%', '7%', '6%', '4%', '6%', '4%', '6%', '10%'],
        disabled: [false, false, false, false, false, true, false, false, false, true, false, true, true],
        type: ['date', 'text', 'nit', 'text', 'text', 'text', 'number', 'number', 'porcent', 'number', 'porcent', 'number', 'number'],

    }
    useEffect(() => {
        setSubtotal(subtotalSum)

    }, [subtotalSum])
    
    const saveItem = () => {
        data.push(dataRow)
        setData(data)
        setDataRow({ fecha: '', Factura: '', NIT: '', Beneficiario: '', Descripcion: '', CeCo, VrAntesIVA: '', ValorIVA: 0, _RteFte: '0%', ValorRteFte: 0, ValorRteIVA: 0, _RteICA: "0%", ValorRteICA: 0, VrNetoPagar: 0 })
        setShowSave(false)
        setRestart(!restart)
    }
    const deleteItem = (e) => {
        const months = data
        months.splice(e.currentTarget.id, 1);
        setMaxRow(maxRow + 1)
        setRestart(!restart)
    }
    const handleChange = ({ target }) => {
        const { name, type, id, accessKey, value } = target
        const val = accessKey === 'porcent' ? porcentInput(value) : value
        let row = { ...dataRow, [name]: val }
        row.ValorRteFte = row.VrAntesIVA * row._RteFte.replace('%', '') / 100
        row.ValorRteICA = row.VrAntesIVA * row._RteICA.replace('%', '') / 100
        row['VrNetoPagar'] = parseInt(row.VrAntesIVA) + parseInt(row.ValorIVA)
        setDataRow(row)
        if (row.fecha !== '' && row.Factura !== '' && row.NIT !== '' && row.Beneficiario !== '' && row.Descripcion !== '' && row['VrNetoPagar'] !== 0)
            setShowSave(true)
        else setShowSave(false)
    }
//

    return (
        <FrameTable>{<table style={{ width: '100%' }}>
            <thead style={{ textAlign: 'center' }}>
             <tr >{tableOption.headersTable.map((item, key) =>
                    <th key={key} scope="Col" style={{textAlign: 'center' , width: tableOption["width"][key] }}>{item}</th>)}
                    {!isView && <th scope="Col" style={{ width: '7%' }}>Accion</th>}
                </tr>
            </thead>
            <tbody>
                {Array.isArray(data) && data?.map((item, key) => (
                 <tr key={key}>{e => setMaxRow(maxRow - 1)}
                        {tableOption.dataTitle.map((itemH, keyH)  =><td scope='row' className=' border-1' style={{border: '1px',paddingLeft:'3px', paddingRight:'3px',textAlign:tableOption.type[keyH]!=='text'?'end':'left'}}>{tableOption.type[keyH] === 'number' ? monedaCOP(item[itemH]) : item[itemH]}</td>)}
                        {!isView && <th style={{ textAlign: 'center' }} role='button' id={key} onClick={deleteItem}>{!isView && <><i style={{ color: 'red', fontSize: '12px' }} className="fa-solid fa-trash"></i></>}</th>}</tr>))
                }
                {!isView && <tr>
                    {tableOption.dataTitle.map((item, key) =><td key={key}><input maxLength={36}  disabled={tableOption.disabled[key]} accessKey={tableOption.type[key]} type={tableOption.type[key]} name={item} id={item} value={dataRow[item]} onChange={e => handleChange(e)}  ></input></td>)}
                <td style={{ textAlign: 'center' }} role='button'>{showSave && <i onClick={saveItem} style={{ color: 'green', fontSize: '23px' }} className="fa fa-solid fa-check"></i>}</td>
                </tr>}
                {Array.from({ length: maxRow }, (_, key) => <tr key={key} >
                    {tableOption.dataTitle.map((item, key)  =><td key={key} style={{ height: '16px',border: '1px solid black' }} >{' '} </td>)}
                    {!isView && <td ></td>}
                </tr>
                )
                }
            <tr>
                   <td colSpan={4} style={{borderBottom:'0px 0px 0px 0px', borderLeft:'0px solid black'}}></td>
                    <th colSpan={2} style={{borderBottom: '1px'}} >Subtotal</th>
                   <td style={{ textAlign: 'end' ,borderBottom: '0px'}}>{monedaCOP(VrAntesIVA)}</td>
                   <td style={{ textAlign: 'end', borderBottom: '0px' }} >{monedaCOP(ValorIVA)}</td>
                   <td style={{borderBottom: '0px'}}></td>
                   <td style={{ textAlign: 'end', borderBottom: '0px' }} >{monedaCOP(ValorRteFte)}</td>
                   <td style={{borderBottom: '0px'}} ></td>
                   <td style={{ textAlign: 'end', borderBottom: '0px' }} >{monedaCOP(ValorRteICA)}</td>
                    <th style={{ textAlign: 'end',borderBottom: '0px' }} >{monedaCOP(subtotalSum)}</th>
                </tr>
            </tbody>
        </table>}
        </FrameTable>
    )
}

const FrameTable = styled.div`
input {
    border-bottom: 0px solid black;
}
input:disabled {

    background: #fff;
    border-bottom: 0px solid black;
}
label{
    font-weight:10;
}
td{
    border: 1px solid black !important;
}
}
`



