import {  getEmployeeF } from '../../../humantalent/HumanTalent.request'
import { sessionActive } from '../../../lib/functions'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Col, Row } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { toast } from 'react-toastify'




const EmployeeInfo = ({ setLinkEmployee,  setEmployee_select_ID }) => {
  const [search, setSearch] = useState('')
  const [showFilterBox, setShowFilterBox] = useState(false)
  const [formats, setFormats] = useState([])
  const [employees, setEmployees] = useState(formats)
  const [options, setOptions] = useState(['creado', 'vinculado', 'desvinculado', 'retirado', 'eliminado'])

  useEffect(() => {
    sessionActive()
    fillTable()
  }, [])

  const fillTable = async () => {
    //Obtener lista de solicitudes de empleo
    const resEmployees = await getEmployeeF('EC')
    if (resEmployees.data.status === 200) {
      setFormats(resEmployees.data.data)
      setEmployees(resEmployees.data.data)
    } else {
      toast.error(resEmployees.data.msj)
    }
  }

  const columns = [
    {
      name: 'Id',
      cell: row => <div data-tag="allowRowEvents" >{row.IdContract_PK}</div>,
      selector: row => row.IdContract_PK,
      sortable: true,
      center:true,
      width:'60px'
    },
    {
      name: 'Nombre',
      cell: row => <div data-tag="allowRowEvents">{row.NombreTrabajador}</div>,
      selector: row => row.IdContract_PK,
      sortable: true,
      
      width:'180px'
    },
    {
      name: 'Apellido',
      cell: row => <div data-tag="allowRowEvents">{row.ApellidoTrabajador}</div>,
      selector: row => row.IdContract_PK,
      sortable: true,
      width:'180px'
    },
    {
      name: 'Estado',
      cell: row => <div data-tag="allowRowEvents">{row.Status}</div>,
      selector: row => row.IdContract_PK,
      sortable: true,
      center:true,
      width:'100px'
    },
    {
      name: 'Cargo',
      cell: row => <div data-tag="allowRowEvents">{row.cargo}</div>,
      selector: row => row.IdContract_PK,
      sortable: true,
      center:true,
      width:'300px'
    },    
  ]

  const paginationOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  }

  const handleSearch = ({ target }) => {
    setSearch(target.value)
    searcher(target.value)
   
  }

  const searcher = FilterText => {
    if (!FilterText) return setEmployees(formats)

    const result = formats.filter(formato =>
      formato.NombreTrabajador.toLowerCase().includes(FilterText.toLowerCase()) ||
      formato.Status.toLowerCase().includes(FilterText.toLowerCase()) ||
      formato.areaTrabajo.toLowerCase().includes(FilterText.toLowerCase()) ||
      formato.ApellidoTrabajador.toLowerCase().includes(FilterText.toLowerCase()) ||
      formato.fechaContratacion.toLowerCase().includes(FilterText.toLowerCase()) ||
      formato.cargo.toLowerCase().includes(FilterText.toLowerCase()) ||
      formato.areaTrabajo.toLowerCase().includes(FilterText.toLowerCase())
)
    setEmployees(result)
  }

  const handleFilter = () => {

    if (showFilterBox) {
      setEmployees(formats)
  
      setOptions(['solicitado', 'revisado', 'aprobado', 'eliminado', 'anulado'])
    }
    
    setShowFilterBox(!showFilterBox)
  }

  const conditionalRowStyles = [
    {
      when: row => row.toggleSelected,
      style: {
        backgroundColor: '#eeeeee',
        userSelect: 'none',
        cursor: 'pointer',
      },
    },
    {
      when: row => !row.toggleSelected,
      style: {
        cursor: 'pointer',
      },
    },
  ]

  const handleRowClicked = row => {
    setEmployee_select_ID(row)
    if (row.toggleSelected === true) setLinkEmployee(false)
    if (row.toggleSelected !== true) {
      options.filter(option => {
        if (row.Status.includes(option)) {
          if (option === 'creado') {
            setLinkEmployee(true)
          } else {
            setLinkEmployee(false)
          }
        }
      })
    }

    const updatedData = employees.map(item => {
      if (row.IdContract_PK !== item.IdContract_PK) {
        return {
          ...item,
          toggleSelected: row.toggleSelected ? !item : row.toggleSelected,
        }
      }

      return {
        ...item,
        toggleSelected: !item.toggleSelected,
      }
    })

    setEmployees(updatedData)
  }

  return (
    <div className="w-50 d-flex justify-content-center align-items-center flex-column w-50 overflow-hidden">
      <Row className="w-100 overflow-hidden">
        <Col>
          <Col className="d-flex flex-column align-items-center">
            <label>Empleado</label>
            <div className="w-100 d-flex gap-2 align-items-center justify-content-center">
              <Search placeholder="Busqueda" onChange={handleSearch} value={search} />
              <ReloadBtn>
                <i className="bi bi-arrow-counterclockwise" />
              </ReloadBtn>
              <ReloadBtn onClick={handleFilter}>
                <i className="fas fa-sliders-h" />
              </ReloadBtn>
            </div>
          </Col>
          
          <Row>
            <DataTable style={{ zIndex: '100' }}
              columns={columns}
              data={employees}
              pagination
              paginationComponentOptions={paginationOptions}
              noDataComponent={<span>No se encontraron elementos</span>}
              conditionalRowStyles={conditionalRowStyles}
              onRowClicked={handleRowClicked}
              fixedHeader= {true}
              fixedHeaderScrollHeight= {'600px'}
              />
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default EmployeeInfo





export const Search = styled.input`
  width: 96%;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 20px;
`

export const ReloadBtn = styled.button`
    background-color: #198754;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 8px;
    font-size: 13px;
    &:active {
        background-color: greenyellow;
    }
`