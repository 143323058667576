import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { sessionActive } from '../../../lib/functions'
import { Col, Row, Button, Form, Modal } from 'react-bootstrap'

import { GTHFORContract } from '../../../lib/formatos/GTHFORContract'

import { eraseFormatId, getFormats, getWorkflowByFormat, updateFormat, updateWorkflow, deleteWorkFlowById, CreateFormat, finishContractById } from '../../../lib/formatos/request'

export const ContractData = props => {
  const [isView, setIsView] = useState(true)
  const [action, setAction] = useState(true)
  const [restart, setRestart] = useState(false)
  const [IdFormat_PK, setIdFormat_PK] = useState('')
  const [showForm, setShowForm] = useState(false)
  const [data, setData] = useState({})
  const [isSU, setisSU] = useState(props.sessionUser.permission.superAdmin.access)
  const [Contracts, setContracts] = useState([])
  const [filterindItems, setFilterindItems] = useState([])
  const [ModalDelete, setModalDelete] = useState(false)
  const [search, setSearch] = useState()
  const [idDelete, setIdDelete] = useState()
  const [nameDelete, setNameDelete] = useState()
  const [WFDelete, setWFDelete] = useState()
  const [formatDelete, setFormatDelete] = useState()
  const [employee, setEmployee] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [idFinish, setIdFinish] = useState()


  useEffect(() => {
    if (!restart) {
      ClearSearch()
      setRestart(!restart)
      sessionActive()
      fillTable()
    }
  }, [restart, props.restartContract])

  const fillTable = async () => {
    //Obtener lista de solicitudes de empleo
    const resContracts = await getFormats('employeecontract')
    if (resContracts.data.status === 200) {
      setContracts(resContracts.data.data)
      setFilterindItems(resContracts.data.data)
    } else {
      toast.error(resContracts.data.msj)
    }
    const resEmployee = await getFormats('employee')
    if (resEmployee.data.status === 200) {
      setEmployee(resEmployee.data.data)
    }
  }

  const editRow = row => {

    if (props.put) {

      if (props.isTH && row.Status === 'creado') return true
      else return false
    }
    return false
  }
  const deleteRow = row => {

    if (props.delete && props.isTH && row.Status === 'creado') {
      return true

    }
    return false
  }
  const finishRow = row => {

    if (props.delete && props.isTH && row.Status === 'activo') {
      return true

    }
    return false
  }

  const finishContract = async () => {

    const resDelele = await finishContractById(idFinish, idDelete)
    if (resDelele.data.status === 200) {
      props.setRestartView(!props.restartView)
      toast.success(resDelele.data.msj)
    } else {
      toast.error(resDelele.data.msj)
    }
    fillTable()
  }

  const deleteContract = async () => {

    const resDelele = await eraseFormatId('employeecontract', idDelete)
    if (resDelele.data.status === 200) {

      let res = await updateFormat({ IdFormat_PK: WFDelete, Status: "disponible" }, "GTHFOR086")
      res = await (await getWorkflowByFormat(formatDelete, idDelete, 'pendiente')).data.data[0].IdWorkFlow_PK
      res = await deleteWorkFlowById(res)
      props.setRestartView(!props.restartView)
      toast.success(resDelele.data.msj)
    } else {
      toast.error(resDelele.data.msj)
    }
    fillTable()
  }

  const checkRow = row => {

    return employee.find(item => item.IdEmployee_FK === row.IdEmployee_FK) === undefined

  }

  const checkContract = async (data) => {

    let lugarNacimiento = data.lugarFechaNacimiento
    delete data.lugarFechaNacimiento
    delete data.empleadorId_FK
    delete data.SocialName
    delete data.DomicilioEmpleador
    delete data.especialidadCurso
    delete data.grupo
    delete data.institucion
    delete data.Clausulas
    delete data.FirmaEmpleado
    delete data.FirmaTestigo
    delete data.FirmaGerencia
    let result = await CreateFormat({ ...data, Status: 'activo', lugarNacimiento: lugarNacimiento }, 'employee')
    if (result.data.status === 200) {
      toast.success('Se creo usuario en BD.employye satisfactoriamente')
    } else {
      toast.error(result.data.msj)
    }


    setRestart(!restart)

  }
  const OnFilter = e => {
    let FilterText = e.target.value
    setSearch(FilterText)
    setFilterindItems(
      Contracts.filter(
        formato => formato.IdEmployee_FK.includes(FilterText) ||
          formato.NombreTrabajador.toLowerCase().includes(FilterText.toLowerCase()) ||
          formato.Status.toLowerCase().includes(FilterText.toLowerCase()) ||
          formato.areaTrabajo.toLowerCase().includes(FilterText.toLowerCase()) ||
          formato.ApellidoTrabajador.toLowerCase().includes(FilterText.toLowerCase()) ||
          formato.fechaContratacion.toLowerCase().includes(FilterText.toLowerCase()) ||
          formato.cargo.toLowerCase().includes(FilterText.toLowerCase()) ||
          formato.areaTrabajo.toLowerCase().includes(FilterText.toLowerCase())
      )
    )
  }

  const ClearSearch = () => {
    setSearch('')
    setFilterindItems(Contracts)
  }

  const handleChange = (data) => {
    setIdFormat_PK(data.IdFormat_PK)
    setData(data)
    setShowForm(!showForm)
    setRestart(false)
    setIsView(true)


  }


  const columns = [
    {
      name: 'id',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.IdFormat_PK}
        </div>
      ),
      selector: row => row.IdFormat_PK,
      sortable: true,
      width: '80px'
    },
    {
      name: 'Documentos',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.IdEmployee_FK}
        </div>
      ),
      selector: row => row.IdFormat_PK,
      sortable: true,
      width: '120px'
    },
    {
      name: 'Nombre',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.NombreTrabajador}
        </div>
      ),
      selector: row => row.IdFormat_PK,
      sortable: true,
    },
    {
      name: 'Apellido',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.ApellidoTrabajador}
        </div>
      ),
      selector: row => row.IdFormat_PK,
      sortable: true,
    },
    {
      name: 'Cargo',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.cargo}
        </div>
      ),
      selector: row => row.IdContract_PK,
      sortable: true,
      grow: 1.5,
    },
    {
      name: 'Area Trabajo',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.areaTrabajo}
        </div>
      ),
      selector: row => row.areaTrabajo,
      sortable: true,
    },
    {
      name: 'Fecha Contratación',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.fechaContratacion}
        </div>
      ),
      selector: row => row.IdContract_PK,
      sortable: true,
    },
    {
      name: 'Estado',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {checkRow(row) ? `(${row.Status})` : row.Status}
        </div>
      ),
      selector: row => row.IdContract_PK,
      sortable: true,
      grow: 1,
    },
    {
      name: 'Acciones',
      cell: row => (
        <div className="tabla dropdown dropstart">
          <i
            className="bi bi-three-dots-vertical"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          />
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <Link
                onClick={() => {
                  setIdFormat_PK(row.IdFormat_PK)
                  setData(row)
                  setShowForm(!showForm)
                  setRestart(false)
                  setIsView(false)
                  setIsEdit(true)

                }}
                className={'dropdown-item text-center border-end' + (editRow(row) ? '' : ' disabled')}
                to="/humantalent"
              >
                <i className="fas fa-edit me-3 "></i>Editar
              </Link>
            </li><li>
              <Link
                className={'dropdown-item text-center border-end' + (finishRow(row) ? '' : ' disabled')}
                to="/humantalent"
                onClick={() => {
                  setIdDelete(row.IdFormat_PK)
                  setIdFinish(row.IdEmployee_FK)      
                  setFormatDelete(row.TipoFormato)
                  setWFDelete(row.EmpSol)
                  setNameDelete(row.NombreTrabajador + " " + row.ApellidoTrabajador)
                  setRestart(false)
                  setModalDelete(true)
                  setAction('Terminar')
                  props.setRestartView(!props.restartView)
                }}
              >
                <i className="bi bi-file-earmark-excel me-3"></i>Terminar
              </Link>
            </li>
            <li>
              <Link
                className={'dropdown-item text-center border-end' + (deleteRow(row) ? '' : ' disabled')}
                to="/humantalent"
                onClick={() => {
                  setIdDelete(row.IdFormat_PK)
                  setFormatDelete(row.TipoFormato)
                  setWFDelete(row.EmpSol)
                  setNameDelete(row.NombreTrabajador + " " + row.ApellidoTrabajador)
                  setRestart(false)
                  setModalDelete(true)
                  setAction('Borrar')
                  props.setRestartView(!props.restartView)
                }}
              >
                <i className="bi bi-trash me-3"></i>Borrar
              </Link>
            </li>
            {isSU && <li>
              <Link
                className={'dropdown-item text-center border-end' + (checkRow(row) ? '' : ' disabled')}
                to="/humantalent"
                onClick={() => {
                  checkContract(row)
                }}
              >
                <i className="bi bi-check me-3"></i>Validar
              </Link>
            </li>}
          </ul>
        </div>
      ),
      button: true,
    },
  ]

  return (
    <>
      <Row>
        <Col>

          <Col className="d-flex">
            <Form.Control
              style={{ widht: "100%" }}
              type="text"
              id="search"
              placeholder="Búsqueda"
              aria-label="search input"
              value={search}
              onChange={OnFilter}

            />
            <Button data-tip="Limpiar Filtro" className="btn btn-success btn-sm" type="button" onClick={ClearSearch}>
              <i className="bi bi-arrow-counterclockwise" />
            </Button>
          </Col>
          <Row>
            <DataTable
              fixedHeader={false}
              fixedHeaderScrollHeight={{ fixedHeaderScrollHeight: '100px' }}
              columns={columns}
              data={filterindItems}
              pagination
              responsive
              dense={props.dense}
              highlightOnHover
              pointerOnHover
              noDataComponent="No hay registros para mostrar"
              paginationComponentOptions={{
                rowsPerPageText: 'Filas por página:',
                rangeSeparatorText: 'de',
              }}
            />

          </Row>
        </Col>

      </Row>
      {showForm && <GTHFORContract
        show={showForm}
        hide={setShowForm}
        data={data}
        isView={isView}
        isEdit={isEdit}
        setData={setData}
        IdFormat_PK={IdFormat_PK}
        setRestart={setRestart}
        restart={restart}
        sessionUser={props.sessionUser}
      />}
      <Modal
        show={ModalDelete}
        onHide={() => {
          setModalDelete(false)
        }}
        centered
      >
        <Modal.Header closeButton>
          <h5>{action} Contrato</h5>
        </Modal.Header>
        <Modal.Body>
          <p>¿Realmente desea {action} el contrato {idDelete} de {nameDelete}?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {

              setModalDelete(false)
            }}
          >
            Cancelar
          </Button>
          <Button className="btn btn-danger"
            onClick={() => {
              if (action === 'Borrar')
                deleteContract()
              else
                finishContract()
              setModalDelete(false)
            }}
          >
            {action}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ContractData
