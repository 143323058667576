import { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import {  sessionActive } from '../lib/functions'
import { getFullData } from '../lib/request'
import UserWorkflow from './workflow/userWorkflow'

import PersonalTab from './personalTab/PersonalTab'
import CompanyTab from './companyTap/CompanyTab'


export default function Selfservice() {
  const [put, setPut] = useState(false);
  const [_delete, set_delete] = useState(false);
  const [post, setPost] = useState(false);
  const [sessionUser, SetSessionUser] = useState(null)
  const userStatus = localStorage.getItem("Status")
 
  let count = 1
  useEffect(() => {   
    if(count ===1){
    sessionActive();
    Permissions();} 
    count= count + 1
  }, [])

  // function obtenerUbicacion() {
  //   if ("geolocation" in navigator) {
  //     navigator.geolocation.getCurrentPosition(
  //       (posicion) => {
  //         const latitud = posicion.coords.latitude;
  //         const longitud = posicion.coords.longitude;
  //         console.log("Latitud:", latitud);
  //         console.log("Longitud:", longitud);
  //       },
  //       (error) => {
  //         console.error("Error al obtener la ubicación:", error);
  //       }
  //     );
  //   } else {
  //     console.warn("La geolocalización no está disponible en este navegador.");
  //   }
  // }

  const Permissions = async () => {

  

    //Obtener perfil de usuario (contrato)
    var res = await getFullData("employee", "IdEmployee_FK", localStorage.getItem("IdUser_PK"))
    SetSessionUser(res.data.data[0])
    if ((res.data.data.length === 0))
      SetSessionUser({
        IdEmployee_FK: localStorage.getItem("IdUser_PK"),
        UserName: localStorage.getItem("UserName"),
        UserLastName: localStorage.getItem("UserLastName"),
        initials: localStorage.getItem("initials"),
      })
      setPut(true)
      set_delete(true)
      setPost(true)

  }

  return (
    <div className="page-content py-3  ">
      <Row >
        <Col style={{ height: '100%' }}>   
          <Card style={{ height: '100%'}}>
            <Tabs >
              <Card.Header className="card-tabs text-center  align-items-center">
                <TabList>
                  <h5 className='tabs-tittle'>Autogestión</h5>
                  <Tab>
                    <p>Workflow</p>
                  </Tab>
                  {userStatus==='active' && <>
                  <Tab>
                    <p>Personal</p>
                  </Tab>
                  
                  <Tab>
                    <p>Laboral</p>
                  </Tab></>}
                </TabList>
              </Card.Header>
              <Card.Body>

                <TabPanel>
                  <UserWorkflow sessionUser={sessionUser} dense={true}></UserWorkflow>

                </TabPanel>
                {userStatus==='active' && <>
                <TabPanel>
                  <PersonalTab
                    put={put}
                    delete={_delete}
                    post={post}
                    sessionUser={sessionUser}
                    route={'/'}
                    isSP={false}
                  />
                </TabPanel>
                <TabPanel>
                <CompanyTab
                    put={put}
                    delete={_delete}
                    post={post}
                    sessionUser={sessionUser}
                    
                    route={'/'}
                    isSP={false}
                  />
                </TabPanel></>}
                {/* <TabPanel>
                   <Assignment put={put} delete={_delete} post={post} sessionUser={sessionUser} newBotton={true} uniqueUser={true}></Assignment> 
                </TabPanel> */}
                  


              </Card.Body>
            </Tabs>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
