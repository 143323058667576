import axios from 'axios'
import ApiURL from '../../../services/apirest'


export const newEvent = async (title, start, end, IdUser_FK, type, IdFormat_FK, idEq, status) => {
    const config = {
      headers: { 'x-access-token': localStorage.getItem('TOKEN') },
    }
    const datos = {
      data: { title, start, end, IdUser_FK, type, IdFormat_FK, idEq, status }
    }
   
    const res = await axios.post(ApiURL + 'calendar', datos, config)
    if (res.data.status === 200) {
      const datos = {
        type,
        data: {
          IdEvent_FK: res.data.data.insertId,
          IdFormat_PK: IdFormat_FK
        },
      }
      const resEvent = await axios.put(ApiURL + 'formats', datos, config)
      return resEvent
    }
    else {
      return res
    }
  }
  export const updateEvent = async (IdEvent_FK, title, start, end, IdUser_FK, type, IdFormat_FK, idEq, status) => {
    
    const config = {
      headers: { 'x-access-token': localStorage.getItem('TOKEN') },
    }
    const datos = {
      data: { IdEvent_FK , title, start, end, IdUser_FK, type, IdFormat_FK, idEq, status }
    }
    console.log(datos);
    const  res = await axios.put(ApiURL + 'calendar', datos, config)
    return  res 
  }
  export const getEvents = async (type, IdFormat_FK, idEq) => {
    
    const config = {
      headers: { 'x-access-token': localStorage.getItem('TOKEN') },
    }
    const res = await axios.get(ApiURL + 'calendar/' + type + '/' + IdFormat_FK + '/' + idEq, config)
    
    return res.data.data
  }
  
  export const fillCalendar = async ( type = '@', IdFormat_FK = '@', IdEq = '@' ) =>{
    
  
  const eventsGet = await getEvents(type, IdFormat_FK, IdEq)
    return eventsGet
    /* console.log( 'evento',  ev);
      let calendarEvents =  [{
      title: "Conference",
      start: "2022-09-17",
      end: "2022-09-19"
    } , {
      title: "Conference",
      start: "2022-09-24",
      end: "2022-09-26"
    }] 
    console.log(type, IdFormat_FK, IdEq );
    const eventsGet = await getEvents(type, IdFormat_FK, IdEq)
    console.log(events)
    const lengthEvent = events.length
    eventsGet.map(
      (item, i) => {
        console.log(item, i);
        events[i+ lengthEvent ]={title:item.title, start: item.start,  end:item.end
       }
      }
    )
    console.log(events) */
  }
  