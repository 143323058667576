import { Accordion } from 'react-bootstrap'

import { useEffect } from 'react'
import Profile from './profile/Profile'




const PersonalTab = props => {
  const restartView = true
  const userStatus = localStorage.getItem("Status")


  useEffect(() => {

  }, [restartView])

  return (
    <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Mi perfil</Accordion.Header>
        <Accordion.Body>
          <Profile
            put={props.put}
            delete={props.delete}
            post={props.post}
            sessionUser={props.sessionUser}
            newBotton={false}
            dense={true}
            uniqueUser={false}
            route={'/humantalent'}
            isTH={true}
          />
        </Accordion.Body>
      </Accordion.Item>
      {userStatus === 'opay' && <>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Solicitud de permisos</Accordion.Header>
        <Accordion.Body>
          {/* <Vacation
            put={props.put}
            delete={props.delete}
            post={props.post}
            sessionUser={props.sessionUser}
            dense={true}
            newBotton={false}
            uniqueUser={false}
            route={'/humantalent'}
            isTH={true}
          /> */}
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2" >
        <Accordion.Header>Solicitud de Vacaciones</Accordion.Header>
        <Accordion.Body>
          {/* <Notice
            put={props.put}
            delete={props.delete}
            post={props.post}
            dense={true}
            sessionUser={props.sessionUser}
            setRestartView={setRestartView}
            restartView ={restartView}
            newBotton={false} /> */}
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>Solicitud de Certificado</Accordion.Header>
        <Accordion.Body>
          {/* <ContractAdd
            put={props.put}
            delete={props.delete}
            post={props.post}
            dense={true}
            sessionUser={props.sessionUser}
            setRestartView={setRestartView}
            restartView ={restartView}
            isTH={true}
          /> */}
        </Accordion.Body>
      </Accordion.Item></>}
    </Accordion>
  )
}
export default PersonalTab
