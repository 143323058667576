import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button, Col, Form, Row } from 'react-bootstrap'
import Moment from 'moment'
import { CreateFormat, getFormats, updateFormat } from './request'
import { CommTable } from '../../commercial/CommTable'
import { toast } from 'react-toastify'
import SpinnerLoad from '../spinnerLoad'
import image from '../../../../assets/img/Logo_b-st.png'

export const COMFORService = ({
show,
hide,
restart,
setRestart,
isView,
data = [],
sessionUser,
setData,
IdFormat_PK = "En Creación",
list
}) => {
  //-------------------------Info Formato
  const formato = 'COM-FOR-SERVICIOS'
  const revision = 'N/A'
  const fechaRev = 'N/A'
  const nombreFormato = 'INGRESO DE SERVCIOS'
  const typef = 'COMFORServicios'

  //-------------------------Registros
  const [currentUser, setCurrentUser] = useState('')
  const [date, setDate] = useState('')
  const [equipos, setequipos] = useState('')
  const [personas, setpersonas] = useState('')
  const [eqTable, setEqTable] = useState([])
  const [personalTable, setPersonalTable] = useState([])
  

  useEffect(() => {

          
      getInfo()
    
  }, [restart])


  const handleChange = async ({ target }) => {
    const { name, type } = target
    const value = type === 'checkbox' ? target.checked : target.value
    setData({ ...data, [name]: value })
  }

  const getInfo = async () => {
    const formatDate = Moment().format('YYYY-MM-DD')
    setDate(formatDate)
    
   if (data.Status!=='en creacion') {
     
    setEqTable(data.EqTable)
    setPersonalTable(data.PersonalTable)

    }




    return true
  }

  const clearStates = () => {
    data=[]
    setData('')
    setCurrentUser('')
    setDate('')
  }
  const onSubmit = async e => {
    e.preventDefault()

    var result = ''
    

    const temp = {
      ...data,
      EqTable: JSON.stringify(eqTable),
      PersonalTable: JSON.stringify(personalTable),
      Status:"Disponible",
      createdBy: sessionUser.IdEmployee_FK,
      createdDate: date
    }

   if(data.IdFormat_PK===undefined)
      result =  await CreateFormat(temp, typef)
    else
     result =  await updateFormat(temp, typef)

     if (result.data.status === 200){
      toast.success(result.data.msj)
     }
     else {
      toast.error(result.data.msj);
    } 

   
    clearStates()
    hide(!show)
    setRestart(!restart)
  }



  return (
    <>
      {show && (
        <Overlay style={{ zIndex: '1'}}>
           {IdFormat_PK!=='en creacion' && <SpinnerLoad trigger={data}/>}
          <ModalContainer>
            <ModalHeader>
              <table style={{ width: '100%'}}>
                <tbody>
                  <tr>
                  <th style={{ width: '20%', fontSize:'10px', verticalAlign: 'bottom' }} rowSpan={3}>
                      <img
                        className="format_logo"
                        src={image}
                        style={{ paddingBottom: '7px', width: '75%', minWidth: "40px" }}
                        alt="Logo RMS"
                      /><br/>
                      NIT 830.507.705-2
                    </th>
                    <th style={{ width: '55%', height: '38px'}}>RELIABILITY MAINTENANCE SERVICES S.A.S</th>
                    <th style={{ width: '25%'}}>{formato}</th>
                  </tr>
                  <tr>
                    <th rowSpan={2}>{nombreFormato}</th>
                    <th style={{ fontSize: '10px'}}>Revisión: {revision}</th>
                  </tr>
                  <tr>
                    <th style={{ fontSize: '10px'}}>Fecha de revisión: {fechaRev}</th>
                  </tr>
                </tbody>
              </table>
            </ModalHeader>
            <CloseButtonModal onClick={() => hide(!show)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-x-square"
                viewBox="0 0 16 16"
              >
                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </CloseButtonModal>


            <Form onSubmit={onSubmit}>
              <Frame isView={isView}>
                <table style={{ width: '100%'}}>
                  <tbody>
                    <tr>
                      <th className="rms" colSpan={4} style={{ width: '100%'}}>
                        <h2 className="text-center">A. INFORMACIÓN GENERAL</h2>
                      </th>
                    </tr>
                    <tr>
                      <td style={{ width: '20%'}}>
                        <label>NÚMERO DE SERVICIO:</label>
                      </td>
                      <td style={{ width: '30%'}}>
                        <input
                          type="number"
                          name="serviceNumber"
                          value={data.IdFormat_PK}
                          required
                          disabled
                        />
                      </td>
                      <td style={{ width: '20%'}}><label>ESTADO:</label></td>

                      <td style={{ width: '30%'}}>
                        <input
                          name="serviceNumber"
                          value={data.Status}
                          required
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>NOMBRE:</label>
                      </td>
                      <td colSpan={3}>
                        <input
                          name="name"
                          value={data.name}
                          onChange={handleChange}
                          required
                          disabled={isView ? true : false}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>DISCIPLINA:</label>
                      </td>
                      <td colSpan={3}>
                        <input
                          name="discipline"
                          value={data.discipline}
                          onChange={handleChange}
                          required
                          disabled={isView ? true : false}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>ÁREA:</label>
                      </td>
                      <td colSpan={3}>
                        <input
                          name="area"
                          value={data.area}
                          onChange={handleChange}
                          required
                          disabled={isView}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>DESCRIPCIÓN:</label>
                      </td>
                      <td colSpan={3}>
                        <input
                          name="description"
                          value={data.description}
                          onChange={handleChange}
                          required
                          disabled={isView ? true : false}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: '20%'}}>
                        <label>CREADO POR:</label>
                      </td>
                      <td style={{ width: '30%'}}>
                        <input
                          type="number"
                          name="serviceNumber"
                          value={data.createdBy}
                          required
                          disabled
                        />
                      </td>
                      <td style={{ width: '20%'}}>
                        <label>FECHA CREACIÓN:</label>
                      </td>
                      <td style={{ width: '30%'}}>
                        <input
                          name="serviceNumber"
                          value={data.createdDate}
                          required
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: '23%'}}>
                        <label>OBSERVACIONES:</label>
                      </td>
                      <td colSpan={3}>
                        <textarea
                          style={{ width: '100%'}}
                          name="Observaciones"
                          value={data.Observaciones}
                          onChange={handleChange}
                          required
                          disabled={isView ? true : false}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Frame>
              <Frame isView={isView}>
                <table style={{ width: '100%'}}>
                  <thead>
                    <tr>
                      <th colSpan={8} className="rms" style={{ width: '20%'}}>
                        <h2 className="text-center">HERRAMIENTAS ESPECIALES</h2>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>

                      <td colSpan={8} style={{ width: '23%'}}>
                        <CommTable data={eqTable} setData={setEqTable} isView={isView} tableType={'tableEQ'} list={list.eqList}/>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Frame>
              <Frame isView={isView}>
                <table style={{ width: '100%'}}>
                  <thead>
                    <tr>
                      <th colSpan={8} className="rms" style={{ width: '20%'}}>
                        <h2 className="text-center">PERSONAL</h2>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan={8} style={{ width: '23%'}}>
                        <CommTable data={personalTable} setData={setPersonalTable} isView={isView} tableType={'tableEmployee'} list={list.personalList}/>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Frame>
              <Row>
                <Col></Col>
                <Col></Col>
                <Col></Col>
                <Col></Col>
                {!isView ? (
                  <>
                    <Col>
                      <Button
                        className="btn btn-danger"
                        onClick={() => {
                          clearStates()
                          hide(!show)
                        }}
                      >
                        Cancelar
                      </Button>
                    </Col>
                    <Col>
                      <Button type="submit" className="btn btn-success">
                        Guardar
                      </Button>
                    </Col>
                  </>
                ) : (
                  <Col>
                    <Button
                      className="btn btn-secundary"
                      onClick={() => {
                        clearStates()
                        hide(!show)
                      }}
                    >
                      Cerrar
                    </Button>
                  </Col>
                )}
              </Row>
            </Form>
          </ModalContainer>
        </Overlay>
      )}
    </>
  )
}

export default COMFORService

const Frame = styled.div`
  width: 950px;
  padding-bottom: 0px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: left;
  border-width: 2px;
  border-color: #1a004a;
  border-style: solid;
  font-size: 12px;
  font-weight: 100;
  .photo {
    background: #ebf1de;
  }
  .rms {
    background: #44bb7a;
  }
  label {
    padding-left: 5px;
    display: flex;
    font-size: 12px;
    font-weight: NORMAL;
    text-align: 'center';
  }
  input {
    text-align: center;
    width: 100%;
    border-bottom: 1px solid black;
    border-color: #1a004a;
    background: ${props => (props.isView ? '#FFF' : '#cee1ed')};

    padding-left: 5px;
    margin-top: 5px;
  }
  textarea {
    text-align: justify;
    width: 100%;
    border-bottom: 1px solid black;
    border-color: #1a004a;
    background: ${props => (props.isView ? '#FFF' : '#cee1ed')};

    padding-left: 5px;
    margin-top: 5px;
  }

  input.edit {
    background: #cee1ed;
  }
  input.disable {
    background: #fff;
  }
  h2 {
    font-size: 14px;
    font-weight: bold;
    text-align: 'center';
  }

  th {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    text-align: left;
    vertical-align: center;
    padding-left: 5px;

    input {
      width: 100%;
    }
  }
  tr {
    border-width: 0px;
    border-color: #1a004a;
    border-style: solid;
  }
`

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgb(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalContainer = styled.div`
  width: 1010px;
  max-height: 90vh;
  min-height: 90vh;
  background: #fff;
  position: relative;
  border-radius: 5px;
  overflow: scroll;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
`

const ModalHeader = styled.div`
  width: 950px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
  padding-bottom: 10px;

  th {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
  }
  tr {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
  }
  table {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    text-align: center;
    vertical-align: center;
    font-size: 14px;
  }
  h3 {
    font-weight: 500;
    font-size: 16px;
    color: #1766dc;
  }
`

const CloseButtonModal = styled.div`
  position: absolute;
  top: 2px;
  right: 5px;
  width: 20px;
  height: 20px;
  border: none;
  background: none;
  cursor: pointer;
  transition: 0.3s ease all;
  border-radius: 5px;
  color: #1766dc;
  &:hover {
    background: #f2f2f2;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`
