
import React, { useEffect,  useState } from 'react'
import styled from 'styled-components'
import SelectOption from '../../../lib/SelectOption'

export function BuyTable({ data = [], setData, isView, tableType, CeCo,  list }) {


    const ComprarPor = [
        { Tipo: 'Bogotá', id: '1' },
        { Tipo: 'Campo' , id: '2' }
      ]

    


    const [showSave, setShowSave] = useState(false)
    const [restart, setRestart] = useState(false)

    const [Data1, setData1] = useState('')
    const [Data2, setData2] = useState('')
    const [Data3, setData3] = useState('')
    const [Data4, setData4] = useState('')
    const [Data5, setData5] = useState('')
    const [Data7, setData7] = useState('')
    const [Data8, setData8] = useState('Bogotá')


    const tableOption = {
        tableBuy: {
            headersTable: ['Item', 'Cant.', 'Unidad', 'Ref.', 'Marca','Descripcion', 'CeCo', 'Linea Presuspuesto', 'Comprar Por' ],
            dataTitle: [ 'Item','Cantidad','Unidad' ,'Referencia', 'Marca' ,'Descripcion','CentroCosto' ,'LineaPresuspuesto', 'ComprarPor'],
            // selectTitle: "Seleccione"
        }
     }
   useEffect(() => {
    }, [restart,CeCo])
    

    const saveItem = () => {

        if (tableType === 'tableBuy') var nuevoItem = {  Cantidad: Data1, Unidad: Data2, Referencia: Data3, Marca: Data4, Descripcion: Data5,  LineaPresuspuesto: Data7, ComprarPor: Data8}

        data[data.length === undefined ? 0 : data.length] = nuevoItem
        
        setData(data)
        
        setData8('Bogotá')
        setData7('')
        
        setData5('')
        setData4('')
        setData3('')
        setData2('')
        setData1('')
        setShowSave(false)
        

        setRestart(!restart)
        

    }
    

    const deleteItem = (e) => {
        const tableData = data
        tableData.splice(e.currentTarget.id, 1);
        //setData(data);
        setRestart(!restart)
    }

    const handleChange = ({ target }) => {
        const { name, type, id, accessKey } = target
        const value = type === 'checkbox' ? target.checked : target.value
        if (id === 'tb') {
            data[accessKey][name.replace('_' + accessKey, '')] = value
            //setData(data)
            setRestart(!restart)
        }

        if (Data1 !== '' && Data2 !== '' && Data3 !== '' && Data4 !== '' && Data5 !== '' && Data7 !== ''  )
            setShowSave(true)
        else setShowSave(false)
    }

    return (
        <Frame>{<table style={{ width: '100%' }} >
            <thead style={{ textAlign: 'center' }}>
                <tr>
                    <th scope="Col" style={{ alignItems:'center', width: '3%' }}>{tableOption[tableType]["headersTable"][0]}</th>
                    <th scope="Col" style={{textAlign:'center', width: '7%' }}>{tableOption[tableType]["headersTable"][1]}</th>
                    <th scope="Col" style={{textAlign:'center', width: '12%' }}>{tableOption[tableType]["headersTable"][2]}</th>
                    <th scope="Col" style={{textAlign:'center', width: '11%',  height: 'auto' }}>{tableOption[tableType]["headersTable"][3]}</th>
                    <th scope="Col" style={{textAlign:'center',width: '10%',  height: 'auto' }}>{tableOption[tableType]["headersTable"][4]}</th>
                    <th scope="Col" style={{ textAlign:'center',width: '30%',  height: 'auto' }}>{tableOption[tableType]["headersTable"][5]}</th> 
                    <th scope="Col" style={{textAlign:'center', width: '6%' }}>{tableOption[tableType]["headersTable"][6]}</th>
                    <th scope="Col" style={{textAlign:'center', width: '14%',  height: 'auto'  }}>{tableOption[tableType]["headersTable"][7]}</th>
                    <th scope="Col" style={{textAlign:'center', width: '7%' }}>{tableOption[tableType]["headersTable"][8]}</th>
                    {!isView && <th scope="Col" style={{ width: '5%' }}>Acciones</th>}
                </tr>
            </thead>
            <tbody>
                {
                    Array.isArray(data) && data?.map((item, i) => (
                        <tr key={i} style={{ fontWeight: '350' }}>
                            <td style={{ textAlign:'center' }}>{i+1}</td> 
                            <td style={{ textAlign:'center' }}>{item[tableOption[tableType]["dataTitle"][1]]}</td>
                            <td style={{ textAlign:'center' }}>{item[tableOption[tableType]["dataTitle"][2]]}</td>
                            <td>{item[tableOption[tableType]["dataTitle"][3]]}</td>
                            <td>{item[tableOption[tableType]["dataTitle"][4]]}</td>
                            <td>{item[tableOption[tableType]["dataTitle"][5]]}</td>
                            <td style={{ textAlign:'center' }}>{CeCo}</td>
                            <td>{item[tableOption[tableType]["dataTitle"][7]]}</td>
                            <td>{item[tableOption[tableType]["dataTitle"][8]]}</td>
                             {!isView && <td style={{ textAlign: 'center' }} role='button' id={i} onClick={deleteItem}>{!isView && <>
                                <i style={{ color: 'red', fontSize: '20px' }} className="fa-solid fa-trash"></i>

                            </>} </td>}</tr>))
                }{!isView && <><tr>
                    <th></th>
                    <th><input type='number' name='Data1' id='na1' value={Data1} onChange={e => { setData1(e.target.value); handleChange(e) }} disabled={false}></input></th>
                   {/* <input name='Data2' id='na2' value={Data2} onChange={e => { setData2(e.target.value); handleChange(e) }} disabled={false} /> */}
                     <th  style={{fontSize:'5'}}><SelectOption type={"MU"} searchable={true} state={Data2} setState={setData2} required disabled={isView ? true : false} /></th> 
                    <th><input name='Data3' id='na3' value={Data3} onChange={e => { setData3(e.target.value); handleChange(e) }} disabled={false} /></th> 
                    <th><input name='Data4' id='na4' value={Data4} onChange={e => { setData4(e.target.value); handleChange(e) }} disabled={false} /></th>
                    <th><textarea name='Data5' id='na5' value={Data5} onChange={e => { setData5(e.target.value); handleChange(e) }} disabled={false} /></th>
                    <th>{CeCo}</th>  
                  
                    <th><input name='Data7' id='na7' value={Data7} onChange={e => { setData7(e.target.value); handleChange(e) }} disabled={false} /></th>
                    <th><select name='Data8' id='na8'  value={Data8} onChange={e => { setData8(e.target.value); handleChange(e) }} disabled={false} title="seleccione..">
                    
                          
                          {ComprarPor.map((article, i) => {
                            return (
                              <option key={i} value={article.Tipo} >{article.Tipo} </option>
                            );
                          })}
                     </select>
                    </th>
                    


                    <th style={{ textAlign: 'center' }} role='button'>{showSave && <i onClick={saveItem} style={{ color: 'green', fontSize: '23px' }} className="fa-solid fa-check"></i>}</th>
                </tr>
                </>}
            </tbody>
        </table>}

        </Frame>
    )
}

const Frame = styled.div`
input {
    border-bottom: 0px solid black;
  }
  input.disable {
    background: #fff;
    border-bottom: 0px solid black;
  }
`