import { Accordion } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import Vacant from './Vacant/Vacant'
import FunctionsManual from './functionsManual/FunctionsManual'
import ConditionChange from './conditionChange/ConditionChange'



export default function Reecruitment  ({ admin, put, _delete , post, sessionUser }) {
  const [restartView, setRestartView] = useState(true)
  const userStatus = localStorage.getItem("Status")


  useEffect(() => {
   
  }, [restartView])

  return (
    <Accordion >
      <Accordion.Item eventKey="0">
        <Accordion.Header>Perfil de cargo / manual de funciones</Accordion.Header>
        <Accordion.Body>
        <FunctionsManual admin={admin} put={put} _delete={_delete} post={post} sessionUser={sessionUser} newBotton={true} isTH={false} uniqueUser={true} route={"/leader"}></FunctionsManual>
                                                     
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="1">
        <Accordion.Header>Solicitud de Empleo </Accordion.Header>
        <Accordion.Body>
           <Vacant admin={admin} put={put} _delete={_delete} post={post} sessionUser={sessionUser} newBotton={true} uniqueUser={true} route={"/leader"} isTH={false}></Vacant>
       
        </Accordion.Body>
      </Accordion.Item>

      {/* */}
      <Accordion.Item eventKey="2">
        <Accordion.Header>Cambio de Condición Contractual </Accordion.Header>
        <Accordion.Body>
           <ConditionChange  put={put} _delete={_delete} post={post} sessionUser={sessionUser} newBotton={true} dense={true} uniqueUser={true} route={"/leader"} isTH={false}></ConditionChange>
       
        </Accordion.Body>
      </Accordion.Item> 


    </Accordion>
  )
} 

