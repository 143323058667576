import { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'

import { decodeUserPermission, sessionActive } from '../lib/functions'
import { getFullData } from '../lib/request'
import User from './user/User'


export default function Users() {
  const [put, setPut] = useState(false)
  const [_delete, set_delete] = useState(false)
  const [post, setPost] = useState(false)
  const [sessionUser, setsessionUser] = useState()
  useEffect(() => {
    sessionActive()
    Permissions()
  }, [])

  const Permissions = async () => {
    //Obtener perfil de usuario (contrato)
    var res = await getFullData('employee', 'IdEmployee_FK', localStorage.getItem('IdUser_PK'))
    res = res.data.data[0]
    setsessionUser(res)
    //Obtener Permisos de Usuario
    const resPermission = decodeUserPermission(
      localStorage.getItem('userPermission'),
      localStorage.getItem('IdUser_PK')
    )

    if (resPermission.superAdmin.access) {
      //Obtener Permisos de Usuario
      setPut(true)
      set_delete(true)
      setPost(true)
    } else {
      setPut(resPermission.user.PUT)
      set_delete(resPermission.user.DELETE)
      setPost(resPermission.user.POST)
    }
  }

  return (
    <div className="page-content py-3">
      <Row>
        <Col>
          <Card>
            <Tabs>
              <Card.Header className="card-tabs text-center  align-items-center">
                <TabList>
                  <h5 className="tabs-tittle">Usuarios</h5>
                  <Tab>
                    <p>Internos</p>
                  </Tab>
                  <Tab>
                    <p>Externos</p>
                  </Tab>
                  
                  
                </TabList>
              </Card.Header>
              <Card.Body>
                <TabPanel>
                  <User
                    put={put}
                    delete={_delete}
                    post={post}
                    sessionUser={sessionUser}
                    newBotton={false}
                    
                  ></User>
                </TabPanel>
                <TabPanel>
                  <User
                    put={put}
                    delete={_delete}
                    post={post}
                    sessionUser={sessionUser}
                    newBotton={true}
                    
                  ></User>
                </TabPanel>
           
              </Card.Body>
            </Tabs>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
