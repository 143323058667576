import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button, Col, Form, Row } from 'react-bootstrap'
import Moment from 'moment'
import { CreateFormat, getFormats, updateFormat } from './request'
import image from '../../../../assets/img/Logo_b-st.png'
import { toast } from 'react-toastify'

export const Encuesta = ({
show,
hide,
restart,
setRestart,
isView,
data = [],
sessionUser,
setData,
IdFormat_PK = "En Creación",

}) => {
  //-------------------------Info Formato
  const formato = 'COM-FOR-144'
  const revision = 'N/A'
  const fechaRev = 'N/A'
  const nombreFormato = 'EVALUACIÓN DE SATISFACCIÓN DEL SERVICIO'
  const typef = 'encuestasatisfaccion'

  //-------------------------Registros
  const [status, setStatus] = useState('')
  const [currentUser, setCurrentUser] = useState('')
  const [date, setDate] = useState('')
  const [equipos, setequipos] = useState('')
  const [personas, setpersonas] = useState('')
  const [eqTable, setEqTable] = useState([])
  const [personalTable, setPersonalTable] = useState([])
  

  useEffect(() => {
    if (!restart) {
      
      setStatus('solicitado')
      setRestart(!restart)
      if (show) getInfo()
      
    }
    
  }, [restart])


  const handleChange = async ({ target }) => {
    const { name, type } = target
    const value = type === 'checkbox' ? target.checked : target.value
    setData({ ...data, [name]: value })
  }

  const getInfo = async () => {
    const formatDate = Moment().format('YYYY-MM-DD')
    setDate(formatDate)
   if (data.length!=0) {
     
    setEqTable(data.EqTable)
    setPersonalTable(data.PersonalTable)

    }
    // else{
    //   data["status"]="creación"
    //       console.log(data.status)
    // }



    return true
  }

  const clearStates = () => {
    data=[]
    setData('')
    setCurrentUser('')
    setDate('')
  }
  const onSubmit = async e => {
    e.preventDefault()

    var result = ''
    

    const temp = {
      ...data,
      
      // status:"Activo",
      // createdBy: sessionUser.IdEmployee_FK,
      // createdDate: date
    }

    if(data.IdFormat_PK===undefined)
       result =  await CreateFormat(temp, typef)
     else
      result =  await updateFormat(temp, typef)

    //  if (result.data.status === 200){
    //   toast.success(result.data.msj)
    //  }
    //  else {
     // toast.error(result.data.msj);
    //} 

   
    clearStates()
    hide(!show)
    setRestart(!restart)
  }



  return (
    <>
      {show && (
        <Overlay style={{ zIndex: '1'}}>
          <ModalContainer>
            <ModalHeader>
              <table style={{ width: '100%'}}>
                <tbody>
                  <tr>
                  <th style={{ width: '20%', fontSize:'10px', verticalAlign: 'bottom' }} rowSpan={3}>
                      <img
                        className="format_logo"
                        src={image}
                        style={{ paddingBottom: '7px', width: '75%', minWidth: "40px" }}
                        alt="Logo RMS"
                      /><br/>
                      NIT 830.507.705-2
                    </th>
                    <th style={{ width: '55%', height: '38px'}}>RELIABILITY MAINTENANCE SERVICES S.A.S</th>
                    <th style={{ width: '25%'}}>{formato}</th>
                  </tr>
                  <tr>
                    <th rowSpan={2}>{nombreFormato}</th>
                    <th style={{ fontSize: '10px'}}>Revisión: {revision}</th>
                  </tr>
                  <tr>
                    <th style={{ fontSize: '10px'}}>Fecha de revisión: {fechaRev}</th>
                  </tr>
                </tbody>
              </table>
            </ModalHeader>
            <CloseButtonModal onClick={() => hide(!show)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-x-square"
                viewBox="0 0 16 16"
              >
                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </CloseButtonModal>


            <Form onSubmit={onSubmit}>
              <Frame isView={isView}>
                <table style={{ width: '100%'}}>
                  <tbody>
                    <tr>
                    <Frame isView={isView}>
                <table style={{ width: '100%'}}>
                  <tbody>
                    <tr>
                      <th className="rms" colSpan={4} style={{ width: '100%'}}>
                        <h2 className="text-center">SOLICITUD</h2>
                      </th>
                    </tr>
                    <tr>
                      <td >
                        <label>Fecha:</label>
                      </td>
                      <td >
                        <input
                        type={'date'}
                          name="Fecha_Encuesta"
                          value={data.Fecha_Encuesta} 
                          onChange={handleChange}
                          required 
                          disabled={isView ? true : false}
                        />
                      </td>
                      <td ><label>Nombre:</label></td>
                      <td >
                        <input 
                          
                          name="Nombre"
                          value={data.Nombre}
                          onChange={handleChange}
                          required 
                          disabled={isView ? true : false}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td >
                        <label>Empresa:</label>
                      </td>
                      <td  >
                        <input
                        
                          name="Empresa"
                          value={data.Empresa} 
                          onChange={handleChange}
                          required 
                          disabled={isView ? true : false}
                        />
                      </td>
                      <td >
                        <label>Cargo:</label>
                      </td>
                      <td >
                        <input
                        
                          name="Cargo"
                          value={data.Cargo} 
                          onChange={handleChange}
                          required 
                          disabled={isView ? true : false}
                        />
                      </td>
                     
                    </tr>
                    <tr>
                      <td >
                        <label>Direccion:</label>
                      </td>
                      <td >
                        <input
                        
                          name="Direccion"
                          value={data.Direccion} 
                          onChange={handleChange}
                          required 
                          disabled={isView ? true : false}
                        />
                      </td>
                      <td >
                        <label>Contrato:</label>
                      </td>
                      <td >
                        <input
                          name="Contrato"
                          value={data.Contrato} 
                          onChange={handleChange}
                          required 
                          disabled={isView ? true : false}
                        />
                      </td>
                     
                    </tr>
                    <tr>
                      <td >
                        <label>Teléfono:</label>
                      </td>
                      <td >
                        <input
                        
                          name="Telefono"
                          value={data.Telefono} 
                          onChange={handleChange}
                          required 
                          disabled={isView ? true : false}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{width:'15%'}}>
                        <label>Alcance del servicio:</label>
                      </td>
                      <td >
                        <input
                        
                          name="Alcance"
                          value={data.Alcance} 
                          onChange={handleChange}
                          required 
                          disabled={isView ? true : false}
                        />
                      </td>
                    </tr>
                    <tr >
                      <td colSpan={4}>
                        <label>
                      Estimado cliente.Para nosotros es  importante conocer el nivel de su  satisfacción sobre  los  servicios suministrados por RMS S.A.  A continuación, por favor, indique la  importancia que tiene para usted el cumplimiento de los servicios.						
                      </label>
                      </td>
                    </tr>
                    <tr >
                      <td colSpan={4}>
                        <label style={{ paddingLeft:'25%'}} >
                        Escala de evaluación: Excelente (5), Muy Bueno (4), Bueno (3), Regular (2), Inaceptable (1).
                                  								
                      </label>
                      <label style={{fontWeight:'bold', paddingLeft:'30%'}}>
                        Por favor, justificar cada calificación en las observaciones
                      </label>
                      </td>
                    </tr>
                    
                  </tbody>
                </table>
              </Frame>
                    </tr>
                  </tbody>
                </table>
              </Frame>
              <Frame isView={isView}>
                <table style={{ width: '100%'}}>
                  <tbody>
                    <tr>
                      <th className="rms" colSpan={4} style={{ width: '50%'}}>
                        <h2 className="text-center">ASPECTOS DE EVALUACIÓN</h2>
                      </th>
                      <th className="rms" colSpan={2} style={{ width: '25%'}}>
                        <h2 className="text-center">CALIFICACION</h2>
                      </th>
                      <th className="rms" colSpan={2} style={{ width: '25%'}}>
                        <h2 className="text-center">OBSERVACIONES</h2>
                      </th>
                    </tr>
                    <tr>
                      <td colSpan={8}>
                        <label  style={{fontWeight:'bold'}}>
                          1. CUMPLIMIENTO DE ALCANCE DEL SERVICIO
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={4}>
                        <label >
                          1.1 ¿La calidad del servicio suministrado es satisfactoria?
                        </label>
                      </td>
                      <td colSpan={2}><input
                       type={'number'} min="1" max="5"
                       name="Cumplimiento1"
                       value={data.Cumplimiento1} 
                       onChange={handleChange}
                       required 
                       disabled={isView ? true : false}
                       ></input></td>

                      <td colSpan={2}>
                        <input 
                        
                        name="Observ1"
                        value={data.Observ1} 
                        onChange={handleChange}
                        
                        disabled={isView ? true : false} 
                        ></input></td>
                    </tr>

                    <tr>
                      <td colSpan={4}>
                        <label >
                          1.2 ¿El servicio prestado es oportuno?
                        </label>
                      </td>
                      <td colSpan={2}><input
                       type={'number'} min="1" max="5"
                       name="Cumplimiento2"
                       value={data.Cumplimiento2} 
                       onChange={handleChange}
                       required 
                       disabled={isView ? true : false}
                       ></input></td>
                      <td colSpan={2}>
                        <input 
                        
                        name="Observ2"
                        value={data.Observ2} 
                        onChange={handleChange}
                        
                        disabled={isView ? true : false} 
                        ></input></td>
                    </tr>
                      
                    <tr>
                      <td colSpan={4}>
                        <label >
                          1.3 ¿El servicio prestado es fiable?
                        </label>
                      </td>
                      <td colSpan={2}><input
                       type={'number'} min="1" max="5"
                       name="Cumplimiento3"
                       value={data.Cumplimiento3} 
                       onChange={handleChange}
                       required 
                       disabled={isView ? true : false}
                       ></input></td>
                      <td colSpan={2}>
                        <input 
                        
                        name="Observ3"
                        value={data.Observ3} 
                        onChange={handleChange}
                        
                        disabled={isView ? true : false} 
                        ></input></td>
                    </tr>
                      
                    <tr>
                      <td colSpan={4}>
                        <label >
                          1.4 ¿El servicio prestado satisface sus necesidades?
                        </label>
                      </td>
                      <td colSpan={2}><input
                       type={'number'} min="1" max="5"
                       name="Cumplimiento4"
                       value={data.Cumplimiento4} 
                       onChange={handleChange}
                       required 
                       disabled={isView ? true : false}
                       ></input></td>
                      <td colSpan={2}>
                        <input 
                        
                        name="Observ4"
                        value={data.Observ4} 
                        onChange={handleChange}
                        
                        disabled={isView ? true : false} 
                        ></input></td>
                    </tr>
                      
                    <tr>
                      <td colSpan={4}>
                        <label >
                          1.5 ¿Cómo califica el conocimiento del personal que atendio sus servicio?
                        </label>
                      </td>
                      <td colSpan={2}><input
                       type={'number'} min="1" max="5"
                       name="Cumplimiento5"
                       value={data.Cumplimiento5} 
                       onChange={handleChange}
                       required 
                       disabled={isView ? true : false}
                       ></input></td>
                     <td colSpan={2}>
                        <input 
                        
                        name="Observ5"
                        value={data.Observ5} 
                        onChange={handleChange}
                        
                        disabled={isView ? true : false} 
                        ></input></td>
                    </tr>
                      
                    <tr>
                      <td colSpan={4}>
                        <label >
                          1.6 ¿Como califica el cumplimiento en los tiempos de respuesta de la cotización?
                        </label>
                      </td>
                      <td colSpan={2}><input
                       type={'number'} min="1" max="5"
                       name="Cumplimiento6"
                       value={data.Cumplimiento6} 
                       onChange={handleChange}
                       required 
                       disabled={isView ? true : false}
                       ></input></td>
                      <td colSpan={2}>
                        <input 
                        
                        name="Observ6"
                        value={data.Observ6} 
                        onChange={handleChange}
                        
                        disabled={isView ? true : false} 
                        ></input></td>
                    </tr>
                      
                    <tr>
                      <td colSpan={4}>
                        <label >
                          1.7 ¿Como califica el cumplimiento en los tiempos de respuesta en el arranque?
                        </label>
                      </td>
                      <td colSpan={2}><input
                       type={'number'} min="1" max="5"
                       name="Cumplimiento7"
                       value={data.Cumplimiento7} 
                       onChange={handleChange}
                       required 
                       disabled={isView ? true : false}
                       ></input></td>
                      <td colSpan={2}>
                        <input 
                        
                        name="Observ7"
                        value={data.Observ7} 
                        onChange={handleChange}
                        
                        disabled={isView ? true : false} 
                        ></input></td>
                    </tr>
                      
                    <tr>
                      <td colSpan={4}>
                        <label >
                          1.8 ¿Cómo califica el cumplimiento en los tiempos de respuesta en la ejecución?
                        </label>
                      </td>
                      <td colSpan={2}><input
                       type={'number'} min="1" max="5"
                       name="Cumplimiento8"
                       value={data.Cumplimiento8} 
                       onChange={handleChange}
                       required 
                       disabled={isView ? true : false}
                       ></input></td>
                      <td colSpan={2}>
                        <input 
                        
                        name="Observ8"
                        value={data.Observ8} 
                        onChange={handleChange}
                        
                        disabled={isView ? true : false} 
                        ></input></td>
                    </tr>
                      
                    <tr>
                      <td colSpan={4}>
                        <label >
                          1.9 Cumplimiento con las especificaciones técnicas del producto y/o servicio
                        </label>
                      </td>
                      <td colSpan={2}><input
                       type={'number'} min="1" max="5"
                       name="Cumplimiento9"
                       value={data.Cumplimiento9} 
                       onChange={handleChange}
                       required 
                       disabled={isView ? true : false}
                       ></input></td>
                     <td colSpan={2}>
                        <input 
                        
                        name="Observ9"
                        value={data.Observ9} 
                        onChange={handleChange}
                        
                        disabled={isView ? true : false} 
                        ></input></td>
                    </tr>
                      
                    <tr>
                      <td colSpan={4}>
                        <label style={{width:'500px'}}>
                          1.10 Cumplimiento de las normas y procedimientos técnicos determinados por el cliente
                        </label>
                      </td>
                      <td colSpan={2}><input
                       type={'number'} min="1" max="5"
                       name="Cumplimiento10"
                       value={data.Cumplimiento10} 
                       onChange={handleChange}
                       required 
                       disabled={isView ? true : false}
                       ></input></td>
                      <td colSpan={2}>
                        <input 
                        
                        name="Observ10"
                        value={data.Observ10} 
                        onChange={handleChange}
                        
                        disabled={isView ? true : false} 
                        ></input></td>
                    </tr>
                  </tbody>
                </table>
              </Frame>
              <Frame isView={isView}>
                <table style={{ width: '100%'}}>
                  <tbody>
                    <tr>
                      <td colSpan={7}>
                        <label  style={{fontWeight:'bold'}}>
                          2. COMUNICACIÓN
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={7}>
                        <label  style={{fontWeight:'bold'}}>
                          ¿Cómo conoció a RMS S.A? Por favor marque  la casilla que corresponda
                        </label>
                      </td>
                    </tr>
                    <tr>
                    <td >  
                           <label style={{width:'30%', fontSize:'15px', }}>
                            Internet
                            </label>
                          </td>
                          <td>
                            <input 
                              type={'radio'}
                              name={'ComoConocioRMS'}
                              value={'Internet'}
                              checked={data['ComoConocioRMS'] === 'Internet' }
                              onChange={handleChange}
                            />
                            </td>
                            <td colSpan={4}></td> 
                    </tr>
                    <tr>
                    <td >  
                           <label style={{ fontSize:'15px', }}>
                            Prensa o revistas
                            </label>
                          </td>
                          <td>
                            <input 
                              type={'radio'}
                              name={'ComoConocioRMS'}
                              value={'Prensa'}
                              checked={data['ComoConocioRMS'] === 'Prensa' }
                              onChange={handleChange}
                            />
                            </td>
                            <td colSpan={4}></td>  
                    </tr>
                    <tr>
                    <td >  
                           <label style={{ fontSize:'15px', }}>
                            Representante commercial
                            </label>
                          </td>
                          <td>
                            <input 
                              type={'radio'}
                              name={'ComoConocioRMS'}
                              value={'Representante'}
                              checked={data['ComoConocioRMS'] === 'Representante' }
                              onChange={handleChange}
                            />
                            </td>
                            <td colSpan={4}></td>  
                    </tr>
                    <tr>
                    <td >  
                           <label style={{ fontSize:'15px', }}>
                            Amigos, colegas o contactos
                            </label>
                          </td>
                          <td>
                            <input 
                              
                              type={'radio'}
                              name={'ComoConocioRMS'}
                              value={'Contactos'}
                              checked={data['ComoConocioRMS'] === 'Contactos' }
                              onChange={handleChange}
                            />
                            </td>
                          <td colSpan={4} ></td> 
                    </tr>
                    <tr>
                    <td style={{width:'35%'}}>  
                           <label style={{ fontSize:'15px', }}>
                            Otro - ¿Cúal?
                            </label>
                          </td>
                          <td>
                            <input 
                              type={'radio'}
                              name={'ComoConocioRMS'}
                              value={'Otro'}
                              checked={data['ComoConocioRMS'] === 'Otro' }
                              onChange={handleChange}
                            />
                            </td>
                          <td ><input
                          name="Otro"
                          value={data.Otro}
                          onChange={handleChange}
                          
                          disabled={isView ? true : false}
                          ></input></td> 
                    </tr>
                  </tbody>
                </table>
              </Frame>
              <Frame isView={isView}>
                <table style={{ width: '100%'}}>
                  <tbody>
                    <tr>
                      <td colSpan={4}>
                        <label  style={{fontWeight:'bold'}}>
                          3. SERVICIOS ADICIONALES
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={4}>
                        <label  style={{fontWeight:'bold'}}>
                          Por favor, indique cuales de los siguientes servicios necesita su organización
                        </label>
                      </td>
                    </tr>
                    <tr>
                    <td >  
                           <label style={{width:'60%', fontSize:'15px', }}>
                            Gestion de activos
                            </label>
                          </td>
                          <td style={{width:'20%'}}>
                            <input 
                              type={'checkbox'}
                              name={'GestionActivos'}
                              value={data.GestionActivos}
                              checked={data.GestionActivos ? 'checked' : ''}
                              onChange={handleChange}
                            />
                            </td>
                             
                    </tr>
                    <tr>
                    <td >  
                           <label style={{ fontSize:'15px', }}>
                            Consultorias en Mantenimiento
                            </label>
                          </td>
                          <td>
                            <input 
                              type={'checkbox'}
                              name={'ConsultMantenimiento'}
                              value={data.ConsultMantenimiento}
                              checked={data.ConsultMantenimiento ? 'checked' : ''}
                              onChange={handleChange}
                            />
                            </td>
                              
                    </tr>
                    <tr>
                    <td >  
                           <label style={{ fontSize:'15px', }}>
                            Auditoria de Mantenimiento
                            </label>
                          </td>
                          <td>
                            <input 
                              type={'checkbox'}
                              name={'AuditMantenimiento'}
                              value={data.AuditMantenimiento}
                              checked={data.AuditMantenimiento ? 'checked' : ''}
                              onChange={handleChange}
                            />
                            </td>
                            
                    </tr>
                    <tr>
                    <td >  
                           <label style={{ fontSize:'15px', }}>
                            Integridad de plantas RBI
                            </label>
                          </td>
                          <td>
                            <input 
                              
                              type={'checkbox'}
                              name={'IntPlantasRBI'}
                              value={data.IntPlantasRBI}
                              checked={data.IntPlantasRBI ? 'checked' : ''}
                              onChange={handleChange}
                            />
                            </td>
                          
                    </tr>
                    <tr>
                    <td >  
                           <label style={{ fontSize:'15px', }}>
                            Operacion y Mantenimiento
                            </label>
                          </td>
                          <td>
                            <input 
                              
                              type={'checkbox'}
                              name={'Operacion_Manteni'}
                              value={data.Operacion_Manteni}
                              checked={data.Operacion_Manteni ? 'checked' : ''}
                              onChange={handleChange}
                            />
                            </td>
                           
                    </tr>
                    <tr>
                    <td >  
                           <label style={{ fontSize:'15px', }}>
                            Capacitacion y entrenamiento
                            </label>
                          </td>
                          <td>
                            <input 
                              
                              type={'checkbox'}
                              name={'Capacitacion'}
                              value={data.Capacitacion}
                              checked={data.Capacitacion ? 'checked' : ''}
                              onChange={handleChange}
                            />
                            </td>
                          
                    </tr>
                    <tr>
                    <td >  
                           <label style={{ fontSize:'15px', }}>
                            Planeacion y programacion de mantenimiento
                            </label>
                          </td>
                          <td>
                            <input 
                              
                              type={'checkbox'}
                              name={'Programacionmantenimiento'}
                              value={data.Programacionmantenimiento}
                              checked={data.Programacionmantenimiento ? 'checked' : ''}
                              onChange={handleChange}
                            />
                            </td>
                           
                    </tr>
                    <tr>
                    <td >  
                           <label style={{ fontSize:'15px', }}>
                           Planes de mantenimeinto (matriz de CBM)
                            
                            </label>
                          </td>
                          <td>
                            <input 
                              
                              type={'checkbox'}
                              name={'MantenimientoCBM'}
                              value={data.MantenimientoCBM}
                              checked={data.MantenimientoCBM ? 'checked' : ''}
                              onChange={handleChange}
                            />
                            </td>
                           
                    </tr>
                    <tr>
                    <td >  
                           <label style={{ fontSize:'15px', }}>
                           Mantenimiento predictivo (PDM)
                            (Vibraciones, desempeño reciprocante, termografia, ultrasonido, alineacion,analisis electrico,videoscopia etc.)
                            </label>
                          </td>
                          <td>
                            <input 
                              type={'checkbox'}
                              name={'MantenimientoPDM'}
                              value={data.MantenimientoPDM}
                              checked={data.MantenimientoPDM ? 'checked' : ''}
                              onChange={handleChange}
                            />
                            </td>
                          
                    </tr>
                    <tr>
                    <td >  
                           <label style={{ fontSize:'15px', }}>
                           Ensayos no destructivos NDT
                            </label>
                          </td>
                          <td>
                            <input 
                              
                              type={'checkbox'}
                              name={'EnsayosNDT'}
                              value={data.EnsayosNDT}
                              checked={data.EnsayosNDT ? 'checked' : ''}
                              onChange={handleChange}
                            />
                            </td>
                           
                    </tr>
                    <tr>
                    <td >  
                           <label style={{ fontSize:'15px', }}>
                           RCM, FMEA, RAM, FMECA, RCA, MIC
                            </label>
                          </td>
                          <td>
                            <input 
                              
                              type={'checkbox'}
                              name={'RCM_FMEA_RAM_FMECA_RCA_MIC'}
                              value={data.RCM_FMEA_RAM_FMECA_RCA_MIC}
                              checked={data.RCM_FMEA_RAM_FMECA_RCA_MIC ? 'checked' : ''}
                              onChange={handleChange}
                            />
                            </td>
                           
                    </tr>
                    <tr>
                    <td >  
                           <label style={{ fontSize:'15px', }}>
                           Salud Ocupacional  y Seguridad Industrial
                            </label>
                          </td>
                          <td>
                            <input 
                              
                              type={'checkbox'}
                              name={'SaludOc_seguridadInd'}
                              value={data.SaludOc_seguridadInd}
                              checked={data.SaludOc_seguridadInd ? 'checked' : ''}
                              onChange={handleChange}
                            />
                            </td>
                           
                    </tr>
                    <tr>
                    <td style={{width:'40%'}}>  
                           <label style={{ fontSize:'15px', }}>
                            Por favor especifique los temas de capacitacion
                            </label>
                          </td>
                        
                          <td ><input
                          name={'TemasCapacitacion'}
                          value={data.TemasCapacitacion}
                          onChange={handleChange}
                          
                          disabled={isView ? true : false}
                          >
                            </input></td> 
                    </tr>
                  </tbody>
                </table>
              </Frame>
              <Frame isView={isView}>
                <table style={{ width: '100%'}}>
                  <tbody>
                    <tr>
                      <td colSpan={8}>
                        <label  style={{fontWeight:'bold'}}>
                          4. RECOMENDACIONES
                        </label>
                      </td>
                    </tr>
                    <tr>
                      
                      <td colSpan={3}>
                        <textarea
                          style={{ width: '100%'}}
                          name="Recomendaciones"
                          value={data.Recomendaciones} //casilla adicional
                          onChange={handleChange}
                         required
                          disabled={isView ? true : false}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Frame>
              <Frame isView={isView}>
                <table style={{ width: '100%'}}>
                  <tbody>
                  <tr >
                      <td colSpan={4}>
                        <label style={{fontWeight:'bold', paddingLeft:'35%'}} >
                        GRACIAS POR AYUDARNOS A MEJORAR
                                  								
                      </label>
                      <label style={{ textAlign:'center'}}>
                      Agradecemos el diligenciamiento de este cuestionario y le solicitamos entregarlo de vuelta al área Comercial de RMS S.A, al mail electrónicos:marketing@rms.com.co
                      </label>
                      <label>    - info@rms.com.co</label>
                      </td>
                    </tr>
                    
                  </tbody>
                </table>
              </Frame>
              
              <Row>
                <Col></Col>
                <Col></Col>
                <Col></Col>
                <Col></Col>
                {!isView ? (
                  <>
                    <Col>
                      <Button
                        className="btn btn-danger"
                        onClick={() => {
                          clearStates()
                          hide(!show)
                        }}
                      >
                        Cancelar
                      </Button>
                    </Col>
                    <Col>
                      <Button type="submit" className="btn btn-success">
                        Guardar
                      </Button>
                    </Col>
                  </>
                ) : (
                  <Col>
                    <Button
                      className="btn btn-secundary"
                      onClick={() => {
                        clearStates()
                        hide(!show)
                      }}
                    >
                      Cerrar
                    </Button>
                  </Col>
                )}
              </Row>
            </Form>
          </ModalContainer>
        </Overlay>
      )}
    </>
  )
}

export default Encuesta

const Frame = styled.div`
          width: 100%;
          padding-bottom: 0px;
          margin-bottom: 10px;
          align-items: center;
          justify-content: left;
          border-width: 2px;
          border-color: #1a004a;
          border-style: solid;
          font-size: 12px;
          font-weight: 100;
          .photo{
            background:  #ebf1de
  }
          .rms{
            background:  #F2F2F2
  }
  label {
          padding-left: 4px;
          
          display: flex;
          font-size: 12px;
          font-weight: normal;
          text-align: 'center';
    
  }
  input {
          text-align: center;
          width: 100%;
          border-bottom: 1px solid black;
          border-color: #1a004a;
          background: ${props => (props.isView ? '#FFF' : '#cee1ed')};
          padding-left: 5px;
          /* margin-top: 5px; */
  }
  textarea {
    text-align: justify;
    width: 100%;
    min-height: 80px ;
    border-bottom: 1px solid black;
    border-color: #1a004a;
    background: ${props => (props.isView ? '#FFF' : '#cee1ed')};
    resize: none;
    
    
  } 
  

  input.edit {
    background: #cee1ed;
  }
  input.disable {
    background: #fff;
  }
  h2 {
    font-size: 14px;
    font-weight: bold;
    text-align: 'center';
  }
  h3 {
    font-size: 20px;
    font-weight: 600;
    padding: 8px;
  }
  p {
    font-size: 16px;
    font-weight: 500;
    padding: 10px;
  }

  th {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    text-align: left;
    vertical-align: center;
    padding-left: 5px;

    input {
      width: 100%;
    }
  }
  tr {
    border-width: 0px;
    border-color: #1a004a;
    border-style: solid;
  }
    
  td {
          border-width: 1px;
          border-color: #1a004a;
          border-style: solid;
  }
`

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgb(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalContainer = styled.div`
  width: 1000px;
  max-height: 90vh;
  min-height: 500px;
  background: #fff;
  position: relative;
  border-radius: 5px;
  overflow: scroll;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
`

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
  padding-bottom: 10px;

  th {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
  }
  tr {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
  }
  table {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    text-align: center;
    vertical-align: center;
    font-size: 14px;
  }
  h3 {
    font-weight: 500;
    font-size: 16px;
    color: #1766dc;
  }
`

const CloseButtonModal = styled.div`
  position: absolute;
  top: 2px;
  right: 5px;
  width: 20px;
  height: 20px;
  border: none;
  background: none;
  cursor: pointer;
  transition: 0.3s ease all;
  border-radius: 5px;
  color: #1766dc;
  &:hover {
    background: #f2f2f2;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`
