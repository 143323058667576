import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button, Col, Form, Row } from 'react-bootstrap'
import Moment from 'moment'
import { CreateFormat, getFormats, updateFormat,newWorkflow,updateWorkflow  } from './request'
// import { CommTable } from '../../commercial/CommTable'
import { toast } from 'react-toastify'
import Signer from '../signer'
import axios from 'axios'

export const OrdenServ = ({
show,
hide,
restart,
setRestart,
isView,
data = [],
sessionUser,
setData,
IdFormat_PK = "en creacion",
isTH, isRev, isApro, workflow,
serviceForm,
setServiceForm,
eqList,
personalList
}) => {
  //-------------------------Info Formato
  const formato = 'Orden de trabajo'
  const revision = 'N/A'
  const fechaRev = 'N/A'
  const nombreFormato = 'ORDEN DE TRABAJO'
  const typef = 'ordenservicio'
  const type = 'ordenservicio'

  //-------------------------Registros
  const [status, setStatus] = useState('')
  const [currentUser, setCurrentUser] = useState('')
  const [date, setDate] = useState('')
  const [archivos, setArchivos] = useState(null);
  const [IdUser_FK, setIdUser_FK] = useState('')
  const [firmaEnviado, setfirmaEnviado] = useState('')
  const [firmaCliente, setfirmaCliente] = useState('')
  const [firmaEjecutor, setfirmaEjecutor] = useState('')
  const [firmaRecibido, setfirmaRecibido] = useState('')
  const [signer, setsigner] = useState('')

  
  

  useEffect(() => {
    if (!restart) {
      clearStates()
      setStatus('solicitado')
      setRestart(!restart)
      if (show) getInfo()
      
    }
    
  }, [restart])


  const handleChange = async ({ target }) => {
    const { name, type } = target
    const value = type === 'checkbox' ? target.checked : target.value
    setData({ ...data, [name]: value })
  }

  const getInfo = async () => {
    const formatDate = Moment().format('YYYY-MM-DD')
    setDate(formatDate)

    if (IdFormat_PK === 'En Creación') {
      
      // const signAuth = await getSigner()
      // //setsigner(signAuth)
      // setfechaSolicitud(formatDate)
      
      // setIdUser_FK(sessionUser.IdEmployee_FK)
      // var firmas = []
      //  await JSON.parse(data.firmas)

      // setsigner(firmas)

      setStatus('solicitado')
      
    }


    if (data["Fecha_Ejec_Ord_Serv"] === undefined) {
      setData({...data, Fecha_Ejec_Ord_Serv: formatDate}) 

  }
    
   if (data.length!=0) {
     
   

    }
    // else{
    //   data["status"]="creación"
    //       console.log(data.status)
    // }



    return true
  }

  const clearStates = () => {
    data=[]
    setData('')
    setCurrentUser('')
    setDate('')
  }
  const onSubmit = async e => {
    e.preventDefault()


    var estado = 'solicitado'
    if (IdFormat_PK === 'en creacion') {
      workflow={TaskDescription: "Documento de prueba " ,
                Status:"Revisión",
                Format: typef,
                dateCreation:date,
                // IdEmployee_created:sessionUser.IdEmployee_FK,
                documents:"Solicitud de cotizacion",
                // IdEmployee_created:sessionUser.IdEmployee_FK,
                
                IdFormat_FK:""}
               
      
      
    } else if (isRev) {
      workflow.Status="Finalizado"
      workflow.dateFinish=date
      const resFinishWF =await updateWorkflow (workflow)
      workflow.Status="Aprobación"
      workflow.IdWorkFlow_PK=null
      workflow.dateFinish=null
      workflow.dateCreation=date
      // workflow.IdEmployee_FK= firmas.aprobador.IdEmployee_FK
      const resWorkflow = await newWorkflow(workflow)
      
      estado = 'revisado'

    } else if (isApro) {
      workflow.Status="Finalizado"
      workflow.dateFinish=date
      
      estado = 'aprobado'
      const resFinishWF =await updateWorkflow (workflow)
    }

    var result = ''
    var Cliente = {
      ApellidoTrabajador: sessionUser.ApellidoTrabajador,
      IdContract_PK: sessionUser.IdContract_PK,
      NombreTrabajador: sessionUser.NombreTrabajador,
      cargo: sessionUser.cargo,
      IdEmployee_FK: sessionUser.IdEmployee_FK,
      firma: firmaCliente,
      fecha: date,
    }
    var Enviado = {
      ApellidoTrabajador: sessionUser.ApellidoTrabajador,
      IdContract_PK: sessionUser.IdContract_PK,
      NombreTrabajador: sessionUser.NombreTrabajador,
      cargo: sessionUser.cargo,
      IdEmployee_FK: sessionUser.IdEmployee_FK,
      firma: firmaEnviado,
      fecha: date,
    }
    var Ejecutor = {
      ApellidoTrabajador: sessionUser.ApellidoTrabajador,
      IdContract_PK: sessionUser.IdContract_PK,
      NombreTrabajador: sessionUser.NombreTrabajador,
      cargo: sessionUser.cargo,
      IdEmployee_FK: sessionUser.IdEmployee_FK,
      firma: firmaEjecutor,
      fecha: date,
    }
    var Recibido = {
      ApellidoTrabajador: sessionUser.ApellidoTrabajador,
      IdContract_PK: sessionUser.IdContract_PK,
      NombreTrabajador: sessionUser.NombreTrabajador,
      cargo: sessionUser.cargo,
      IdEmployee_FK: sessionUser.IdEmployee_FK,
      firma: firmaRecibido,
      fecha: date,
    }
    var firmas = {...signer, Cliente, Enviado,Ejecutor,Recibido}


    firmas = JSON.stringify(firmas)
    const temp = {
      ...data,
      firmas,
      status:"Activo",
      // createdBy: sessionUser.IdEmployee_FK,
      
    }
if (IdFormat_PK === 'en creacion') {
      const result = await CreateFormat(data, typef)
     if (result.data.status === 200) {
       workflow["IdFormat_FK" ]=result.data.data.insertId
       const resWorkflow = await newWorkflow(workflow)
       
        if (resWorkflow.data.status === 200){
         toast.success(resWorkflow.data.msj)
        }
        else {
         toast.error(resWorkflow.data.msj);
       } 
     }else{
       toast.error(result.data.msj);
     } 
   } else {    
     data.IdFormat_PK = IdFormat_PK
     const result = await updateFormat(data, typef)
   }
   
    clearStates()
    hide(!show)
    setRestart(!restart)
  }



  return (
    <>
      {show && (
        <Overlay style={{ zIndex: '1'}}>
          <ModalContainer>
            <ModalHeader>
              <table style={{ width: '100%'}}>
                <tbody>
                  <tr>
                    <th style={{ width: '20%', verticalAlign: 'bottom'}} rowSpan={3}>
                      NIT 830.507.705-2
                    </th>
                    <th style={{ width: '55%', height: '38px'}}>RELIABILITY MAINTENANCE SERVICES S.A.S</th>
                    <th style={{ width: '25%'}}>{formato}</th>
                  </tr>
                  <tr>
                    <th rowSpan={2}>{nombreFormato}</th>
                    <th style={{ fontSize: '10px'}}>Revisión: {revision}</th>
                  </tr>
                  <tr>
                    <th style={{ fontSize: '10px'}}>Fecha de revisión: {fechaRev}</th>
                  </tr>
                </tbody>
              </table>
            </ModalHeader>
            <CloseButtonModal onClick={() => hide(!show)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-x-square"
                viewBox="0 0 16 16"
              >
                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </CloseButtonModal>


            <Form onSubmit={onSubmit}>
              <Frame isView={isView}>
                <table style={{ width: '100%'}}>
                  <tbody>
                    <tr>
                      <th className="rms" colSpan={4} style={{ width: '100%'}}>
                        <h2 className="text-center">DESCRIPCION DE LA ORDEN DE TRABAJO</h2>
                      </th>
                    </tr>
                    <tr>
                      <td style={{ width: '20%' }}>
                        <label>Fecha Solicitud</label>
                      </td>
                      <td>
                        <input className='disable' name='Fecha_Ejec_Ord_Serv' type={'date'} value={data.Fecha_Ejec_Ord_Serv} onChange={handleChange} disabled={true}></input>
                      </td>
                      <td style={{ width: '20%' }}>
                        <label>Estado Orden </label>
                      </td>
                      <td>
                        <input className='disable' name='status'  value={data.status} onChange={handleChange}  disabled={true} ></input>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: '20%' }}>
                        <label>Orden de servicio</label>
                      </td>
                      <td colSpan={3}>
                        <input name='OrdenServicio' value={data.OrdenServicio} onChange={handleChange} required disabled={isView ? true : false}></input>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: '20%' }}>
                        <label>Tipo de servicio</label>
                      </td>
                      <td colSpan={3}>
                        <input className='disable' name='TipoServicio' value={data.TipoServicio} onChange={handleChange} required disabled={true}></input>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: '20%' }}>
                        <label>Alcance</label>
                      </td>
                      <td colSpan={3}>
                        <input className='disable' name='Alcance' value={data.Alcance} onChange={handleChange} required disabled={true}></input>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Frame>
             
              <Frame isView={isView}>
                <table style={{ width: '100%'}}>
                  <tbody>
                    <tr>
                      <th className="rms" colSpan={6} style={{ width: '100%'}}>
                        <h2 className="text-center">INFORMACION DEL CLIENTE</h2>
                      </th>
                    </tr>
                    <tr>
                      <td style={{ width: '20%' }}>
                        <label>Razón social</label>
                      </td>
                      <td colSpan={5}>
                      <input className='disable' name='RazonSocial' value={data.RazonSocial} onChange={handleChange} required disabled={ true}></input>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: '20%' }}>
                        <label>Lugar de ejecucion</label>
                      </td>
                      <td colSpan={5}>
                      <input className='disable' name='LugarEjecucion' value={data.LugarEjecucion} onChange={handleChange} required disabled={true}></input>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: '20%' }}>
                        <label>Datos de contacto</label>
                      </td>
                      <td>
                      <input className='disable' name='Contacto' value={data.Contacto} onChange={handleChange} required disabled={true}></input>
                      </td>
                      <td style={{ width: '12%' }}>
                        <label>Telefono</label>
                      </td>
                      <td>
                      <input className='disable' name='Telefono' value={data.Telefono} onChange={handleChange} required disabled={true}></input>
                      </td>
                      <td style={{ width: '12%' }}>
                        <label>Email</label>
                      </td>
                      <td>
                      <input name='Email' value={data.Email} onChange={handleChange} required disabled={isView ? true : false}></input>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Frame>

              <Frame isView={isView}>
                <table style={{ width: '100%'}}>
                  <tbody>
                    <tr>
                      <th className="rms" colSpan={4} style={{ width: '100%'}}>
                        <h2 className="text-center">PERSONAL ASIGNADO</h2>
                      </th>
                    </tr>
                    <tr>
                      <td style={{ width: '20%' }}>
                        <label>Nombre</label>
                      </td>
                      <td>
                      <input name='Personal1' value={data.Personal1} onChange={handleChange} required disabled={isView ? true : false}></input>
                      </td>
                      <td style={{ width: '20%' }}>
                        <label>Nombre</label>
                      </td>
                      <td>
                      <input name='Personal2' value={data.Personal2} onChange={handleChange} required disabled={isView ? true : false}></input>
                      </td>
                    </tr>
                    
                    <tr>
                      <td style={{ width: '20%' }}>
                        <label>Cargo</label>
                      </td>
                      <td>
                        <input name='Cargo1' value={data.Cargo1} onChange={handleChange} required disabled={isView ? true : false}></input>
                      </td>
                      <td style={{ width: '20%' }}>
                        <label>Cargo</label>
                      </td>
                      <td>
                        <input name='Cargo2' value={data.Cargo2} onChange={handleChange} required disabled={isView ? true : false}></input>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: '20%' }}>
                        <label>Nombre</label>
                      </td>
                      <td>
                        <input name='Personal3' value={data.Personal3} onChange={handleChange} required disabled={isView ? true : false}></input>
                      </td>
                      <td style={{ width: '20%' }}>
                        <label>Nombre</label>
                      </td>
                      <td>
                      <input name='Personal4' value={data.Personal4} onChange={handleChange} required disabled={isView ? true : false}></input>
                      </td>
                    </tr>
                    
                    <tr>
                      <td style={{ width: '20%' }}>
                        <label>Cargo</label>
                      </td>
                      <td>
                        <input name='Cargo3' value={data.Cargo3} onChange={handleChange} required disabled={isView ? true : false}></input>
                      </td>
                      <td style={{ width: '20%' }}>
                        <label>Cargo</label>
                      </td>
                      <td>
                        <input name='Cargo4' value={data.Cargo4} onChange={handleChange} required disabled={isView ? true : false}></input>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Frame>

              <Frame isView={isView}>
                <table style={{ width: '100%'}}>
                  <tbody>
                    <tr>
                      <th className="rms" colSpan={4} style={{ width: '100%'}}>
                        <h2 className="text-center">DESCRIPCIÓN DE TRABAJO DE CAMPO</h2>
                      </th>
                    </tr>
                    <tr >
                      <td colSpan={4}>
                        <textarea name='Descripcion' value={data.Descripcion} onChange={handleChange} required disabled={isView ? true : false}>
                         
                      </textarea>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Frame>

              <Frame isView={isView}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <th colSpan={4} style={{ width: '52%' }}><label >Cliente</label></th>
                    <th colSpan={4} style={{ width: '52%' }}><label > Ejecutor RMS S.A</label></th>
                    </tr>
                    <tr>
                      <th colSpan={4} style={{ alignItems: 'center' }}>
                        <Signer
                          enable={ isView ? false : true}
                          sign={firmaCliente}
                          setSign={setfirmaCliente}
                          doc={type}
                          user={IdUser_FK}
                        />
                      </th>
                      <th colSpan={4} style={{ alignItems: 'center' }}>
                        <Signer
                          enable={ isView ? false : true}
                          sign={firmaEjecutor}
                          setSign={setfirmaEjecutor}
                          doc={type}
                          user={IdUser_FK}
                        />
                      </th>
                    </tr>
                    <tr>
                      <th colSpan={3} ><label>Nombre</label></th>
                      <td><input
                      name='Cliente' 
                      value={data.Cliente} 
                      onChange={handleChange} required
                       disabled={isView ? true : false}
                       ></input>
                       </td>
                      <th colSpan={3} ><label>Nombre</label></th>
                      <td><input
                      name='Ejecutor' 
                      value={data.Ejecutor} 
                      onChange={handleChange} required
                       disabled={isView ? true : false}
                       ></input>
                       </td>
                    </tr>
                    <tr>
                    <th colSpan={3} ><label>Cargo</label></th>
                    <td><input
                      name='CargoCliente' 
                      value={data.CargoCliente} 
                      onChange={handleChange} required
                       disabled={isView ? true : false}
                       ></input>
                       </td>
                      <th colSpan={3} ><label>Cargo</label></th>
                      <td><input
                      name='CargoEjecutor' 
                      value={data.CargoEjecutor} 
                      onChange={handleChange} required
                       disabled={isView ? true : false}
                       ></input>
                       </td>
                    </tr>
                    
                      
                    </tbody>
                </table>
              </Frame>

              <Frame isView={isView}>
                <table style={{ width: '100%'}}>
                  <tbody>
                    <tr>
                      <th className="rms" colSpan={4} style={{ width: '100%'}}>
                        <h2 className="text-center">DESCRIPCION DE LA ORDEN DE TRABAJO</h2>
                      </th>
                    </tr>
                    <tr>
                      <td style={{ width: '20%' }}>
                        <label>Orden de servicio</label>
                      </td>
                      <td>
                      <input className='disable' name='OrdenServicio' value={data.OrdenServicio} onChange={handleChange} required disabled={true}></input>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: '20%' }}>
                        <label>Tipo de servicio</label>
                      </td>
                      <td>
                      <input className='disable' name='TipoServicio' value={data.TipoServicio} onChange={handleChange} required disabled={true}></input>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: '20%' }}>
                        <label>Alcance</label>
                      </td>
                      <td>
                      <input className='disable' name='Alcance' value={data.Alcance} onChange={handleChange} required disabled={true}></input>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: '20%' }}>
                        <label>Descripcion</label>
                      </td>
                      <td>
                        <textarea className='disable' name='Descripcion' value={data.Descripcion} onChange={handleChange} required disabled={true}></textarea>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Frame>

              <Frame isView={isView}>
                <table style={{ width: '100%'}}>
                  <tbody>
                    <tr>
                      <th className="rms" colSpan={6} style={{ width: '100%'}}>
                        <h2 className="text-center">INFORMACION DEL CLIENTE</h2>
                      </th>
                    </tr>
                    <tr>
                      <td style={{ width: '20%' }}>
                        <label>Razón social</label>
                      </td>
                      <td colSpan={5}>
                      <input className='disable' name='RazonSocial' value={data.RazonSocial} onChange={handleChange} required disabled={true}></input>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: '20%' }}>
                        <label>Lugar de ejecucion</label>
                      </td>
                      <td colSpan={5}>
                      <input className='disable' name='LugarEjecucion' value={data.LugarEjecucion} onChange={handleChange} required disabled={true}></input>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: '20%' }}>
                        <label>Datos de contacto</label>
                      </td>
                      <td>
                      <input className='disable' name='Contacto' value={data.Contacto} onChange={handleChange} required disabled={true}></input>
                      </td>
                      <td style={{ width: '12%' }}>
                        <label>Telefono</label>
                      </td>
                      <td>
                      <input className='disable' name='Telefono' value={data.Telefono} onChange={handleChange} required disabled={true}></input>
                      </td>
                      <td style={{ width: '12%' }}>
                        <label>Email</label>
                      </td>
                      <td>
                      <input className='disable' name='Email' value={data.Email} onChange={handleChange} required disabled={true}></input>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Frame>

              <Frame isView={isView}>
                <table style={{ width: '100%'}}>
                  <tbody>
                    <tr>
                      <th className="rms" colSpan={5} style={{ width: '100%'}}>
                        <h2 className="text-center">PLANEACION</h2>
                      </th>
                    </tr>
                    <tr>
                      <td style={{ width: '20%' }}>
                        <label>Matiz de riesgos</label>
                      </td>
                      <td>
                      <input name='MatizRiesgos' value={data.MatizRiesgos} onChange={handleChange} required disabled={isView ? true : false}></input>
                      </td>
                      <td style={{ width: '20%' }}>
                        <label>Procedimientos</label>
                      </td>
                      <td>
                      <input name='Procedimientos' value={data.Procedimientos} onChange={handleChange} required disabled={isView ? true : false}></input>
                      </td>
                    </tr>
                    
                    <tr>
                      <td style={{ width: '20%' }}>
                        <label>Dias de campo</label>
                      </td>
                      <td>
                      <input name='DiasCampo' value={data.DiasCampo} onChange={handleChange} required disabled={isView ? true : false}></input>
                      </td>
                      <td style={{ width: '20%' }}>
                        <label>Dias de oficina</label>
                      </td>
                      <td>
                      <input name='DiasOficina' value={data.DiasOficina} onChange={handleChange} required disabled={isView ? true : false}></input>
                      </td>
                    </tr>
                   
                    <tr>
                      <td style={{ width: '20%' }}>
                        <label>Fecha de inicio</label>
                      </td>
                      <td>
                      <input name='FechaInicio' type={'date'} value={data.FechaInicio} onChange={handleChange} required disabled={isView ? true : false}></input>
                      </td>
                      <td style={{ width: '20%' }}>
                        <label>Fecha de finalizacion</label>
                      </td>
                      <td>
                      <input name='FechaFin' type={'date'} value={data.FechaFin} onChange={handleChange} required disabled={isView ? true : false}></input>
                      </td>
                    </tr>
                    
                    <tr >
                      <td style={{ width: '20%' }}>
                        <label>Observaciones</label>
                      </td>
                      <td colSpan={4}>
                        <textarea name='Observaciones' value={data.Observaciones} onChange={handleChange} required disabled={isView ? true : false}></textarea>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Frame>

              <Frame isView={isView}>
                <table style={{ width: '100%'}}>
                  <tbody>
                    <tr>
                      <th className="rms" colSpan={4} style={{ width: '100%'}}>
                        <h2 className="text-center">PERSONAL ASIGNADO</h2>
                      </th>
                    </tr>
                    <tr>
                      <td style={{ width: '20%' }}>
                        <label>Nombre</label>
                      </td>
                      <td>
                      <input className='disable' name='Personal1' value={data.Personal1} onChange={handleChange} required disabled={true}></input>
                      </td>
                      <td style={{ width: '20%' }}>
                        <label>Nombre</label>
                      </td>
                      <td>
                      <input className='disable' name='Personal2' value={data.Personal2} onChange={handleChange} required disabled={true}></input>
                      </td>
                    </tr>
                    
                    <tr>
                      <td style={{ width: '20%' }}>
                        <label>Cargo</label>
                      </td>
                      <td>
                        <input className='disable' name='Cargo1' value={data.Cargo1} onChange={handleChange} required disabled={true}></input>
                      </td>
                      <td style={{ width: '20%' }}>
                        <label>Cargo</label>
                      </td>
                      <td>
                        <input className='disable' name='Cargo2' value={data.Cargo2} onChange={handleChange} required disabled={true}></input>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: '20%' }}>
                        <label>Nombre</label>
                      </td>
                      <td>
                        <input className='disable' name='Personal3' value={data.Personal3} onChange={handleChange} required disabled={true}></input>
                      </td>
                      <td style={{ width: '20%' }}>
                        <label>Nombre</label>
                      </td>
                      <td>
                      <input className='disable' name='Personal4' value={data.Personal4} onChange={handleChange} required disabled={true}></input>
                      </td>
                    </tr>
                    
                    <tr>
                      <td style={{ width: '20%' }}>
                        <label>Cargo</label>
                      </td>
                      <td>
                        <input className='disable' name='Cargo3' value={data.Cargo3} onChange={handleChange} required disabled={true}></input>
                      </td>
                      <td style={{ width: '20%' }}>
                        <label>Cargo</label>
                      </td>
                      <td>
                        <input className='disable' name='Cargo4' value={data.Cargo4} onChange={handleChange} required disabled={true}></input>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Frame>

              <Frame isView={isView}>
                <table style={{ width: '100%'}}>
                  <tbody>
                    <tr>
                      <th className="rms" colSpan={4} style={{ width: '100%'}}>
                        <h2 className="text-center">DESCRIPCIÓN DE TRABAJO DE CAMPO</h2>
                      </th>
                    </tr>
                    <tr >
                      <td colSpan={4}>
                        <label>De acuerdo al trato establecido por el cliente</label>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Frame>

              <Frame isView={isView}>
                <table style={{ width: '100%'}}>
                  <tbody>
                    <tr>
                      <th className="rms" colSpan={5} style={{ width: '100%'}}>
                        <h2 className="text-center">ANEXOS A ENTREGAR</h2>
                      </th>
                    </tr>
                    <tr>
                      <td style={{ width: '20%' }}>
                        <label>Reporte de actividades</label>
                      </td>
                      <td>
                        <input type={'file'} name='ReporteActividades' value={data.ReporteActividades} onChange={handleChange}  ></input>
                        
                      </td>
                      <td style={{ width: '20%' }}>
                        <label>Acta final y/o parcial</label>
                      </td>
                      <td>
                        <input type={'file'}></input>
                        
                      </td>
                    </tr>
                    
                    <tr>
                      <td style={{ width: '20%' }}>
                        <label>Acta de inicio</label>
                      </td>
                      <td>
                        <input type={'file'}></input>
                        
                      </td>
                      <td style={{ width: '20%' }}>
                        <label>Aceptacion trabajo de campo</label>
                      </td>
                      <td>
                        <input type={'file'}></input>
                        
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: '20%' }}>
                        <label>Copia permiso de trabajo</label>
                      </td>
                      <td>
                        <input type={'file'}></input>
                        
                      </td>
                      <td style={{ width: '20%' }}>
                        <label>Legalizacion</label>
                      </td>
                      <td>
                        <input type={'file'}></input>
                        
                      </td>
                    </tr>
                    
                    <tr>
                      <td style={{ width: '20%' }}>
                        <label>Inspeccion de vehiculo</label>
                      </td>
                      <td>
                        <input type={'file'} ></input>
                        
                      </td>
                      <td colSpan={2}>
                        <label></label>
                      </td>
                      
                    </tr>
                  </tbody>
                </table>
              </Frame>

              <Frame isView={isView}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <th colSpan={4} style={{ width: '52%' }}><label >Enviada por </label></th>
                    <th colSpan={4} style={{ width: '52%' }}><label > Recibida por </label></th>
                    </tr>
                    <tr>
                      <th colSpan={4} style={{ alignItems: 'center' }}>
                        <Signer
                          enable={ isView ? false : true}
                          sign={firmaEnviado}
                          setSign={setfirmaEnviado}
                          doc={type}
                          user={IdUser_FK}
                        />
                      </th>
                      <th colSpan={4} style={{ alignItems: 'center' }}>
                      <Signer
                          enable={ isView ? false : true}
                          sign={firmaRecibido}
                          setSign={setfirmaRecibido}
                          doc={type}
                          user={IdUser_FK}
                        />
                      </th>
                    </tr>
                    <tr>
                      <th colSpan={3} ><label>Nombre</label></th>
                      <td>
                      <input name='EnviadaPor' value={data.EnviadaPor} onChange={handleChange} required disabled={isView ? true : false}></input>
                        </td>
                      <th colSpan={3} ><label>Nombre</label></th>
                      <td>
                      <input name='RecibidaPor' value={data.RecibidaPor} onChange={handleChange} required disabled={isView ? true : false}></input>
                        </td>
                    </tr>
                    <tr>
                    <th colSpan={3} ><label>Cargo</label></th>
                      <td>
                      <input name='EnviadaCargo' value={data.EnviadaCargo} onChange={handleChange} required disabled={isView ? true : false}></input>
                        </td>
                      <th colSpan={3} ><label>Cargo</label></th>
                      <td>
                      <input name='RecibidaCargo' value={data.RecibidaCargo} onChange={handleChange} required disabled={isView ? true : false}></input>
                        </td>
                    </tr>
                    
                      
                    </tbody>
                </table>
              </Frame>

              <Frame isView={isView}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <label>
                    0 Enviar solicitud al área de contabilidad (Para: auxcontable@rms.com.co; auxcontable2@rms.com.co CC: lruge@rms.com.co) <br/>
                   1 Enviar solicitud al área de compras (Para: compras@rms.com.co)<br/>
                   2 Enviar solictud a presidencia con copia a auxiliar administrativa (Para: efernandez@rms.com.co  CC: auxadminbta@rms.com.co)<br/>
                  3 Enviar solicitud a recursos humanos (Para: rrhh@rms.com.co CC: alopez@rms.com.co)<br/>
                    </label>
                    
                      
                    </tbody>
                </table>
              </Frame>
              
              
              <Row>
                <Col></Col>
                <Col></Col>
                <Col></Col>
                <Col></Col>
                {!isView ? (
                  <>
                    <Col>
                      <Button
                        className="btn btn-danger"
                        onClick={() => {
                          clearStates()
                          hide(!show)
                        }}
                      >
                        Cancelar
                      </Button>
                    </Col>
                    <Col>
                      <Button type="submit" className="btn btn-success">
                        Guardar
                      </Button>
                    </Col>
                  </>
                ) : (
                  <Col>
                    <Button
                      className="btn btn-secundary"
                      onClick={() => {
                        clearStates()
                        hide(!show)
                      }}
                    >
                      Cerrar
                    </Button>
                  </Col>
                )}
              </Row>
            </Form>
          </ModalContainer>
        </Overlay>
      )}
    </>
  )
}

export default OrdenServ

const Frame = styled.div`
          width: 100%;
          padding-bottom: 0px;
          margin-bottom: 10px;
          align-items: center;
          justify-content: left;
          border-width: 2px;
          border-color: #1a004a;
          border-style: solid;
          font-size: 12px;
          font-weight: 100;
          .photo{
            background:  #ebf1de
  }
          .rms{
            background:  #F2F2F2
  }
  label {
          padding-left: 5px;
          display: flex;
          font-size: 12px;
          font-weight: bold;
          text-align: 'center';
    
  }
  input {
          text-align: center;
          width: 100%;
          border-bottom: 1px solid black;
          border-color: #1a004a;
          background: ${props => (props.isView ? '#FFF' : '#cee1ed')};
          padding-left: 5px;
          /* margin-top: 5px; */
  }
  textarea {
    text-align: justify;
    width: 100%;
    border-bottom: 1px solid black;
    border-color: #1a004a;
    background: ${props => (props.isView ? '#FFF' : '#cee1ed')};
    resize: none;
    padding-left: 5px;
    
  } 

  input.edit {
    background: #cee1ed;
  }
  input.disable {
    background: #fff;
  }
  textarea.disable {
    background: #fff;
  }
  h2 {
    font-size: 14px;
    font-weight: bold;
    text-align: 'center';
  }

  th {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    text-align: left;
    vertical-align: center;
    padding-left: 5px;

    input {
      width: 100%;
    }
  }
  tr {
    border-width: 0px;
    border-color: #1a004a;
    border-style: solid;
  }
    
  td {
          border-width: 1px;
          border-color: #1a004a;
          border-style: solid;
  }
`

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgb(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalContainer = styled.div`
  width: 1000px;
  max-height: 90vh;
  min-height: 500px;
  background: #fff;
  position: relative;
  border-radius: 5px;
  overflow: scroll;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
`

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
  padding-bottom: 10px;

  th {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
  }
  tr {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
  }
  table {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    text-align: center;
    vertical-align: center;
    font-size: 14px;
  }
  h3 {
    font-weight: 500;
    font-size: 16px;
    color: #1766dc;
  }
`

const CloseButtonModal = styled.div`
  position: absolute;
  top: 2px;
  right: 5px;
  width: 20px;
  height: 20px;
  border: none;
  background: none;
  cursor: pointer;
  transition: 0.3s ease all;
  border-radius: 5px;
  color: #1766dc;
  &:hover {
    background: #f2f2f2;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`
