import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { CreateFormat, updateFormat, newWorkflow, updateWorkflow, getFormats } from './request'
import { Button, Col, Form, Row } from 'react-bootstrap'
import Signer from '../signer'
import Moment from 'moment';
import { toast } from 'react-toastify'
import SelectOption from '../SelectOption'
import { useUserContext } from '../../../../hooks/custom'
import GTHFOR076 from './GTHFOR076'
import SpinnerLoad from '../spinnerLoad'
import image from '../../../../assets/img/Logo_b-st.png'
import { capitalLeter, cityName, monedaCOP, onlyNumeric } from '../functions'
import { createEmployeeFormat, updateEmployeeFormat } from '../../humantalent/HumanTalent.request'

export const GTHFOR070 = ({ data, show, hide, restart, setRestart, isView, isTH, isRev, isApro, isSign = false, IdFormat_PK, workflow, list }) => {
  /*---------ESTADOS------------------
      [     creado      ] => docuento guardado con firma de solicitante
              V
      [    revisado     ] => cuando TH lo ha firmado
              V
      [    aprobado     ] => cuando gerencia lo ha firmado
              V
      [    procesado    ] => se tiene la vacante y candidato listo por talento humano
              V
      [   finalizado    ] => cuando se ha vinculado con un empleado 
              V
      [   cancelado    ] => cuando se declina el proceso de contratacion
              


  */


  const TerminoContrato = [
    { periodo: '1 mes', prueba: '6 dias' },
    { periodo: '2 meses', prueba: '12 dias' },
    { periodo: '3 meses', prueba: '18 dias' },
    { periodo: '4 meses', prueba: '24 dias' },
    { periodo: '5 meses', prueba: '30 dias' },
    { periodo: '6 meses', prueba: '36 dias' },
    { periodo: '7 meses', prueba: '42 dias' },
    { periodo: '8 meses', prueba: '48 dias' },
    { periodo: '9 meses', prueba: '54 dias' },
    { periodo: '10 meses', prueba: '60 dias' },
    { periodo: '11 meses', prueba: '60 dias' },
    { periodo: '1 Año', prueba: '60 dias' },

  ]
  //-------------------Definicion de datos para listas
  const { sessionUser } = useUserContext()
  const formato = 'GTH-FOR-70'
  const type = 'GTHFOR070'
  const revision = 7
  const fechaRev = '29/07/2022'


  const [esIntegral, setEsIntegral] = useState(false)
  const [date, setDate] = useState('')
  const [IdUser_FK, setIdUser_FK] = useState('')
  const [centroCosto, setcentroCosto] = useState('')
  const [fechaSolicitud, setfechaSolicitud] = useState('')
  const [cargoSolicitante, setcargoSolicitante] = useState('')
  const [areaSolicitante, setareaSolicitante] = useState('')
  const [cargoRequerido, setcargoRequerido] = useState('')
  const [lugarTrabajoReq, setlugarTrabajoReq] = useState('')
  const [fechaProgIngreso, setfechaProgIngreso] = useState('')
  const [Contrato, setContrato] = useState('')
  const [tipoVacante, settipoVacante] = useState('Vacante Nueva')
  const [personaReemplazar, setpersonaReemplazar] = useState('')
  const [metodoReclutamiento, setmetodoReclutamiento] = useState('')
  const [metodoReclutamientoOtro, setmetodoReclutamientoOtro] = useState('')
  const [manualFunciones, setmanualFunciones] = useState("")
  const [entrevistaLider, setentrevistaLider] = useState(0)
  const [pruebasTecnicas, setpruebasTecnicas] = useState(0)
  const [ECM, setECM] = useState(0)
  const [tipoContrato, settipoContrato] = useState('')
  const [tipoNomina, settipoNomina] = useState('Legal')
  const [origenCargo, setorigenCargo] = useState('')
  const [tipoPersonal, settipoPersonal] = useState('')
  const [terminoInicial, setterminoInicial] = useState('')
  const [tiempoAdaptacion, settiempoAdaptacion] = useState('')
  const [turno, setturno] = useState('')
  const [funcionConductor, setfuncionConductor] = useState(0)
  const [espaciosConfinados, setespaciosConfinados] = useState(0)
  const [trabajoAlturas, settrabajoAlturas] = useState(0)
  const [tipoRiesgoARL, settipoRiesgoARL] = useState(1)
  const [medicinaPrepagada, setmedicinaPrepagada] = useState(0)
  const [seguroExequial, setseguroExequial] = useState(0)
  const [seguroVIda, setseguroVIda] = useState(0)
  const [desayuno, setdesayuno] = useState(0)
  const [almuerzo, setalmuerzo] = useState(0)
  const [cena, setcena] = useState('')
  const [noAplicaAlimentacion, setnoAplicaAlimentacion] = useState(1)
  const [licencias, setlicencias] = useState('')
  const [licenciasCheck, setlicenciasCheck] = useState(false)
  const [telefonoMovil, settelefonoMovil] = useState(0)
  const [eMail, seteMail] = useState('')
  const [eMailCheck, seteMailCheck] = useState(false)
  const [pantalla, setpantalla] = useState(0)
  const [computador, setcomputador] = useState(0)
  const [discoDuro, setdiscoDuro] = useState(0)
  const [puestoTrabajo, setpuestoTrabajo] = useState(0)
  const [elementosOficina, setelementosOficina] = useState(0)
  const [OtroRecurso, setOtroRecurso] = useState('')
  const [nombreSolicitante, setnombreSolicitante] = useState('')
  const [apellidoSolicitante, setapellidoSolicitante] = useState('')
  const [firmaSolicitante, setfirmaSolicitante] = useState('')
  const [nombreRevision, setnombreRevision] = useState('')
  const [cargoRevision, setcargoRevision] = useState('')
  const [firmaRevision, setfirmaRevision] = useState('')
  const [fechaRevision, setfechaRevision] = useState('')
  const [nombreAprobador, setnombreAprobador] = useState('')
  const [cargoAprobador, setcargoAprobador] = useState('')
  const [firmaAprobador, setfirmaAprobador] = useState('')
  const [seleccionCandidato, setseleccionCandidato] = useState('')
  const [numeroVacante, setnumeroVacante] = useState('')
  const [fechaAprobador, setfechaAprobador] = useState('')
  const [nombreCandidato, setnombreCandidato] = useState('')
  const [apellidoCandidato, setapellidoCandidato] = useState('')
  const [expedicionCandidato, setexpedicionCandidato] = useState('')
  const [dniCandidato, setdniCandidato] = useState('')
  const [ingresoEfectivo, setingresoEfectivo] = useState('')
  const [signer, setsigner] = useState('')
  const [salarioBasico, setsalarioBasico] = useState(0)
  const [salarioIntegral, setsalarioIntegral] = useState(0)
  const [primaAlimentacion, setprimaAlimentacion] = useState('')
  const [primaMovilizacion, setprimaMovilizacion] = useState('')
  const [primaLocalizacion, setprimaLocalizacion] = useState('')
  const [otroSalario, setotroSalario] = useState('')
  const [textoOtroSalario, settextoOtroSalario] = useState('')
  const [Status, setStatus] = useState('creando')
  const [fechaGestion, setfechaGestion] = useState('')
  const [ShowManuales, hideManuales] = useState(false)
  const [dataManual, setDataManual] = useState([])
  const [_next, setNext] = useState(false)
  const [holdTC, setHoldTC] = useState(false)


  useEffect(() => {
    clearStates()
    getInfo()
  }, [restart])

  const clearStates = async () => {
    setfechaGestion('')
    setIdUser_FK('')
    setcentroCosto('')
    setcargoSolicitante('')
    setareaSolicitante('')
    setcargoRequerido('')
    setlugarTrabajoReq('')
    setfechaProgIngreso('')
    setContrato('')
    setECM(0)
    settipoVacante('')
    setpersonaReemplazar('')
    setmetodoReclutamiento('')
    setmetodoReclutamientoOtro('')
    setmanualFunciones("")
    setpruebasTecnicas(0)
    setentrevistaLider(0)
    settipoContrato('')
    settipoNomina('Legal')
    settipoPersonal('')
    setorigenCargo('')
    setterminoInicial('')
    settiempoAdaptacion('')
    setturno('')
    setfuncionConductor(0)
    setespaciosConfinados(0)
    settrabajoAlturas(0)
    settipoRiesgoARL('1')
    setmedicinaPrepagada(0)
    setseguroExequial(0)
    setseguroVIda(0)
    setdesayuno(0)
    setalmuerzo(0)
    setcena(0)
    setnoAplicaAlimentacion(1)
    setlicencias('')
    setlicenciasCheck(false)
    settelefonoMovil(0)
    seteMail('')
    seteMailCheck(false)
    setpantalla(0)
    setcomputador(0)
    setdiscoDuro(0)
    setpuestoTrabajo(0)
    setelementosOficina(0)
    setOtroRecurso('')
    setnombreSolicitante('')
    setapellidoSolicitante('')
    setfirmaSolicitante('')
    setnombreRevision('')
    setcargoRevision('')
    setfirmaRevision('')
    setfechaRevision('')
    setnombreAprobador('')
    setcargoAprobador('')
    setfirmaAprobador('')
    setfechaAprobador('')
    setnombreCandidato('')
    setapellidoCandidato('')
    setdniCandidato('')
    setexpedicionCandidato('')
    setingresoEfectivo('')
    setseleccionCandidato('')
    setnumeroVacante('')
    setsalarioBasico(0)
    setEsIntegral(false)
    setsalarioIntegral(0)
    setprimaAlimentacion('')
    setprimaMovilizacion('')
    setprimaLocalizacion('')
    setotroSalario('')
    settextoOtroSalario('')
    setStatus('')
    setHoldTC(false)
  }

  // useEffect(() => {

  //   verifyNext()

  // }
  //   , [firmaAprobador.IdEmployee_FK])

  const isSigning = () => {
    if (
      Status === 'creando' && sessionUser.IdEmployee_FK === firmaSolicitante.IdEmployee_FK ||
      Status === 'creado' && sessionUser.IdEmployee_FK === firmaSolicitante.IdEmployee_FK ||
      Status === 'creado' && sessionUser.IdEmployee_FK === firmaRevision.IdEmployee_FK ||
      Status === 'revisado' && sessionUser.IdEmployee_FK === firmaAprobador.IdEmployee_FK)
      return true
    return false

  }

  const convertToPdf = () => {
    // Código para imprimir el contenido
    window.print();
  };

  const getInfo = async () => {
    // console.log(list.Empleados.find(i => i.cargo === "Gerente General").NombreTrabajador)
    // res.find(i => i.cargo === "Gerente General").NombreTrabajador
    const formatDate = Moment().format('YYYY-MM-DD')
    setDate(formatDate)
    if (IdFormat_PK === 'en creacion') {

      setfechaSolicitud(formatDate)
      setIdUser_FK(sessionUser.IdEmployee_FK)
      setcargoSolicitante(sessionUser.cargo)
      setnombreSolicitante(sessionUser.NombreTrabajador)
      setapellidoSolicitante(sessionUser.ApellidoTrabajador)
      setareaSolicitante(sessionUser.areaTrabajo)
      setfechaGestion(formatDate)
      settipoVacante('Vacante Nueva')
      setfirmaSolicitante({
        NombreTrabajador: sessionUser.NombreTrabajador,
        ApellidoTrabajador: sessionUser.ApellidoTrabajador,
        cargo: sessionUser.cargo,
        IdEmployee_FK: sessionUser.IdEmployee_FK
      })
      let res = list.Empleados
      


      setfirmaAprobador({
        NombreTrabajador: list.Empleados.find(i => i.cargo === "Gerente General").NombreTrabajador,
        ApellidoTrabajador: list.Empleados.find(i => i.cargo === "Gerente General").ApellidoTrabajador,
        cargo: list.Empleados.find(i => i.cargo === "Gerente General").cargo,
        IdEmployee_FK: list.Empleados.find(i => i.cargo === "Gerente General").IdEmployee_FK
      })
      setfirmaRevision({
        NombreTrabajador: list.Empleados.find(i => i.cargo === "Gerente Recursos Humanos").NombreTrabajador,
        ApellidoTrabajador: list.Empleados.find(i => i.cargo === "Gerente Recursos Humanos").ApellidoTrabajador,
        cargo: list.Empleados.find(i => i.cargo === "Gerente Recursos Humanos").cargo,
        IdEmployee_FK: list.Empleados.find(i => i.cargo === "Gerente Recursos Humanos").IdEmployee_FK
      })


      setStatus('creando')

    } else if (IdFormat_PK !== undefined && IdFormat_PK !== 'en creacion') {

      //getDataList()
      if (isRev) setfechaRevision(date)
      if (isApro) setfechaAprobador(date)

      setIdUser_FK(data.IdUser_FK)
      setcentroCosto(data.centroCosto)
      setfechaSolicitud(data.fechaSolicitud)
      setcargoSolicitante(data.cargoSolicitante)
      setnombreSolicitante(data.nombreSolicitante)
      setapellidoSolicitante(data.apellidoSolicitante)
      setareaSolicitante(data.areaSolicitante)
      setcargoRequerido(data.cargoRequerido)
      setlugarTrabajoReq(data.lugarTrabajoReq)
      setfechaProgIngreso(data.fechaProgIngreso)
      setContrato(data.Contrato)
      setECM(data.ECM)
      settipoVacante(data.tipoVacante)
      setpersonaReemplazar(data.personaReemplazar)
      setmetodoReclutamiento(data.metodoReclutamiento)
      setmetodoReclutamientoOtro(data.metodoReclutamientoOtro)
      setmanualFunciones(data.manualFunciones)
      setentrevistaLider(data.entrevistaLider)
      setpruebasTecnicas(data.pruebasTecnicas)
      settipoContrato(data.tipoContrato)
      setHoldTC(data.tipoContrato === 'COL' || data.tipoContrato === 'CTI' ? true : false)
      settipoNomina(data.tipoNomina)
      setorigenCargo(data.origenCargo)
      settipoPersonal(data.tipoPersonal)
      setterminoInicial(data.terminoInicial)
      settiempoAdaptacion(data.tiempoAdaptacion)
      setturno(data.turno)
      setfuncionConductor(data.funcionConductor)
      setespaciosConfinados(data.espaciosConfinados)
      settrabajoAlturas(data.trabajoAlturas)
      settipoRiesgoARL(data.tipoRiesgoARL)
      setmedicinaPrepagada(data.medicinaPrepagada)
      setseguroExequial(data.seguroExequial)
      setseguroVIda(data.seguroVIda)
      setdesayuno(data.desayuno)
      setalmuerzo(data.almuerzo)
      setcena(data.cena)
      setnoAplicaAlimentacion(data.noAplicaAlimentacion)
      setlicencias(data.licencias)
      setlicenciasCheck(data.licencias !== '' ? true : false)
      settelefonoMovil(data.telefonoMovil)
      seteMailCheck(data.eMail !== '' ? true : false)
      seteMail(data.eMail)
      setpantalla(data.pantalla)
      setcomputador(data.computador)
      setdiscoDuro(data.discoDuro)
      setpuestoTrabajo(data.puestoTrabajo)
      setelementosOficina(data.elementosOficina)
      setOtroRecurso(data.OtroRecurso)
      var firmas = await JSON.parse(data.firmas)

      setsigner(firmas)

      setfirmaSolicitante(firmas.solicitante)
      setfirmaRevision(firmas.revision)
      setfirmaAprobador(firmas.aprobador)
      setnombreCandidato(data.nombreCandidato)
      setapellidoCandidato(data.apellidoCandidato)
      setdniCandidato(data.dniCandidato)
      setexpedicionCandidato(data.expedicionCandidato)
      setingresoEfectivo(data.ingresoEfectivo)
      setseleccionCandidato(data.seleccionCandidato)
      setnumeroVacante(data.numeroVacante)
      const salario = await JSON.parse(data.salario)

      if (salario.tipo === 'Basico') {
        setsalarioIntegral(0)
        setEsIntegral(false)

      } else {
        setsalarioBasico(0)
        setEsIntegral(true)
      }

      setEsIntegral(salario.tipo === 'Basico' ? false : true)
      setsalarioBasico(salario.tipo === 'Basico' ? salario.valor : 0)
      setsalarioIntegral(salario.tipo === 'Integral' ? salario.valor : 0)
      setprimaAlimentacion(salario.tipo === 'Basico' ? salario.primaAlimentacion : '')
      setprimaMovilizacion(salario.tipo === 'Basico' ? salario.primaMovilizacion : '')
      setprimaLocalizacion(salario.tipo === 'Basico' ? salario.primaLocalizacion : '')
      setotroSalario(salario.tipo === 'Basico' ? salario.otroSalario : '')
      settextoOtroSalario(salario.tipo === 'Basico' ? salario.textoOtroSalario : '')
      setStatus(data.Status)
      setfechaGestion(data.fechaGestion)

      if (IdFormat_PK === 'copiando') {
        settipoVacante('Vacante Nueva')
        setpersonaReemplazar('')
        setIdUser_FK(sessionUser.IdEmployee_FK)
        setcargoSolicitante(sessionUser.cargo)
        setnombreSolicitante(sessionUser.NombreTrabajador)
        setapellidoSolicitante(sessionUser.ApellidoTrabajador)
        setareaSolicitante(sessionUser.areaTrabajo)
        setmanualFunciones('')
        setfechaProgIngreso('')
        setnombreCandidato('')
        setapellidoCandidato('')
        setdniCandidato('')
        setexpedicionCandidato('')
        setingresoEfectivo('')
        setseleccionCandidato('')
        setnumeroVacante('')
        seteMail('')
        setcargoRequerido('')
        setfechaGestion(formatDate)
        setfechaSolicitud(formatDate)
        setStatus('creando')
        setfirmaSolicitante({
          NombreTrabajador: sessionUser.NombreTrabajador,
          ApellidoTrabajador: sessionUser.ApellidoTrabajador,
          cargo: sessionUser.cargo,
          IdEmployee_FK: sessionUser.IdEmployee_FK,
          fecha: ''
        })
        // let res = await getFormats('employee')
        // res = res.data.data.filter(item => item.Status === "activo")


        setfirmaAprobador({
          NombreTrabajador: list.Empleados.find(i => i.cargo === "Gerente General").NombreTrabajador,
          ApellidoTrabajador: list.Empleados.find(i => i.cargo === "Gerente General").ApellidoTrabajador,
          cargo: list.Empleados.find(i => i.cargo === "Gerente General").cargo,
          IdEmployee_FK: list.Empleados.find(i => i.cargo === "Gerente General").IdEmployee_FK,
          fecha: ''
        })
        setfirmaRevision({
          NombreTrabajador: list.Empleados.find(i => i.cargo === "Gerente Recursos Humanos").NombreTrabajador,
          ApellidoTrabajador: list.Empleados.find(i => i.cargo === "Gerente Recursos Humanos").ApellidoTrabajador,
          cargo: list.Empleados.find(i => i.cargo === "Gerente Recursos Humanos").cargo,
          IdEmployee_FK: list.Empleados.find(i => i.cargo === "Gerente Recursos Humanos").IdEmployee_FK,
          fecha: ''
        })

      }



    }
    return true
  }

  const updateManual = async (borrar) => {

    let result = ''

    if (borrar) {
      result = await updateFormat({
        IdFormat_PK: manualFunciones,
        HSE: JSON.stringify(THManual.HSEIni),
        Status: 'disponible'
      }, 'gthfor76')
      return
    }
    
    var THManual = JSON.parse(list.matrixauth.legal)

    var HSEman = THManual.HSEIni

    HSEman.SST += funcionConductor ? THManual.HSEAdd.conduccion : ""
    HSEman.SST += trabajoAlturas ? THManual.HSEAdd.alturas : ""
    HSEman.SST += espaciosConfinados ? THManual.HSEAdd.espaciosConf : ""
    HSEman.SST = HSEman.SST.replace('</ol>', '') + '</ol>'
    result = await updateFormat({
      IdFormat_PK: manualFunciones,
      HSE: JSON.stringify(HSEman),
      Status: 'firmaEmpleado',
      firmaEmpleado: JSON.stringify({
        NombreTrabajador: nombreCandidato,
        ApellidoTrabajador: apellidoCandidato,
        IdEmployee_FK: dniCandidato,
        fecha: ''
      })
    }, 'gthfor76')
    if ((IdFormat_PK !== 'en creacion' && IdFormat_PK !== 'copiando' && data.manualFunciones !== manualFunciones))

      result = await updateFormat({
        IdFormat_PK: data.manualFunciones,
        HSE: JSON.stringify(JSON.parse(list.matrixauth.legal).HSEIni),
        firmaEmpleado: JSON.stringify({ NombreTrabajador: '', ApellidoTrabajador: '', IdEmployee_FK: '', fecha: '' }),
        Status: 'disponible'
      }, 'gthfor76')
  }

  const onSubmit = async e => {

    e.preventDefault()

    var nextWorkflow = []
    var idWF = ''
    if (
      ((Status === 'creando' && sessionUser.IdEmployee_FK === firmaSolicitante.IdEmployee_FK && !firmaSolicitante.image && isView) ||
        (Status === 'creado' && sessionUser.IdEmployee_FK === firmaSolicitante.IdEmployee_FK && !firmaSolicitante.image && isView) ||
        (isSign && sessionUser.IdEmployee_FK === firmaRevision.IdEmployee_FK && !firmaRevision.image && isView) ||
        (isSign && sessionUser.IdEmployee_FK === firmaAprobador.IdEmployee_FK && !firmaAprobador.image && isView)) && Status !== 'editando'
    ) {
      toast.error('por favor firmar el documento')
      return
    }
    var salario = []
    if (salarioIntegral === 0) {
      salario = JSON.stringify({
        tipo: 'Basico',
        valor: onlyNumeric(salarioBasico),
        primaAlimentacion:onlyNumeric(primaAlimentacion),
        primaMovilizacion:onlyNumeric(primaMovilizacion),
        primaLocalizacion:onlyNumeric(primaLocalizacion),
        otroSalario:onlyNumeric(otroSalario),
        textoOtroSalario,
      })
    } else {
      salario = JSON.stringify({
        tipo: 'Integral',
        valor: onlyNumeric(salarioIntegral),
        primaAlimentacion,
        primaMovilizacion,
        primaLocalizacion,
        otroSalario,
      })
    }
    var firmas = { solicitante: firmaSolicitante, revision: firmaRevision, aprobador: firmaAprobador }
    var estado = 'creado'

    firmas = JSON.stringify(firmas)

    const data = {
      centroCosto,
      IdUser_FK,
      fechaSolicitud,
      cargoSolicitante,
      areaSolicitante,
      cargoRequerido,
      lugarTrabajoReq,
      fechaProgIngreso,
      Contrato,
      tipoVacante,
      personaReemplazar,
      metodoReclutamiento,
      metodoReclutamientoOtro,
      manualFunciones,
      entrevistaLider,
      pruebasTecnicas,
      tipoContrato,
      tipoNomina,
      origenCargo,
      tipoPersonal,
      terminoInicial,
      tiempoAdaptacion,
      turno,
      funcionConductor,
      espaciosConfinados,
      trabajoAlturas,
      tipoRiesgoARL,
      medicinaPrepagada,
      seguroExequial,
      seguroVIda,
      desayuno,
      almuerzo,
      cena,
      noAplicaAlimentacion,
      licencias,
      telefonoMovil,
      eMail,
      pantalla,
      computador,
      discoDuro,
      puestoTrabajo,
      elementosOficina,
      OtroRecurso,
      firmas,
      nombreSolicitante,
      apellidoSolicitante,
      nombreCandidato,
      apellidoCandidato,
      dniCandidato,
      expedicionCandidato,
      ingresoEfectivo,
      seleccionCandidato,
      numeroVacante,
      salario,
      Status: estado,
      fechaGestion: date
    }
    if (isSign) {
      
      let updateData, actionWF
      if (sessionUser.IdEmployee_FK === firmaRevision.IdEmployee_FK) {
        updateData = { IdFormat_PK: IdFormat_PK, firmas: firmas, Status: 'revisado' }
        idWF = firmaAprobador.IdEmployee_FK
        actionWF = 'aprobar'
      }
      else if (sessionUser.IdEmployee_FK === firmaAprobador.IdEmployee_FK) {
        updateData = { IdFormat_PK: IdFormat_PK, firmas: firmas, Status: 'aprobado' }
        idWF = undefined
        actionWF = undefined
      }

      const result = await updateEmployeeFormat(type, updateData, workflow, idWF, actionWF)
      if (result.data.status === 200) {
        toast.success(result.data.msj)
      } else {
        toast.error(result.data.msj);
      }
    }

    else if (IdFormat_PK === 'en creacion' || IdFormat_PK === 'copiando') {

      const result = await createEmployeeFormat(type, { ...data, JefeSolicitante: sessionUser.idJefeInm_FK },
        {
          TaskDescription: "Documento de " + sessionUser.NombreTrabajador + " " + sessionUser.ApellidoTrabajador,
          Status: "pendiente",
          action: "Revisar",
          Format: type,
          dateCreation: date,
          IdEmployee_created: sessionUser.IdEmployee_FK,
          documents: "Solicitud Empleo",
          IdEmployee_FK: firmaRevision.IdEmployee_FK,
          IdFormat_FK: ""
        }
      )

      if (result.data.status === 200) {
        updateManual()
        toast.success(result.data.msj)
      } else {
        toast.error(result.data.msj);
      }
    } else {

      if (isTH && Status === "aprobado" || isTH && Status === "procesando" ||isTH && Status === "procesado") {
        if (nombreCandidato !== "" && apellidoCandidato !== "" && dniCandidato !== "" && seleccionCandidato !== "" &&
          numeroVacante !== "" && ingresoEfectivo !== "" && expedicionCandidato !== ""){
           data['Status'] = "procesado"
          }
        else {
          toast.error(" por favor diligencie los campos en azul ");
          return
        }
      }
      data.IdFormat_PK= IdFormat_PK
      const result = await updateFormat(data, type)

      if (result.data.status === 200) {
        updateManual()
        toast.success(result.data.msj)
      }
      else {
        toast.error(result.data.msj);
        return
      }


    }

    clearStates()
    hide(!show)
    setRestart(!restart)
  }


  // ob submit ficionando con api anterior
  // const onSubmit = async e => {
  //   e.preventDefault()
  //   var nextWorkflow = []
  //   if (
  //     ((Status === 'creando' && sessionUser.IdEmployee_FK === firmaSolicitante.IdEmployee_FK && !firmaSolicitante.image && isView) ||
  //       (Status === 'creado' && sessionUser.IdEmployee_FK === firmaSolicitante.IdEmployee_FK && !firmaSolicitante.image && isView) ||
  //       (isSign && sessionUser.IdEmployee_FK === firmaRevision.IdEmployee_FK && !firmaRevision.image && isView) ||
  //       (isSign && sessionUser.IdEmployee_FK === firmaAprobador.IdEmployee_FK && !firmaAprobador.image && isView)) && Status !== 'editando'
  //   ) {
  //     toast.error('por favor firmar el documento')
  //     return
  //   }

  //   var salario = []
  //   if (salarioIntegral === 0) {
  //     salario = JSON.stringify({
  //       tipo: 'Basico',
  //       valor: salarioBasico,
  //       primaAlimentacion,
  //       primaMovilizacion,
  //       primaLocalizacion,
  //       otroSalario,
  //       textoOtroSalario,
  //     })
  //   } else {
  //     salario = JSON.stringify({
  //       tipo: 'Integral',
  //       valor: salarioIntegral,
  //       primaAlimentacion,
  //       primaMovilizacion,
  //       primaLocalizacion,
  //       otroSalario,
  //     })
  //   }

  //   var firmas = { solicitante: firmaSolicitante, revision: firmaRevision, aprobador: firmaAprobador }


  //   var estado = 'creado'
  //   if (IdFormat_PK === 'en creacion' || IdFormat_PK === 'copiando') {
  //     workflow = {
  //       TaskDescription: "Documento de " + sessionUser.NombreTrabajador + " " + sessionUser.ApellidoTrabajador,
  //       Status: "pendiente",
  //       action: "Revisar",
  //       Format: type,
  //       dateCreation: date,
  //       IdEmployee_created: sessionUser.IdEmployee_FK,
  //       documents: "Solicitud Empleo",
  //       IdEmployee_FK: firmaRevision.IdEmployee_FK,
  //       IdFormat_FK: ""
  //     }
  //   } else if (isSign && sessionUser.IdEmployee_FK === firmaRevision.IdEmployee_FK) {
  //     workflow.Status = "finalizado"
  //     workflow.dateFinish = date
  //     let resWF = await updateWorkflow(workflow)
  //     delete workflow.IdWorkFlow_PK
  //     delete workflow.dateFinish
  //     workflow.Status = "pendiente"
  //     workflow.action = "aprobar"
  //     workflow.dateCreation = date
  //     workflow.IdEmployee_FK = firmaAprobador.IdEmployee_FK
  //     resWF = await newWorkflow(workflow)
  //     estado = 'revisado'
  //   }
  //   else if (isSign && sessionUser.IdEmployee_FK === firmaAprobador.IdEmployee_FK) {
  //     workflow.Status = "finalizado"
  //     workflow.dateFinish = date
  //     estado = 'aprobado'
  //     const resFinishWF = await updateWorkflow(workflow)

  //   }
  //   else if (isTH && firmaAprobador.image && firmaRevision.image && firmaSolicitante.image) {
  //     estado = 'aprobado'

  //   }


  //   firmas = JSON.stringify(firmas)

  //   updateManual(false)
  //   const data = {
  //     centroCosto,
  //     IdUser_FK,
  //     fechaSolicitud,
  //     cargoSolicitante,
  //     areaSolicitante,
  //     cargoRequerido,
  //     lugarTrabajoReq,
  //     fechaProgIngreso,
  //     Contrato,
  //     tipoVacante,
  //     personaReemplazar,
  //     metodoReclutamiento,
  //     metodoReclutamientoOtro,
  //     manualFunciones,
  //     entrevistaLider,
  //     pruebasTecnicas,
  //     tipoContrato,
  //     tipoNomina,
  //     origenCargo,
  //     tipoPersonal,
  //     terminoInicial,
  //     tiempoAdaptacion,
  //     turno,
  //     funcionConductor,
  //     espaciosConfinados,
  //     trabajoAlturas,
  //     tipoRiesgoARL,
  //     medicinaPrepagada,
  //     seguroExequial,
  //     seguroVIda,
  //     desayuno,
  //     almuerzo,
  //     cena,
  //     noAplicaAlimentacion,
  //     licencias,
  //     telefonoMovil,
  //     eMail,
  //     pantalla,
  //     computador,
  //     discoDuro,
  //     puestoTrabajo,
  //     elementosOficina,
  //     OtroRecurso,
  //     firmas,
  //     nombreSolicitante,
  //     apellidoSolicitante,
  //     nombreCandidato,
  //     apellidoCandidato,
  //     dniCandidato,
  //     expedicionCandidato,
  //     ingresoEfectivo,
  //     seleccionCandidato,
  //     numeroVacante,
  //     salario,
  //     Status: estado,
  //     fechaGestion: date
  //   }



  //   if (IdFormat_PK === 'en creacion' || IdFormat_PK === 'copiando') {

  //     const result = await CreateFormat({ ...data, JefeSolicitante: sessionUser.idJefeInm_FK }, type)
  //     if (result.data.status === 200) {
  //       workflow["IdFormat_FK"] = result.data.data.insertId
  //       const resWorkflow = await newWorkflow(workflow)
  //       if (resWorkflow.data.status === 200) {
  //         updateManual()
  //         toast.success(resWorkflow.data.msj)
  //       }
  //       else {
  //         toast.error(resWorkflow.data.msj);
  //       }
  //     } else {
  //       toast.error(result.data.msj);
  //     }
  //   } else {

  //     if (isTH && Status === "aprobado" || isTH && Status === "procesado") {
  //       if (nombreCandidato !== "" && apellidoCandidato !== "" && dniCandidato !== "" && seleccionCandidato !== "" &&
  //         numeroVacante !== "" && ingresoEfectivo !== "" && expedicionCandidato !== "")
  //         data.Status = "procesado"
  //       else {
  //         toast.error(" por favor diligencie los campos en azul ");
  //         return
  //       }

  //     }

  //     data.IdFormat_PK = IdFormat_PK
  //     const result = await updateFormat(data, type)
  //     if (result.data.status === 200) {
  //       toast.success(result.data.msj)
  //     }
  //     else {
  //       toast.error(result.data.msj);
  //     }
  //   }
  //   clearStates()
  //   hide(!show)
  //   setRestart(!restart)
  // }

  return (
    <>
      {show && (
        <Overlay style={{ zIndex: '1' }} >
          <SpinnerLoad trigger={data} />
          <ModalContainer><CloseButtonModal className='no-print' onClick={() => hide(!show)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-x-square"
              viewBox="0 0 16 16"
            >
              <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </CloseButtonModal>
            <ModalHeader>
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <th style={{ width: '20%', fontSize: '9px', verticalAlign: 'bottom' }} rowSpan={3}>
                      <img
                        className="format_logo"
                        src={image}
                        style={{ paddingBottom: '5px', width: '75%', minWidth: "40px" }}
                        alt="Logo RMS"
                      /><br />
                      NIT 830.507.705-2
                    </th>
                    <th style={{ width: '55%', height: '30px' }}>RELIABILITY MAINTENANCE SERVICES S.A.S</th>
                    <th style={{ width: '25%' }}>{formato}</th>
                  </tr>
                  <tr>
                    <th rowSpan={2}>SOLICITUD Y APROBACIÓN DE EMPLEO</th>
                    <th style={{ fontSize: '9px' }}>Revisión: {revision}</th>
                  </tr>
                  <tr>
                    <th style={{ fontSize: '9px' }}>Fecha de revisión:{fechaRev}</th>
                  </tr>
                </tbody>
              </table>
            </ModalHeader>
            <Row>
              <center> <label style={{ fontSize: '11px', fontWeight: '10000' }}>Solicitud No.</label>
                <label style={{ fontSize: '11px', fontWeight: '10000' }}>{IdFormat_PK}</label>
              </center>
            </Row>


            <Form onSubmit={onSubmit}>
              <Frame isView={isView || isTH}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <th colSpan={2} style={{ width: '50%', textAlign: 'center' }}>
                        <h2>DATOS DEL SOLICITANTE</h2>
                      </th>
                      <th style={{ width: '50%', textAlign: 'center' }} colSpan={2}>
                        <h2>DATOS DEL CARGO REQUERIDO</h2>
                      </th>
                    </tr>
                    <tr>
                      <th style={{ width: '20%' }}>Fecha de solicitud:</th>
                      <th style={{ width: '30%' }}>
                        <input
                          onChange={e => { }} className="disable" value={fechaSolicitud} required disabled={true} />
                      </th>
                      <th style={{ width: '18%' }}>Cargo requerido:</th>
                      <th style={{ width: '32%' }}>
                        <input
                          value={cargoRequerido}
                          list="Cargos"
                          className={tipoContrato === "CAP" ? '' : 'disable'}
                          onChange={e => {
                            setcargoRequerido(e.target.value)
                          }}
                          required
                          disabled={tipoContrato === "CAP" ? false : true}
                        />
                        <datalist id="Cargos">{list.listaCargos}</datalist>
                      </th>
                    </tr>
                    <tr>
                      <th >Solicitante:</th>
                      <th >
                        <input
                          onChange={e => { }}
                          className="disable"
                          value={nombreSolicitante + " " + apellidoSolicitante}
                          required
                          disabled
                        />
                      </th>
                      <th >Lugar de trabajo:</th>
                      <td style={{ textAlign: 'center' , fontWeight:'300'}} >
                        {isView ? cityName(lugarTrabajoReq) : <SelectOption
                          type={"city"} searchable={true} state={lugarTrabajoReq} setState={setlugarTrabajoReq} required disabled={isView & !isTH ? true : false} />

                        }
                      </td>
                    </tr>
                    <tr>
                      <th >Cargo:</th>
                      <th >
                        <input
                          className="disable"
                          value={cargoSolicitante}
                          onChange={e => {
                            setcargoSolicitante(e.target.value)
                          }}
                          required
                          disabled
                        />
                      </th>
                      <th >Centro de Costo:</th>
                      <th >

                        <select
                          name="CeCo"
                          value={centroCosto}
                          onChange={e => { setcentroCosto(e.target.value); setContrato(e.target.selectedOptions[0].id) }}
                          required
                          title="seleccione..."
                          disabled={isView || isTH ? true : false}
                        ><option key={0}></option>

                          {list.listaCeCo.map((article, i) => {
                            return (
                              <option key={i + 1} id={article.NumeroContrato} value={article.CeCo}>{article.CeCo + " - " + JSON.parse(article.Empresa).SocialName} </option>
                            );
                          })}
                        </select>
                      </th>
                    </tr>
                    <tr>
                      <th >Area:</th>
                      <th >
                        <input
                          className="disable"
                          value={areaSolicitante}
                          onChange={e => {
                            setareaSolicitante(e.target.value)
                          }}
                          required
                          disabled
                        />
                      </th>
                      <th >Contrato:</th>
                      <th >

                        <select
                          name="Contrato"
                          value={Contrato}
                          onChange={e => { setcentroCosto(e.target.selectedOptions[0].id); setContrato(e.target.value) }}
                          required
                          disabled={isView || isTH ? true : false}
                          placeholder="seleccione..."
                          title="seleccione..."
                        ><option key={0}></option>

                          {list.listaCeCo.map((article, i) => {
                            return (
                              <option key={i + 1} id={article.CeCo} value={article.NumeroContrato}>{article.NumeroContrato} </option>
                            );
                          })}
                        </select>
                      </th>
                    </tr>
                    <tr>
                      <th >Estado:</th>
                      <th >
                        <input
                          onChange={e => { }}
                          className="disable"
                          type={'text'}
                          value={Status}
                          required
                          disabled={true}
                        />
                      </th><th >Fecha programada ingreso:</th>
                      <th >
                        <input

                          data-provide="datepicker"
                          type={'date'}
                          value={fechaProgIngreso}
                          onChange={e => {
                            setfechaProgIngreso(e.target.value)
                          }}
                          required
                          disabled={isView || isTH ? true : false}
                        />
                      </th>

                    </tr>
                  </tbody>
                </table>
              </Frame>

              <Frame isView={isView || isTH}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <th colSpan={4} style={{ width: '100%' }}>
                        <h2>1. APROBACIÓN REQUERIDA</h2>
                      </th>
                    </tr>
                    <tr>
                      <th>
                        <label>
                          <input
                            style={{ width: '10%' }}
                            type={'radio'}
                            name={'aprobacion'}
                            value={'Vacante Nueva'}
                            checked={tipoVacante === 'Vacante Nueva' ? 'checked' : ''}
                            onChange={e => {
                              settipoVacante(e.target.value)
                              setpersonaReemplazar('')
                            }}
                            required
                            disabled={isView || isTH ? true : false}
                          />
                          Vacante Nueva
                        </label>
                      </th>
                      <th colSpan={2} style={{ width: '80%' }}></th>
                    </tr>
                    <tr>
                      <th>
                        <label>
                          <input
                            style={{ width: '10%' }}
                            type={'radio'}
                            name={'aprobacion'}
                            value={'Reemplazo'}
                            checked={tipoVacante === 'Reemplazo' ? 'checked' : ''}
                            onChange={e => {
                              settipoVacante(e.target.value)
                            }}
                            required
                            disabled={isView || isTH ? true : false}
                          />
                          Reemplazo
                        </label>
                      </th>
                      <th style={{ width: '20%' }}>Persona a reemplazar:</th>
                      <th style={{ width: '50%' }}>
                        <input
                          value={personaReemplazar}
                          onChange={e => {
                            setpersonaReemplazar(e.target.value)
                            settipoVacante('Reemplazo')
                          }}
                          disabled={isView || isTH ? true : false}
                        />
                      </th>
                    </tr>
                  </tbody>
                </table>
              </Frame>

              <Frame isView={isView || isTH}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <th colSpan={4} style={{ width: '100%' }}>
                        <h2>2. MÉTODO DE RECLUTAMIENTO </h2>
                      </th>
                    </tr>
                    <tr>
                      <th style={{ width: '25%' }}>
                        <label>
                          <input
                            style={{ width: '20px', right: '30px' }}
                            id='metodoReclutamiento'
                            name='metodoReclutamiento'
                            type={'radio'}
                            value={'PromInterna'}
                            checked={metodoReclutamiento === 'PromInterna' ? 'checked' : ''}
                            onChange={e => {
                              setmetodoReclutamiento(e.target.value)
                              setmetodoReclutamientoOtro('')
                            }}
                            required
                            disabled={isView || isTH ? true : false}
                          />
                          Promoción interna
                        </label>
                      </th>
                      <th style={{ width: '30%' }}>
                        <label>
                          <input
                            id='metodoReclutamiento'
                            name='metodoReclutamiento'
                            style={{ width: '20px', right: '30px' }}
                            type={'radio'}
                            value={'MercadoLaboral'}
                            checked={metodoReclutamiento === 'MercadoLaboral' ? 'checked' : ''}
                            onChange={e => {
                              setmetodoReclutamiento(e.target.value)
                              setmetodoReclutamientoOtro('')
                            }}
                            required
                            disabled={isView || isTH ? true : false}
                          />
                          elempleo.com
                        </label>
                      </th>
                      <th style={{ width: '40%' }}>
                        <label>
                          <input
                            style={{ width: '20px', right: '30px' }}
                            id='metodoReclutamiento'
                            name='metodoReclutamiento'
                            type={'radio'}
                            value={'Comunidades'}
                            checked={metodoReclutamiento === 'Comunidades' ? 'checked' : ''}
                            onChange={e => {
                              setmetodoReclutamiento(e.target.value)
                              setmetodoReclutamientoOtro('')
                            }}
                            required
                            disabled={isView || isTH ? true : false}
                          />
                          SPE (Vacantes que se requieran publicar en las comunidades)
                        </label>
                      </th>
                    </tr>
                  </tbody>
                </table>
              </Frame>

              <Frame isView={isView || isTH}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <th colSpan={2} style={{ width: '100%' }}>
                        <h2>3. DOCUMENTOS REQUERIDOS </h2>
                      </th>
                    </tr>
                    <tr>
                      <th style={{ width: '25%' }}>
                        <label onClick={(e) => {
                          e.preventDefault();
                          if (manualFunciones !== '') {
                            setDataManual(list.Manuales.filter(i => i.IdFormat_PK === parseInt(manualFunciones))[0])
                            hideManuales(!ShowManuales)

                          }
                        }
                        }>
                          <input
                            style={{ width: '20px', right: '30px' }}
                            type={'checkbox'}
                            checked={manualFunciones !== "" ? 'checked' : ''}
                            onChange={e => { let a = e }}
                            defaultChecked={''}
                            required
                            //readOnly
                            disabled={isView ? true : tipoContrato === "CAP" ? true : false}
                          />
                          Perfíl del cargo y manual de funciones
                        </label>
                        <select className={tipoContrato === "CAP" ? 'disable' : ''} value={manualFunciones} onChange={e => { setmanualFunciones(e.target.value); setcargoRequerido(e.target.selectedOptions[0].id) }} disabled={isView || isTH ? true : tipoContrato === "CAP" ? true : false} title="seleccione..">

                          <option value={manualFunciones === '' ? "" : manualFunciones}  >
                            {manualFunciones === '' ? 'seleccione...' : manualFunciones + ' - ' + list.Manuales.filter(i => i.IdFormat_PK === parseInt(manualFunciones))[0].Cargo
                            }
                          </option>
                          {list.Manuales.filter(item => item.Status === "disponible" && item.creadoPor === sessionUser.IdEmployee_FK).map((article, i) => {
                            return (
                              <option key={i} id={article.Cargo} value={article.IdFormat_PK}  >{article.IdFormat_PK} - {article.Cargo} </option>
                            );
                          })}
                        </select>
                      </th>
                      <th style={{ width: '25%' }}>
                        <label>
                          <input
                            style={{ width: '20px', right: '30px' }}
                            type={'checkbox'}
                            checked={entrevistaLider ? 'checked' : ''}
                            onChange={e => {
                              setentrevistaLider(!entrevistaLider)
                            }}
                            defaultChecked={''}
                            required
                            disabled={isView || isTH ? true : false}
                          />
                          Entrevista líder de área
                        </label>
                      </th>
                      <th style={{ width: '25%' }}>
                        <label>
                          <input
                            style={{ width: '20px', right: '30px' }}
                            type={'checkbox'}
                            checked={pruebasTecnicas ? 'checked' : ''}
                            onChange={e => {
                              setpruebasTecnicas(!pruebasTecnicas)
                            }}
                            defaultChecked={''}
                            required
                            disabled={isView || isTH ? true : false}
                          />
                          Pruebas técnicas
                        </label>
                      </th>
                    </tr>
                  </tbody>
                </table>
              </Frame>
              <Frame isView={isView || isTH}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <th colSpan={7} style={{ width: '100%' }}>
                        <h2>4. CONDICIONES LABORALES</h2>
                      </th>
                    </tr>

                    <tr>
                      <th rowSpan={2} style={{ width: '10%' }}>
                        Tipo de Contrato:
                      </th>
                      <th style={{ width: '15%' }}>
                        <label>
                          <input
                            style={{ width: '20px', right: '30px' }}
                            type={'radio'}
                            name={'tipoContrato'}
                            value={'CTF'}
                            checked={tipoContrato === 'CTF' ? 'checked' : ''}
                            onChange={e => {
                              settipoContrato(e.target.value)
                              setHoldTC(false)
                              settiempoAdaptacion('')
                            }}
                            defaultChecked={''}
                            required
                            disabled={isView || isTH ? true : false}
                          />
                          A término fijo
                        </label>
                      </th>
                      <th style={{ width: '15%' }}>
                        <label>
                          <input
                            style={{ width: '20px', right: '30px' }}
                            type={'radio'}
                            name={'tipoContrato'}
                            value={'CTI'}
                            checked={tipoContrato === 'CTI' ? 'checked' : ''}
                            onChange={e => {
                              settipoContrato(e.target.value)
                              setHoldTC(true)
                              setterminoInicial('Indeterminado')
                              settiempoAdaptacion('60 días')
                            }}
                            defaultChecked={''}
                            required
                            disabled={isView || isTH ? true : false}
                          />
                          indefinido
                        </label>
                      </th>
                      <th rowSpan={2} style={{ width: '15%' }}>
                        Origen del Cargo:
                      </th>
                      <th style={{ width: '15%' }}>
                        <label>
                          <input
                            style={{ width: '20px', right: '30px' }}
                            type={'radio'}
                            name={'origenCargo'}
                            value={'Contractual'}
                            checked={origenCargo === 'Contractual' ? 'checked' : ''}
                            onChange={e => {
                              setorigenCargo(e.target.value)

                            }}
                            defaultChecked={''}
                            required
                            disabled={isView || isTH ? true : false}
                          />
                          Contractual
                        </label>
                      </th>
                      <th rowSpan={2} style={{ width: '15%' }}>
                        Tipo de personal:
                      </th>
                      <th style={{ width: '15%' }}>
                        <label>
                          <input
                            style={{ width: '20px', right: '30px' }}
                            type={'radio'}
                            name={'tipoPersonal'}
                            value={'Operativo'}
                            checked={tipoPersonal === 'Operativo' ? 'checked' : ''}
                            onChange={e => {
                              settipoPersonal(e.target.value)
                            }}
                            defaultChecked={''}
                            required
                            disabled={isView || isTH ? true : false}
                          />
                          Operativo
                        </label>
                      </th>
                    </tr>
                    <tr>
                      <th style={{ width: '15%' }}>
                        <label>
                          <input
                            style={{ width: '20px', right: '30px' }}
                            type={'radio'}
                            name={'tipoContrato'}
                            value={'COL'}
                            checked={tipoContrato === 'COL' ? 'checked' : ''}
                            onChange={e => {
                              settipoContrato(e.target.value)
                              setHoldTC(true)
                              setterminoInicial('Indeterminado')
                              settiempoAdaptacion('60 días')
                            }}
                            defaultChecked={''}
                            required
                            disabled={isView || isTH ? true : false}
                          />
                          A Obra o Labor
                        </label>
                      </th>
                      <th style={{ width: '15%' }}>
                        <label>
                          <input
                            style={{ width: '20px', right: '30px' }}
                            type={'radio'}
                            name={'tipoContrato'}
                            value={'CAP'}
                            checked={tipoContrato === 'CAP' ? 'checked' : ''}
                            onChange={e => {
                              settipoContrato(e.target.value)
                              setHoldTC(true)
                              setterminoInicial('Indeterminado')
                              settiempoAdaptacion('no aplica')
                            }}
                            required
                            disabled={isView || isTH ? true : false}
                          />
                          Aprendizaje
                        </label>
                      </th>
                      <th style={{ width: '15%' }}>
                        <label>
                          <input
                            style={{ width: '20px', right: '30px' }}
                            type={'radio'}
                            name={'origenCargo'}
                            value={'NoContractual'}
                            checked={origenCargo === 'NoContractual' ? 'checked' : ''}
                            onChange={e => {
                              setorigenCargo(e.target.value)

                            }}
                            defaultChecked={''}
                            required
                            disabled={isView || isTH ? true : false}
                          />

                          No contractual
                        </label>
                      </th>
                      <th style={{ width: '15%' }}>
                        <label>
                          <input
                            style={{ width: '20px', right: '30px' }}
                            type={'radio'}
                            name={'tipoPersonal'}
                            value={'Administrativo'}
                            checked={tipoPersonal === 'Administrativo' ? 'checked' : ''}
                            onChange={e => {
                              settipoPersonal(e.target.value)
                            }}
                            required
                            disabled={isView || isTH ? true : false}
                          />
                          Administrativo
                        </label>
                      </th>
                    </tr>
                    <tr>
                      <th colSpan={2} style={{ width: '30%' }}>
                        Término inicial del contrato:
                      </th>
                      <th colSpan={2} style={{ width: '25%' }}>

                        <select
                          value={terminoInicial}
                          onChange={e => { setterminoInicial(e.target.value); setterminoInicial(e.target.value); settiempoAdaptacion(e.target.selectedOptions[0].id) }}
                          required
                          disabled={isView || isTH || holdTC ? true : false}
                          title="seleccione.."
                        >{holdTC ? <option key={0}>{terminoInicial}</option> : <><option key={0}></option>

                          {TerminoContrato.map((article, i) => {
                            return (
                              <option key={i + 1} id={article.prueba} value={article.periodo}>{article.periodo} </option>
                            );
                          })}</>}
                        </select>
                      </th>
                      <th>Tipo Nómina</th>
                      <th colSpan={2}>
                        <Row>
                          <Col>
                            <label style={{ width: '25px', right: '10px' }}>
                              Legal
                              <input
                                style={{ width: '20px' }}
                                type={'radio'}
                                name={'tipoNomina'}
                                value={'Legal'}
                                checked={`${tipoNomina}` === 'Legal' ? 'checked' : ''}
                                onChange={e => {
                                  settipoNomina(e.target.value)
                                }}
                                required
                                disabled={isView || isTH ? true : false}
                              />
                            </label>
                          </Col>
                          <Col>
                            <label style={{ width: '25px', right: '10px', paddingRight: '20px' }}>
                              Convencional
                              <input
                                style={{ width: '20px', paddingLeft: '20px' }}
                                type={'radio'}
                                name={'tipoNomina'}
                                value={'Convencional'}
                                checked={tipoNomina === 'Convencional' ? 'checked' : ''}
                                onChange={e => {
                                  settipoNomina(e.target.value)
                                }}
                                required
                                disabled={isView || isTH ? true : false}
                              />
                            </label>
                          </Col>


                        </Row>
                      </th>
                    </tr>
                    <tr>
                      <th colSpan={2} style={{ width: '15%' }}>
                        Tiempo de adaptación al cargo:
                      </th>
                      <th colSpan={2}>
                        <input
                          className="disable"
                          value={tiempoAdaptacion}
                          onChange={e => {
                            settiempoAdaptacion(e.target.value)
                          }}
                          required
                          disabled
                        />

                      </th>
                      <th>Turno:</th>
                      <th colSpan={2}>
                        <Row>
                          <Col>
                            <label style={{ width: '25px', right: '10px' }}>
                              <input
                                style={{ width: '15px' }}
                                type={'radio'}
                                name={'turno'}
                                value={'5x2'}
                                checked={turno === '5x2' ? 'checked' : ''}
                                onChange={e => {
                                  setturno(e.target.value)
                                }}
                                required
                                disabled={isView || isTH ? true : false}
                              />
                              5X2
                            </label>
                          </Col>
                          <Col>
                            <label style={{ width: '25px', right: '10px' }}>
                              <input
                                style={{ width: '15px' }}
                                type={'radio'}
                                name={'turno'}
                                value={'14x7'}
                                checked={turno === '14x7' ? 'checked' : ''}
                                onChange={e => {
                                  setturno(e.target.value)
                                }}
                                required
                                disabled={isView || isTH ? true : false}
                              />
                              14X7
                            </label>
                          </Col>
                          <Col>
                            <label style={{ width: '25px', right: '10px' }}>
                              <input
                                style={{ width: '15px' }}
                                type={'radio'}
                                name={'turno'}
                                value={'20x10'}
                                checked={turno === '20x10' ? 'checked' : ''}
                                onChange={e => {
                                  setturno(e.target.value)
                                }}
                                required
                                disabled={isView || isTH ? true : false}
                              />
                              20X10
                            </label>
                          </Col>
                          <Col>
                            <label style={{ width: '25px', right: '5px' }}>
                              <input
                                style={{ width: '15px' }}
                                type={'radio'}
                                name={'turno'}
                                value={'14x14'}
                                checked={turno === '14x14' ? 'checked' : ''}
                                onChange={e => {
                                  setturno(e.target.value)
                                }}
                                required
                                disabled={isView || isTH ? true : false}
                              />
                              14X14
                            </label>
                          </Col>
                        </Row>
                      </th>
                    </tr>
                    <tr>
                      <th colSpan={4} style={{ width: '25%' }}>
                        <label>
                          <input
                            style={{ width: '20px', right: '30px' }}
                            type={'checkbox'}
                            checked={funcionConductor ? 'checked' : ''}
                            onChange={e => {
                              setfuncionConductor(!funcionConductor)
                            }}
                            disabled={isView || isTH ? true : false}
                          />
                          Funciones de Conductor
                        </label>
                      </th>
                      <th>Riesgo ARL</th>
                      <th colSpan={2}>
                        <Row>
                          <Col>
                            <label style={{ width: '25px', right: '10px' }}>
                              1
                              <input
                                style={{ width: '20px' }}
                                type={'radio'}
                                name={'RiesgoARL'}
                                value={'1'}
                                checked={`${tipoRiesgoARL}` === '1' ? 'checked' : ''}
                                onChange={e => {
                                  settipoRiesgoARL(e.target.value)
                                }}
                                required
                                disabled={isView || isTH ? true : false}
                              />
                            </label>
                          </Col>
                          <Col>
                            <label style={{ width: '25px', right: '10px' }}>
                              2
                              <input
                                style={{ width: '20px' }}
                                type={'radio'}
                                name={'RiesgoARL'}
                                value={'2'}
                                checked={`${tipoRiesgoARL}` === '2' ? 'checked' : ''}
                                onChange={e => {
                                  settipoRiesgoARL(e.target.value)
                                }}
                                required
                                disabled={isView || isTH ? true : false}
                              />
                            </label>
                          </Col>
                          <Col>
                            <label style={{ width: '25px', right: '10px' }}>
                              3
                              <input
                                style={{ width: '20px' }}
                                type={'radio'}
                                name={'RiesgoARL'}
                                value={'3'}
                                checked={`${tipoRiesgoARL}` === '3' ? 'checked' : ''}
                                onChange={e => {
                                  settipoRiesgoARL(e.target.value)
                                }}
                                required
                                disabled={isView || isTH ? true : false}
                              />
                            </label>
                          </Col>
                          <Col>
                            <label style={{ width: '25px', right: '10px' }}>
                              4
                              <input
                                style={{ width: '20px' }}
                                type={'radio'}
                                name={'RiesgoARL'}
                                value={'4'}
                                checked={`${tipoRiesgoARL}` === '4' ? 'checked' : ''}
                                onChange={e => {
                                  settipoRiesgoARL(e.target.value)
                                }}
                                required
                                disabled={isView || isTH ? true : false}
                              />
                            </label>
                          </Col>
                          <Col>
                            <label style={{ width: '25px', right: '10px' }}>
                              5
                              <input
                                style={{ width: '20px' }}
                                type={'radio'}
                                name={'RiesgoARL'}
                                value={'5'}
                                checked={`${tipoRiesgoARL}` === '5' ? 'checked' : ''}
                                onChange={e => {
                                  settipoRiesgoARL(e.target.value)
                                }}
                                required
                                disabled={isView || isTH ? true : false}
                              />
                            </label>
                          </Col>
                        </Row>
                      </th>
                    </tr>
                    <tr>
                      <th colSpan={4}>
                        <label>
                          <input
                            style={{ width: '20px', right: '30px' }}
                            type={'checkbox'}
                            checked={espaciosConfinados ? 'checked' : ''}
                            onChange={e => {
                              setespaciosConfinados(!espaciosConfinados)
                            }}
                            disabled={isView || isTH ? true : false}
                          />
                          Funciones en espacios confinados
                        </label>
                      </th>
                      <th colSpan={3}>
                        <label>
                          <input
                            style={{ width: '20px', right: '30px' }}
                            type={'checkbox'}
                            checked={trabajoAlturas ? 'checked' : ''}
                            onChange={e => {
                              settrabajoAlturas(!trabajoAlturas)
                            }}
                            disabled={isView || isTH ? true : false}
                          />
                          Funciones en alturas
                        </label>
                      </th>
                    </tr>
                    <tr>
                      <th colSpan={2}>
                        <label>
                          <input
                            style={{ width: '20px', right: '30px' }}
                            type={'checkbox'}
                            checked={medicinaPrepagada ? 'checked' : ''}
                            onChange={e => {
                              setmedicinaPrepagada(!medicinaPrepagada)
                            }}
                            disabled={isView || isTH ? true : false}
                          />
                          Medicina Prepagada
                        </label>
                      </th>
                      <th colSpan={2}>
                        <label>
                          <input
                            style={{ width: '20px', right: '30px' }}
                            type={'checkbox'}
                            checked={seguroExequial ? 'checked' : ''}
                            onChange={e => {
                              setseguroExequial(!seguroExequial)
                            }}
                            disabled={isView || isTH ? true : false}
                          />
                          Seguro Exequial
                        </label>
                      </th>
                      <th colSpan={32}>
                        <label>
                          <input
                            style={{ width: '20px', right: '30px' }}
                            type={'checkbox'}
                            checked={seguroVIda ? 'checked' : ''}
                            onChange={e => {
                              setseguroVIda(!seguroVIda)
                            }}
                            disabled={isView || isTH ? true : false}
                          />
                          Seguro de Vida
                        </label>
                      </th>
                    </tr>
                    <tr>
                      <th colSpan={2}>Alimentación:</th>
                      <th colSpan={5}>
                        <Row>
                          <Col>
                            <label>
                              <input
                                style={{ width: '20px', right: '30px' }}
                                type={'checkbox'}
                                checked={desayuno ? 'checked' : ''}
                                onChange={e => {
                                  setdesayuno(!desayuno)
                                  setnoAplicaAlimentacion(false)
                                }}
                                disabled={isView || isTH ? true : false}
                              />
                              Desayuno
                            </label>
                          </Col>
                          <Col>
                            <label>
                              <input
                                style={{ width: '20px', right: '30px' }}
                                type={'checkbox'}
                                checked={almuerzo ? 'checked' : ''}
                                onChange={e => {
                                  setalmuerzo(!almuerzo)
                                  setnoAplicaAlimentacion(false)
                                }}
                                disabled={isView || isTH ? true : false}
                              />
                              Almuerzo
                            </label>
                          </Col>
                          <Col>
                            <label>
                              <input
                                style={{ width: '20px', right: '30px' }}
                                type={'checkbox'}
                                checked={cena ? 'checked' : ''}
                                onChange={e => {
                                  setcena(!cena)
                                  setnoAplicaAlimentacion(false)
                                }}
                                disabled={isView || isTH ? true : false}
                              />
                              Cena
                            </label>
                          </Col>
                          <Col>
                            <label>
                              <input
                                style={{ width: '20px', right: '30px' }}
                                type={'checkbox'}
                                name={'noAplica'}
                                checked={noAplicaAlimentacion ? 'checked' : ''}
                                onChange={e => {
                                  setnoAplicaAlimentacion(!noAplicaAlimentacion)
                                  setdesayuno(false)
                                  setalmuerzo(false)
                                  setcena(false)
                                }}
                                disabled={isView || isTH ? true : false}
                              />
                              N/A
                            </label>
                          </Col>
                        </Row>
                      </th>
                    </tr>
                  </tbody>
                </table>
              </Frame>

              <Frame isView={isView || isTH}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <th colSpan={4} style={{ width: '100%' }}>
                        <h2>5. RECURSOS DE TRABAJO</h2>
                      </th>
                    </tr>
                    <tr>
                      <th style={{ width: '20%' }}>
                        <label>
                          <input
                            style={{ width: '20px', right: '30px' }}
                            type={'checkbox'}
                            name={'computador:'}
                            checked={computador ? 'checked' : ''}
                            onChange={e => {
                              setcomputador(!computador)
                            }}
                            disabled={isView || isTH ? true : false}
                          />
                          Computador
                        </label>
                      </th>
                      <th style={{ width: '20%' }}>
                        <label>
                          <input
                            style={{ width: '20px', right: '30px' }}
                            type={'checkbox'}
                            name={'telefonoMovil'}
                            checked={telefonoMovil ? 'checked' : ''}
                            onChange={e => {
                              settelefonoMovil(!telefonoMovil)
                            }}
                            disabled={isView || isTH ? true : false}
                          />
                          Teléfono móvil
                        </label>
                      </th>

                      <th style={{ width: '20%' }}>
                        <label>
                          <input
                            style={{ width: '20px', right: '30px' }}
                            type={'checkbox'}
                            name={'pantalla'}
                            checked={pantalla ? 'checked' : ''}
                            onChange={e => {
                              setpantalla(!pantalla)
                            }}
                            disabled={isView || isTH ? true : false}
                          />
                          Pantalla
                        </label>
                      </th>
                      <th style={{ width: '40%' }}>
                        <label>
                          <input
                            style={{ width: '10px', right: '30px', marginRight: '4px' }}
                            type={'checkbox'}
                            name={'eMailCheck'}
                            checked={eMailCheck ? 'checked' : ''}
                            onChange={e => {
                              seteMailCheck(!eMailCheck)
                              if (eMailCheck) seteMail('')
                            }}
                            disabled={isView || isTH ? true : false}
                          />
                          e-mail:

                          <input style={{ width: '220px', marginLeft: '30px', right: '10px' }}
                            className={isView || isTH || !eMailCheck ? 'disable' : ''}
                            type={'text'}
                            value={eMail}
                            onChange={e => {
                              seteMail(e.target.value)

                            }}
                            required={eMailCheck ? true : false}
                            disabled={isView || isTH || !eMailCheck ? true : false}
                          />
                        </label>
                      </th>
                    </tr>
                    <tr>

                      <th >
                        <label>
                          <input
                            style={{ width: '20px', right: '30px' }}
                            type={'checkbox'}
                            name={'puestoTrabajo'}
                            checked={puestoTrabajo ? 'checked' : ''}
                            onChange={e => {
                              setpuestoTrabajo(!puestoTrabajo)
                            }}
                            disabled={isView || isTH ? true : false}
                          />
                          Puesto de trabajo
                        </label>
                      </th>
                      <th >
                        <label>
                          <input
                            style={{ width: '20px', right: '30px' }}
                            type={'checkbox'}
                            name={'elementosOficina'}
                            checked={elementosOficina ? 'checked' : ''}
                            onChange={e => {
                              setelementosOficina(!elementosOficina)
                            }}
                            disabled={isView || isTH ? true : false}
                          />
                          Elementos de oficina
                        </label>
                      </th>   <th >
                        <label>
                          <input
                            style={{ width: '20px', right: '30px' }}
                            type={'checkbox'}
                            name={'discoDuro'}
                            checked={discoDuro ? 'checked' : ''}
                            onChange={e => {
                              setdiscoDuro(!discoDuro)
                            }}
                            disabled={isView || isTH ? true : false}
                          />
                          Disco Duro
                        </label>
                      </th><th >
                        <label>
                          <input
                            style={{ width: '10px', right: '20px', marginRight: '4px' }}
                            type={'checkbox'}
                            name={'licenciasCheck'}
                            checked={licenciasCheck ? 'checked' : ''}
                            onChange={e => {
                              setlicenciasCheck(!licenciasCheck)
                              if (licenciasCheck) setlicencias('')
                            }}
                            disabled={isView || isTH ? true : false}
                          />
                          Licencias:

                          <input style={{ width: '220px', marginLeft: '15px', right: '10px' }}
                            className={isView || isTH || !licenciasCheck ? 'disable' : ''}
                            type={'text'}
                            value={licencias}
                            onChange={e => {
                              setlicencias(e.target.value)

                            }}
                            required={licenciasCheck ? true : false}
                            disabled={isView || isTH || !licenciasCheck ? true : false}
                          />
                        </label>
                      </th>
                    </tr>
                    <tr>
                      <th >
                        <label>Otro:</label>
                      </th>
                      <th colSpan={3} >
                        <input
                          value={OtroRecurso}
                          onChange={e => {
                            setOtroRecurso(e.target.value)
                          }}
                          disabled={isView || isTH ? true : false}
                          list="dtOtroRecurso"
                        />
                        <datalist id="dtOtroRecurso">{list.listaOtroRecurso}</datalist>
                      </th>
                    </tr>
                  </tbody>
                </table>
              </Frame>
              <Frame isView={isView || isTH}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <th colSpan={5} style={{ width: '100%' }}>
                        <h2>6. REVISIÓN, AUTORIZACIÓN Y APROBACIÓN</h2>
                      </th>
                    </tr>
                    <tr>
                      <th style={{ width: '10%' }}></th>
                      <th style={{ width: '30%', textAlign: 'center' }}>Nombre</th>
                      <th style={{ width: '30%', textAlign: 'center' }}>Cargo</th>
                      <th style={{ width: '20%', textAlign: 'center' }}>Firma</th>
                      <th style={{ width: '10%', textAlign: 'center' }}>Fecha</th>
                    </tr>
                    <tr style={{ height: '55px' }}>
                      <th style={{ width: '10%' }}>Solicitante</th>
                      <th style={{ width: '30%' }}>
                        <input
                          onChange={e => { }}
                          className="disable"
                          value={firmaSolicitante.NombreTrabajador + " " + firmaSolicitante.ApellidoTrabajador}

                          required
                          disabled
                        />
                      </th>
                      <th style={{ width: '30%' }}>
                        <input
                          onChange={e => { }}
                          className="disable"
                          value={firmaSolicitante.cargo}
                          required
                          disabled
                        />
                      </th>
                      <th style={{ alignItems: 'center' }}>
                        <Signer
                          enable={(Status === 'creando' || Status === 'creado') && sessionUser.IdEmployee_FK === firmaSolicitante.IdEmployee_FK}
                          sign={firmaSolicitante}
                          setSign={setfirmaSolicitante}
                          doc={type}
                          user={sessionUser.IdEmployee_FK}
                        />
                      </th>
                      <th style={{ width: '10%' }}>
                        <input
                          onChange={e => { }} className="disable" value={firmaSolicitante.fecha} required disabled={true} />
                      </th>
                    </tr>
                    <tr style={{ height: '55px' }}>
                      <th style={{ width: '10%' }}>Revisión</th>
                      <th style={{ width: '30%' }}>
                        <input
                          onChange={e => { }}
                          className="disable"
                          value={firmaRevision.NombreTrabajador + " " + firmaRevision.ApellidoTrabajador}
                          disabled
                        />
                      </th>
                      <th style={{ width: '30%' }}>
                        <input
                          className="disable"
                          value={firmaRevision.cargo}
                          disabled
                        />
                      </th>
                      <th style={{ alignItems: 'center' }}>
                        <Signer
                          enable={Status === 'creado' && isSign && sessionUser.IdEmployee_FK === firmaRevision.IdEmployee_FK}
                          sign={firmaRevision}
                          setSign={setfirmaRevision}
                          doc={type}
                          user={sessionUser.IdEmployee_FK}
                        />
                      </th>
                      <th style={{ width: '10%' }}>
                        <input
                          onChange={e => { }}
                          className="disable"
                          value={firmaRevision.fecha}
                          disabled
                        />
                      </th>
                    </tr>
                    <tr style={{ height: '55px' }}>
                      <th style={{ width: '10%' }}>Aprobación</th>
                      <th style={{ width: '30%' }}>
                        <input
                          className="disable"
                          value={firmaAprobador.NombreTrabajador + " " + firmaAprobador.ApellidoTrabajador}
                          disabled
                        />
                      </th>
                      <th style={{ width: '30%' }}>
                        <input
                          onChange={e => { }}
                          className="disable"
                          value={firmaAprobador.cargo}
                          disabled
                        />
                      </th>
                      <th style={{ alignItems: 'center' }}>
                        <Signer
                          enable={isSign && sessionUser.IdEmployee_FK === firmaAprobador.IdEmployee_FK}
                          sign={firmaAprobador}
                          setSign={setfirmaAprobador}
                          doc={type}
                          user={sessionUser.IdEmployee_FK}
                        />
                      </th>
                      <th style={{ width: '10%' }}>
                        <input
                          onChange={e => { }}
                          className="disable"
                          value={firmaAprobador.fecha}
                          disabled
                        />
                      </th>
                    </tr>
                  </tbody>
                </table>
              </Frame>
              <Frame isView={(isView && !isTH) || data.Status === "finalizado"}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <th colSpan={6} style={{ width: '100%' }}>
                        <h2>7. RECURSOS HUMANOS</h2>
                      </th>
                    </tr>

                    <tr>
                      <th colSpan={2} style={{ width: '28%' }}>
                        Nombre del candidato seleccionado:
                      </th>
                      <td colSpan={4}>nombres:
                        <input
                          style={{ width: "35%" }}
                          className={!isView || isTH && data.Status !== "finalizado" ? 'edit' : 'disable'}
                          value={nombreCandidato}
                          onChange={e => {
                            setnombreCandidato(capitalLeter(e.target.value, 0));
                          }}
                          disabled={isView & !isTH || data.Status === "finalizado" ? true : false}
                        />
                        .   apellidos:
                        <input
                          style={{ width: "35%" }}

                          className={!isView || isTH && data.Status !== "finalizado" ? 'edit' : 'disable'}
                          value={apellidoCandidato}
                          onChange={e => {
                            setapellidoCandidato(capitalLeter(e.target.value, 0))
                          }}
                          disabled={isView & !isTH || data.Status === "finalizado" ? true : false}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th colSpan={2}>Cedula de ciudadania No.</th>
                      <th colSpan={1}>
                        <input
                          className={!isView || isTH && data.Status !== "finalizado" ? 'edit' : 'disable'}
                          value={dniCandidato}
                          onChange={e => {
                            setdniCandidato(onlyNumeric(e.target.value));
                          }}
                          disabled={isView & !isTH || data.Status === "finalizado" ? true : false}
                        />
                      </th>
                      <th colSpan={2} style={{ textAlign: 'center' }}>de</th>
                      <td style={{ textAlign: 'center' }} >
                        {isView & !isTH || data.Status === "finalizado" ? cityName(expedicionCandidato) : <SelectOption
                          type={"city"} searchable={true} state={expedicionCandidato} setState={setexpedicionCandidato} required disabled={false} />


                        }
                      </td>

                    </tr>
                    <tr>
                      <th colSpan={2} > Fecha efectiva de ingreso: </th>
                      <th colSpan={1} >
                        <input
                          data-provide="datepicker"
                          type={'date'}
                          value={ingresoEfectivo}
                          onChange={e => {
                            setingresoEfectivo(e.target.value)
                          }}
                          disabled={isView & !isTH || data.Status === "finalizado" ? true : false}
                        />
                      </th>
                    </tr>
                    <tr colSpan={6} style={{ width: '100%' }}>
                      <td>Espacio esclusivo para talento humano</td>
                    </tr>
                    <tr>
                      <th colSpan={2} style={{ textAlign: 'center' }}>
                        Seleccion de Candidato
                      </th>
                      <th colSpan={1}>
                        <input
                          className={!isTH || data.Status === "finalizado" ? 'disable' : 'edit'}
                          value={seleccionCandidato}
                          onChange={e => {
                            setseleccionCandidato(e.target.value)
                          }}
                          disabled={isTH && data.Status !== "finalizado" ? false : true}
                        />
                      </th>
                      <th colSpan={2} style={{ textAlign: 'center' }}>
                        Codigo vacante de postulado
                      </th>
                      <th colSpan={1}>
                        <input
                          className={!isTH || data.Status === "finalizado" ? 'disable' : 'edit'}
                          value={numeroVacante}
                          onChange={e => {
                            setnumeroVacante(e.target.value)
                          }}
                          disabled={isTH && data.Status !== "finalizado" ? false : true}
                        />
                      </th>
                    </tr>
                  </tbody>
                </table>
              </Frame>
              <Frame isView={isView || isTH}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <th colSpan={5} style={{ width: '100%' }}>
                        <h2>8. INFORMACIÓN SALARIAL</h2>
                      </th>
                    </tr>
                    <tr>
                      <th style={{ width: '30%' }}>Salario:</th>
                      <th style={{ width: '15%', textAlign: 'right' }}>Básico </th>
                      <th style={{ width: '20%', textAlign: 'center' }}>
                        <input
                          
                          value={monedaCOP(salarioBasico)}
                          onChange={e => {
                            setsalarioBasico(onlyNumeric(e.target.value))
                            setsalarioIntegral(0)
                            setEsIntegral(true)
                            setprimaAlimentacion(0)
                            setprimaMovilizacion(0)
                            setprimaLocalizacion(0)
                            settextoOtroSalario('ninguno')
                            setotroSalario(0)
                          }}
                          required={esIntegral ? true : false}
                          disabled={isView || isTH ? true : false}
                        />
                      </th>
                      <th style={{ width: '15%', textAlign: 'right' }}>Integral </th>
                      <th style={{ width: '20%' }}>
                        <input
                          
                          value={monedaCOP(salarioIntegral)}
                          onChange={e => {
                            setsalarioIntegral(onlyNumeric(e.target.value))
                            setsalarioBasico(0)
                            setEsIntegral(false)
                            setprimaAlimentacion('')
                            settextoOtroSalario('')
                            setprimaMovilizacion('')
                            setprimaLocalizacion('')
                            setotroSalario('')
                          }}
                          required={!esIntegral ? true : false}
                          disabled={isView || isTH ? true : false}
                        />
                      </th>
                    </tr>
                    <tr>
                      <th style={{ width: '30%' }}>Alimentación: </th>
                      <th colSpan={4} style={{ width: '70%' }}>
                        <input
                          className={isView || isTH || !esIntegral ? 'disable' : ''}
                          
                          value={monedaCOP(primaAlimentacion)}
                          onFocus={e=>setprimaAlimentacion('')}
                          onChange={e => { setprimaAlimentacion(onlyNumeric(e.target.value)) }}
                          required={esIntegral ? true : false}
                          disabled={isView || isTH || !esIntegral ? true : false}
                        />
                      </th>
                    </tr>
                    <tr>
                      <th style={{ width: '30%' }}>Movilización: </th>
                      <th colSpan={4} style={{ width: '70%' }}>
                        <input
                          className={isView || isTH || !esIntegral ? 'disable' : ''}
                          
                          value={monedaCOP(primaMovilizacion)}
                          onFocus={e=>setprimaMovilizacion('')}
                          onChange={e => {
                            setprimaMovilizacion(onlyNumeric(e.target.value))
                          }}
                          required={esIntegral ? true : false}
                          disabled={isView || isTH || !esIntegral ? true : false}

                        />
                      </th>
                    </tr>
                    <tr>
                      <th >Localización: </th>
                      <th colSpan={4} style={{ width: '70%' }}>
                        <input
                          className={isView || isTH || !esIntegral ? 'disable' : ''}
                         
                          value={monedaCOP(primaLocalizacion)}
                          onFocus={e => {setprimaLocalizacion('')}}
                          onChange={e => {
                            setprimaLocalizacion(onlyNumeric(e.target.value))
                          }}
                          required={esIntegral ? true : false}
                          disabled={isView || isTH || !esIntegral ? true : false}
                        />
                      </th>
                    </tr>
                    <tr>
                      <th style={{ width: '20%' }}>
                        Otros:
                        <input
                          className={isView || isTH || !esIntegral ? 'disable' : ''}
                          style={{ width: '80%' }}
                          value={textoOtroSalario}

                          onFocus={e => { settextoOtroSalario('')}}
                          onChange={e => { settextoOtroSalario(e.target.value)}}
                          required={esIntegral ? true : false}
                          disabled={isView || isTH || !esIntegral ? true : false}
                        />
                        
                      </th>
                      <th colSpan={4} style={{ width: '70%' }}>
                        <input
                          className={isView || isTH || !esIntegral ? 'disable' : ''}
                          value={monedaCOP(otroSalario)}

                          onFocus={e => { setotroSalario('') }}
                          onChange={e => { setotroSalario(onlyNumeric(e.target.value)) }}
                          disabled={isView || isTH || !esIntegral ? true : false}
                        />
                      </th>
                    </tr>
                  </tbody>
                </table>
              </Frame>
              <Row className='no-print'>
                <Col></Col>
                <Col></Col>
                <Col></Col>
                <Col></Col>

                {isSign || !isView || (isTH && data.Status !== "finalizado") ? (
                  <>
                    <Col>
                      <Button
                        className="btn btn-danger"
                        onClick={() => {
                          clearStates()
                          hide(!show)
                        }}
                      >
                        Cancelar
                      </Button>
                    </Col>
                    <Col>
                      <Button type="submit" className="btn btn-success">
                        Guardar
                      </Button>
                    </Col>
                  </>
                ) : (<> <Col>

                  <Col>
                    <Button
                      className="btn btn-info"
                      onClick={convertToPdf}
                    >
                      <i className="bi bi-printer"></i>
                    </Button>

                  </Col>
                </Col>
                  <Col>
                    <Button
                      className="btn btn-secundary"
                      onClick={() => {
                        clearStates()
                        hide(!show)
                      }}
                    >
                      Cerrar
                    </Button>
                  </Col></>
                )}
              </Row>
            </Form>
            {ShowManuales &&
              <GTHFOR076
                sessionUser={sessionUser}
                data={dataManual}
                setData={setDataManual}
                show={ShowManuales}
                hide={hideManuales}
                Status={"isView"}

                isTH={false}
                restart={restart}
                setRestart={setRestart}
                isView={true}
                IdFormat_PK={manualFunciones}
              />}
          </ModalContainer>
        </Overlay>
      )}
    </>
  )
}


export default GTHFOR070

const Frame = styled.div`
width: 20.3cm;
  padding-bottom: 0px;
  margin-bottom: 4px;
  align-items: center;
  justify-content: left;
  border-width: 1px;
  border-color: #1a004a;
  border-style: solid;
  font-size: 9px;
  font-weight: 1000;
  label {
    display: flex;
  }
  select {
    height:14px;
    text-align: center;
    width: 100%;
    background: ${props => (props.isView ? '#FFF' : '#cee1ed')};
    border-width: 0px;
  
}

  input {
    text-align: center;
    width: 100%;

    background: ${props => (props.isView ? '#FFF' : '#cee1ed')};

    padding-left: -5px;
  }

  input.edit {
    background: #cee1ed;
  }
  input.disable {
    background: #fff;
  }
  select.disable {
    background: #fff;
  }
  h2 {
    font-size: 10px;
    font-weight: bold;
    margin-bottom: 2px;
  }
  th {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    text-align: left;
    vertical-align: center;
    padding-left: 5px;
    input {
      width: 100%;
    }
  }
  tr {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
  }
`

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgb(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalContainer = styled.div`
width: 21,6cm;
  max-height: 90vh;
  min-height: 90vh;
  background: #fff;
  position: relative;
  border-radius: 5px;
  overflow-x: hidden;
  overflow-y: scroll;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 15px;

  @media print {
    padding-top: 20px;
    padding-left: 25px;
    max-height: 100vh;
    min-height: 100vh;
    min-height: 27.94cm;
    width: 100%;
    height: 27.94cm;
    /* Estilos para la versión impresa */
    overflow: hidden;
    /* Aquí ocultamos los componentes que no queremos imprimir */
    @page {
      size: letter; /* Puedes usar diferentes tamaños de papel como A4, Letter, etc. */
      margin: 0; /* Márgenes laterales en 0 */
      padding: 0px;
      orientation: portrait ; /* Cambiar a 'landscape' si necesitas orientación horizontal */
    }
     
      /* Ajustar el área de impresión */
    .no-print {
      display: none;
    }
} 
`

const ModalHeader = styled.div`
width: 20.3cm;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -6px;
  padding-bottom: -6px;

  th {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
  }
  tr {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
  }
  table {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    text-align: center;
    vertical-align: center;
    font-size: 12px;
  }
  h3 {
    font-weight: 500;
    font-size: 14px;
    color: #1766dc;
  }
`

const CloseButtonModal = styled.div`
  position: absolute;
  top: 2px;
  right: 5px;
  width: 20px;
  height: 20px;
  border: none;
  background: none;
  cursor: pointer;
  transition: 0.3s ease all;
  border-radius: 5px;
  color: #1766dc;
  &:hover {
    background: #f2f2f2;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`
