import React, { useEffect, useState, useRef } from 'react'
import DataTable from 'react-data-table-component'
import { toast } from 'react-toastify'
import { decodeUserPermission, encodeUserPermission, permissionInit, sessionActive } from '../../lib/functions'
import { Col, Row, Button, Form, Modal, FloatingLabel, Table } from 'react-bootstrap'
import { CreateUserF, EditingUserF, getUserByIdF, getUsersF, setPermissionF } from '../../lib/request'
import { getFormats } from '../../lib/formatos/request'
import SpinnerLoad from '../../lib/spinnerLoad'


export const User = ({ newBotton, sessionUser }) => {
    const chkbtn = useRef()
    const [users, setUsers] = useState([]);
    const [companies, setCompanies] = useState([]);
    // const [IdCompany_FK, setIdCompany_FK] = useState('')
    const IdUser_PK = localStorage.getItem('IdUser_PK')
    const [Title, setTitle] = useState();
    const [cbFilter, setcbFilter] = useState(true)
    const [ModalUser, setModalUser] = useState(false);
    const [ModalPassword, setModalPassword] = useState(false);
    const [ModalRole, setModalRole] = useState(false);
    const [ModalToken, setModalToken] = useState(false);
    const [ModalStatus, setModalStatus] = useState(false);
    const [IsEditing, setIsEditing] = useState(false);
    // const [mainCompany, setMainCompany] = useState([])
    const [action, setAction] = useState("")
    const [newPassword, setNewPassword] = useState("");
    const [put, setPut] = useState(false);
    const [_delete, set_delete] = useState(false);
    const [post, setPost] = useState(false);
    const [userPermissionTable, setUserPermissionTable] = useState(false)
    const [MyRollDecryp, setMyRollDecryp] = useState()
    const [originalUsers, setOriginalUsers] = useState("")
    const [restart, setRestart] = useState(false)
    const [showForm, setShowForm] = useState(false)
    const [data, setData] = useState({})
    const [filterindItems, setFilterindItems] = useState([])
    const [search, setSearch] = useState()
    // const [userPermission, setUserPermission] = useState('')
    let isSuperadmin = false
    const Roles = [
        {
            id: 'user',
            name: 'Usuarios',
            weight: 5
        },
        {
            id: 'companies',
            name: 'Empresas',
            weight: 5
        },

        {
            id: 'employes',
            name: 'Empleado',
            weight: 5
        },
        {
            id: 'client',
            name: 'Cliente',
            weight: 10
        },
        {
            id: 'hseq',
            name: 'HSEQ',
            weight: 20
        },
        {
            id: 'technical',
            name: 'Técnico',
            weight: 30
        },
        {
            id: 'commercial',
            name: 'Comercial',
            weight: 40
        }, {
            id: 'supplychain',
            name: 'Compras',
            weight: 41
        },


        {
            id: 'logistic',
            name: 'Logística',
            weight: 45
        },

        {
            id: 'accounting',
            name: 'Contabilidad',
            weight: 50
        },
        {
            id: 'operations',
            name: 'Operaciones',
            weight: 55
        },
        {
            id: 'leader',
            name: 'Lider',
            weight: 60
        },
        {
            id: 'humanTalent',
            name: 'Talento Humano',
            weight: 70
        },
        {
            id: 'manager',
            name: 'Gerente',
            weight: 80
        },
        {
            id: 'admin',
            name: 'Administrador',
            weight: 90
        },
        {
            id: 'superAdmin',
            name: 'SuperAdmin',
            weight: 100
        },
    ]
    const maxRollF = (userPermission) => {
        if (userPermission.superAdmin.access) return { name: "superAdmin", value: 100 }
        else if (userPermission.admin.access) return { name: "admin", value: 90 }
        else if (userPermission.manager.access) return { name: "gerente", value: 80 }
        else if (userPermission.humanTalent.access) return { name: "talentoHumano", value: 70 }
        else if (userPermission.leader.access) return { name: "lider", value: 60 }
        else if (userPermission.accounting.access) return { name: "contabilidad", value: 40 }
        else if (userPermission.supplychain.access) return { name: "compras", value: 40 }
        else if (userPermission.technical.access) return { name: "tecnico", value: 40 }
        else if (userPermission.logistic.access) return { name: "logistica", value: 40 }
        else if (userPermission.commercial.access) return { name: "comercial", value: 40 }
        else if (userPermission.companies.access) return { name: "companies", value: 40 }
        else if (userPermission.client.access) return { name: "cliente", value: 10 }
        else return { name: "Basico", value: 0 }

    }
    useEffect(() => {
        sessionActive()
        Permissions()
        fillTable()

    }, [restart])

    const fillTable = async () => {
        let mainCompany = []
        const cryptword = localStorage.getItem('maxRoll')
        const key = localStorage.getItem('IdUser_PK')

        setMyRollDecryp(decodeUserPermission(cryptword, key))


        //Obtener lista de Empresas
        var resCompanies = await getFormats('company')
        if (resCompanies.data.status === 200) {
            mainCompany = resCompanies.data.data.find(i => i.MainCompany === "MAIN")
            // setMainCompany(mainCompany)
            var Companies = resCompanies.data.data.filter(i => newBotton ? i.MainCompany === "NO" : i.MainCompany === "MAIN")
            setCompanies(Companies)
            // setIdCompany_FK(Companies.IdEmpresa_PK)
        } else {
            toast.error(resCompanies.data.msj)
        }
        resCompanies = resCompanies.data.data

        //Obtener lista de usuarios
        var resUsers = await getUsersF();
        if (resUsers.data.status === 200) {
            resUsers = resUsers.data.data
            resUsers = isSuperadmin ? resUsers.filter(i => newBotton ? i.IdCompany_FK !== mainCompany.IdCompany_PK : i.IdCompany_FK === mainCompany.IdCompany_PK) :
                resUsers.filter(i => newBotton ? i.IdCompany_FK !== mainCompany.IdCompany_PK && i.IdJefeInm_FK === null : i.IdCompany_FK === mainCompany.IdCompany_PK && i.IdJefeInm_FK === key)

            resUsers = resUsers.map(user => ({ ...user, CompanyName: resCompanies.filter(i => i.IdCompany_PK.includes(user.IdCompany_FK))[0]["SocialName"] }))


            setOriginalUsers(resUsers)

            setUsers(resUsers.filter(i => i.Status === "active"))
            setFilterindItems(resUsers.filter(i => i.Status === "active"))

        } else {
            toast.error(resUsers.data.msj)
        }
    }
    const setFilterActvie = (active) => {

        setUsers(active ? originalUsers.filter(i => i.Status === "active") : originalUsers)
        setFilterindItems(active ? originalUsers.filter(i => i.Status === "active") : originalUsers)
    }
    const Permissions = () => {
        //Obtener Permisos de Usuario
        const resPermission = decodeUserPermission(localStorage.getItem("userPermission"), localStorage.getItem("IdUser_PK") || permissionInit)
        // setUserPermission(resPermission)
        if (resPermission.superAdmin.access) {
            isSuperadmin = true
            setPut(true); set_delete(true); setPost(true);
        } else {
            setPut(resPermission.user.PUT);
            set_delete(resPermission.user.DELETE);

            setPost(resPermission.user.POST);
        }
    }
    const OnFilter = (e) => {
        let FilterText = e.target.value;

        setSearch(FilterText)
        setFilterindItems(users.filter(
            (user) => (
                user.IdUser_PK.includes(FilterText) ||
                user.UserName.toLowerCase().includes(FilterText.toLowerCase()) ||
                user.UserLastName.toLowerCase().includes(FilterText.toLowerCase()) ||
                user.UserEmail.toLowerCase().includes(FilterText.toLowerCase()) ||
                user.IdCompany_FK.toLowerCase().includes(FilterText.toLowerCase()) ||
                user.CompanyName.toLowerCase().includes(FilterText.toLowerCase()) ||
                user.Position.toLowerCase().includes(FilterText.toLowerCase()) ||
                user.Status.toLowerCase().includes(FilterText.toLowerCase())

            )
        ))
    }
    const ClearSearch = () => {
        setSearch('')
        setFilterindItems(cbFilter ? originalUsers.filter(i => i.Status === "active") : originalUsers)
    }

    const ClearStates = () => {
        setSearch('')
        setModalUser(false)
        setData([])
        setAction("none")
        setIsEditing(false)
        setTitle("")
        setRestart(!restart)
    }
    const EditUser = () => {
        setAction("Edit")
        setIsEditing(true)
        setTitle("Editar Usuario")
    }
    const ChangePW = () => {
        setNewPassword(data.IdUser_PK + "**")
        setAction("ChangePW")
        setModalPassword(true)
        setTitle("Editar Usuario")
    }
    const ChangeStatus = () => {
        setAction("ChangeStatus")
        setModalStatus(true)
        setTitle(data.Status === "active" ? "Inactivar" : "Activar Usuario")
    }
    const tokenCode = () => {
        setAction("TokenCode")
        setModalToken(true)
        setTitle("Token de whatsapp")
    }
    const ChangeRole = async () => {
        setUserPermissionTable(decodeUserPermission(data.userPermission, data.IdUser_PK) || permissionInit) //, supplychain:{ DELETE:false, POST:false, PUT: false, access:false}})
        setAction("ChangeRole")
        setModalRole(true)
    }
    const selectRow = (data) => {
        setModalUser(true)
        setAction("view")
        setIsEditing(false)
        setData({ ...data })//, maxRoll: decodeUserPermission(data.maxRoll, data.IdUser_PK) })
        setShowForm(!showForm)
        setRestart(false)

        setUserPermissionTable(decodeUserPermission(data.userPermission, data.IdUser_PK) || permissionInit)
        setTitle('Información Usuario')
    }
    const CreateUser = () => {
        setModalUser(true)
        setAction("creating")
        setIsEditing(true)
        setData({
            IdUser_PK: '',
            IdCompany_FK: companies[0].IdCompany_PK,
            UserName: '',
            UserLastName: '',
            Position: '',
            PhoneNumber: '',
            UserEmail: '',
            maxRoll: { name: "sin asignar", value: 0 },
            Avatar: ''
        })
        setTitle('Crear Nuevo Usuario')
    }
    const handleChange = async ({ target }) => {
        const { name, type, role, id } = target
        const value = type === 'checkbox' ? target.checked : target.value

        action === "Edit" ? setData({ ...data, [name]: value }) : setNewPassword(value)

        if (role === "role") {
            if (id === "access" && value === false) {
                setUserPermissionTable({ ...userPermissionTable, [name]: { access: false, PUT: false, POST: false, DELETE: _delete } })
            } else {

                setUserPermissionTable({ ...userPermissionTable, [name]: { ...userPermissionTable[name], [id]: value } })
            }
        }
    }
    const OnSubmit = async e => {
        e.preventDefault()
        if (action === "Edit") {
            delete data.CompanyName
            delete data.Password
            delete data.dateCreated
            await getUserByIdF('IdUser_PK', IdUser_PK, false)

            if (MyRollDecryp.value > decodeUserPermission(data.maxRoll, data.IdUser_PK) ? decodeUserPermission(data.maxRoll, data.IdUser_PK).value : 0) {
                delete data.maxRoll


                const resEditing = await EditingUserF(data)
                if (resEditing.data.status === 200) {
                    toast.success(resEditing.data.msj)
                    setModalUser(false)
                } else {
                    toast.error(resEditing.data.msj)
                    return

                }
            } else {
                toast.error('No tiene permiso para esta acción')
                setModalUser(false)
            }
        } else if (action === "creating") {

            const resCreate = await CreateUserF({ ...data, Avatar: "default" })
            if (resCreate.data.status === 200) {
                toast.success(resCreate.data.msj)
                setModalUser(false)
            } else {
                toast.error(resCreate.data.msj)
                return
            }
        } else if (action === "ChangePW") {

            const resChangePW = await EditingUserF({ IdUser_PK: data.IdUser_PK, Password: newPassword })
            if (resChangePW.data.status === 200) {
                toast.success(resChangePW.data.msj)
                setAction("Edit")
                setModalPassword(false)
                return
            } else {
                toast.error(resChangePW.data.msj)
                return
            }
        } else if (action === "ChangeRole") {
            const Encryp = encodeUserPermission(userPermissionTable, data.IdUser_PK)
            const maxRollEncrip = encodeUserPermission(maxRollF(userPermissionTable), data.IdUser_PK)
            setData({ ...data, maxRoll: maxRollEncrip, userPermission: Encryp })
            const resPermission = await setPermissionF(Encryp, maxRollEncrip, data.IdUser_PK)
            if (resPermission.data.status === 200) {
                toast.success(resPermission.data.msj)
                setAction("Edit")
                setModalRole(false)
                return
            } else {
                toast.error(resPermission.data.msj)
                setAction("Edit")
                setModalRole(false)
                return
            }
        } else if (action === "ChangeStatus") {
            const resChangeStatus = await EditingUserF({ IdUser_PK: data.IdUser_PK, Status: data.Status === "active" ? "inactive" : "active" })
            if (resChangeStatus.data.status === 200) {
                toast.success(resChangeStatus.data.msj)
                setAction("Edit")
                setModalStatus(false)
            } else {
                toast.error(resChangeStatus.data.msj)
                setAction("Edit")
                setModalRole(false)
                return
            }
        }
        ClearStates()


    }
    const columns = [
        {
            name: 'Id',
            cell: row => (
                <div onClick={() => { selectRow(row) }} data-tag="allowRowEvents"   >
                    {row.IdUser_PK}
                </div>
            ),
            selector: row => row.IdUser_PK,
            sortable: true,
            fixed: true,
        },
        {
            name: 'Nombres',
            cell: row => (
                <div onClick={() => { selectRow(row) }} data-tag="allowRowEvents"  >
                    {row.UserName}
                </div>
            ),
            selector: row => row.UserName,
            sortable: true,
        },
        {
            name: 'Apellidos',
            cell: row => (
                <div onClick={() => { selectRow(row) }} data-tag="allowRowEvents"  >
                    {row.UserLastName}
                </div>
            ),
            selector: row => row.UserLastName,
            sortable: true,
        },
        {
            name: 'Cargo',
            cell: row => (
                <div onClick={() => { selectRow(row) }} data-tag="allowRowEvents" >
                    {row.Position}
                </div>
            ),
            selector: row => row.Position,
            sortable: true,
        },
        {
            name: 'Empresa ',
            cell: row => (
                <div onClick={() => { selectRow(row) }} data-tag="allowRowEvents"  >
                    {row.CompanyName}
                </div>
            ),
            selector: row => row.IdCompany_FK,
            sortable: true,
            grow: 1.5,
        },
        {
            name: 'Roll',
            cell: row => (
                <div onClick={() => { selectRow(row) }} data-tag="allowRowEvents" >
                    {decodeUserPermission(row.maxRoll, row.IdUser_PK) ? decodeUserPermission(row.maxRoll, row.IdUser_PK).name : "error"}
                </div>
            ),
            selector: row => row.phoneNumber,
            sortable: true,
        },
        {
            name: 'Estado',
            cell: row => (
                <div onClick={() => { selectRow(row) }} data-tag="allowRowEvents" >
                    {row.Status}
                </div>
            ),
            selector: row => row.Status,
            sortable: true,
        },

    ]

    return (
        <>
            <Row>

                <Col>
                    {newBotton && (
                        <Button variant="success"
                            className="m-3 p-2 rounded-pill btn-sm ms-1"
                            disabled={post ? false : true} onClick={() => { CreateUser() }}><i className="bi bi-person-plus mx-2"></i>Crear Usuario</Button>
                    )}
                    <Row>
                        <Col className="d-flex">
                            <Form.Control
                                type="text"
                                id="search"
                                placeholder="Búsqueda"
                                aria-label="search input"
                                value={search}
                                onChange={OnFilter}
                            />
                            <Button data-tip="Limpiar Filtro" className="btn btn-success btn-sm" type="button" onClick={ClearSearch}>
                                <i className="bi bi-arrow-counterclockwise" />
                            </Button>
                            <Col>
                                <Form.Check
                                    className="m-2  "

                                    inline
                                    onChange={(e) => { setFilterActvie(!cbFilter); setcbFilter(!cbFilter) }}
                                    checked={cbFilter ? true : false}
                                    id="activo"
                                    label="activo"
                                    name="active"
                                    type={"checkbox"}

                                /></Col>
                        </Col>
                    </Row>
                    <Row>
                        <SpinnerLoad trigger={originalUsers} />
                        <DataTable
                            fixedHeader={false}
                            fixedHeaderScrollHeight={{ fixedHeaderScrollHeight: '100px' }}
                            columns={columns}
                            data={filterindItems}
                            pagination
                            responsive
                            highlightOnHover
                            pointerOnHover
                            noDataComponent="No hay registros para mostrar"
                            paginationComponentOptions={{
                                rowsPerPageText: 'Filas por página:',
                                rangeSeparatorText: 'de',
                            }}
                        />
                    </Row>
                </Col>
            </Row>

            {/*     <---------------------------formulario Informacion Usuario ------------------------------------> */}
            {ModalUser && <Modal show={ModalUser} onHide={() => { setModalUser(false) }} centered >
                <Form onSubmit={OnSubmit}>
                    <Modal.Header closeButton>
                        <h5 className="modal-title" id="UserModalLabel">{Title}</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>

                            <Col md={12}>
                                <FloatingLabel controlId="IdCompany_FK"
                                    label="Empresa"
                                    className="mb-3">
                                    <Form.Select name="IdCompany_FK"
                                        onChange={handleChange}
                                        value={data.IdCompany_FK}
                                        disabled={!IsEditing || !newBotton || data.Status === "inactive" ? true : false}>
                                        {
                                            companies.map(companie => {
                                                return (
                                                    <option
                                                        value={companie.IdCompany_PK}
                                                        key={companie.IdCompany_PK}>
                                                        {companie.SocialName}
                                                    </option>
                                                )
                                            })
                                        }
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel
                                    controlId="IdUser_PK"
                                    label="Identificación"
                                    className="mb-3">
                                    <Form.Control type="number"
                                        name="IdUser_PK"
                                        placeholder="Identificación"
                                        className="form-control"
                                        onChange={handleChange}
                                        value={data.IdUser_PK}
                                        required
                                        disabled={!IsEditing || !newBotton || data.Status === "inactive" ? true : false}>
                                    </Form.Control>
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel
                                    controlId="maxRoll"
                                    label="maxRoll"
                                    className="mb-3">
                                    <Form.Control type="text"
                                        name="maxRoll"
                                        placeholder="Identificación"
                                        className="form-control"
                                        onChange={handleChange}
                                        value={decodeUserPermission(data.maxRoll, data.IdUser_PK) ? decodeUserPermission(data.maxRoll, data.IdUser_PK).name : "error"}
                                        required
                                        disabled={true}>
                                    </Form.Control>
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="UserName"
                                    label="Nombres"
                                    className="mb-3">
                                    <Form.Control name="UserName"
                                        placeholder="Nombres"
                                        className="form-control"
                                        onChange={handleChange}
                                        value={data.UserName}
                                        required
                                        disabled={!IsEditing || !newBotton || data.Status === "inactive" ? true : false}>
                                    </Form.Control>
                                </FloatingLabel>
                            </Col>

                            <Col md={6} >
                                <FloatingLabel controlId="UserLastName"
                                    label="Apellidos"
                                    className="mb-3">
                                    <Form.Control type="text"
                                        name="UserLastName"
                                        placeholder="Apellidos"
                                        className="form-control"
                                        onChange={handleChange}
                                        value={data.UserLastName}
                                        required
                                        disabled={!IsEditing || !newBotton || data.Status === "inactive" ? true : false}>
                                    </Form.Control>
                                </FloatingLabel>
                            </Col>
                            <Col md={12}>
                                <FloatingLabel controlId="Position"
                                    label="Cargo"
                                    className="mb-3">
                                    <Form.Control name="Position"
                                        placeholder="Cargo"
                                        className="form-control"
                                        onChange={handleChange}
                                        value={data.Position}
                                        required
                                        disabled={!IsEditing || !newBotton || data.Status === "inactive" ? true : false}>
                                    </Form.Control>
                                </FloatingLabel>
                            </Col>
                            <Col md={5}>
                                <FloatingLabel controlId="phoneNumber"
                                    label="Número Teléfonico"
                                    className="mb-3">
                                    <Form.Control name="phoneNumber"
                                        type="number"
                                        placeholder="Número Teléfonico"
                                        className="form-control"
                                        onChange={handleChange}
                                        value={data.phoneNumber}
                                        required
                                        disabled={!IsEditing || !newBotton || data.Status === "inactive" ? true : false}>
                                    </Form.Control>
                                </FloatingLabel>
                            </Col>
                            <Col md={7}>
                                <FloatingLabel controlId="UserEmail"
                                    label="Correo Electrónico"
                                    className="mb-3">
                                    <Form.Control type="email"
                                        name="UserEmail"
                                        placeholder="Correo Electrónico"
                                        className="form-control"
                                        onChange={handleChange}
                                        value={data.UserEmail}
                                        required
                                        disabled={!IsEditing || !newBotton || data.Status === "inactive" ? true : false}>
                                    </Form.Control>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row>
                            <div className="form-check px-5 " >
                                <input className="form-check-input" type="checkbox"  onClick={tokenCode} value={data.tokenCodeAv} id="flexCheckDisabled" disabled={!IsEditing} />
                                <label className="form-check-label" for="flexCheckDisabled">
                                    requerir token whatsapp
                                </label>
                            </div>

                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        {<button type="button" className="btn btn-info" onClick={ChangeStatus} data-dismiss="modal" disabled={!IsEditing}>{data.Status === "active" ? "inactivar" : "activar"}  </button>}

                        {data.Status === "active" && <button type="button" className="btn btn-primary" onClick={ChangeRole} data-dismiss="modal"disabled={!IsEditing} >roles</button>}
                        {data.Status === "active" && <button type="button" className="btn btn-warning" onClick={ChangePW} data-dismiss="modal"disabled={!IsEditing} >contraseña</button>}
                        <br></br>
                        {put && <button type="button" className="btn btn-primary" onClick={EditUser} data-dismiss="modal">Editar</button>}

                        {data.Status === "active" && <Button type="submit" style={{ display: !IsEditing || !newBotton ? 'none' : 'inline-block' }} variant="success">
                            Guardar
                        </Button>}
                        <button type="button" className="btn btn-secondary" onClick={ClearStates} data-dismiss="modal">Cerrar</button>
                    </Modal.Footer>
                </Form>
            </Modal>}
            {/*     <---------------------------formulario Cambio Password ------------------------------------> */}
            {ModalPassword && <Modal show={ModalPassword} onHide={() => { setModalPassword(false) }} size="sm" centered backdrop="static">
                <Form onSubmit={OnSubmit}>
                    <Modal.Header >
                        <h5 className="modal-title" id="UserModalLabel">Cambio de Contraseña</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>

                            <Col md={12}>
                                <FloatingLabel controlId="newPassword"
                                    label="nueva contraseña"
                                    className="mb-3">
                                    <Form.Control name="newPassword"
                                        placeholder="Nueva contraseña"
                                        className="form-control"
                                        onChange={handleChange}
                                        value={newPassword}
                                        required
                                        disabled={false}>
                                    </Form.Control>
                                </FloatingLabel>
                            </Col>




                        </Row>
                    </Modal.Body>
                    <Modal.Footer>


                        <Button type="submit" style={{ display: !IsEditing ? 'none' : 'inline-block' }} variant="success">
                            Guardar
                        </Button>
                        <button type="button" className="btn btn-secondary" onClick={e => setModalPassword(false)} data-dismiss="modal">Cerrar</button>
                    </Modal.Footer>
                </Form>
            </Modal>}
            {/*     <---------------------------formulario Cambio Estatus ------------------------------------> */}
            {ModalStatus && <Modal show={ModalStatus} onHide={() => { setModalStatus(false) }} size="sm" centered backdrop="static">
                <Form onSubmit={OnSubmit}>
                    <Modal.Header >
                        <h5 className="modal-title" id="UserModalLabel">{Title}</h5>
                    </Modal.Header>
                    <Modal.Body >
                        <h6>¿desea continuar con la {data.Status === "active" ? "inactivación " : "activación "} de usuario {data.UserName + " " + data.UserLastName} ? </h6>
                    </Modal.Body>

                    <Modal.Footer>


                        <Button type="submit" style={{ display: !IsEditing ? 'none' : 'inline-block' }} variant="success">
                            Si
                        </Button>
                        <button type="button" className="btn btn-secondary" onClick={e => setModalStatus(false)} data-dismiss="modal">No</button>
                    </Modal.Footer>
                </Form>
            </Modal>}

             {/*     <---------------------------formulario habilitar token ------------------------------------> */}
             {ModalToken && <Modal show={ModalToken} onHide={() => { setModalToken(false) }} size="sm" centered backdrop="static">
                <Form onSubmit={OnSubmit}>
                    <Modal.Header >
                        <h5 className="modal-title" id="UserModalLabel">{Title}</h5>
                    </Modal.Header>
                    <Modal.Body >
                        <h6>¿desea  {data.tokenCodeAv? "inhabilitar " : "habilitar "} el ingreso con token para {data.UserName + " " + data.UserLastName} ? </h6>
                    </Modal.Body>

                    <Modal.Footer>


                        <Button type="submit" style={{ display: !IsEditing ? 'none' : 'inline-block' }} variant="success">
                            Si
                        </Button>
                        <button type="button" className="btn btn-secondary" onClick={e => setModalToken(false)} data-dismiss="modal">No</button>
                    </Modal.Footer>
                </Form>
            </Modal>}
            {/*     <---------------------------formulario Roles ------------------------------------> */}

            {ModalRole && <Modal show={ModalRole} onHide={() => { setModalRole(false) }} centered size="lg" backdrop="static">
                <Form onSubmit={OnSubmit}>
                    <Modal.Header closeButton>
                        <h5>Administración de Roles - {data.UserName + " " + data.UserLastName}</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <Table>
                            <thead>
                                <tr>
                                    <th style={{ width: '15%' }}>Acceso</th>
                                    <th style={{ width: '40%' }}>Perfil</th>
                                    <th style={{ width: '15%' }}>Crear</th>
                                    <th style={{ width: '15%' }}>Editar</th>
                                    <th style={{ width: '15%' }}>Borrar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {




                                    Roles.map((item, i) => (

                                        <tr key={item.id}>
                                            <td>
                                                <Form.Check
                                                    ref={chkbtn}
                                                    className="form-switch"
                                                    onChange={e => handleChange(e)}
                                                    //disabled={item.weight > MyRollDecryp.value}
                                                    type="checkbox" role="role" id={"access"} name={item.id}
                                                    checked={!userPermissionTable ? false : userPermissionTable[item.id].access}
                                                />
                                            </td>
                                            {console.log(userPermissionTable)}
                                            <td>{item.name}</td>
                                            <td>
                                                <Form.Check
                                                    onChange={e => handleChange(e)}
                                                    ref={chkbtn} role="role" type="checkbox" id={'POST'} name={item.id}
                                                    disabled={!userPermissionTable ? false : !userPermissionTable[Roles[i].id].access}
                                                    checked={!userPermissionTable ? false : userPermissionTable[item.id].POST}

                                                />
                                            </td>
                                            <td>
                                                <Form.Check
                                                    onChange={e => handleChange(e)}
                                                    ref={chkbtn} role="role" type="checkbox" id={'PUT'} name={item.id}
                                                    disabled={!userPermissionTable ? false : !userPermissionTable[Roles[i].id].access}
                                                    checked={!userPermissionTable ? false : userPermissionTable[item.id].PUT}

                                                />
                                            </td>
                                            <td>
                                                <Form.Check
                                                    onChange={e => handleChange(e)}
                                                    ref={chkbtn} role="role" type="checkbox" id={'DELETE'} name={item.id}
                                                    disabled={!userPermissionTable ? false : !userPermissionTable[Roles[i].id].access}
                                                    checked={!userPermissionTable ? false : userPermissionTable[item.id].DELETE}

                                                />
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" variant="success">
                            <i className="bi bi-save me-2"></i>
                            Guardar
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>}
        </>
    )
}

export default User