import React, { useState, useEffect } from 'react'
import { sessionActive, decodeUserPermission } from '../lib/functions'
import { getFullData } from "../lib/request";
import { Col, Row, Card, Button } from 'react-bootstrap'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Reecruitment from './recruitment/Recruitment';


export default function Leader() {
    const [admin, SetAdmin ]= useState(false)
    const [put, setPut] = useState(false);
    const [_delete, set_delete] = useState(false);
    const [post, setPost] = useState(false);
    const [sessionUser, SetSessionUser] = useState(null)
    useEffect(() => {
        sessionActive();
        Permissions();
    }, [])
    const Permissions =async () => {
        //Obtener Permisos de Usuario
        const resPermission = decodeUserPermission(localStorage.getItem("userPermission"), localStorage.getItem("IdUser_PK"))
        if (resPermission.superAdmin.access) {
            setPut(true); set_delete(true); setPost(true);
            SetAdmin(true)
        } else {
            setPut(resPermission.leader.PUT);
            set_delete(resPermission.leader.DELETE);
            setPost(resPermission.leader.POST);
        }
        //Obtener perfil de usuario (contrato)
        var res = await getFullData("employee", "IdEmployee_FK",localStorage.getItem("IdUser_PK"))
            SetSessionUser(res.data.data[0])
         
    }

    return (
        <div className="page-content py-3">
            <Row >
                <Col>
                    <Card style={{minHeigth: "900px"}}>
                        <Tabs >
                            <Card.Header className="card-tabs text-center  align-items-center">
                                <TabList>
                                    <h5 className='tabs-tittle'>Lider</h5>
                                    
                                    <Tab style={{width:'150px'}}>
                                        <p>Reclutamiento</p>
                                    </Tab>
                                    <Tab style={{width:'200px'}}>
                                        <p>Ordenes</p>
                                    </Tab>
                                    
                                </TabList>
                            </Card.Header>
                            <Card.Body>
 
                                <TabPanel>
                                    <Reecruitment admin={admin}
                     put={put} _delete={_delete} post={post} sessionUser={sessionUser}  />
                                </TabPanel>
                                <TabPanel>
                                
                                </TabPanel>

                              
                            </Card.Body>
                        </Tabs>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}
