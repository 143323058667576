import Moment from 'moment'
import { useEffect, useState, useRef } from 'react'
import { Button, Col, Form, Row, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import Signer from '../signer'
import { CreateFormat, newWorkflow, updateFormat, updateWorkflow } from './request'
import { BuyTable } from '../../supplychain/suppliesTab/RQ/BuyTable'
import { useUserContext } from '../../../../hooks/custom'
import SpinnerLoad from '../spinnerLoad'
import image from '../../../../assets/img/Logo_b-st.png'


export const COMFOR167 = ({
  show,
  hide,
  restart,
  setRestart,
  isView,
  data = '',
  setData,
  isSign = false,
  list,
  workflow = [],
  IdFormat_PK,


}) => {
  /*---------ESTADOS------------------
      [     creado      ] => documento creado  firmado por elaborador
              V
      [    firmaJefe    ] => documento firmado por el jefe
              V
      [   firmaLiderA   ] => documento firmado por el lider de area
              V
      [     Aprobar    ] => documento firmado por el gerente general
              V
      [     Rechazado    ] => documento firmado por el gerente general
              V
      [ Procesar ] => ?  cotizado => comprado 
              V
      [       Finalizados         ] => ?
  */





  const wf = {

    creando: "Firmar RQ Jefe",
    firmaJefe: "Firmar RQ Lider Area",
    firmaLiderA: "Aprobar RQ",
    Aprobar: "Aprobar RQ"
  }

  const estado = {
    creando: "firmaJefe",
    firmaJefe: "firmaLiderA",
    firmaLiderA: "Aprobar",
    Aprobar: "Procesar",
    Procesar: "Finalizado"
  }



  //-------------------------Info Formato
  const formato = 'COM-FOR-167'
  const revision = 'N/A'
  const type = 'COMFOR167'
  const fechaRev = '17/08/2022'
  const nombreFormato = 'REQUISICION'
  const typef = 'COMFOR167'

  //-------------------------Registros
  const modalRef = useRef(null);
  const printRef = useRef(null);
  const { sessionUser } = useUserContext()
  const [makeLeader, setMakeLeader] = useState(false)
  const [date, setDate] = useState('')
  const [buyTable, setBuyTable] = useState([])
  const [firmaSolicitante, setfirmaSolicitante] = useState('')
  const [firmaJefe, setfirmaJefe] = useState('')
  const [firmaLider, setfirmaLider] = useState('')
  const [firmaGerente, setfirmaGerente] = useState('')
  const [listaLider, setListaLider] = useState([])
  const [empleados, setEmpleados] = useState('')
  const [isEdit, setIsEdit] = useState(false)
  const [modalAction, setModalAction] = useState(false)
  const [actionBtn, setActionBtn] = useState("Rechazar")
  const [signStatus, setSignStatus] = useState({ firmaSolicitante: false, firmaGerente: false, firmaLider: false, firmaJefe: false })


  useEffect(() => {
    if (!restart) {
      setRestart(!restart)
      if (show) getInfo()
    }
  }, [restart])

  useEffect(() => {
    async function fetchData() {
      const res = (await (updateFormat({ IdFormat_PK: IdFormat_PK, Status: 'Finalizada', formatData: printRef.current.outerHTML }, typef)))
      if (res.data.status === '200') {
        clearStates()
        hide(!show)
        setRestart(!restart)
      }
      else {
        toast.error(res.data.msj)
      }
    }
    if (data.Status === "Finalizar" && firmaGerente.image) {
      fetchData();
    }
  }, [firmaGerente.NombreTrabajador])



  const convertToPdf = () => {
    // Código para imprimir el contenido
    window.print();
  };

  const handleChange = async ({ target }) => {
    const { name, type } = target
    const value = type === 'checkbox' ? target.checked : target.value
    setData({ ...data, [name]: value, })
  }

  const ActionSol = async () => {

    let motRachazo = ""
    let newStatus = ""
    if (actionBtn === "Rechazar") {
      newStatus = "Rechazado"
      motRachazo = data.rechazo + "\n" + sessionUser.NombreTrabajador + " " + sessionUser.ApellidoTrabajador + '\n fecha: ' + date
    }
    if (actionBtn === "Reanudar") {
      if (workflow.action === "Firmar RQ Jefe") newStatus = "firmaJefe"
      if (workflow.action === "Firmar RQ Lider Area") newStatus = 'firmaLiderA'
      if (workflow.action === "Aprobar RQ") newStatus = 'Aprobar'
    }
    var result = await updateFormat({ IdFormat_PK: IdFormat_PK, Status: newStatus, rechazo: motRachazo }, typef)
    if (result.data.status === 200) {
      await updateWorkflow({ ...workflow, Status: actionBtn === "Rechazar" ? "rechazado" : 'pendiente', dateFinish: date })
      toast.success(result.data.msj)
    } else {
      toast.error('error:', result.data.msj)
    }
    clearStates()
    hide(!show)
    setRestart(!restart)

  }



  const getInfo = async () => {

    const formatDate = Moment().format('YYYY-MM-DD')
    setDate(formatDate)
    // let resDNI = await getFormats('employee')
    // resDNI = resDNI.data.data.filter(item => item.Status.includes('activo'))
    // setEmpleados(resDNI)
    // var res = await getFormats('COMFORContract')
    // setListaLider(res.data.data)

    setEmpleados(list.Empleados)
    setListaLider(list.contrato)

    if (IdFormat_PK === 'en creacion') {
      setfirmaSolicitante({
        ApellidoTrabajador: sessionUser.ApellidoTrabajador,
        IdEmployee_FK: sessionUser.IdEmployee_FK,
        NombreTrabajador: sessionUser.NombreTrabajador,
        fecha: '',

      })
      // let jefeInm = await getUserByIdF('IdUser_PK', sessionUser.idJefeInm_FK, true)
      let jefeInm = await list.Empleados.filter(item => item.IdEmployee_FK.includes(sessionUser.idJefeInm_FK))


      setfirmaJefe({
        ApellidoTrabajador: jefeInm[0].ApellidoTrabajador,
        IdEmployee_FK: jefeInm[0].IdEmployee_FK,
        NombreTrabajador: jefeInm[0].NombreTrabajador,
        fecha: '',
      })
      // let gerente = await resDNI.filter(item => item.cargo.includes('Gerente General') && !item.Status.includes('inactivo'))
      let gerente = await list.Empleados.filter(item => item.cargo.includes('Gerente General') && !item.Status.includes('inactivo'))

      setfirmaGerente({
        ApellidoTrabajador: gerente[0].ApellidoTrabajador,
        IdEmployee_FK: gerente[0].IdEmployee_FK,
        NombreTrabajador: gerente[0].NombreTrabajador,
        fecha: '',
      })
      // let lider = await resDNI.filter(item => item.cargo.includes('lider'))
      let lider = await list.Empleados.filter(item => item.cargo.includes('lider'))

      setfirmaLider({
        ApellidoTrabajador: lider[0]?.ApellidoTrabajador ?? "sin seleccionar",
        IdEmployee_FK: lider[0]?.IdEmployee_FK ?? "",
        NombreTrabajador: lider[0]?.NombreTrabajador ?? "contrato",
        fecha: '',
      })
      setBuyTable(data.BuyTable)
      setData({
        rechazo: '',
        FechaRequerido: '',
        Justificacion: '',
        Proveedores: '',
        EntregarEn: '',
        EntregarA: '',
        CeCo: '',
        Contrato: '',
        IdFormat_PK: 'en creacion',
        FechaSolicitud: formatDate,
        Status: 'creando'
      })
    } else {
      setBuyTable(await JSON.parse(data.BuyTable))
      var firma = JSON.parse(data.firmaSolicitante)
      setfirmaSolicitante(firma)
      setSignStatus({ ...signStatus, firmaSolicitante: firma.image ? true : false })
      firma = JSON.parse(data.firmaJefe)
      setSignStatus({ ...signStatus, firmaJefe: firma.image ? true : false })
      setfirmaJefe(firma)
      firma = JSON.parse(data.firmaLider)
      setSignStatus({ ...signStatus, firmaLider: firma.image ? true : false })
      setfirmaLider(firma)
      firma = JSON.parse(data.firmaGerente)
      setSignStatus({ ...signStatus, firmaGerente: firma.image ? true : false })
      setfirmaGerente(firma)
    }


    if (data.Status === "edicion") {
      setIsEdit(true)
      setData({ ...data, Status: 'firmaJefe' })
    }


  }

  const setLeader = (target) => {
    const { id, lang, value } = target
    let lider = empleados.filter(item => item.IdEmployee_FK.includes(id))
    setfirmaLider({
      ApellidoTrabajador: lider.length === 1 ? lider[0].ApellidoTrabajador : "contrato",
      IdEmployee_FK: lider.length === 1 ? lider[0].IdEmployee_FK : "",
      NombreTrabajador: lider.length === 1 ? lider[0].NombreTrabajador : "Seleccione el",
      fecha: '',
    })
    setMakeLeader(lider.length === 1 && lider[0].IdEmployee_FK === sessionUser.IdEmployee_FK ? true : false)
    setData({
      ...data,
      CeCo: lider.length === 1 ? lang : "sin contrato",
      Contrato: lider.length === 1 ? value : ""
    })
  }
  const clearStates = () => {
    data = []
    setData('')
    setDate('')
  }

  const onEnter = async e => {
    console.log(e)
  }

  const onSubmit = async e => {
    let result
    e.preventDefault()
    var nextId_WF = ""
    var nextWF = []
    if (buyTable === undefined || buyTable.length === 0) {
      toast.error('debe incluir al menos un (1) item')
    }
    if (
      (isSign && sessionUser.IdEmployee_FK === firmaSolicitante.IdEmployee_FK && !firmaSolicitante.image) ||
      (isSign && sessionUser.IdEmployee_FK === firmaJefe.IdEmployee_FK && !firmaJefe.image) ||
      (isSign && sessionUser.IdEmployee_FK === firmaLider.IdEmployee_FK && !firmaLider.image) ||
      (makeLeader && sessionUser.IdEmployee_FK === firmaLider.IdEmployee_FK && !firmaLider.image) ||
      (isSign && sessionUser.IdEmployee_FK === firmaGerente.IdEmployee_FK && !firmaGerente.image)
    ) {
      toast.error('por favor firme el documento')
      return
    }


    data = {
      ...data,
      firmaSolicitante: JSON.stringify(firmaSolicitante),
      firmaJefe: JSON.stringify(firmaJefe),
      firmaLider: JSON.stringify(firmaLider),
      firmaGerente: JSON.stringify(firmaGerente),
      createdBy: firmaSolicitante.NombreTrabajador + ' ' + firmaSolicitante.ApellidoTrabajador,
      BuyTable: JSON.stringify(buyTable),
      Status: !isEdit ? estado[data.Status] : 'firmaJefe'
    }

    if (isSign) {
      let dataSing, actionWF, idWF, cat

      const singStatus = [
        {
          creado: {
            dataSing: { IdFormat_PK: data.IdFormat_PK, firmaJefe: JSON.stringify(firmaJefe), Status: "firmaJefe" },
            actionWF: 'firmar Lider',
            idWF: firmaLider.IdEmployee_FK
          },
          firmaJefe: {
            dataSing: { IdFormat_PK: data.IdFormat_PK, firmaLider: JSON.stringify(firmaLider), Status: "firmaLiderA" },
            actionWF: 'Aprobar',
            idWF: firmaGerente.IdEmployee_FK
          },
          firmaLiderA: {
            dataSing: { IdFormat_PK: data.IdFormat_PK, firmaGerente: JSON.stringify(firmaGerente), Status: "Procesar" },
            actionWF: undefined,
            idWF: undefined
          }
        },
        {
          creado: {
            dataSing: { IdFormat_PK: data.IdFormat_PK, firmaJefe: JSON.stringify(firmaJefe), Status: "firmaLiderA" },
            actionWF: 'Aprobar',
            idWF: firmaGerente.IdEmployee_FK
          },
          firmaLiderA: {
            dataSing: { IdFormat_PK: data.IdFormat_PK, firmaGerente: JSON.stringify(firmaGerente), Status: "Procesar" },
            actionWF: undefined,
            idWF: undefined
          }
        },
        {
          creado: {
            dataSing: { IdFormat_PK: data.IdFormat_PK, firmaJefe: JSON.stringify(firmaJefe), firmaGerente: JSON.stringify(firmaGerente), Status: "Procesar" },
            actionWF: undefined,
            idWF: undefined
          }
        }
        ,
        {
          creado: {
            dataSing: { IdFormat_PK: data.IdFormat_PK, firmaJefe: JSON.stringify(firmaJefe), firmaLider: JSON.stringify(firmaLider),  firmaGerente: JSON.stringify(firmaGerente), Status: "Procesar" },
            actionWF: undefined,
            idWF: undefined
          }
        },
        {
          creado: {
            dataSing: { IdFormat_PK: data.IdFormat_PK, firmaJefe: JSON.stringify(firmaJefe), Status: "firmaJefe" },
            actionWF: 'firmar Lider',
            idWF: firmaLider.IdEmployee_FK
          },
          firmaJefe: {
            dataSing: { IdFormat_PK: data.IdFormat_PK,firmaLider: JSON.stringify(firmaLider), firmaGerente: JSON.stringify(firmaGerente), Status: "Procesar" },
            actionWF: undefined,
            idWF: undefined
          }

        },
        {
          creado: {
            dataSing: { IdFormat_PK: data.IdFormat_PK, firmaJefe: JSON.stringify(firmaJefe), firmaGerente: JSON.stringify(firmaGerente), Status: "firmaJefe" },
            actionWF: 'firmar Lider',
            idWF: firmaLider.IdEmployee_FK
          },
          firmaJefe: {
            dataSing: { IdFormat_PK: data.IdFormat_PK, firmaGerente: JSON.stringify(firmaGerente), Status: "Procesar" },
            actionWF: undefined,
            idWF: undefined
          }

        }
      ]

      if (firmaSolicitante.IdEmployee_FK !== firmaJefe.IdEmployee_FK !== firmaLider.IdEmployee_FK !== firmaGerente.IdEmployee_FK) cat = 0
      else if (firmaSolicitante.IdEmployee_FK === firmaLider.IdEmployee_FK !== firmaJefe.IdEmployee_FK !== firmaGerente.IdEmployee_FK) cat = 1
      else if (firmaSolicitante.IdEmployee_FK === firmaLider.IdEmployee_FK !== firmaJefe.IdEmployee_FK === firmaGerente.IdEmployee_FK) cat = 2
      else if (firmaSolicitante.IdEmployee_FK !== firmaJefe.IdEmployee_FK === firmaLider.IdEmployee_FK === firmaGerente.IdEmployee_FK) cat = 3
      else if (firmaSolicitante.IdEmployee_FK !== firmaJefe.IdEmployee_FK !== firmaLider.IdEmployee_FK === firmaGerente.IdEmployee_FK) cat = 4
      else if (firmaSolicitante.IdEmployee_FK !== firmaLider.IdEmployee_FK !== firmaJefe.IdEmployee_FK === firmaGerente.IdEmployee_FK) cat = 5

      dataSing=singStatus[cat][data.Status].dataSing
      actionWF=singStatus[cat][data.Status].actionWF
      idWF=singStatus[cat][data.Status].idWF

      // const result = await updateFormat(type, updateData, workflow, idWF, actionWF)
      if (result.data.status === 200) {
        toast.success(result.data.msj)
      } else {
        toast.error(result.data.msj);
      }

    }


    /* creado, firmaJefe, firmaLiderA, Aprobar, Rechazado, Procesar, Finalizados  */
    /*  firmaSolicitante, firmaJefe, firmaLider,firmaGerente */
    /* if (isSign) {
          if (data.Status === 'firmaJefe' && !firmaLider.image) {
            nextId_WF = firmaLider.IdEmployee_FK
          }
          else if (data.Status === 'firmaJefe' && firmaLider.image) {
            data = { ...data, Status: "firmaLiderA" }
            nextId_WF = firmaGerente.IdEmployee_FK
          }
          else if (data.Status === 'firmaLiderA' && !firmaGerente.image) {
    
            nextId_WF = firmaGerente.IdEmployee_FK
          }
          else if (data.Status === 'firmaLiderA' && firmaGerente.image) {
            data = { ...data, Status: "Aprobar" }
          }
    
        }
    
    
        data = {
          ...data,
          firmaSolicitante: JSON.stringify(firmaSolicitante),
          firmaJefe: JSON.stringify(firmaJefe),
          firmaLider: JSON.stringify(firmaLider),
          firmaGerente: JSON.stringify(firmaGerente),
          createdBy: firmaSolicitante.NombreTrabajador + ' ' + firmaSolicitante.ApellidoTrabajador,
          BuyTable: JSON.stringify(buyTable),
          Status: !isEdit ? estado[data.Status] : 'firmaJefe'
        }
        if (data.IdFormat_PK === 'en creacion') {
          delete data.IdFormat_PK
          result = await CreateFormat(data, typef)
          if (result.data.status === 200) {
            nextWF = {
              TaskDescription: "RQ de " + firmaSolicitante.NombreTrabajador,
              action: "Firmar RQ Jefe",
              Status: "pendiente",
              Format: typef,
              dateCreation: date,
              documents: "RQ",
              IdFormat_FK: result.data.data.insertId,
              IdEmployee_created: sessionUser.IdEmployee_FK,
              IdEmployee_FK: firmaJefe.IdEmployee_FK,
            }
    
    
            result = await newWorkflow(nextWF)
            if (result.data.status === 200) {
              toast.success("RQ creada exitosamente")
            }
            else {
              toast.error(result.data.msj); return
            }
          }
          else {
            toast.error(result.data.msj); return
          }
        }
        else {
    
          if (signStatus.firmaSolicitante) delete data.firmaSolicitante
          if (signStatus.firmaJefe) delete data.firmaJefe
          if (signStatus.firmaGerente) delete data.firmaGerente
          if (signStatus.firmaLider) delete data.firmaLider
    
          result = await updateFormat(data, typef)
          if (result.data.status === 200 && isSign && !isEdit) {
            result = await updateWorkflow({ ...workflow, Status: "finalizado", dateFinish: date })
            delete workflow.IdWorkFlow_PK
            if (data.Status !== 'Procesar') {
    
              result = await newWorkflow({
                ...workflow,
                IdEmployee_FK: nextId_WF,
    
                action: wf[data.Status]
              })
            }
            if (result.data.status === 200) {
    
    
              toast.success("RQ actualizada exitosamente")
            } else {
              toast.error(result.data.msj); return
            }
          } else if (result.data.status === 200 && isEdit) { toast.success("RQ actualizada exitosamente") }
          else {
            toast.error(result.data.msj); return
          }
        }
    
    */
    clearStates()
    hide(!show)
    setRestart(!restart)

  }

  return (
    <>
      {show && (
        <Overlay style={{ zIndex: '1' }}>
          <SpinnerLoad trigger={firmaSolicitante} />


          <ModalContainer ref={modalRef} className="modal-container"><CloseButtonModal
            className="no-print"
            onClick={() => {
              clearStates()
              hide(!show)
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-x-square"
              viewBox="0 0 16 16"
            >
              <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </CloseButtonModal>
            <Form onSubmit={onSubmit} onKeyDown={onEnter}>
              {data.formatData && false ? <div dangerouslySetInnerHTML={{ __html: data.formatData }}></div> : <PrintArea ref={printRef} >
                <ModalHeader>
                  <table style={{ width: '100%' }}>
                    <tbody>
                      <tr>
                        <th style={{ width: '20%', fontSize: '10px', verticalAlign: 'bottom' }} rowSpan={3}>
                          <img
                            className="format_logo"
                            src={image}
                            style={{ paddingBottom: '7px', width: '75%', minWidth: "40px" }}
                            alt="Logo RMS"
                          /><br />
                          NIT 830.507.705-2
                        </th>
                        <th style={{ width: '55%', height: '38px' }}>RELIABILITY MAINTENANCE SERVICES S.A.S</th>
                        <th style={{ width: '25%' }}>{formato}</th>
                      </tr>
                      <tr>
                        <th rowSpan={2}>{nombreFormato}</th>
                        <th style={{ fontSize: '10px' }}>Revisión: {revision}</th>
                      </tr>
                      <tr>
                        <th style={{ fontSize: '10px' }}>Fecha de revisión: {fechaRev}</th>
                      </tr>
                    </tbody>
                  </table>
                </ModalHeader>



                <Frame isView={isView}>
                  <table style={{ width: '100%' }}>
                    <tbody>
                      <tr>
                        <th className="rms" colSpan={6} style={{ width: '100%' }}>
                          <h2 className="text-center">INFORMACION</h2>
                        </th>
                      </tr>
                      <tr>
                        <td style={{ width: '27%' }}>
                          <label>Fecha de solicitud</label>
                        </td>
                        <td style={{ width: '10%' }}>
                          <input
                            type={'date'}
                            className="disable"
                            name="FechaSolicitud"
                            value={data.FechaSolicitud}
                            onChange={e => { }}
                            required
                            disabled={true}
                          ></input>
                        </td>
                        <td colSpan={4}></td>
                      </tr>
                      <tr>
                        <td >
                          <label>Fecha de requerido en campo</label>
                        </td>
                        <td>
                          <input
                            type={'date'}
                            name="FechaRequerido"
                            value={data.FechaRequerido}
                            onChange={handleChange}
                            required
                            disabled={isView ? true : false}
                          ></input>
                        </td>
                        <td style={{ width: '7%' }}>
                          <label>Estado</label>
                        </td>
                        <td style={{ width: '15%' }}>
                          <input
                            className="disable"
                            name="Status"
                            value={data.Status}
                            onChange={() => { }}
                            required
                            disabled={true}
                          ></input>
                        </td>
                        <td style={{ width: '15%' }}>
                          <label>Requisicion No.</label>
                        </td>
                        <td style={{ width: '28px' }}>
                          <input
                            className="disable"
                            name="IdFormat_PK"
                            value={IdFormat_PK}
                            onChange={() => { }}
                            required
                            disabled={true}
                          ></input>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: '80px' }}>
                          <label>Persona que solicita la compra/Servicio:</label>
                        </td>
                        <td colSpan={3}>
                          <input
                            className="disable"
                            name='createdBy'
                            value={firmaSolicitante.NombreTrabajador + ' ' + firmaSolicitante.ApellidoTrabajador}
                            onChange={() => { }}
                            required
                            disabled={true}
                          ></input>
                        </td>

                        <td>
                          <label>Contrato</label>
                        </td>
                        <td>{firmaJefe.image ? <input
                          className="disable"
                          value={data.Contrato}
                          required
                          disabled={true}
                        ></input> :
                          <select
                            style={{ width: '100%' }}
                            name="Contrato"
                            value={data.Contrato}
                            onChange={e => {
                              setLeader(e.target.selectedOptions[0], e.target.value)

                            }}
                            required
                            disabled={isView ? true : false}
                            title="seleccione..."

                          >
                            <option key={0}></option>

                            {listaLider.map((article, i) => {
                              return (
                                <option key={i + 1} id={article.Lider} lang={article.CeCo} value={article.NumeroContrato}>
                                  {article.NumeroContrato + " - " + article.CeCo}
                                </option>
                              );
                            })}
                          </select>}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Frame>

                <Frame isView={isView}>
                  <table style={{ width: '100%' }}>
                    <tbody>
                      <tr>
                        <td>
                          <label>AREA:</label>
                        </td>
                        <td>
                          <Row>
                            <Col><label>GCO <input
                              type={'radio'}
                              name={'Area'}
                              value={'GCO'}
                              checked={data['Area'] === 'GCO' ? 'checked' : ''}
                              onChange={handleChange}
                              required
                              disabled={isView ? true : false}
                            ></input></label></Col>
                            <Col><label>GOP <input
                              type={'radio'}
                              name={'Area'}
                              value={'GOP'}
                              checked={data['Area'] === 'GOP' ? 'checked' : ''}
                              onChange={handleChange}
                              required
                              disabled={isView ? true : false}
                            ></input></label></Col>
                            <Col><label>GRE <input
                              type={'radio'}
                              name={'Area'}
                              value={'GRE'}
                              checked={data['Area'] === 'GRE' ? 'checked' : ''}
                              onChange={handleChange}
                              required
                              disabled={isView ? true : false}
                            ></input></label></Col>
                            <Col><label>HSEQ <input
                              type={'radio'}
                              name={'Area'}
                              value={'HSEQ'}
                              checked={data['Area'] === 'HSEQ' ? 'checked' : ''}
                              onChange={handleChange}
                              required
                              disabled={isView ? true : false}
                            ></input></label></Col>
                          </Row>

                        </td>



                        <td>
                          <label>REEMBOLSABLE/RECOBRABLE:</label>
                        </td>
                        <td>
                          <Row>
                            <Col><label>Si <input
                              type={'radio'}
                              name={'Reembolsable'}
                              value={'Si'}
                              checked={data['Reembolsable'] === 'Si' ? 'checked' : ''}
                              onChange={handleChange}
                              required
                              disabled={isView ? true : false}
                            ></input></label></Col>
                            <Col><label>No <input
                              type={'radio'}
                              name={'Reembolsable'}
                              value={'No'}
                              checked={data['Reembolsable'] === 'No' ? 'checked' : ''}
                              onChange={handleChange}
                              required
                              disabled={isView ? true : false}
                            ></input></label></Col>
                          </Row>

                        </td>

                        <td>
                          <label>GASTO DIRECTO:</label>
                        </td>
                        <td>
                          <Row>
                            <Col><label>Si <input
                              type={'radio'}
                              name={'GastoDirecto'}
                              value={'Si'}
                              checked={data['GastoDirecto'] === 'Si' ? 'checked' : ''}
                              onChange={handleChange}
                              required
                              disabled={isView ? true : false}
                            ></input></label></Col>
                            <Col><label>No <input
                              type={'radio'}
                              name={'GastoDirecto'}
                              value={'No'}
                              checked={data['GastoDirecto'] === 'No' ? 'checked' : ''}
                              onChange={handleChange}
                              required
                              disabled={isView ? true : false}
                            ></input></label></Col>
                          </Row>

                        </td>

                      </tr>
                    </tbody>
                  </table>
                </Frame>

                <Frame isView={isView}>
                  <table style={{ width: '100%' }}>
                    <tbody>
                      <tr>
                        <td style={{ width: '18%' }} >
                          <label>JUSTIFICACION DE LA RQ</label>
                        </td>
                        <td style={{ width: '37%', height: 'auto' }}>
                          <input
                            name="Justificacion"
                            value={data.Justificacion}
                            onChange={handleChange}
                            required
                            disabled={isView ? true : false}
                          ></input>
                        </td>
                        <td style={{ width: '15%' }}>
                          <label>TIPO DE REQUISICION</label>
                        </td>

                        <td style={{ width: '35%', verticalAlign: 'middle' }}>
                          <Row >
                            <Col style={{ verticalAlign: 'middle' }}><label style={{ verticalAlign: 'middle' }} >Bienes y/o materiales<input
                              type={'radio'}
                              style={{ height: '15px', verticalAlign: 'middle' }}
                              name={'TipoRequisicion'}
                              value={'Bienes y/o materiales'}
                              checked={data['TipoRequisicion'] === 'Bienes y/o materiales' ? 'checked' : ''}
                              onChange={handleChange}
                              required
                              disabled={isView ? true : false}
                            ></input></label></Col>
                            <Col ><label >Servicios<input
                              type={'radio'}
                              name={'TipoRequisicion'}
                              value={'Servicios'}
                              checked={data['TipoRequisicion'] === 'Servicios' ? 'checked' : ''}
                              onChange={handleChange}
                              required
                              disabled={isView ? true : false}
                            ></input></label></Col>
                          </Row>

                        </td>

                      </tr>
                    </tbody>
                  </table>
                </Frame>

                <Frame isView={isView}>
                  <table style={{ width: '100%' }}>
                    <tbody>
                      <tr>
                        <td rowSpan={4} style={{ width: '30%' }} >
                          <label>PROVEEDORES SUGERIDOS <br /> (justificar preferidos) </label>
                          <textarea
                            style={{ height: '110px' }}
                            name={'Proveedores'}
                            value={data.Proveedores}
                            onChange={handleChange}

                            disabled={isView ? true : false}
                          ></textarea>
                        </td>
                        <td rowSpan={1} style={{ height: '30px', width: '25%' }}>
                          <label>ENTREGAR EN </label>
                        </td>
                        <td rowSpan={5} style={{ width: '45%', fontSize: '2.5mm', fontWeight: '350' }} >
                          <label>PLANEE SIEMPRE, TENGA EN CUENTA </label>
                          <p>
                            1. Describir el alcance general del servicio; 2. Indicar especificaciones técnicas aplicables,
                            hojas de datos, planos y diagramas, requerimientos de QA/QC y HSEQ; 3. Suministrar lista de
                            materiales, equipos y/o servicios requeridos; 4. Especificar duración del servicio; 5.
                            Contactar al líder o Auxiliar de Compras directamente para aclarar puntos específicos de la
                            solicitud; 6. Elaborar la solicitud con suficiente anticipación; 7. Diligenciar TODOS los
                            campos del formato de REQ; 8. Otros puntos que considere relevantes; 9. Involucrar al líder o
                            Auxiliar de Compras tan pronto se detecte la necesidad.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td >
                          <input
                            name="EntregarEn"
                            value={data.EntregarEn}
                            onChange={handleChange}
                            required
                            disabled={isView ? true : false}
                          ></input>
                        </td>

                      </tr>
                      <tr>
                        <td style={{ height: '30px' }}>
                          <label>ENTREGAR A </label>
                        </td>
                      </tr>
                      <tr>
                        <td >
                          <input
                            name="EntregarA"
                            value={data.EntregarA}
                            onChange={handleChange}
                            required
                            disabled={isView ? true : false}
                          ></input>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Frame>
                <Frame isView={isView}>
                  <table style={{ width: '100%' }}>
                    <tbody>
                      <tr>
                        <td>
                          <BuyTable
                            data={buyTable}
                            setData={setBuyTable}
                            isView={isView}
                            tableType={'tableBuy'}
                            list={list}
                            CeCo={data.CeCo}
                          ></BuyTable>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Frame>

                <Frame isView={isView}>
                  <table style={{ width: '100%' }}>
                    <tbody>
                      <tr></tr>
                      <tr>
                        <th style={{ width: '18%', textAlign: 'center' }}> Aprobación</th>
                        <th style={{ width: '30%', textAlign: 'center' }}>Nombre</th>

                        <th style={{ width: '10%', textAlign: 'center' }}>Fecha</th>
                        <th style={{ width: '20%', textAlign: 'center' }}>Firma</th>
                      </tr>
                      <tr style={{ height: '50px' }}>
                        <th style={{ width: '10%' }}>Elaboró</th>
                        <th style={{ width: '30%' }}>
                          <input
                            className="disable"
                            value={firmaSolicitante.NombreTrabajador + ' ' + firmaSolicitante.ApellidoTrabajador}
                            onChange={e => { }}
                            disabled
                          />
                        </th>

                        <th style={{ width: '10%' }}>
                          <input
                            className="disable"
                            onChange={e => { }}
                            value={firmaSolicitante.fecha}

                            disabled={true}
                          />
                        </th>
                        <th style={{ textAlign: 'center' }}>
                          <Signer
                            enable={sessionUser.IdEmployee_FK === firmaSolicitante.IdEmployee_FK}
                            sign={firmaSolicitante}
                            setSign={setfirmaSolicitante}
                            doc={type}
                            user={sessionUser.IdEmployee_FK}
                            isView={isView}
                          />
                        </th>
                      </tr>
                      <tr style={{ height: '50px' }}>
                        <th style={{ width: '10%' }}>Jefe inmediato</th>
                        <th style={{ width: '30%' }}>
                          <input
                            className="disable"
                            value={firmaJefe.NombreTrabajador + ' ' + firmaJefe.ApellidoTrabajador}
                            onChange={e => { }}

                            disabled
                          />
                        </th>

                        <th style={{ width: '10%' }}>
                          <input className="disable" value={firmaJefe.fecha} onChange={() => { }} disabled={true} required isView={isView} />
                        </th>
                        <th style={{ textAlign: 'center' }}>
                          <Signer
                            enable={isSign && sessionUser.IdEmployee_FK === firmaJefe.IdEmployee_FK}
                            sign={firmaJefe}
                            setSign={setfirmaJefe}
                            doc={typef}
                            user={sessionUser.IdEmployee_FK}

                          />
                        </th>
                      </tr>
                      <tr style={{ height: '50px' }}>
                        <th style={{ width: '10%' }}>Autorizo lider de área</th>
                        <th style={{ width: '30%' }}>
                          <input
                            className="disable"
                            value={firmaLider.NombreTrabajador + ' ' + firmaLider.ApellidoTrabajador}
                            onChange={e => { }}

                            disabled
                          />
                        </th>

                        <th style={{ width: '10%' }}>
                          <input className="disable" value={firmaLider.fecha} onChange={() => { }} disabled={true} isView={isView} />
                        </th>
                        <th style={{ textAlign: 'center' }} >
                          <Signer
                            enable={(isSign && sessionUser.IdEmployee_FK === firmaLider.IdEmployee_FK) || makeLeader}
                            sign={firmaLider}
                            setSign={setfirmaLider}
                            doc={typef}
                            user={sessionUser.IdEmployee_FK}
                          />
                        </th>
                      </tr>
                      <tr style={{ height: '50px' }}>
                        <th style={{ width: '10%' }}>Aprobación gerente general</th>
                        <th style={{ width: '30%' }}>
                          <input
                            className="disable"
                            value={firmaGerente.NombreTrabajador + ' ' + firmaGerente.ApellidoTrabajador}
                            onChange={e => { }}
                            disabled

                          />
                        </th>

                        <th style={{ width: '10%' }}>
                          <input className="disable" value={firmaGerente.fecha} onChange={e => { }} disabled={true} isView={firmaLider === true ? true : false} />
                        </th>
                        <th style={{ textAlign: 'center' }}>
                          <Signer
                            enable={isSign && sessionUser.IdEmployee_FK === firmaGerente.IdEmployee_FK}
                            sign={firmaGerente}
                            setSign={setfirmaGerente}
                            doc={typef}
                            user={sessionUser.IdEmployee_FK}
                          />
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </Frame>
                {data.Status === "Rechazado" && <Frame isView={true} >
                  <table style={{ width: '100%' }}>
                    <tbody>
                      <tr>
                        <td>
                          <label>MOTIVO DEL RECHAZO  </label>
                          <textarea
                            style={{ height: '60px' }}
                            name={'rechazo'}
                            value={data.rechazo}
                            onChange={handleChange}

                            disabled={true}
                          ></textarea>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Frame>}

              </PrintArea>}

              <Row className="no-print">
                <Col></Col>
                <Col></Col>
                <Col></Col>
                <Col></Col>

                {(!isView || (data.Status !== 'Rechazado' && isSign)) ? (
                  <>
                    <Col>
                      <Button
                        className="btn btn-danger"
                        onClick={() => {
                          clearStates()
                          hide(!show)
                        }}
                      >
                        Cancelar
                      </Button>
                    </Col>

                    <Col>
                      <Button type="submit" className="btn btn-success">
                        Guardar
                      </Button>
                    </Col>
                  </>
                ) : (<> <Col>

                  <Col>
                    <Button
                      className="btn btn-info"
                      onClick={convertToPdf}
                    >
                      <i className="bi bi-printer"></i>
                    </Button>

                  </Col>
                </Col>
                  <Col>
                    <Button
                      className="btn btn-secundary"
                      onClick={() => {
                        clearStates()
                        hide(!show)
                      }}
                    >
                      Cerrar
                    </Button>
                  </Col></>
                )}

                {data.Status !== 'creando' && isSign && <Col>
                  <Button
                    className="btn btn-warning"
                    onClick={() => {
                      setActionBtn(data.Status === 'Rechazado' ? "Reanudar" : 'Rechazar')


                      setModalAction(true)

                    }}
                  >

                    {data.Status === "Rechazado" ? 'Reanudar' : 'Rechazar'}
                  </Button>
                </Col>}
              </Row>
            </Form>
          </ModalContainer>

        </Overlay>
      )}
      <Modal
        show={modalAction}
        onHide={() => {
          setModalAction(false)
        }}
        centered
      >
        <Modal.Header closeButton>
          <h5>{actionBtn} Solicitud</h5>
        </Modal.Header>
        <Modal.Body>
          <p>¿Realmente desea {actionBtn.toLowerCase()} la RQ {IdFormat_PK}? </p>

          {actionBtn === "Rechazar" && <><p>Por favor especifique el motivo para rechazar la requicisión</p>
            <textarea
              style={{ width: '100%', height: '60px' }}
              name={'rechazo'}
              value={data.rechazo}
              onChange={handleChange}

              disabled={false}
            /></>}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setData({ ...data, rechazo: "" })
              setModalAction(false)
            }}
          >
            Cancelar
          </Button>
          <Button className="btn btn-danger"
            onClick={() => {
              if (data.rechazo === "") {
                toast.error('indique el motivo para rechazar')
                return
              }
              ActionSol()
              setModalAction(false)
            }}
          >
            {actionBtn}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default COMFOR167


const Frame = styled.div`
  width: 20.89cm;
  padding-bottom: 0px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: left;
  border-width: 1px;
  border-color: #1a004a;
  border-style: solid;
  font-size: 12px;
  font-weight: 1000;
  .photo {
    background: #ebf1de;
  }
  .rms {
    background: #f2f2f2;
  }
  label {
    padding-left: 5px;
    display: flex;
    font-size: 10px;
    font-weight: bold;
    text-align: 'center';
  }
  select {
    
    background: ${props => (props.isView ? '#FFF' : '#cee1ed')};
    
  
}
  input {
    text-align: center;
    width: 100%;
    border-bottom: 1px solid black;
    border-color: #1a004a;
    background: ${props => (props.isView ? '#FFF' : '#cee1ed')};
    padding-left: 5px;
    /* margin-top: 5px; */
  }
  textarea {
    text-align: justify;
    width: 100%;
    border-bottom: 1px solid black;
    border-color: #1a004a;
    background: ${props => (props.isView ? '#FFF' : '#cee1ed')};
    resize: none;
    padding-left: 5px;
  }
  input.edit {
    background: #cee1ed;
  }
  input.disable {
    background: #fff;
  }
  h2 {
    font-size: 14px;
    font-weight: bold;
    text-align: 'center';
  }
  th {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    text-align: left;
    vertical-align: center;
    padding-left: 5px;
    input {
      width: 100%;
    }
  }
  tr {
    border-width: 0px;
    border-color: #1a004a;
    border-style: solid;
  }
  td {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
  }
`

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgb(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalContainer = styled.div`
  position: relative
  width: 21.69;
  max-height: 90vh;
  min-height: 90vh;
  background: #fff;
  position: relative;
  border-radius: 5px;
  overflow: scroll;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 0.4cm;

  @media print {
    padding-top: 20px;
    padding-left: 25px;
    max-height: 100vh;
    min-height: 100vh;
    min-height: 27.94cm;
    width: 100%;
    height: 27.94cm;
    /* Estilos para la versión impresa */
    overflow: hidden;
    /* Aquí ocultamos los componentes que no queremos imprimir */
    @page {
      size: letter; /* Puedes usar diferentes tamaños de papel como A4, Letter, etc. */
      margin: 0; /* Márgenes laterales en 0 */
      padding: 0px;
      orientation: portrait ; /* Cambiar a 'landscape' si necesitas orientación horizontal */
    }
     
      /* Ajustar el área de impresión */
    .no-print {
      display: none;
    }
} 
`
const PrintArea = styled.div`

width: 20.89cm;

`



const ModalHeader = styled.div`
  width: 20.89cm;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
  padding-bottom: 10px;
  td {
    font-weight: 1000;
    
  }
  th {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    
  }
  tr {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
  }
  table {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    text-align: center;
    vertical-align: center;
    font-size: 14px;
  }
  h3 {
    font-weight: 500;
    font-size: 16px;
    color: #1766dc;
  }
`
const CloseButtonModal = styled.div`
  position: absolute;
  top: 16px;
  right: 17px;
  width: 20px;
  height: 20px;
  border: none;
  background: none;
  cursor: pointer;
  transition: 0.3s ease all;
  border-radius: 5px;
  color: #1766dc;
  &:hover {
    background: #f2f2f2;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`;