import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import ApiURL from "../../../../../services/apirest";
import {  Col, Row, Button, Modal, InputGroup, Form, FloatingLabel, } from "react-bootstrap";
import Signer from "../../../lib/signer";
import { useUserContext } from "../../../../../hooks/custom";
import { EditingUserF } from "../../../lib/request";
import { ChromePicker } from 'react-color';


const Profile = () => {
  const { sessionUser } = useUserContext()
  const [signature, setSignature] = useState([])
  const [showChgPSW, hideChgPSW] = useState(false)
  const [oldPsw, setOldPsw] = useState('')
  const [newPSW, setNewPSW] = useState('')
  const [retryPSW, setRetryPSW] = useState('')
  const initial= (
    sessionUser.NombreTrabajador.substring(0, 1).toUpperCase() +
    sessionUser.ApellidoTrabajador.substring(0, 1).toUpperCase()
  )
  const [isCorrectPSW, setIsCorrectPSW] = useState("")
  const [isSamePSW, setIsSamePSW] = useState("")
  const [isSValidPSW, setIsValidPSW] = useState("")
  const userStatus = localStorage.getItem("Status")
  const [color, setColor] = useState(localStorage.getItem('avatar')); // Estado para almacenar el color seleccionado
  const [showColorPicker, setShowColorPicker] = useState(false); // Estado para mostrar/ocultar el colorPicker

  const handleClick = async () => {
    console.log(showColorPicker)
    
    if (showColorPicker) { 
      setShowColorPicker(false); // Cambiar el estado para mostrar u ocultar el colorPicker
    localStorage.setItem("avatar", color);
      await EditingUserF({IdUser_PK: sessionUser.IdEmployee_FK, avatar:color})}
     else 
       setShowColorPicker(true); // Cambiar el estado para mostrar u ocultar el colorPicker
  };


  
  const handleChange = async (color) => {
    
   
    setColor(color.hex.replace('#', '')); // Actualizar el estado con el color seleccionado
  };

  useEffect(() => {
    getInfo()
  }, []);

  const    getInfo = () => {

  };

  const samePSW = (psw) => {
   
    if(newPSW === psw) setIsSamePSW(true)
      else setIsSamePSW(false)
  };

  const initPSW = () => {
    setIsValidPSW("")
    setIsSamePSW('')
    setIsCorrectPSW("")
    setOldPsw('')
    setNewPSW('')
    setRetryPSW('')
    hideChgPSW(true)
  };
  const checkNewPSW = () => {

    const minLength = 8;
    const minSpecialChars = 1;
    const specialCharacters = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
     if (newPSW.length < minLength || !specialCharacters.test(newPSW)) {
      setIsValidPSW(false);
    } else {
      const specialCharsCount = (newPSW.match(specialCharacters) || []).length;
      if (specialCharsCount >= minSpecialChars) {
        setIsValidPSW(true);
      } else {
        setIsValidPSW(false);
      }
    }
  };

  const validPSW = async () => {
    const res = await axios.post(ApiURL + "auth/signin", {
      IdUser_PK: sessionUser.IdEmployee_FK,
      Password: oldPsw
    });
    if (res.data.status === 200){
      setIsCorrectPSW(true)
    }
    else{
      toast.error('la contraseña anterior es incorrecta')
      setIsCorrectPSW(false)}
  };


  const onChangeInput = (e) => {
    e.preventDefault();
  };
  const onSubmit =async (e) => {
    e.preventDefault();
    const resChangePW = await EditingUserF ({ IdUser_PK: sessionUser.IdEmployee_FK, Password: newPSW })
            if (resChangePW.data.status === 200) {
                toast.success(resChangePW.data.msj)
                hideChgPSW(false)
                return
            } else {
                toast.error(resChangePW.data.msj)
                return
            }
  };
  

  return (<div className="card p-3">

    <Row>
      <Col>
        <center>
          <div className="avatar-xl  bg-gradient"
           
           onClick={handleClick}
        style={{ background: `#${color}` }}>
          
            {initial}
          </div>    
           {showColorPicker && (
        <ChromePicker color={`#${color}`} onChange={handleChange} />
      )} 
        </center>
      </Col>

      <Col>
        <Row>
          <Col>
            <div className="form-floating my-2 ">
              <input
                type="text"
                name="IdUser_PK"
                id="IdUser_PK"
                placeholder="Identificación"
                className="form-control"
                value={sessionUser?.IdEmployee_FK?? localStorage.getItem("IdUser_PK")}
                required
                readOnly
              />
              <label htmlFor="IdUser_PK">Identificación</label>
            </div>
          </Col>
          <Col>
            <div className="form-floating my-2 ">
              <input
                type="text"
                name="IdCompany_FK"
                id="IdCompany_FK"
                placeholder="Empresa"
                className="form-control"
                value={sessionUser?.SocialName??'pendiente'}
                required
                readOnly
              />
              <label htmlFor="IdCompany_FK">Empresa</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="form-floating my-2 ">
              <input
                type="text"
                name="UserName"
                id="UserName"
                placeholder="Nombres"
                className="form-control"
                onChange={onChangeInput}
                defaultValue={sessionUser?.NombreTrabajador??localStorage.getItem("UserName")}
                required
                readOnly
              />
              <label htmlFor="UserName">Nombres</label>
            </div>
          </Col>
          <Col>
            <div className="form-floating my-2 ">
              <input
                type="text"
                name="UserLastName"
                id="UserLastName"
                placeholder="Apellidos"
                className="form-control"
                onChange={onChangeInput}
                defaultValue={sessionUser?.ApellidoTrabajador??localStorage.getItem("UserLastName")}
                required
                readOnly
              />
              <label htmlFor="UserLastName">Apellidos</label>
            </div>
          </Col>
        </Row>
        <Row>
        <FloatingLabel controlId="floatingPassword" label="correo electronico">
            <Form.Control type="email" placeholder="correo electronico" 
             value={sessionUser?.emailTrabajador??'pendeinte'}
             required
             readOnly
            />
            
          </FloatingLabel>
          
         
        </Row><br />
        <Row>
        {userStatus === 'active' &&<Col>
            <button
              type="button"
              id="ChangePswBtn"
              className="btn btn-warning m-2 my-1 "
              style={{ width: "204px" }}
              onClick={initPSW}

            >
              <i className="bi bi-unlock mx-2"></i>Cambiar contraseña
            </button>
          </Col>}
          <Col>
            <Signer
              enable={true}
              sign={signature}
              setSign={setSignature}
              doc={'test'}
              user={sessionUser.IdEmployee_FK}
              isView={false}
              save={true}
            />
          </Col>
        </Row>
      </Col>

    </Row>


    <Modal show={showChgPSW}
      onHide={() => {
        hideChgPSW(false)
      }}
      centered

    >


      <Modal.Header closeButton>
        <h5>Cambiar Contraseña</h5>
      </Modal.Header>
      <Modal.Body>

        <InputGroup hasValidation className=" p-2" >
          <FloatingLabel controlId="floatingPassword" label="contraseña anteior">
            <Form.Control type="password" placeholder="contraseña anteior" 
             value={oldPsw}
             onChange={e=> setOldPsw(e.target.value)}
            isValid={isCorrectPSW}
            isInvalid={isCorrectPSW===false}
            onBlur={validPSW}
            />
            <Form.Control.Feedback type="invalid">
              ingrese la contraseña actual.
            </Form.Control.Feedback>
          </FloatingLabel>
        </InputGroup>
        <InputGroup hasValidation className=" p-2" >
          <FloatingLabel controlId="floatingPassword" label="contraseña nueva">
            <Form.Control type="password" placeholder="contraseña bueva" 
             value={newPSW}
             onChange={e=> setNewPSW(e.target.value)}
            isValid={isSValidPSW}
            isInvalid={isSValidPSW===false}
            onBlur={checkNewPSW}
            />
            <Form.Control.Feedback type="invalid">
             la contraseña dene tener minimo 8 caractera con dos caracteres especiales /[!@#$%^
            </Form.Control.Feedback>
          </FloatingLabel>
        </InputGroup>
        <InputGroup hasValidation className=" p-2" >
          <FloatingLabel controlId="floatingPassword" label="confirme la contraseña">
            <Form.Control type="password" placeholder="confirme la contraseña" 
             value={retryPSW}
             onChange={e=> {setRetryPSW(e.target.value); samePSW(e.target.value)}}
            isValid={isSamePSW}
            isInvalid={isSamePSW===false}
            
         
            />
            <Form.Control.Feedback type="invalid">
             la contraseñas no coinciden 
            </Form.Control.Feedback>
          </FloatingLabel>
        </InputGroup>
       
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-primary  "
          onClick={() => {
            hideChgPSW(false)
          }}
        >
          Cancelar
        </Button>
        <Button
          type="submit"
          className="btn btn-warning  "
          id={"delete"}
          disabled={!isCorrectPSW || !isSValidPSW  || !isSamePSW ||  isCorrectPSW==='' || isSValidPSW===''  || isSamePSW===''}
          onClick={onSubmit}
        >
          Cambiar
        </Button >
      </Modal.Footer>
    </Modal>

    {/* <div className="card">
      <div className="card-body py-3">
        <form method="GET" action="UserCtrl">
          <div className="row">
            <div className="col-5  ">

            </div>
            <div className="col-7">
              <div className="row">
                <div className="py-1 col-md-6 col-sm-12">

                </div>
                <div className="py-1 col-md-6 col-sm-12">

                </div>
                <div className="py-1 col-md-6 col-sm-12">

                </div>
                <div className="py-1 col-md-6 col-sm-12">

                </div>
                <div className="py-1 col-md-12 col-sm-12">

                </div>{" "}
                <center>
                  <button
                    className="btn btn-success  m-2"
                    id="EdtBtn"
                    style={{ width: "204px" }}
                    onClick={onEdit}
                  >
                    <i className="bi bi-pen mx-2"></i>Editar
                  </button>
                  <button
                    hidden
                    className="btn btn-danger  m-2"
                    style={{ maxWidth: "142px" }}
                    id="CancelEdtBtn"
                    onClick={onEdit}
                  >
                    <i className="bi bi-x-circle mx-2"></i>Cancelar
                  </button>
                  <button
                    hidden
                    id="SaveEdtBtn"
                    className="btn btn-success  m-2"
                    style={{ maxWidth: "142px" }}
                    onClick={saveEdit}
                  >
                    <i className="bi bi-save mx-2"></i>Guardar
                  </button>

                  <br></br>
                  <button
                    type="button"
                    id="ChangePswBtn"
                    className="btn btn-primary m-2 my-1"
                    style={{ width: "204px" }}
                    onClick={changePsw}
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    <i className="bi bi-unlock mx-2"></i>Cambiar contraseña
                  </button>

                  <div
                    className="modal fade"
                    id="exampleModal"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel">
                            Cambiar Contraseña
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div className="form-floating m-2">
                            <input
                              type="password"
                              autoComplete="current-password"
                              className="form-control"
                              id="current-password"
                              placeholder="Password Actual"
                              onBlur={changePsw}
                            />
                            <label htmlFor="floatingPassword">
                              Password actual
                            </label>
                          </div>
                          <div className="form-floating m-2">
                            <input
                              type="password"
                              className="form-control"
                              id="NewPassword"
                              autoComplete="new-password"
                              placeholder="Nuevo Password"
                            />
                            <label htmlFor="floatingPassword">
                              Password Nuevo
                            </label>
                          </div>
                          <div className="form-floating m-2">
                            <input
                              type="password"
                              className="form-control"
                              id="ConfirmPassword"
                              autoCapitalize="confirm-password"
                              placeholder="Confirmar Password"
                              onBlur={changePsw}
                            />
                            <label htmlFor="floatingPassword">
                              Confirmar Password
                            </label>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-warning"
                            style={{ maxWidth: "100px" }}
                            data-bs-dismiss="modal"
                          >
                            Salir
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            id="ChangePswbtnSave"
                            style={{ maxWidth: "100px" }}
                            onClick={changePsw}
                            data-bs-dismiss="modal"
                          >
                            Cambiar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </center>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div> */}
  </div>
  );
};
export default Profile;
