import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { sessionActive } from '../../../lib/functions'
import { Col, Row, Button, Form } from 'react-bootstrap'
import GTHFOR086 from '../../../lib/formatos/GTHFOR086'
import { getFormats, deleteFormatId, getWorkflowByFormat } from '../../../lib/formatos/request'
import { useUserContext } from '../../../../../hooks/custom'
import GTHFORContract from '../../../lib/formatos/GTHFORContract'

export const Employee = props => {
  const { sessionUser, Permission } = useUserContext()
  const [showFormCreate, setShowFormCreate] = useState(false)
  const [isView, setIsView] = useState(true)
  const [restart, setRestart] = useState(false)
  const [IdFormat_PK, setIdFormat_PK] = useState('')
  const [showForm, setShowForm] = useState(false)
  const [data, setData] = useState()
  const [formats, setFormats] = useState([])
  const [filterindItems, setFilterindItems] = useState([])
  const [search, setSearch] = useState()
  const [idDelete, setIdDelete] = useState()
  const [list, setList] = useState([])
  const [workflow, setWorkflow] = useState([])

  useEffect(() => {
    if (!restart) {
      setRestart(!restart)
      sessionActive()
      fillTable()
    }
  }, [restart])

  const fillTable = async () => {

    //Obtener lista de solicitudes de empleo
    const resFormats = await getFormats('GTHFOR086')
   
    if (resFormats.data.status === 200) {
      setFormats(resFormats.data.data)
      setFilterindItems(resFormats.data.data)
    } else {
      toast.error(resFormats.data.msj)
    }
    setList(resFormats.data.list)




   
  }
  const newContract =  (data) => {
    if (data.Status === "contratacion"){
      return false}
    else if ((Permission.superAdmin.access || Permission.humanTalent.PUT) && data.Status === "disponible")
      return true
    return false
  }
  const editRow = row => {
    if (props.put) {
      if (row.Status === 'creado') return true
      else return false
    }
    return false
  }

  const setWF = async (IdFormat_PK) => {
    setWorkflow(await (await getWorkflowByFormat("GTHFOR086", IdFormat_PK, 'pendiente')).data.data[0])
    return

  }


  const deleteRow = row => {
   
    if (props._delete) {
      if (props.isTH && row.Status === 'creado') return true
      else return false
    }
    return false
  }

  const deleteSol = async () => {
    //borrar un solicitud
    const resDelele = await deleteFormatId('MITFOR207', idDelete)
    if (resDelele.data.status === 200) {
      toast.success(resDelele.data.msj)
    } else {
      toast.error(resDelele.data.msj)
    }
    fillTable()
  }

  const OnFilter = e => {
    let FilterText = e.target.value
    setSearch(FilterText)
    setFilterindItems(
      formats.filter(
        item =>
          //item.IdFormat_PK.includes(FilterText) ||
          item.EmpName.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.EmpLastName.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.EmpCellPhone.includes(FilterText) ||
          item.EmpEmail.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.WorksitePosition.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.Status.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.WorksitePhone.includes(FilterText)

      )
    )
  }

  const ClearSearch = () => {
    setSearch('')
    setFilterindItems(formats)
  }

  const handleChange = (data) => {
    setData(data)
    setShowForm(!showForm)
    setRestart(false)
    setIsView(true)
    setIdFormat_PK(data.IdFormat_PK)
    props.setRestartView(!props.restartView)
  }


  const columns = [
    {
      name: 'Id',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.IdFormat_PK}
        </div>
      ),
      selector: row => row.IdFormat_PK,
      sortable: true,
      fixed: true,
      grow:0.5
    }, {
      name: 'DNI',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.idEmployeePK}
        </div>
      ),
      selector: row => row.idEmployeePK,
      sortable: true,
      fixed: true,
      grow:0.5
    },
    {
      name: 'Nombres',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.EmpName}
        </div>
      ),
      selector: row => row.EmpName,
      sortable: true,
      grow:0.8
    },
    {
      name: 'Apellidos',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.EmpLastName}
        </div>
      ),
      selector: row => row.EmpLastName,
      sortable: true,
      grow:0.8
    },
    {
      name: 'Celular',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.EmpCellPhone}
        </div>
      ),
      selector: row => row.EmpCellPhone,
      sortable: true,
      grow:0.5
    },
    {
      name: 'Email',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.EmpEmail}
        </div>
      ),
      selector: row => row.EmpEmail,
      sortable: true,
      grow:1.5
    },
    {
      name: 'Posición de trabajo',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.WorksitePosition}
        </div>
      ),
      selector: row => row.WorksitePosition,
      sortable: true,
      grow:1.8
    },
    {
      name: 'Estado ',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.Status}
        </div>
      ),
      selector: row => row.Status,
      sortable: true,
      grow: 1.5,
    },
    {
      name: 'Acciones',
      cell: row => (
        <div className="tabla dropdown dropstart">
          <i
            className="bi bi-three-dots-vertical"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          />
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <Link
                onClick={async () => {
                  setWF(row.IdFormat_PK)
                  setShowFormCreate(!showFormCreate)
                  setRestart(false)
                  setIsView(false)
                  props.setRestartView(!props.restartView)
                  setIdFormat_PK("en creacion")
                  setData(row)

                }}
                className={'dropdown-item text-center border-end' + (newContract(row) ? '' : ' disabled')}
                to="/humantalent"
              >
                <i className="bi bi-pen me-3 "></i>Contratar
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  setData({...row, Status:'editando'})
                  setShowForm(!showForm)
                  setRestart(false)
                  setIsView(false)
                  setIdFormat_PK(row.IdFormat_PK)
                }}
                className={'dropdown-item text-center border-end' + (editRow(row) ? '' : ' disabled')}
                to="/humantalent"
              >
                <i className="fas fa-edit me-3"></i>Editar
              </Link>
            </li>
            <li>
              <Link
                className={'dropdown-item text-center border-end' + (deleteRow(row) ? '' : ' disabled')}
                to="/humantalent"
                onClick={() => {
                  setIdDelete(row.IdFormat_PK)
                  setRestart(false)
                }}
              >
                <i className="bi bi-trash me-3"></i>Borrar
              </Link>
            </li>
          </ul>
        </div>
      ),
      button: true,
    },
  ]

  return (
    <>
      <Row>
        <Col>
          {props.newBotton && (
            <Button variant="success"
              className="m-3 p-2 rounded-pill btn-sm ms-1"
              disabled={props.post ? false : true}
              onClick={() => {
                setShowForm(!showForm)
                setRestart(false)
                setIsView(false)
                setIdFormat_PK('en creacion')
              }}
            >
              <i className="fa fa-solid fa-user mx-2" />
              Nuevo Empleado
            </Button>
          )}
          <Col className="d-flex">
            <Form.Control
              type="text"
              id="search"
              placeholder="Búsqueda"
              aria-label="search input"
              value={search}
              onChange={OnFilter}
            />
            <Button data-tip="Limpiar Filtro" className="btn btn-success btn-sm" type="button" onClick={ClearSearch}>
              <i className="bi bi-arrow-counterclockwise" />
            </Button>
          </Col>

          <Row>
            <DataTable
              fixedHeader={false}
              fixedHeaderScrollHeight={{ fixedHeaderScrollHeight: '100px' }}
              columns={columns}
              data={filterindItems}
              pagination
              responsive
              dense={props.dense}
              highlightOnHover
              pointerOnHover
              noDataComponent="No hay registros para mostrar"
              paginationComponentOptions={{
                rowsPerPageText: 'Filas por página:',
                rangeSeparatorText: 'de',
              }}
            />

          </Row>
        </Col>

      </Row>

      {showForm && <GTHFOR086
        sessionUser={props.sessionUser}
        data={data}
        setData={setData}
        show={showForm}
        hide={setShowForm}
        restart={restart}
        setRestart={setRestart}
        isView={isView}
        IdFormat_PK={IdFormat_PK}
        list={list}
      />}
      {showFormCreate && <GTHFORContract
        show={showFormCreate}
        hide={setShowFormCreate}
        data={data}
        isView={isView}
        setData={setData}
        workflow={workflow}
        IdFormat_PK="en creacion"
        setRestart={setRestart}
        restart={restart}
        sessionUser={sessionUser}
      />}
    </>
  )
}

export default Employee
