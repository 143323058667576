import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button, Col, Form, Row, FloatingLabel, ButtonGroup, ToggleButton } from 'react-bootstrap'
import Moment from 'moment'
import { CreateFormat, getFormats, updateFormat } from '../../lib/formatos/request'
import { CreateMultiDataF } from '../../lib/request'
import { toast } from 'react-toastify'
import { capitalLeter } from '../../lib/functions'

export const NewClass = ({
  show,
  hide,
  restart,
  setRestart,
  isView,
  data = [],
  sessionUser,
  setData,
  IdFormat_PK = "En Creación",
  serviceForm,
  Type,
  setServiceForm,
  list
}) => {
  //-------------------------Info Formato

  const typef = 'classTable'

  //-------------------------Registros
  const [status, setStatus] = useState('')
  const [currentUser, setCurrentUser] = useState('')
  const [date, setDate] = useState('')
  const [typeClass, setTypeClass] = useState(true)
  const [equipos, setequipos] = useState('')
  const [classData, setClass] = useState({})
  const [subClass, setSubClass] = useState({})
  const [Features, setFeatures] = useState({})
  const [inputFeatures, setInputFeatures] = useState('')


  const [personas, setpersonas] = useState('')



  useEffect(() => {
    if (!restart) {
      //clearStates()
      setStatus('solicitado')
      setRestart(!restart)
      if (show) getInfo()

    }

  }, [restart])





  const handleChange = async ({ target }) => {
    const { name, type } = target
    let value = type === 'checkbox' ? target.checked : target.value

    if (name === 'Class') {
      if (`${value}`.lenght > 2) return
      else
        value = value.toUpperCase()
    }
    if (name === 'SubClass') {
      if (`${value}`.lenght > 2) return
      else
        value = value.toUpperCase()
    }
    setData({ ...data, [name]: value })
  }

  const getInfo = async () => {
    const formatDate = Moment().format('YYYY-MM-DD')
    setDate(formatDate)
    let temp = list.filter(item => item.Type === 'Class')
    setClass(temp)
    temp = list.filter(item => item.Type === 'SubClass')
    setSubClass(temp) 
    if(data.Type){
    
      setTypeClass(data.Type==='SubClass')
    }
    if(data.Features)
  {  if(data.Features !== 'NA')
        {temp=data.Features.indexOf("{") !== -1 ? JSON.parse(data.Features) : {}
         setFeatures(temp)}}
    return true
  }

  const clearStates = () => {
    setData('')
    setCurrentUser('')
    setDate('')
  }
  const saveFeature = () => {

    if (inputFeatures.length < 2) {
      toast.error('escriba la caracteristica')
      return
    }
    let temp={ ...Features, [capitalLeter(inputFeatures, 3)]: '' }
    setFeatures(temp)
    setData({...data, Features:JSON.stringify(temp)})
    setRestart(!restart)
    setInputFeatures('')
  }
  const handleDelete = async (nameFile) => {
    delete Features[nameFile]
    setData({...data, Features:JSON.stringify(Features)})
    setFeatures(Features)
    setRestart(!restart)
  }
  const render = (nameShow) => {
    return (<div className='flexbox'>
       <div style={{ marginBottom: '10px', marginRight: '10px', height: '100%', width: '100%' }} className="file-preview-container">
        <div className="position-relative bg-light file-preview" style={{ width: '150px', height: '25px' }}>
          {!isView && <a className="delete-button  " onClick={e => handleDelete(nameShow)}>
            <i
              className=" bi bi-trash"
              style={{ zIndex: 10, color: 'red', fontSize: '16px', cursor: 'pointer', position: 'absolute', top: '-3px', right: '12px' }}
            ><label style={{ cursor: 'pointer', position: 'absolute', top: '16px', right: '-7px', fontSize: '10px' }} >borrar</label></i>
          </a>}

          <div style={{ cursor: 'pointer', paddingTop: '8px' }} className="position-absolute top-50 start-50 translate-middle cursor-pointer">
            <h6 className="file-name  text-primary text-capitalize">{nameShow}</h6>
          </div>
        </div>
      </div>

    </div>

    );

  }

  const renderFeatures = () => {
    if (typeClass && Object.keys(Features).length > 0) {

      return (

        Object.keys(Features).map((key) => {
         
          return render(key);
        }
        )
      )
    }
    else
      return null
  }

  const onSubmit = async e => {
    e.preventDefault()
    let temp = ''
    if (!typeClass) {
      if (!data.IdFormat_PK) {
        if (list.filter(item => item.Class === data.Class).length > 0) {
          toast.error('La denominacion de Clase ya existe')
          return
        }
        if (list.filter(item => item.ClassName.toUpperCase().trim().includes(data.ClassName.toUpperCase().trim())).length > 0) {
          toast.error('La Nombre de la Clase ya existe')
          return

        }
      }
      data = {
        ...data,
        ClassName: capitalLeter(data.ClassName, 3),
        SubClass: data.Class,
        Description: 'NA',
        Type: 'Class',
        Field: 0,
        Features: 'NA'
      }

    }
    else {
      if (!data.IdFormat_PK) {
        if (list.filter(item => item.SubClass === data.SubClass).length > 0) {
          toast.error('La denominacion de SubClase ya existe')
          return
        }
        if (list.filter(item => item.Description.toUpperCase().trim().includes(data.Description.toUpperCase().trim())).length > 0) {
          toast.error('la descripción ya existe')
          return
        }
      }
      if (!data.SubClass.startsWith(data.Class)){
        toast.error('la subclase debe empezar por las mismas letra que la clase')
        return
      }
      data = {
        ...data,
        Description: capitalLeter(data.Description, 3),
        Type: 'SubClass',
        Field: 0,
        Features: JSON.stringify(Features)
      }
    }

    if (!data.IdFormat_PK) {
      temp = await CreateFormat(data, typef)
    }
    else {
      temp = await updateFormat(data, typef)

    }

    if (temp.data.status === 200) {
      toast.success(temp.data.msj)
    }
    else {
      toast.error(temp.data.msj);
    }




    clearStates()
    hide(!show)
    setRestart(!restart)
  }



  return (
    <>
      {show && (
        <Overlay style={{ zIndex: '1' }}>
          <ModalContainer>
            <ModalHeader>

            </ModalHeader>
            <CloseButtonModal onClick={() => hide(!show)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-x-square"
                viewBox="0 0 16 16"
              >
                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </CloseButtonModal>


            <Form onSubmit={onSubmit}>
              {!data.IdFormat_PK && <ButtonGroup style={{ width: '100%', textAlign: 'center' }}>
                <ToggleButton
                  style={{ width: '50%' }}
                  id={`radio-1`}
                  type="radio"
                  variant={'outline-primary'}
                  name="radio"
                  value={false}
                  checked={!typeClass}
                  onChange={(e) => setTypeClass(false)}
                >Clase</ToggleButton>
                <ToggleButton
                  style={{ width: '50%' }}
                  id={`radio-2`}
                  type="radio"
                  variant={'outline-success'}
                  name="radio"
                  value={true}
                  checked={typeClass}
                  onChange={(e) => setTypeClass(true)}
                >Subclase</ToggleButton></ButtonGroup>}

              <Frame isView={isView}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>

                    </tr>
                    <tr>
                      <td style={{ width: '30%' }} colSpan={2}>
                        <label>ID:</label>
                      </td>
                      <td style={{ width: '70%' }} colSpan={2}>
                        <input
                          type="text"
                          name="id"
                          defaultValue={data.IdFormat_PK}
                          disabled
                        />
                      </td>

                    </tr>
                    <tr>
                      <td style={{ width: '10%' }} >
                        <label>Clase:</label>
                      </td>
                      <td style={{ width: '15%' }} >
                        <input
                          name="Class"
                          value={data.Class}
                          onChange={handleChange}
                          required
                          disabled={isView || typeClass}
                          maxLength={2}

                        />
                      </td>

                      <td style={{ width: '25%' }}>
                        <label>Nombre de Clase:</label>
                      </td>
                      <td style={{ width: '50%' }}>
                        {!typeClass || isView ? <input
                          name="ClassName"
                          value={data.ClassName}
                          onChange={handleChange}
                          required
                          disabled={isView}


                        /> :
                          <select
                            name="Class"
                            value={data.ClassName}
                            onChange={e => { setData({ ...data, Description: '', Class: e.target.selectedOptions[0].id, SubClass: e.target.selectedOptions[0].id, ClassName: e.target.value }); setFeatures('') }}
                            required
                            disabled={isView ? true : false}
                            placeholder="seleccione..."
                            title="seleccione..."
                          ><option key={0}></option>
                            {Object.keys(classData).map((i) => {
                              return (
                                <option key={i + 1} id={classData[i].Class} value={classData[i].ClassName}>{classData[i].ClassName} </option>
                              );
                            })}
                          </select>
                        }

                      </td>
                    </tr>
                    {typeClass && <><tr>
                      <td>
                        <label>SubClase:</label>
                      </td>
                      <td >
                        <input

                          name="SubClass"
                          value={data.SubClass}
                          onChange={handleChange}
                          required
                          maxLength={4}
                          disabled={isView}
                        />
                      </td>

                      <td>
                        <label>Descripcion:</label>
                      </td>
                      <td >
                        <input
                          name="Description"
                          value={data.Description}
                          onChange={handleChange}
                          required
                          disabled={isView ? true : false}
                        />
                      </td>
                    </tr><br></br>
                      <tr>
                      {typeClass &&  <td colSpan={2}>Caracteteristicas</td>}
                       {!isView &&  <><td colSpan={2} >
                          <input
                            name="inputFeature"
                            value={inputFeatures}
                            onChange={e => setInputFeatures(e.target.value)}

                            disabled={isView}
                            placeholder='escriba una caracteristica ej. Marca'
                          />
                        </td>
                        <td>
                          <Button
                            style={{ width: '25px', position: 'absolute', paddingTop: '0px', paddingLeft: '7px', height: '25px', top: !data.IdFormat_PK?'174px':'135px', right: '3px' }}
                            variant={'outline-primary'}
                            type="button"
                            className="btn btn-light"
                            onClick={saveFeature}
                          >+</Button>
                        </td></>}
                      </tr>
                    </>}

                  </tbody>
                </table>
                {(true ) && <div style={{ marginTop: '20px', display: 'flex', flexWrap: 'wrap' }}>
                  {renderFeatures()}

                </div>}
              </Frame>


              <Row>
                <Col></Col>
                <Col></Col>
                <Col></Col>
                <Col></Col>

                {!isView ? (
                  < >
                    <Col>
                      <Button
                        className="btn btn-danger"
                        onClick={() => {
                          clearStates()
                          hide(!show)
                        }}
                      >
                        Cancelar
                      </Button>
                    </Col>
                    <Col>
                      <Button
                      style={{position:'absolute', Button:'0px', right:'0px'}}
                      type="submit" className="btn btn-success">
                        Guardar
                      </Button>
                    </Col>
                  </>
                ) : (
                  <Col>
                    <Button
                      className="btn btn-secundary"
                      onClick={() => {
                        clearStates()
                        hide(!show)
                      }}
                    >
                      Cerrar
                    </Button>
                  </Col>
                )}
              </Row>
            </Form>
          </ModalContainer>
        </Overlay>
      )}
    </>
  )
}

export default NewClass

const Frame = styled.div`
  width: 100%;
  padding-bottom: 0px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: left;

  font-size: 12px;
  font-weight: 100;
  .photo {
    background: #ebf1de;
  }
  .rms {
    background: #44bb7a;
  }
  FloatingLabel {
    
    display: flex;
    font-size: 29px;
    font-weight: NORMAL;
    text-align: 'center';
    padding-left: 25px;
    opacity: 0.65;
    transform: scale(0.75) translateY(0rem) translateX(0.15rem);
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  }
  input {
    text-align: center;
    width: 100%;
    border-bottom: 1px solid black;
    border-color: #1a004a;
    background: ${props => (props.isView ? '#FFF' : '#cee1ed')};

    padding-left: 5px;
    margin-top: 5px;
  }
  textarea {
    text-align: justify;
    width: 100%;
    border-bottom: 1px solid black;
    border-color: #1a004a;
    background: ${props => (props.isView ? '#FFF' : '#cee1ed')};

    padding-left: 5px;
    margin-top: 5px;
  }

  input.edit {
    background: #cee1ed;
  }
  input.disable {
    background: #fff;
  }
  h2 {
    font-size: 14px;
    font-weight: bold;
    text-align: 'center';
  }

  th {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    text-align: left;
    vertical-align: center;
    padding-left: 5px;

    input {
      width: 100%;
    }
  }
  tr {
    border-width: 0px;
    border-color: #1a004a;
    border-style: solid;
  }
`

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgb(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalContainer = styled.div`
  width: 550px;
  height: 400px;
  min-height: 300px;
  background: #fff;
  position: relative;
  border-radius: 5px;
  overflow: scroll;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
`

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
  padding-bottom: 10px;

  th {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
  }
  tr {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
  }
  table {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    text-align: center;
    vertical-align: center;
    font-size: 14px;
  }
  h3 {
    font-weight: 500;
    font-size: 16px;
    color: #1766dc;
  }
`

const CloseButtonModal = styled.div`
  position: absolute;
  top: 2px;
  right: 5px;
  width: 20px;
  height: 20px;
border: no ne;
backgro u nd: none;
cursor :  pointer;
trans i tion: 0.3s ease all;
border- r adius: 5px;
color:   #1766dc;
&:hove r  {
  back g round: #f2f2f2;
}  
svg  { 
    width: 100%;
  height: 100%;
}
`
