import React from 'react'
import { Navigate } from 'react-router'

export default class NotFound extends React.Component {
    render() {
        return (
            <Navigate to="/"/>
        )
    }
}
