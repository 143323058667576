import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button, Col, Form, Row } from 'react-bootstrap'
import Moment from 'moment'
import { CreateFormat, getFormats, updateFormat } from './request'
// import { CommTable } from '../../commercial/CommTable'
import { toast } from 'react-toastify'
import Signer from '../signer'

export const Inform = ({
  show,
  hide,
  restart,
  setRestart,
  isView,
  data = [],
  sessionUser,
  setData,
  IdFormat_PK ,
  serviceForm,
  setServiceForm,
  eqList,
  personalList
}) => {
  //-------------------------Info Formato
  const formato = 'BOG-O&M1-FOR-5'
  const revision = 'N/A'
  const fechaRev = 'N/A'
  const nombreFormato = 'ACTA DE ENTREGA- FINALIZACIÓN DEL SERVICIO'
  const typef = 'informe'
  const type = 'informe'

  //-------------------------Registros
  const [status, setStatus] = useState('')
  const [currentUser, setCurrentUser] = useState('')
  const [date, setDate] = useState('')
  const [equipos, setequipos] = useState('')
  const [personas, setpersonas] = useState('')
  const [eqTable, setEqTable] = useState([])
  const [personalTable, setPersonalTable] = useState([])
  const [IdUser_FK, setIdUser_FK] = useState('')
 
  const [firmaCliente, setfirmaCliente] = useState('')
  const [firmaEjecutor, setfirmaEjecutor] = useState('')

  const [signer, setsigner] = useState('')


  useEffect(() => {
    if (!restart) {

      setStatus('solicitado')
      setRestart(!restart)
      if (show) getInfo()

    }

  }, [restart])


  const handleChange = async ({ target }) => {
    const { name, type } = target
    const value = type === 'checkbox' ? target.checked : target.value
    setData({ ...data, [name]: value })
  }

  const getInfo = async () => {
    const formatDate = Moment().format('YYYY-MM-DD')
    setDate(formatDate)
    console.log(data)
    if (data.length !== 0) {
      

    }
    // else {
    //   data["status"] = "creación"
    //   console.log(data.status)
    // }



    return true
  }

  const clearStates = () => {
    data = []
    setData('')
    setCurrentUser('')
    setDate('')
  }
  const onSubmit = async e => {
    e.preventDefault()

    var result = ''
    var Cliente = {
      ApellidoTrabajador: sessionUser.ApellidoTrabajador,
      IdContract_PK: sessionUser.IdContract_PK,
      NombreTrabajador: sessionUser.NombreTrabajador,
      cargo: sessionUser.cargo,
      IdEmployee_FK: sessionUser.IdEmployee_FK,
      firma: firmaCliente,
      fecha: date,
    }
    
    var Ejecutor = {
      ApellidoTrabajador: sessionUser.ApellidoTrabajador,
      IdContract_PK: sessionUser.IdContract_PK,
      NombreTrabajador: sessionUser.NombreTrabajador,
      cargo: sessionUser.cargo,
      IdEmployee_FK: sessionUser.IdEmployee_FK,
      firma: firmaEjecutor,
      fecha: date,
    }
    
    var firmas = {...signer, Cliente,Ejecutor}
    firmas = JSON.stringify(firmas)

    const temp = {
      ...data,
      firmas,
      
      
      status: "Activo",
      // createdBy: sessionUser.IdEmployee_FK,
      // createdDate: date
    }

      if(data.IdFormat_PK===undefined)
         result =  await CreateFormat(temp, typef)
       else
       result =  await updateFormat(temp, typef)

     if (result.data.status === 200) {
       toast.success(result.data.msj)
     }
     else
      {
       toast.error(result.data.msj);
     }


    clearStates()
    hide(!show)
    setRestart(!restart)
  }



  return (
    <>
      {show && (
        <Overlay style={{ zIndex: '1' }}>
          <ModalContainer>
            <ModalHeader>
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <th style={{ width: '20%', verticalAlign: 'bottom' }} rowSpan={3}>
                      NIT 830.507.705-2
                    </th>
                    <th style={{ width: '55%', height: '38px' }}>RELIABILITY MAINTENANCE SERVICES S.A.S</th>
                    <th style={{ width: '25%' }}>{formato}</th>
                  </tr>
                  <tr>
                    <th rowSpan={2}>{nombreFormato}</th>
                    <th style={{ fontSize: '10px' }}>Revisión: {revision}</th>
                  </tr>
                  <tr>
                    <th style={{ fontSize: '10px' }}>Fecha de revisión: {fechaRev}</th>
                  </tr>
                </tbody>
              </table>
            </ModalHeader>
            <CloseButtonModal onClick={() => hide(!show)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-x-square"
                viewBox="0 0 16 16"
              >
                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </CloseButtonModal>


            <Form onSubmit={onSubmit}>
              <Frame isView={isView}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <th className="rms" colSpan={8} style={{ width: '100%' }}>
                        <h2 className="text-center">DESCRIPCION DE LA ORDEN DE TRABAJO</h2>
                      </th>
                    </tr>
                    <tr>
                      <td style={{ width: '20%' }}>
                        <label>Fecha Solicitud </label>
                      </td>
                      <td>
                        <input className='disable' name='Fecha_Informe' type={'date'} value={data.Fecha_Informe} onChange={handleChange}  disabled={true}></input>
                      </td>
                      <td style={{ width: '20%' }}>
                        <label>Estado Informe </label>
                      </td>
                      <td>
                        <input className='disable' name='status'  value={data.status} onChange={handleChange}  disabled={true} ></input>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: '20%' }}>
                        <label>Orden de servicio</label>
                      </td>
                      <td colSpan={3}>
                        <input
                        className='disable'
                        name="OrdenServicio"
                        value={data.OrdenServicio} 
                        onChange={handleChange}
                        required 
                        disabled={true}
                        ></input>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: '20%' }}>
                        <label>Tipo de servicio</label>
                      </td>
                      <td colSpan={3}>
                        <input
                        className='disable'
                        name="TipoServicio"
                        value={data.TipoServicio} 
                        onChange={handleChange}
                        required 
                        disabled={true}
                        ></input>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: '20%' }}>
                        <label>Alcance</label>
                      </td>
                      <td colSpan={3}>
                        <input
                        className='disable'
                        name="Alcance"
                        value={data.Alcance} 
                        onChange={handleChange}
                        required 
                        disabled={true}></input>
                      </td>
                    </tr>

                  </tbody>
                </table>
              </Frame>
              <Frame isView={isView}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <th className="rms" colSpan={8} style={{ width: '100%' }}>
                        <h2 className="text-center">INFORMACIÓN DEL CLIENTE</h2>
                      </th>
                    </tr>
                    <tr>
                      <td style={{ width: '20%' }}>
                        <label>Razon social</label>
                      </td>
                      <td colSpan={5}>
                        <input 
                        className='disable'
                        name="RazonSocial"
                        value={data.RazonSocial} 
                        onChange={handleChange}
                        required 
                        disabled={true}></input>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: '20%' }}>
                        <label>Lugar de ejecucion</label>
                      </td>
                      <td colSpan={5}>
                        <input
                        className='disable'
                        name="LugarEjecucion"
                        value={data.LugarEjecucion} 
                        onChange={handleChange}
                        required 
                        disabled={true}
                        ></input>
                      </td>
                    </tr>
                    <tr>
                      <th colSpan={8} style={{ width: '100%' }}><h2 className="text-center">Datos de contacto</h2></th>

                    </tr>
                    <tr>
                      <td >
                        <label>Contacto</label>
                      </td>
                      <td>
                        <input
                        className='disable'
                        name="Contacto"
                        value={data.Contacto} 
                        onChange={handleChange}
                        required 
                        disabled={true}
                        ></input>
                      </td>
                      <td >
                        <label>Teléfono</label>
                      </td>
                      <td>
                        <input
                        className='disable'
                        name="Telefono"
                        value={data.Telefono} 
                        onChange={handleChange}
                        required 
                        disabled={true}
                        ></input>
                      </td>
                      <td >
                        <label>Email</label>
                      </td>
                      <td>
                        <input
                        className='disable'
                        name="Email"
                        value={data.Email} 
                        onChange={handleChange}
                        required 
                        disabled={true}
                        ></input>
                      </td>
                    </tr>

                  </tbody>
                </table>
              </Frame>
              <Frame isView={isView}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <th className="rms" colSpan={8} style={{ width: '100%' }}>
                        <h2 className="text-center">DESCRIPCIÓN DE LAS ACTIVIDADES EJECUTADAS</h2>
                      </th>
                    </tr>
                    <tr>
                      <td>
                        <textarea
                        name="Descripcion"
                        value={data.Descripcion} 
                        onChange={handleChange}
                        required 
                        disabled={isView ? true : false}
                        ></textarea>
                      </td>
                    </tr>


                  </tbody>
                </table>
              </Frame>
              <Frame isView={isView}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <th className="rms" colSpan={8} style={{ width: '100%' }}>
                        <h2 className="text-center">ADJUNTOS</h2>
                      </th>
                    </tr>
                    <tr>
                      <td style={{ width: '20%' }}>
                        <label>Informe técnico</label>
                      </td>
                      <td>
                        <input type={'file'} ></input>
                        
                      </td>
                      
                      
                    </tr>


                  </tbody>
                </table>
              </Frame>
              <Frame isView={isView}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <th className="rms" colSpan={8} style={{ width: '100%' }}>
                        <h2 className="text-center">OBSERVACIONES DEL CLIENTE  DEL TRABAJO EJECUTADO</h2>
                      </th>
                    </tr>
                    <tr>
                      <td style={{ width: '30%' }}>
                        <label>Marcar la casilla correspondiente <br />¿Se recibe satisfaccion?</label>
                      </td>
                      <td>
                        <label>Si<input
                         type={'radio'}
                         name={'Satisfaccion'}
                         value={'Si'}
                         checked={data['Satisfaccion'] === 'Si' }
                         onChange={handleChange}
                         ></input></label>

                      </td>
                      <td>
                        <label>No<input
                         type={'radio'}
                         name={'Satisfaccion'}
                         value={'No'}
                         checked={data['Satisfaccion'] === 'No' }
                         onChange={handleChange}
                         ></input></label>
                      </td>
                    </tr>
                    <tr>
                      <th colSpan={8} style={{ width: '100%' }}><h2 >Por favor calificar en cada cuadro teniendo presente los siguientes parametros</h2></th>
                    </tr>
                    <tr>
                      <th colSpan={8} style={{ width: '100%' }}><h2 >(1)Malo <br /> (3)Regular <br />  (5)Bueno</h2></th>

                    </tr>
                    <tr>
                      <th colSpan={3} style={{ width: '52%' }}><label >Cumplimiento de entrega</label></th>
                      <td >
                      <label>1
                        <input
                      type={'radio'} 
                      name="CumplimientoEntrega"
                      value={'1'}
                      checked={data['CumplimientoEntrega'] === '1' }
                        onChange={handleChange}
                        required 
                        disabled={isView ? true : false}
                      ></input>
                        </label>  
                      </td>
                      
                      <td >
                      <label>3
                        <input
                      type={'radio'} 
                      name="CumplimientoEntrega"
                      value={'3'}
                      checked={data['CumplimientoEntrega'] === '3' }
                        onChange={handleChange}
                        required 
                        disabled={isView ? true : false}
                      ></input>
                        </label>  
                      </td>
                      
                      <td >
                      <label>5
                        <input
                      type={'radio'} 
                      name="CumplimientoEntrega"
                      value={'5'}
                      checked={data['CumplimientoEntrega'] === '5' }
                        onChange={handleChange}
                        required 
                        disabled={isView ? true : false}
                      ></input>
                        </label>  
                      </td>
                      
                    </tr>
                    <tr>
                      <th colSpan={3} style={{ width: '52%' }}><label >Cumplimiento de las especificaciones</label></th>
                      <td >
                      <label>1
                        <input
                      type={'radio'} 
                      name="CumplimientoEspecificaciones"
                      value={'1'}
                      checked={data['CumplimientoEspecificaciones'] === '1' }
                        onChange={handleChange}
                        required 
                        disabled={isView ? true : false}
                      ></input>
                        </label>  
                      </td>
                      
                      <td >
                      <label>3
                        <input
                      type={'radio'} 
                      name="CumplimientoEspecificaciones"
                      value={'3'}
                      checked={data['CumplimientoEspecificaciones'] === '3' }
                        onChange={handleChange}
                        required 
                        disabled={isView ? true : false}
                      ></input>
                        </label>  
                      </td>
                      
                      <td >
                      <label>5
                        <input
                      type={'radio'} 
                      name="CumplimientoEspecificaciones"
                      value={'5'}
                      checked={data['CumplimientoEspecificaciones'] === '5' }
                        onChange={handleChange}
                        required 
                        disabled={isView ? true : false}
                      ></input>
                        </label>  
                      </td>
                    </tr>
                    <tr>
                      <th colSpan={3} style={{ width: '52%' }}><label >Cumplimiento de las especificaciones comerciales</label></th>
                      <td >
                      <label>1
                        <input
                      type={'radio'} 
                      name="CumplimientoEspecificacionesComerc"
                      value={'1'}
                      checked={data['CumplimientoEspecificacionesComerc'] === '1' }
                        onChange={handleChange}
                        required 
                        disabled={isView ? true : false}
                      ></input>
                        </label>  
                      </td>
                      
                      <td >
                      <label>3
                        <input
                      type={'radio'} 
                      name="CumplimientoEspecificacionesComerc"
                      value={'3'}
                      checked={data['CumplimientoEspecificacionesComerc'] === '3' }
                        onChange={handleChange}
                        required 
                        disabled={isView ? true : false}
                      ></input>
                        </label>  
                      </td>
                      
                      <td >
                      <label>5
                        <input
                      type={'radio'} 
                      name="CumplimientoEspecificacionesComerc"
                      value={'5'}
                      checked={data['CumplimientoEspecificacionesComerc'] === '5' }
                        onChange={handleChange}
                        required 
                        disabled={isView ? true : false}
                      ></input>
                        </label>  
                      </td>
                    </tr>
                    <tr>
                      <th colSpan={8} style={{ width: '50%' }}><label >Observaciones y/o comentarios adicionales</label></th>
                      
                    </tr>
                    <tr>
                      <td colSpan={8}><textarea
                      name="Observaciones"
                      value={data.Observaciones} 
                      onChange={handleChange}
                      required 
                      disabled={isView ? true : false}
                      ></textarea></td>
                    </tr>
                    <tr>
                      <th colSpan={8} style={{ width: '100%' }}><h2  >Observacion <br /> Para peticiones, quejas, reclamos y/o reconocimientos, puede por favor: <br /> Escribir al email: info@rms.com.co  <br /> Llamar al numero celular: (57) 321 490 8758 <br/> Diligenciar fomulario en https://goo.gl/forms/xNo7YqjBWu5XeKF62 </h2></th>
                    </tr>
                    

                  </tbody>
                </table>
              </Frame>
              <Frame isView={isView}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <th colSpan={4} style={{ width: '52%' }}><label >Cliente</label></th>
                    <th colSpan={4} style={{ width: '52%' }}><label >RMS S.A</label></th>
                    </tr>
                    <tr>
                      <th colSpan={4} style={{ alignItems: 'center' }}>
                        <Signer
                          enable={ IdFormat_PK === 'en creacion' ? true : false}
                          sign={firmaCliente}
                          setSign={setfirmaCliente}
                          doc={type}
                          user={IdUser_FK}
                        />
                      </th>
                      <th colSpan={4} style={{ alignItems: 'center' }}>
                        <Signer
                         enable={ IdFormat_PK === 'en creacion' ? true : false}
                         sign={firmaEjecutor}
                         setSign={setfirmaEjecutor}
                         doc={type}
                         user={IdUser_FK}
                        />
                      </th>
                    </tr>
                    <tr>
                      <th colSpan={3} ><label>Nombre</label></th>
                      <td><input></input></td>
                      <th colSpan={3} ><label>Nombre</label></th>
                      <td><input></input></td>
                    </tr>
                    <tr>
                    <th colSpan={3} ><label>Cargo</label></th>
                      <td><input></input></td>
                      <th colSpan={3} ><label>Cargo</label></th>
                      <td><input></input></td>
                    </tr>
                    
                      
                    </tbody>
                </table>
              </Frame>

              <Row>
                <Col></Col>
                <Col></Col>
                <Col></Col>
                <Col></Col>
                {!isView ? (
                  <>
                    <Col>
                      <Button
                        className="btn btn-danger"
                        onClick={() => {
                          clearStates()
                          hide(!show)
                        }}
                      >
                        Cancelar
                      </Button>
                    </Col>
                    <Col>
                      <Button type="submit" className="btn btn-success">
                        Guardar
                      </Button>
                    </Col>
                  </>
                ) : (
                  <Col>
                    <Button
                      className="btn btn-secundary"
                      onClick={() => {
                        clearStates()
                        hide(!show)
                      }}
                    >
                      Cerrar
                    </Button>
                  </Col>
                )}
              </Row>
            </Form>
          </ModalContainer>
        </Overlay>
      )}
    </>
  )
}

export default Inform

const Frame = styled.div`
          width: 100%;
          padding-bottom: 0px;
          margin-bottom: 10px;
          align-items: center;
          justify-content: left;
          border-width: 2px;
          border-color: #1a004a;
          border-style: solid;
          font-size: 12px;
          font-weight: 100;
          .photo{
            background:  #ebf1de
  }
          .rms{
            background:  #F2F2F2
  }
  label {
          padding-left: 5px;
          display: flex;
          font-size: 12px;
          font-weight: bold;
          text-align: 'center';
    
  }
  input {
          text-align: center;
          width: 100%;
          border-bottom: 1px solid black;
          border-color: #1a004a;
          background: ${props => (props.isView ? '#FFF' : '#cee1ed')};
          padding-left: 5px;
          /* margin-top: 5px; */
  }
  textarea {
    text-align: justify;
    width: 100%;
    min-height: 80px ;
    border-bottom: 1px solid black;
    border-color: #1a004a;
    background: ${props => (props.isView ? '#FFF' : '#cee1ed')};
    resize: none;
    
    
  } 
  

  input.edit {
    background: #cee1ed;
  }
  input.disable {
    background: #fff;
  }
  h2 {
    font-size: 14px;
    font-weight: bold;
    text-align: 'center';
  }
  h3 {
    font-size: 20px;
    font-weight: 600;
    padding: 8px;
  }
  p {
    font-size: 16px;
    font-weight: 500;
    padding: 10px;
  }

  th {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    text-align: left;
    vertical-align: center;
    padding-left: 5px;

    input {
      width: 100%;
    }
  }
  tr {
    border-width: 0px;
    border-color: #1a004a;
    border-style: solid;
  }
    
  td {
          border-width: 1px;
          border-color: #1a004a;
          border-style: solid;
  }
`

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgb(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalContainer = styled.div`
  width: 1000px;
  max-height: 90vh;
  min-height: 500px;
  background: #fff;
  position: relative;
  border-radius: 5px;
  overflow: scroll;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
`

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
  padding-bottom: 10px;

  th {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
  }
  tr {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
  }
  table {
    border-width: 1px;
    border-color: #1a004a;
    border-style: solid;
    text-align: center;
    vertical-align: center;
    font-size: 14px;
  }
  h3 {
    font-weight: 500;
    font-size: 16px;
    color: #1766dc;
  }
`

const CloseButtonModal = styled.div`
  position: absolute;
  top: 2px;
  right: 5px;
  width: 20px;
  height: 20px;
  border: none;
  background: none;
  cursor: pointer;
  transition: 0.3s ease all;
  border-radius: 5px;
  color: #1766dc;
  &:hover {
    background: #f2f2f2;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`
