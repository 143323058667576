import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { sessionActive } from '../../../lib/functions'
import { Col, Row, Button, Form, Modal } from 'react-bootstrap'

import COMFOR167 from '../../../lib/formatos/COMFOR167'
import { getFormats, updateFormat, eraseFormatId } from '../../../lib/formatos/request'
import SelectOption from '../../../lib/SelectOption'
import { useUserContext } from '../../../../../hooks/custom'
import SpinnerLoad from '../../../lib/spinnerLoad'

export const RQ = props => {
  const { sessionUser } = useUserContext()
  const IdUser_PK = localStorage.getItem('IdUser_PK')
  const [restart, setRestart] = useState(false)
  const [IdFormat_PK, setIdFormat_PK] = useState('')
  const [showForm, setShowForm] = useState(false)
  const [formats, setFormats] = useState([])
  const [isView, setIsView] = useState(false)
  const [filterStatus, setFilterStatus] = useState("")
  const [idDelete, setIdDelete] = useState()
  const [idAction, setIdAction] = useState()
  const [search, setSearch] = useState('')
  const [data, setData] = useState('')
  const [filterindItems, setFilterindItems] = useState()
  const [initData, setInitData] = useState('')
  const [ModalDelete, setModalDelete] = useState(false)
  const [modalAction, setModalAction] = useState(false)
  const [actionBtn, setActionBtn] = useState("Finalizar")
  const [list, setList] = useState([])
  const typef = 'COMFOR167'
  const [showModal, setShowModal] = useState(true)

  useEffect(() => {

    if (!restart) {
      setRestart(!restart)
      sessionActive()
      fillTable()
    }
  }, [restart])

  useEffect(() => {
    refillTable()

  }, [filterStatus])

  const estados = [{ label: 'Aprobar', value: 'Aprobar' },
  { label: 'Procesar', value: 'Procesar' },
  { label: 'Finalizada', value: 'Finalizada' }]



  const refillTable = async () => {

    //let _data = initData.filter(item => filterStatus.includes(item.Status));
    setFormats(filterStatus.length === 0 ? initData : initData.filter(item => filterStatus.includes(item.Status)))
    setFilterindItems(filterStatus.length === 0 ? initData : initData.filter(item => filterStatus.includes(item.Status)))
    setSearch('')
  }

  const fillTable = async () => {

    //Obtener lista de servicios
    var resFormats = await getFormats('COMFOR167')
    if (resFormats.data.status === 200) {
      const _data = (props.isSP || props.isOper) ? resFormats.data.data : resFormats.data.data.filter(registro => {
        const firmaSolicitante = JSON.parse(registro.firmaSolicitante);
        return firmaSolicitante.IdEmployee_FK === sessionUser.IdEmployee_FK;
      });
      setFormats(_data)
      setFilterindItems(_data)
      setInitData(_data)
    } else {
      toast.error(resFormats.data.msj)
    }
    setList(resFormats.data.list)
  }


  useEffect(() => {
    sessionActive()
    fillTable()

  }, [restart])

  const deleteSol = async () => {
    //borrar un solicitud
    const resDelele = await eraseFormatId(typef, idDelete)
    if (resDelele.data.status === 200) {
      toast.success(resDelele.data.msj)
    } else {
      toast.error(resDelele.data.msj)
    }
    fillTable()
  }
  const ActionSol = async () => {
    let newStatus = actionBtn
    if (actionBtn === "Anular") newStatus = "Anulado"
    if (actionBtn === "Revertir") newStatus = "Procesar"
    if (actionBtn === "Finalizar") return
    const result = await updateFormat({ IdFormat_PK: idAction, Status: newStatus }, typef)
    if (result.data.status === 200) {
      toast.success(result.data.msj)
    } else {
      toast.error(result.data.msj)
    }
    ClearSearch()
    fillTable()

  }

  const OnFilter = e => {

    let FilterText = e.target.value
    setSearch(FilterText)
    setFilterindItems(
      formats.filter(

        item =>
          //item.IdFormat_PK.includes(!isNaN(FilterText) ? FilterText : 0) ||
          item.createdBy.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.Contrato.includes(FilterText) ||
          item.Area.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.TipoRequisicion.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.Justificacion.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.EntregarEn.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.EntregarA.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.BuyTable.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.Status.toLowerCase().includes(FilterText.toLowerCase())
      )
    )
  }

  const ClearSearch = () => {
    setFilterStatus('')
    setSearch('')
    setFilterindItems(initData)
    setRestart(!restart)
  }

  const handleChange = (data) => {

    setData(data)
    setShowModal(!showModal)
    setShowForm(!showForm)
    setRestart(false)
    setIsView(true)
    setIdFormat_PK(data.IdFormat_PK)

  }
  const editRow = row => {

    if (props.put) {
      if (IdUser_PK === JSON.parse(row.firmaSolicitante).IdEmployee_FK && row.Status === 'firmaJefe') return true

      else return false
    }
    return false
  }
  const deleteRow = row => {
    if (props.delete) {
      if (IdUser_PK === JSON.parse(row.firmaSolicitante).IdEmployee_FK && row.Status === 'firmaJefe') return true

      else return false
    }
    return false
  }

  const columns = [
    {
      name: 'Id',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.IdFormat_PK}
        </div>
      ),
      selector: row => row.IdFormat_PK,
      sortable: true,
      grow: 0.2
    },
    {
      name: 'Solicitante',
      cell: row => (<div onClick={() => { handleChange(row) }} data-tag="allowRowEvents">{row.createdBy}</div>),
      selector: row => row.createdBy,
      sortable: true,
      grow: 2
    },

    {
      name: 'Contrato',
      cell: row => (<div onClick={() => { handleChange(row) }} data-tag="allowRowEvents">{row.Contrato}</div>),
      selector: row => row.Contrato,
      sortable: true,
    },
    {
      name: 'Area',
      cell: row => (<div onClick={() => { handleChange(row) }} data-tag="allowRowEvents">{row.Area}</div>),
      selector: row => row.Area,
      sortable: true,
      grow: 0.5
    }, {
      name: 'Entregar a',
      cell: row => (<div onClick={() => { handleChange(row) }} data-tag="allowRowEvents">{row.EntregarA}</div>),
      selector: row => row.EntregarA,
      sortable: true,
    },
    {
      name: 'Tipo de requisicion',
      cell: row => (<div onClick={() => { handleChange(row) }} data-tag="allowRowEvents">{row.TipoRequisicion}</div>),
      selector: row => row.TipoRequisicion,
      sortable: true,
      grow: 1.5
    },
    {
      name: 'Estado',
      cell: row => (<div onClick={() => { handleChange(row) }} data-tag="allowRowEvents">{(row.Status)}</div>),
      selector: row => row.Status,
      sortable: true,
    }, {
      name: 'Acciones',
      cell: row => (
        <div className="tabla dropdown dropstart">
          <i
            className="bi bi-three-dots-vertical"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          />
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            {!props.isSP ? <><li>
              <Link
                onClick={() => {

                  setData({ ...row, Status: "edicion" }
                  )
                  setShowForm(!showForm)
                  setRestart(false)
                  setIsView(false)
                  setIdFormat_PK(row.IdFormat_PK)
                }}
                className={'dropdown-item text-center border-end' + (editRow(row) ? '' : ' disabled')}
                to={props.route}
              >
                <i className="bi bi-pen me-3"></i>Editar
              </Link>
            </li>
              <li>
                <Link
                  className={'dropdown-item text-center border-end' + (deleteRow(row) ? '' : ' disabled')}
                  to={props.route}
                  onClick={() => {
                    setIdDelete(row.IdFormat_PK)
                    setRestart(false)
                    setModalDelete(true)
                  }}
                >
                  <i className="bi bi-trash me-3"></i>Borrar
                </Link>
              </li></> : <><li>
                <Link
                  className={'dropdown-item text-center border-end' + (row.Status === 'Procesar' || row.Status === 'Anulado' ? '' : ' disabled')}
                  to={props.route}
                  onClick={() => {
                    setActionBtn(row.Status === 'Anulado' ? "Revertir" : 'Anular')
                    setIdAction(row.IdFormat_PK)
                    setRestart(false)
                    setModalAction(true)
                  }}
                >
                  <i className={row.Status === 'Anulado' ? 'bi bi-folder-symlink' : 'bi bi-folder-x'}></i> {row.Status === 'Anulado' ? ' Revertir ' : ' Anular '}
                </Link>
              </li> <li>
                <Link
                  className={'dropdown-item text-center border-end' + (row.Status === 'Procesar' ? '' : ' disabled')}
                  to={props.route}
                  onClick={() => {
                    setActionBtn("Finalizar")
                    setData({ ...row, Status: 'Finalizar' })
                    setIdFormat_PK(row.IdFormat_PK)
                    setIdAction(row.IdFormat_PK)
                    setRestart(false)
                    setModalAction(true)
                  }}
                >
                  <i className="bi bi-bag-check me-3"></i>Finalizar
                </Link>
              </li></>}
          </ul>
        </div>
      ),
      button: true,
    }

  ]


  return (
    <div>

      <Row >
        <Col >
          {props.newBotton && (
            <Button
              variant="success"
              className="m-3 p-2 rounded-pill btn-sm ms-1"
              disabled={props.post ? false : true}
              onClick={() => {
                setRestart(false)
                setShowForm(!showForm)
                setData({ Status: 'en creacion' })
                setIsView(false)
                setIdFormat_PK('en creacion')
              }}>

              <i className="fa-solid fa-shop mx-2" />Nueva Compra{''}
            </Button>
          )}
          <Col className="d-flex " style={{ width: '100%' }}>

            <Form.Control
              defaultValue={''}
              type="text"
              id="search"
              style={{ width: '65%' }}
              placeholder="Búsqueda"
              aria-label="search input"
              value={search || ''}
              onChange={OnFilter}
            />

            <SelectOption
              type={'custom'}
              widthSelect={'380px'}
              searchable={true}
              state={filterStatus}
              setState={setFilterStatus}
              list={estados}
              multi={true}
              placeHolder={'filtro...'}
            />
            <Button data-tip="Limpiar Filtro" className="btn btn-success btn-sm" type="button" onClick={ClearSearch}>
              <i className="bi bi-arrow-counterclockwise" />
            </Button>
          </Col>
          <Row>
            <SpinnerLoad trigger={initData} />
            <DataTable
              fixedHeader={false}
              fixedHeaderScrollHeight={{ fixedHeaderScrollHeight: '100px' }}
              columns={columns}
              data={filterindItems}
              pagination
              responsive
              dense={props.dense}
              highlightOnHover
              pointerOnHover
              noDataComponent="No hay registros para mostrar"
              paginationComponentOptions={{
                rowsPerPageText: 'Filas por página:',
                rangeSeparatorText: 'de',
              }}
            />
          </Row>
        </Col>
      </Row>
      {showForm && <COMFOR167
        sessionUser={sessionUser}
        data={data}
        setData={setData}
        show={showForm}
        hide={setShowForm}
        restart={restart}
        setRestart={setRestart}
        isView={isView}
        IdFormat_PK={IdFormat_PK}

        list={list}
      />}
      <Modal
        show={ModalDelete}
        onHide={() => {
          setModalDelete(false)
        }}
        centered
      >
        <Modal.Header closeButton>
          <h5>Eliminar Solicitud</h5>
        </Modal.Header>
        <Modal.Body>
          <p>¿Realmente desea eliminar la RQ {idDelete}?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {

              setModalDelete(false)
            }}
          >
            Cancelar
          </Button>
          <Button className="btn btn-danger"
            onClick={() => {
              deleteSol()
              setModalDelete(false)
            }}
          >
            Eliminar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={modalAction}
        onHide={() => {
          setModalAction(false)
        }}
        centered
      >
        <Modal.Header closeButton>
          <h5>{actionBtn} Solicitud</h5>
        </Modal.Header>
        <Modal.Body>
          <p>¿Realmente desea {actionBtn.toLowerCase()} la RQ {idAction}?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {

              setModalAction(false)
            }}
          >
            Cancelar
          </Button>
          <Button className="btn btn-danger"
            onClick={() => {
              setShowModal(!showModal)
              setShowForm(!showForm)
              setRestart(false)
              setIsView(true)
              ActionSol()
              setModalAction(false)
            }}
          >
            {actionBtn}
          </Button>
        </Modal.Footer>
      </Modal>

    </div>



  )
}

export default RQ
