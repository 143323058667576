import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button, Col, Form, Row, } from 'react-bootstrap'
import { sessionActive, decodeUserPermission } from '../lib/functions'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom'


export const SelectContract = ({ show, hide, contracts, setContractSelected, restart, setRestart, expandSidebar }) => {
  const [filterindItems, setFilterindItems] = useState([])
  const [formats, setFormats] = useState([])
  const [search, setSearch] = useState()
  const [company, setCompany] = useState([])
  const [contrato, setContrato] = useState()
  const [put, setPut] = useState(false)
  const [_delete, set_delete] = useState(false)
  const [post, setPost] = useState(false)

  useEffect(() => {
    sessionActive()
    fillTable()
  }, [])

  const fillTable = async () => {
    //Obtener Permisos de Usuario
    const resPermission = decodeUserPermission(
      localStorage.getItem('userPermission'),
      localStorage.getItem('IdUser_PK')
    )

    if (resPermission.superAdmin.access) {
      setPut(true)
      set_delete(true)
      setPost(true)
    } else {
      setPut(resPermission.client.PUT)
      set_delete(resPermission.client.DELETE)
      setPost(resPermission.client.POST)
    }
    setFormats(contracts)
    setFilterindItems(contracts)

  }


  const OnFilter = e => {
    let FilterText = e.target.value
    setSearch(FilterText)
    setFilterindItems(
      formats.filter(
        item =>

          item.NumeroContrato.includes(FilterText) ||
          item.GerenteContrato.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.description.toLowerCase().includes(FilterText.toLowerCase()) ||
          item.Status.toLowerCase().includes(FilterText.toLowerCase())
      )
    )
  }

  const handleChange = (data) => {
    let temp = data
   setContractSelected({
      ...data,
      Empresa: JSON.parse(data["Empresa"]),
      Items: JSON.parse(data["Items"]),
      users: JSON.parse(data["users"])
    })
 }

  const ClearSearch = () => {
    setSearch('')
    setFilterindItems(formats)

  }
  const columns = [
    {
      name: 'item',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.IdFormat_PK}
        </div>
      ),
      selector: row => row.IdFormat_PK,
      sortable: true,
      fixed: true,
    }, {
      name: 'Contrato/OS',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.NumeroContrato}
        </div>
      ),
      selector: row => row.IdFormat_PK,
      sortable: true,
      fixed: true,
    },
    {
      name: 'Gerente',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.GerenteContrato}
        </div>
      ),
      selector: row => row.GerenteContrato,
      sortable: true,
    },
    {
      name: 'Descripcion',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.description}
        </div>
      ),
      grow: 2,
      selector: row => row.description,
      sortable: true,
    },
    {
      name: 'Estado',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.Status}
        </div>
      ),
      selector: row => row.Status,
      sortable: true,
    },
    {
      name: 'Seleccionar',
      cell: row => (
        <div >

          <Link to={"/request/"}   >
            <Button type="Submit" style={{ color: 'white' }} onClick={() => { handleChange(row) }}>
              <i className="fa-solid fa-check"></i>
            </Button>
          </Link>

        </div>
      ),
      button: true,
    },


  ]

  return (
    <>



      {(
        <Overlay expandSidebar={expandSidebar} >
          <ContenedorModal>
            <EncabezadoModal>
              <h3>Seleccione el contrato para la solicitud</h3>
            </EncabezadoModal>
            <Frame>
              <Col className="d-flex">
                <Form.Control
                  type="text"
                  id="search"
                  placeholder="Búsqueda"
                  aria-label="search input"
                  value={search}
                  onChange={OnFilter}
                />
                <Button className="btn btn-success btn-sm" type="button" onClick={ClearSearch}>
                  <i className="bi bi-arrow-counterclockwise" />
                </Button>
              </Col>
              <br></br>
              <DataTable
                fixedHeader={false}
                fixedHeaderScrollHeight={{ fixedHeaderScrollHeight: '100px' }}
                columns={columns}
                data={filterindItems}
                pagination
                responsive
                //dense={props.dense}
                highlightOnHover
                pointerOnHover
                noDataComponent="No hay registros para mostrar"
                paginationComponentOptions={{
                  rowsPerPageText: 'Filas por página:',
                  rangeSeparatorText: 'de',
                }}
              />
            </Frame>


          </ContenedorModal>
        </Overlay>
      )}


    </>

  );
}

export default SelectContract

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: ${props => (props.expandSidebar ? '40': '220') }px;

  background: rgb(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  
`
const ContenedorModal = styled.div`
  width: 900px;
  min-height: 400px;
  background: #E8E8E8;
  left: -10%;
  position: relative;
  border-radius: 5px ;
  box-shadow: rgba(100,100,111,0.2) 0px 7px 29px 0px;
  padding: 25px;
`
const EncabezadoModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-bottom:  10px;
  border-bottom: 1px solid #E8E8E8 ;

  h3{
    font-weight: 500;
    font-size: 20px;
    color: #303437;
  }
`

const Frame = styled.div`
  input {
    text-align: center;
    width: 100%;
    border: 1px solid ;
    border-color: #D8D8D8;
    background:  #fff;
    padding-left: 5px; 
    /* margin-top: 5px; */
}
  label {
    
    display: flex;
    font-size: 15px;
    font-weight: bold;
    text-align: 'center';
  }
  
  button{
    width: 40px;
    height: 40px;
    padding: 0;

  }
  `
