import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { sessionActive } from '../../../lib/functions'
import { Col, Row, Button, Form, Modal } from 'react-bootstrap'
import { GTHFOR244 } from '../../../lib/formatos/GTHFOR244'


import { eraseFormatId, getFormats, getWorkflowByFormat, updateFormat, deleteWorkFlowById } from '../../../lib/formatos/request'


export const ConditionChange = ({ put,
  _delete,
  post,
  sessionUser,
  newBotton,
  dense,
  route,
  isTH }) => {
  const typeF = 'GTHFOR244'
  const [isView, setIsView] = useState(true)
  const [restart, setRestart] = useState(false)
  const [IdFormat_PK, setIdFormat_PK] = useState('')
  const [showForm, setShowForm] = useState(false)
  const [data, setData] = useState({})
  const [newBt, setnewBt] = useState(false)
  const [ConditionChange, setConditionChange] = useState([])
  const [filterindItems, setFilterindItems] = useState([])
  const [ModalDelete, setModalDelete] = useState(false)
  const [empleados, setEmpleados] = useState(false)
  const [ModalNew, setModalNew] = useState(false)
  const [search, setSearch] = useState()
  const [idDelete, setIdDelete] = useState()
  const [idUser, setIdUser] = useState()
  const [nameDelete, setNameDelete] = useState()
  const [WFDelete, setWFDelete] = useState()
  const [formatDelete, setFormatDelete] = useState()
  const [list, setList] = useState("")
  const [isProcess, setisProcess] = useState(false)

  useEffect(() => {
    if (!restart) {
      setRestart(!restart)
      sessionActive()
      fillTable()
    }
  }, [restart])

  const fillTable = async () => {
    //Obtener lista de cambios de condicion

    //Obtener lista de Manuales
    // let res = await getFormats('gthfor76')

    // setList({ Manuales: res.data.data })
    // //const resConditionChange = await getFormats('employee' )
    // let resDNI = await getFormats('employee')

    // resDNI = resDNI.data.data.filter(item => item.Status === "activo" && item.idJefeInm_FK === localStorage.getItem("IdUser_PK"))
    // setEmpleados(resDNI)
    // setnewBt(resDNI.length === 0 ? false : true)
    const resConditionChange = await getFormats(typeF)
    if (resConditionChange.data.status === 200) {
      let dataConditionChange = isTH ? resConditionChange.data.data : resConditionChange.data.data.filter(item => item.createdBy === localStorage.getItem("IdUser_PK"))
      setConditionChange(dataConditionChange)
      setFilterindItems(dataConditionChange)
    } else {
      toast.error(resConditionChange.data.msj)
    }
     setList({ Manuales: resConditionChange.data.list.Manuales })
     let resDNI = resConditionChange.data.list.Empleados.filter(item => item.Status === "activo" && item.idJefeInm_FK === localStorage.getItem("IdUser_PK"))
     setEmpleados(resDNI)
     setnewBt(resDNI.length === 0 ? false : true)
  }

  const processRow = row => {
    if (put) {
      if (isTH && row.Status === 'Aprobado') return true
      else return false
    }
    return false
  }

  const editRow = row => {
    if (put) {
      if (!isTH && row.Status === 'creado') return true
      if (!isTH && row.Status === 'firmadoTH' && JSON.parse(row.firmaSolicitante).IdEmployee_FK === JSON.parse(row.firmaTH).IdEmployee_FK) return true
      else return false
    }
    return false
  }
  const deleteRow = row => {

    if (_delete && isTH && row.Status === 'creado') {
      return true

    }
    return false
  }

  const createChange = async () => {
    setRestart(false)
    setIsView(false)
    setIdFormat_PK('en creacion')
    let userChange = ConditionChange.filter(item => `${item.IdEmployee_FK}` === `${idUser}` && item.Status === 'active')
    if (userChange < 1) {
      userChange = empleados.filter(item => `${item.IdEmployee_FK}` === `${idUser}` && item.Status === 'activo')

    }
    var dataAct = {
      lugarActividades: 'N/A',
      areaTrabajo: 'N/A',
      cargo: 'N/A',
      manualFunciones: '',
      personalACargo: 'N/A',
      tipoContrato: 'N/A',
      salario: {
        otroSalario: 'N/A',
        primaAlimentacion: 'N/A',
        primaLocalizacion: 'N/A',
        primaMovilizacion: 'N/A',
        textoOtroSalario: 'N/A',
        tipo: 'N/A',
        valor: 'N/A'
      },
      CeCo: 'N/A',
      tipoNomina: 'N/A'
     
    }
    var dataAnt = {
      lugarActividades: userChange[0].lugarActividades,
      areaTrabajo: userChange[0].areaTrabajo,
      cargo: userChange[0].cargo,
      manualFucniones: userChange[0].manualFunciones,
      personalACargo: userChange[0].personalACargo,
      tipoContrato: userChange[0].tipoContrato,
      salario: JSON.parse(userChange[0].salario),
      CeCo: userChange[0].CeCo,
      tipoNomina: userChange[0].tipoNomina,



    }
    setData({
      dataAct: dataAct,
      dataAnt: dataAnt,
      NombreTrabajador: userChange[0].NombreTrabajador,
      ApellidoTrabajador: userChange[0].ApellidoTrabajador,
      IdEmployee_FK: userChange[0].IdEmployee_FK,
      fechaContratacion: userChange[0].fechaContratacion,
      tipoCambio: userChange[0].tipoCambio,
      Status: 'creando',
      IdFormat_FK: userChange[0].IdFormat_PK
    })


  }
  const deleteContract = async () => {

    const resDelele = await eraseFormatId('employee', idDelete)
    if (resDelele.data.status === 200) {

      let res = await updateFormat({ IdFormat_PK: WFDelete, Status: "disponible" }, "GTHFOR086")
      res = await (await getWorkflowByFormat(formatDelete, idDelete, 'pendiente')).data.data[0].IdWorkFlow_PK
      await deleteWorkFlowById(res)
      toast.success(resDelele.data.msj)
    } else {
      toast.error(resDelele.data.msj)
    }
    fillTable()
  }

  const OnFilter = e => {
    let FilterText = e.target.value
    setSearch(FilterText)
    setFilterindItems(
      ConditionChange.filter(
        formato => formato.IdEmployee_FK.includes(FilterText) ||
          formato.NombreTrabajador.toLowerCase().includes(FilterText.toLowerCase()) ||
          formato.Status.toLowerCase().includes(FilterText.toLowerCase()) ||
          formato.areaTrabajo.toLowerCase().includes(FilterText.toLowerCase()) ||
          formato.ApellidoTrabajador.toLowerCase().includes(FilterText.toLowerCase()) ||
          formato.fechaContratacion.toLowerCase().includes(FilterText.toLowerCase()) ||
          formato.cargo.toLowerCase().includes(FilterText.toLowerCase()) ||
          formato.areaTrabajo.toLowerCase().includes(FilterText.toLowerCase())
      )
    )
  }

  const ClearSearch = () => {
    setisProcess(false)
    setSearch('')
    setFilterindItems(ConditionChange)
  }

  const handleChange = (data) => {
    setIdFormat_PK(data.IdFormat_PK)
    setData({ ...data, dataAnt: JSON.parse(data.dataAnt), dataAct: JSON.parse(data.dataAct) })
    setShowForm(!showForm)
    setRestart(false)
    setIsView(true)

    setisProcess(false)

  }


  const columns = [
    {
      name: 'id',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.IdFormat_PK}
        </div>
      ),
      selector: row => row.IdFormat_PK,
      sortable: true,
      width: '80px'
    },
    {
      name: 'Documentos',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.IdEmployee_FK}
        </div>
      ),
      selector: row => row.IdFormat_PK,
      sortable: true,
      width: '120px'
    },
    {
      name: 'Nombre',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.NombreTrabajador}
        </div>
      ),
      selector: row => row.IdFormat_PK,
      sortable: true,
    },
    {
      name: 'Apellido',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.ApellidoTrabajador}
        </div>
      ),
      selector: row => row.IdFormat_PK,
      sortable: true,
    },
    {
      name: 'Cargo',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {JSON.parse(row.dataAnt).cargo}
        </div>
      ),
      selector: row => row.IdContract_PK,
      sortable: true,
      grow: 1.5,
    },
    {
      name: 'Area Trabajo',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {JSON.parse(row.dataAnt).areaTrabajo}
        </div>
      ),
      selector: row => JSON.parse(row.dataAnt).areaTrabajo,
      sortable: true,
    },
    {
      name: 'Fecha Contratación',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.fechaContratacion}
        </div>
      ),
      selector: row => row.IdContract_PK,
      sortable: true,
    },
    {
      name: 'Estado',
      cell: row => (
        <div
          onClick={() => {
            handleChange(row)
          }}
          data-tag="allowRowEvents"
        >
          {row.Status}
        </div>
      ),
      selector: row => row.IdContract_PK,
      sortable: true,
      grow: 1,
    },
    {
      name: 'Acciones',
      cell: row => (
        <div className="tabla dropdown dropstart">
          <i
            className="bi bi-three-dots-vertical"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          />
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            {isTH ? <><li>
              <Link
                onClick={() => {
                  setIdFormat_PK(row.IdFormat_PK)

                  setData({ ...row, dataAnt: JSON.parse(row.dataAnt), dataAct: JSON.parse(row.dataAct) })
                  setShowForm(!showForm)
                  setRestart(false)
                  setIsView(true)
                  setisProcess(true)


                }}
                className={'dropdown-item text-center border-end' + (processRow(row) ? '' : ' disabled')}
                to={route}
              >
                <i className="fas fa-edit me-3 "></i>Procesar
              </Link>
            </li></> : <><li>
              <Link
                onClick={() => {
                  setIdFormat_PK(row.IdFormat_PK)

                  setData({ ...row, dataAnt: JSON.parse(row.dataAnt), dataAct: JSON.parse(row.dataAct) })
                  setShowForm(!showForm)
                  setRestart(false)
                  setIsView(false)

                  setisProcess(false)
                }}
                className={'dropdown-item text-center border-end' + (editRow(row) ? '' : ' disabled')}
                to={route}
              >
                <i className="fas fa-edit me-3 "></i>Editar
              </Link>
            </li>
              <li>
                <Link
                  className={'dropdown-item text-center border-end' + (deleteRow(row) ? '' : ' disabled')}
                  to={route}
                  onClick={() => {
                    setIdDelete(row.IdFormat_PK)
                    setFormatDelete(row.TipoFormato)
                    setWFDelete(row.EmpSol)
                    setNameDelete(row.NombreTrabajador + " " + row.ApellidoTrabajador)
                    setRestart(false)
                    setModalDelete(true)
                    setisProcess(true)
                  }}
                >
                  <i className="bi bi-trash me-3"></i>Borrar
                </Link>
              </li></>}
          </ul>
        </div>
      ),
      button: true,
    },
  ]

  return (
    <>
      <Row>
        <Col>
          {newBotton && (
            <Button
              variant="success"
              className="m-3 p-2 rounded-pill btn-sm ms-1"
              disabled={post && newBt ? false : true}
              onClick={() => {
                setModalNew(!ModalNew)
              }}
            >
              <i className="bi bi-window-plus mx-2" />
              Crear Solicitud
            </Button>
          )}
          <Col className="d-flex">
            <Form.Control
              style={{ widht: "100%" }}
              type="text"
              id="search"
              placeholder="Búsqueda"
              aria-label="search input"
              value={search}
              onChange={OnFilter}

            />
            <Button data-tip="Limpiar Filtro" className="btn btn-success btn-sm" type="button" onClick={ClearSearch}>
              <i className="bi bi-arrow-counterclockwise" />
            </Button>
          </Col>
          <Row>
            <DataTable
              fixedHeader={false}
              fixedHeaderScrollHeight={{ fixedHeaderScrollHeight: '100px' }}
              columns={columns}
              data={filterindItems}
              pagination
              responsive
              dense={dense}
              highlightOnHover
              pointerOnHover
              noDataComponent="No hay registros para mostrar"
              paginationComponentOptions={{
                rowsPerPageText: 'Filas por página:',
                rangeSeparatorText: 'de',
              }}
            />

          </Row>
        </Col>

      </Row>
      {showForm && <GTHFOR244
        show={showForm}
        hide={setShowForm}
        data={data}
        isView={isView}
        setData={setData}
        IdFormat_PK={IdFormat_PK}
        setRestart={setRestart}
        restart={restart}
        isTH={isTH}
        sessionUser={sessionUser}
        list={list}
        isProcess={isProcess}
      />}
      <Modal
        show={ModalNew}
        onHide={() => {
          setModalNew(false)
        }}
        centered
      >
        <Modal.Header closeButton>
          <h5>Seleecione al colaborador</h5>
        </Modal.Header>
        <Modal.Body>
          <div style={{ alignContent: 'center', textAlign: 'center' }}>
            <select
              name="contabilidad"
              tabIndex="49"
              onChange={e =>
                setIdUser(e.target.value)

              }
              required
              placeholder="seleccione..."
              title="seleccione..."
            >
              <option key={0} value={''} selected> </option>
              {empleados.length === 1 && <option key={1} value={empleados[0].IdEmployee_FK}>{empleados[0].NombreTrabajador + " " + empleados[0].ApellidoTrabajador} </option>}
              {empleados.length > 1 && empleados.map((item, i) => {
                return (
                  <option key={i + 1} value={item.IdEmployee_FK}>{item.NombreTrabajador + " " + item.ApellidoTrabajador} </option>
                );
              })}
            </select></div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {

              setModalDelete(false)
            }}
          >
            Cancelar
          </Button>
          <Button className="btn btn-danger"
            onClick={() => {
              createChange()
              setModalNew(false)
              setShowForm(!showForm)
            }}
          >
            Modificar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={ModalDelete}
        onHide={() => {
          setModalDelete(false)
        }}
        centered
      >
        <Modal.Header closeButton>
          <h5>Eliminar Solicitud cambio contractual</h5>
        </Modal.Header>
        <Modal.Body>
          <p>¿Realmente desea eliminar el la solicitud {idDelete} de {nameDelete}?</p>

        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {

              setModalDelete(false)
            }}
          >
            Cancelar
          </Button>
          <Button className="btn btn-danger"
            onClick={() => {
              deleteContract()
              setModalDelete(false)
            }}
          >
            Eliminar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ConditionChange
