import React, { useState, useEffect } from 'react'
import { sessionActive, decodeUserPermission } from '../lib/functions'
import { getFullData } from '../lib/request'
import { Col, Row, Card } from 'react-bootstrap'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import Contracts from './contracts/Contracts'
import { Services } from './services/Services'
import Companies from './companies/Companies'
import OperReq from './operReq/OperReq'



export default function Operations({dataUser,contracts}) {
  const [put, setPut] = useState(false)
  const [_delete, set_delete] = useState(false)
  const [post, setPost] = useState(false)
  const [sessionUser, SetSessionUser] = useState([])

  useEffect(() => {
    sessionActive()
    Permissions()
  }, [])
  const Permissions = async () => {
    //Obtener Permisos de Usuario
    const resPermission = decodeUserPermission(
      localStorage.getItem('userPermission'),
      localStorage.getItem('IdUser_PK')
    )
    if (resPermission.superAdmin.access) {
      setPut(true)
      set_delete(true)
      setPost(true)
    } else {
      setPut(resPermission.operations.PUT)
      set_delete(resPermission.operations.DELETE)
      setPost(resPermission.operations.POST)
    }
    //Obtener perfil de usuario (contrato)
    var res = await getFullData('employee', 'IdEmployee_FK', localStorage.getItem('IdUser_PK'))
    SetSessionUser(res.data.data[0])
  }

  return (
    <>
    <div className="position-relative page-content py-3">
      <Row>
        <Col>
          <Card style={{minHeigth:'900px'}}>
            <Tabs>
              <Card.Header className="card-tabs text-center  align-items-center">
                <TabList>
                  <h5 className="tabs-tittle">Operaciones</h5>
                  <Tab><p>Solicitudes</p></Tab>
                  <Tab><p>Servicios</p></Tab>
                  <Tab><p>Contratos</p></Tab>
                  <Tab><p>Empresas</p></Tab>
                
                </TabList>
              </Card.Header>
              <Card.Body>
              <TabPanel>
                  
                  <OperReq
                    put={put}
                    delete={_delete}
                    post={post}
                    sessionUser={sessionUser}
                    newBotton={true}
                    uniqueUser={true}
                  />
                </TabPanel>
                <TabPanel>
                  
                  <Services
                    put={put}
                    delete={_delete}
                    post={post}
                    sessionUser={sessionUser}
                    newBotton={true}
                    uniqueUser={true}
                  />
                </TabPanel>
                <TabPanel>
                  <Contracts
                    put={put}
                    delete={_delete}
                    post={post}
                    sessionUser={sessionUser}
                    newBotton={true}
                    uniqueUser={true}
                  ></Contracts>
                </TabPanel>
                <TabPanel>
                <Companies
                    put={put}
                    delete={_delete}
                    post={post}
                    sessionUser={sessionUser}
                    newBotton={true}
                    uniqueUser={true}
                  ></Companies>
                </TabPanel>
                
              </Card.Body>
            </Tabs>
          </Card>
        </Col>
      </Row>
    </div>
    </>
  )
}
