    import React, { useEffect, useState } from 'react'
    import styled from 'styled-components'
    import SelectOption from '../lib/SelectOption'

    export const CommTable = ({ data = [], setData, isView, tableType, list }) => {
        const [showSave, setShowSave] = useState(false)
        const [restart, setRestart] = useState(false)
        const [selected, setSelected] = useState()
        const [Data0, setData0] = useState('')
        const [Data1, setData1] = useState('')
        const [Data2, setData2] = useState('')
        const [Data3, setData3] = useState('')
        const [Data4, setData4] = useState('')
        const [Data5, setData5] = useState('')
        const [Data6, setData6] = useState('')
        const [Data7, setData7] = useState('')

        const [singleSelections, setSingleSelections] = useState([]);
        const [options, setOptions] = useState(list)
        const tableOption = {
            tableEQ: {
                headersTable: ['Id', 'Marca', 'Modelo', 'Serial', 'Tipo', 'Descripción'],
                dataTitle: ["IdFormat_PK", 'EqMarca', 'EqModelo', 'EqSerial', 'EqTipo', 'EqDenominacion'],
                selectTitle: "Seleccione un equipo...",
                width: ['8%', '11%', '11%', '14%', '28%', '28%']
            },
            tableEmployee: {
                headersTable: ['Id', 'Nombre', 'Apellido', 'Cargo', 'Area', 'CeCo'],
                dataTitle: ["IdEmployee_FK", 'NombreTrabajador', 'ApellidoTrabajador', 'cargo', 'areaTrabajo', 'lugarDesempeñoLabores'],
                selectTitle: "Seleccione el presional para ejecutar el servicio...",
                width: ['10%', '15%', '15%', '25%', '20%', '15%']
            },
            tableContract: {
                headersTable: ['Id', 'Nombre', 'Disciplina', 'Estado', 'Cantidad', 'Precio unitario', 'Precio total', 'Total consumido'],
                dataTitle: ["IdFormat_PK", 'name', 'discipline', 'Status'],
                selectTitle: "Seleccione los el contrato"
            },
            tableServices: {
                headersTable: ['Id', 'Nombre', 'Disciplina', 'Estado', 'Cantidad', 'Precio unitario'],
                dataTitle: ["IdFormat_PK", 'name', 'discipline', 'Status', 'Cantidad', 'Preciounitario'],
                selectTitle: "Seleccione los servicios...",
                width: ["10%", "30%", "15%", "15%", "15%", "15%"]
            },
            // tableCompanies: {
            //     headersTable: ['Id', 'Nombre', 'Disciplina', 'Estado', 'Cantidad', 'Precio unitario', 'Precio total', 'Total consumido'],
            //     dataTitle: ["IdCompany_PK", 'SocialName', 'ContactPerson', 'PhoneContact'],
            //     selectTitle: "Seleccione los servicios...",
            //     width:[ '10%','15%','15%','25%','20%','15%']
            // },
            tableUsers: {
                headersTable: ['Usuario', 'Nombres', 'Apellidos', 'Empresa', 'Cargo', 'Estado'],
                dataTitle: ["IdUser_PK", 'UserName', 'UserLastName', 'IdCompany_FK', 'Position', 'Status'],
                selectTitle: "Seleccione el usuario...",
                width: ['10%', '15%', '15%', '25%', '20%', '15%']
            },

        }

        useEffect(() => {
            if (!!selected) {
                let selectedValue = list.filter(i => i.value === selected)[0]
                if (selected !== undefined) {
                    setData0(selectedValue[tableOption[tableType].dataTitle[0]])
                    setData1(selectedValue[tableOption[tableType].dataTitle[1]])
                    setData2(selectedValue[tableOption[tableType].dataTitle[2]])
                    setData3(selectedValue[tableOption[tableType].dataTitle[3]])
                    if (tableType === 'tableServices') {
                        setData4('')
                        setData5('')
                    } else {
                        setData4(selectedValue[tableOption[tableType].dataTitle[4]])
                        setData5(selectedValue[tableOption[tableType].dataTitle[5]])
                        setShowSave(true)
                    }

                }
            }
        }, [selected])






        const saveItem = () => {

            if (tableType === 'tableEQ') var nuevoItem = { IdFormat_PK: Data0, EqMarca: Data1, EqModelo: Data2, EqSerial: Data3, EqTipo: Data4, EqDenominacion: Data5 }
            else if (tableType === 'tableEmployee') var nuevoItem = { IdEmployee_FK: Data0, NombreTrabajador: Data1, ApellidoTrabajador: Data2, cargo: Data3, areaTrabajo: Data4, lugarDesempeñoLabores: Data5 }
            else if (tableType === 'tableContract') var nuevoItem = { IdFormat_PK: Data0, name: Data1, discipline: Data2, Status: Data3, Cantidad: Data4, Preciounitario: Data5, Preciototal: Data6, Totalconsumido: Data7 }
            else if (tableType === 'tableServices') var nuevoItem = { IdFormat_PK: Data0, name: Data1, discipline: Data2, Status: Data3, Cantidad: Data4, Preciounitario: Data5, Preciototal: Data6 }
            else if (tableType === 'tableUsers') var nuevoItem = { IdUser_PK: Data0, UserName: Data1, UserLastName: Data2, IdCompany_FK: Data3, Position: Data4, Status: Data5 }
            // else if (tableType === 'tableCompanies') var nuevoItem = { IdCompany_PK: Data0, SocialName: Data1, ContactPerson: Data2, PhoneContact: Data3 }
            data.push(nuevoItem)
            setData(data)

            setData0('')
            setData7('')
            setData6('')
            setData5('')
            setData4('')
            setData3('')
            setData2('')
            setData1('')
            setShowSave(false)
            setRestart(!restart)
        }
        const deleteItem = (e) => {
            const months = data
            months.splice(e.currentTarget.id, 1);
            //setData(data);
            setRestart(!restart)
        }



        const handleChange = ({ target }) => {
            const { name, type, id, accessKey } = target
            const value = type === 'checkbox' ? target.checked : target.value

            if (Data1 !== '' && Data2 !== '' && Data3 !== '' && Data4 !== '' && Data5 !== '')
                setShowSave(true)
            else if (Data1 !== '' && Data2 !== '' && Data3 !== '' && tableType === 'LCC') setShowSave(true)
            else setShowSave(false)
        }


        return (
            <Frame>{<table style={{ width: '100%' }}>
                <thead style={{ textAlign: 'center' }}>
                    <tr>
                        <th scope="Col" style={{ width: tableOption[tableType]["width"][0] }}>{tableOption[tableType]["headersTable"][0]}</th>
                        <th scope="Col" style={{ width: tableOption[tableType]["width"][1] }}>{tableOption[tableType]["headersTable"][1]}</th>
                        <th scope="Col" style={{ width: tableOption[tableType]["width"][2] }}>{tableOption[tableType]["headersTable"][2]}</th>
                        <th scope="Col" style={{ width: tableOption[tableType]["width"][3] }}>{tableOption[tableType]["headersTable"][3]}</th>
                        <th scope="Col" style={{ width: tableOption[tableType]["width"][4] }}>{tableOption[tableType]["headersTable"][4]}</th>
                        <th scope="Col" style={{ width: tableOption[tableType]["width"][5] }}>{tableOption[tableType]["headersTable"][5]}</th>
                        {tableType === 'tableServices' ? <>{!isView && <th scope="Col" style={{ width: '7%' }}>Acciones</th>}</> : !isView && <th scope="Col" style={{ width: '10%' }}>Acciones</th>}
                    </tr>
                </thead>
                <tbody>
                    {Array.isArray(data) && data?.map((item, key) => (
                        <tr key={key}>
                            <th scope='row'><label>{item[tableOption[tableType]["dataTitle"][0]]}</label></th>
                            <th><label>{item[tableOption[tableType]["dataTitle"][1]]}</label></th>
                            <th><label>{item[tableOption[tableType]["dataTitle"][2]]}</label></th>
                            <th><label>{item[tableOption[tableType]["dataTitle"][3]]}</label></th>
                            <th><label>{item[tableOption[tableType]["dataTitle"][4]]}</label></th>
                            <th><label>{item[tableOption[tableType]["dataTitle"][5]]}</label></th>
                            {!isView && <th style={{ textAlign: 'center' }} role='button' id={key} onClick={deleteItem}>{!isView && <><i style={{ color: 'red', fontSize: '20px' }} className="fa-solid fa-trash"></i></>}</th>}</tr>))
                    }{!isView && <><tr>
                        <td><input name='Data0' id='na0' value={Data0} disabled={true}></input></td>
                        <td><input name='Data1' id='na1' value={Data1} disabled={true}></input></td>
                        <td><input name='Data2' id='na2' value={Data2} disabled={true} /></td>
                        <td><input name='Data3' id='na3' value={Data3} disabled={true} /></td>
                        <td><input type={tableType === 'tableServices' ? 'number' : 'text'} name='Data4' id='na4'
                            onChange={e => { setData4(e.target.value); handleChange(e) }} value={Data4} disabled={tableType === 'tableServices' ? false : true} /></td>
                        <td><input type={tableType === 'tableServices' ? 'number' : 'text'} name='Data5' id='na5'
                            onChange={e => { setData5(e.target.value); handleChange(e) }} value={Data5} disabled={tableType === 'tableServices' ? false : true} /></td>

                        <th style={{ textAlign: 'center' }} role='button'>{showSave && <i onClick={saveItem} style={{ color: 'green', fontSize: '23px' }} className="fa-solid fa-check"></i>}</th>
                    </tr>
                        <tr><td colSpan={6}>
                            <SelectOption type={"custom"}
                                searchable={true}
                                state={selected}
                                setState={setSelected}
                                list={list}

                            />
                        </td>

                        </tr></>}
                </tbody>
            </table>}
            </Frame>
        )
    }

    const Frame = styled.div`
    input {
        border-bottom: 0px solid black;
    }
    input.disable {
        background: #fff;
        border-bottom: 0px solid black;
    }
    `