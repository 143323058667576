import { decodeUserPermission } from '../components/app/lib/functions';
import {  getFullData } from '../components/app/lib/request';
import SpinnerLoad from  '../components/app/lib/spinnerLoad';
import { createContext, useEffect, useState } from 'react';

export const UserContext = createContext()

export function UserContextProvider(props) {
    const [sessionUser, SetSessionUser] = useState([])
    const [Permission, SetPermission] = useState([])

    useEffect(() => {
        getData()
    }, [])

    const userValue = { sessionUser, Permission }
    const getData = async () => {
        //Obtener Permisos de Usuario
        SetPermission( decodeUserPermission (
        localStorage.getItem('userPermission'),
        localStorage.getItem('IdUser_PK')
      ) )
        //Obtener perfil de usuario (contrato)
        var res = await getFullData  ("employee", "IdEmployee_FK", localStorage.getItem("IdUser_PK"))
        let permission = decodeUserPermission (localStorage.getItem('userPermission'), localStorage.getItem('IdUser_PK')) 
                        
        SetSessionUser({...res.data.data[0], permission: permission })
        if ((res.data.data.length === 0))
            SetSessionUser({
                IdEmployee_FK: localStorage.getItem("IdUser_PK"),
                UserName: localStorage.getItem("UserName"),
                UserLastName: localStorage.getItem("UserLastName"),
                initials: localStorage.getItem("initials"),

            })
        return
    }

    return (
        <UserContext.Provider value={userValue}>
            <SpinnerLoad trigger={sessionUser} /> 
            {props.children}
        </UserContext.Provider>
    )
}
